import axios from 'axios';
import Cookies from 'js-cookie';
import { API_HOST } from '../utils/constant';


const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache', //needed for IE11
  Authorization: `Bearer ${Cookies.get('id_token')}`,
};

const instance = axios.create({
  baseURL: API_HOST,
  headers,
});

instance.interceptors.request.use(
  function (config) {
    const token = Cookies.get("id_token");
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


// Add a response interceptor
instance.interceptors.response.use((response) => {
  return response;
}, (error) => {

  if (error.response && error.response.status === 401) {
    localStorage.removeItem('user');
    localStorage.removeItem('userLayout');
    Cookies.remove('id_token');

    if ((window.location.href.indexOf("login") < 0) && (window.location.href.indexOf("signup") < 0)) {
      window.location = "/";  // <-- add your path  - should redirect to new logout page here where we can run logout via reducer
    }
  } else
    return Promise.reject(error);

  // if (error.response && error.response.data && error.response.data.error &&
  //   (error.response.data.session === false || error.response.data.session === "false")) {
  //   localStorage.removeItem("userId"); // <-- add your var
  //   window.location = "/";   // <-- add your path
  // }
  // else if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
  //   toastMessage(error.response.data.error.message, 1);
  // }
  // else
  //   if (error.response && error.response.status === 401) {
  //     localStorage.removeItem("userId"); // <-- add your var
  //     window.location = "/";  // <-- add your path
  //   } else
  //     return Promise.reject(error);
});


export const api = instance;
