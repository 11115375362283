
const CustomDrawer = ({ children, open, onClose }) => {



  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose()
    }
  };

  return (
    <div>
      {open && (
        <div
          role="presentation"
          onKeyDown={handleKeyDown}
          onClick={onClose}
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              width: 300,
              backgroundColor: 'white',
              padding: '16px',
              zIndex: 1001,
            }}
          >
            <button onClick={onClose}>Close Drawer</button>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};



export default CustomDrawer;
