import { api } from './api'


// //need to put this in reducer - then all jump metrics can pull it from reducer!!!!!!
// export const getJumpData = (sessionid) => {
//   return api.get(`/session-metric-data/getJumpData/${sessionid}`).then(res => {
//     return res
//   })
// }


export const getCadenceDetailsForChart = (sessionid) => {
  return api.get(`/sessionData/getCadenceData/${sessionid}`).then(res => {
    //return api.get(`/session-metric-data/getDataBySessionID/${sessionid}/CadenceData`).then(res => {
    return res
  })
}

export const getCadenceExtraInfo = (sessionid) => {
  return api.get(`/sessionData/getCadenceExtraInfo/${sessionid}`).then(res => {
    //return api.get(`/session-metric-data/getDataBySessionID/${sessionid}/FootstrikeData`).then(res => {
    return res
  })
}

export const getContactTime = (sessionid) => {
  return api.get(`/sessionData/getContactTime/${sessionid}`).then(res => {
    //return api.get(`/session-metric-data/getDataBySessionID/${sessionid}/ContactTimeData`).then(res => {
    return res
  })
}

export const getFlightTime = (sessionid) => {
  return api.get(`/sessionData/getFlightTime/${sessionid}`).then(res => {
    //return api.get(`/session-metric-data/getDataBySessionID/${sessionid}/FlightTimeData`).then(res => {
    return res
  })
}


export const getAssymetry = (sessionid) => {
  return api.get(`/sessionData/getAssymetryDetails/${sessionid}`).then(res => {
    //return api.get(`/session-metric-data/getDataBySessionID/${sessionid}/StrideSymmetryData`).then(res => {
    return res
  })
}


export const getSwingStance = (sessionid) => {
  return api.get(`/sessionData/getSwingStanceRatio/${sessionid}`).then(res => {
    //return api.get(`/session-metric-data/getDataBySessionID/${sessionid}/SwingStanceData`).then(res => {
    return res
  })
}

//trim session api
export const setSessionTrim = (sessionID, postBody) => {
  //expects session ID and postbody in [{"from":808,"to":9116}] format with the numbers representing time in milliseconds
  return api.post(`sessionData/setSessionTrimData/${sessionID}`, postBody).then(res => {
    return res     
  })
}

//trim session reset api
export const resetSessionTrim = (sessionID) => {
  return api.get(`sessionData/resetTrimData/${sessionID}`).then(res => {
    return res
  })
}
