import { api } from './api'

export const getOrganisationsAPI = (userid) => {
  return api.get(`/organisation/organisationList/${userid}`).then(res => {
    return res
  })
}

export const removeAdminAPI = (orgAdminID) => {
  return api.get(`/organisation/removeAdministrator/${orgAdminID}`).then(res => {
    return res
  })
}

export const removeCoachAPI = (orgCoachID) => {
  return api.get(`/organisation/removeCoach/${orgCoachID}`).then(res => {
    return res
  })
}


export const removePodFromOrgAPI = (podID) => {
  return api.get(`/organisation/deactivatepod/${podID}`).then(res => {
    return res
  })
}


export const removePlayerFromOrgAPI = (playerID) => {
  return api.get(`/organisation/deactivateplayer/${playerID}`).then(res => {
    return res
  })
}

export const playerDetailsAPI = (data) => {
  return api.post('player/details', data).then(res => {
    return res
  })
}

export const updatePlayerDetailsAPI = (data) => {
  return api.patch(`/players/${data.id}`, data).then(res => {
    return res
  })
}

export const addAdminToOrgAPI = (OrgId, data) => {
  return api.post(`organisation/addadmin/${OrgId}`, data).then(res => {
    return res
  })
}


export const addCoachToOrgAPI = (OrgId, data) => {
  return api.post(`organisation/addcoach/${OrgId}`, data).then(res => {
    return res
  })
}

//organisation tags apis
//get all organisation tags
export const getOrganisationTagsAPI = (OrgId) => {
  return api.get(`/tags/getList/${OrgId}`).then(res => {
    return res
  })
}

//add new tag to organisations tags
export const addOrganisationTagAPI = (data) => {
  return api.post(`/tags/addTag`, data).then(res => {
    return res
  })
}

//remove tag from organisation
export const removeOrganisationTagAPI = (tagId) => {
  return api.get(`/tags/deleteTag/${tagId}`)
}
