/* eslint-disable */
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const HomePageInnerTableRow = (props) => {
  const {
    data,
    heights = "30px",
    goToPlayerProfilePage,
    goToOrganisationProfilePage,
    clickHandler,
    selectedRow,
    performanceType,
    timeframe,
    comparisonType
  } = props;

  const [windowWidth, setWindowWidth] = useState(screen.width)

  const widthOutput = screen.width
  function handleResize(){
      setWindowWidth(window.innerWidth)
    }

  useEffect(()=> {
   

  },[window.addEventListener('resize', handleResize)])

 

  //user.fullname.split(" ").map(item => item[0]).join("");

  return (
    <>
      {data.length > 0 ? (
        <>
          {data.map((item, i) => (
            <motion.tr
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.6,
                delay: 0.07 * i,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              style={{ marginBottom: "0.5rem" }}
              height={heights}
              key={i}
              id={
                item.playerID ? `player-${item.playerID}` : `org-${item.orgID}`
              }
              className={`table-row-color table-row-items ${
                !item.isPlayer ? "td-items-org" : ""
              }`}
            >
              <td
                className="home-table-row home-table-row-head"
                onClick={() => {
                  if (item.isPlayer) {
                    {goToPlayerProfilePage && goToPlayerProfilePage(item.playerID)}
                  }
                  if (!item.isPlayer) {
                    {goToOrganisationProfilePage && goToOrganisationProfilePage(item.orgID)}
                  }
                }}
              >
                <th>{widthOutput > 820  ? item.player : item.player.split(" ").map(item => item[0]).join("")}</th>
              </td>

              {/* {populateFirstGraph(i, item)} */}

              <td
                className="home-table-row home-table-row-item"
                onClick={() => {
                  if (item.isPlayer) {
                    clickHandler(item);
                  }
                }}
              >
                <th>
                  {item.overview["4"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`]
                    ? item.overview["4"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`].toFixed(1) + ""
                    : "-"}
                </th>
              </td>
              <td
                className="home-table-row home-table-row-item"
                onClick={() => {
                  if (item.isPlayer) {
                    clickHandler(item);
                  }
                }}
              >
                <th>
                  {item.overview["10"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`]
                    ? item.overview["10"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`].toFixed(1) + ""
                    : "-"}
                </th>
              </td>
              <td
                className="home-table-row home-table-row-item"
                onClick={() => {
                  if (item.isPlayer) {
                    clickHandler(item);
                  }
                }}
              >
                <th>
                  {item.overview["14"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`]
                    ? item.overview["14"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`].toFixed(1) + ""
                    : "-"}
                </th>
              </td>
              <td
                className="home-table-row home-table-row-item"
                onClick={() => {
                  if (item.isPlayer) {
                    clickHandler(item);
                  }
                }}
              >
                <th>
                  {item.overview["5"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`]
                    ? item.overview["5"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`].toFixed(1) + ""
                    : "-"}
                </th>
              </td>
              <td
                className="home-table-row home-table-row-item"
                onClick={() => {
                  if (item.isPlayer) {
                    clickHandler(item);
                  }
                }}
              >
                <th>
                  {item.overview["load"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`]
                    ? item.overview["load"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`].toFixed(1) + ""
                    : "-"}
                </th>
              </td>
              <td
                className="home-table-row home-table-row-item"
                onClick={() => {
                  if (item.isPlayer) {
                    clickHandler(item);
                  }
                }}
              >
                <th>
                  {item.overview["gait"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`]
                    ? item.overview["gait"]?.[`${timeframe}${(!item.isPlayer && performanceType === "Avg") ? "Avg" : ""}${performanceType}`].toFixed(1) + ""
                    : "-"}
                </th>
              </td>
            </motion.tr>
          ))}
        </>
      ) : (
        <tr>
          <td className="td-items">No Players Found</td>
        </tr>
      )}
    </>
  );
};

export default HomePageInnerTableRow;
