import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOrganisationsAPI, playerDetailsAPI, removeAdminAPI, removeCoachAPI, removePlayerFromOrgAPI, removePodFromOrgAPI, updatePlayerDetailsAPI } from '../api/organisations';
import { resetState } from './resetSlice';
export const getOrganisationsToolkit = createAsyncThunk('organisation/getOrganisations', async (userid) => {
  const response = await getOrganisationsAPI(userid);
  return response.data;
});

export const removePlayerFromOrg = createAsyncThunk('organisation/removePlayerFromOrg', async (playerID) => {
  const response = await removePlayerFromOrgAPI(playerID);
  return response.data;
});

export const removePodFromOrg = createAsyncThunk('organisation/removePodFromOrg', async (podID) => {
  const response = await removePodFromOrgAPI(podID);
  return response.data;
});

export const removeCoach = createAsyncThunk('organisation/removeCoach', async (orgCoachID) => {
  const response = await removeCoachAPI(orgCoachID);
  return response.data;
});

export const removeAdmin = createAsyncThunk('organisation/removeAdmin', async (orgAdminID) => {
  const response = await removeAdminAPI(orgAdminID);

  return response.data;
});

export const setPlayerDetails = createAsyncThunk('organisation/setPlayerDetails', async (payload,{dispatch}) => {
  const { newPlayer, userid } = payload;
  if (newPlayer.id > 0) {
    const response = await updatePlayerDetailsAPI(newPlayer);
    dispatch(getOrganisationsToolkit(userid));
    return response.data;
  } else {
    const response = await playerDetailsAPI(newPlayer);
    dispatch(getOrganisationsToolkit(userid));
    return response.data;
  }
});


export const initialState = {
  orgList: [],
  orgDetailCounter: 0,
  status: 'idle',
  error: null
}
const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {
    resetOrganisationDetailView: (state) => {
      state.orgDetailCounter++;
    },
    updateOrg: (state,action) => {
      state.orgList = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganisationsToolkit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOrganisationsToolkit.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orgList = action.payload;
      })
      .addCase(getOrganisationsToolkit.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(removePlayerFromOrg.fulfilled, (state, action) => {
        state.orgList = action.payload;
      })
      .addCase(removePodFromOrg.fulfilled, (state, action) => {
        state.orgList = action.payload;
      })
      .addCase(removeCoach.fulfilled, (state, action) => {
        state.orgList = action.payload;
      })
      .addCase(removeAdmin.fulfilled, (state, action) => {
        state.orgList = action.payload;
      })
      .addCase(setPlayerDetails.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(resetState, () => {
        return initialState;
      });
  }
});

export const { resetOrganisationDetailView, updateOrg  } = organisationSlice.actions;

export const selectOrganisation = (state) => state.organisation;

export default organisationSlice.reducer;
