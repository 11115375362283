/* eslint-disable */
import { connect } from "react-redux";
import {
  displayLoadingCharts,
  displayNoData,
} from "components/profileFunctions/profileFunctions";
import { useState } from "react";
import { selectSessionList2 } from "store/sessionListSlice";
import { selectSession } from "store/sessionSlice";

function PictureMaps(props) {
  const { sessionList, corsNeeded = false, session } = props;
  const sessionType = session.jumpData.data?.multimediaData
    ? "jumpData"
    : session.runData.data?.multimediaData
    ? "runData"
    : null;
  const sessionData =
    sessionType === "jumpData"
      ? session.jumpData.data
      : sessionType === "runData"
      ? session.runData.data
      : sessionType === "rawData"
      ? session.rawData.data
      : null;

  const [corsImageHandled, setCorsImageHandled] = useState([]);

  // //proxy url
  // const proxyUrl = "https://cors-anywhere.herokuapp.com/";

  // //proxy cors function
  // function proxyCorsImage(src){
  //     if(corsImageHandled.length < 4){
  //          const isBase64 = /^data:image\/(png|jpeg);base64,/.test(src);
  //         if (isBase64) {
  //             return;
  //         }
  //         fetch(proxyUrl + src)
  //             .then((res) => res.blob())
  //             .then(
  //             (blob) =>
  //                 new Promise((resolve, reject) => {
  //                 const reader = new FileReader();
  //                 reader.onerror = reject;
  //                 reader.onload = () => {
  //                     resolve(reader.result);
  //                 };
  //                 reader.readAsDataURL(blob);
  //                 })
  //             )
  //             .then((dataURL) => {
  //                 if(corsImageHandled.length < 4){
  //                     setCorsImageHandled(prevValue => [...prevValue, dataURL])
  //                 }
  //             });
  //     }

  // }

  const displayImages = () => {
    let picArray = [];
    const start = [];
    const stance = [];
    const end = [];

    let mappedData = [];
    //regular render of images
    if (sessionType === "runData" && corsNeeded === false) {
      start.push(sessionData?.multimediaData?.startCOPImgURL);
      stance.push(sessionData?.multimediaData?.stanceCOPImgURL);
      end.push(sessionData?.multimediaData?.endCOPImgURL);
      picArray = [...start, ...stance, ...end];

      picArray.forEach((item) => {
        if (item) {
          mappedData.push(
            <div className="pic" key={`pic_div_${picArray.indexOf(item)}`}>
              <h4>
                {picArray.indexOf(item) === 0
                  ? "Initial Contact"
                  : picArray.indexOf(item) === 1
                  ? "Stance Phase"
                  : picArray.indexOf(item) === 2
                  ? "Final Contact"
                  : ""}
              </h4>
              <img
                src={item}
                alt={
                  picArray.indexOf(item) === 0
                    ? "image of Initial Contact"
                    : picArray.indexOf(item) === 1
                    ? "image of Stance Phase"
                    : picArray.indexOf(item) === 2
                    ? "image of Final Contact"
                    : ""
                }
              />
            </div>
          );
        }
      });

      return mappedData;
    }
    //rendering images for to avoid cors for pdf download
    if (sessionType === "runData" && corsNeeded === true) {
      start.push(sessionData?.multimediaData?.startCOPImgURL);
      stance.push(sessionData?.multimediaData?.stanceCOPImgURL);
      end.push(sessionData?.multimediaData?.endCOPImgURL);
      picArray = [...start, ...stance, ...end];

      // picArray.forEach(item => {
      // proxyCorsImage(item)
      // })

      picArray.forEach((item) => {
        if (item) {
          mappedData.push(
            <div className="pic" key={`pic_div_${picArray.indexOf(item)}`}>
              <h4>
                {picArray.indexOf(item) === 0
                  ? "Initial Contact"
                  : picArray.indexOf(item) === 1
                  ? "Stance Phase"
                  : picArray.indexOf(item) === 2
                  ? "Final Contact"
                  : ""}
              </h4>
              <img
                src={corsImageHandled[picArray.indexOf(item)]}
                alt={
                  picArray.indexOf(item) === 0
                    ? "image of Initial Contact"
                    : picArray.indexOf(item) === 1
                    ? "image of Stance Phase"
                    : picArray.indexOf(item) === 2
                    ? "image of Final Contact"
                    : ""
                }
              />
            </div>
          );
        }
      });

      return mappedData;
    }

    if (sessionType === "jumpData") {
      start.push(sessionData?.multimediaData?.startCOPImgURL);
      end.push(sessionData?.multimediaData?.endCOPImgURL);
      picArray = [...start, ...end];

      picArray.forEach((item) => {
				if(item){
					mappedData.push(
						<div className="pic" key={`pic_div_${picArray.indexOf(item)}`}>
							<h4>
								{picArray.indexOf(item) === 0
									? "Take-Off"
									: picArray.indexOf(item) === 1
									? "Landing"
									: ""}
							</h4>
							<img
								src={item}
								alt={
									picArray.indexOf(item) === 0
										? "image of Initial Contact"
										: picArray.indexOf(item) === 1
										? "image of Final Contact"
										: ""
								}
							/>
						</div>
					);
				}
      });

      return mappedData;
    }

    if (
      session.runData.status === "loading" ||
      session.jumpData.status === "loading"
    ) {
      return displayLoadingCharts();
    }

    if (!sessionType || !sessionData) {
      return displayNoData();
    }
  };

  {
    return corsNeeded === false ? (
      <div className="metric-container-parent">
        <div className="mapping-pictures">{displayImages()}</div>
      </div>
    ) : (
      <div className="metric-container-parent">
        <div className="mapping-pictures-pdf">{displayImages()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionList: selectSessionList2(state),
    session: selectSession(state),
  };
}

export default connect(mapStateToProps)(PictureMaps);
