/* eslint-disable */
import * as Dialog from "@radix-ui/react-dialog";
import DownArrow from "../icons/downArrow";
import { updateSessionActivityAPI } from "api/session";


import { useState } from "react";
import "./activityChangeModal.scss";
import Button from "../button/button";
import { useDispatch } from "react-redux";
import { getSessionList } from "store/sessionListSlice";

const ActivityChangeModal = (props) => {
  const [selectedActivity, setSlectedActivity] = useState({ name: "", id: "" });
  const [open, setOpen] = useState(false)

  const { button, currentActivity, activityList, sessionID, rerunMetrics, checkForJumpData } = props;

  const dispatch = useDispatch();


  const close = () => {
    // setModal(prevModal => !prevModal);
    document.body.classList.remove("modal-open");
		setSlectedActivity({ name: "", id: "" })
  };

  function handleClick(name, id) {
    setSlectedActivity({ name: name, id: id });
  }

  async function submitActivityChange() {
    let postBody = {
      id: sessionID,
      aid: selectedActivity.id
    }
    //if poth postbody elements exist
    if(selectedActivity.id && sessionID){
        let updateSessionAactivity = await updateSessionActivityAPI(postBody)

        //if response status is 200
        if(await updateSessionAactivity.status === 200){
          await close()
          await setOpen(false)
          await rerunMetrics()
          await dispatch(getSessionList())
          
        }
      }  
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <span>{button}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="activityTypeModal">
          <div className="activity-change-modal-container">
            <div className="activity-change-modal-heading">
              <h1>Change Activity Type</h1>
              <p>Some Guidance on what this modal is for</p>
            </div>
            <div className="activity-change-type">
              <h3 className="current-activity">{currentActivity}</h3>
              <div className="activity-arrow">
                <DownArrow direction={"right"} size="12" />
              </div>
              <h3
                className={`future-activity ${
                  selectedActivity?.name.length > 0
                    ? "future-activity-selected"
                    : "future-activity-not-selected"
                }`}
              >
                {selectedActivity?.name.length > 0
                  ? selectedActivity.name
                  : "Select"}
              </h3>
            </div>
          </div>
          <div className="activitiy-table">
            {activityList.map(
              (item) =>
							//remove the current activity as well as the activity type id for activities that metrics arent ready for
                (item?.name !== currentActivity && item.activityTypeID !== 3 && item.activityTypeID !== 4) && (
                  <div
                    key={item.id}
                    id={item.id}
                    className={`activity-item ${
                      item?.name === selectedActivity?.name
                        ? "activity-item-selected"
                        : ""
                    }`}
                    onClick={() => handleClick(item?.name, item.id)}
                  >
                    {item?.name}
                  </div>
                )
            )}
          </div>
          <div className="activity-actions">
            <div className="activity-button" onClick={()=>submitActivityChange()}> 
              <Button
                value={"Confirm Change"}
                active={selectedActivity?.name.length > 0 ? true : false}
              />
            </div>
            <Dialog.Close
              style={{
                backgroundColor: "transparent",
                boxShadow: "none",
                border: "none",
                padding: "none",
                margin: "none",
              }}
            >
              <div className="activity-button" onClick={() => {close, setOpen(false)}}>
                <Button value={"Cancel Change"} warning={true} />
              </div>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ActivityChangeModal;
