import { motion } from "framer-motion";
import "../../../../src/components/common/table/table.scss";
import TableHeadItem from "../table/tableHeadItem/tableHeadItem";
import HomePageInnerTableRow from "./HomePageInnerTableRow";
import { useState } from "react";

const HomePageInnerTable = (props) => {
  const {
    header,
    headers,
    data,
    cls,
    goToPlayerProfilePage,
    goToOrganisationProfilePage,
    clickHandler,
    selectedRow,
    performanceType,
    timeframe,
    comparisonType,
    maxZScore,
    setMaxZScore,
  } = props;

  let filteredData = data;

  const [filteredDataState, setFilteredDataState] = useState([]);
  const [descending, setDescending] = useState(true);
  const [filteringByWhichIndex, setFilteringByWhichIndex] = useState(null)

  const arrayMappingKeys = ["4", "10", "14", "5", "load", "gait"];

  function filterDataHandle(index) {
    if (descending) {
      setFilteredDataState([
        ...filteredData.sort((a, b) =>
          a?.overview?.[arrayMappingKeys[index - 1]]?.[
            `${timeframe}${performanceType}`
          ] <
          b?.overview?.[arrayMappingKeys[index - 1]]?.[
            `${timeframe}${performanceType}`
          ]
            ? 1
            : -1
        ),
      ]);
    } else {
      setFilteredDataState([
        ...filteredData.sort((a, b) =>
          a?.overview?.[arrayMappingKeys[index - 1]]?.[
            `${timeframe}${performanceType}`
          ] >
          b?.overview?.[arrayMappingKeys[index - 1]]?.[
            `${timeframe}${performanceType}`
          ]
            ? 1
            : -1
        ),
      ]);
    }
    setDescending(!descending);
    setFilteringByWhichIndex(index-1)
  }

  return (
    <>
      <div className={`table-home table-container-global`}>
        {header}
        <motion.table className="table-a">
          <thead className="table-head">
            <tr>
              {headers.map((h, i) => {
                return (
                  <TableHeadItem
                    key={i}
                    index={i}
                    item={h}
                    filterDataHandle={filterDataHandle}
                    comparisonType={comparisonType}
                    filteringByWhichIndex = {filteringByWhichIndex+1}
                    isDescending = {descending}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody className={`${cls} table-general`}>
            <HomePageInnerTableRow
              data={
                filteredDataState.legnth > 0 ? filteredDataState : filteredData
              }
              goToPlayerProfilePage={goToPlayerProfilePage}
              goToOrganisationProfilePage={goToOrganisationProfilePage}
              clickHandler={clickHandler}
              selectedRow={selectedRow}
              performanceType={performanceType}
              timeframe={timeframe}
              comparisonType={comparisonType}
              maxZScore={maxZScore}
              setMaxZScore={setMaxZScore}
            />
          </tbody>
        </motion.table>
      </div>
    </>
  );
};

export default HomePageInnerTable;
