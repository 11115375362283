/* eslint-disable */
import {
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_ERROR_RANGE_COLOR,
  NEW_BOTH_FEET_COLOR,
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
} from "../../utils/constant";
import {
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ComposedChart,
  Bar,
  Cell,
} from "recharts";
import Tooltips from "components/common/toolTips/toolTips";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import moment from "moment";
import { useState } from "react";
import Toggler from "components/common/toggler/Toggler";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

export default function DailyLoad(props) {
  const style = { height: "500px", padding: "0px 45px 10px 0px" };
  const { data, handleLoadClick } = props;
  const [loadDisplayed, setLoadDisplayed] = useState("dailyLoad");
  const [loadToggle, setLoadToggle] = useState(false);

  const CustomTooltip = ({ active, payload, heading = "Heading" }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip-stance">
          <p className="tooltip-label">{heading}</p>

          <div>
            <p style={{ color: NEW_BOTH_FEET_COLOR }}>{`Date : ${moment(
              payload[0].payload.date
            ).format("DD/MM/YYYY")}`}</p>
            <p
              style={{ color: "white" }}
            >{`Duration : ${payload[0].payload.duration}`}</p>
            {!loadToggle && (
              <>
                <p
                  style={{ color: NEW_ERROR_RANGE_COLOR }}
                >{`Total Load : ${payload[0].payload.totalLoad.toFixed(2)}`}</p>
                <p
                  style={{ color: NEW_LEFT_COLOR }}
                >{`Left Load : ${payload[0].payload.totalLeftLoad.toFixed(
                  2
                )}`}</p>
                <p
                  style={{ color: NEW_RIGHT_COLOR }}
                >{`Right Load : ${payload[0].payload.totalRightLoad.toFixed(
                  2
                )}`}</p>
              </>
            )}

            {loadToggle && (
              <>
                <p
                  style={{ color: NEW_BOTH_FEET_COLOR }}
                >{`Total Relative Load : ${payload[0].payload.totalRelativeLoad.toFixed(
                  2
                )}`}</p>
              </>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  if (data.status === "loading")
    return (
      <>
        <div className="activity-overview-chart-container">
          <div>{displayLoadingCharts()}</div>
        </div>
      </>
    );

  function changeLoadDisplayed(e) {
    if (e) {
      if (props.data[0].totalRelativeLoad) {
        setLoadDisplayed("totalRelativeLoad");
      }
    } else {
      if (props.data[0].totalLoad) {
        setLoadDisplayed("totalLoad");
      }
    }
  }

  return (
    <div className={"column activity-overview "}>
      <div className="activity-overview-chart-container">
        <div
          className="player-profile-analytics-title load-title-flex"
          style={{
            margin: "15px 0 0 75px",
            padding: "20px 0 20px 0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <p
              style={{
                display: "flex",
                position: "relative",
                textTransform: "uppercase",
                margin: 0,
              }}
            >
              {!loadToggle ? "Daily Load" : "Total Relative Load"}
            </p>
            <div style={{ paddingLeft: "0.5rem" }}>
              <MainTooltip
                tooltipText={
                  !loadToggle
                    ? "Daily load represents to individuals physical effort throughout the day, either by individual limb or accumulative load."
                    : "Relative Load is daily load relative to the duration of the sessions collected that day."
                }
                tooltipIcon={"info"}
                iconSize={25}
              />
            </div>
          </div>

          <Toggler
            isToggled={loadToggle}
            setIsToggled={setLoadToggle}
            changeToggleDisplayed={changeLoadDisplayed}
            togglerHeadings={{ left: "Daily Load", right: "Relative Load" }}
          />
        </div>
        <div style={style}>
          <ResponsiveContainer>
            <ComposedChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid
                strokeDasharray="4 4"
                stroke={NEW_AXIS_COLOR}
                strokeWidth={1}
                opacity={0.4}
              />
              <XAxis
                dataKey="date"
                tick={{ fill: NEW_AXIS_TICK }}
                stroke={NEW_AXIS_COLOR}
                fontSize={".75rem"}
                reversed={false}
                tickFormatter={(unixTime) =>
                  moment(unixTime).format("DD/MM/YYYY")
                }
                type="number"
                domain={["auto", "auto"]}
              ></XAxis>

              <YAxis
                dataKey={!loadToggle ? "totalLoad" : "totalRelativeLoad"}
                label={{
                  value: !loadToggle
                    ? "Total Load (a.u.)"
                    : "Total Relative Load (a.u.)",
                  angle: -90,
                  position: "insideLeft",
                  fill: "white",
                  fontSize: "14px",
                }}
                tick={{ fill: NEW_AXIS_TICK }}
                stroke={NEW_AXIS_COLOR}
              ></YAxis>

              {!loadToggle && (
                <>
                  <Line
                    dataKey="totalLoad"
                    name="Total Daily Load"
                    stroke={NEW_BOTH_FEET_COLOR}
                    opacity={0.1}
                  />

                  <Bar
                    dataKey="totalLeftLoad"
                    stackId="a"
                    fill={NEW_LEFT_COLOR}
                    name="Left Load"
                    barSize={100}
                    onClick={(e) => {
                      handleLoadClick(e);
                    }}
                  />

                  <Bar
                    dataKey="totalRightLoad"
                    stackId="a"
                    fill={NEW_RIGHT_COLOR}
                    name="Right Load"
                    barSize={100}
                    onClick={(e) => {
                      handleLoadClick(e);
                    }}
                  />
                </>
              )}

              {loadToggle && (
                <>
                  <Bar
                    dataKey="totalRelativeLoad"
                    fill={NEW_BOTH_FEET_COLOR}
                    name="Total Relative Load"
                    barSize={100}
                  />
                </>
              )}

              {/* <Bar
                dataKey="totalLoad"
                stackId="b"
                fill={NEW_BOTH_FEET_COLOR}
                name="Total Load"
                barSize={100}
              /> */}

              <Legend
                verticalAlign="top"
                align="right"
                layout="horizontal"
                wrapperStyle={{ top: -20, fontSize: 11 }}
              />

              <Tooltip
                content={
                  <CustomTooltip heading="Load" />
                }
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
