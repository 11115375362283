/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";


const ReuseableInfiniteScrollingTable = (props) => {
  const {
    dataToDisplay = [],
    fullDataList,
    searchList,
    dataListStatus,
    headingComponet,
		rowComponent,
  } = props;


  //state declaration
  const [listItems, setListItems] = useState(
    Array.from(dataToDisplay.slice(0, 20))
  );
  const [hasMore, setHasMore] = useState(true);

  const { currentSearchTerm } = useSelector((state) => state.playerActivity);

  useEffect(() => {
    setListItems(Array.from(dataToDisplay.slice(0, 20)));
    setHasMore(dataToDisplay.length > 20);
  }, [dataToDisplay]);

  function fetchMoreListItems() {
    if (listItems.length >= dataToDisplay.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setListItems((prevState) => [
        ...prevState,
        ...dataToDisplay.slice(prevState.length, prevState.length + 20),
      ]);
    }, 200);
  }

  if (dataListStatus === "idle" || dataListStatus === "loading") {
    return (
      <Spinner cls="spinner-user-profile" classnames={"user-profile-loader"} />
    );
  }

  // check if there are no records to display
  if (
    searchList?.length === 0 ||
    (fullDataList?.length === 0 && currentSearchTerm?.length < 1)
  ) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.6,
          delay: 0.1 * 1,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ marginBottom: "0.5rem" }}
      >
        <div className="record-not-found" style={{ paddingTop: "6rem" }}>
          <h1>No Records Found</h1>
        </div>
      </motion.div>
    );
  }

	return (
		<>
			{headingComponet}

			<InfiniteScroll
        dataLength={listItems.length}
        next={fetchMoreListItems}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        height={"60vh"}
      >
			{rowComponent(listItems)}
			</InfiniteScroll>
		</>
	)
};

export default React.memo(ReuseableInfiniteScrollingTable);
