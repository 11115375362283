import { useState } from "react";
import { useValidation } from "./useValidation";
import { postFile } from "api/apiHelper";

export function useAddPlayer() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newPlayerID, setNewPlayerID] = useState(-1);
  const [errors, validateInputs] = useValidation(); // use the useValidation hook and destructure the validateInputs function
  const [submissionStatus, setSubmissionStatus] = useState(false);

  async function addPlayer(state, props, dispatch) {
    const { actions } = props;
    setIsSubmitting(true);

    // call the validateInputs function and use the returned errors
    const formIsValid = validateInputs(state, dispatch);
    if (!formIsValid) {
      setIsSubmitting(false);
      setErrorMessage(errors.error);
      return;
    }
    try {
      const {
        playerID,
        iconUrl,
        userName,
        emailOne,
        selectedOrg,
        gender,
        age,
        height,
        weight,
        shoesize,
        footlength,
        footwidth,
        pod,
        dob,
      } = state;
      const { user } = props;

      let player = {
        name: userName,
        gender,
        email: emailOne,
        age: parseInt(age) || 0,
        height: parseInt(height) || 0,
        weight: parseInt(weight) || 0,
        shoeSize: parseInt(shoesize) || 0,
        footLength: parseInt(footlength) || 0,
        footWidth: parseInt(footwidth) || 0,
        pod: parseInt(pod) || 0,
        orgID: parseInt(selectedOrg) || 0,
        dob: dob,
      };

      if (playerID > 0) {
        //this is an edit not a create!!
        player.id = playerID;
        player.iconUrl = iconUrl || "";
      }

      const payload = { newPlayer: player, userid: user.id };
      const res = await actions.setPlayerDetails(payload);

      if (res.payload.id > 0) {
        const { file } = state;
        if (file) {
          await addIcon(res.payload.id, actions, state, user.id, dispatch);
        } else if (!(playerID > 0)) {
          //create player  - so redirect to player list
          await actions.getOrganisationsToolkit(user.id);
          setNewPlayerID(res.payload.id);
          dispatch({
            type: "updateState",
            payload: { updateSuccess: true, playerID: res.payload.id },
          });
        }
      } else if (res.error) {
        setErrorMessage("Error updating details");
        dispatch({
          type: "updateState",
          payload: { errors: { formErrors: true, error: errorMessage } },
        });
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Error updating details");
      dispatch({
        type: "updateState",
        payload: {
          errors: { formErrors: true, error: errorMessage },
          playerCreated: false,
          newPlayerID: -1,
        },
      });
    }

    setIsSubmitting(false);
    setSubmissionStatus(true);

    setTimeout(() => {
      setSubmissionStatus(false);
    }, 3500);
  }

  async function addIcon(thisPlayerID, actions, state, userID, dispatch) {
    const { file } = state;

    let url = `/player/addImage/${thisPlayerID}`;

    postFile(url, file).then((res) => {
      if (res.data.result > 0) {
        //success
        actions.getOrganisationsToolkit(userID);
        dispatch({ type: "updateState", payload: { updateSuccess: true } });
      } else {
        setErrorMessage("Error uploading Image");
        console.log("error uploading image");
      }
    });
  }

  async function resetSumbissionStatus() {
    setSubmissionStatus(false);
  }

  return [
    isSubmitting,
    errorMessage,
    newPlayerID,
    submissionStatus,
    addPlayer,
    resetSumbissionStatus,
  ];
}
