import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getActivitiesAPI } from '../api/activities';
import { resetState } from './resetSlice';
const initialState = {
  activities: [],
  status: 'idle',
};

export const getActivities = createAsyncThunk(
  'activities/getActivities',
  async () => {
    const response = await getActivitiesAPI();
    return response.data;
  }
);

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivities.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getActivities.fulfilled, (state, action) => {
      state.activities = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(getActivities.rejected, (state) => {
      state.status = 'failed';
    })
    .addCase(resetState, () => {
      return initialState;
    });
  },
});

export const selectActivityList = (state) => state.activity;

export default activitySlice.reducer;
