import ResetPassForm from './ResetPassForm';

const ResetPasswordPage = () => (
  <div className="loginContainer">

          <div className="left_login" >
                    {/* <div className="left_danu_logo" /> */}
          </div>

          <div className="right_login">
                    <ResetPassForm />
          </div>
  </div>
);

export default ResetPasswordPage;
