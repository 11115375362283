/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addTeamGroupAPI,
  deleteTeamGroupAPI,
  getTeamGroupListAPI,
} from "api/teams";
import { resetState } from "./resetSlice";
import { getTeams } from "./teamSlice";
import { removeDuplicates } from "utils/helper";

const initialState = {
  groups: [],
  groupStatus: "idle",
};


export const getTeamGroups = createAsyncThunk(
  "groups/getTeamGroups",
  async (teamID) => {
    try {
      const response = await getTeamGroupListAPI(teamID);
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteGroup = createAsyncThunk(
  "groups/deleteGroups",
  async ({groupID, teamID}, { dispatch }) => {
    try {
      const response = await deleteTeamGroupAPI(groupID);
      await dispatch(getTeamGroups(teamID));
      await dispatch(getTeams())
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const addTeamGroup = createAsyncThunk(
  "groups/addTeamGroup",
  async (postBody, { dispatch }) => {
    try {
      const response = await addTeamGroupAPI(postBody);
      await dispatch(getTeamGroups(postBody.teamid));
      return await response.data;
    } catch (error) {
      return await error;
    }
  }
);

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    resetTeamGroupData: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTeamGroups.pending, (state) => {
      state.groupStatus = "loading";
    });
    builder.addCase(getTeamGroups.fulfilled, (state, action) => {
      state.groups = removeDuplicates([...state.groups, ...action.payload]);
      state.groupStatus = "succeeded";
    });
    builder.addCase(deleteGroup.fulfilled, () => {
      return initialState;
    })
    builder
      .addCase(getTeamGroups.rejected, (state) => {
        state.groupStatus = "failed";
      })
      .addCase(resetState, () => {
        return initialState;
      });
  },
});

export const {resetTeamGroupData} = groupSlice.actions

export const selectedGroupList = (state) => state.group;

export default groupSlice.reducer;

