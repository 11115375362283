/* eslint-disable */
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Legend,
  Tooltip,
  BarChart,
  Cell,
  ReferenceLine,
  ReferenceArea,
  Dot,
} from "recharts";
import {
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_BOTH_FEET_COLOR,
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_HIGH_VALUE,
  NEW_LOW_VALUE,
  RIGHT_SWING_COLOR,
} from "utils/constant";
import DownIcon from "components/common/icons/downIcon";
import Toggler from "components/common/toggler/Toggler";
import "./athleteOverviewChart.scss";
import MainTooltip from "components/common/mainTooltip/MainTooltip";
import { useState } from "react";
import Polygon from "components/common/icons/downArrow";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSessionId, setGraphModal } from "store/playerActivitySlice";

const AthleteOverviewChart = (props) => {
  const {
    chartTitle,
    chartTittleToggled,
    chartData = [],
    isMetric,
    primaryKey,
    primaryKeyToggled,
    secondaryKeys,
    bilateral,
    hasToggler,
    togglerKeys,
    tooltip,
    tooltipToggled,
    chartAxisValueLabel,
    chartAxisValueLabelToggled,
    isLoading,
    chartType,
    xAxisKey,
    handleChartElementClick,
    ChartCustomTooltip,
    hasSideToggler = false,
    tooltipLeft,
    tooltipRight,
    tooltipValue,
    maxMinAvg,
    measurementUnit,
    selectedTags,
    handleTagClick,
  } = props;

  const [graphToggled, setGraphToggled] = useState(true);
  const [toggleGraph, setToggleGraph] = useState(false);
  const [toggleGraphTable, setToggleGraphTable] = useState(false);
  const [sessionSelectedForGraphTable, setSessionSelectedForGraphTable] =
    useState({});

  const maxValue = Math.max(...chartData.map((item) => item.value));

  const dispatch = useDispatch();
  const { graphModal } = useSelector((state) => state.playerActivity);

  const handleTableToggle = () => {
    setToggleGraphTable((prevData) => !prevData);
    setSessionSelectedForGraphTable({});
  };

  const convertToEpochTime = (dataArray) => {
    return dataArray.map((item) => {
      const { TSCreated, ...rest } = item;
      return {
        ...rest,
        TSCreated: new Date(TSCreated).getTime(), // Convert to Epoch time in seconds
      };
    });
  };

  // calculate percentage difference between two numbers
  const percentageDifference = (num1, num2) => {
    const difference = Math.abs(num1 - num2);
    const average = (num1 + num2) / 2;
    const percentDifference = (difference / average) * 100;

    return percentDifference.toFixed(1);
  };

  const modifiedDataDate = convertToEpochTime(chartData);

  const displayAsymmLegend = (data) => {
    return (
      <div className="metric-legend-horizontal" style={{ height: "100%" }}>
        <ResponsiveContainer>
          <ComposedChart
            height={200}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {/*Start of Placeholder lines used to label legend*/}
            <Line
              name="Average Asymmetry"
              dataKey="null123"
              stroke={NEW_AXIS_TICK}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="plainline"
            />

            <Line
              name="Left Foot"
              dataKey="null123"
              stroke={NEW_LEFT_COLOR}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="square"
            />
            <Line
              name="Right Foot"
              dataKey="null123"
              stroke={NEW_RIGHT_COLOR}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="square"
            />
            {/*End of Placeholder lines used to label legend*/}

            <Legend verticalAlign="top" align="right" layout="horizontal" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const chartXdomainScale = (arr) => {
    let newArr = arr?.map((item) =>
      item.value < 0 ? item.value * -1 : item.value
    );

    return Math.max(...newArr) < 25
      ? [-25, 25]
      : Math.max(...newArr) >= 25 && Math.max(...newArr) < 50
      ? [-50, 50]
      : Math.max(...newArr) >= 50 && Math.max(...newArr) < 75
      ? [-75, 75]
      : [-100, 100];
  };

  const displayLegend = (data) => {
    return (
      <div className="metric-legend-horizontal" style={{ height: "100%" }}>
        <ResponsiveContainer>
          <ComposedChart
            height={200}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {/*Start of Placeholder lines used to label legend*/}
            <Line
              name="95% Confidence Interval"
              dataKey="null123"
              stroke={RIGHT_SWING_COLOR}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="plainline"
            />
            {bilateral === true ? (
              <Line
                name={tooltipValue}
                dataKey="null123"
                stroke={NEW_RIGHT_COLOR}
                strokeDasharray="5 5"
                opacity={0.1}
                legendType="square"
              />
            ) : (
              <>
                <Line
                  name={"Right " + tooltipValue}
                  dataKey="null123"
                  stroke={NEW_RIGHT_COLOR}
                  strokeDasharray="5 5"
                  opacity={0.1}
                  legendType="square"
                />
                <Line
                  name={"Left " + tooltipValue}
                  dataKey="null123"
                  stroke={NEW_LEFT_COLOR}
                  strokeDasharray="5 5"
                  opacity={0.1}
                  legendType="square"
                />
              </>
            )}
            {/*End of Placeholder lines used to label legend*/}

            <Legend verticalAlign="top" align="right" layout="horizontal" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const displayCorrectAverage = (chartData) => {
    const averageValue = Number(
      (
        modifiedDataDate
          .map((item) => item.value)
          .reduce((sum, currentValue) => sum + currentValue, 0) /
        modifiedDataDate.length
      ).toFixed(1)
    );
    const latestValue = chartData[0].value;
    return (
      <div className="overview_asym_chart_detail">
        <div className="overview_asym_chart_value">
          <div className="value_title">
            <h3>Average</h3>
          </div>
          <div className="value_span_container">
            {averageValue + ` ${measurementUnit}`}
          </div>
          <span
            style={{
              color:
                latestValue > averageValue ? NEW_HIGH_VALUE : NEW_LOW_VALUE,
            }}
          >
            <Polygon
              direction={latestValue > averageValue ? "up" : "down"}
              color={
                latestValue > averageValue ? NEW_HIGH_VALUE : NEW_LOW_VALUE
              }
            />
            {latestValue > averageValue
              ? `${percentageDifference(averageValue, latestValue)} %`
              : ` ${percentageDifference(averageValue, latestValue)} %`}
            <span>
              <MainTooltip
                tooltipText={`The latest session recorded is ${percentageDifference(
                  averageValue,
                  latestValue
                )}% ${
                  latestValue > averageValue ? "higher" : "lower"
                } than average.`}
                tooltipIcon={"info"}
                iconSize={20}
              />
            </span>
          </span>
        </div>
      </div>
    );
  };

  const displayCorrectAsymmetryAverage = (modifiedDataDate) => {
    const averageAsym = Number(
      (
        modifiedDataDate
          .map((item) => item.value)
          .reduce((sum, currentValue) => sum + currentValue, 0) /
        modifiedDataDate.length
      ).toFixed(1)
    );
    return (
      <div className="overview_asym_chart_detail">
        <div className="overview_asym_chart_value">
          <div className="value_title">
            <h3>Average</h3>
          </div>
          <div className="value_span_container">
            <span
              style={{
                color: averageAsym > 0 ? NEW_RIGHT_COLOR : NEW_LEFT_COLOR,
              }}
            >
              {averageAsym > 0 ? "R" : "L"}
            </span>
            {averageAsym > 0 ? averageAsym : averageAsym * -1}%
            <span>
              <MainTooltip
                tooltipText={`Average Asymmetry biased to the ${
                  averageAsym > 0 ? "right" : "left"
                }`}
                tooltipIcon={"info"}
                iconSize={20}
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const getStandardDeviationForReferenceArea = (arr, scale) => {
    let array = arr.map((item) => item.value);

    const n = array.length;
    const mean = array.reduce((a, b) => a + b) / n;
    const standardDev = Math.sqrt(
      array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
    );

    const multiple = 1.96;

    if (scale === "lower") {
      return mean - standardDev * multiple < 0
        ? 0
        : mean - standardDev * multiple;
    }

    if (scale === "higher") {
      return mean + standardDev * multiple;
    }
  };

  const customDot = (props) => {
    const { cx, cy, value, payload, dataKey } = props;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={4} // Example: size changes based on the value
        stroke="white"
        fill={(() => {
          try {
            const tags = JSON.parse(payload.tags);
            if (!Array.isArray(tags))
              return dataKey !== "rightValue"
                ? NEW_RIGHT_COLOR
                : NEW_LEFT_COLOR;

            const matchingTag = tags.filter((item) =>
              selectedTags.includes(item.TID)
            )[0];

            return matchingTag
              ? NEW_BOTH_FEET_COLOR
              : dataKey !== "rightValue"
              ? NEW_RIGHT_COLOR
              : NEW_LEFT_COLOR;
          } catch (e) {
            // Handle error if JSON.parse fails
            console.error("Invalid JSON in payload.tags:", e);
            return dataKey !== "rightValue" ? NEW_RIGHT_COLOR : NEW_LEFT_COLOR;
          }
        })()} // Example: color changes based on the value
      />
    );
  };

  const customDotActive = (props) => {
    const { cx, cy, value, payload, dataKey } = props;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={4} // Example: size changes based on the value
        stroke="none"
        fill={(() => {
          try {
            const tags = JSON.parse(payload.tags);
            if (!Array.isArray(tags))
              return dataKey !== "rightValue"
                ? NEW_RIGHT_COLOR
                : NEW_LEFT_COLOR;

            const matchingTag = tags.filter((item) =>
              selectedTags.includes(item.TID)
            )[0];

            return matchingTag
              ? NEW_BOTH_FEET_COLOR
              : dataKey !== "rightValue"
              ? NEW_RIGHT_COLOR
              : NEW_LEFT_COLOR;
          } catch (e) {
            // Handle error if JSON.parse fails
            console.error("Invalid JSON in payload.tags:", e);
            return dataKey !== "rightValue" ? NEW_RIGHT_COLOR : NEW_LEFT_COLOR;
          }
        })()} // Example: color changes based on the value
      />
    );
  };

  function handleClick(clickPayload) {
    const payload = clickPayload?.activePayload[0].payload;
    if (payload) {
      if (Object.keys(payload).length !== 0 && toggleGraphTable === false) {
        const sessionID = payload.sessionID;
        dispatch(setSelectedSessionId(sessionID));
        dispatch(setGraphModal(!graphModal));
      }
      if (Object.keys(payload).length !== 0 && toggleGraphTable === true) {
        setSessionSelectedForGraphTable(payload);
      }
    }
  }

  return !isLoading ? (
    <div className={`athlete_overview_chart_container`}>
      <div className="athlete_overview_chart_head">
        <div className="athlete_overview_chart_heading">
          <h2>{!toggleGraph ? chartTitle : chartTittleToggled}</h2>
          <MainTooltip tooltipText={!toggleGraph ? tooltip : tooltipToggled} />
        </div>
        <div className="athlete_overview_chart_heading_end">
          {hasToggler ? (
            <Toggler
              isToggled={toggleGraph}
              setIsToggled={setToggleGraph}
              changeToggleDisplayed={() => {
                console.log("toggled");
              }}
              togglerHeadings={{
                left: togglerKeys[0],
                right: togglerKeys[1],
              }}
            />
          ) : (
            ""
          )}
          <div
            onClick={() => setGraphToggled((prev) => !prev)}
            className={`overview_table_toggler ${
              graphToggled
                ? "overview_table_toggler_open"
                : "overview_table_toggler_closed"
            } `}
          >
            <Polygon direction={"down"} />
          </div>
        </div>
      </div>
      <div
        className={`athlete_overview_chart ${
          !graphToggled ? "athlete_overview_chart_off" : ""
        }`}
      >
        <div className="overview_chart">
          {chartType === "bar" ? (
            <ResponsiveContainer>
              <ComposedChart
                data={modifiedDataDate}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid
                  strokeDasharray="4 4"
                  stroke={NEW_AXIS_COLOR}
                  strokeWidth={1}
                  opacity={0.4}
                />
                <XAxis
                  dataKey={xAxisKey}
                  tick={{ fill: NEW_AXIS_TICK }}
                  stroke={NEW_AXIS_COLOR}
                  fontSize={".75rem"}
                  reversed={false}
                  tickFormatter={(date) => moment(date).format("DD/MM/YYYY")}
                  type="number"
                  domain={["auto", "auto"]}
                />

                <YAxis
                  dataKey={!toggleGraph ? primaryKey : primaryKeyToggled}
                  label={{
                    value: !toggleGraph
                      ? chartAxisValueLabel
                      : chartAxisValueLabelToggled,
                    angle: -90,
                    position: "insideLeft",
                    fill: "white",
                    fontSize: "14px",
                  }}
                  tick={{ fill: NEW_AXIS_TICK }}
                  stroke={NEW_AXIS_COLOR}
                />

                {!toggleGraph && (
                  <>
                    <Line
                      dataKey={primaryKey}
                      name="Total Daily Load"
                      stroke={NEW_BOTH_FEET_COLOR}
                      opacity={0.3}
                    />
                    <Bar
                      dataKey={secondaryKeys[0]}
                      stackId="a"
                      fill={NEW_LEFT_COLOR}
                      name="Left Load"
                      barSize={100}
                      onClick={(e) => {
                        handleChartElementClick(e);
                      }}
                    />

                    <Bar
                      dataKey={secondaryKeys[1]}
                      stackId="a"
                      fill={NEW_RIGHT_COLOR}
                      name="Right Load"
                      barSize={100}
                      onClick={(e) => {
                        handleChartElementClick(e);
                      }}
                    />
                  </>
                )}

                {toggleGraph && (
                  <>
                    <Bar
                      dataKey={primaryKeyToggled}
                      fill={NEW_BOTH_FEET_COLOR}
                      name="Total Relative Load"
                      barSize={100}
                      onClick={(e) => {
                        handleChartElementClick(e);
                      }}
                    />
                  </>
                )}
                <Legend
                  verticalAlign="top"
                  align="right"
                  layout="horizontal"
                  wrapperStyle={{ top: 0, fontSize: 11 }}
                />

                <Tooltip
                  content={
                    <ChartCustomTooltip
                      heading={!toggleGraph ? chartTitle : chartTittleToggled}
                      toggle={toggleGraph}
                    />
                  }
                />
              </ComposedChart>
            </ResponsiveContainer>
          ) : null}

          {chartType === "line" ? (
            <div className="athlete_overview_line_chart">
              {displayCorrectAverage(modifiedDataDate)}
              <div className="legend" style={{ height: "25px", width: "100%" }}>
                {displayLegend(modifiedDataDate)}
              </div>
              <div style={{ height: "82%", width: "100%" }}>
                <ResponsiveContainer>
                  <ComposedChart
                    data={modifiedDataDate}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                    onClick={(value) => {
                      maxMinAvg !== "avg"
                        ? handleClick(value)
                        : console.log("clicked");
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="4 4"
                      stroke={NEW_AXIS_COLOR}
                      strokeWidth={1}
                      opacity={0.4}
                    />
                    <XAxis
                      dataKey={xAxisKey}
                      tick={{ fill: NEW_AXIS_TICK }}
                      stroke={NEW_AXIS_COLOR}
                      fontSize={".75rem"}
                      reversed={false}
                      tickFormatter={(date) =>
                        moment(date).format("DD/MM/YYYY")
                      }
                      type="number"
                      domain={["auto", "auto"]}
                    />
                    <YAxis
                      dataKey={
                        bilateral === true ? primaryKey : secondaryKeys[0]
                      }
                      label={{
                        value: !toggleGraph
                          ? chartAxisValueLabel
                          : chartAxisValueLabelToggled,
                        angle: -90,
                        position: "insideLeft",
                        fill: "white",
                        fontSize: "12px",
                        style: { textAnchor: "middle" },
                      }}
                      tick={{ fill: NEW_AXIS_TICK }}
                      tickFormatter={(value) => value.toFixed(2)}
                      stroke={NEW_AXIS_COLOR}
                      domain={
                        bilateral === true
                          ? [
                              0,
                              Math.round(maxValue) +
                                (Math.round(maxValue) / 100) * 40,
                            ]
                          : [0, Math.round(maxValue) + (maxValue / 100) * 120]
                      }
                    />

                    <ReferenceArea
                      y1={getStandardDeviationForReferenceArea(
                        modifiedDataDate,
                        "higher"
                      )}
                      y2={getStandardDeviationForReferenceArea(
                        modifiedDataDate,
                        "lower"
                      )}
                      opacity={0.2}
                      strokeDasharray="5 5"
                      stroke="white"
                    />

                    {bilateral === true ? (
                      <Line
                        name="Right Foot"
                        dataKey="value"
                        stroke={NEW_RIGHT_COLOR}
                        opacity={1}
                        legendType="square"
                        // dot={{stroke: "white", strokeWidth: 2, onClick: (e)=>console.log(e)}}
                        dot={customDot}
                        activeDot={customDot}
                      />
                    ) : (
                      <>
                        <Line
                          name="Right Foot"
                          dataKey={secondaryKeys[0]}
                          stroke={NEW_RIGHT_COLOR}
                          opacity={1}
                          legendType="square"
                          // dot={{stroke: "white", strokeWidth: 2, onClick: (e)=>console.log(e)}}
                          dot={customDot}
                          activeDot={customDot}
                        />
                        <Line
                          name="Right Foot"
                          dataKey={secondaryKeys[1]}
                          stroke={NEW_LEFT_COLOR}
                          opacity={1}
                          legendType="square"
                          // dot={{stroke: "white", strokeWidth: 2, onClick: (e)=>console.log(e)}}
                          dot={customDot}
                          activeDot={customDotActive}
                        />
                      </>
                    )}

                    <Tooltip
                      content={<ChartCustomTooltip heading={tooltipValue} />}
                      cursor={{ fill: NEW_AXIS_COLOR, opacity: ".3" }}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          ) : null}

          {chartType === "asym-bar" ? (
            <div className="athlete_overview_line_chart">
              {displayCorrectAsymmetryAverage(modifiedDataDate)}
              <div className="legend" style={{ height: "25px", width: "100%" }}>
                {displayAsymmLegend(modifiedDataDate)}
              </div>
              <div style={{ height: "80%", width: "100%" }}>
                <ResponsiveContainer>
                  <BarChart
                    data={modifiedDataDate}
                    layout="vertical"
                    onClick={(value) => {
                      if (maxMinAvg !== "avg") {
                        handleClick(value);
                      }
                    }}
                  >
                    <XAxis
                      type="number"
                      domain={chartXdomainScale(modifiedDataDate)}
                      tick={{ fill: NEW_AXIS_TICK }}
                      stroke={NEW_AXIS_COLOR}
                      tickCount={11}
                      label={{
                        value: chartAxisValueLabel,
                        offset: -3,
                        position: "insideBottom",
                        fill: NEW_AXIS_TICK,
                      }}
                    />
                    <YAxis
                      dataKey={xAxisKey}
                      tickFormatter={(date) =>
                        moment(date).format("MMM DD YYYY")
                      }
                      type="category"
                      tick={{ fill: NEW_AXIS_TICK, fontSize: "10px" }}
                      stroke={NEW_AXIS_COLOR}
                    />
                    <CartesianGrid
                      strokeDasharray="4 4"
                      stroke={NEW_AXIS_COLOR}
                      strokeWidth={1}
                      opacity={0.4}
                    />

                    <ReferenceLine
                      x={
                        modifiedDataDate
                          .map((item) => item.value)
                          .reduce(
                            (sum, currentValue) => sum + currentValue,
                            0
                          ) / modifiedDataDate.length
                      }
                      stroke={NEW_AXIS_TICK}
                      dot={false}
                      strokeDasharray="5 5"
                      name="Averege Asymmetry"
                    />

                    <ReferenceLine
                      x={0}
                      stroke={NEW_AXIS_COLOR}
                      dot={false}
                      strokeDasharray="5 5"
                      name="Averege Asymmetry"
                    />

                    <Tooltip
                      content={<ChartCustomTooltip heading={tooltipValue} />}
                      cursor={{ fill: NEW_AXIS_COLOR, opacity: ".3" }}
                    />

                    <Bar dataKey={primaryKey} legendType="none">
                      {modifiedDataDate.length
                        ? modifiedDataDate.map((entry, index) => (
                            <Cell
                              key={`groundContact-asym-${index}`}
                              fill={
                                entry[primaryKey] < 0
                                  ? NEW_LEFT_COLOR
                                  : NEW_RIGHT_COLOR
                              }
                              stroke={
                                maxMinAvg !== "avg"
                                  ? (() => {
                                      try {
                                        const tags = JSON.parse(entry.tags);
                                        if (!Array.isArray(tags)) return "";

                                        const matchingTag = tags.filter(
                                          (item) =>
                                            selectedTags.includes(item.TID)
                                        )[0];

                                        return matchingTag
                                          ? NEW_BOTH_FEET_COLOR
                                          : "";
                                      } catch (e) {
                                        // Handle error if JSON.parse fails
                                        console.error(
                                          "Invalid JSON in entry.tags:",
                                          e
                                        );
                                        return "";
                                      }
                                    })()
                                  : ""
                              }
                              strokeWidth={2}
                              opacity={0.7}
                              legendType="none"
                            />
                          ))
                        : modifiedDataDate.map((entry, index) => (
                            <Cell
                              key={`groundContact-asym-${index}`}
                              fill={
                                entry[primaryKey] < 0
                                  ? NEW_LEFT_COLOR
                                  : NEW_RIGHT_COLOR
                              }
                              stroke={
                                maxMinAvg !== "avg"
                                  ? (() => {
                                      try {
                                        const tags = JSON.parse(entry.tags);
                                        if (!Array.isArray(tags)) return "";

                                        const matchingTag = tags.filter(
                                          (item) =>
                                            selectedTags.includes(item.TID)
                                        )[0];

                                        return matchingTag
                                          ? NEW_BOTH_FEET_COLOR
                                          : "";
                                      } catch (e) {
                                        // Handle error if JSON.parse fails
                                        console.error(
                                          "Invalid JSON in entry.tags:",
                                          e
                                        );
                                        return "";
                                      }
                                    })()
                                  : ""
                              }
                              strokeWidth={2}
                              opacity={0.7}
                              legendType="none"
                            />
                          ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          ) : null}
        </div>

        {hasSideToggler ? (
          <div
            className={`overview_chart_table_container ${
              toggleGraphTable
                ? "overview_chart_table_container_open"
                : "overview_chart_table_container_closed"
            }`}
          >
            <div
              className={`overview_chart_table_toggler_container`}
              onClick={() => handleTableToggle()}
            >
              <div
                className={`overview_chart_table_toggler ${
                  !toggleGraphTable ? "overview_chart_table_toggler_closed" : ""
                }`}
              >
                <Polygon direction={"down"} />
              </div>
            </div>
            <div className="overview_chart_table">
              <div className="overview_chart_table_metric_values_container">
                <div className="metric_values_container_heading">
                  <h4>
                    {moment(sessionSelectedForGraphTable.TSCreated).format(
                      "MMM DD YYYY"
                    )}
                  </h4>
                  <p>Session Summary</p>
                </div>
                {Object.keys(sessionSelectedForGraphTable).length !== 0 ? (
                  <div className="metric_value_container">
                    {maxMinAvg !== "avg" ? (
                      <div className="value_format">
                        <div className="value_label">Session ID:</div>
                        <div className="value_value">
                          {sessionSelectedForGraphTable.sessionID}
                        </div>
                      </div>
                    ) : null}
                    <div className="value_format">
                      <div className="value_label">{tooltipValue}:</div>
                      <div className="value_value">
                        {sessionSelectedForGraphTable[primaryKey]}
                      </div>
                    </div>
                    <div className="value_format">
                      <div className="value_label">{tooltipLeft}:</div>
                      <div className="value_value">
                        {sessionSelectedForGraphTable[secondaryKeys[0]]}
                      </div>
                    </div>
                    <div className="value_format">
                      <div className="value_label">{tooltipRight}:</div>
                      <div className="value_value">
                        {sessionSelectedForGraphTable[secondaryKeys[1]]}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="metric_value_container">
                    <div className="value_format">
                      {maxMinAvg !== "avg"
                        ? "Please Select a session from the graph on the left"
                        : "Averaged session cannot be currently interacted with in this section. (Work in Progress)"}
                    </div>
                  </div>
                )}
              </div>
              <div className="overview_chart_table_tags_container">
                <h5>Tags</h5>
                <div className="overview_table_tags_container">
                  {Object.keys(sessionSelectedForGraphTable).length !== 0 ? (
                    JSON.parse(sessionSelectedForGraphTable?.tags).length >
                    0 ? (
                      JSON.parse(sessionSelectedForGraphTable?.tags).map(
                        (item) => (
                          //TID: 82, tag: "TagName"
                          <div
                            className={`tag ${
                              selectedTags.includes(item.TID)
                                ? "tag-selected"
                                : ""
                            }`}
                            onClick={() => handleTagClick(item.TID)}
                          >
                            {item.tag}
                          </div>
                        )
                      )
                    ) : (
                      <p className="no_tags_message">
                        {maxMinAvg !== "avg"
                          ? " This Session does not have any tags assigned to it"
                          : " Averaged session cannot be currently interacted with in this section. (Work in Progress)"}
                      </p>
                    )
                  ) : (
                    <p className="session_not_selected_message">
                      {maxMinAvg !== "avg"
                        ? " Please Select a session from the graph on the left"
                        : " Averaged session cannot be currently interacted with in this section. (Work in Progress)"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    <div
      className={`athlete_overview_chart_container ${
        isLoading ? "shimmer" : ""
      }`}
    >
      <div
        className={`athlete_overview_chart ${
          !graphToggled ? "athlete_overview_chart_off" : ""
        }`}
      >
        <div className="overview_chart"></div>
      </div>
    </div>
  );
};

export default AthleteOverviewChart;
