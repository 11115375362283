import { Provider } from 'react-redux';
import AppContainer from "./AppContainer";
import { BrowserRouter as Router } from "react-router-dom";

const Root = ({ store, history }) => (
  <Provider store={store}>

      <Router history={history}>
              <AppContainer />
      </Router>
  </Provider>
);

export default Root;