import TableHeadItem from "./tableHeadItem/tableHeadItem";
import TableRow from "./tableRow/tableRow";
import { motion } from "framer-motion";
import "./table.scss";

const Table = ({
  deactivateRow,
  headers,
  data,
  tableWidth,
  header = null,
  cls,
  height,
  link,
  currentPage,
  pagination,
  value,
  tableType,
  handleHover,
  ...fields
}) => {
  return (
    <>
      <div className={`${tableWidth} table-container-global`}>
        {header}
        <motion.table className="table-a">
          <thead className="table-head">
            <tr>
              {headers.map((h, i) => {
                return (
                  <TableHeadItem
                    key={i}
                    item={h}
                    length={data && data.length}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody className={`${cls} table-general`}>
            <TableRow
              deactivateRow={deactivateRow}
              handleHover={handleHover}
              tableType={tableType}
              pagination={pagination}
              value={value}
              link={link}
              currentPage={currentPage}
              heights={height}
              data={data}
              {...fields}
            />
          </tbody>
        </motion.table>
      </div>
    </>
  );
};

export default Table;
