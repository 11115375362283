/* eslint-disable */
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectOrganisation, getOrganisationsToolkit } from "store/orgSlice";
import { useState } from "react";
import { displayDate } from "../../utils/helper";
import Button from "components/common/button/button";
import {
  getListOfPods,
  selectPodList,
  removePod,
  selectAdminList,
} from "store/adminSlice";
import { useEffect, useMemo } from "react";
import "../../styles/pods2.scss";
import DialogDemo from "components/common/modalBox/modalBox";
import AlertDialogDemo from "components/common/alertDialog/alertDialog";
import { motion } from "framer-motion";
import ReuseableInfiniteScrollingTable from "../infiniteTable/ReuseableTable";
import { nanoid } from "@reduxjs/toolkit";
import { registerPod, removePodAPI } from "api/admin";
import MainButton from "components/common/reuseableButtonComponent/MainButton";

function PodList(props) {
  //modal state declared
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [deactivateRow, setDeactivateRow] = useState("");
  const [modal, setModal] = useState(false);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");


  //pods state
  const [pods, setPods] = useState([]);

  const hasOrganisations = useMemo(
    () => props.organisation.orgList.length > 0,
    [props.organisation.orgList]
  );


  // useMemo for filteredPods
  const filteredPods = useMemo(() => {
    if (!props.podList || !currentSearchTerm) {
      return props.podList;
    }

    return props.podList.filter((row) => {
      return (
        row.orgName.toLowerCase().includes(currentSearchTerm.toLowerCase()) ||
        row.ble.toLowerCase().includes(currentSearchTerm.toLowerCase())
      );
    });
  }, [currentSearchTerm, props.podList]);

  // useEffect to get list of pods and update state
  useEffect(() => {
    if (
      props.podList?.length === 0 &&
      props.adminList.status !== "succeeded" &&
      props.adminList.status !== "loading"
    ) {
      props.actions.getListOfPods();
    }

    loadItemsToDisplay();
  }, [props.podList, props.adminList.status, filteredPods, currentSearchTerm]);

  // useEffect to get organisations
  useEffect(() => {
    if (!hasOrganisations) {
      props.actions.getOrganisationsToolkit();
    }
  }, [hasOrganisations]);

  //responsible for rerendering the podlist orglist whenever the data changes
  useEffect(()=> {
    props.actions.getListOfPods();
  },[props.organisation.orgList])

  async function removePod(e, pod) {
    e.preventDefault();

    try {
      await props.actions.removePod(pod.id);
      document.body.classList.remove("modal-open");
    } catch {
      console.log("error occured");
    }
  }

  //load items to display function sets counts to state
  function loadItemsToDisplay() {
    let listToDisplay = filteredPods;
    setPods(listToDisplay);
  }

  //search function sets current search term to state called onChange in search input
  function handleSearchChange(e) {
    setCurrentSearchTerm(e.target.value.trim());
  }
  function editForm() {
    setModal((prevEditForm) => !prevEditForm);
    document.body.classList.add("modal-open");
  }

  async function changePodOrg(e, podID, BLEID) {

    const orgID = props.organisation.orgList.filter(
      (item) => item.Name === e
    )[0].OrgID;


    let postBody = {
      ble: BLEID,
      orgID: parseInt(orgID),
    };

    await props.actions
      .removePod(podID)
      .then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          registerPod(postBody)
            .then((res2) => {
              if (res2.data.result > 0) {
                  props.actions.getListOfPods();
              }
            })
            .catch(() => {
              console.log("error re-adding the pod");
            });
        }
      })
      .catch(() => {
        console.log("error deleting the pod");
      });
  }

  function displayPODData(rows) {
    return rows.length > 0
      ? rows.map((pod, i) => {
          let BLEID = pod.ble;

          return (
            <div className="pod-item-container" key={pod.id}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.6,
                  delay: 0.07 * i,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <div className="pod-tables-row">
                  <div className="pod-col pod-col-name">
                    {displayDate(pod.tsCreated)}
                  </div>
                  <div className="pod-col">{pod.id}</div>
                  <div className="pod-col">{BLEID}</div>

                  {/* <div className="pod-col pod-col-last">{pod.orgName}</div> */}
                  <div className="pod-col pod-col-last">
                    <select
                      name="organisations"
                      onChange={(e) =>
                        changePodOrg(e.target.value, pod.id, BLEID)
                      }
                    >
                      {props.organisation.orgList.map((item) => (
                        <option
                          value={item.Name}
                          key={nanoid()}
                          selected={pod.orgName === item.Name}
                        >
                          {item.Name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    className="pod-col delete-column"
                    onClick={() => setDeactivateRow(pod)}
                  >
                    <span
                      onClick={() => {
                        setDeactivateModalOpen((prevModal) => !prevModal);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      <MainButton buttonText={"Deactivate"} buttonPop={true}/>
                      {/* <Button value={"De-activate"} /> */}
                    </span>
                  </div>
                </div>
              </motion.div>
            </div>
          );
        })
      : "No Pods Found";
  }

  //display table data function
  function displayTableData(header) {
    let rows = pods;
    return (
      <div>
        <div className="pods-table">
          {rows.length > 0 ? (
            <div className={`pod-tables-headers`}>
              {/* checks if headers exist and maps over them if they do */}
              {header
                ? header.map((heading) => (
                    <div
                      className={`pod-tables-header ${
                        header.indexOf(heading) === 0
                          ? "first-heading"
                          : header.indexOf(heading) === header.length - 1
                          ? "last-heading"
                          : ""
                      }`}
                      key={`header${header.indexOf(heading)}`}
                    >
                      {heading}
                      {/* if the heading is first in the array it adds a counter to the amount of rows under the heading */}
                      {header.indexOf(heading) === 0 ? ` (${rows.length})` : ""}
                    </div>
                  ))
                : ""}
            </div>
          ) : (
            <div className="tables-empty-message"></div>
          )}

          <ReuseableInfiniteScrollingTable
            dataToDisplay={rows}
            rowComponent={displayPODData}
          />
        </div>
        <AlertDialogDemo
          type="pod"
          deactivateRow={deactivateRow}
          open={deactivateModalOpen}
          setLogoutDialogOpen={setDeactivateModalOpen}
          proceed={removePod}
        />
      </div>
    );
  }

  const memoizedTableData = useMemo(() => {
    const headers = [
      "Registration Date",
      "DB ID",
      "BLE ID",
      "Organisation",
      "Action",
    ];
    return displayTableData(headers);
  }, [pods, deactivateModalOpen, deactivateRow, props.podList]);

  return (
    <div className="pods-list-container">
      <div className="pods-head-container">
        <div className="pods-head">
          <h1>POD Database</h1>
        </div>
        <div className="pods-search_create">
          <div className="pod-search">
            <div className="search-input-parent">
              <input
                type="text"
                autoComplete="off"
                id="searchInput"
                onChange={(e) => handleSearchChange(e)}
                placeholder="Search"
              />
            </div>
          </div>
          <DialogDemo
            open={modal}
            close={setModal}
            process="podRegister"
            button={
              //<Button value={"Register New POD"} onClickHandler={editForm} />
              <div onClick={editForm}>
                <MainButton buttonText = {"Register New POD"}/>
              </div>
            }
          />
        </div>
      </div>
      {memoizedTableData}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    organisation: selectOrganisation(state),
    podList: selectPodList(state),
    adminList: selectAdminList(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { getOrganisationsToolkit, getListOfPods, removePod },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PodList);
