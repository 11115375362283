const Polygon = ({
  size = "17",
  direction,
  color = "#FAFAFA",
  className = "",
}) => {
  const style = {};
  if (direction === "right") {
    style.transform = "rotate(270deg)";
  }
  if (direction === "up") {
    style.transform = "rotate(180deg)";
  }
  return (
    <svg
      style={style}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 12 7"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99994 6.50019V6.50019C5.73414 6.50172 5.47866 6.39737 5.28994 6.21019L1.28994 2.21019V2.21019C0.897819 1.81806 0.897819 1.18231 1.28994 0.790185C1.68206 0.398063 2.31782 0.398063 2.70994 0.790185L5.99994 4.10019L9.29994 0.920185V0.920185C9.66925 0.509537 10.3015 0.476022 10.7122 0.845328C11.1228 1.21463 11.1563 1.84691 10.787 2.25756C10.7539 2.29437 10.7181 2.32867 10.6799 2.36018L6.67994 6.22018V6.22018C6.49707 6.3965 6.25394 6.49661 5.99994 6.50018L5.99994 6.50019Z"
        fill={color}
      />
    </svg>
  );
};

export default Polygon;
