import * as Dialog from "@radix-ui/react-dialog";
import Button from "components/common/button/button";
import { Cross2Icon } from "@radix-ui/react-icons";

const CreatedAthlete = (props) => {
  const { userName, close } = props;

  return (
    <>
      <div style={{ width: "100%", height: "40vh" }} className="center-content">
        <div className="display-message">
          <div style={{ width: 600, textAlign: "center", color: "white" }}>
            <p style={{ fontSize: "20px", fontWeight: 700 }}>
              New Athlete Created
            </p>{" "}
            <br />
            <p>
              Your new Athlete {userName} will be available on Athletes section
            </p>
          </div>
        </div>
        <div>
          <div>
            <div
              className="org-msg-btn"
              onClick={(e) => {
                props.reset(e);
              }}
              style={{ paddingBottom: "2rem", paddingTop: "2rem" }}
            >
              <Button value={`Create Another Athlete`} />
            </div>

            <div className="org-msg-btn">
              <Button value={`Back To Organisation`} onClickHandler={close} />
            </div>
          </div>
        </div>

        <Dialog.Close asChild>
          <button className="IconButton" aria-label="Close" onClick={close}>
            <Cross2Icon />
          </button>
        </Dialog.Close>
      </div>
    </>
  );
};

export default CreatedAthlete;
