/* DEMO CHART NOT IN USE !!!!!------------------------------------------- */
/* eslint-disable */
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "utils/constant";
import { quantileSeq, create, all, config, max, min } from "mathjs";

import ReactApexChart from "react-apexcharts";

function CompareSessionBoxPlot(props) {
  const { sessionsData, metricName } = props;

  const math = create(all, config);

  console.log(props);

  var array = [
    0.572, 0.536, 0.556, 0.58, 0.584, 0.6, 0.472, 0.672, 0.572, 0.564, 0.588,
    0.612, 0.644,
  ];
  console.log(array);
  const quantiles = math.quantileSeq(array, [0, 0.25, 0.5, 0.75, 1]);
  const iqr = quantiles[3] - quantiles[1];

  let boxPlotData = quantiles;
  console.log("boxPlotData before: ", boxPlotData);

  let lowestFilterValue = max(quantiles[0], quantiles[0] - 1.5 * iqr);
  let highestFilterValue = min(quantiles[4], quantiles[2] + 1.5 * iqr);

  console.log("boxPlotData after: ", lowestFilterValue, highestFilterValue);

  const handleDataStructuring = (objectKey, dataForStructuring) => {
    let quantiles = math.quantileSeq(
      dataForStructuring.data
        .filter((value) => value.hasOwnProperty(objectKey))
        .map((filteredValue) => filteredValue?.[objectKey])
        .filter((notNull) => notNull !== null),
      [0, 0.25, 0.5, 0.75, 1]
    );

    const iqr = quantiles[3] - quantiles[1];

    let lowestFilterValue = max(quantiles[0], quantiles[1] - 1.5 * iqr).toFixed(3);
    let highestFilterValue = min(quantiles[4], quantiles[3] + 1.5 * iqr).toFixed(3);
    quantiles[0] =lowestFilterValue
    quantiles[4] = highestFilterValue

    // let newRange = math.quantileSeq(
    //   dataForStructuring.data
    //     .filter((value) => value.hasOwnProperty(objectKey))
    //     .map((filteredValue) => filteredValue?.[objectKey])
    //     .filter(
    //       (item) => item <= highestFilterValue && item >= lowestFilterValue
    //     ),
    //   [0, 0.25, 0.5, 0.75, 1]
    // );
    let newRange = math.quantileSeq(
      dataForStructuring.data
        .filter((value) => value.hasOwnProperty(objectKey))
        .map((filteredValue) => filteredValue?.[objectKey])
        .filter(
          (item) => item <= highestFilterValue && item >= lowestFilterValue
        ),
      [0, 0.25, 0.5, 0.75, 1]
    );
    console.log("old range: ", quantiles);
    console.log(" new range: ", newRange);
    return quantiles;
  };

  //console.log(math.quantileSeq(array, [0, 0.25, 0.5, 0.75, 1]));

  const series = [
    {
      name: "Left Foot",
      type: 'boxPlot',
      data: [
        ...sessionsData.map((item) => ({
          x: item.sessionID,
          y: [...handleDataStructuring("Left Foot", item)],
        })),
      ],
      
    },
    {
      name: "Right Foot",
      type: 'boxPlot',
      data: [
        ...sessionsData.map((item) => ({
          x: item.sessionID,
          y: [...handleDataStructuring("Right Foot", item)],
        })),
      ],
    },
  
    // {
    //   name: 'Left Foot Outliers',
    //   type: 'scatter',
    //   data: [
    //     ...sessionsData.map((item) => ({
    //       x: item.sessionID,
    //       y: [...handleDataStructuring("Left Foot", item)],
    //     })),
    //   ]
    // },

    // {
    //   name: 'Right Foot Outliers',
    //   type: 'scatter',
    //   data: [
    //     ...sessionsData.map((item) => ({
    //       x: item.sessionID,
    //       y: [...handleDataStructuring("Right Foot", item)],
    //     })),
    //   ]
    // },

    // {
    //   name: 'box',
    //   type: 'boxPlot',
    //   data: [
    //     {
    //       x: new Date('2017-01-01').getTime(),
    //       y: [54, 66, 69, 75, 88]
    //     },
    //     {
    //       x: new Date('2018-01-01').getTime(),
    //       y: [43, 65, 69, 76, 81]
    //     },
    //     {
    //       x: new Date('2019-01-01').getTime(),
    //       y: [31, 39, 45, 51, 59]
    //     },
    //     {
    //       x: new Date('2020-01-01').getTime(),
    //       y: [39, 46, 55, 65, 71]
    //     },
    //     {
    //       x: new Date('2021-01-01').getTime(),
    //       y: [29, 31, 35, 39, 44]
    //     }
    //   ]
    // },
    // {
    //   name: 'outliers',
    //   type: 'scatter',
    //   data: [
    //     {
    //       x: new Date('2017-01-01').getTime(),
    //       y: 32
    //     },
    //     {
    //       x: new Date('2018-01-01').getTime(),
    //       y: 25
    //     },
    //     {
    //       x: new Date('2019-01-01').getTime(),
    //       y: 64
    //     },
    //     {
    //       x: new Date('2020-01-01').getTime(),
    //       y: 27
    //     },
    //     {
    //       x: new Date('2020-01-01').getTime(),
    //       y: 78
    //     },
    //     {
    //       x: new Date('2021-01-01').getTime(),
    //       y: 15
    //     }
    //   ]
    // }

  ];

  console.log(series);

  const data = {
    series: [...series],
    options: {
      //colors responsible for the fill of the box plots
      colors: ["#8e44ad", "#3498db", "#8e44ad", "#3498db"],
      chart: {
        type: "boxPlot",
        height: 550,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        boxPlot: {
          //this empty array causes the box plots to respect the colors from the options array, only works with empty array
          colors: [],
        },
        bar: {
          horizontal: false,
          columnWidth: "70%",
        },
      },
      stroke: {
        width: 2,
        colors: ["#8e44ad", "#3498db" ],
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
        theme: "dark",
      },
      yaxis: {
        axisBorder: {
          show: true,
          color: NEW_AXIS_COLOR,
          height: "100%",
          width: 2,
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: NEW_AXIS_COLOR,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          text: undefined,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: NEW_AXIS_TICK,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: function(val) {
            return val.toFixed(2);
          },
          datetimeUTC: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
      xaxis: {
        axisBorder: {
          show: true,
          color: NEW_AXIS_COLOR,
          height: 2,
          width: "100%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: NEW_AXIS_COLOR,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          text: undefined,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: NEW_AXIS_TICK,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },
      grid: {
        show: true,
        borderColor: NEW_AXIS_COLOR,
        strokeDashArray: 4,
        position: "back",
        opacity: 0.1,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    },
  };

  console.log(props);
  return (
    <div className="chart-table-container asym">
      <div className="chart-heading">{metricName}</div>
      <div className="chart-container">
        <ReactApexChart
          options={data.options}
          series={data.series}
          type={data.options.chart.type}
          height={data.options.chart.height}
        />
      </div>
    </div>
  );
}

export default CompareSessionBoxPlot;

//series placeholder object
// series: [
//   {
//     name: "Left Foot",
//     data: [
//       {
//         x: "Left",
//         y: [54, 66, 69, 75, 88],
//       },
//       {
//         x: "Right",
//         y: [54, 66, 69, 75, 88],
//       },
//       {
//         x: "Jan 2016",
//         y: [43, 65, 69, 76, 81],
//       },
//       {
//         x: "Jan 2017",
//         y: [31, 39, 45, 51, 59],
//       },
//       {
//         x: "Jan 2018",
//         y: [39, 46, 55, 65, 71],
//       },
//       {
//         x: "Jan 2019",
//         y: [29, 31, 35, 39, 44],
//       },
//       {
//         x: "Jan 2020",
//         y: [41, 49, 58, 61, 67],
//       },
//       {
//         x: "Jan 2021",
//         y: [54, 59, 66, 71, 88],
//       },
//     ],
//   },
//   {
//     name: "Right Foot",
//     data: [
//       {
//         x: "Left",
//         y: [54, 66, 69, 75, 88],
//       },
//       {
//         x: "Right",
//         y: [54, 66, 69, 75, 88],
//       },
//       {
//         x: "Jan 2016",
//         y: [43, 65, 69, 76, 81],
//       },
//       {
//         x: "Jan 2017",
//         y: [31, 39, 45, 51, 59],
//       },
//       {
//         x: "Jan 2018",
//         y: [39, 46, 55, 65, 71],
//       },
//       {
//         x: "Jan 2019",
//         y: [29, 31, 35, 39, 44],
//       },
//       {
//         x: "Jan 2020",
//         y: [41, 49, 58, 61, 67],
//       },
//       {
//         x: "Jan 2021",
//         y: [54, 59, 66, 71, 88],
//       },
//     ],
//   },
// ],
