/* eslint-disable */
import * as Dialog from "@radix-ui/react-dialog";
import MainModal from "../mainModal/MainModal";
import MainButton from "../reuseableButtonComponent/MainButton";
import WarningTooltip from "../warningTooltip/WarningTooltip";
import "./tag.scss";

const Tag = (props) => {
  const {
    tagData,
    handleClick = false,
    hasHandleClick = false,
    clickButtonSymbol,
    hasIcon = false,
    TagIcon,
    hasConfirmationModal = false,
    tagModalHeader = "",
    tagModalDetails = "",
  } = props;

  const { tag, detail } = tagData;
  return (
    <div className="tag">
      {detail && (
        <div>
          <WarningTooltip
            warningText={detail}
            warningIconType={"info"}
            warningForTag={true}
          />
        </div>
      )}
      <p>{tag}</p>
      {hasHandleClick && !hasConfirmationModal && (
        <div className="add-button" onClick={() => handleClick(tagData)}>
          {!hasIcon && <p>{clickButtonSymbol}</p>}
          {hasIcon && (
            <div className="tag-icon">
              <TagIcon size={"14"} color={"white"} />
            </div>
          )}
        </div>
      )}
      {hasHandleClick && hasConfirmationModal && (
        <MainModal
          button={
            <div className="add-button">
              {!hasIcon && <p>{clickButtonSymbol}</p>}
              {hasIcon && (
                <div className="tag-icon">
                  <TagIcon size={"14"} color={"white"} />
                </div>
              )}
            </div>
          }
          modalBody={
            <div className="tag-modal-container">
              <div className="tag-modal-header">
                <h4>{tagModalHeader}</h4>
              </div>
              <div className="tag-modal-detail">
                <p>{tagModalDetails}</p>
              </div>
              <div className="tag-modal-controls">
                <div onClick={() => handleClick(tagData)}>
                  <MainButton buttonText={"Confirm"} />
                </div>
                <Dialog.Close>
                  <div>
                    <MainButton
                      buttonText={"Cancel Change"}
                      buttonImportant={true}
                    />
                  </div>
                </Dialog.Close>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default Tag;
