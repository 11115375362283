/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import PlayerActivityDetails from "./PlayerActivityDetails2";
//import Pagination from "components/common/Pagination/Pagination";
import { getSessionList, setSessionList } from "store/sessionListSlice";
import { getOrganisationsToolkit, selectOrganisation } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import DisplayTableData from "components/activityTable/activityTable";
// import DisplayTableDataSecond from "components/activityTable/activityTableSecond";

import { useState } from "react";
import { useEffect } from "react";
import FilterContainer from "components/common/filterContainer/FilterContainer";
import { useSelector, useDispatch } from "react-redux";

import SessionComparison from "components/compare-sessions/SessionComparison";
import CustomGraphLayoutModal from "components/common/custom-graph-layout-modal/CustomGraphLayoutModal";
import moment from "moment";
import { selectActivityList } from "store/activitySlice";

const PlayerActivity2 = React.memo(function PlayerActivity2(props) {
  const {
    user,
    organisation,
    match,
    playerid = null,
    fromSessionInput,
    athleteLoadDate,
  } = props;

  const [sessionsForComparison, setSessionsForComparison] = useState(false);
  const [comparingSessions, setComparingSessions] = useState(false);
  const [comparedSessionType, setComparedSessionType] = useState("");
  const [sessionFiltered, setSessionFiltered] = useState([]);
  const [windowSize, setWindowSize] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);

  //pdf download modal state
  const [customGraphLayoutModalOpen, setCustomGraphLayoutModalOpen] =
    useState(false);

  const [searchList, setSearchList] = useState();
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  });
  const { filtering, noRecords } = useSelector((state) => state.playerActivity);
  const { status, sessionList } = useSelector((state) => state.sessionList);
  const { orgList } = useSelector((state) => state.organisation);

  useEffect(() => {
    if (athleteLoadDate) {
      let loadDate = new Date(athleteLoadDate);
      setFilterDate({ startDate: moment(loadDate), endDate: moment(loadDate) });
    }
  }, [athleteLoadDate]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const dispatch = useDispatch();

  //The useEffect shouldn't be async as it was here previously useEffect function expects either a function or a function that returns a cleanup function.
  //If you return a promise from the useEffect function, it will not be handled correctly by the React rendering engine and may cause issues with component rendering and state updates.
  useEffect(() => {
    const fetchData = () => {
      if (status === "idle" && sessionList.length === 0 && user.id) {
        dispatch(getSessionList());
      }

      if (orgList.length === 0 && user.id) {
        dispatch(getOrganisationsToolkit(user.id));
      }
    };
    fetchData();
  }, []);

  let fullSessionList = React.useMemo(() => {
    if (status === "idle") {
      return [];
    }

    let list = Array.isArray(sessionList.data) ? [...sessionList.data] : [];

    if (match.params.id) {
      list = list.filter((session) => {
        return session.playerID === playerid;
      });
    }
    return list;
  }, [status, sessionList, match.params.id, playerid]);

  function modifySessionListWhenDiscardingSession(sessionID) {
    //remove from redux added to session list
    if (!sessionFiltered.length) {
      dispatch(
        setSessionList({
          ...sessionList,
          data: fullSessionList.filter((item) => item.ID !== sessionID),
        })
      );
      setSessionFiltered(
        fullSessionList.filter((item) => item.ID !== sessionID)
      );
    } else {
      dispatch(
        setSessionList({
          ...sessionFiltered,
          data: fullSessionList.filter((item) => item.ID !== sessionID),
        })
      );
      setSessionFiltered(
        sessionFiltered.filter((item) => item.ID !== sessionID)
      );
    }
  }

  const sessionsToDisplay = React.useMemo(() => {
    //added session discard to remove session from list when removing session !!Note, look into alternative method, perhaps remember the possition in the table
    return filtering
      ? searchList
      : noRecords
      ? searchList
      : sessionFiltered.length > 0
      ? sessionFiltered
      : fullSessionList;
  }, [filtering, searchList, noRecords, status, sessionFiltered]);

  let propsForFilterContainer = {
    setSearchList: setSearchList,
    setFilterDate: setFilterDate,
    setSessionsForComparison: setSessionsForComparison,
    setComparingSessions: setComparingSessions,
    setComparedSessionType: setComparedSessionType,
    setCustomGraphLayoutModalOpen: setCustomGraphLayoutModalOpen,
    orgList: orgList,
    searchList: searchList,
    fullSessionList: fullSessionList,
    filterDate: filterDate,
    fromSessionInput: fromSessionInput,
    sessionsToDisplay: sessionsToDisplay,
    sessionListStatus: status,
    athleteLoadDate: athleteLoadDate,
    windowWidth: windowSize[0],
    userPermisionLevel: user.permissionLevel,
    user: user,
    activityList: props.activityList.activities,
  };

  let propsForDisplayTableData = {
    fromSessionInput: fromSessionInput,
    sessionsToDisplay: sessionsToDisplay,
    setSearchList: setSearchList,
    searchList: searchList,
    sessionListStatus: status,
    permissionLevel: user.permissionLevel,
    fullSessionList: fullSessionList,
  };

  return (
    <div>
      <div className="session-list-container">
        <div className="session-list-data-container player-activity">
          <FilterContainer {...propsForFilterContainer} />

          <div className={`session-list-table-data`}>
            <DisplayTableData {...propsForDisplayTableData} />
          </div>
        </div>
      </div>
      <PlayerActivityDetails
        fromPlayerProfile
        sessionId={playerid}
        removeSessionFromList={modifySessionListWhenDiscardingSession}
      />

      <SessionComparison
        comparingSesions={comparingSessions}
        setComparingSessions={setComparingSessions}
        sessionsForComparison={sessionsForComparison}
        setSessionsForComparison={setSessionsForComparison}
        comparedSessionType={comparedSessionType}
        setComparedSessionType={setComparedSessionType}
      />

      <CustomGraphLayoutModal
        match={match}
        modalIn={customGraphLayoutModalOpen}
        setModal={setCustomGraphLayoutModalOpen}
        sessionID={sessionsForComparison}
      />
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    organisation: selectOrganisation(state),
    activityList: selectActivityList(state),
  };
};

export default connect(mapStateToProps)(PlayerActivity2);
