/* eslint-disable */
import { useDispatch, useSelector } from "react-redux";
import {
  setCheckedSessionsForComparison,
  setCheckedSessions2,
} from "store/playerActivitySlice";

function OrderColumns(props) {
  const {
    idTitle = "Session",
    dateTitle = "Date",
    durationTitle = "Duration",
    selectCheckTitle = "Select",
    selectCheckAllTitle = "Select All",
    clearCheckTitle = "Clear",
    athleteName = "Athlete",
    activityTitle = "Activity",
    notesTitle = "Notes",
    tagsTitle = "Tags",
    searchList,
    fullSessionList,
  } = props;

  const dispatch = useDispatch();

  const { checkedSessions } = useSelector((state) => state.playerActivity);
  const setCheckedSessions = (data) => dispatch(setCheckedSessions2(data));
  const setCheckedSessionsForCompare = (data) =>
    dispatch(setCheckedSessionsForComparison(data));

  function restSelected() {
    let inputs = document.querySelectorAll(".checkboxes");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }

    dispatch(setCheckedSessionsForComparison([]));
    dispatch(setCheckedSessions2([]));
  }

  function checkAllSessions() {
    if (searchList) {
      if (searchList.length < fullSessionList.length) {
        setCheckedSessions(searchList?.map((item) => String(item.ID)));
        setCheckedSessionsForCompare(searchList.map((item) => item.ActivityName));
      }
    }
  }

  const isListFiltered = fullSessionList?.length > searchList?.length;

  return (
    <>
      <div className="session-list-header player-activity">
        {/* <div className="dropdown-column">

              </div> */}
        <div className="clear-column">
          <div
            className={`clear-header ${
              checkedSessions.length > 0 ? "clearable" : ""
            }`}
            onClick={() => {
              checkedSessions.length > 0 ? restSelected() : checkAllSessions();
            }}
          >
            {isListFiltered && !checkedSessions.length > 0
              ? selectCheckAllTitle
              : checkedSessions.length > 0
              ? clearCheckTitle + ` (${checkedSessions.length})`
              : selectCheckTitle}
          </div>
        </div>
        <div className="date-column">
          <div className="text-header">{dateTitle}</div>
        </div>

        <div className="duration-column">
          <div className="text-header">{durationTitle}</div>
        </div>

        <div className="id-column">
          <div className="id-column-header">{idTitle}</div>
        </div>

        <div className="player-column">
          <div className="id-column-header">{athleteName}</div>
        </div>

        <div className="name-column">
          <div className="id-column-header">{activityTitle}</div>
        </div>

        <div className="notes-column">
          <div className="text-header">{notesTitle}</div>
        </div>

        <div className="tags-column">
          <div className="text-header">{tagsTitle}</div>
        </div>
      </div>
    </>
  );
}

export default OrderColumns;
