/* eslint-disable */
import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import "./reportsDownloadModal.scss";
import Button from "../button/button";
import { API_HOST } from "utils/constant";
import Cookies from "js-cookie";
import axios from "axios";
import { getSessionFromList, getSessionFromList2 } from "utils/helper";
import { useState } from "react";
import { useEffect } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import moment from "moment";
import { connect } from "react-redux";
import { selectUser } from "store/authSlice";
import { useDispatch } from "react-redux";
import { setSelectedSessionId } from "store/playerActivitySlice";
import MainTooltip from "../mainTooltip/MainTooltip";

const ReportsDownloadModal = (props) => {
  const {
    button,
    sessionID,
    sessionList,
    searchList = null,
    selectedActivity,
    selectedAthlete,
    startFilterDate,
    endFilterDate,
    checkedSessions = [],
    open,
    setCustomGraphLayoutModal,
  } = props;


  const [disableButton, setDisableButton] = React.useState(true);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [inputsChecked, setInputsChecked] = useState(0);
  const [sessionInputed, setSessionsInputed] = useState([]);
  const [disabledDownloading, setDisabledDownloading] = useState({
    normDataDownloading: false,
    sessionMetricDownloading: false,
    debugDataDownloading: false,
    rawDataDownloading: false,
    combinedZeroDownloading: false,
    downloadMemoryDataDownloading: false,

  });

  //memory session links
  const [memorySessionData, setMemorySessionData] = useState({})


  const dispatch = useDispatch();

  useEffect(() => {
    inputsChecked === 0 ? setDisableButton(true) : setDisableButton(false);
    setSessionsInputed(checkedSessions);
  }, [inputsChecked]);

  //used to handle downloading state and diabling button
  useEffect(() => {
    checkDownloadingState();
  }, [disabledDownloading, checkedSessions]);

  //checks if any of the objects in the downloading state are true, disables downloadButton and changes loading state, else re-enable button and loading state to false
  function checkDownloadingState() {
    const {
      normDataDownloading,
      sessionMetricDownloading,
      debugDataDownloading,
      rawDataDownloading,
      combinedZeroDownloading,
    } = disabledDownloading;
    if (
      normDataDownloading === true ||
      sessionMetricDownloading === true ||
      debugDataDownloading === true ||
      rawDataDownloading === true ||
      combinedZeroDownloading === true
    ) {
      setDisableButton(true);
      setLoadingFiles(true);
    } else {
      setTimeout(() => {
        setDisableButton(false);
        setLoadingFiles(false);
      }, 2000);
    }
  }


  // useEffect(() => {
  //   if (open) {
  //     document.body.classList.add("modal-open");
  //     if(sessionID){
  //       console.log("Session is open with id : ", sessionID)
  //     }
  //   } else {
  //     document.body.classList.remove("modal-open");
  //   }

  //   return () => {
  //     document.body.classList.remove("modal-open");
  //   };
  // }, [open]);


  //checks if input is checked and sets state accoringly
  function setIsChecked(id) {
    document.getElementById(id).checked
      ? (document.getElementById(id).checked = false)
      : (document.getElementById(id).checked = true);

    if (document.getElementById(id).checked) {
      setInputsChecked((prev) => prev + 1);
    } else {
      setInputsChecked((prev) => prev - 1);
    }
  }

  const close = () => {
    document.body.classList.remove("modal-open");
    setInputsChecked(0);
    setMemorySessionData({})
  };

  //batch file name
  function getBatchFileName(
    selectedActivity,
    selectedAthlete,
    startFilterDate,
    endFilterDate,
    fileName
  ) {
    if (selectedActivity !== null) {
      fileName = fileName + "_" + selectedActivity?.[0].label?.trim();
    }
    if (selectedAthlete !== null) {
      fileName = fileName + "_" + selectedAthlete?.[0].label?.trim();
    }

    if (startFilterDate !== null && startFilterDate !== "") {
      const startDate = moment(startFilterDate).format("DD-MM-YYYY");
      fileName = fileName + "_from_" + startDate.toLowerCase().trim();
    }

    if (endFilterDate !== null && endFilterDate !== "") {
      const endDate = moment(endFilterDate).format("DD-MM-YYYY");
      fileName = fileName + "_to_" + endDate.toLowerCase().trim();
    }

    return fileName;
  }

  //download debug info -----------------------------------------------------------------------------------------------------
  function downloadDebugInfo(id) {
    setDisabledDownloading(
      (prevObj) => (prevObj = { ...prevObj, debugDataDownloading: true })
    );

    const feet = ["left", "right"];

    for (let k = 0; k < feet.length; k++) {
      let url = `${API_HOST}/sessionData/getDebugData/${id}/${feet[k]}/`;

      const headers = {
        Pragma: "no-cache", //needed for IE 11
        Authorization: `Bearer ${Cookies.get("id_token")}`,
      };

      let fileName = `${id}_${feet[k]}_foot_debugData.txt`;

      axios({
        url,
        method: "GET",
        responseType: "blob", // important,
        headers,
      }).then((response) => {
        let data = response.data;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // for IE
          let debugData = new Blob([response.data], { type: "text" });
          window.navigator.msSaveOrOpenBlob(debugData, fileName);
        } else {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          setDisabledDownloading(
            (prevObj) => (prevObj = { ...prevObj, debugDataDownloading: false })
          );
        }
      });
    }
  }

  //batch debug info
  function batchDownloadDebugInfo(
    searchList,
    selectedActivity,
    selectedAthlete,
    startFilterDate,
    endFilterDate
  ) {
    setDisabledDownloading(
      (prevObj) => (prevObj = { ...prevObj, debugDataDownloading: true })
    );
    let zip = new JSZip();

    if (sessionInputed.length > 0) {
      if (sessionInputed.length > 25) {
        alert("You cannot download more than 25 at a time");
        setDisabledDownloading(
          (prevObj) => (prevObj = { ...prevObj, debugDataDownloading: false })
        );
      } else {
        const feet = ["left", "right"];

        for (let k = 0; k < feet.length; k++) {
          const headers = {
            Pragma: "no-cache", //needed for IE 11
            Authorization: `Bearer ${Cookies.get("id_token")}`,
          };

          let promiseArray = [];
          let delayIncrement = 500;
          let delay = 0;

          for (let i = 0; i < sessionInputed.length; i++) {
            let url = `${API_HOST}/sessionData/getDebugData/${sessionInputed[i]}/${feet[k]}/`;

            let tempPromise = new Promise((resolve) =>
              setTimeout(resolve, delay)
            ).then(() => {
              //now - after the delay - we call the API!
              console.log(
                "about to call API for sessionID  " + sessionInputed[i]
              );
              console.log("time : " + new Date().toISOString());

              let postBody = {
                sessionID: sessionInputed[i],
                datatype: "",
              };

              return axios({
                url,
                method: "GET",
                data: postBody,
                responseType: "blob", // important,
                headers,
              }).catch(function () {
                console.log("error for sessionID  " + sessionInputed[i]);
              });
            }, 500);

            promiseArray.push(tempPromise);

            // After every 20 downloads increase delay by 250 milliseconds to reduce server load
            if (i > 0 && i % 20 === 0) {
              delayIncrement = delayIncrement + 250;
            }

            delay += delayIncrement;
          }

          let fileName =
            getBatchFileName(
              selectedActivity,
              selectedAthlete,
              startFilterDate,
              endFilterDate,
              `debugInfo`
            ) + ".zip";
          Promise.all(promiseArray).then(function (results) {
            console.log("all functions called - now do zip!!!!");

            results.forEach((result) => {
              if (
                result &&
                result.config &&
                result.config.data &&
                result.data
              ) {
                let thisSession = getSessionFromList(
                  parseInt(JSON.parse(result.config.data).sessionID),
                  searchList
                );
                zip.file(
                  `${thisSession.playerName}-${thisSession.ActivityName}` +
                    "-Session_" +
                    JSON.parse(result.config.data).sessionID.toString() +
                    `_${feet[k]}_foot_debugData` +
                    `.txt`,
                  new Blob([result.data])
                );
              }
            });
            //makes sure the loop has both feet debug data before downloading the zip file
            if (Object.keys(zip.files).length > sessionInputed.length) {
              zip.generateAsync({ type: "blob" }).then(function (blob) {
                saveAs(blob, fileName);
              });
              setDisabledDownloading(
                (prevObj) =>
                  (prevObj = { ...prevObj, debugDataDownloading: false })
              );
            }
          });
        }
      }
    } else {
      if (searchList.length > 25) {
        alert("You cannot download more than 25 at a time");
        setDisabledDownloading(
          (prevObj) => (prevObj = { ...prevObj, debugDataDownloading: false })
        );
      } else {
        const feet = ["left", "right"];

        for (let k = 0; k < feet.length; k++) {
          const headers = {
            Pragma: "no-cache", //needed for IE 11
            Authorization: `Bearer ${Cookies.get("id_token")}`,
          };

          let promiseArray = [];
          let delayIncrement = 500;
          let delay = 0;

          for (let i = 0; i < searchList.length; i++) {
            let session = searchList[i];
            let url = `${API_HOST}/sessionData/getDebugData/${session.ID}/${feet[k]}/`;

            let tempPromise = new Promise((resolve) =>
              setTimeout(resolve, delay)
            ).then(() => {
              //now - after the delay - we call the API!
              console.log("about to call API for sessionID  " + session.ID);
              console.log("time : " + new Date().toISOString());

              let postBody = {
                sessionID: session.ID,
                datatype: "",
              };

              return axios({
                url,
                method: "GET",
                data: postBody,
                responseType: "blob", // important,
                headers,
              }).catch(function () {
                console.log("error for sessionID  " + session.ID);
              });
            }, 500);

            promiseArray.push(tempPromise);

            // After every 20 downloads increase delay by 250 milliseconds to reduce server load
            if (i > 0 && i % 20 === 0) {
              delayIncrement = delayIncrement + 250;
            }

            delay += delayIncrement;
          }

          let fileName =
            getBatchFileName(
              selectedActivity,
              selectedAthlete,
              startFilterDate,
              endFilterDate,
              `debugInfo`
            ) + ".zip";
          Promise.all(promiseArray).then(function (results) {
            console.log("all functions called - now do zip!!!!");

            results.forEach((result) => {
              if (
                result &&
                result.config &&
                result.config.data &&
                result.data
              ) {
                let thisSession = getSessionFromList(
                  parseInt(JSON.parse(result.config.data).sessionID),
                  searchList
                );
                zip.file(
                  `${thisSession.playerName}-${thisSession.ActivityName}` +
                    "-Session_" +
                    JSON.parse(result.config.data).sessionID.toString() +
                    `_${feet[k]}_foot_debugData` +
                    `.txt`,
                  new Blob([result.data])
                );
              }
            });
            //makes sure the loop has both feet debug data before downloading the zip file
            if (Object.keys(zip.files).length > searchList.length) {
              zip.generateAsync({ type: "blob" }).then(function (blob) {
                saveAs(blob, fileName);
              });
              setDisabledDownloading(
                (prevObj) =>
                  (prevObj = { ...prevObj, debugDataDownloading: false })
              );
            }
          });
        }
      }
    }
  }

  //csv report -------------------------------------------------------------------------------------------------------------
  function downloadCSVReport(id) {
    let url = `${API_HOST}/sessionData/getReportsInCSVFormat`;
    setDisabledDownloading(
      (prevObj) => (prevObj = { ...prevObj, sessionMetricDownloading: true })
    );
    const headers = {
      Pragma: "no-cache", //needed for IE 11
      Authorization: `Bearer ${Cookies.get("id_token")}`,
    };

    // Download left foot data
    let postBody = {
      sessionID: id,
      datatype: "",
    };

    let thisSession = getSessionFromList2(parseInt(id), sessionList);
    let fileName = "";
    if (thisSession?.ActivityName) {
      fileName = `${getNamewithNoSpaces(
        thisSession.playerName
      )}_${getNamewithNoSpaces(thisSession.ActivityName)}_Report_${id}.csv`;
    } else {
      fileName = `${getNamewithNoSpaces(
        thisSession.playerName
      )}_Report_${id}.csv`;
    }

    axios({
      url,
      method: "POST",
      data: postBody,
      responseType: "blob", // important,
      headers,
    }).then((response) => {
      let data = response.data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        var csvData = new Blob([response.data], { type: "text/csv" });
        window.navigator.msSaveOrOpenBlob(csvData, fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setDisabledDownloading(
          (prevObj) =>
            (prevObj = { ...prevObj, sessionMetricDownloading: false })
        );
      }
    });
  }

  //csv report batch download
  function batchDownloadCSVReport(
    searchList,
    selectedActivity,
    selectedAthlete,
    startFilterDate,
    endFilterDate
  ) {
    setDisabledDownloading(
      (prevObj) => (prevObj = { ...prevObj, sessionMetricDownloading: true })
    );
    let url = `${API_HOST}/sessionData/getReportsInCSVFormatV2/`;

    let fileName =
      getBatchFileName(
        selectedActivity,
        selectedAthlete,
        startFilterDate,
        endFilterDate,
        "DanuMetrics"
      ) + ".csv";

    //checks if any sessions are checked and prioritises checked else download all filtered data
    function handleCSVBatch(sessions) {
      if (sessionInputed.length > 0) {
        for (let i = 0; i < sessions.length; i++) {
          if (i === 0) {
            url = url + sessions[i];
          } else {
            url = url + "," + sessions[i];
          }
        }
      } else {
        for (let i = 0; i < sessions.length; i++) {
          let thisSearchSession = sessions[i];
          if (i === 0) {
            url = url + thisSearchSession.ID;
          } else {
            url = url + "," + thisSearchSession.ID;
          }
        }
      }

      const headers = {
        Pragma: "no-cache", //needed for IE 11
        Authorization: `Bearer ${Cookies.get("id_token")}`,
      };

      if (sessions.length > 50) {
        alert("You cannot download more than 50 at a time");
        setDisabledDownloading(
          (prevObj) =>
            (prevObj = { ...prevObj, sessionMetricDownloading: false })
        );
      } else {
        axios({
          url,
          method: "GET",
          //data: postBody,
          responseType: "blob", // important,
          headers,
        }).then((response) => {
          let data = response.data;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // for IE
            var csvData = new Blob([response.data], { type: "text/csv" });
            window.navigator.msSaveOrOpenBlob(csvData, fileName);
          } else {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            setDisabledDownloading(
              (prevObj) =>
                (prevObj = { ...prevObj, sessionMetricDownloading: false })
            );
          }
        });
      }
    }

    //prioritise checked sessions
    if (sessionInputed.length > 0) {
      let sessions = sessionInputed;
      handleCSVBatch(sessions);
    } else {
      handleCSVBatch(searchList);
    }
  }

  // norm data---------------------------------------------------------------------------------------------------------------
  function downloadNormalisedFile(id) {
    setDisabledDownloading(
      (prevObj) => (prevObj = { ...prevObj, normDataDownloading: true })
    );

    let url = `${API_HOST}/sessionData/getSockNormalisedData`;

    const headers = {
      Pragma: "no-cache", //needed for IE 11
      Authorization: `Bearer ${Cookies.get("id_token")}`,
    };

    // Download left foot data
    let postBody = {
      sessionID: id,
      datatype: "left",
    };

    let fileName = "";

    let thisSession = getSessionFromList2(parseInt(id), sessionList);
    if (thisSession?.ActivityName) {
      fileName = `${getNamewithNoSpaces(
        thisSession.playerName
      )}_${getNamewithNoSpaces(
        thisSession.ActivityName
      )}_normalisedData_${id}.csv`;
    } else {
      fileName = `${getNamewithNoSpaces(
        thisSession.playerName
      )}_normalisedData_${id}.csv`;
    }

    axios({
      url,
      method: "POST",
      data: postBody,
      responseType: "blob", // important,
      headers,
    }).then((response) => {
      let data = response.data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        var csvData = new Blob([response.data], { type: "text/csv" });
        window.navigator.msSaveOrOpenBlob(csvData, fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setDisabledDownloading(
          (prevObj) => (prevObj = { ...prevObj, normDataDownloading: false })
        );
      }
    });
  }

  //batch normaalised data zip file
  function batchDownloadNormalisedFile(
    searchList,
    selectedActivity,
    selectedAthlete,
    startFilterDate,
    endFilterDate
  ) {
    setDisabledDownloading(
      (prevObj) => (prevObj = { ...prevObj, normDataDownloading: true })
    );
    let url = `${API_HOST}/sessionData/getSockNormalisedData`;
    let zip = new JSZip();
    let emptySessions = [];

    const headers = {
      Pragma: "no-cache", //needed for IE 11
      Authorization: `Bearer ${Cookies.get("id_token")}`,
    };

    //checks if any sessions are checked and prioritises checked else download all filtered data
    function handleBatchNormalisedFile(sessions) {
      if (sessions.length > 50) {
        alert(
          "You cannot download more than 50 normalised data files at a time, more will cause DOS issues"
        );
        setDisabledDownloading(
          (prevObj) => (prevObj = { ...prevObj, normDataDownloading: false })
        );
      } else {
        let promiseArray = [];
        let delayIncrement = 500;
        let delay = 0;

        if (sessionInputed.length > 0) {
          for (let i = 0; i < sessions.length; i++) {
            let session = Number(sessions[i]);
            let postBody = {
              sessionID: session,
              datatype: "left",
            };

            let tempPromise = new Promise((resolve) =>
              setTimeout(resolve, delay)
            ).then(() => {
              //now - after the delay - we call the API!
              console.log("about to call API for sessionID  " + session);
              console.log("time : " + new Date().toISOString());

              return axios({
                url,
                method: "POST",
                data: postBody,
                responseType: "blob", // important,
                headers,
              }).catch(function () {
                console.log("error for sessionID normalisedBatch " + session);
                emptySessions.push(session);
              });
            });

            promiseArray.push(tempPromise);

            // After every 20 downloads increase delay by 250 milliseconds to reduce server load
            if (i > 0 && i % 20 === 0) {
              delayIncrement = delayIncrement + 250;
            }

            delay += delayIncrement;
          }
        } else {
          for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i];
            let postBody = {
              sessionID: session.ID,
              datatype: "left",
            };

            let tempPromise = new Promise((resolve) =>
              setTimeout(resolve, delay)
            ).then(() => {
              //now - after the delay - we call the API!
              console.log("about to call API for sessionID  " + session.ID);
              console.log("time : " + new Date().toISOString());

              return axios({
                url,
                method: "POST",
                data: postBody,
                responseType: "blob", // important,
                headers,
              }).catch(function () {
                console.log(
                  "error for sessionID normalisedBatch " + session.ID
                );
                emptySessions.push(session.ID);
              });
            });

            promiseArray.push(tempPromise);

            // After every 20 downloads increase delay by 250 milliseconds to reduce server load
            if (i > 0 && i % 20 === 0) {
              delayIncrement = delayIncrement + 250;
            }

            delay += delayIncrement;
          }
        }

        let fileName =
          getBatchFileName(
            selectedActivity,
            selectedAthlete,
            startFilterDate,
            endFilterDate,
            `normalisedBatch`
          ) + ".zip";
        Promise.all(promiseArray).then(function (results) {
          console.log("all functions called - now do zip!!!!");

          results.forEach((result) => {
            if (result && result.config && result.config.data && result.data) {
              let thisSession = getSessionFromList(
                parseInt(JSON.parse(result.config.data).sessionID),
                searchList
              );
              zip.file(
                `${thisSession.playerName.split(" ").join("")}_${
                  thisSession.ActivityName
                }` +
                  `_normalisedData_` +
                  JSON.parse(result.config.data).sessionID.toString() +
                  ".csv",
                new Blob([result.data])
              );
            }
          });
          zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, fileName);
          });
          setDisabledDownloading(
            (prevObj) => (prevObj = { ...prevObj, normDataDownloading: false })
          );

          if (emptySessions.length > 0) {
            let eptySess = mapArrayToText(emptySessions);
            let alertMessage = `Sessions data not available for: ${eptySess}`;
            alert(alertMessage);
          }
        });
      }
    }

    //prioritise checked sessions
    if (sessionInputed.length > 0) {
      let sessions = sessionInputed;
      handleBatchNormalisedFile(sessions);
    } else {
      handleBatchNormalisedFile(searchList);
    }
  }

  //file download zero + combined zero --------------------------------------------------------------------------------------#

  //name format should be AthleteName_ActivityType_dataType_sessionID.csv
  function downloadFile(id, sesList, datatype) {
    let url = `${API_HOST}/sessionData/getSockSessionData`;
    let postBody = {
      sessionID: id,
      datatype: datatype,
    };

    {
      datatype === "combined"
        ? setDisabledDownloading(
            (prevObj) => (prevObj = { ...prevObj, rawDataDownloading: true })
          )
        : setDisabledDownloading(
            (prevObj) =>
              (prevObj = { ...prevObj, combinedZeroDownloading: true })
          );
    }

    let thisSession = getSessionFromList2(parseInt(id), sesList);
    let fileName = "";
    if (thisSession.ActivityName) {
      if (datatype === "combined") {
        fileName = `${getNamewithNoSpaces(
          thisSession.playerName
        )}_${getNamewithNoSpaces(
          thisSession.ActivityName
          // )}_rawData_${id}_${datatype}.csv`; //changes to download file name/ 02/02/2024
        )}_rawData_${id}.csv`;
      }
      if (datatype === "combinedZero") {
        fileName = `${getNamewithNoSpaces(
          thisSession.playerName
        )}_${getNamewithNoSpaces(
          thisSession.ActivityName
          // )}_rawData_${id}_${datatype}.csv`; //changes to download file name/ 02/02/2024
        )}_rawZeroData_${id}.csv`;
      }
    } else {
      fileName = `${getNamewithNoSpaces(
        thisSession.playerName
      )}_rawData_${id}.csv`;
    }

    const headers = {
      Pragma: "no-cache", //needed for IE 11
      Authorization: `Bearer ${Cookies.get("id_token")}`,
    };

    axios({
      url,
      method: "POST",
      data: postBody,
      responseType: "blob", // important,
      headers,
    }).then((response) => {
      let data = response.data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        var csvData = new Blob([response.data], { type: "text/csv" });
        window.navigator.msSaveOrOpenBlob(csvData, fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        {
          datatype === "combined"
            ? setDisabledDownloading(
                (prevObj) =>
                  (prevObj = { ...prevObj, rawDataDownloading: false })
              )
            : setDisabledDownloading(
                (prevObj) =>
                  (prevObj = { ...prevObj, combinedZeroDownloading: false })
              );
        }
      }
    });
  }

  //batch download files zero + combined zero
  function batchDownloadFile(
    searchList,
    selectedActivity,
    selectedAthlete,
    startFilterDate,
    endFilterDate,
    datatype
  ) {
    let url = `${API_HOST}/sessionData/getSockSessionData`;
    let zip = new JSZip();
    {
      datatype === "combined"
        ? setDisabledDownloading(
            (prevObj) => (prevObj = { ...prevObj, rawDataDownloading: true })
          )
        : setDisabledDownloading(
            (prevObj) =>
              (prevObj = { ...prevObj, combinedZeroDownloading: true })
          );
    }

    const headers = {
      Pragma: "no-cache", //needed for IE 11
      Authorization: `Bearer ${Cookies.get("id_token")}`,
    };

    function handleBatchDownloadFile(sessions) {
      if (sessions.length > 50) {
        alert("You cannot download more than 50 files at a time");
        {
          datatype === "combined"
            ? setDisabledDownloading(
                (prevObj) =>
                  (prevObj = { ...prevObj, rawDataDownloading: false })
              )
            : setDisabledDownloading(
                (prevObj) =>
                  (prevObj = { ...prevObj, combinedZeroDownloading: false })
              );
        }
      } else {
        let promiseArray = [];
        let delayIncrement = 500;
        let delay = 0;

        if (sessionInputed.length > 0) {
          for (let i = 0; i < sessions.length; i++) {
            let session = Number(sessions[i]);
            let dataT = datatype;

            let tempPromise = new Promise((resolve) =>
              setTimeout(resolve, delay)
            ).then(() => {
              //now - after the delay - we call the API!
              console.log("about to call API for sessionID  " + session);
              console.log("time : " + new Date().toISOString());

              return axios({
                url,
                method: "POST",
                data: { sessionID: session, datatype: dataT },
                responseType: "blob",
                headers,
              }).catch(function () {
                console.log("error for sessionID  " + session);
              });
            });

            promiseArray.push(tempPromise);

            // After every 20 downloads increase delay by 250 milliseconds to reduce server load
            if (i > 0 && i % 20 === 0) {
              delayIncrement = delayIncrement + 250;
            }

            delay += delayIncrement;
          }
        } else {
          for (let i = 0; i < sessions.length; i++) {
            let session = sessions[i];
            let dataT = datatype;

            let tempPromise = new Promise((resolve) =>
              setTimeout(resolve, delay)
            ).then(() => {
              //now - after the delay - we call the API!
              console.log("about to call API for sessionID  " + session.ID);
              console.log("time : " + new Date().toISOString());

              return axios({
                url,
                method: "POST",
                data: { sessionID: session.ID, datatype: dataT },
                responseType: "blob",
                headers,
              }).catch(function () {
                console.log("error for sessionID  " + session.ID);
              });
            });

            promiseArray.push(tempPromise);

            // After every 20 downloads increase delay by 250 milliseconds to reduce server load
            if (i > 0 && i % 20 === 0) {
              delayIncrement = delayIncrement + 250;
            }

            delay += delayIncrement;
          }
        }

        let fileName =
          getBatchFileName(
            selectedActivity,
            selectedAthlete,
            startFilterDate,
            endFilterDate,
            `rawDataBatch`
          ) + ".zip";
        Promise.all(promiseArray).then(function (results) {
          console.log("all functions called - now do zip!!!!");

          results.forEach((result) => {
            if (result && result.config && result.config.data && result.data) {
              let thisSession = getSessionFromList(
                parseInt(JSON.parse(result.config.data).sessionID),
                searchList
              );
              if (datatype === "combined") {
                zip.file(
                  `${thisSession.playerName.split(" ").join("")}_${
                    thisSession.ActivityName
                  }_` +
                    "rawData_" +
                    JSON.parse(result.config.data).sessionID.toString() +
                    ".csv",
                  new Blob([result.data])
                );
              }

              if (datatype === "combinedZero") {
                zip.file(
                  `${thisSession.playerName.split(" ").join("")}_${
                    thisSession.ActivityName
                  }_` +
                    "rawZeroData_" +
                    JSON.parse(result.config.data).sessionID.toString() +
                    ".csv",
                  new Blob([result.data])
                );
              }

              // old download name structure changed 02/02/2024
              // zip.file(
              //   `${thisSession.playerName.split(" ").join("")}_${
              //     thisSession.ActivityName
              //   }_` +
              //     "rawData_" +
              //     JSON.parse(result.config.data).sessionID.toString() +
              //     `_${datatype}` +
              //     ".csv",
              //   new Blob([result.data])
              // );
            }
          });
          zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, fileName);
          });
          {
            datatype === "combined"
              ? setDisabledDownloading(
                  (prevObj) =>
                    (prevObj = { ...prevObj, rawDataDownloading: false })
                )
              : setDisabledDownloading(
                  (prevObj) =>
                    (prevObj = { ...prevObj, combinedZeroDownloading: false })
                );
          }
        });
      }
    }

    //prioritise checked sessions
    if (sessionInputed.length > 0) {
      let sessions = sessionInputed;
      handleBatchDownloadFile(sessions);
    } else {
      handleBatchDownloadFile(searchList);
    }
  }

  //name with no spaces
  function getNamewithNoSpaces(input) {
    return input.replace(/\s+/g, "");
  }

  //download Handler Memory Data
  const downloadMemoryDataHandler = () => {
   
    const downloadFile = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        const blob = await response.blob();
        const link = document.createElement('a');
        const urlObject = URL.createObjectURL(blob);
        link.href = urlObject;
        link.setAttribute('download', getFileNameFromUrl(url)); // Set the filename for download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(urlObject); // Clean up the URL object
      } catch (error) {
        console.error(`Error downloading ${url}:`, error);
      }
    };
    
    const getFileNameFromUrl = (url) => {
      const parts = url.split('/');
      return parts[parts.length - 1] || 'download';
    };
    

    if(Object.keys(memorySessionData).length !== 0){
      const leftLink = memorySessionData.left
      const rightLink = memorySessionData.right
      const jsonLink = memorySessionData.json

      const arrayOfLinks = [leftLink, rightLink, jsonLink]
    }
  }

  //handle file download depending on which input is checked
  function handleFileDownload(id) {
    if (document.getElementById("normData")?.checked === true) {
      downloadNormalisedFile(id, sessionList);
    }
    if (document.getElementById("sessionMetrics")?.checked === true) {
      downloadCSVReport(parseInt(id));
    }
    if (document.getElementById("debugData")?.checked === true) {
      downloadDebugInfo(parseInt(id));
    }
    if (document.getElementById("rawData")?.checked === true) {
      downloadFile(parseInt(id), sessionList, "combined");
    }
    if (document.getElementById("rawDataZero")?.checked === true) {
      downloadFile(parseInt(id), sessionList, "combinedZero");
    }
    if (document.getElementById("downloadMemoryData")?.checked === true) {
      downloadMemoryDataHandler();
    }
    
  }

  //handle batch file download depending on which input is checked
  function handleBatchFileDownload(
    searchList,
    selectedActivity,
    selectedAthlete,
    startFilterDate,
    endFilterDate
  ) {
    if (document.getElementById("normData")?.checked === true) {
      batchDownloadNormalisedFile(
        searchList,
        selectedActivity,
        selectedAthlete,
        startFilterDate,
        endFilterDate
      );
    }
    if (document.getElementById("sessionMetrics")?.checked === true) {
      batchDownloadCSVReport(
        searchList,
        selectedActivity,
        selectedAthlete,
        startFilterDate,
        endFilterDate
      );
    }
    if (document.getElementById("debugData")?.checked === true) {
      batchDownloadDebugInfo(
        searchList,
        selectedActivity,
        selectedAthlete,
        startFilterDate,
        endFilterDate
      );
    }
    if (document.getElementById("rawData")?.checked === true) {
      batchDownloadFile(
        searchList,
        selectedActivity,
        selectedAthlete,
        startFilterDate,
        endFilterDate,
        "combined"
      );
    }
    if (document.getElementById("rawDataZero")?.checked === true) {
      batchDownloadFile(
        searchList,
        selectedActivity,
        selectedAthlete,
        startFilterDate,
        endFilterDate,
        "combinedZero"
      );
    }
  }

  //convert array to text for alerts
  function mapArrayToText(arr) {
    let text = "";

    arr.forEach((item) => (text += ` ${item}`));

    return text;
  }

  //when modal is open
  const openModalHandler = async () => {
    if(sessionID) {
      fetch(`${API_HOST}/sessionData/checkIfMemorySession/${sessionID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("id_token")}`,
        },
      }).then(async (res) => {
        if(res.ok){
          const data = await res.json();
          console.log("setting setMemorySessionData", data)
          await setMemorySessionData(data)
        }
      })
    }

  }

  return (
    <Dialog.Root onOpenChange={() => openModalHandler()}>
      <Dialog.Trigger asChild>
        <span disabled>{button}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title asChild>
            <h1 className="download-modal-title">
              {searchList === null ? "Download Files" : "Download File Batch"}
            </h1>
          </Dialog.Title>
          <Dialog.Description asChild>
            <div>
              <p className="download-modal-description">
                {searchList === null
                  ? "Please select the files you'd like to download from below."
                  : "Please select the file batch you'd like to download from below."}
              </p>
              {searchList === null ? (
                <p className="download-modal-description">
                  Session ID: {sessionID}
                </p>
              ) : (
                <></>
              )}
            </div>
          </Dialog.Description>
          <Dialog.Close onClick={() => close} asChild>
            <button className="IconButton" aria-label="Close">
              <Cross2Icon />
            </button>
          </Dialog.Close>
          <div className="download-modal-content">
            <div className="download-checks">
              {(props.user.permissionLevel === 1 ||
                props.user.permissionLevel === 2) && (
                <div
                  className="download-label-checkbox normData"
                  onClick={() => setIsChecked("normData")}
                >
                  <label
                    htmlFor="normData"
                    onClick={() => setIsChecked("normData")}
                  >
                    Normalised Data
                  </label>
                  <input
                    type="checkbox"
                    id="normData"
                    onClick={() => setIsChecked("normData")}
                  ></input>
                </div>
              )}
              <div
                className="download-label-checkbox sessionMetrics"
                onClick={() => setIsChecked("sessionMetrics")}
              >
                <label
                  htmlFor="sessionMetrics"
                  onClick={() => setIsChecked("sessionMetrics")}
                >
                  Session Metrics
                </label>
                <input
                  type="checkbox"
                  id="sessionMetrics"
                  onClick={() => setIsChecked("sessionMetrics")}
                ></input>
              </div>
              {(props.user.permissionLevel === 1 ||
                props.user.permissionLevel === 2) && (
                <div
                  className="download-label-checkbox debugData"
                  onClick={() => setIsChecked("debugData")}
                >
                  <label
                    htmlFor="debugData"
                    onClick={() => setIsChecked("debugData")}
                  >
                    Debug Data
                  </label>
                  <input
                    type="checkbox"
                    id="debugData"
                    onClick={() => setIsChecked("debugData")}
                  ></input>
                </div>
              )}
              {(props.user.permissionLevel === 1 ||
                props.user.permissionLevel === 2) && (
                <div
                  className="download-label-checkbox rawData"
                  onClick={() => setIsChecked("rawData")}
                >
                  <label
                    htmlFor="rawData"
                    onClick={() => setIsChecked("rawData")}
                  >
                    Raw Data
                  </label>
                  <input
                    type="checkbox"
                    id="rawData"
                    onClick={() => setIsChecked("rawData")}
                  ></input>
                </div>
              )}
              {(props.user.permissionLevel === 1 ||
                props.user.permissionLevel === 2) && (
                <div
                  className="download-label-checkbox rawDataZero"
                  onClick={() => setIsChecked("rawDataZero")}
                >
                  <label
                    htmlFor="rawDataZero"
                    onClick={() => setIsChecked("rawDataZero")}
                  >
                    Combined Zero Data
                  </label>
                  <input
                    type="checkbox"
                    id="rawDataZero"
                    onClick={() => setIsChecked("rawDataZero")}
                  ></input>
                </div>
              )}

              {(props.user.permissionLevel === 1 ||
                props.user.permissionLevel === 2) && props.sessionID && Object.keys(memorySessionData).length !== 0 && (
                  <div className="memory-data-container">
                    <h3>Download Memory Data</h3>
                    <div className="memory-data">
                      {/* <div
                        className="download-label-checkbox downloadMemoryData"
                        onClick={() => setIsChecked("downloadMemoryData")}
                      >
                        <label
                          htmlFor="downloadMemoryData"
                          onClick={() => setIsChecked("downloadMemoryData")}
                        >
                          Download Memory Data
                        </label>
                        <input
                          type="checkbox"
                          id="downloadMemoryData"
                          onClick={() => setIsChecked("downloadMemoryData")}
                        ></input>
                      </div> */}
                      <a href={`${memorySessionData.left}`}>Left Data</a>
                      <div style={{display: "flex", alignItems: "center"}}>
                        <a href={`${memorySessionData.json}`} target="_blank">Json Data</a>
                        <MainTooltip tooltipIcon={"warning"} tooltipText={"Json file download will open a new tab with the json data"}/>
                      </div>
                      <a href={`${memorySessionData.right}`}>Right Data</a>
                    </div>
                  </div>
              )}
            </div>

            <div className="download-modal-btns">
              <Button
                value={`${loadingFiles ? "Loading Files...." : "Download"}`}
                onClickHandler={
                  searchList === null
                    ? () => handleFileDownload(sessionID)
                    : () =>
                        handleBatchFileDownload(
                          searchList,
                          selectedActivity,
                          selectedAthlete,
                          startFilterDate,
                          endFilterDate
                        )
                }
                disabled={disableButton}
                warning={disableButton}
              />

              {checkedSessions.length === 1 && (
                <Dialog.Close>
                  <div
                    onClick={() => {
                      setCustomGraphLayoutModal(true);
                      dispatch(setSelectedSessionId(checkedSessions[0]));
                    }}
                  >
                    <Button
                      value={`Create Custom Report`}
                      disabled={checkedSessions.length > 1 ? true : false}
                    />
                  </div>
                </Dialog.Close>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
  };
};

export default React.memo(connect(mapStateToProps)(ReportsDownloadModal));
