/* eslint-disable */
import { Responsive, WidthProvider } from "react-grid-layout";
import DragIcon from "components/common/icons/dragIcon";
import EscapeIcon from "components/nav-components/nav-icons/escape-icon";
import SaveIcon from "components/common/icons/saveIcon";
import { useState } from "react";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

const ResponsiveGridLayout = WidthProvider(Responsive);

const MetricGridControls = ({ customizingTable, setCustomizingTable }) => {
  return (
    <div className="metric-grid-controls">
      <div className="grid-heading">
        <h2>Metrics</h2>
        {customizingTable && (
          <MainTooltip 
            tooltipText={
              "Drag and drop rows from the table below customize the order of the comparison graphs"
            }
            tooltipIcon={"info"}
            iconSize={25}
          />
        )}
      </div>
      <div className="grid-controls">
        {customizingTable && (
          <button
            onClick={() => {
              setCustomizingTable(!customizingTable);
            }}
          >
            <EscapeIcon />
          </button>
        )}
        <button
          onClick={() => {
            setCustomizingTable(!customizingTable);
          }}
        >
          {!customizingTable ? "Customize Layout" : "Save Layout"}
          {!customizingTable && <DragIcon color="#49AFC8" />}
          {customizingTable && <SaveIcon color="#49AFC8" />}
        </button>
      </div>
    </div>
  );
};

const SelectedSessionsMetricTable = ({
  comparisonType,
  formattedData,
  formattedDataForTable,
  formattedHeadersForTable,
  jumpSession,
  setCustomGridLayout,
  comparedSessionType,
}) => {

  const [customizingTable, setCustomizingTable] = useState(false);

  //layout for (Sprint, Jog, Walk)
  const gaitLayout = {
    lg: [
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "a",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "b",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "c",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
        i: "d",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 4,
        i: "e",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 5,
        i: "f",
        moved: false,
        static: false,
      },
    ],
  };

  //layout for (Double Leg Countermovement Jump, Double Leg Squat Jump)
  const jumpDoubleLegLayout1 = {
    lg: [
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "a",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "b",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "e",
        moved: false,
        static: false,
      },
    ],
  };

  //layout for (Double Leg Drop Jump, Double Leg Repeated Hops, Double Leg Depth Jump)
  const jumpDoubleLegLayout2 = {
    lg: [
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "a",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "b",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "c",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
        i: "d",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 4,
        i: "e",
        moved: false,
        static: false,
      },
    ],
  };

  //layout for (Single Leg Countermovement Jump, Single Leg Squat Jump)
  const jumpSingleLegLayout1 = {
    lg: [
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "a",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "b",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "c",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
        i: "d",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 4,
        i: "e",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 5,
        i: "f",
        moved: false,
        static: false,
      },
    ],
  };

  //layout for (Single Leg Repeated Hops)
  const jumpSingleLegLayout2 = {
    lg: [
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "c",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "d",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "g",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
        i: "h",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 4,
        i: "i",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 5,
        i: "j",
        moved: false,
        static: false,
      },
    ],
  };

  //layout for (Single Leg Drop Jump, Single Leg Depth Jump)
  const jumpSingleLegLayout3 = {
    lg: [
      {
        w: 1,
        h: 1,
        x: 0,
        y: 0,
        i: "c",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 1,
        i: "d",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 2,
        i: "g",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 3,
        i: "h",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 4,
        i: "i",
        moved: false,
        static: false,
      },
      {
        w: 1,
        h: 1,
        x: 0,
        y: 5,
        i: "j",
        moved: false,
        static: false,
      },
    ],
  };

  let layout =
    comparedSessionType === "Jog" ||
    comparedSessionType === "Sprint" ||
    comparedSessionType == "Walk"
      ? gaitLayout
      : comparedSessionType === "Double Leg Countermovement jump" ||
        comparedSessionType === "Double Leg Squat Jump"
      ? jumpDoubleLegLayout1
      : comparedSessionType === "Double Leg Drop Jump" ||
        comparedSessionType === "Double Leg Repeated Hops" ||
        comparedSessionType === "Double Leg Depth Jump"
      ? jumpDoubleLegLayout2
      : comparedSessionType === "Single Leg Countermovement Jump" ||
        comparedSessionType === "Single Leg Squat Jump"
      ? jumpSingleLegLayout1
      : comparedSessionType === "Single Leg Drop Jump" ||
        comparedSessionType === "Single Leg Depth Jump"
      ? jumpSingleLegLayout2
      : comparedSessionType === "Single Leg Repeated Hops"
      ? jumpSingleLegLayout3
      : "";

  if (!jumpSession) {
    return (
      <div className="comparison-metric-table">
        <MetricGridControls
          customizingTable={customizingTable}
          setCustomizingTable={setCustomizingTable}
        />
        <div className="comparison-metric-table-headings">
          <div className="metric-header">
            <h4>Metric</h4>
          </div>

          {formattedData.length < 50 ? (
            <div className="athletes-header">
              {formattedHeadersForTable.map((item) => (
                <div
                  className="athletes-header-container"
                  style={{
                    width: `${100 / formattedHeadersForTable.length}%`,
                    wordSpacing: "99999999px",
                  }}
                >
                  <h4>{item.athleteName}</h4>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="comparison-metric-table-data">
          <ResponsiveGridLayout
            className="layout"
            layouts={layout}
            breakpoints={{ lg: 0 }}
            cols={{ lg: 1 }}
            rowHeight={64}
            onLayoutChange={(e) => {
              setCustomGridLayout(e);
            }}
            isDraggable={customizingTable}
            isResizable={false}
          >
            {formattedDataForTable.map((item) => item && (
              <div
                className="comparison-metric-table-row"
                key={`${item.layoutKey}`}
              >
                <div className="metric-header">
                  <h4>{item.metricName}</h4>
                </div>
                {formattedData.length < 50 ? (
                  <div className="metric-data">
                    {item?.metricValues?.map((value) => {
                      if (item.valueCount > 1) {
                        return (
                          <div
                            className="metric-data-double"
                            style={{
                              width: `${100 / item.metricValues.length}%`,
                            }}
                          >
                            <p>
                              {value.averageLeftValue || value.maxLeftValue
                                ? (comparisonType === "average"
                                    ? value.averageLeftValue.toFixed(2)
                                    : value.maxLeftValue.toFixed(2)) + " L"
                                : "-"}
                            </p>
                            <p>
                              {value.averageRightValue || value.maxRightValue
                                ? (comparisonType === "average"
                                    ? value.averageRightValue.toFixed(2)
                                    : value.maxRightValue.toFixed(2)) + " R"
                                : "-"}
                            </p>
                          </div>
                        );
                      } else if (
                        item.valueCount < 2 &&
                        item.type === "symmetry"
                      ) {
                        return (
                          <div
                            className="metric-data-double"
                            style={{
                              width: `${100 / item.metricValues.length}%`,
                            }}
                          >
                            <p>
                              {value.averageSymmetryValue &&
                              value.maxSymmetryValue
                                ? (comparisonType === "average"
                                    ? value.averageSymmetryValue.toFixed(2) > 0
                                      ? value.averageSymmetryValue.toFixed(2)
                                      : value.averageSymmetryValue.toFixed(2) *
                                        -1
                                    : value.maxSymmetryValue.toFixed(2) > 0
                                    ? value.maxSymmetryValue.toFixed(2)
                                    : value.maxSymmetryValue.toFixed(2) * -1) +
                                  `${
                                    (value.averageSymmetryValue ||
                                      value.maxSymmetryValue) > 0
                                      ? " R"
                                      : " L"
                                  }`
                                : "-"}
                            </p>
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </ResponsiveGridLayout>
        </div>
        {formattedData.length < 50 ? (
          <div className="comparison-metric-table-sessionID">
            <div className="metric-header">
              <h4>Session ID</h4>
            </div>
            <div className="athletes-header">
              {formattedHeadersForTable.map((item) => (
                <h4
                  style={{ width: `${100 / formattedHeadersForTable.length}%` }}
                >
                  {item.sessionID}
                </h4>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return (
      <div className="comparison-metric-table">
        <MetricGridControls
          customizingTable={customizingTable}
          setCustomizingTable={setCustomizingTable}
        />
        <div className="comparison-metric-table-headings">
          <div className="metric-header">
            <h4>Metric</h4>
          </div>
          {formattedData.length < 50 ? (
            <div className="athletes-header">
              {formattedHeadersForTable.map((item) => (
                <div
                  className="athletes-header-container"
                  style={{
                    width: `${100 / formattedHeadersForTable.length}%`,
                    wordSpacing: "99999999px",
                  }}
                >
                  <h4>{item.athleteName}</h4>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="comparison-metric-table-data">
          <ResponsiveGridLayout
            className="layout"
            layouts={layout}
            breakpoints={{ lg: 0 }}
            cols={{ lg: 1 }}
            rowHeight={64}
            onLayoutChange={(e) => {
              setCustomGridLayout(e);
            }}
            isDraggable={customizingTable}
            isResizable={false}
          >
            {formattedDataForTable.map(
              (item) =>
                item.needsMetric && (
                  <div
                    className="comparison-metric-table-row"
                    key={item.layoutKey}
                  >
                    <div className="metric-header">
                      <h4>{item.metricName}</h4>
                    </div>

                    {formattedData.length < 50 ? (
                      <div className="metric-data">
                        {item.metricValues.map((value) => {
                          if (item.valueCount > 1 && item.type === "single") {
                            return (
                              <div
                                className="metric-data-double"
                                style={{
                                  width: `${100 / item.metricValues.length}%`,
                                }}
                              >
                                <p>
                                  {value.averageLeftValue || value.maxLeftValue
                                    ? (
                                        comparisonType === "average"
                                          ? value?.averageLeftValue?.toFixed(2)
                                          : value?.maxLeftValue?.toFixed(2)
                                      )
                                      ? value?.maxLeftValue?.toFixed(2) + " L"
                                      : "-"
                                    : "-"}
                                </p>
                                <p>
                                  {value.averageRightValue ||
                                  value.maxRightValue
                                    ? (
                                        comparisonType === "average"
                                          ? value?.averageRightValue?.toFixed(2)
                                          : value?.maxRightValue?.toFixed(2)
                                      )
                                      ? value?.maxRightValue?.toFixed(2) + " R"
                                      : "-"
                                    : "-"}
                                </p>
                              </div>
                            );
                          } else if (
                            item.valueCount < 2 &&
                            item.type === "symmetry"
                          ) {
                            return (
                              <div
                                className="metric-data-double"
                                style={{
                                  width: `${100 / item.metricValues.length}%`,
                                }}
                              >
                                <p>
                                  {comparisonType === "average" &&
                                  value.averageSymmetryValue &&
                                  value.averageSymmetryValue !== NaN
                                    ? (value.averageSymmetryValue?.toFixed(2) >
                                      0
                                        ? value.averageSymmetryValue?.toFixed(2)
                                        : value.averageSymmetryValue?.toFixed(
                                            2
                                          ) * -1) +
                                      `${
                                        value.averageSymmetryValue > 0
                                          ? " R"
                                          : " L"
                                      }`
                                    : comparisonType !== "average" &&
                                      value.maxSymmetryValue &&
                                      value.maxSymmetryValue !== NaN
                                    ? (value.maxSymmetryValue?.toFixed(2) > 0
                                        ? value.maxSymmetryValue?.toFixed(2)
                                        : value.maxSymmetryValue?.toFixed(2) *
                                          -1) +
                                      `${
                                        value.maxSymmetryValue > 0 ? " R" : " L"
                                      }`
                                    : "-"}
                                </p>
                              </div>
                            );
                          } else if (
                            item.valueCount < 2 &&
                            item.type === "double"
                          ) {
                            return (
                              <div
                                className="metric-data-double"
                                style={{
                                  width: `${100 / item.metricValues.length}%`,
                                }}
                              >
                                <p>
                                  {value.averageValue && value.maxValue
                                    ? comparisonType === "average"
                                      ? value.averageValue.toFixed(2) > 0
                                        ? value.averageValue.toFixed(2)
                                        : value.averageValue.toFixed(2) * -1
                                      : value.maxValue.toFixed(2) > 0
                                      ? value.maxValue.toFixed(2)
                                      : value.maxValue.toFixed(2) * -1
                                    : "-"}
                                </p>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )
            )}
          </ResponsiveGridLayout>
        </div>
        {formattedData.length < 50 ? (
          <div className="comparison-metric-table-sessionID">
            <div className="metric-header">
              <h4>Session ID</h4>
            </div>
            <div className="athletes-header">
              {formattedHeadersForTable.map((item) => (
                <h4
                  style={{ width: `${100 / formattedHeadersForTable.length}%` }}
                >
                  {item.sessionID}
                </h4>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
};

export default SelectedSessionsMetricTable;
