import React, { useMemo } from "react";
import "./tableRow.scss";
import Button from "components/common/button/button";
import { motion } from "framer-motion";

let PageSize = 10;

const TableRow = ({
  deactivateRow,
  data,
  heights = "30px",
  link,
  currentPage,
  pagination = false,
  value,
  tableType = null,
  handleHover = ()=>{},
  ...fields
}) => {
  const currentTableData = useMemo(() => {
    if (!pagination) {
      return data;
    }
    if (value.length >= 1) {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return data.slice(firstPageIndex, lastPageIndex);
    }
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, value,pagination]);

  return (
    <>
      {currentTableData.length >= 1 ? (
            <>
            {currentTableData.map((item, i) => (
              <motion.tr
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                onMouseOver={() => handleHover(item)}
                transition={{
                  duration: 0.6,
                  delay: 0.07 * i,
                  ease: [0, 0.71, 0.2, 1.01]
                }}
                style={{ marginBottom: "0.5rem" }}
                height={heights}
                key={i}
                onClick={link ? () => link(item.playerID) : undefined}
                className={`table-row-color table-row-items ${!item.isPlayer ? "td-items-org" : ""}`}
              >
                {Object.entries(fields.fields).map(([fieldKey, fieldValue]) => (
                  <td key={fieldValue} className={`td-items`} >
                    {item[fieldKey]}
                  </td>
                ))}
                {tableType && tableType === "admin" ? (
                  <td className="td-items">
                    <Button value={"Deactivate"} onClick={() => deactivateRow()} />
                  </td>
                ) : null}
              </motion.tr>
            ))}
      </>

      ) : (

            <tr>
              <td className="td-items">No Players Found</td>
            </tr>

      )}
    </>
  );
};

export default React.memo(TableRow);
