import { api } from './api'

export const loginAPI = (data) => {  
  return api.post('users/login', data).then(res => {
    return res     
  })
}

export const signUpAPI = (data) => {  
  return api.post('users/signup', data).then(res => {
    return res     
  })
}

export const validateEmail = (code) => {  
  return api.get(`/users/confirmcode/${code}`).then(res => {
    return res     
  })
}

export const sendResetPassEmail = (email) => {  
  return api.get(`/users/reset-password/init/${email}`).then(res => {
    return res     
  })
}

export const resetPassAPI = (obj) => {  
  return api.put(`/users/reset-password/complete`,obj).then(res => {
    return res     
  })
}