/* eslint-disable */
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  getPlayerOptions,
  getTagsOptions,
  options,
} from "components/playerActivityFunctions/playerActivityFunctions";
import Select from "react-select";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import ReportsDownloadModal from "../downloadModal/reportsDownloadModal";
import { getActivities } from "store/activitySlice";
import { getTags } from "store/tagSlice";
import {
  setCurrentSearchTerm,
  setSelectedActivity,
  setSelectedAthlete,
  setSelectedTag,
  setFiltering,
  resetFilters,
  setNoRecords,
  setCheckedSessions2,
} from "store/playerActivitySlice";
import {
  addTagsToSessionsAPI,
  getMultipleGaitSessionAPI,
  removeTagFromSessionAPI,
} from "api/session";
import { getMultipleJumpSessionAPI } from "api/session";
import FilterIcon from "../icons/filter";
import FilteringModal from "../filteringModal/FilteringModal";
import { DownloadIcon } from "@radix-ui/react-icons";
import CompareIcon from "../icons/compareIcon";
import ResetIcon from "../icons/reset";
import MainButton from "../reuseableButtonComponent/MainButton";
import MainModal from "../mainModal/MainModal";
import TagsIcon from "../icons/tags";
import CloseIcon from "../icons/closeIcon";
import {
  addOrganisationTagAPI,
  getOrganisationTagsAPI,
  removeOrganisationTagAPI,
} from "api/organisations";
import Tag from "../tag/Tag";
import { nanoid } from "@reduxjs/toolkit";
import { getSessionList } from "store/sessionListSlice";
import Spinner from "../spinner/spinner";
import Toggler from "../toggler/Toggler";
import DeleteIcon from "../icons/deleteIcon";
import { postFile } from "api/apiHelper";
import { api } from "api/api";
import UploadIcon from "../icons/uploadIcon";
import Cookies from "js-cookie";
import JSZip, { file } from "jszip";
import { msToTime } from "utils/helper";
import WarningIndicator from "../warningIndicator/WarningIndicator";
import SockIcon from "../icons/sockIcon";
import CalibrationIcon from "../icons/calibrationIcon";
import FileIcon from "../icons/fileIcon";
import { API_HOST } from "utils/constant";
import UploadingIndicationButton from "../uploadingIndicator/UploadIndicationButton";
import { setSelectedSessionId, setGraphModal } from "store/playerActivitySlice";

const childVariants = {
  initial: { opacity: 0, x: -50 },
  animate: { opacity: 1, x: 0 },
};

function FilterContainer(props) {
  const [focusedInput, setFocusedInput] = useState(null);
  const [modal, setModal] = useState(false);
  const [exerciseTypeGroup, setExerciseTypeGroup] = useState([]);
  const [exerciseGroups, setExerciseGroups] = useState([]);

  //ref
  const selectRef = React.useRef();

  const [organisationAthleteGroups, setOrganisationAthleteGroups] = useState(
    []
  );
  const [organisationGroups, setOrganisationGroups] = useState([]);

  const [tagGroupOptions, setTagGroupOptions] = useState([]);
  const [tagGroups, setTagGroups] = useState([]);

  const [checkFilter, setCheckFilter] = useState(false);

  const [isMounted, setIsMounted] = React.useState(false);

  //tags filtering state
  const [selectedSessionsOrgs, setSelectedSessionsOrgs] = useState();
  const [selectedSessionList, setSelectedSessionList] = useState();
  const [orgTags, setOrgTags] = useState();
  const [newTagInfo, setNewTagInfo] = useState({ tagName: "", tagDetails: "" });
  const [selectedTagsForAssignment, setSelectedTagsForAssignment] = useState(
    []
  );
  const [batchAddTagsPostBody, setBatchAddTagsPostBody] = useState({
    sessionTagList: [],
  });
  const [tagsBeingAdded, setTagsBeingAdded] = useState(false);
  const [tagDeletionToggler, setTagDeletionToggler] = useState(false);

  //selected files for upload
  const [filesSelectedForUpload, setFilesSelectedForUpload] = useState([]);
  const [binaryFilesSelectedForUpload, setBinaryFilesSelectedForUpload] =
    useState([]);
  const [textFilesSelectedForUpload, setTextFilesSelectedForUpload] = useState(
    []
  );
  const [allSelectedFilesForUpload, setAllSelectedFilesForUpload] = useState(
    []
  );
  const [pairedSelectedSessionsForUpload, setPairedSelectedSessionsForUpload] =
    useState([]);

  //files selected for upload validation state
  const [validationForFilesForUpload, setValidationForFilesForUpload] =
    useState([]);

  const [fileValidation, setFileValidation] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState([]);
  const [filesBeingUploaded, setFilesBeingUploaded] = useState(false);
  const [whichIndexUploadInit, setWhichIndexUploadInit] = useState(null);

  const {
    orgList,
    filterDate,
    fromSessionInput,
    fullSessionList,
    searchList,
    setFilterDate,
    setSearchList,
    sessionListStatus,
    setSessionsForComparison,
    setComparingSessions,
    setComparedSessionType,
    setCustomGraphLayoutModalOpen,
    windowWidth,
    userPermisionLevel,
    user,
  } = props;

  const dispatch = useDispatch();

  let comparisonLimit =
    windowWidth >= 768 && windowWidth < 820
      ? 10
      : windowWidth >= 820 && windowWidth < 1024
      ? 12
      : windowWidth >= 1024 && windowWidth < 1440
      ? 15
      : windowWidth >= 1440 && windowWidth < 2560
      ? 18
      : windowWidth >= 2560
      ? 30
      : 5;

  const { activityList, playerActivity, tagList } = useSelector((state) => ({
    activityList: state.activity,
    playerActivity: state.playerActivity,
    tagList: state.tag,
  }));

  const { graphModal } = useSelector((state) => state.playerActivity);

  const selectHeight =
    window.innerWidth >= 821 ? 442 : fromSessionInput ? 230 : 285;

  const {
    checkedSessions,
    checkedSessionForComparison,
    filtering,
    selectedActivity,
    selectedAthlete,
    selectedTag,
    currentSearchTerm,
  } = useSelector((state) => state.playerActivity);

  /// console log of state

  console.log(props);
  console.log(activityList);
  console.log(playerActivity);

  React.useEffect(() => {
    setIsMounted(true);

    return () => {
      if (playerActivity.filtering) {
        dispatch(resetFilters());
      }
      setIsMounted(false);
    };
  }, [playerActivity.filtering]);

  React.useEffect(() => {
    if (activityList.status === "idle") {
      dispatch(getActivities());
    }
  }, [activityList.status]);

  React.useEffect(() => {
    if (tagList.status === "idle") {
      dispatch(getTags());
    }
  }, [tagList.status]);

  //state reset
  useEffect(() => {
    if (tagsBeingAdded === true) {
      setTimeout(() => {
        dispatch(getSessionList());
      }, 200);
    }
    setTimeout(() => {
      setTagsBeingAdded(false);
    }, 1500);
  }, [tagsBeingAdded]);

  //use effect added to create an array of unique org ids of the selected sessions
  useEffect(() => {
    const checkedSessionOrgs = [];
    const listOfCheckedSessions = [];
    //only do this if checked sessions has a length > than 0
    if (checkedSessions.length > 0) {
      checkedSessions.forEach((item) => {
        fullSessionList.forEach((session) => {
          //change item to number since item is a String type
          if (session.ID === Number(item)) {
            listOfCheckedSessions.push(session);
            checkedSessionOrgs.push(session.orgID);
          }
        });
      });
    }
    ///setting the state to the spread of a new set of the array of org ids of the selected session
    setSelectedSessionList(listOfCheckedSessions);
    setSelectedSessionsOrgs([...new Set(checkedSessionOrgs)]);
  }, [checkedSessions, fullSessionList]);

  //use effect for batch tag
  useEffect(() => {
    if (
      selectedTagsForAssignment.length > 0 &&
      selectedSessionList.length > 0
    ) {
      selectedSessionList.forEach((session) => {
        selectedTagsForAssignment.forEach((tag) => {
          const newTag = {
            tid: JSON.parse(tag).id,
            sid: session.ID,
          };

          // Check if the tag is already in sessionTagList
          const existingTag = batchAddTagsPostBody.sessionTagList.find(
            (t) => t.tid === newTag.tid && t.sid === newTag.sid
          );

          if (!existingTag) {
            // Add the tag only if it's not a duplicate
            setBatchAddTagsPostBody((prevPostBody) => ({
              sessionTagList: [...prevPostBody.sessionTagList, newTag],
            }));
          }
        });
      });
    }
  }, [selectedTagsForAssignment, selectedSessionList]);

  //testing file reading
  useEffect(() => {
    if (filesSelectedForUpload) {
      [...filesSelectedForUpload].forEach((file) => {
        //printFile(file);
        if (file.name.includes(".INF")) {
          // setTextFilesSelectedForUpload(prevData =>  ([...prevData, file]))
          setTextFilesSelectedForUpload((prevData) => {
            // Check if the file already exists in prevData
            const fileExists = prevData.some(
              (existingFile) => existingFile.name === file.name
            );

            // If the file doesn't exist, add it to prevData
            if (!fileExists) {
              return [...prevData, file];
            }

            // Otherwise, return prevData unchanged
            return prevData;
          });
        }
        if (file.name.includes(".TXT")) {
          // setBinaryFilesSelectedForUpload(prevData =>  ([...prevData, file]))
          setBinaryFilesSelectedForUpload((prevData) => {
            // Check if the file already exists in prevData
            const fileExists = prevData.some(
              (existingFile) => existingFile.name === file.name
            );

            // If the file doesn't exist, add it to prevData
            if (!fileExists) {
              return [...prevData, file];
            }

            // Otherwise, return prevData unchanged
            return prevData;
          });
        }
      });
    }
  }, [filesSelectedForUpload]);

  useEffect(() => {
    setAllSelectedFilesForUpload([
      ...binaryFilesSelectedForUpload,
      ...textFilesSelectedForUpload,
    ]);
  }, [binaryFilesSelectedForUpload, textFilesSelectedForUpload]);

  useEffect(() => {
    // [...allSelectedFilesForUpload].forEach((file) => {
    //   printFile(file);
    // });
    const processBatch = async () => {
      const batchPromises = allSelectedFilesForUpload.map(async (file) => {
        printFile(file);
      });
      await Promise.all(batchPromises);
    };

    processBatch();
  }, [allSelectedFilesForUpload]);

  // useEffect(() => {
  //   const filesWithBothFeet = validationForFilesForUpload.filter(
  //     (file) => file.bilateral
  //   );
  // }, [validationForFilesForUpload]);

  const setForComparison = new Set(checkedSessionForComparison);

  ///opening sesion after files have been added
  function handleOpenSession(sessionID, canOpen = false) {
    console.log(sessionID);
    dispatch(getSessionList());
    setTimeout(() => {
      if (canOpen) {
        console.log("dispatched");
        dispatch(setSelectedSessionId(sessionID));
        dispatch(setGraphModal(!graphModal));
      }
    }, 2000);
  }

  const onDatesChange = React.useCallback(
    ({ startDate, endDate }) => {
      setFilterDate({ startDate, endDate });
    },
    [filterDate]
  );

  const onFocusChange = React.useCallback(
    (focusedInput) => setFocusedInput(focusedInput),
    [focusedInput]
  );

  const isOutsideRange = React.useCallback(() => false, []); // No dependencies here as the function always returns false

  //returns boolean value if any filtering occurs used to hide download button
  function filterChecker() {
    if (filtering || checkedSessions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function checkedChecker() {
    if (checkedSessions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function editForm() {
    setModal((prevEditForm) => !prevEditForm);
    document.body.classList.add("modal-open");
  }

  //do filter function
  const doFilter = React.useCallback(() => {
    if (!isMounted) {
      // The component is not mounted, so return early to avoid running the filter logic.
      return;
    }
    let startDate = filterDate.startDate
      ? new Date(filterDate.startDate)
      : null;
    let endDate = filterDate.endDate ? new Date(filterDate.endDate) : null;

    if (endDate) {
      //setting hours for the end of the day
      endDate.setHours(23, 59, 59, 999);
    }

    if (startDate) {
      //setting hours for the start of the day
      startDate.setHours(0, 0, 0, 1);
    }

    let athleteIdSet = new Set();
    let activitySet = new Set();
    let tagIdSet = new Set();

    if (selectedAthlete !== null && !organisationAthleteGroups.length > 0) {
      //athleteIdSet.add(parseInt(selectedAthlete.value));
      selectedAthlete.forEach((athlete) => {
        athleteIdSet.add(parseInt(athlete.value));
      });
      // athleteIdSet.add(...selectedAthlete?.map(item => parseInt(item.value)));
    }

    if (organisationAthleteGroups.length > 0) {
      for (let organisation of organisationAthleteGroups) {
        athleteIdSet.add(parseInt(organisation.value));
      }
    }

    if (
      selectedActivity !== null &&
      selectedActivity?.length !== 0 &&
      !exerciseTypeGroup.length > 0
    ) {
      selectedActivity.forEach((item) => {
        activitySet.add(item?.label?.toLowerCase()?.trim());
      });
      // activitySet.add(selectedActivity.label.toLowerCase().trim());
    }

    if (exerciseTypeGroup.length > 0) {
      for (let exercise of exerciseTypeGroup) {
        activitySet.add(exercise.label.toLowerCase().trim());
      }
    }

    if (selectedTag !== null && !tagGroupOptions.length > 0) {
      selectedTag.forEach((item) => {
        tagIdSet.add(parseInt(item.value));
      });
      // tagIdSet.add(parseInt(selectedTag.value));
    }

    if (tagGroupOptions.length > 0) {
      for (let tag of tagGroupOptions) {
        tagIdSet.add(parseInt(tag.value));
      }
    }

    let filteredSessionList = fullSessionList.filter((row) => {
      let date = new Date(row.tsCreated);

      // Date condition check
      if (
        (startDate && date.getTime() <= startDate.getTime()) ||
        (endDate && date.getTime() >= endDate.getTime())
      ) {
        return false;
      }

      // Athlete ID condition check
      if (athleteIdSet.size > 0 && !athleteIdSet.has(row.playerID)) {
        return false;
      }

      const hasValue = (set, array) => {
        let value = false;
        for (let item of array) {
          if (set.has(item.TID)) {
            value = true;
            break;
          }
        }
        return value;
      };

      //tag id condition checl
      if (tagIdSet.size > 0 && !hasValue(tagIdSet, JSON.parse(row.tags))) {
        return false;
      }

      // Activity condition check
      if (
        activitySet.size > 0 &&
        !activitySet.has((row.ActivityName?.toLowerCase() ?? "").trim())
      ) {
        return false;
      }
      setCheckFilter(true);
      return true;
    });

    if (filteredSessionList.length <= 0) {
      dispatch(setNoRecords(true));
      setSearchList([]);
    } else {
      setSearchList(filteredSessionList);
      dispatch(setFiltering(filteredSessionList.length > 0));
    }
  }, [
    filterDate.endDate,
    filterDate.startDate,
    selectedAthlete,
    selectedTag,
    organisationAthleteGroups,
    selectedActivity,
    exerciseTypeGroup,
    sessionListStatus,
  ]);

  //activty change function
  function activityChange(selectedActivity) {
    dispatch(setSelectedActivity(selectedActivity));
    //clear selection when chaging exercise type
    dispatch(setCheckedSessions2([]));
    setExerciseGroups([]);
    setExerciseTypeGroup([]);
  }

  //athlete change function
  function athleteChange(selectedAthlete) {
    dispatch(setSelectedAthlete(selectedAthlete));
    setOrganisationGroups([]);
    setOrganisationAthleteGroups([]);
  }

  //tag change function
  function tagChange(selectedTag) {
    dispatch(setSelectedTag(selectedTag));
  }

  React.useEffect(() => {
    // Check if state values are not in their initial state]

    if (
      selectedActivity !== null ||
      selectedTag !== null ||
      selectedAthlete !== null ||
      filterDate.endDate !== null || // Replace with your initial state for filterDate.endDate
      exerciseTypeGroup.length > 0 ||
      organisationAthleteGroups.length > 0 ||
      exerciseGroups.length > 0 || // Replace with your initial state for exerciseGroups
      organisationGroups.length > 0 // Replace with your initial state for organisationGroups
    ) {
      if (exerciseTypeGroup.length > 0) {
        dispatch(setSelectedActivity(null));
      }
      if (organisationAthleteGroups.length > 0) {
        dispatch(setSelectedAthlete(null));
      }
      //causing setFiltered triggered to false
      doFilter();
    }
  }, [
    selectedActivity,
    selectedAthlete,
    selectedTag,
    filterDate.endDate,
    exerciseTypeGroup,
    organisationAthleteGroups,
    exerciseGroups,
    organisationGroups,
    sessionListStatus,
  ]);

  const reset = () => {
    setSearchList();
    setFilterDate({ startDate: null, endDate: null });
    setExerciseTypeGroup([]);
    setExerciseGroups([]);
    setOrganisationAthleteGroups([]);
    setOrganisationGroups([]);
    setTagGroupOptions([]);
    setTagGroups([]);
    dispatch(resetFilters());

    //makes sure all checkboxes are cleared
    let inputs = document.querySelectorAll(".checkboxes");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
  };

  const resetFilteringSelects = () => {
    setExerciseTypeGroup([]);
    setExerciseGroups([]);
    setOrganisationAthleteGroups([]);
    setOrganisationGroups([]);
    setTagGroupOptions([]);
    setTagGroups([]);
    dispatch(resetFilters());
    //makes sure all checkboxes are cleared
    let inputs = document.querySelectorAll(".checkboxes");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
  };

  const doSearch = React.useCallback((searchTerm) => {
    dispatch(setFiltering(true));
    dispatch(setCurrentSearchTerm(searchTerm));
    const filteredList = fullSessionList.filter((row) => {
      const date = new Date(row.tsCreated);
      const lowerCaseSearchTerm = searchTerm?.toLowerCase();

      return (
        (!filterDate.startDate ||
          !filterDate.endDate ||
          (date >= new Date(filterDate.startDate) &&
            date <= new Date(filterDate.endDate).setHours(23, 59, 59, 999))) &&
        (!selectedActivity ||
          row.ActivityName?.toLowerCase().trim() ===
            selectedActivity.label?.toLowerCase().trim()) &&
        (!selectedAthlete ||
          row.playerID === parseInt(selectedAthlete.value)) &&
        (!searchTerm ||
          [row.name, row.detail, row.ID, row.playerName, row.ActivityName].some(
            (field) => String(field).toLowerCase().includes(lowerCaseSearchTerm)
          ))
      );
    });

    setSearchList(filteredList);
  });

  React.useEffect(() => {
    if (currentSearchTerm.length >= 1) {
      doSearch(currentSearchTerm);
    }
  }, []);

  //handle search change
  function handleSearchChange(event) {
    doSearch(event.target.value);
  }

  //get tags in org
  const getOrgTags = () => {
    const orgID =
      selectedSessionsOrgs?.length === 0
        ? orgList?.[0]?.["OrgID"]
        : selectedSessionsOrgs?.[0];
    getOrganisationTagsAPI(orgID).then((tags) => {
      if (tags) {
        setOrgTags(tags.data);
        setNewTagInfo({ tagName: "", tagDetails: "" });
        setSelectedTagsForAssignment([]);
        setBatchAddTagsPostBody({
          sessionTagList: [],
        });
      } else {
        throw console.error("Error getting Tags");
      }
    });
  };

  //create new tag
  const createNewTag = async () => {
    const orgID =
      selectedSessionsOrgs?.length === 0
        ? orgList?.[0]?.["OrgID"]
        : selectedSessionsOrgs?.[0];

    const postBody = {
      tag: newTagInfo.tagName,
      detail: newTagInfo.tagDetails,
      orgid: orgID,
      createdByUserID: user.id,
    };

    if (newTagInfo.tagName.length > 0) {
      const data = await addOrganisationTagAPI(postBody);
      await getOrgTags();
      await dispatch(getTags());
      await setNewTagInfo({ tagName: "", tagDetails: "" });
    }
  };

  //selected tags for assignemt handler
  const addTagToAssignemtList = (data) => {
    let currentListOfTags = selectedTagsForAssignment;

    if (!currentListOfTags.includes(JSON.stringify(data))) {
      setSelectedTagsForAssignment((prevData) => [
        ...prevData,
        JSON.stringify(data),
      ]);
    }
  };

  //remove from tags selected to assignment
  const removeTagFromAssignmentList = (data) => {
    let currentListOfTags = selectedTagsForAssignment;

    if (currentListOfTags.includes(JSON.stringify(data))) {
      const indexToRemove = currentListOfTags.indexOf(JSON.stringify(data));
      currentListOfTags.splice(indexToRemove, 1);
      setSelectedTagsForAssignment([...currentListOfTags]);
    }
  };

  //add tags to sessions handler
  const addTagsToSessionsHandler = () => {
    if (batchAddTagsPostBody.sessionTagList.length > 0) {
      addTagsToSessionsAPI(batchAddTagsPostBody);
      setTagsBeingAdded(true);
    }
  };

  //delete tag handler
  const deleteTagFromOrg = async (tagData) => {
    const data = await removeOrganisationTagAPI(tagData.id);
    await getOrgTags();
    await dispatch(getTags());
    await setTimeout(() => {
      dispatch(getSessionList());
    }, 200);
  };

  //remove tag from session
  const removeTagFromSession = async (tagData) => {
    const data = await removeTagFromSessionAPI(tagData.STID);
    await getOrgTags();
    await dispatch(getTags());
    await setTimeout(() => {
      dispatch(getSessionList());
    }, 200);
  };

  //date handler
  const dateFormatHandler = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  //handleFileChange
  const handleFileChange = (e) => {
    let filesNotPresentInList = e.target.files;
    if (filesSelectedForUpload?.length > 0) {
      filesNotPresentInList = [...e.target.files].filter(
        (item) =>
          ![...filesSelectedForUpload].some((check) => check.name === item.name)
      );
    }
    if (e.target.files) {
      setFilesSelectedForUpload((prevData) => [
        ...prevData,
        ...filesNotPresentInList,
      ]);
      setBinaryFilesSelectedForUpload([]);
      setTextFilesSelectedForUpload([]);
      setPairedSelectedSessionsForUpload([]);
    }
  };

  function sortFiles(fileObjects) {
    // Separate calibration and non-calibration files

    const calibrationFiles = [];
    const infFiles = [];
    const txtFiles = [];
    for (const fileObj of fileObjects) {
      if (fileObj.isCalibrationFile) {
        calibrationFiles.push(fileObj);
      } else if (fileObj.fileType === "INF") {
        infFiles.push(fileObj);
      } else if (fileObj.fileType === "TXT") {
        txtFiles.push(fileObj);
      }
    }

    // Combine the sorted arrays
    return [...calibrationFiles, ...infFiles, ...txtFiles];
  }

  const handleConvertActivtyFromID = (id) => {
    const activityObject = [...props.activityList].filter(
      (activity) => activity.id === id
    )[0]?.name;

    return activityObject;
  };

  const createZip = async (files) => {
    const zip = new JSZip();

    for (let file of files) {
      let fileName = file?.name;
      zip.file(fileName, file);
    }

    const zipContent = await zip.generateAsync({ type: "blob" });
    return zipContent;
  };

  const handleFileUpload1 = async (filesForUpload, index = null) => {
    setFilesBeingUploaded(true);

    const leftFiles = filesForUpload.filter((item) => item.name.includes("L"));
    const rightFiles = filesForUpload.filter((item) => item.name.includes("R"));

    const uniqueID = nanoid();

    let zipRight = new JSZip();
    let zipBlobRight;

    for (let file of rightFiles) {
      let fileName = file?.name;
      zipRight.file(fileName, file);
    }

    const zipLeftContent = await createZip(leftFiles);
    const zipRightContent = await createZip(rightFiles);

    await zipRight.generateAsync({ type: "blob" }).then((blobdata) => {
      zipBlobRight = new Blob([blobdata]);
    });

    const leftUpload = async () => {
      await setFileUploadStatus((prevStatus) => {
        const newStatus = prevStatus.map((status) => {
          if (status.file === index) {
            return { ...status, statusLeft: 1 };
          }

          return status;
        });

        return newStatus;
      });
      //First request Made

      await fetch(`${API_HOST}/memory/startUploadV2/${nanoid()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("id_token")}`,
        },
      }).then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          if (data.url && data.key) {
            //Second  request Made
            await fetch(data.url, {
              method: "PUT",
              body: zipLeftContent,
              headers: {
                //might be wrong if issue try zip
                "Content-Type": "application/zip",
                "Content-Disposition": `attachment; filename=${data.key}`,
              },
            }).then(async (res2) => {
              if (res2.ok) {
                await setFileUploadStatus((prevStatus) => {
                  const newStatus = prevStatus.map((status) => {
                    if (status.file === index) {
                      return { ...status, statusLeft: 2 };
                    }

                    return status;
                  });

                  return newStatus;
                });

                const url = `${data.processMemoryURL}${data.uploadID}`;
                const auth = `Bearer ${Cookies.get("id_token")}`;
                const timeoutInMilliseconds = 7000; // 7 seconds

                const maxRetries = 3; // Set the maximum number of retries

                const makeCallToAPI = async () => {
                  let retryCount = 0;

                  const fetchWithRetry = async () => {
                    try {
                      const controller = new AbortController();
                      const timeout = setTimeout(() => {
                        controller.abort(); // Abort the request after the specified timeout
                      }, timeoutInMilliseconds);

                      const response = await fetch(
                        `${data.processMemoryURL}${data.uploadID}`,
                        {
                          method: "GET",
                          headers: {
                            Authorization: auth,
                          },
                          signal: controller.signal, // Pass the signal to the fetch request
                        }
                      );

                      clearTimeout(timeout); // Clear the timeout if the request completes before the timeout

                      if (response.ok) {
                        const dataRes = await response.json();

                        setFilesBeingUploaded(false);

                        await setFileUploadStatus((prevStatus) => {
                          const arrayLength =
                            dataRes.results.length > 1 ? 1 : 0;
                          const newStatus = prevStatus.map((status) => {
                            if (status.file === index) {
                              return {
                                ...status,
                                statusLeft: 3,
                                newSessionID:
                                  dataRes.results[arrayLength].sessionID,
                              };
                            }

                            return status;
                          });

                          return newStatus;
                        });
                      } else {
                        setFilesBeingUploaded(false);
                        throw new Error(`HTTP error: ${response.status}`);
                      }
                    } catch (error) {
                      if (retryCount < maxRetries) {
                        retryCount++;
                        await fetchWithRetry(); // Retry the request
                      } else {
                        setFilesBeingUploaded(false);
                        console.error("Tried 3 times. Request failed.");
                        await setFileUploadStatus((prevStatus) => {
                          const newStatus = prevStatus.map((status) => {
                            if (status.file === index) {
                              return { ...status, uploadFailed: true };
                            }

                            return status;
                          });

                          return newStatus;
                        });
                        // Move on to the next request here
                      }
                    }
                  };

                  await fetchWithRetry();
                };

                // Call makeCallToAPI to start the process
                setTimeout(async () => {
                  await makeCallToAPI();

                  if (rightFiles.length > 0) {
                    await rightUpload();
                  }
                }, 1000); // 1000 milliseconds = 1 second
              } else {
                setFilesBeingUploaded(false);
              }
            });
          } else {
            setFilesBeingUploaded(false);
          }
        } else {
          setFilesBeingUploaded(false);
        }
      });
    };

    const rightUpload = async () => {
      await setFileUploadStatus((prevStatus) => {
        const newStatus = prevStatus.map((status) => {
          if (status.file === index) {
            return { ...status, statusRight: 1 };
          }

          return status;
        });

        return newStatus;
      });
      setFilesBeingUploaded(true);
      await fetch(`${API_HOST}/memory/startUploadV2/${nanoid()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("id_token")}`,
        },
      }).then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          if (data.url && data.key) {
            //Second  request Made
            await fetch(data.url, {
              method: "PUT",
              body: zipRightContent,
              headers: {
                //might be wrong if issue try zip
                "Content-Type": "application/zip",
                "Content-Disposition": `attachment; filename=${data.key}`,
              },
            }).then(async (res2) => {
              if (res2.ok) {
                await setFileUploadStatus((prevStatus) => {
                  const newStatus = prevStatus.map((status) => {
                    if (status.file === index) {
                      return { ...status, statusRight: 2 };
                    }

                    return status;
                  });

                  return newStatus;
                });
                const auth = `Bearer ${Cookies.get("id_token")}`;
                const timeoutInMilliseconds = 7000; // 7 seconds

                const maxRetries = 3; // Set the maximum number of retries

                const makeCallToAPI = async () => {
                  let retryCount = 0;

                  const fetchWithRetry = async () => {
                    try {
                      const controller = new AbortController();
                      const timeout = setTimeout(() => {
                        controller.abort(); // Abort the request after the specified timeout
                      }, timeoutInMilliseconds);

                      const response = await fetch(
                        `${data.processMemoryURL}${data.uploadID}`,
                        {
                          method: "GET",
                          headers: {
                            Authorization: auth,
                          },
                          signal: controller.signal, // Pass the signal to the fetch request
                        }
                      );

                      clearTimeout(timeout); // Clear the timeout if the request completes before the timeout

                      if (response.ok) {
                        const dataRes = await response.json();

                        setFilesBeingUploaded(false);

                        await setFileUploadStatus((prevStatus) => {
                          const arrayLength =
                            dataRes.results.length > 1 ? 1 : 0;
                          const newStatus = prevStatus.map((status) => {
                            if (status.file === index) {
                              return {
                                ...status,
                                statusRight: 3,
                                newSessionID:
                                  dataRes.results[arrayLength].sessionID,
                              };
                            }

                            return status;
                          });

                          return newStatus;
                        });
                      } else {
                        setFilesBeingUploaded(false);
                        throw new Error(`HTTP error: ${response.status}`);
                      }
                    } catch (error) {
                      if (retryCount < maxRetries) {
                        retryCount++;
                        await fetchWithRetry(); // Retry the request
                      } else {
                        setFilesBeingUploaded(false);
                        console.error("Tried 3 times. Request failed.");
                        await setFileUploadStatus((prevStatus) => {
                          const newStatus = prevStatus.map((status) => {
                            if (status.file === index) {
                              return { ...status, uploadFailed: true };
                            }

                            return status;
                          });

                          return newStatus;
                        });
                        // Move on to the next request here
                      }
                    }
                  };

                  await fetchWithRetry();
                };

                // Call makeCallToAPI to start the process
                // makeCallToAPI();

                setTimeout(async () => {
                  await makeCallToAPI();
                }, 1000); // 1000 milliseconds = 1 second
              } else {
                setFilesBeingUploaded(false);
              }
            });
          } else {
            setFilesBeingUploaded(false);
          }
        } else {
          setFilesBeingUploaded(false);
        }
      });
    };

    if (leftFiles.length > 0 && rightFiles.length > 0) {
      await leftUpload();
    } else if (leftFiles.length > 0 && rightFiles.length === 0) {
      await leftUpload();
    } else if (leftFiles.length === 0 && rightFiles.length > 0) {
      // await fileReaderRight.readAsDataURL(zipBlobRight);
      await rightUpload();
    }
  };

  // testing file generation speed

  // handle single file upload
  const singleFileUploadHandler = async (index, session) => {
    const sessionFiles = await filesSelectedForUpload.filter(
      (item) =>
        item.name.includes(session.fileName.slice(0, -5)) ||
        item.name.includes(session.calibrationSessionID)
    );
    //valideation for files added here
    const isValid = fileValidation.some((item) => {
      return (
        item.includes(String(session.calibrationSessionID)) ||
        item.includes(String(session.fileName.slice(0, -5)))
      );
    });

    if (filesBeingUploaded === false && !isValid) {
      await setFilesBeingUploaded(true);
      await setWhichIndexUploadInit(index);
      console.log("Session is valid");
      await handleFileUpload1(sessionFiles, index);
    }
  };

  //handle multiple file upload
  const uploadFile = async (session, index) => {
    const sessionFiles = filesSelectedForUpload.filter(
        (item) =>
            item.name.includes(session.fileName.slice(0, -5)) ||
            item.name.includes(session.calibrationSessionID)
    );

    // Validation for files added here
    const isValid = fileValidation.some((item) => {
        return (
            item.includes(String(session.calibrationSessionID)) ||
            item.includes(String(session.fileName.slice(0, -5)))
        );
    });

    if (!filesBeingUploaded && !isValid) {
        await setFilesBeingUploaded(true);
        await setWhichIndexUploadInit(index);
        console.log("Session is valid");
        await handleFileUpload1(sessionFiles, index);
        await setFilesBeingUploaded(false); // Ensure the flag is reset after upload
    }
};

const multipleFileUploadHandler = async (sessions) => {
    for (let index = 0; index < sessions.length; index++) {
        await uploadFile(sessions[index], index);
    }
};


  const printFile = async (file) => {
    const isJsonString = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };

    function fixJsonString(inputString) {
      try {
        return JSON.parse(inputString);
      } catch (error) {
        const fixedString = inputString + "}";
        return isJsonString(fixedString) ? JSON.parse(fixedString) : false;
      }
    }

    const readFileAsText = (file) => {
      console.log("readFile as text inside file reader");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsText(file);
      });
    };

    const fileExists = validationForFilesForUpload.some(
      (existingFile) => existingFile?.fileName === file?.name
    );

    if (file.name.includes(".TXT")) {
      if (!fileExists) {
        setValidationForFilesForUpload((prevData) => [
          ...prevData,
          {
            fileName: file?.name,
            file: file,
            fileType: "TXT",
            calibrationFileNeeded: file?.name.includes("-"),
            isCalibrationFile: false,
            calibrationSessionID: null,
            activity: null,
            athleteName: null,
          },
        ]);
      }
      return;
    } else {
      try {
        const fileContent = await readFileAsText(file);
        const jsonResult = fixJsonString(fileContent);

        const { Session: session, Meta: meta } = jsonResult;

        if (!fileExists) {
          setValidationForFilesForUpload((prevData) => [
            ...prevData,
            {
              fileName: file?.name,
              file: file,
              fileType: "INF",
              calibrationFileNeeded:
                !session.calibrationSession &&
                session.calibrationSessionID <= 0,
              isCalibrationFile: session.calibrationSession,
              calibrationSessionID: session.calibrationSession
                ? null
                : session.calibrationSessionID,
              activity: session.activity,
              sessionDate: session.mTS,
              athleteName: meta?.name || null,
              sessionDuration: meta?.duration || null,
              bilateral: jsonResult?.Bilateral === 1,
            },
          ]);
        }
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };

  const changeTagTeletionToggler = (e) => {
    console.log(e);
  };

  const wakeUpApiHandler = async () => {
    await fetch(`${API_HOST}/memory/warmUpMemoryFlow`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("id_token")}`,
      },
    }).then(async (res) => {});
  };

  // selector styles
  const selectStyles = {
    input: (base) => ({
      ...base,
      color: "white",
    }),
    control: (base) => ({
      ...base,
      boxShadow: "none",
      backgroundColor: "#0F182A",
      color: "white",
      borderRadius: "7px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return {
        ...provided,
        opacity,
        transition,
        color: "grey",
      };
    },
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      color: "white",
      //option background colour#
      borderRadius: 5,
      paddingTop: 5,
      marginTop: 5,
      background: isFocused
        ? "rgba(30, 151, 214, 0.05)"
        : isSelected
        ? "#1B314B"
        : undefined,
      zIndex: -1,
      display: "flex",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 8,
      marginTop: 10,
      padding: 0,
      backgroundColor: "#141E32",
      boxShadow: "none",
      height: selectHeight,
      overflow: "hidden",
      position: "relative",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      height: selectHeight,
      maxHeight: selectHeight,
      "::-webkit-scrollbar": {
        width: "3px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#0A111F",
      },
      "::-webkit-scrollbar-thumb": {
        background: "rgba(30, 151, 214, 0.3)",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "rgba(30, 151, 214, 0.4)",
      },
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#1B314B",
        borderRadius: 7,
        maxWidth: "40%",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "White",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
  };

  // files for upload handle =============================================
  // Custom filter function
  function customFilter(file) {
    // Check if it's not a calibration file and has the correct file type
    return !file.isCalibrationFile && file.fileType === "INF";
  }

  // Apply the filter
  const filteredFiles = sortFiles(validationForFilesForUpload).filter(
    customFilter
  );

  //Checks if a file is missing and sets it to state
  const fileValidationHandling = (stateSetting, valueSet) => {
    if (!fileValidation.includes(valueSet)) {
      stateSetting();
    }
  };

  //Re-Checks the files missing and if they are added they are removed from the list
  const fileValidationHandlingRemoval = (valueSet) => {
    const index = fileValidation.indexOf(valueSet);
    let validArray = [];
    if (index > -1) {
      validArray = fileValidation.splice(index, 1);
    }
    if (fileValidation.includes(valueSet)) {
      setFileValidation(validArray);
    }
  };

  // Remove duplicates based on the value after slicing the file name
  const uniqueFiles = [];
  const seenValues = new Set();

  filteredFiles.forEach((file) => {
    const value = file.fileName.slice(0, -5);
    if (!seenValues.has(value)) {
      uniqueFiles.push(file);
      seenValues.add(value);
    }
  });

  console.log("filteredFiles", filteredFiles);
  console.log(uniqueFiles);

  return (
    <div className="filtering-container">
      <div className="user-profile-filter-dropdown-container">
        <div className="filter-container">
          <div className="search_date">
            {/*Search Bar-----------------------------------------------------------------------------*/}
            <div className="search-container">
              <input
                className="nav-search activity-search"
                placeholder="Search Reports"
                type="text"
                value={currentSearchTerm}
                onChange={handleSearchChange}
              />
            </div>

            {/*Date Picker --------------------------------------------------------------------------*/}
            <div className="user-profile-date-filter-container">
              <div className="user-profile-end-date-filter">
                <DateRangePicker
                  startDate={filterDate?.startDate}
                  minimumNights={0}
                  startDateId="start_date"
                  endDate={filterDate?.endDate}
                  endDateId="end_date"
                  onDatesChange={onDatesChange}
                  numberOfMonths={1}
                  focusedInput={focusedInput}
                  onFocusChange={onFocusChange}
                  displayFormat={"DD/MM/YYYY"}
                  isOutsideRange={isOutsideRange} // Optional: Allows selection of dates in the past
                />
              </div>
            </div>
          </div>
          <div className="filter_reset">
            {/*Filter Button------------------------------------------------------------------------*/}
            <FilteringModal
              button={
                <MainButton
                  buttonText={
                    <>
                      {filtering &&
                        (selectedActivity !== null ||
                          selectedAthlete !== null ||
                          selectedTag !== null) && (
                          <p>
                            {(typeof selectedActivity === "object" &&
                            selectedActivity !== null
                              ? 1
                              : 0) +
                              (typeof selectedAthlete === "object" &&
                              selectedAthlete !== null
                                ? 1
                                : 0) +
                              (typeof selectedTag === "object" &&
                              selectedTag !== null
                                ? 1
                                : 0)}
                          </p>
                        )}
                      <p className="button-title">Filter</p>
                    </>
                  }
                  ButtonIcon={FilterIcon}
                  buttonIconSize={14}
                  buttonPop={
                    (selectedActivity !== null ||
                      selectedAthlete !== null ||
                      selectedTag !== null) &&
                    true
                  }
                />
              }
              exerciseTypeFilter={
                <div className="user-profile-activity-filter">
                  <Select
                    ref={selectRef}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    id="activity"
                    value={selectedActivity}
                    onChange={activityChange}
                    openMenuOnFocus={true}
                    menuIsOpen={true}
                    options={options(
                      activityList?.activities.length >= 1
                        ? activityList.activities
                        : [],
                      setExerciseTypeGroup,
                      exerciseTypeGroup,
                      setExerciseGroups
                    )}
                    styles={selectStyles}
                    placeholder={
                      <div
                        className={`select-placeholder ${
                          exerciseGroups.length < 1 ? "active-placeholder" : ""
                        }`}
                      >
                        {exerciseGroups.length < 1 ? (
                          <p>Search...</p>
                        ) : (
                          exerciseGroups.map((item) => (
                            <p key={item} className="activity-group">
                              {item}
                            </p>
                          ))
                        )}{" "}
                      </div>
                    }
                    isMulti={true}
                  />
                </div>
              }
              athleteFilter={
                fromSessionInput && (
                  <div className="user-profile-activity-filter">
                    <Select
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      id="athlete"
                      value={selectedAthlete}
                      onChange={athleteChange}
                      menuIsOpen={true}
                      options={getPlayerOptions(
                        orgList.length >= 0 ? orgList : [],
                        setOrganisationAthleteGroups,
                        organisationAthleteGroups,
                        setOrganisationGroups
                      )}
                      styles={selectStyles}
                      placeholder={
                        <div
                          className={`select-placeholder ${
                            organisationGroups.length < 1
                              ? "active-placeholder"
                              : ""
                          }`}
                        >
                          {organisationGroups.length < 1 ? (
                            <p>Search...</p>
                          ) : (
                            organisationGroups.map((item) => (
                              <p key={item} className="activity-group">
                                {item}
                              </p>
                            ))
                          )}{" "}
                        </div>
                      }
                      isMulti={true}
                    />
                  </div>
                )
              }
              tagFilter={
                <div className="user-profile-activity-filter">
                  <Select
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    id="tag"
                    value={selectedTag}
                    menuIsOpen={true}
                    onChange={tagChange}
                    options={getTagsOptions(
                      tagList.tags.length >= 0 ? tagList.tags : [],
                      setTagGroupOptions,
                      tagGroupOptions,
                      setTagGroups
                    )}
                    styles={selectStyles}
                    placeholder={
                      <div
                        className={`select-placeholder ${
                          tagGroups.length < 1 ? "active-placeholder" : ""
                        }`}
                      >
                        {tagGroups.length < 1 ? (
                          <p>Search...</p>
                        ) : (
                          tagGroups.map((item) => (
                            <p key={item} className="activity-group">
                              {item}
                            </p>
                          ))
                        )}{" "}
                      </div>
                    }
                    isMulti={true}
                  />
                </div>
              }
              resetFilter={reset}
            />
            {/*Reset Button------------------------------------------------------------------------*/}
            <div onClick={reset}>
              {/* <div className="filter-button-container">
                <p>Reset</p>
                <ResetIcon size={"14px"} color={"white"} />
              </div> */}
              <MainButton
                buttonText={"Reset"}
                ButtonIcon={ResetIcon}
                buttonIconSize={14}
                buttonIconColour={"white"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="user-profile-buttons">
        <div>
          <MainModal
            onOpen={getOrgTags}
            button={<MainButton buttonText={"Tags"} ButtonIcon={TagsIcon} />}
            modalBody={
              <div className={`filter-tags-modal-container`}>
                <div className="filter-tags-modal-heading">
                  <h3>Create and Assign Tags</h3>
                  <p>
                    {selectedSessionsOrgs?.length > 1
                      ? "Please select sessions from only one organisation"
                      : selectedSessionsOrgs?.length === 1
                      ? orgList?.filter(
                          (item) => item?.OrgID === selectedSessionsOrgs?.[0]
                        )?.[0]?.["Name"]
                      : orgList?.[0]?.["Name"]}
                  </p>
                </div>
                <div className="filter-tags-modal-toggler">
                  <Toggler
                    isToggled={tagDeletionToggler}
                    setIsToggled={setTagDeletionToggler}
                    changeToggleDisplayed={changeTagTeletionToggler}
                    togglerHeadings={{
                      left: "Assign Tags",
                      right: "Delete Tags",
                    }}
                  />
                </div>

                <div className="filter-tags-modal-body">
                  <div className="filter-tags-modal-half">
                    <div className="filter-available-tags-container">
                      <h4>Selected Sessions</h4>
                      {selectedSessionsOrgs?.length === 0 ? (
                        <div className="tags-container filter-tags-warning">
                          <h4>No Sessions Selected</h4>
                        </div>
                      ) : (
                        <div className="tags-container">
                          <div className="session-container">
                            {selectedSessionList?.map((item) => (
                              <div className="session" key={nanoid()}>
                                <div className="session-info">
                                  <div className="session-athlete">
                                    {item.playerName}
                                  </div>
                                  <div className="session-activity">
                                    {item.ActivityName}
                                  </div>
                                  <div className="session-id">
                                    Session ID: {item.ID}
                                  </div>
                                  <div className="session-id">
                                    Date: {dateFormatHandler(item?.tsCreated)}
                                  </div>
                                </div>
                                <div className="session-tags">
                                  {JSON.parse(item?.tags).map((tag) => (
                                    <div key={nanoid()}>
                                      <Tag
                                        tagData={tag}
                                        hasHandleClick={tagDeletionToggler}
                                        handleClick={removeTagFromSession}
                                        hasIcon={true}
                                        TagIcon={DeleteIcon}
                                        hasConfirmationModal={true}
                                        tagModalHeader={`Remove Tag`}
                                        tagModalDetails={
                                          "Would you like to remove the selected tag from the selsected session?"
                                        }
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="filter-available-tags-container">
                      <h4>Create New Tags</h4>
                      <div className="tags-container create-tags-container">
                        <div className="create-new-tag-inputs-container">
                          <input
                            placeholder="Tag Name"
                            disabled={
                              selectedSessionsOrgs?.length > 1 ? true : false
                            }
                            value={newTagInfo.tagName}
                            onChange={(e) => {
                              setNewTagInfo((prev) => ({
                                ...prev,
                                tagName: e.target.value,
                              }));
                            }}
                          ></input>
                          <textarea
                            placeholder="Tag Description"
                            value={newTagInfo.tagDetails}
                            disabled={
                              selectedSessionsOrgs?.length > 1 ? true : false
                            }
                            onChange={(e) => {
                              setNewTagInfo((prev) => ({
                                ...prev,
                                tagDetails: e.target.value,
                              }));
                            }}
                          ></textarea>
                        </div>

                        <div className="create-new-tag-buttons">
                          <div
                            className="create-tag-button"
                            onClick={() => createNewTag()}
                          >
                            <MainButton
                              buttonText={"Create"}
                              ButtonIcon={TagsIcon}
                              buttonIconSize={18}
                              buttonFontSize={10}
                            />
                          </div>
                          <div
                            className="create-tag-button"
                            onClick={() => {
                              setNewTagInfo({ tagName: "", tagDetails: "" });
                            }}
                          >
                            <MainButton
                              buttonText={"Clear"}
                              ButtonIcon={CloseIcon}
                              buttonIconSize={18}
                              buttonFontSize={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="filter-tags-modal-half">
                    <div className="filter-available-tags-container">
                      <div>
                        <h4>Available Tags</h4>
                        <div className="filter-available-tags">
                          {selectedSessionsOrgs?.length === 1 ||
                          selectedSessionsOrgs?.length === 0 ? (
                            <div className="tags-container-tags">
                              {!tagDeletionToggler
                                ? orgTags?.map((tag) => {
                                    if (
                                      !selectedTagsForAssignment.includes(
                                        JSON.stringify(tag)
                                      )
                                    ) {
                                      return (
                                        <div key={nanoid()}>
                                          <Tag
                                            tagData={tag}
                                            hasHandleClick={
                                              selectedSessionsOrgs?.length === 1
                                                ? true
                                                : false
                                            }
                                            handleClick={addTagToAssignemtList}
                                            clickButtonSymbol={"+"}
                                          />
                                        </div>
                                      );
                                    }
                                  })
                                : orgTags?.map((tag) => {
                                    if (
                                      !selectedTagsForAssignment.includes(
                                        JSON.stringify(tag)
                                      )
                                    ) {
                                      return (
                                        <div key={nanoid()}>
                                          <Tag
                                            tagData={tag}
                                            hasHandleClick={
                                              selectedSessionsOrgs?.length === 1
                                                ? true
                                                : false
                                            }
                                            handleClick={deleteTagFromOrg}
                                            hasIcon={true}
                                            TagIcon={DeleteIcon}
                                            hasConfirmationModal={true}
                                            tagModalHeader={`Delete Tag`}
                                            tagModalDetails={
                                              "Would you like to delete the selected tag from the organisation?"
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                            </div>
                          ) : (
                            <div className="tags-container filter-tags-warning">
                              <h4>
                                The selected sessions are from different
                                organisations and may not be assigned a tag.
                              </h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="filter-available-tags-container">
                      <div>
                        <h4>Tags for Assignment</h4>
                        <div className="filter-selected-tags">
                          <div className="tags-container">
                            {(
                              selectedSessionsOrgs?.length > 1 ? (
                                <div className="tags-container filter-tags-warning">
                                  <h4>
                                    The selected sessions are from different
                                    organisations and may not be assigned a tag.
                                  </h4>
                                </div>
                              ) : (
                                selectedSessionsOrgs?.length === 0
                              )
                            ) ? (
                              <div className="tags-container filter-tags-warning">
                                <h4>
                                  Sessions have to be selected for tags to be
                                  assigned.
                                </h4>
                              </div>
                            ) : (
                              <div className="tags-container-tags">
                                {selectedTagsForAssignment.map((tag) => (
                                  <div key={nanoid()}>
                                    <Tag
                                      tagData={JSON.parse(tag)}
                                      hasHandleClick={
                                        selectedSessionsOrgs?.length === 1
                                          ? true
                                          : false
                                      }
                                      handleClick={removeTagFromAssignmentList}
                                      clickButtonSymbol={"-"}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="filter-tags-modal-buttons">
                  <div
                    className="filter-tags-button"
                    onClick={() => addTagsToSessionsHandler()}
                  >
                    <MainButton
                      buttonText={"Confirm Assignment"}
                      buttonLoading={tagsBeingAdded}
                      ButtonIcon={TagsIcon}
                    />
                  </div>
                </div>
              </div>
            }
            responseResetState={setTagDeletionToggler}
          />
        </div>
        <div>
          {(!filterChecker() ||
            [...setForComparison].length >= 2 ||
            checkedSessions.length < 2 ||
            (checkedSessions.length > comparisonLimit &&
              userPermisionLevel !== 1)) && (
            <MainButton
              buttonText={"Compare"}
              ButtonIcon={CompareIcon}
              buttonIconSize={14}
              buttonDisabled={true}
              buttonImportant={
                checkedSessions.length > comparisonLimit ? true : false
              }
              buttonIconColour={"#fafafafa"}
              buttonTooltip={
                checkedSessions.length < comparisonLimit ? (
                  "Please Select one or more sessions of the same type for Comparison"
                ) : checkedSessions.length > comparisonLimit ? (
                  <p>
                    The session comparison limit for this screen size is{" "}
                    <span className="comparison-limit-warning">
                      {comparisonLimit}
                    </span>
                  </p>
                ) : (
                  ""
                )
              }
            />
          )}

          {filterChecker() &&
            [...setForComparison].length <= 1 &&
            checkedSessions.length > 1 &&
            (checkedSessions.length <= comparisonLimit ||
              userPermisionLevel === 1) && (
              <div
                className="session-compare-button"
                onClick={async () => {
                  if (checkedChecker()) {
                    let data = [];

                    if (
                      [...setForComparison].length <= 1 &&
                      (setForComparison.has("Sprint") ||
                        setForComparison.has("Jog") ||
                        setForComparison.has("Walk"))
                    ) {
                      data = await getMultipleGaitSessionAPI(checkedSessions);
                    }

                    // if (selectedActivity.label.includes("Leg")) {
                    if (
                      [...setForComparison].length <= 1 &&
                      (setForComparison.has(
                        "Double Leg Countermovement jump"
                      ) ||
                        setForComparison.has("Double Leg Drop Jump") ||
                        setForComparison.has("Double Leg Squat Jump") ||
                        setForComparison.has("Double Leg Repeated Hops") ||
                        setForComparison.has("Double Leg Depth Jump") ||
                        setForComparison.has("Double Leg Horizontal Jump") ||
                        setForComparison.has(
                          "Single Leg Countermovement Jump"
                        ) ||
                        setForComparison.has("Single Leg Drop Jump") ||
                        setForComparison.has("Single Leg Squat Jump") ||
                        setForComparison.has("Single Leg Repeated Hops") ||
                        setForComparison.has("Single Leg Depth Jump"))
                    ) {
                      data = await getMultipleJumpSessionAPI(checkedSessions);
                    }

                    if (data.length > 0 && [...setForComparison].length <= 1) {
                      setComparingSessions(true);
                      const dataSortedByDate = data
                        .filter((item) => item.ts)
                        .sort((a, b) => new Date(a.ts) - new Date(b.ts));
                      setSessionsForComparison(dataSortedByDate);
                      setComparedSessionType([...setForComparison][0]);
                    }
                  }
                }}
              >
                <MainButton
                  buttonText={"Compare"}
                  ButtonIcon={CompareIcon}
                  buttonIconSize={14}
                  buttonPop={true}
                />
              </div>
            )}
        </div>

        <div>
          {!filterChecker() && (
            <MainButton
              buttonText={"Download"}
              ButtonIcon={DownloadIcon}
              buttonIconSize={14}
              buttonDisabled={true}
              buttonIconColour={"#fafafafa"}
              buttonTooltip={"Please Select one or more sessions for download."}
            />
          )}
          {filterChecker() && (
            <ReportsDownloadModal
              open={modal}
              setModal={setModal}
              button={
                <MainButton
                  buttonText={"Download"}
                  ButtonIcon={DownloadIcon}
                  buttonIconSize={14}
                  buttonIconColour={"#fafafafa"}
                />
              }
              searchList={filtering ? searchList : fullSessionList}
              selectedActivity={selectedActivity}
              selectedAthlete={selectedAthlete}
              startFilterDate={filterDate.startDate}
              endFilterDate={filterDate.endDate}
              checkedSessions={checkedSessions}
              sessionList={fullSessionList}
              setCustomGraphLayoutModal={setCustomGraphLayoutModalOpen}
            />
          )}
        </div>

        {props?.userPermisionLevel === 1 || props?.orgList[0]?.OrgID === 83 ? (
          <MainModal
            onOpen={() => {
              setFilesSelectedForUpload([]);
              setBinaryFilesSelectedForUpload([]);
              setTextFilesSelectedForUpload([]);
              setValidationForFilesForUpload([]);
              setPairedSelectedSessionsForUpload([]);
              setFileValidation([]);
              setFileUploadStatus([]);
              wakeUpApiHandler();
              setFilesBeingUploaded(false);
              setWhichIndexUploadInit(null);
            }}
            button={
              <MainButton
                buttonText={"Upload"}
                ButtonIcon={UploadIcon}
                buttonIconSize={14}
                buttonIconColour={"#fafafafa"}
              />
            }
            modalBody={
              <div className="upload-session-file-modal-container">
                <div className="upload-title">
                  <h4>Upload Session Files</h4>
                </div>
                <div className="upload-body">
                  <form method="post" enctype="multipart/form-data">
                    <label for="file" className="custom-file-upload-button">
                      Select Files
                    </label>
                    <input
                      id="file"
                      name="file"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    />
                  </form>
                </div>

                <div className="file-list-container">
                  {filesSelectedForUpload.length > 0 && (
                    <div className="row-headings">
                      <div className="date">Date</div>
                      <div className="athlete">Athlete</div>
                      <div className="activity">Activity</div>
                      <div className="feet">Foot</div>
                      <div className="calibration">Calibration</div>
                      <div className="data">Data</div>
                      <div className="upload">Upload</div>
                    </div>
                  )}

                  {filesSelectedForUpload.length > 0 &&
                    uniqueFiles
                      .sort(
                        (a, b) =>
                          Date.parse(b.sessionDate) - Date.parse(a.sessionDate)
                      )
                      .map((session, index) => {
                        if (fileUploadStatus.length < index + 1) {
                          setFileUploadStatus((prevStatus) => [
                            ...prevStatus,
                            {
                              file: index,
                              statusLeft: 0,
                              statusRight: 0,
                              bilateral: session.bilateral,
                              uploadFailed: false,
                            },
                          ]);
                        }
                        return (
                          <div className="file-row">
                            <div className="date">
                              {moment(session.sessionDate).format(
                                "DD/MM/YYYY h:mm:ss"
                              )}
                            </div>
                            <div className="athlete">{session.athleteName}</div>
                            <div className="activity">
                              {handleConvertActivtyFromID(session.activity)}
                            </div>
                            <div className="feet">
                              {session.bilateral === true ? (
                                <div className="row-indicators">
                                  {
                                    //left foot
                                    // "#e74c3c"
                                    <WarningIndicator
                                      warningIcon={
                                        <SockIcon
                                          size={23}
                                          reverse={true}
                                          color={
                                            validationForFilesForUpload.filter(
                                              (files) =>
                                                files.fileName.includes(
                                                  `${session.fileName.slice(
                                                    0,
                                                    -5
                                                  )}L.INF`
                                                )
                                            ).length >= 1
                                              ? "#1dd1a1"
                                              : "#e74c3c"
                                          }
                                        />
                                      }
                                      warningText={
                                        validationForFilesForUpload.filter(
                                          (files) =>
                                            files.fileName.includes(
                                              `${session.fileName.slice(
                                                0,
                                                -5
                                              )}L.INF`
                                            )
                                        ).length >= 1
                                          ? `Left Foot INF File Selected ${
                                              fileValidationHandlingRemoval(
                                                session.fileName.slice(0, -5) +
                                                  "L.INF"
                                              )
                                                ? ""
                                                : ""
                                            }`
                                          : `Please Select ${
                                              session.fileName.slice(0, -5) +
                                              "L"
                                            }.INF File 
                                      ${
                                        fileValidationHandling(
                                          () =>
                                            setFileValidation((prevData) => [
                                              ...prevData,
                                              session.fileName.slice(0, -5) +
                                                "L.INF",
                                            ]),
                                          session.fileName.slice(0, -5) +
                                            "L.INF"
                                        )
                                          ? ""
                                          : ""
                                      }`
                                      }
                                    />
                                  }
                                  {
                                    //right foot
                                    <WarningIndicator
                                      warningIcon={
                                        <SockIcon
                                          size={23}
                                          color={
                                            validationForFilesForUpload.filter(
                                              (files) =>
                                                files.fileName.includes(
                                                  `${session.fileName.slice(
                                                    0,
                                                    -5
                                                  )}R.INF`
                                                )
                                            ).length >= 1
                                              ? "#1dd1a1"
                                              : "#e74c3c"
                                          }
                                        />
                                      }
                                      warningText={
                                        validationForFilesForUpload.filter(
                                          (files) =>
                                            files.fileName.includes(
                                              `${session.fileName.slice(
                                                0,
                                                -5
                                              )}R.INF`
                                            )
                                        ).length >= 1
                                          ? `Right Foot INF File Selected ${
                                              fileValidationHandlingRemoval(
                                                session.fileName.slice(0, -5) +
                                                  "R.INF"
                                              )
                                                ? ""
                                                : ""
                                            }`
                                          : `Please Select ${
                                              session.fileName.slice(0, -5) +
                                              "R"
                                            }.INF File 
                                      ${
                                        fileValidationHandling(
                                          () =>
                                            setFileValidation((prevData) => [
                                              ...prevData,
                                              session.fileName.slice(0, -5) +
                                                "R.INF",
                                            ]),
                                          session.fileName.slice(0, -5) +
                                            "R.INF"
                                        )
                                          ? ""
                                          : ""
                                      }`
                                      }
                                    />
                                  }
                                </div>
                              ) : (
                                <div className="row-indicators">
                                  {
                                    //single foot
                                    <WarningIndicator
                                      warningIcon={
                                        <SockIcon
                                          size={23}
                                          reverse={
                                            validationForFilesForUpload.filter(
                                              (files) =>
                                                files.fileName.includes("L")
                                            ).length
                                              ? true
                                              : false
                                          }
                                          color={
                                            validationForFilesForUpload.filter(
                                              (files) =>
                                                files.fileName.includes("R") ||
                                                (files.fileName.includes("L") &&
                                                  session.fileName ===
                                                    files.fileName)
                                            ).length >= 1
                                              ? "#1dd1a1"
                                              : "#e74c3c"
                                          }
                                        />
                                      }
                                      warningText={
                                        validationForFilesForUpload.filter(
                                          (files) =>
                                            files.fileName.includes("R") ||
                                            (files.fileName.includes("L") &&
                                              session.fileName ===
                                                files.fileName)
                                        ).length >= 1
                                          ? `INF File Selected ${
                                              fileValidationHandlingRemoval(
                                                session.fileName.slice(0, -3) +
                                                  ".INF"
                                              )
                                                ? ""
                                                : ""
                                            }`
                                          : `Please Select ${session.fileName.slice(
                                              0,
                                              -3
                                            )}.INF File 
                                      ${
                                        fileValidationHandling(
                                          () =>
                                            setFileValidation((prevData) => [
                                              ...prevData,
                                              session.fileName.slice(0, -3) +
                                                "INF",
                                            ]),
                                          session.fileName.slice(0, -3) + "INF"
                                        )
                                          ? ""
                                          : ""
                                      }`
                                      }
                                    />
                                  }
                                </div>
                              )}
                            </div>
                            <div className="calibration">
                              {session.bilateral === true ? (
                                <div className="row-indicators">
                                  {
                                    //left foot calibration
                                    <WarningIndicator
                                      warningIcon={
                                        <CalibrationIcon
                                          size={23}
                                          color={
                                            session.calibrationFileNeeded
                                              ? validationForFilesForUpload.filter(
                                                  (files) =>
                                                    // left calibration file present
                                                    files.fileName.includes(
                                                      String(
                                                        session.calibrationSessionID
                                                      ) + "L"
                                                    )
                                                ).length > 1
                                                ? "#1dd1a1"
                                                : "#e74c3c"
                                              : "#f1c40f"
                                          }
                                        />
                                      }
                                      warningText={
                                        session.calibrationFileNeeded
                                          ? validationForFilesForUpload.filter(
                                              (files) =>
                                                // left calibration file present
                                                files.fileName.includes(
                                                  String(
                                                    session.calibrationSessionID
                                                  ) + "L"
                                                )
                                            ).length > 1
                                            ? `Left Foot Calinration Files Selected ${
                                                fileValidationHandlingRemoval(
                                                  session.calibrationSessionID +
                                                    "L.INF"
                                                )
                                                  ? ""
                                                  : ""
                                              } ${
                                                fileValidationHandlingRemoval(
                                                  session.calibrationSessionID +
                                                    "L.TXT"
                                                )
                                                  ? ""
                                                  : ""
                                              }`
                                            : `Please Select ${
                                                session.calibrationSessionID +
                                                "L"
                                              }.INF File and the ${
                                                session.calibrationSessionID +
                                                "L"
                                              }.TXT File
                                       ${
                                         fileValidationHandling(
                                           () =>
                                             setFileValidation((prevData) => [
                                               ...prevData,
                                               session.calibrationSessionID +
                                                 "L.INF",
                                             ]),
                                           session.calibrationSessionID +
                                             "L.INF"
                                         )
                                           ? ""
                                           : ""
                                       }  ${
                                                fileValidationHandling(
                                                  () =>
                                                    setFileValidation(
                                                      (prevData) => [
                                                        ...prevData,
                                                        session.calibrationSessionID +
                                                          "L.TXT",
                                                      ]
                                                    ),
                                                  session.calibrationSessionID +
                                                    "L.TXT"
                                                )
                                                  ? ""
                                                  : ""
                                              }`
                                          : "Calibration file is not needed"
                                      }
                                    />
                                  }
                                  {
                                    //right foot
                                    <WarningIndicator
                                      warningIcon={
                                        <CalibrationIcon
                                          size={23}
                                          color={
                                            session.calibrationFileNeeded
                                              ? validationForFilesForUpload.filter(
                                                  (files) =>
                                                    // left calibration file present
                                                    files.fileName.includes(
                                                      String(
                                                        session.calibrationSessionID
                                                      ) + "R"
                                                    )
                                                ).length > 1
                                                ? "#1dd1a1"
                                                : "#e74c3c"
                                              : "#f1c40f"
                                          }
                                        />
                                      }
                                      warningText={
                                        session.calibrationFileNeeded
                                          ? validationForFilesForUpload.filter(
                                              (files) =>
                                                // left calibration file present
                                                files.fileName.includes(
                                                  String(
                                                    session.calibrationSessionID
                                                  ) + "R"
                                                )
                                            ).length > 1
                                            ? `Right Foot Calibration Files Selected ${
                                                fileValidationHandlingRemoval(
                                                  session.calibrationSessionID +
                                                    "R.INF"
                                                )
                                                  ? ""
                                                  : ""
                                              } ${
                                                fileValidationHandlingRemoval(
                                                  session.calibrationSessionID +
                                                    "R.TXT"
                                                )
                                                  ? ""
                                                  : ""
                                              }`
                                            : `Please Select ${
                                                session.calibrationSessionID +
                                                "R"
                                              }.INF File and the ${
                                                session.calibrationSessionID +
                                                "R"
                                              }.TXT File
                                       ${
                                         fileValidationHandling(
                                           () =>
                                             setFileValidation((prevData) => [
                                               ...prevData,
                                               session.calibrationSessionID +
                                                 "R.INF",
                                             ]),
                                           session.calibrationSessionID +
                                             "R.INF"
                                         )
                                           ? ""
                                           : ""
                                       }  ${
                                                fileValidationHandling(
                                                  () =>
                                                    setFileValidation(
                                                      (prevData) => [
                                                        ...prevData,
                                                        session.calibrationSessionID +
                                                          "R.TXT",
                                                      ]
                                                    ),
                                                  session.calibrationSessionID +
                                                    "R.TXT"
                                                )
                                                  ? ""
                                                  : ""
                                              }`
                                          : "Calibration file is not needed"
                                      }
                                    />
                                  }
                                </div>
                              ) : (
                                <div className="row-indicators">
                                  {
                                    //single foot
                                    <WarningIndicator
                                      warningIcon={
                                        <CalibrationIcon
                                          size={23}
                                          color={
                                            session.calibrationFileNeeded
                                              ? validationForFilesForUpload.filter(
                                                  (files) =>
                                                    // left calibration file present
                                                    files.fileName.includes(
                                                      String(
                                                        session.calibrationSessionID
                                                      ) + "R"
                                                    ) ||
                                                    files.fileName.includes(
                                                      String(
                                                        session.calibrationSessionID
                                                      ) + "L"
                                                    )
                                                ).length >= 1
                                                ? "#1dd1a1"
                                                : "#e74c3c"
                                              : "#f1c40f"
                                          }
                                        />
                                      }
                                      warningText={
                                        session.calibrationFileNeeded
                                          ? validationForFilesForUpload.filter(
                                              (files) =>
                                                // left calibration file present
                                                files.fileName.includes(
                                                  String(
                                                    session.calibrationSessionID
                                                  ) + "R"
                                                ) ||
                                                files.fileName.includes(
                                                  String(
                                                    session.calibrationSessionID
                                                  ) + "L"
                                                )
                                            ).length >= 1
                                            ? "Calibration File Selected"
                                            : `Please Select: ${String(
                                                session.calibrationSessionID
                                              )} Files`
                                          : "Calibration file is not needed"
                                      }
                                    />
                                  }
                                </div>
                              )}
                            </div>
                            <div className="data">
                              {session.bilateral === true ? (
                                <div className="row-indicators">
                                  {
                                    //left foot
                                    <WarningIndicator
                                      warningIcon={
                                        <FileIcon
                                          size={23}
                                          color={
                                            validationForFilesForUpload.some(
                                              (files) =>
                                                files.fileName.includes(
                                                  `${session.fileName.slice(
                                                    0,
                                                    -5
                                                  )}L.TXT`
                                                )
                                            )
                                              ? "#1dd1a1"
                                              : "#e74c3c"
                                          }
                                        />
                                      }
                                      warningText={
                                        validationForFilesForUpload.some(
                                          (files) =>
                                            files.fileName.includes(
                                              `${session.fileName.slice(
                                                0,
                                                -5
                                              )}L.TXT`
                                            )
                                        )
                                          ? `Left Foot Data File Selected ${
                                              fileValidationHandlingRemoval(
                                                session.fileName.slice(0, -5) +
                                                  "L.TXT"
                                              )
                                                ? ""
                                                : ""
                                            }`
                                          : `Please Select ${
                                              session.fileName.slice(0, -5) +
                                              "L"
                                            }.TXT File 
                                      ${
                                        fileValidationHandling(
                                          () =>
                                            setFileValidation((prevData) => [
                                              ...prevData,
                                              session.fileName.slice(0, -5) +
                                                "L.TXT",
                                            ]),
                                          session.fileName.slice(0, -5) +
                                            "L.TXT"
                                        )
                                          ? ""
                                          : ""
                                      }`
                                      }
                                    />
                                  }
                                  {
                                    //right foot
                                    <WarningIndicator
                                      warningIcon={
                                        <FileIcon
                                          size={23}
                                          color={
                                            validationForFilesForUpload.some(
                                              (files) =>
                                                files.fileName.includes(
                                                  `${session.fileName.slice(
                                                    0,
                                                    -5
                                                  )}R.TXT`
                                                )
                                            )
                                              ? "#1dd1a1"
                                              : "#e74c3c"
                                          }
                                        />
                                      }
                                      warningText={
                                        validationForFilesForUpload.some(
                                          (files) =>
                                            files.fileName.includes(
                                              `${session.fileName.slice(
                                                0,
                                                -5
                                              )}R.TXT`
                                            )
                                        )
                                          ? `Right Foot Data File Selected ${
                                              fileValidationHandlingRemoval(
                                                session.fileName.slice(0, -5) +
                                                  "R.TXT"
                                              )
                                                ? ""
                                                : ""
                                            }`
                                          : `Please Select ${
                                              session.fileName.slice(0, -5) +
                                              "R"
                                            }.TXT File 
                                      ${
                                        fileValidationHandling(
                                          () =>
                                            setFileValidation((prevData) => [
                                              ...prevData,
                                              session.fileName.slice(0, -5) +
                                                "R.TXT",
                                            ]),
                                          session.fileName.slice(0, -5) +
                                            "R.TXT"
                                        )
                                          ? ""
                                          : ""
                                      }`
                                      }
                                    />
                                  }
                                </div>
                              ) : (
                                <div className="row-indicators">
                                  {
                                    //single foot
                                    <WarningIndicator
                                      warningIcon={
                                        <FileIcon
                                          size={23}
                                          color={
                                            validationForFilesForUpload.some(
                                              (files) =>
                                                files.fileName.includes(
                                                  `${session.fileName.slice(
                                                    0,
                                                    -4
                                                  )}.TXT`
                                                )
                                            )
                                              ? "#1dd1a1"
                                              : "#e74c3c"
                                          }
                                        />
                                      }
                                      warningText={
                                        validationForFilesForUpload.filter(
                                          (files) =>
                                            files.fileName.includes(
                                              `${session.fileName.slice(
                                                0,
                                                -4
                                              )}.TXT`
                                            )
                                        ).length >= 1
                                          ? `TXT File Selected ${
                                              fileValidationHandlingRemoval(
                                                session.fileName.slice(0, -3) +
                                                  "TXT"
                                              )
                                                ? ""
                                                : ""
                                            }`
                                          : `Please Select ${session.fileName.slice(
                                              0,
                                              -3
                                            )}TXT File 
                                      ${
                                        fileValidationHandling(
                                          () =>
                                            setFileValidation((prevData) => [
                                              ...prevData,
                                              session.fileName.slice(0, -3) +
                                                "TXT",
                                            ]),
                                          session.fileName.slice(0, -3) + "TXT"
                                        )
                                          ? ""
                                          : ""
                                      }`
                                      }
                                    />
                                  }
                                </div>
                              )}
                            </div>
                            <div className="upload">
                              <UploadingIndicationButton
                                clickHandler={
                                  filesBeingUploaded === false &&
                                  fileUploadStatus[index]?.statusLeft !== 3 &&
                                  fileUploadStatus[index]?.statusRight !== 3
                                    ? () =>
                                        singleFileUploadHandler(index, session)
                                    : () => console.log("Locked")
                                }
                                tooltipText={""}
                                uploadStatus={fileUploadStatus[index]}
                                uploadInProgress={filesBeingUploaded}
                                whichIndexUploadInit={whichIndexUploadInit}
                                index={index}
                              />
                              {fileUploadStatus[index]?.statusLeft > 0 ||
                              fileUploadStatus[index]?.statusRight > 0 ? (
                                <div className={`upload-status`}>
                                  {fileUploadStatus[index]?.bilateral ? (
                                    fileUploadStatus[index]?.statusRight !==
                                    3 ? (
                                      <p>
                                        {fileUploadStatus[index]?.statusLeft ===
                                          0 &&
                                        fileUploadStatus[index]?.statusRight ===
                                          0 &&
                                        !fileUploadStatus[index]?.uploadFailed
                                          ? "Starting Left..."
                                          : fileUploadStatus[index]
                                              ?.statusLeft === 1 &&
                                            fileUploadStatus[index]
                                              ?.statusRight === 0 &&
                                            !fileUploadStatus[index]
                                              ?.uploadFailed
                                          ? "Uploading Left..."
                                          : fileUploadStatus[index]
                                              ?.statusLeft === 2 &&
                                            fileUploadStatus[index]
                                              ?.statusRight === 0 &&
                                            !fileUploadStatus[index]
                                              ?.uploadFailed
                                          ? "Processing Left"
                                          : fileUploadStatus[index]
                                              ?.statusLeft === 3 &&
                                            fileUploadStatus[index]
                                              ?.statusRight === 0 &&
                                            !fileUploadStatus[index]
                                              ?.uploadFailed
                                          ? "Finishing Left..."
                                          : fileUploadStatus[index]
                                              ?.statusRight === 0 &&
                                            !fileUploadStatus[index]
                                              ?.uploadFailed
                                          ? "Starting Right"
                                          : fileUploadStatus[index]
                                              ?.statusRight === 1 &&
                                            !fileUploadStatus[index]
                                              ?.uploadFailed
                                          ? "Uploading Right"
                                          : fileUploadStatus[index]
                                              ?.statusRight === 2 &&
                                            !fileUploadStatus[index]
                                              ?.uploadFailed
                                          ? "Processing Right "
                                          : fileUploadStatus[index]
                                              ?.statusRight === 3 &&
                                            !fileUploadStatus[index]
                                              ?.uploadFailed
                                          ? "Finishing Right..."
                                          : "Failed"}
                                      </p>
                                    ) : (
                                      <p
                                        onClick={() =>
                                          handleOpenSession(
                                            fileUploadStatus[index]
                                              ?.newSessionID,
                                            fileUploadStatus[index]
                                              ?.statusLeft === 3 &&
                                              fileUploadStatus[index]
                                                ?.statusRight === 3
                                          )
                                        }
                                      >
                                        Session ID:{" "}
                                        {fileUploadStatus[index]?.newSessionID}
                                      </p>
                                    )
                                  ) : !fileUploadStatus[index]?.newSessionID &&
                                    (fileUploadStatus[index]?.statusLeft !==
                                      3 ||
                                      fileUploadStatus[index]?.statusRight !==
                                        3) ? (
                                    <p>
                                      {fileUploadStatus[index]?.statusLeft ===
                                        0 &&
                                      fileUploadStatus[index]?.statusRight ===
                                        0 &&
                                      !fileUploadStatus[index]?.uploadFailed
                                        ? "Starting Left..."
                                        : fileUploadStatus[index]
                                            ?.statusLeft === 1 &&
                                          fileUploadStatus[index]
                                            ?.statusRight === 0 &&
                                          !fileUploadStatus[index]?.uploadFailed
                                        ? "Uploading Left..."
                                        : fileUploadStatus[index]
                                            ?.statusLeft === 2 &&
                                          fileUploadStatus[index]
                                            ?.statusRight === 0 &&
                                          !fileUploadStatus[index]?.uploadFailed
                                        ? "Left Uploded"
                                        : fileUploadStatus[index]
                                            ?.statusLeft === 3 &&
                                          fileUploadStatus[index]
                                            ?.statusRight === 0 &&
                                          !fileUploadStatus[index]?.uploadFailed
                                        ? "Left Complete"
                                        : fileUploadStatus[index]
                                            ?.statusRight === 0 &&
                                          !fileUploadStatus[index]?.uploadFailed
                                        ? "Starting Right..."
                                        : fileUploadStatus[index]
                                            ?.statusRight === 1 &&
                                          !fileUploadStatus[index]?.uploadFailed
                                        ? "Uploading Right..."
                                        : fileUploadStatus[index]
                                            ?.statusRight === 2 &&
                                          !fileUploadStatus[index]?.uploadFailed
                                        ? "Right Uploded"
                                        : fileUploadStatus[index]
                                            ?.statusRight === 3 &&
                                          !fileUploadStatus[index]?.uploadFailed
                                        ? "Right Complete"
                                        : "Failed"}
                                    </p>
                                  ) : (
                                    <p
                                      onClick={() =>
                                        handleOpenSession(
                                          fileUploadStatus[index]?.newSessionID,
                                          fileUploadStatus[index]
                                            ?.statusLeft === 3 ||
                                            fileUploadStatus[index]
                                              ?.statusRight === 3
                                        )
                                      }
                                    >
                                      Session ID:{" "}
                                      {fileUploadStatus[index]?.newSessionID}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })}
                    
                </div>
                {filesSelectedForUpload.length ? <div className="upload-all-button" onClick={() => multipleFileUploadHandler(uniqueFiles)}>
                        <MainButton  buttonText="Upload All"/>
                      </div> : ""}
                
              </div>
            }
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default React.memo(FilterContainer);
