import { api } from './api'

export const createOrganisation = (data) => {
  return api.post('organisation/create', data).then(res => {
    return res
  })
}

export const registerPod = (data) => {
  return api.post('/pod/registerNew', data).then(res => {
    return res
  })
}

export const getListOfPodsAPI = () => {
  return api.get('pods/getList').then(res => {
    return res
  })
}

export const removePodAPI = (podID) => {
  return api.get(`pods/deactivatepod/${podID}`).then(res => {
    return res
  })
}

export const deactivateOrgAPI = (orgId) => {
  return api.get(`/organisation/deactivateorg/${orgId}`).then(res => {
    return res
  })
}

export const getUsersAPI = () => {
  return api.get('user/getListOfUsers').then(res => {
    return res
  })
}
