/* eslint-disable */
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getSessionList, getupdateSessionDetail } from "store/sessionListSlice";
import { selectUser } from "store/authSlice";
import EscapeIcon from "components/nav-components/nav-icons/escape-icon";
import Button from "components/common/button/button";
import {
  checkSessionDataStatus,
  discardSessionAPI,
  getJumpDataAPI,
  getRunDataAPI,
  reRunMetricsAPI,
} from "../../api/session";
import { getSessionFromList, msToTime } from "../../utils/helper";
import * as metricRules from "../../utils/metrics";
import { UserProfile_URL } from "../../utils/urls";
import CadenceReport from "../metrics/CadenceReport";
import ContactTimeJump from "../metrics/ContactTimeJump";
import FlightTime from "../metrics/FlightTime";
import FlightTimeJump from "../metrics/FlightTimeJump";
import Force from "../metrics/ForceData";
import GroundContactTime from "../metrics/GroundContactTime";
import JumpHeight from "../metrics/JumpHeight";
import PeakTibialAcceleration from "../metrics/PeakTibialAccelerationReport";
import Power from "../metrics/Power";
import RSI from "../metrics/RSI";
import FlightTimeAsymmetry from "components/metrics/FlightTimeAsymmetry";
import GroundContactTimeAsymmetry from "components/metrics/GroundContactTimeAsymmetry";
import SwingStanceFootStrike from "components/metrics/SwingStanceFootStrike";
import Video from "../metrics/Video";
import "../../styles/metrics_updated.scss";
import { useState } from "react";
import { useEffect } from "react";
import ReportsDownloadModal from "components/common/downloadModal/reportsDownloadModal";
import TestSlide from "components/common/testSlide/testSlide";
import PictureMaps from "components/metrics/PictureMaps";
import { displayDateForPlayerList } from "../../utils/helper";
import DriveIndex from "components/metrics/DriveIndex";
import MetricBrush from "components/metrics/metric-helpers/MetricBrush";
import MetricTrimBrush from "components/metrics/metric-helpers/MetricTrimBrush";
import DownArrow from "../../components/common/icons/downArrow";
import TrimIcon from "components/common/icons/trimIcon";
import { useCheckForJumpData } from "components/common/hooks/useCheckForJumpData";
import { setGraphModal, setSelectedSessionId } from "store/playerActivitySlice";
import { getJumpData, resetJumpData } from "store/jumpDataSlice";
import { getRunData, resetRunData } from "store/runDataSlice";
import { setSessionTrim, resetSessionTrim } from "api/charts";
import MagnifyingGlass from "components/common/icons/magnifyingGlass";
import SessionTaggingModal from "components/common/taggingModal/SessionTaggingModal";
import { resetAllData } from "store/sessionSlice";
import AlertDialogDemo from "components/common/alertDialog/alertDialog";
import EditIcon from "components/common/icons/editIcon";
import ActivityChangeModal from "components/common/activityChangeModal/ActivityChangeModal";
import GroundClearance from "components/metrics/GroundClearance";
import GroundClearanceAsymmetry from "components/metrics/GroundClearanceAsymmetry";
import StepLength from "components/metrics/StepLength";
import StepLengthAsymmetry from "components/metrics/StepLengthAsymmetry";
import StrideVelocity from "components/metrics/StrideVelocity";
import StrideVelocityAsymmetry from "components/metrics/StrideVelocityAsymmetry";
import GroundClearanceJump from "components/metrics/GroundClearanceJump";
import JumpLength from "components/metrics/JumpLength";
import JumpVelocity from "components/metrics/JumpVelocity";
import StrideTime from "components/metrics/StrideTime";
import StrideTimeAsymmetry from "components/metrics/StrideTimeAsymmetry";
import SwingTime from "components/metrics/SwingTime";
import SwingTimeAsymmetry from "components/metrics/SwingTimeAsymmetry";
import MainModal from "components/common/mainModal/MainModal";
import MainButton from "components/common/reuseableButtonComponent/MainButton";
import DeleteIcon from "components/common/icons/deleteIcon";
import LoaderIcon from "components/common/icons/loader";
import ResetIcon from "components/common/icons/reset";
import WarningTooltip from "components/common/warningTooltip/WarningTooltip";
import { userState } from "store/authSlice";
import { updateUserLayoutAPI } from "api/user";
import Spinner from "components/common/spinner/spinner";
import MainTooltip from "components/common/mainTooltip/MainTooltip";
import StrideLength from "components/metrics/StrideLength";
import StrideLengthAsymmetry from "components/metrics/StrideLengthAsymmetry";
import MainChartComponent from "components/metrics/MainChartComponent";

function PlayerActivityDetails(props) {
  //declaring state
  const [textareaValue, settextareaValue] = useState("");
  const [editForm, setEditForm] = useState(false);
  const [rerunMetricButton, setRerunMetricButton] = useState("Rerun Metrics");
  const [graphDomain, setGraphDomain] = useState(false);
  const [graphTrimDomain, setGraphTrimDomain] = useState(["auto", "auto"]);
  const [domainToggle, setDomainToggle] = useState(false);
  const [trimToggle, setTrimToggle] = useState(false);
  const [isTrimOpen, setIsTrimOpen] = useState(false);
  const [tagBeingAdded, setTagBeingAdded] = useState(false);
  const [discardSessionModalOpen, setDiscardSessionModalOpen] = useState(false);
  const [strideVelocityDisplayed, setStrideVelocityDisplayed] =
    useState("meanStrideVelocity");
  const [jumpVelocityDisplayed, setJumpVelocityDisplayed] =
    useState("meanJumpVelocity");
  const [cadenceFrequencyToggler, setCadenceFrequencyToggler] =
    useState("cadenceData");
  const [sessionMetricsToggled, setSessionMetricsToggled] = useState([]);
  const [disabledGraphIDs, setDisabledGraphIDs] = useState([]);
  const dispatch = useDispatch();

  //session loading
  const [sessionLoading, setSessionLoading] = useState(true);

  const { playerActivity, session, sessionList, activityList } = useSelector(
    (state) => ({
      activityList: state.activity,
      session: state.sessionList,
      playerActivity: state.playerActivity,
      sessionList: state.session,
    })
  );

  //logged userData
  const loggedUserData = useSelector(userState);

  //props declared
  const { actions, fromPlayerProfile } = props;
  let xAxisDomainConcatArray = [];
  let yAxisDomainConcatArray = [];
  let asymRangeArray = [];
  let asymRangeArray_y = [];
  let asymJumpRangeArray = [];

  const checkForJumpData = useCheckForJumpData();
  const selectedSessionId = playerActivity.selectedSessionId;
  const graphModal = playerActivity.graphModal;

  let updatedData;

  let thisSession;

  if (session.sessionList.data) {
    thisSession = getSessionFromList(
      selectedSessionId,
      session.sessionList?.data
    );
  }

  // immediate invocation of function, checks if session is trimmed and applies trim to the set domain
  (() => {
    if (
      thisSession?.ActivityType === "Run" &&
      sessionList.runData.data &&
      sessionList.runData.data?.td &&
      sessionList.runData.data?.td.length > 0 &&
      session.status === "succeeded" &&
      playerActivity.graphModal
    ) {
      let domainStart = sessionList?.runData?.data?.td[0].from / 1000;
      let domainEnd = sessionList?.runData?.data?.td[0].to / 1000;
      if (
        sessionList?.runData?.data?.td.length > 0 &&
        String(graphDomain) !== String([domainStart, domainEnd])
      ) {
        if (graphDomain === false && graphTrimDomain[0] === "auto") {
          setGraphDomain([domainStart, domainEnd]);
          setGraphTrimDomain([domainStart, domainEnd]);
          // checkForJumpData();
        }
      }
    }

    if (
      thisSession?.ActivityType === "Jump" &&
      sessionList.jumpData.data &&
      sessionList.jumpData.data?.td &&
      sessionList.jumpData.data?.td.length > 0 &&
      session.status === "succeeded" &&
      playerActivity.graphModal
    ) {
      let domainStart = sessionList?.jumpData?.data?.td[0].from / 1000;
      let domainEnd = sessionList?.jumpData?.data?.td[0].to / 1000;
      if (
        sessionList?.jumpData?.data?.td.length > 0 &&
        String(graphDomain) !== String([domainStart, domainEnd])
      ) {
        if (graphDomain === false && graphTrimDomain[0] === "auto") {
          setGraphDomain([domainStart, domainEnd]);
          setGraphTrimDomain([domainStart, domainEnd]);
          // checkForJumpData();
        }
      }
    }
  })();
  // look at adding a loading state when loading charts, small visual bug occurs when loading if a disabled chart layout is saved.

  useEffect(() => {
    if (sessionLoading === true && playerActivity.graphModal === true) {
      setTimeout(() => {
        setSessionLoading(false);
      }, 2500);
    }
  }, [playerActivity.graphModal === true, playerActivity.selectedSessionId]);

  useEffect(() => {
    let savedLayoutUserData =
      loggedUserData?.user?.layout && JSON.parse(loggedUserData?.user?.layout);
    if (localStorage.getItem("userLayout")) {
      savedLayoutUserData = JSON.parse(localStorage.getItem("userLayout"));
    }
    if (
      savedLayoutUserData?.sessionLayout?.[
        thisSession?.ActivityName?.toLowerCase()
      ]?.disabledGraphIDs?.length > 0 &&
      playerActivity.graphModal === true
    ) {
      setTimeout(() => {
        setDisabledGraphIDs(
          savedLayoutUserData?.sessionLayout?.[
            thisSession?.ActivityName?.toLowerCase()
          ]?.disabledGraphIDs
        );
      }, 2000);
    }
  }, [playerActivity.graphModal === true]);

  useEffect(() => {
    if (tagBeingAdded === true) {
      checkForJumpData();
      dispatch(getSessionList());
    }
    setTagBeingAdded(false);
  }, [tagBeingAdded]);

  //close modal handler
  function closeModal() {
    dispatch(setGraphModal(!playerActivity.graphModal));
    dispatch(setSelectedSessionId(null));
    document.body.classList.remove("disableScroll");
    setRerunMetricButton("Rerun Metrics");
    setDisabledGraphIDs([]);
  }

  useEffect(() => {
    setTrimToggle(!trimToggle);
    function disableScrollModalOpen() {
      document.body.classList.add("disableScroll");
    }

    function enableScrollModalClosed() {
      document.body.classList.remove("disableScroll");
    }
    setTimeout(() => {
      if (playerActivity.graphModal) {
        disableScrollModalOpen();
        setGraphDomain(false);
        setGraphTrimDomain(["auto", "auto"]);
        setTrimToggle(!trimToggle);
      } else {
        enableScrollModalClosed();
      }
    }, 500);

    // getGraphsRenedered();

    // Cleanup function
    return () => {
      if (playerActivity.graphModal && playerActivity.selectedSessionId) {
        dispatch(setSelectedSessionId(null));
        dispatch(setGraphModal(!playerActivity.graphModal));
      }

      enableScrollModalClosed();
    };
  }, [playerActivity.graphModal, playerActivity.selectedSessionId]);

  useEffect(() => {
    getGraphsRenedered();
  }, [playerActivity.selectedSessionId, sessionList]);

  //get items within specific div
  function getGraphsRenedered() {
    let elements = document
      ?.querySelector(".metric-list-container")
      ?.querySelectorAll(".toggle-metric-class");
    let elementsIDs = [];

    for (let i = 0; i < elements?.length; i++) {
      elementsIDs?.push(elements[i].id);
    }

    setSessionMetricsToggled(elementsIDs);
  }

  //user session layout --------------------------------------------------------------------------
  async function updateSessionLayout() {
    const activityType = await thisSession?.ActivityName.toLowerCase();
    const loggedInUserLayout = await JSON.parse(loggedUserData?.user.layout);
    const localStorageData = JSON.parse(localStorage.getItem("userLayout"));

    const postBody = await {
      layout: JSON.stringify({
        pdf: { ...loggedInUserLayout.pdf },
        comparison: { ...loggedInUserLayout.comparison },
        sessionLayout: {
          ...loggedInUserLayout.sessionLayout,
          [activityType]: {
            disabledGraphIDs: disabledGraphIDs,
          },
        },
        athleteOverview: { ...localStorageData.athleteOverview },
      }),
    };
    await localStorage.setItem("userLayout", postBody.layout);
    await updateUserLayoutAPI(postBody);
  }

  //scroll to top when modal rerenders
  function scrollToTop() {
    if (document.getElementById("modal-activity-box"))
      document.getElementById("modal-activity-box").scrollTop = 0;
  }

  function displaySessionDetail(thisSession) {
    const { name, detail, playerName } = thisSession;

    if (detail && detail !== "Add Description" && detail !== "") {
      if (name && name !== "Add Description" && name !== "") {
        if (name.includes(playerName)) {
          return detail;
        } else {
          return `${detail}, ${name}`;
        }
      } else if (name !== playerName) {
        return detail;
      }
    } else if (
      name &&
      name !== "Add Description" &&
      name !== "" &&
      name !== playerName
    ) {
      return name;
    }

    return "-";
  }

  const handleDiscardSessionModal = () => {
    setDiscardSessionModalOpen(true);
    document.body.classList.add("modal-open");
  };

  function discardSession(e) {
    e.preventDefault();

    discardSessionAPI(selectedSessionId).then(() => {
      setDiscardSessionModalOpen(false);
      closeModal();
      // dispatch(getSessionList())
      props.removeSessionFromList(selectedSessionId);
    });

    document.body.classList.remove("modal-open");
  }

  //rerun metrics
  function rerunMetrics() {
    setRerunMetricButton("Metric Process Initiated");

    reRunMetricsAPI(selectedSessionId).then(() => {
      let thisSession;
      if (session.sessionList) {
        thisSession = session.sessionList?.data.find(
          (s) => s.ID === selectedSessionId
        );
      }

      if (!thisSession) {
        return;
      }

      if (
        thisSession &&
        (thisSession.jumpTypeID > 0 || thisSession.activityTypeID === 2)
      ) {
        actions.resetJumpData(thisSession.ID);

        let numRequestsMade = 0;
        const interval = setInterval(() => {
          checkSessionDataStatus(thisSession.ID).then((res) => {
            numRequestsMade++;
            if (res.offlineJobStatus === 2 || numRequestsMade >= 8) {
              clearInterval(interval); // Clear the interval
              setRerunMetricButton("Rerun Metrics");
              if (res.offlineJobStatus === 2) {
                dispatch(setSelectedSessionId(selectedSessionId));
                setTimeout(() => {
                  checkForJumpData();
                }, 2000);
              }
            }
          });
        }, 3000);
      } else if (
        thisSession.runTypeID > 0 ||
        thisSession.activityTypeID === 1 ||
        thisSession.activityID === 21
      ) {
        //for other - call rundata API as capacitance will be returned here

        actions.resetRunData(thisSession.ID); // sets to loading

        let numRequestsMade = 0;
        const interval = setInterval(() => {
          checkSessionDataStatus(thisSession.ID).then((res) => {
            numRequestsMade++;
            if (res.offlineJobStatus === 2 || numRequestsMade >= 8) {
              clearInterval(interval); // Clear the interval
              setRerunMetricButton("Rerun Metrics");

              if (res.offlineJobStatus === 2) {
                dispatch(setSelectedSessionId(selectedSessionId));
                setTimeout(() => {
                  checkForJumpData();
                }, 2000);
              }
            }
          });
        }, 3000);
      }
    });
  }

  //if modal is open disable body scroll
  function disableScrollModalOpen() {
    document.body.classList.add("disableScroll");
  }

  function enableScrollModalClosed() {
    document.body.classList.remove("disableScroll");
  }

  if (playerActivity.graphModal) {
    disableScrollModalOpen();
  }

  //takes in a array and decides the x axis max value of the grahphs based on max values
  function chartXdomainScale(arr) {
    let newArr = arr.map((item) =>
      item.Symmetry < 0 ? item.Symmetry * -1 : item.Symmetry
    );

    //custom range
    // return([-1*(Math.max(...newArr))-10, Math.max(...newArr)+10])

    return Math.max(...newArr) < 50 ? [-50, 50] : [-100, 100];
  }

  function chartYdomainScale(arr) {
    //creates array of from specific object keys
    let newArr = arr.map(
      (item) => Number(item["Left Foot"]) || Number(item["Right Foot"])
    );
    //filteres out NaN values
    //const newArr2 = newArr.filter(item => item !== NaN ? item : "");
    newArr = newArr.filter((item) => !isNaN(item));
    //sets the range from 0 - the max value of the array fixed to two devimal places and turned to number
    return [
      0,
      Number(
        (Math.max(...newArr) + (Math.max(...newArr) / 100) * 10).toFixed(2)
      ),
    ];
  }

  const strideSymmetryData = sessionList?.runData?.data?.StrideSymmetryData;

  if (strideSymmetryData && strideSymmetryData.length !== 0) {
    const contactTime = strideSymmetryData.contactTime;
    const flightTime = strideSymmetryData.flightTime;
    const meanStrideVelocity = strideSymmetryData.meanStrideVelocity;
    const maxStrideVelocity = strideSymmetryData.maxStrideVelocity;
    const stepLength = strideSymmetryData.stepLength;
    const groundClearance = strideSymmetryData.groundClearance;

    if (
      contactTime &&
      contactTime.length !== 0 &&
      flightTime &&
      flightTime.length !== 0 &&
      meanStrideVelocity &&
      meanStrideVelocity.length !== 0 &&
      maxStrideVelocity &&
      maxStrideVelocity.length !== 0 &&
      stepLength &&
      stepLength.length !== 0 &&
      groundClearance &&
      groundClearance.length !== 0
    ) {
      // Spreads the two arrays into one
      xAxisDomainConcatArray = [
        ...contactTime,
        ...flightTime,
        ...meanStrideVelocity,
        ...maxStrideVelocity,
        ...stepLength,
        ...groundClearance,
      ];

      // Sets the value of range array to the result of the chart function by passing the concat array as a parameter
      asymRangeArray = chartXdomainScale(xAxisDomainConcatArray);
    } else if (
      contactTime &&
      contactTime.length !== 0 &&
      flightTime &&
      flightTime.length !== 0
    ) {
      // Spreads the two arrays into one
      xAxisDomainConcatArray = [...contactTime, ...flightTime];

      // Sets the value of range array to the result of the chart function by passing the concat array as a parameter
      asymRangeArray = chartXdomainScale(xAxisDomainConcatArray);
    }
  }

  const jumpData = sessionList.jumpData?.data;
  if (jumpData) {
    const jumpSymmetryChartData = jumpData.jumpData?.symmetryChartData;
    const flightSymmetryChartData = jumpData.flightData?.symmetryChartData;
    const powerSymmetryChartData = jumpData.powerData?.symmetryChartData;
    const rsiSymmetryChartData = jumpData.rsiData?.symmetryChartData;
    const contactTimeSymmetryChartData =
      jumpData.contactTimeData?.symmetryChartData;

    if (
      jumpSymmetryChartData &&
      flightSymmetryChartData &&
      powerSymmetryChartData
    ) {
      xAxisDomainConcatArray = [
        ...jumpSymmetryChartData,
        ...flightSymmetryChartData,
        ...powerSymmetryChartData,
      ];
      asymJumpRangeArray = chartXdomainScale(xAxisDomainConcatArray);
    }
    if (
      rsiSymmetryChartData &&
      flightSymmetryChartData &&
      contactTimeSymmetryChartData
    ) {
      xAxisDomainConcatArray = [
        ...rsiSymmetryChartData,
        ...flightSymmetryChartData,
        ...contactTimeSymmetryChartData,
      ];
      asymJumpRangeArray = chartXdomainScale(xAxisDomainConcatArray);
    }
  }

  const runData = sessionList?.runData?.data;
  if (runData?.length) {
    const flightTimeData = runData?.FlightTimeData;
    const contactTimeData = runData?.ContactTimeData;

    if (
      flightTimeData &&
      flightTimeData.length !== 0 &&
      contactTimeData &&
      contactTimeData.length !== 0
    ) {
      yAxisDomainConcatArray = [
        ...flightTimeData?.stepData,
        ...contactTimeData?.stepData,
      ];
      asymRangeArray_y = chartYdomainScale(yAxisDomainConcatArray);
    }
  }

  //closes modal when clicking off it
  // too wrong shouldn't call event listener thats outside of react's lifecycle causing infinite call to closeModal
  // document.addEventListener(
  //   "click",
  //   function (event) {
  //     if (
  //       !event.target.closest(".player-activity-box") &&
  //       document.body.classList.contains("modal-open")
  //     ) {
  //       closeModal();
  //     }
  //   },
  //   true
  // );

  // temp removed the api used to generated images for the pdf file
  // //proxy url
  // const proxyUrl = "https://cors-anywhere.herokuapp.com/";

  // //proxy cors function
  // function proxyCorsImage(src) {
  //   const isBase64 = /^data:image\/(png|jpeg);base64,/.test(src);
  //   if (isBase64) {
  //     return;
  //   }
  //   fetch(proxyUrl + src)
  //     .then((res) => res.blob())
  //     .then(
  //       (blob) =>
  //         new Promise((resolve, reject) => {
  //           const reader = new FileReader();
  //           reader.onerror = reject;
  //           reader.onload = () => {
  //             resolve(reader.result);
  //           };
  //           reader.readAsDataURL(blob);
  //         })
  //     )
  //     .then((dataURL) => {
  //       setAthleteIcon(dataURL);
  //     });
  // }

  // graphModal && proxyCorsImage(thisSession?.playerIconUrl);

  // function Base64ToImage(base64img, callback) {
  //   var img = new Image();
  //   img.onload = function() {
  //       callback(img);
  //   };
  //   img.src = base64img;
  // }

  // if(graphModal){
  //   Base64ToImage(baseImage, function(img) {
  //   document.getElementById('pdf-athlete-icon').appendChild(img);
  // });
  // }

  //show form details
  function showDetailForm() {
    let thisSession;

    if (session.sessionList.status === 200) {
      thisSession = session?.sessionList?.data.find(
        (s) => s.ID === selectedSessionId
      );
    }

    setEditForm(true);
    settextareaValue(thisSession.detail);
    setTimeout(() => {
      document.getElementById("detailinput").value =
        displaySessionDetail(thisSession);
    }, 100);
  }

  const handleChange = (event) => {
    settextareaValue(event.target.value);
  };

  const handleBlur = () => {
    if (textareaValue === thisSession.detail || textareaValue === "") {
      setEditForm(false);
      return;
    }
    const newDesc = {
      id: thisSession.ID,
      detail: textareaValue,
    };
    dispatch(getupdateSessionDetail(newDesc));
    settextareaValue("");
    setEditForm(false);
  };

  const handleBrushResize = () => {
    if (
      !document
        .querySelector(".metric-brush-container-closed")
        .classList.contains("metric-brush-container-open")
    ) {
      document
        .querySelector(".metric-brush-container-closed")
        .classList.add("metric-brush-container-open");
    } else {
      document
        .querySelector(".metric-brush-container-closed")
        .classList.remove("metric-brush-container-open");
    }
  };

  const handleBrushTrim = () => {
    if (
      !document
        .querySelector(".metric-trim-brush-container-closed")
        .classList.contains("metric-trim-brush-container-open")
    ) {
      document
        .querySelector(".metric-trim-brush-container-closed")
        .classList.add("metric-trim-brush-container-open");
      setIsTrimOpen(true);
    } else {
      document
        .querySelector(".metric-trim-brush-container-closed")
        .classList.remove("metric-trim-brush-container-open");
      setIsTrimOpen(false);
    }
  };

  //remove index from array
  function removeIndex(array, index) {
    let newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  }

  //toggle view of graph
  function toggleViewOfGraph(togglerID, toggledID) {
    let tempArray = [...disabledGraphIDs];

    if (
      !document
        .getElementById(togglerID)
        .classList.contains("disabled-graph-toggler")
    ) {
      document
        .getElementById(togglerID)
        .classList.add("disabled-graph-toggler");
    } else {
      document
        .getElementById(togglerID)
        .classList.remove("disabled-graph-toggler");
    }

    if (tempArray.includes(toggledID)) {
      tempArray = removeIndex(tempArray, tempArray.indexOf(toggledID));
      setDisabledGraphIDs([...tempArray]);
    } else {
      tempArray.push(toggledID);
      setDisabledGraphIDs([...tempArray]);
    }
  }

  const activity = activityList.activities.filter(
    (item) => item?.id === thisSession?.activityID
  );

  function renderCapacitanceOnly(selectedSession, sessionData, dataType) {
    //graph type should be either capacitance or other

    const activityDataKey =
      selectedSession.ActivityType === "Run" ? "runData" : "jumpData";

    if (dataType === "capacitance") {
      return true;
    } else if (
      dataType === "other" &&
      activityDataKey === "runData" &&
      Object.keys(sessionData?.[activityDataKey]?.data).length &&
      Object.keys(sessionData?.[activityDataKey]?.data.ContactTimeData).length >
        0 &&
      Object.keys(sessionData?.[activityDataKey]?.data.FootstrikeData).length >
        0 &&
      Object.keys(
        sessionData?.[activityDataKey]?.data.PeakTibialAccelerationData
      ).length > 0 &&
      Object.keys(sessionData?.[activityDataKey]?.data.StrideSymmetryData)
        .length > 0 &&
      Object.keys(sessionData?.[activityDataKey]?.data.SwingStanceData).length >
        0 &&
      Object.keys(sessionData?.[activityDataKey]?.data.cadenceData).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <TestSlide modalIn={playerActivity.graphModal} className="activity-modal">
      <div className="player-activity-box" id="modal-activity-box">
        <div className="reportsContainer">
          <div className="is-flex is-flex-direction-column ">
            <div className="player-activity-container">
              <div className="player-profile-icon-container">
                <div>
                  {thisSession && thisSession.playerIconUrl ? (
                    <img
                      className="default-placeholder-size is-clickable"
                      src={thisSession.playerIconUrl}
                      alt="player icon"
                    />
                  ) : (
                    <div className="default-placeholder" />
                  )}
                </div>
              </div>

              <div className="reports-athlete-info-container">
                <div className="reports-athlete-name_sessions">
                  {thisSession &&
                    thisSession.playerName &&
                    fromPlayerProfile && (
                      <div className="reports-athlete-name">
                        <Link
                          to={UserProfile_URL + "/" + thisSession.playerID}
                          onClick={() => (
                            closeModal(),
                            enableScrollModalClosed(),
                            scrollToTop(),
                            setGraphDomain(false),
                            setGraphTrimDomain(["auto", "auto"])
                          )}
                        >
                          {thisSession.playerName}
                        </Link>
                      </div>
                    )}

                  {thisSession &&
                    thisSession.playerName &&
                    !fromPlayerProfile && (
                      <Link
                        to={UserProfile_URL + "/" + thisSession.playerID}
                        onClick={() => (
                          closeModal(),
                          enableScrollModalClosed(),
                          scrollToTop(),
                          setGraphDomain(false),
                          setGraphTrimDomain(["auto", "auto"])
                        )}
                      >
                        {thisSession.playerName}
                      </Link>
                    )}

                  {thisSession &&
                  thisSession?.detail != thisSession.playerName ? (
                    <div
                      onClick={showDetailForm}
                      className="reports-athlete-info-details"
                    >
                      <div className="reports-athlete-info-value-container">
                        <p className="reports-athlete-info-heading">Details</p>
                        <EditIcon />
                      </div>
                      {editForm ? (
                        <textarea
                          className="detail-input-text-area"
                          id="detailinput"
                          placeholder={thisSession.detail || "Session Details"}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={textareaValue}
                          autoFocus={true}
                        />
                      ) : (
                        <p className="reports-athlete-info-value">
                          {displaySessionDetail(thisSession)}
                          {/* {thisSession.detail} */}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className=""> </div>
                  )}
                </div>
                <div className="session-information_details">
                  {thisSession && thisSession.ActivityName && (
                    <div className="reports-athlete-info-type">
                      <div>
                        <div className="session-type">
                          <p className="reports-athlete-info-heading">Type</p>
                          <div className="edit-session-type-button">
                            <ActivityChangeModal
                              button={<EditIcon />}
                              currentActivity={thisSession.ActivityName}
                              activityList={activityList.activities}
                              sessionID={thisSession.ID}
                              rerunMetrics={rerunMetrics}
                              checkForJumpData={checkForJumpData}
                            />
                          </div>
                        </div>
                        <p className="reports-athlete-info-value">
                          {/* {thisSession.ActivityName} */}
                          {activity[0]?.name
                            ? activity[0]?.name
                            : thisSession.ActivityName}
                        </p>
                      </div>
                    </div>
                  )}

                  {thisSession && selectedSessionId && (
                    <div className="reports-athlete-info-type">
                      <div>
                        <div className="session-title-tootip">
                          <p className="reports-athlete-info-heading">
                            Session
                          </p>
                          <div style={{ paddingLeft: "0.5rem" }} />
                          {props.user.permissionLevel === 1 && (
                            <MainTooltip
                              tooltipText={`${
                                sessionList?.jumpData?.data?.mv
                                  ? sessionList?.jumpData?.data?.mv
                                  : sessionList?.runData?.data?.mv
                                  ? sessionList?.runData?.data?.mv
                                  : "Rerun metrics to get metric version"
                              }`}
                              tooltipIcon={"info"}
                              iconSize={15}
                            />
                          )}
                        </div>

                        <p className="reports-athlete-info-value">
                          {selectedSessionId}
                        </p>
                      </div>
                    </div>
                  )}

                  {thisSession && thisSession.duration != "0" && (
                    <div className="reports-athlete-info-type">
                      <div>
                        <div className="session-title-tootip">
                          <p className="reports-athlete-info-heading">
                            Duration
                          </p>
                          <div style={{ paddingLeft: "0.5rem" }} />
                          <MainTooltip
                            tooltipText={`Format HH:MM:SS:MS`}
                            tooltipIcon={"info"}
                            iconSize={15}
                          />
                        </div>
                        <p className="reports-athlete-info-value">
                          {msToTime(thisSession.duration)}
                        </p>
                      </div>
                    </div>
                  )}

                  {thisSession && thisSession.duration != "0" && (
                    <div className="reports-athlete-info-type">
                      <div>
                        <p className="reports-athlete-info-heading">Date</p>
                        <p className="reports-athlete-info-value">
                          {displayDateForPlayerList(thisSession.tsCreated)}
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="buttons-container">
                  <div className="buttons">
                    <div className="session-settings-button">
                      <MainModal
                        button={<Button value={"Session Settings"} />}
                        modalBody={
                          <div className="session-settings-container">
                            <div className="session-settings-heading">
                              <h4>Session Settings</h4>
                            </div>
                            <div className="session-settings-controls">
                              {props.user.permissionLevel === 1 && (
                                <div className="reports-buttons">
                                  <div
                                    onClick={() => handleDiscardSessionModal()}
                                  >
                                    <MainButton
                                      buttonText={"Discard Session"}
                                      buttonImportant={true}
                                      ButtonIcon={DeleteIcon}
                                      buttonIconSize={20}
                                      buttonFontSize={12}
                                    />
                                  </div>
                                </div>
                              )}
                              {props.user.permissionLevel === 1 && (
                                <div className="reports-buttons">
                                  <div onClick={(e) => rerunMetrics(e)}>
                                    {/* <Button
                                    value={rerunMetricButton}
                                    id={"rerunMetric"}
                                    warning={
                                      rerunMetricButton ===
                                      "Metric Process Initiated"
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      rerunMetricButton ===
                                      "Metric Process Initiated"
                                        ? true
                                        : false
                                    }
                                  /> */}
                                    <MainButton
                                      buttonText={rerunMetricButton}
                                      ButtonIcon={ResetIcon}
                                      buttonIconSize={20}
                                      buttonFontSize={12}
                                      buttonImportant={
                                        rerunMetricButton ===
                                        "Metric Process Initiated"
                                          ? true
                                          : false
                                      }
                                      buttonDisabled={
                                        rerunMetricButton ===
                                        "Metric Process Initiated"
                                          ? true
                                          : false
                                      }
                                      buttonLoading={
                                        rerunMetricButton ===
                                        "Metric Process Initiated"
                                          ? true
                                          : false
                                      }
                                      buttonTooltip={"Metric Rerun Initialised"}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="session-settings-layout">
                              <div className="session-settings-header">
                                <h5>Toggle Metrics</h5>
                              </div>
                              <div className="session-settings-body">
                                {sessionMetricsToggled.map((item) => (
                                  <div
                                    className={`metric-present-toggle-button ${
                                      disabledGraphIDs.includes(item) &&
                                      "disabled-graph-toggler"
                                    }`}
                                    key={`option-${item}`}
                                    id={`option-${item}`}
                                    onClick={() =>
                                      toggleViewOfGraph(`option-${item}`, item)
                                    }
                                  >
                                    {item?.split("_")?.join(" ")}
                                    {disabledGraphIDs.includes(item) && (
                                      <WarningTooltip
                                        warningIconType={"infoFilled"}
                                        warningText={"Metric is toggled off"}
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                              <div
                                className="session-settings-submission-controls"
                                onClick={() => updateSessionLayout()}
                              >
                                <MainButton
                                  buttonText={"Update Metric Layout"}
                                />
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>

                    <div className="reports-buttons">
                      <ReportsDownloadModal
                        button={<Button value={"Download Files"} />}
                        sessionID={selectedSessionId}
                        sessionList={session.sessionList.data}
                      />
                    </div>

                    {thisSession && (
                      <div className="reports-buttons">
                        <SessionTaggingModal
                          button={<Button value={"Session Tags"} />}
                          sessionTags={JSON.parse(thisSession.tags)}
                          orgID={thisSession.orgID}
                          sessionID={thisSession.ID}
                          setTagBeingAdded={setTagBeingAdded}
                          user={props.user.id}
                        />
                      </div>
                    )}
                  </div>
                  {disabledGraphIDs.length > 0 && (
                    <div className="session-disabled-warning">
                      <WarningTooltip
                        warningText={`The following metrics have been toggled off: ${disabledGraphIDs.map(
                          (item, index) =>
                            item?.split("_")?.join(" ") +
                            (index < disabledGraphIDs.lenght ? ", " : "")
                        )}`}
                        warningIconSize={20}
                        warningIconColor={"#e67e22"}
                        warningIconType={"infoFilled"}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div
                onClick={() => {
                  closeModal(),
                    enableScrollModalClosed(),
                    scrollToTop(),
                    setGraphDomain(false),
                    setGraphTrimDomain(["auto", "auto"]),
                    setIsTrimOpen(false),
                    sessionLoading === false ? setSessionLoading(true) : null,
                    setSessionLoading(true),
                    actions.resetAllData();
                }}
                className="close-reports-container"
              >
                <EscapeIcon size="18px" color="white" />
              </div>
            </div>{" "}
            {thisSession && thisSession.tags !== "[]" && (
              <div className="player-activity-tag-container">
                {/*Tags container for each session*/}
                <div className="player-activity-container-tags">
                  <div className="tags-container">
                    {thisSession && thisSession.tags && (
                      <div className="tags">
                        {JSON.parse(thisSession.tags).map((item) => (
                          <div className="tag" key={item.STID}>
                            <p>{item.tag}</p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`${
                sessionLoading ? "loading-background" : "loading-finished"
              }`}
            >
              <Spinner cls="spinner" />
            </div>
            {/* end top row */}
            {sessionList.runData.status !== "failed" &&
              thisSession &&
              (thisSession.runTypeID > 0 ||
                thisSession.activityTypeID === 1) && (
                <div className="metric-list-container">
                  {sessionList.runData.data &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Metric_Video") && (
                      <div
                        className={`pressure-mapping-video toggle-metric-class ${
                          disabledGraphIDs.includes("Foot_Phase_Images") &&
                          "full-width"
                        }`}
                        id="Metric_Video"
                      >
                        <Video sessionid={selectedSessionId} />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Foot_Phase_Images") && (
                      <div
                        className={`pressure-mapping-pictures toggle-metric-class ${
                          disabledGraphIDs.includes("Metric_Video") &&
                          "full-width"
                        }`}
                        id="Foot_Phase_Images"
                      >
                        <PictureMaps />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    renderCapacitanceOnly(
                      thisSession,
                      sessionList,
                      "capacitance"
                    ) &&
                    !disabledGraphIDs.includes("Pressure_Trace") && (
                      <div
                        className="force-graph toggle-metric-class"
                        id="Pressure_Trace"
                      >
                        <Force
                          sessionid={selectedSessionId}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}

                  {/* <div className="to-be-added">
                      <AverageStrideTime
                        runData={sessionList.runData}
                        graphDomain={graphDomain}
                      />
                    </div> */}

                  {thisSession.ActivityName === "Sprint" &&
                    sessionList.runData.data?.gaitMetricData?.driveData &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Drive_Index") && (
                      <div
                        className="to-be-added toggle-metric-class"
                        id="Drive_Index"
                      >
                        <DriveIndex
                          runData={sessionList.runData}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          yRange={asymRangeArray_y}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Contact_Time") && (
                      <div
                        className={`contact-time-graph toggle-metric-class`}
                        id="Contact_Time"
                      >
                        <GroundContactTime
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Contact_Time") && (
                      <div className="contact-time-asym">
                        <GroundContactTimeAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    activity?.[0]?.name !== "Walk" &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Flight_Time") && (
                      <div
                        className="flight-time-graph toggle-metric-class"
                        id="Flight_Time"
                      >
                        <FlightTime
                          match={props.match}
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    activity?.[0]?.name !== "Walk" &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Flight_Time_Asymmetry") &&
                    !disabledGraphIDs.includes("Flight_Time") && (
                      <div
                        className="flight-time-asym"
                        id="Flight_Time_Asymmetry"
                      >
                        <FlightTimeAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          thisSession={thisSession}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    sessionList.runData.data.gaitMetricData?.strideArray
                      ?.stepData &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Stride_Time") && (
                      <div
                        className="ground-clearance-graph toggle-metric-class"
                        id="Stride_Time"
                      >
                        <StrideTime
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    sessionList.runData.data.StrideSymmetryData?.stride &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Stride_Time_Asymmetry") &&
                    !disabledGraphIDs.includes("Stride_Time") && (
                      <div
                        className="ground-clearance-asym"
                        id="Stride_Time_Asymmetry"
                      >
                        <StrideTimeAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}

                  {sessionList.runData.data &&
                    sessionList.runData.data.gaitMetricData?.swingArray
                      ?.stepData &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Swing_Time") && (
                      <div
                        className="ground-clearance-graph toggle-metric-class"
                        id="Swing_Time"
                      >
                        <SwingTime
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    sessionList.runData.data.StrideSymmetryData?.swing &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Swing_Time_Asymmetry") &&
                    !disabledGraphIDs.includes("Swing_Time") && (
                      <div
                        className="ground-clearance-asym"
                        id="Swing_Time_Asymmetry"
                      >
                        <SwingTimeAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    sessionList.runData.data.gaitMetricData?.strideLength &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Stride_Length") && (
                      <div
                        className="ground-clearance-graph toggle-metric-class"
                        id="Stride_Length"
                      >
                        <StrideLength
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    sessionList.runData.data.StrideSymmetryData?.stepLength &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Step_Length_Asymmetry") &&
                    !disabledGraphIDs.includes("Step_Length") && (
                      <div
                        className="ground-clearance-asym"
                        id="Step_Length_Asymmetry"
                      >
                        <StrideLengthAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    sessionList.runData.data.gaitMetricData?.stepLength &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Step_Length") && (
                      <div
                        className="ground-clearance-graph toggle-metric-class"
                        id="Step_Length"
                      >
                        <StepLength
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    sessionList.runData.data.StrideSymmetryData?.stepLength &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Step_Length_Asymmetry") &&
                    !disabledGraphIDs.includes("Step_Length") && (
                      <div
                        className="ground-clearance-asym"
                        id="Step_Length_Asymmetry"
                      >
                        <StepLengthAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}

                  {sessionList.runData.data &&
                    (sessionList.runData.data.gaitMetricData
                      ?.meanStrideVelocity ||
                      sessionList.runData.data.gaitMetricData
                        ?.maxStrideVelocity) &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Stride_Velocity") && (
                      <div
                        className="ground-clearance-graph toggle-metric-class"
                        id="Stride_Velocity"
                      >
                        <StrideVelocity
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          strideVelocityDisplayed={strideVelocityDisplayed}
                          setStrideVelocityDisplayed={
                            setStrideVelocityDisplayed
                          }
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    (sessionList.runData.data.StrideSymmetryData
                      ?.meanStrideVelocity ||
                      sessionList.runData.data.StrideSymmetryData
                        ?.maxStrideVelocity) &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Stride_Velocity_Asymmetry") &&
                    !disabledGraphIDs.includes("Stride_Velocity") && (
                      <div
                        className="ground-clearance-asym"
                        id="Stride_Velocity_Asymmetry"
                      >
                        <StrideVelocityAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          strideVelocityDisplayed={strideVelocityDisplayed}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {/* {sessionList.runData.data &&
                    sessionList.runData.data.gaitMetricData?.groundClearance &&
                    renderCapacitanceOnly(
                      thisSession,
                      sessionList,
                      "other"
                    ) && (
                      <div className="ground-clearance-graph toggle-metric-class">
                        <GroundClearance
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )} */}
                  {/* {sessionList.runData.data &&
                    sessionList.runData.data.StrideSymmetryData
                      ?.groundClearance &&
                    renderCapacitanceOnly(
                      thisSession,
                      sessionList,
                      "other"
                    ) && (
                      <div className="ground-clearance-asym toggle-metric-class">
                        <GroundClearanceAsymmetry
                          sessionid={selectedSessionId}
                          xRange={asymRangeArray}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )} */}
                  {sessionList.runData.data &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Cadence") && (
                      <div
                        className={` estimated-steps-graph toggle-metric-class ${
                          disabledGraphIDs.includes(
                            "Peak_Tibial_Acceleration"
                          ) && "full-width"
                        }`}
                        id="Cadence"
                      >
                        <CadenceReport
                          cadenceFrequencyToggler={cadenceFrequencyToggler}
                          setCadenceFrequencyToggler={
                            setCadenceFrequencyToggler
                          }
                          sessionid={selectedSessionId}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Peak_Tibial_Acceleration") && (
                      <div
                        className={`peak-tibial-acceleration-graph toggle-metric-class ${
                          disabledGraphIDs.includes("Cadence") && "full-width"
                        }`}
                        id="Peak_Tibial_Acceleration"
                      >
                        <PeakTibialAcceleration
                          sessionid={selectedSessionId}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}
                  {sessionList.runData.data &&
                    playerActivity.graphModal &&
                    renderCapacitanceOnly(thisSession, sessionList, "other") &&
                    !disabledGraphIDs.includes("Swing_Stance_Footstrike") && (
                      <div
                        className="swing-stance-foot-strike toggle-metric-class"
                        id="Swing_Stance_Footstrike"
                      >
                        {/* <FootstrikeReport sessionid={sessionId} /> */}
                        <SwingStanceFootStrike />
                      </div>
                    )}
                </div>
              )}
            {sessionList.rawData.status !== "failed" &&
              thisSession &&
              thisSession.activityTypeID === 5 && (
                <div className="metric-list-container">
                  {sessionList?.rawData?.data?.multimediaData?.videoURL &&
                    !disabledGraphIDs.includes("Metric_Video") && (
                      <div
                        className={`pressure-mapping-video toggle-metric-class ${
                          // !(disabledGraphIDs.includes("Foot_Phase_Images") ||
                          // (sessionList?.rawData?.data?.multimediaData?.endCOPImgURL ||
                          //   sessionList?.rawData?.data?.multimediaData?.stanceCOPImgURL ||
                          //   sessionList?.rawData?.data?.multimediaData?.startCOPImgURL)) &&
                          "full-width"
                        }`}
                        id="Metric_Video"
                      >
                        <Video sessionid={selectedSessionId}></Video>
                      </div>
                    )}

                  {/* Temp removed images as they are not being generated for the ballance sessions atm 12/06/24
                  
                  {sessionList.rawData.status !== "failed" &&
                   (sessionList?.rawData?.data?.multimediaData?.endCOPImgURL ||
                    sessionList?.rawData?.data?.multimediaData?.stanceCOPImgURL ||
                    sessionList?.rawData?.data?.multimediaData?.startCOPImgURL) &&
                    !disabledGraphIDs.includes("Foot_Phase_Images") && (
                      <div
                        className={`pressure-mapping-pictures toggle-metric-class ${
                          // disabledGraphIDs.includes("Metric_Video") &&
                          "full-width"
                        }`}
                        id="Foot_Phase_Images"
                      >
                        <PictureMaps />
                      </div>
                    )} */}
                  {sessionList.rawData.data &&
                    renderCapacitanceOnly(
                      thisSession,
                      sessionList,
                      "capacitance"
                    ) &&
                    !disabledGraphIDs.includes("Pressure_Trace") && (
                      <div
                        className="force-graph toggle-metric-class"
                        id="Pressure_Trace"
                      >
                        <Force
                          sessionid={selectedSessionId}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                          isTrimOpen={isTrimOpen}
                        />
                      </div>
                    )}

                  <div
                    className="stability_score-graph toggle-metric-class metric_padding_bottom"
                    id="Stability_Score"
                  >
                    <MainChartComponent
                      fixedHeight={"230px"}
                      chartTitle={"Stability Score"}
                      tooltipText={"Stability Score Tooltip"}
                      chartType={"bullet"}
                      chartOrientation={"vertical"}
                      chartHeight={"150px"}
                      chartDataKey={"stabilityScore"}
                      bilateralChart={true}
                      bilateralKeys={{
                        left: "leftResults",
                        right: "rightResults",
                        mean: "meanResults",
                      }}
                      hasToggler={true}
                      togglerValues={{ left: "Mean", right: "Individual" }}
                      settingsChartKey={"stability-score"}
                    />
                  </div>

                  <div
                    className="ballance_metrics_tiles toggle-metric-class metric_padding_bottom"
                    id="Tiled_Balance_Metrics"
                  >
                    <MainChartComponent
                      fixedHeight={"250px"}
                      chartType={"tiles"}
                      tilesforRendering={[
                        {
                          metricName: "Area of Ellipse",
                          metricValuesKeys: {
                            mean: "meanResults",
                            left: "leftResults",
                            right: "rightResults",
                            dataKey: "areaOfEllipse",
                          },
                          metricUnit: "CM²",
                        },
                        {
                          metricName: "Length of Ellipse",
                          metricValuesKeys: {
                            mean: "meanResults",
                            left: "leftResults",
                            right: "rightResults",
                            dataKey: "lengthOfEllipse",
                          },
                          metricUnit: "CM",
                        },
                        {
                          metricName: "Width of Ellipse",
                          metricValuesKeys: {
                            mean: "meanResults",
                            left: "leftResults",
                            right: "rightResults",
                            dataKey: "widthOfEllipse",
                          },
                          metricUnit: "CM",
                        },
                        {
                          metricName: "Medio Lateral Range",
                          metricValuesKeys: {
                            mean: "meanResults",
                            left: "leftResults",
                            right: "rightResults",
                            dataKey: "medioLateralRange",
                          },
                          metricUnit: "CM",
                        },
                        {
                          metricName: "Total Displacement",
                          metricValuesKeys: {
                            mean: "meanResults",
                            left: "leftResults",
                            right: "rightResults",
                            dataKey: "totalDisplacement",
                          },
                          metricUnit: "CM",
                        },
                      ]}
                      hasSettings={true}
                      settingsSide={"right-side"}
                      settingsChartKey={"tiled-balance-metrics"}
                      togglerValues={{ left: "Mean", right: "Individual" }}
                    />
                  </div>
                </div>
              )}
            {thisSession &&
              (thisSession.jumpTypeID > 0 ||
                thisSession.activityTypeID === 2) &&
              thisSession.jumpTypeID != 19 && ( //19 == Isometric Calf  Test
                <div className="metric-list-container">
                  {!disabledGraphIDs.includes("Metric_Video") && (
                    <div
                      className={`pressure-mapping-video toggle-metric-class ${
                        disabledGraphIDs.includes("Foot_Phase_Images") &&
                        "full-width"
                      }`}
                      id="Metric_Video"
                    >
                      <Video sessionid={selectedSessionId}></Video>
                    </div>
                  )}
                  {!disabledGraphIDs.includes("Foot_Phase_Images") && (
                    <div
                      className={`pressure-mapping-pictures toggle-metric-class ${
                        disabledGraphIDs.includes("Metric_Video") &&
                        "full-width"
                      }`}
                      id="Foot_Phase_Images"
                    >
                      <PictureMaps />
                    </div>
                  )}
                  {!disabledGraphIDs.includes("Pressure_Trace") && (
                    <div
                      className="force-graph toggle-metric-class"
                      id="Pressure_Trace"
                    >
                      <Force
                        sessionid={selectedSessionId}
                        displayTotal={true}
                        trimDomain={graphTrimDomain}
                        graphDomain={graphDomain}
                        showSettings={true}
                      />
                    </div>
                  )}

                  {metricRules.DisplayRSI(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) &&
                    !disabledGraphIDs.includes("RSI") && (
                      <div className="rsi-graph toggle-metric-class" id="RSI">
                        <RSI
                          sessionid={selectedSessionId}
                          xRange={asymJumpRangeArray}
                          graphDomain={graphDomain}
                          showSettings={true}
                        />
                      </div>
                    )}

                  {metricRules.DisplayJumpHeight(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) &&
                    !disabledGraphIDs.includes("Jump_Height") && (
                      <div
                        className="jump-height-graph toggle-metric-class"
                        id="Jump_Height"
                      >
                        <JumpHeight
                          sessionid={selectedSessionId}
                          xRange={asymJumpRangeArray}
                          graphDomain={graphDomain}
                          showSettings={true}
                        />
                      </div>
                    )}
                  {metricRules.DisplayFlightTimeJump(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) &&
                    !disabledGraphIDs.includes("Flight_Time") && (
                      <div
                        className="flight-time-jump-graph toggle-metric-class"
                        id="Flight_Time"
                      >
                        <FlightTimeJump
                          thisSession={thisSession}
                          sessionid={selectedSessionId}
                          xRange={asymJumpRangeArray}
                          graphDomain={graphDomain}
                          showSettings={true}
                        />
                      </div>
                    )}
                  {/* {metricRules.DisplayGroundClearanceJump(
                    thisSession.activityTypeID,
                    thisSession.activityID
                  ) && (
                    <div className="ground-clearance-jump-graph">
                      <GroundClearanceJump
                        sessionid={selectedSessionId}
                        yRange={asymRangeArray_y}
                        graphDomain={graphDomain}
                        trimDomain={graphTrimDomain}
                        showSettings={true}
                      />
                    </div>
                  )} */}
                  {metricRules.DisplayJumpLength(
                    thisSession.activityTypeID,
                    thisSession.activityID
                  ) &&
                    !disabledGraphIDs.includes("Jump_Length") && (
                      <div
                        className="jump-length-graph toggle-metric-class"
                        id="Jump_Length"
                      >
                        <JumpLength
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          graphDomain={graphDomain}
                          trimDomain={graphTrimDomain}
                          showSettings={true}
                        />
                      </div>
                    )}
                  {metricRules.DisplayJumpVelocity(
                    thisSession.activityTypeID,
                    thisSession.activityID
                  ) &&
                    !disabledGraphIDs.includes("Jump_Velocity") && (
                      <div
                        className="jump-velocity-graph toggle-metric-class"
                        id="Jump_Velocity"
                      >
                        <JumpVelocity
                          sessionid={selectedSessionId}
                          yRange={asymRangeArray_y}
                          showSettings={true}
                          jumpVelocityDisplayed={jumpVelocityDisplayed}
                          setJumpVelocityDisplayed={setJumpVelocityDisplayed}
                        />
                      </div>
                    )}
                  {metricRules.DisplayContactTimeJump(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) &&
                    !disabledGraphIDs.includes("Contact_Time") && (
                      <div
                        className="contact-time-jump-graph toggle-metric-class"
                        id="Contact_Time"
                      >
                        <ContactTimeJump
                          sessionid={selectedSessionId}
                          xRange={asymJumpRangeArray}
                          graphDomain={graphDomain}
                          showSettings={true}
                        />
                      </div>
                    )}
                  {metricRules.DisplayPower(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) &&
                    !disabledGraphIDs.includes("Peak_Power") && (
                      <div
                        className="jump-power-graph toggle-metric-class"
                        id="Peak_Power"
                      >
                        <Power
                          sessionid={selectedSessionId}
                          xRange={asymJumpRangeArray}
                          graphDomain={graphDomain}
                          showSettings={true}
                        />
                      </div>
                    )}
                </div>
              )}
            {thisSession &&
              thisSession.activityID === 21 &&
              !disabledGraphIDs.includes("Pressure_Trace") && ( //other
                <div className="metric-list-container">
                  {thisSession &&
                    sessionList?.runData?.data?.multimediaData?.videoURL &&
                    thisSession.activityID === 21 &&
                    !disabledGraphIDs.includes("Metric_Video") && (
                      <div
                        className={`pressure-mapping-video toggle-metric-class full-width`}
                        id="Metric_Video"
                      >
                        <Video sessionid={selectedSessionId} />
                      </div>
                    )}
                  <div
                    className="force-graph toggle-metric-class"
                    id="Pressure_Trace"
                  >
                    <Force sessionid={selectedSessionId} showSettings={true} />
                  </div>
                </div>
              )}
            {thisSession &&
              (thisSession.runTypeID > 0 ||
                thisSession.activityTypeID === 1) && (
                <div className="metric-brush-container-closed">
                  <div
                    className="testButton"
                    onClick={() => handleBrushResize()}
                  >
                    <div className="brush-arrow">
                      <MagnifyingGlass direction={"up"} />
                    </div>
                  </div>
                  <div className="brush-container">
                    <MetricBrush
                      setGraphDomain={setGraphDomain}
                      resetBrush={domainToggle}
                      trimDomain={
                        graphTrimDomain[0] !== "auto" ? graphTrimDomain : false
                      }
                    />
                    <div className="brush-options">
                      <div
                        onClick={() => {
                          setGraphDomain(
                            graphTrimDomain[0] !== "auto"
                              ? graphTrimDomain
                              : false
                          );
                          setDomainToggle(!domainToggle);
                        }}
                      >
                        <Button value={"Clear Zoom"} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {thisSession &&
              (thisSession.runTypeID > 0 ||
                thisSession.activityTypeID === 1) && (
                <div className="metric-trim-brush-container-closed">
                  <div className="testButton" onClick={() => handleBrushTrim()}>
                    <div className="brush-arrow">
                      <TrimIcon
                        size="17px"
                        fill={graphDomain ? "#49afc8" : "#ffffff"}
                      />
                    </div>
                  </div>
                  <div className="brush-container">
                    <MetricTrimBrush
                      sessionID={thisSession.ID}
                      setGraphTrim={setGraphTrimDomain}
                      graphDomain={graphDomain}
                      resetTrim={trimToggle}
                      isTrimOpen={isTrimOpen}
                    />
                    <div className="trim-options">
                      <div
                        onClick={async () => {
                          const postBody = [
                            {
                              from: graphTrimDomain[0] * 1000,
                              to: graphTrimDomain[1] * 1000,
                            },
                          ];
                          const setSessionTrimCheck = await setSessionTrim(
                            thisSession.ID,
                            postBody
                          );

                          if (setSessionTrimCheck.data === true) {
                            if ((await thisSession.ActivityType) === "Jump") {
                              await actions.resetJumpData(thisSession.ID);
                              setTrimToggle(!trimToggle);
                              updatedData = await getJumpDataAPI(
                                thisSession.ID
                              );
                              let domainStart =
                                (await updatedData.td[0].from) / 1000;
                              let domainEnd =
                                (await updatedData.td[0].to) / 1000;
                              setGraphDomain([domainStart, domainEnd]);
                              handleBrushTrim();
                            }

                            if ((await thisSession.ActivityType) === "Run") {
                              await actions.resetRunData(thisSession.ID);
                              setTrimToggle(!trimToggle);
                              updatedData = await getRunDataAPI(thisSession.ID);
                              let domainStart =
                                (await updatedData.td[0].from) / 1000;
                              let domainEnd =
                                (await updatedData.td[0].to) / 1000;
                              setGraphDomain([domainStart, domainEnd]);
                              handleBrushTrim();
                            }
                          }
                        }}
                      >
                        <Button value={"Confirm Trim"} />
                      </div>
                      <div
                        onClick={async () => {
                          await resetSessionTrim(thisSession.ID);
                          setTrimToggle(!trimToggle);
                          setGraphTrimDomain(false);
                          setGraphDomain(false);
                          checkForJumpData();
                        }}
                      >
                        <Button value={"Clear Trim"} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      {
        ///discard session modal
        <AlertDialogDemo
          type="discardSession"
          open={discardSessionModalOpen}
          proceed={discardSession}
          setDiscardSessionModalOpen={setDiscardSessionModalOpen}
        />
      }
    </TestSlide>
  );
}

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSessionList,
      getupdateSessionDetail,
      getJumpData,
      getRunData,
      resetJumpData,
      resetRunData,
      resetAllData,
    },
    dispatch
  ),
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(PlayerActivityDetails)
);
