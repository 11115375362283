export function thisUserIsAnAdminOfThisOrg(org) {

  if (localStorage.user && org) {

    let loggedInUser = JSON.parse(localStorage.user)
    if (loggedInUser.permissionLevel === 1) {
      return true
    }

    for (var i = 0; i < org.admins.length; i++) {
      var thisAdmin = org.admins[i];
      if (thisAdmin.userid === loggedInUser.id) {
        return true
      }
    }
  }

  return false
}

export function thisUserIsACoachOfThisOrg(org) {
  if (localStorage.user && org) {

    let loggedInUser = JSON.parse(localStorage.user)
    if (loggedInUser.permissionLevel === 1) {
      //user is Super Admin - so can see admin of all orgs!
      return true
    }

    for (var i = 0; i < org.coaches.length; i++) {
      var thisCoach = org.coaches[i];
      if (thisCoach.userid === loggedInUser.id) {
        //the logged in user IS in the list of admins for this org
        return true
      }
    }
  }
  return false
}

export function thisUserIsAPlayerOfThisOrg(org) {
  if (localStorage.user && org) {

    let loggedInUser = JSON.parse(localStorage.user)
    if (loggedInUser.permissionLevel === 1) {
      //user is Super Admin - so can see admin of all orgs!
      return true
    }

    for (var i = 0; i < org.players.length; i++) {
      var thisPlayer = org.players[i];
      if (thisPlayer.userid === loggedInUser.id) {
        //the logged in user IS in the list of admins for this org
        return true
      }
    }
  }
  return false
}
