import { api } from "./api";

export const getSessionListAPI = () => {
  return api.get("session/getListOfSessions").then((res) => {
    return res;
  });
};

export const updateSessionDetailAPI = (data) => {
  return api.post("UpdateSessionDetail", data).then((res) => {
    return res;
  });
};

//api for discarding session session
export const discardSessionAPI = (sessionID) => {
  return api
  .get(`/session/discardSession/${sessionID}`)
    .then((res) => {
      let returnObject = res.data;
      return returnObject;
    });
}

export const reRunMetricsAPI = (sessionID) => {
  return api
    .get(`/session-metric-data/reRunMetricsAPI1/${sessionID}`)
    .then((res) => {
      let returnObject = res.data;
      return returnObject;
    });
};

export const checkSessionDataStatus = (sessionID) => {
  return api
    .get(`/session-metric-data/checkSessionDataStatus/${sessionID}`)
    .then((res) => {
      let returnObject = res.data;
      return returnObject;
    });
};

export const getRunDataAPI = (sessionID) => {
  return api
    .get(`/session-metric-data/getRunDataMetrics/${sessionID}`)
    .then((res) => {
      let returnObject = res.data;

      return returnObject;
    });
};

export const getRawMetrics = (sessionID) => {
  return api.get(`/session-metric-data/getRawMetrics/${sessionID}`)
  .then((res) => {
    let returnObject = res.data;

    return returnObject;
  });
}

export const getMultipleGaitSessionAPI = (sessionIDs) => {
  // /session-metric-data/getRunDataMetricsForMultipleSessions/22807,22808
  return api
    .get(`/session-metric-data/getRunDataMetricsForMultipleSessions/${sessionIDs}`)
    .then((res) => {
      let returnObject =  res.data;
      return returnObject
    })
}

export const getMultipleJumpSessionAPI = (sessionIDs) => {
  // /session-metric-data/getRunDataMetricsForMultipleSessions/22807,22808
  return api
    .get(`/session-metric-data/getJumpDataMetricsForMultipleSessions/${sessionIDs}`)
    .then((res) => {
      let returnObject =  res.data;
      return returnObject
    })
}

export const getJumpDataAPI = (sessionID) => {
  return api
    .get(`/session-metric-data/getJumpDataMetrics/${sessionID}`)
    .then((res) => {
      if (res.data.JumpMetricData) {
        let returnObject = JSON.parse(res.data.JumpMetricData);
        return returnObject;
      } else {
        //let returnObject = JSON.parse(res.data)
        return res.data;
      }
    });
};

export const getAthleteHistoricalGaitChartOverViewData = (athleteID) => {
  return api
    .get(
      `/session-metric-data/getAthleteHistoricalGaitChartOverViewData/${athleteID}/`
    )
    .then((res) => {
      let returnObject = res.data;
      return returnObject;
    });
};

//api call for gait activity
export const getAthleteHistoricalLoadChartData = (athleteID) => {
  return api
    .get(`/session-metric-data/getAthleteHistoricalLoadChartData/${athleteID}/`)
    .then((res) => {
      let returnObject = res.data;
      return returnObject;
    });
};

export const getAthleteHistoricalGroundContactTimeAsymData = (athleteID) => {
  return api
    .get(
      `/session-metric-data/getAthleteHistoricalGroundContactTimeAsymData/${athleteID}/`
    )
    .then((res) => {
      let returnObject = res.data;
      return returnObject;
    });
};

//api call for getting all user raw data sessions
export const getAthleteHistoricalSessionRawData = (athleteID) => {
  return api
    .get(`/session-metric-data/userSessionHistory/${athleteID}/`)
    .then((res) => {
      let returnObject = res.data;
      return returnObject;
    });
};

//api for adding tag to session
export const addTagToSessionAPI = (data) => {
  return api.post(`/addTagToSession`, data).then(res => {
    return res
  })
}

//api adding mulitple tags to multiple sessions
export const addTagsToSessionsAPI = (data) => {
  return api.post(`/addTagsToSessions`, data).then(res => {
    return res
  })
}

//api for adding tag to session
export const removeTagFromSessionAPI = (stid) => {
  return api.get(`/tags/removeTagFromSession/${stid}`).then(res => {
    return res
  })
}

//api for updating session activity 
export const updateSessionActivityAPI = (data) => {
  return api.post(`/UpdateSessionActivity`, data).then(res => {
    return res
  })
}



