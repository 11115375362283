import { createSlice } from "@reduxjs/toolkit";
import { resetState } from "./resetSlice";

const initialState = {
  currentSearchTerm: "",
  selectedActivity: null,
  selectedAthlete: null,
  selectedTag: null,
  filtering: false,
  noRecords: false,
  selectedSessionId: null,
  modalIsOpen: false,
  graphModal: false,
  checkedSessions: [],
  checkedSessionForComparison: [],
  checkedAthletesForComparison: [],
};

const playerActivitySlice = createSlice({
  name: "PlayerActivity2",
  initialState,
  reducers: {
    setCurrentSearchTerm: (state, action) => {
      state.currentSearchTerm = action.payload;
    },
    setSelectedActivity: (state, action) => {
      state.selectedActivity = action.payload;
    },
    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    },
    setGraphModal: (state, action) => {
      state.graphModal = action.payload;
    },
    setSelectedAthlete: (state, action) => {
      state.selectedAthlete = action.payload;
    },
    setFiltering: (state, action) => {
      state.filtering = action.payload;
    },
    setSelectedSessionId: (state, action) => {
      state.selectedSessionId = action.payload;
    },
    setModalIsOpen: (state, action) => {
      state.modalIsOpen = action.payload;
    },
    setCheckedSessions2: (state, action) => {
      state.checkedSessions = action.payload;
    },
    setCheckedSessionsForComparison: (state, action) => {
      state.checkedSessionForComparison = action.payload;
    },
    setCheckedAthletesForComparison: (state, action) => {
      state.checkedAthletesForComparison = action.payload;
    },
    setNoRecords: (state, action) => {
      state.filtering = action.payload;
    },

    resetFilters: (state) => {
      state.currentSearchTerm = "";
      state.selectedActivity = null;
      state.selectedAthlete = null;
      state.selectedTag = null;
      state.filtering = false;
      state.selectedSessionId = null;
      state.checkedSessions = [];
      state.checkedSessionForComparison = [];
      state.checkedAthletesForComparison = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => {
      return initialState;
    });
  },
});

export const {
  setCurrentSearchTerm,
  setSelectedActivity,
  setSelectedAthlete,
  setSelectedTag,
  setFiltering,
  setSelectedSessionId,
  setModalIsOpen,
  setCheckedSessions2,
  setCheckedSessionsForComparison,
  setCheckedAthletesForComparison,
  setNoRecords,
  setOrganisationAthleteGroups,
  setOrganisationGroups,
  setGraphModal,
  setExerciseTypeGroup,
  resetFilters,
  setExerciseGroups,
} = playerActivitySlice.actions;

export const playerActivity = (state) => state.PlayerActivity;

export default playerActivitySlice.reducer;
