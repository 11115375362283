


export function getAverage(foot, stepArray) {

  let left = 0
  let right = 0
  let both = 0
  let leftCounter = 0
  let rightCounter = 0
  let bothCount = 0 

  stepArray.forEach((step) => {
    if (foot === "left") {
      if (!isNaN(Number(step["Left Foot"])) && (step["Left Foot"] !== null)) {
        left = left + Number(step["Left Foot"])
        leftCounter = leftCounter + 1
      }
    }

    if (foot === "right") {
      if (!isNaN(Number(step["Right Foot"])) && (step["Right Foot"] !== null)) {
        right = right + Number(step["Right Foot"])
        rightCounter = rightCounter + 1
      }
    }

    if (foot === "both") {
      if (!isNaN(Number(step["Both Feet"])) && (step["Both Feet"] !== null)) {
        both = both + Number(step["Both Feet"])
        bothCount = bothCount + 1
      }
    }
  })


  if (foot === "left") {
    if (left === 0) {
      return 0
    }
    return (left / leftCounter).toFixed(3)
  }
  if (foot === "right") {
    if (right === 0) {
      return 0
    }
    return (right / rightCounter).toFixed(3)
  }
  if (foot === "both") {
    if (both === 0) {
      return 0
    }
    return (both / bothCount).toFixed(3)
  }
}


export function weHaveDataForThisFoot(foot, stepArray) {

  let weHaveData = false
  stepArray.forEach((step) => {
    if (foot === "left") {
      if (!isNaN(Number(step["Left Foot"])) && (step["Left Foot"] !== null)) {
        //this is valid data for this foot!!!
        weHaveData = true
        return true
      }
    }

    if (foot === "right") {
      if (!isNaN(Number(step["Right Foot"])) && (step["Right Foot"] != null)) {
        //this is valid data for this foot!!!
        weHaveData = true
        return true
      }
    }
  })

  return weHaveData
}

export function gctMax(foot, stepArray) {

  let maxRight = 0
  let maxLeft = 0
  let maxBoth = 0
  stepArray.forEach((step) => {

    if (foot === "left") {
      if (!isNaN(Number(step["Left Foot"])) && (step["Left Foot"] !== null)) {
        maxLeft = Math.max(maxLeft, Number(step["Left Foot"]));
      }
    }
    else if (foot === "right") {
      if (!isNaN(Number(step["Right Foot"])) && (step["Right Foot"] !== null)) {
        maxRight = Math.max(maxRight, Number(step["Right Foot"]));
      }
    }
   else if (foot === "both") {
      if (!isNaN(Number(step["Both Feet"])) && (step["Both Feet"] !== null)) {
        maxBoth = Math.max(maxBoth, Number(step["Both Feet"]));
      }
    }
  })

  if (foot === "left") {
    return maxLeft
  }
  else if (foot === "right") {
    return maxRight
  }

 else if (foot === "both") {
    return maxBoth
  }

  //return Math.max(maxRight, maxLeft)
}

export function gctMin(foot, stepArray) {

  let minRight = Infinity
  let minLeft = Infinity
  let minBoth = Infinity
  stepArray.forEach((step) => {

    if (foot === "right") {
      if (!isNaN(Number(step["Right Foot"])) && (step["Right Foot"] !== null) && (step["Right Foot"] !== 0)) {
        minRight = Math.min(minRight, Number(step["Right Foot"]));
      }
    }else if (foot === "left") {
      if (!isNaN(Number(step["Left Foot"])) && (step["Left Foot"] !== null) && (step["Left Foot"] !== 0)) {
        minLeft = Math.min(minLeft, Number(step["Left Foot"]));
      }
    }else if (foot === "both") {
      if (!isNaN(Number(step["Both Feet"])) && (step["Both Feet"] !== null) && (step["Both Feet"] !== 0)) {
        minBoth = Math.min(minBoth, Number(step["Both Feet"]));
      }
    }
  })

  if (foot === "left") {
    return minLeft
  }
  else if (foot === "right") {
    return minRight
  }
  else if (foot === "both") {
    return minBoth
  }
  //return Math.min(minRight, minLeft)
}


export function isBilat(jumpTypeID, activityID) {
  if (jumpTypeID === 1 || jumpTypeID === 6 || jumpTypeID === 2 || jumpTypeID === 7 || jumpTypeID === 8 || jumpTypeID === 9 || jumpTypeID === 10)
    return true
  else if (activityID === 4 || activityID === 9 || activityID === 5 || activityID === 10 || activityID === 11 || activityID === 12 || activityID === 13)
    return true
  else
    return false
}

//cuurently all jumps use flight time chart
export function DisplayFlightTimeJump(jumpTypeID, activityID) {
  
  if (jumpTypeID !== 18 && activityID !== 21) {
    return true
  }
  return false
}

export function DisplayGroundClearanceJump(jumpTypeID, activityID) {
  if (jumpTypeID === 2 && activityID === 12) {
    return true
  }
  return false
}

export function DisplayJumpLength(jumpTypeID, activityID) {
  if (jumpTypeID === 2 && activityID === 12) {
    return true
  }
  return false
}

export function DisplayJumpVelocity(jumpTypeID, activityID) {
  if (jumpTypeID === 2 && activityID === 12) {
    return true
  }
  return false
}



export function DisplayContactTimeJump(jumpTypeID, activityID) {
  if (jumpTypeID === 2 || jumpTypeID === 7 || jumpTypeID === 8 || jumpTypeID === 10 || jumpTypeID === 13 || jumpTypeID === 14 || jumpTypeID === 15 || jumpTypeID === 16)
    return true
  else if (activityID === 5 || activityID === 10 || activityID === 11 || activityID === 13 || activityID === 16 || activityID === 17 || activityID === 18 || activityID === 19)
    return true
  else
    return false
}


export function DisplayJumpHeight(jumpTypeID, activityID) {
  if (jumpTypeID === 2 || jumpTypeID === 7 || jumpTypeID === 8 || jumpTypeID === 1 || jumpTypeID === 6 || jumpTypeID === 11 || jumpTypeID === 12)
    return true
  else if (activityID === 5 || activityID === 10 || activityID === 11 || activityID === 4 || activityID === 9 || activityID === 14 || activityID === 15)
    return true
  else
    return false
}


export function DisplayPower(jumpTypeID, activityID) {
  if (jumpTypeID === 2 || jumpTypeID === 7 || jumpTypeID === 8 || jumpTypeID === 1 || jumpTypeID === 6 || jumpTypeID === 11 || jumpTypeID === 12)
    return true
  else if (activityID === 5 || activityID === 10 || activityID === 11 || activityID === 4 || activityID === 9 || activityID === 14 || activityID === 15)
    return true
  else
    return false
}


export function DisplayRSI(jumpTypeID, activityID) {
  if (jumpTypeID === 2 || jumpTypeID === 7 || jumpTypeID === 8 || jumpTypeID === 13 || jumpTypeID === 14 || jumpTypeID === 15)
    return true
  else if (activityID === 5 || activityID === 10 || activityID === 11 || activityID === 16 || activityID === 17 || activityID === 18)
    return true
  else
    return false
}

export const getTooltipStyle = (payloadItem) => {
  if (payloadItem.name && payloadItem.name.toLowerCase().indexOf("left") > -1) {
    return 'left'
  }
  if (payloadItem.name && payloadItem.name.toLowerCase().indexOf("right") > -1) {
    return 'right'
  }
  return 'both-feet';
};

export const CustomTooltipV2 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {

    return (
      <div className="custom-tooltip">
        <div className="label"> {`Time (s): ${label}`}</div>

        {payload.length >= 1 && <div className={`${getTooltipStyle(payload[0])}`}>{`${payload[0].name}`} : {` ${payload[0].value}`}s</div>}
        {payload.length >= 2 && <div className={`${getTooltipStyle(payload[1])}`}>{`${payload[1].name}`} : {` ${payload[1].value}`}s</div>}
      </div>
    );
  }

  return null;
};


export const CustomTooltipForJumpCharts = ({ active, payload, label, data, desc}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {data[label - 1] && <div className="label">Rep {`${data[label - 1].name}`}</div>}

        {payload[0] && payload[0].name === 'Both Feet' && <div className="both-feet">{`${payload[0].name}`} : {` ${payload[0].value ? payload[0].value : "N/A "}`}{desc}</div>}
        {payload.length >= 1 && payload[0].name !== 'Both Feet' && <div className={`${getTooltipStyle(payload[0])}`}>{`${payload[0].name}`} : {` ${payload[0].value ? payload[0].value : "N/A "}`}{desc}</div>}
        {payload.length >= 2 && payload[1].name !== 'Both Feet' && <div className={`${getTooltipStyle(payload[1])}`}>{`${payload[1].name}`} : {` ${payload[1].value ? payload[1].value : "N/A "}`}{desc}</div>}
      </div>
    );
  }

  return null;
};


export const CustomTooltipForHistoricalJumps = ({ active, payload, label, data, desc }) => {
  if (active && payload && payload.length) {

    //-----------------------------------------------------------------------------------------------------------------
    let filteredPoints = data.filter(point => {
      if (point.date === label) {
        return true
      }
      return false
    })

    if (filteredPoints.length > 0) {
      let thisObject = filteredPoints[0]

      return (
        <div className="custom-tooltip">
          <div className="label"> {`${thisObject.date}`}</div>

          {thisObject.value && <div className="both-feet">Jump height: {`${thisObject.value}`}cm</div>}
          {payload.length >= 1 && payload[0].name === 'leftValue' && <div className={`${getTooltipStyle(payload[0])}`}>Left Jump Height : {` ${payload[0].value}`}{desc}</div>}
          {payload.length >= 2 && payload[1].name === 'rightValue' && <div className={`${getTooltipStyle(payload[1])}`}>Right Jump Height : {` ${payload[1].value}`}{desc}</div>}
        </div>
      );
    }
  }

  return null;
};

export const CustomTooltipForHistoricalRSI = ({ active, payload, label, data }) => {
  if (active && payload && payload.length) {

    let filteredPoints = data.filter(point => {
      if (point.date === label) {
        return true
      }
      return false
    })

    if (filteredPoints.length > 0) {
      let thisObject = filteredPoints[0]

      return (
        <div className="custom-tooltip">
          <div className="label"> {`${thisObject.date}`}</div>

          {thisObject.value && <div className="both-feet">RSI: {`${thisObject.value}`}</div>}
          {payload.length >= 1 && payload[0].name === 'leftValue' && <div className={`${getTooltipStyle(payload[0])}`}>RSI: {`${payload[0].value}`}</div>}
          {payload.length >= 2 && payload[1].name === 'rightValue' && <div className={`${getTooltipStyle(payload[1])}`}>RSI: {`${payload[1].value}`}</div>}
        </div>
      );
    }
  }

  return null;
};

export const getSymValue = (tempValue) => {
  if(Number(tempValue)){
    if (Number(tempValue) < 0)
      return Number(tempValue) * -1
    else
      return Number(tempValue)
  }
}

export const CustomTooltipForAsymmetry = ({ active, payload, }) => {
  if (active && payload && payload.length) {

    let className = "right"
    let display = ""

    let displayValue = Math.round(payload[0].value * 100) / 100
    if (payload[0].value > 0) {
      display = "Right Foot"
      className = "right"
    }
    else {
      displayValue = (Math.round(payload[0].value * 100) / 100) * -1
      display = "Left Foot"
      className = "left"
    }

    return (
      <div className="custom-tooltip">
        {/* <div className="label">{`${title}`}</div> */}
        {/* <div className={className}>{`${payload[0].name}`} : {` ${payload[0].value}`}</div> */}
        <div className={className}>{`${display}`} : {` ${displayValue}`}</div>
      </div>
    );
  }

  return null;
};
