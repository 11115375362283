/* eslint-disable */

/* router.js */
import { Route, Switch } from "react-router-dom";
import AdminHome from "../components/admin/AdminHome";
import OrganisationSetup from "../components/admin/OrganisationSetup";
import PodRegistration from "../components/admin/PodRegistration";
import HomePage from "../components/HomePage";
import Login from "../components/LoginPage";
import OrganisationDetail from "./OrganisationDetail";
import OrganisationsList from "./OrganisationsList";
import ResetPasswordPage from "../components/ResetPasswordPage";
import SessionInput from "../components/SessionInput";
import SignUpPage from "../components/SignUpPage";
import requireAuth from "../utils/auth";
import {
  AdminHome_URL,
  CoachesHome_URL,
  HOME_URL,
  LOGIN_URL,
  ManageTeams_URL,
  NewUser_URL,
  OrganisationDetail_URL,
  OrganisationSetup_URL,
  OrganisationsList_URL,
  PodList_URL,
  PodRegistration_URL,
  RESETPASS_URL,
  SessionInput_URL,
  SIGNUP_URL,
  Teams_URL,
  UserProfile_URL,
  UsersHome_URL,
} from "../utils/urls";
import CoachesHome from "./coaches/CoachesHome";
import NewUser from "./users/NewUser";
import UsersHome2 from "./users/UsersHome2";
import PodList from "./admin/PodList";
import UserProfile2 from "./users/useProfile2";
import UserProfile from "./users/UserProfile";
import NotFoundPage from "./NotFoundPage";
import TeamsPage from "./TeamsPage";
import ManageTeamsPage from "./ManageTeamsPage";

const AppRouter = () => (
  <Switch>
    <Route exact path={HOME_URL} component={requireAuth(HomePage)} />
    <Route
      exact
      path={SessionInput_URL}
      component={requireAuth(SessionInput)}
    />
    <Route
      exact
      path={`${SessionInput_URL}/:pageid`}
      component={requireAuth(SessionInput)}
    />
    <Route
      exact
      path={`${SessionInput_URL}/:pageid/:searchterm`}
      component={requireAuth(SessionInput)}
    />

    <Route
      exact
      path={Teams_URL}
      component={requireAuth(TeamsPage)}
    />

    <Route
      exact
      path={ManageTeams_URL}
      component={requireAuth(ManageTeamsPage)}
    />


    {/* admin pages */}
    <Route exact path={AdminHome_URL} component={requireAuth(AdminHome)} />
    <Route
      exact
      path={PodRegistration_URL}
      component={requireAuth(PodRegistration)}
    />
    <Route
      exact
      path={`${PodRegistration_URL}/:originID`}
      component={requireAuth(PodRegistration)}
    />
    <Route exact path={PodList_URL} component={requireAuth(PodList)} />
    {/* end admin pages */}

    <Route
      exact
      path={OrganisationSetup_URL}
      component={requireAuth(OrganisationSetup)}
    />

    <Route exact path={CoachesHome_URL} component={requireAuth(CoachesHome)} />

    <Route exact path={NewUser_URL} component={requireAuth(NewUser)} />
    <Route
      exact
      path={`${NewUser_URL}/:orgid`}
      component={requireAuth(NewUser)}
    />
    <Route
      exact
      path={`${NewUser_URL}/:orgid/:originID`}
      component={requireAuth(NewUser)}
    />

    <Route exact path={UsersHome_URL} component={requireAuth(UsersHome2)} />

    {/* Temp removal of  previous user profile componet*/}
    {/* <Route
      exact
      path={`${UserProfile_URL}/:id`}
      component={requireAuth(UserProfile2)}
    />
    <Route
      exact
      path={`${UserProfile_URL}/:id/:pageid`}
      component={requireAuth(UserProfile2)}
    />
    <Route
      exact
      path={`${UserProfile_URL}/:id/:pageid/:searchterm`}
      component={requireAuth(UserProfile2)}
    /> */}

    <Route
      exact
      path={`${UserProfile_URL}/:id`}
      component={requireAuth(UserProfile)}
    />
    <Route
      exact
      path={`${UserProfile_URL}/:id/:pageid`}
      component={requireAuth(UserProfile)}
    />
    <Route
      exact
      path={`${UserProfile_URL}/:id/:pageid/:searchterm`}
      component={requireAuth(UserProfile)}
    />

    <Route
      exact
      path={OrganisationsList_URL}
      component={requireAuth(OrganisationsList)}
    />
    <Route
      exact
      path={`${OrganisationDetail_URL}/:id`}
      component={requireAuth(OrganisationDetail)}
    />

    <Route exact path={LOGIN_URL} component={requireAuth(Login)} />
    <Route exact path={SIGNUP_URL} component={requireAuth(SignUpPage)} />
    <Route
      exact
      path={`${SIGNUP_URL}/:cc`}
      component={requireAuth(SignUpPage)}
    />
    <Route
      exact
      path={`${RESETPASS_URL}/:cc`}
      component={requireAuth(ResetPasswordPage)}
    />
    <Route
      exact
      path={`${RESETPASS_URL}`}
      component={requireAuth(ResetPasswordPage)}
    />
    <Route component={NotFoundPage} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);

export default AppRouter;
