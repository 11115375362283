import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgID: 0,
};

const selectedOrgForTeamSlice = createSlice({
  name: "selectedOrgForTeam",
  initialState,
  reducers: {
    setSelectedOrgForTeam(state, action) {
      state.orgID =  action.payload;
    }
  },
});


export const {setSelectedOrgForTeam} = selectedOrgForTeamSlice.actions

export const selectedOrgForTeams = (state) => state.selectedOrgForTeam;

export default selectedOrgForTeamSlice.reducer;
