import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAthleteHistoricalLoadChartData,
  getAthleteHistoricalGaitChartOverViewData,
  getAthleteHistoricalGroundContactTimeAsymData,
  getAthleteHistoricalSessionRawData,
} from "../api/session";
import { resetState } from "./resetSlice";


// Create async thunks
export const getAthleteHistoricalGait = createAsyncThunk(
  "athlete/getAthleteHistoricalGait",
  async (athleteID) => {
    const response = await getAthleteHistoricalGaitChartOverViewData(athleteID);
    return response;
  }
);

export const getAthleteHistoricalLoad = createAsyncThunk(
  "athlete/getAthleteHistoricalLoad",
  async (athleteID) => {
    const response = await getAthleteHistoricalLoadChartData(athleteID);
    return response;
  }
);

export const getAthleteHistoricalGroundContact = createAsyncThunk(
  "athlete/getAthleteHistoricalGroundContact",
  async (athleteID) => {
    const response = await getAthleteHistoricalGroundContactTimeAsymData(
      athleteID
    );
    return response;
  }
);


export const getAthleteHistoricalRawSessions = createAsyncThunk(
  "athlete/getAthleteHistoricalSessionRawData",
  async (athleteID) => {
    const response = await getAthleteHistoricalSessionRawData(
      athleteID
    );
    return response;
  }
)

// Initial state
export const initialState = {
  loadedAthleteIds: "",
  athleteHistoricalGait: { data: {}, status: "idle" },
  athleteHistoricalLoad: { data: {}, status: "idle" },
  athleteHistoricalGroundContact: { data: {}, status: "idle" },
  athleteHistoricaRawSessions: { data: {}, status: "idle" },
};

// Create slice
const athleteSlice = createSlice({
  name: "athlete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAthleteHistoricalGait.pending, (state) => {
        state.athleteHistoricalGait.status = "loading";
      })
      .addCase(getAthleteHistoricalGait.fulfilled, (state, action) => {
        state.loadedAthleteIds = action.meta.arg;
        state.athleteHistoricalGait.data = action.payload;
        state.athleteHistoricalGait.status = "succeeded";
      })
      .addCase(getAthleteHistoricalGait.rejected, (state) => {
        state.athleteHistoricalGait.status = "failed";
      })
      //--------------------------------------------------------------------

      .addCase(getAthleteHistoricalLoad.pending, (state) => {
        state.athleteHistoricalLoad.status = "loading";
      })
      .addCase(getAthleteHistoricalLoad.fulfilled, (state, action) => {
        state.loadedAthleteIds = action.meta.arg;
        state.athleteHistoricalLoad.data = action.payload;
        state.athleteHistoricalLoad.status = "succeeded";
      })
      .addCase(getAthleteHistoricalLoad.rejected, (state) => {
        state.athleteHistoricalLoad.status = "failed";
      })
      //--------------------------------------------------------------------

      .addCase(getAthleteHistoricalGroundContact.pending, (state) => {
        state.athleteHistoricalGroundContact.status = "loading";
      })
      .addCase(getAthleteHistoricalGroundContact.fulfilled, (state, action) => {
        state.loadedAthleteIds = action.meta.arg;
        state.athleteHistoricalGroundContact.data = action.payload;
        state.athleteHistoricalGroundContact.status = "succeeded";
      })
      .addCase(getAthleteHistoricalGroundContact.rejected, (state) => {
        state.athleteHistoricalGroundContact.status = "failed";
      })
      //--------------------------------------------------------------------

      .addCase(getAthleteHistoricalRawSessions.pending, (state) => {
        state.athleteHistoricaRawSessions.status = "loading";
      })
      .addCase(getAthleteHistoricalRawSessions.fulfilled, (state, action) => {
        state.loadedAthleteIds = action.meta.arg;
        state.athleteHistoricaRawSessions.data = action.payload;
        state.athleteHistoricaRawSessions.status = "succeeded";
      })
      .addCase(getAthleteHistoricalRawSessions.rejected, (state) => {
        state.athleteHistoricaRawSessions.status = "failed";
      })
      .addCase(resetState, () => {
        return initialState;
      }); 
  },
});

export default athleteSlice.reducer;

// Export selectors
export const selectAthlete = (state) => state.athlete
