/* eslint-disable */
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { getOrganisationTagsAPI } from "api/organisations";
import Button from "../button/button";
import { addTagToSessionAPI } from "api/session";
import { addOrganisationTagAPI } from "api/organisations";
import { removeTagFromSessionAPI } from "api/session";
import Spinner from "../spinner/spinner";
import { getTags } from "store/tagSlice";
import { useDispatch } from "react-redux";

const SessionTaggingModal = (props) => {
  const { button, sessionTags, orgID, sessionID, setTagBeingAdded, user } =
    props;
  const [orgTags, setOrgTags] = useState([]);
  const [isTagCreationOpen, setIsTagCreationOpen] = useState(false);
  const [newTagInfo, setNewTagInfo] = useState({ tagName: "", tagDetails: "" });
  const [addingTag, setAddingTag] = useState(false);
  const [tagSearchValue, setTagSearchValue] = useState("");
  const [filterTagsSearched, setFilterTagsSearched] = useState([]);
  const [sessionTagsSearched, setSessionTagsSearched] = useState([]);


  const dispatch = useDispatch();

  //checks if the session tags are present in the organisation tags and filters it out to avoid duplication
  const filteredTags = orgTags.filter((item) => {
    let checker = false;
    for (let i = 0; i < sessionTags.length; i++) {
      if (sessionTags[i].TID === item.id) {
        checker = true;
      }
    }
    if (!checker) {
      return item;
    }
  });


  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  useEffect(() => {
    setAddingTag(false);
  }, [sessionTags.length, filteredTags.length]);

  useEffect(() => {
    const assignedTags = sessionTags.filter((item) =>
      item.tag
        .split(" ")
        .join("")
        .toLowerCase()
        .includes(tagSearchValue.split(" ").join("").toLowerCase())
    );
    const unassignedTags = filteredTags.filter((item) =>
      item.tag
        .split(" ")
        .join("")
        .toLowerCase()
        .includes(tagSearchValue.split(" ").join("").toLowerCase())
    );

    setSessionTagsSearched(assignedTags);
    setFilterTagsSearched(unassignedTags);
  }, [tagSearchValue]);


  //toggle for tag creation
  const tagCreationOpen = () => {
    setIsTagCreationOpen(!isTagCreationOpen);
  };

  //add tag to session
  const addingTagToSession = async (tag) => {
    const postBody = {
      tid: tag.id,
      sid: sessionID,
    };

    if (postBody.tid && postBody.sid) {
      const addingTag = await addTagToSessionAPI(postBody);
      await setTagBeingAdded(true);
      await setAddingTag(true);
    }
  };

  //remove tag from session
  const removingTagFromSession = async (tag) => {
    if (tag.STID) {
      await removeTagFromSessionAPI(tag.STID);
      await setTagBeingAdded(true);
      await setAddingTag(true);
    }
  };

  const createNewTag = async () => {
    const postBody = {
      tag: newTagInfo.tagName,
      detail: newTagInfo.tagDetails,
      orgid: orgID,
      createdByUserID: user,
    };

    if (newTagInfo.tagName.length > 0) {
      const data = await addOrganisationTagAPI(postBody);
      await getOrgTags();
      await dispatch(getTags());
      await setTagBeingAdded(true);
    }
  };

  const getOrgTags = () => {
    getOrganisationTagsAPI(orgID).then((tags) => {
      if (tags) {
        setOrgTags(tags.data);
      } else {
       console.log("Error getting Tags");
      }
    });
  };

  const close = () => {
    // setModal(prevModal => !prevModal);
    document.body.classList.remove("modal-open");
    setOrgTags([]);
  };

  return (
    <Dialog.Root
      onOpenChange={(isOpen) => {
        getOrgTags();
        if (!isOpen) {
        }
      }}
    >
      <Dialog.Trigger asChild>
        <span>{button}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="TaggingModal">
          <div className="tag-modal-container">
            {addingTag && (
              <div className="tag-being-added-loader">
                <div className="loader">
                  <Spinner />
                </div>
              </div>
            )}
            <div
              className={`existing-tags-modal  ${
                isTagCreationOpen ? "existing-tags-modal-open" : ""
              }`}
            >
              <div className="tag-modal-head">
                <div className="tag-modal-head-heading_close">
                  <h4>SESSION TAGS</h4>
                  <Dialog.Close onClick={() => close} asChild>
                    <button className="closeIcon" aria-label="Close">
                      <Cross2Icon />
                    </button>
                  </Dialog.Close>
                </div>
                <input
                  placeholder="Search..."
                  type="search"
                  name="tag-search"
                  id="tag-search"
                  onChange={(e) => setTagSearchValue(e.target.value)}
                />
              </div>
              {!isTagCreationOpen && (
                <div className="tag-modal-active">
                  <h4>ACTIVE TAGS</h4>
                  <div className="active-tags">
                    {sessionTags && !sessionTags.length ? (
                      <p>Add Tags from the section below or create a new tag</p>
                    ) : sessionTagsSearched.length > 0 ? (
                      sessionTagsSearched?.map((tag) => (
                        <div className="tag" key={tag.id}>
                          <p>{tag.tag}</p>
                          <div
                            className="remove-tag-button"
                            onClick={() => {
                              removingTagFromSession(tag);
                            }}
                          >
                            <Cross2Icon />
                          </div>
                        </div>
                      ))
                    ) : (
                      sessionTags?.map((tag) => (
                        <div className="tag" key={tag.id}>
                          <p>{tag.tag}</p>
                          <div
                            className="remove-tag-button"
                            onClick={() => {
                              removingTagFromSession(tag);
                            }}
                          >
                            <Cross2Icon />
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              )}
              <div className={`tag-modal-tags`}>
                <h4>ADD TAGS</h4>
                <div className="tag-list">
                  {filterTagsSearched.length > 0
                    ? filterTagsSearched.map((tag) => (
                        <div className="tag" key={tag.id}>
                          <p>{tag.tag}</p>
                          <div
                            className="add-tag-button"
                            onClick={() => {
                              addingTagToSession(tag);
                            }}
                          >
                            <Cross2Icon />
                          </div>
                        </div>
                      ))
                    : filteredTags.map((tag) => (
                        <div className="tag" key={tag.id}>
                          <p>{tag.tag}</p>
                          <div
                            className="add-tag-button"
                            onClick={() => {
                              addingTagToSession(tag);
                            }}
                          >
                            <Cross2Icon />
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>

            <div
              className={`tag-modal-adding-tags  ${
                isTagCreationOpen ? "adding-tags-open" : ""
              }`}
            >
              <div className={`modal-adding-tags`}>
                <h3>Create new Tag</h3>
                <input
                  type="text"
                  placeholder="Tag Name"
                  value={newTagInfo.tagName}
                  onChange={(e) => {
                    setNewTagInfo((prev) => ({
                      ...prev,
                      tagName: e.target.value,
                    }));
                  }}
                ></input>
                <textarea
                  placeholder="Tag Description"
                  value={newTagInfo.tagDetails}
                  onChange={(e) => {
                    setNewTagInfo((prev) => ({
                      ...prev,
                      tagDetails: e.target.value,
                    }));
                  }}
                ></textarea>
              </div>
              <div className="modal-buttons">
                <div
                  className="add-tag-button"
                  onClick={() => {
                    tagCreationOpen();
                    if (isTagCreationOpen) {
                      createNewTag();
                    }
                  }}
                >
                  <Button
                    value={isTagCreationOpen ? "Create Tag" : "New Tag"}
                  />
                </div>
                {isTagCreationOpen && (
                  <div
                    className="cancel-tag-button"
                    onClick={() => {
                      tagCreationOpen();
                    }}
                  >
                    <Button value={"Cancel Creation"} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SessionTaggingModal;
