import React  from 'react';
import '../testSlide/testSlide.css';

const TestSlide = ({ children, direction = 'left', duration = 500, distance = '100%',modalIn=false, className = "" , id = ""}) => {


  const transitionStyles = {
    entering: { transform: `translateX(${direction === 'right' ? '' : '-'}${distance})` },
    entered: { transform: 'translateX(0%)' },
    exiting: { transform: `translateX(${direction === 'right' ? '-' : ''}${distance})` },
    exited: { transform: `translateX(${direction === 'right' ? '-' : ''}${distance})` },
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '50',
          left: '0',
          bottom: '0',
          right: '0',
          zIndex:9999,
          ...transitionStyles[modalIn ? 'entered' : 'exited'],
          transition: `transform ${duration}ms ease-in-out`,
        }}

        className = {className}
        id = {id}
      >
        {children}
      </div>
    </>
  );
};

export default React.memo(TestSlide);
