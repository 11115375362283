import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
//import PropTypes from 'prop-types';
import logo from "../assets/images/DanuTitle.png";
import { RESETPASS_URL, SIGNUP_URL } from "../utils/urls";
import LoginInput from "./LoginInput";
import Button from "./common/button/button";
import { login, clearAuth } from "../store/authSlice";
import Spinner from "./common/spinner/spinner";

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      identifier: "",
      password: "",
      errors: {},
    };

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
    this.regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  }

  goToSignIn() {
    this.props.actions.clearAuth();
    this.props.history.push(SIGNUP_URL);
  }

  goToResetPassword() {
    this.props.actions.clearAuth();
    this.props.history.push(RESETPASS_URL);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  login(e) {
    e.preventDefault();

    if (this.validateUserInputs()) {
      this.props.actions.login(this.state);
    }
  }

  isValidEmail(email) {
    return this.regex.test(email);
  }

  validateUserInputs() {
    const errors = {};
    errors.formErrors = false;

    if (!this.state.identifier || this.state.identifier.length === 0) {
      errors.identifier = "You must enter an email";
      errors.formErrors = true;
    } else if (!this.isValidEmail(this.state.identifier)) {
      errors.identifier = "You must enter a valid email";
      errors.formErrors = true;
    } else if (!this.state.password || this.state.password.length === 0) {
      errors.password = "You must enter a password";
      errors.formErrors = true;
    }
    this.setState({ errors });
    return !errors.formErrors;
  }

  render() {
    // deconstruct variables from state
    const { identifier, password } = this.state;

    return (
      <div className="login-container">
        <div className="logo-container">
          <img
            src={logo}
            className="rightLogo"
            alt="Recently updated indicator"
          />
        </div>

        <form
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          onSubmit={this.login}
        >
          <LoginInput
            title={"Email"}
            id={"identifier"}
            onChange={this.onChange}
            autoComplete={"username"}
            value={identifier}
            type="text"
            placeholder={"Email"}
          />
          <LoginInput
            title={"Password"}
            id={"password"}
            onChange={this.onChange}
            autoComplete={"current-password"}
            value={password}
            type="password"
            placeholder={"Password"}
          />

          {this.props.authenticationError && (
            <div className="login-error">Invalid Details</div>
          )}
          {this.state.errors && (
            <div className="login-error">{this.state.errors.identifier}</div>
          )}

          {!(
            this.props.authenticationError && this.props.authenticationError
          ) &&
            this.props.user &&
            this.props.user.status > 400 &&
            this.props.user.status < 500 && (
              <div className="login-error">Invalid Details</div>
            )}

          <div className="login-button-container">
            <Button
              type="submit"
              value={this.props.loading ? <Spinner /> : "LOGIN"}
            />
          </div>
        </form>

        <div className="login-extra">
          <div
            className="login-extra-link"
            onClick={() => {
              this.goToSignIn();
            }}
          >
            Create an account
          </div>
          {/* <Link className="login-extra-link"  to={SIGNUP_URL}>Create an account</Link> */}
          <div className="login-divider">|</div>
          <div
            className="login-extra-link"
            onClick={() => {
              this.goToResetPassword();
            }}
          >
            Forgotten your password?
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated, user, authenticationError, loading } = state.auth;
  return { isAuthenticated, user, authenticationError, loading };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ clearAuth, login }, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
