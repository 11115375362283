/* eslint-disable */
import * as TabsPrimitive from "@radix-ui/react-tabs";
import React, { useEffect, useState } from "react";
import AthleteGraphHeader from "../table/athleteHeader/athleteHeader";
import { motion } from "framer-motion";
import "./mytabs.scss";

const MyTabs = ({
  setTabs = null,
  tabs,
  headerClass,
  query,
  showForm,
  open,
  setValue,
  header = false,
  setModalIn,
  athleteLoadDate = false,
}) => {
  const [defaultValue, setDefaultValue] = useState(tabs[0].value);
  const [[page, direction], setPage] = useState([0, 0]);

  
  useEffect(()=> {
    if(athleteLoadDate){
      setPage([1,1])
    }
    
  },[athleteLoadDate])


    //setting content of the
    const setters = (title) => {
      setTabs(title.toLowerCase());
    };
    

  const handleTabChange = (value) => {
    setDefaultValue(value.toLowerCase());
  };

  return (
    <TabsPrimitive.Root className="container-tabs" defaultValue={defaultValue}>
      <TabsPrimitive.List className={`tab-header ${headerClass}`}>
        <div style={{ display: "inherit" }}>
          {tabs.map(({ title, value, length }, index) => {
            const isActive = index === page;

            return (
              <TabsPrimitive.Trigger
                key={value}
                value={value}
                onMouseDown={()=> {
                  if (setTabs) {
                    setters(title);
                  }
                  setPage([index, index - page]);
                  handleTabChange(value);
                }}
           
                className={`tabs-trigger`}
                id={value}
              >
                <span className="tabs-span-title">
                  {title} {length >= 1 ? `(${length})` : ""}
                </span>
                {isActive && (
                  <motion.div className="underline" layoutId="underline" />
                )}
              </TabsPrimitive.Trigger>
            );
          })}
        </div>
        {header ? (
          <AthleteGraphHeader
            value={query}
            onClickHandler={showForm}
            open={open}
            onChangeHandler={(e) => setValue(e.target.value)}
            setModalIn={setModalIn}
          />
        ) : null}
      </TabsPrimitive.List>

      {tabs.length > page && (
        <TabsPrimitive.Content
          value={defaultValue}
          className="content-container-tabs"
        >
          {tabs[page].display}
        </TabsPrimitive.Content>
      )}
    </TabsPrimitive.Root>
  );
};

export default React.memo(MyTabs);
