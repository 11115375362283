import EscapeIcon from 'components/nav-components/nav-icons/escape-icon';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { selectOrganisation, setPlayerDetails, getOrganisationsToolkit } from 'store/orgSlice';
import '../../styles/player_updated.scss'
import { postFile } from '../../api/apiHelper';
import Button from 'components/common/button/button';
import { selectUser } from 'store/authSlice';


class PlayerForm extends React.Component {

      constructor(props, context) {
            super(props, context);
            this.state = {
                  playerID: -1,
                  selectedOrg: undefined,
                  file: undefined,
                  userName: '',
                  emailOne: '',
                  emailTwo: '',
                  errors: {},
                  gender: '',
                  age: 0,
                  playerCreated: false,
                  newPlayerID: 0,
                  updateSuccess: false,
                  alertIsOpen: false,
                  height: 0, weight: 0, shoesize: 0, footlength: 0, footwidth: 0, pod: 0
            };

            this.regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            this.onChange = this.onChange.bind(this);
      }

      fileInputRef = React.createRef();



      componentDidMount() {
            this.setState({ selectedOrg: parseInt(this.props.match.params.orgid) || undefined })


            let player = this.getThisPlayer()
            if (player) {
                  this.setState({
                        playerID: player.playerID,
                        selectedOrg: player.oid,
                        file: undefined,
                        iconUrl: player.playerIconURL,
                        userName: player.player,
                        emailOne: player.email,
                        emailTwo: player.email,
                        errors: {},
                        gender: player.gender ? player.gender : undefined,
                        age: player.age > 0 ? player.age : undefined,
                        playerCreated: false,
                        newPlayerID: 0,
                        height: player.height > 0 ? player.height : undefined
                        , weight: player.weight > 0 ? player.weight : undefined
                        , shoesize: player.shoeSize > 0 ? player.shoeSize : undefined
                        , footlength: player.footLength > 0 ? player.footLength : undefined,
                        footwidth: player.footWidth > 0 ? player.footWidth : undefined
                        , pod: player.pod > 0 ? player.pod : undefined
                  })
            }
      }

      getThisPlayer() {
            const { organisation } = this.props;

            if (organisation && organisation.orgList && organisation.orgList.length > 0) {
                  //we have an org list
                  for (var i = 0; i < organisation.orgList.length; i++) {
                        var thisOrg = organisation.orgList[i];

                        for (var p = 0; p < thisOrg.players.length; p++) {
                              var thisPlayer = thisOrg.players[p];
                              if (thisPlayer.playerID === parseInt(this.props.match.params.id)) {
                                    return thisPlayer
                              }
                        }
                  }
            }
            return undefined
      }

      reset() {
            this.setState({
                  playerID: -1,
                  selectedOrg: undefined,
                  file: undefined,
                  userName: '',
                  emailOne: '',
                  emailTwo: '',
                  errors: {},
                  gender: '',
                  updateSuccess: false,
                  age: undefined,
                  playerCreated: false,
                  newPlayerID: 0,
                  height: undefined, weight: undefined, shoesize: undefined, footlength: undefined, footwidth: undefined, pod: undefined
            })
      }

      handleFileChange(event) {
            if (event.target.files.length > 0) {
                  this.setState({ file: event.target.files[0] })

                  var image = document.getElementById('tempOrgIcon');
                  image.src = URL.createObjectURL(event.target.files[0]);
            }
      }

      triggerFileInput = event => {
            event.preventDefault()
            this.fileInputRef.current.click();
      }

      onChange(e) {
            this.setState({ [e.target.name]: e.target.value });
      }

      handleUserSelect(value) {
            this.setState({ selectedOrg: value });
      }


      validateInputs() {
            const errors = {};
            errors.formErrors = false;

            if (
                  ((!this.state.userName) || (this.state.userName.length === 0))
            ) {
                  errors.error = 'You must enter the athletes name';
                  errors.formErrors = true;
            }
            else if ((this.state.emailOne !== this.state.emailTwo)) {
                  errors.error = 'Emails do not match';
                  errors.formErrors = true;
            }
            else if (!this.state.selectedOrg) {
                  errors.error = 'You must select an organisation';
                  errors.formErrors = true;
            }

            this.setState({ errors });
            return !errors.formErrors;
      }

     async addPlayer(e) {
            e.preventDefault();

            if (this.validateInputs()) {
                  const { playerID, iconUrl, userName, emailOne, selectedOrg, gender, age, height, weight, shoesize, footlength, footwidth, pod } = this.state;
                  const { user, actions } = this.props;


                  let player = {
                        name: userName,
                        gender,
                        email: emailOne,
                        age: parseInt(age) || 0, height: parseInt(height) || 0, weight: parseInt(weight) || 0, shoeSize: parseInt(shoesize) || 0,
                        footLength: parseInt(footlength) || 0, footWidth: parseInt(footwidth) || 0, pod: parseInt(pod) || 0,
                        orgID: parseInt(selectedOrg) || 0
                  }

                  if (playerID > 0) {
                        //this is an edit not a create!!
                        player.id = playerID
                        player.iconUrl = iconUrl || ''
                  }
                  const payload = { newPlayer: player, userid: user.id };

                  this.props.actions.setPlayerDetails(payload).then(res => {
                        const { file } = this.state;
                        if (res.payload.id > 0) {
                              if (playerID > 0) {
                                    //edit
                                    this.setState({ updateSuccess: true, alertIsOpen: true });
                              }

                              if (file) {
                                    this.addIcon(res.payload.id)
                              }
                              else if (!(playerID > 0)) {
                                    //create player  - so redirect to player list
                                    actions.getOrganisationsToolkit(user.id)
                                    this.setState({newPlayerID:res.payload.id,playerCreated:true})
                              }
                        }
                        else if (res.error) {
                              console.log(res.error);
                              const errors = {};
                              errors.formErrors = true;
                              errors.error = 'Error updating details'
                              this.setState({ errors });
                        }


                  })
                        .catch((error) => {
                              console.log(error);
                              const errors = {};
                              errors.formErrors = true;
                              errors.error = 'Error updating details'
                              this.setState({ errors });
                              this.setState({ playerCreated: true, newPlayerID: -1 });
                        });

            }
      }

      redirectToPlayerList() {
            this.props.history.push('/players_home');
      }

      addIcon(thisPlayerID) {
            const { user, actions } = this.props;
            const { file, playerID } = this.state;

            let url = `player/addImage/${thisPlayerID}`

            postFile(url, file).then(res => {
                  if (res.data.result > 0) {
                        //success
                        actions.getOrganisationsToolkit(user.id)

                        if (!(playerID > 0)) {
                              //this is a create player  - so do redirect!
                              this.redirectToPlayerList();
                        }
                  } else {
                        //failure
                  }
            })
      }

      displayCreatedScreen() {
            const { newPlayerID } = this.state;

            return (
                  <div style={{ width: '100%', height: '50%' }} className="center-content">
                        <div className="display_text">
                              {newPlayerID > 0 &&
                                    <div style={{ width: 600, textAlign: 'center', color:'white' }}>
                                          <p>New Athlete Created</p>
                                    </div>
                              }
                              {newPlayerID < 0 && <p>Error Creating New Athlete</p>}
                        </div>
                        <div>
                              <div className="large-button-parent" onClick={(e) => { this.reset(e) }}>
                                    <div className="large-button">
                                          Create Another Athlete
                                    </div>
                              </div>

                              {parseInt(this.props.match.params.originID) > 0 && <div className="large-button-parent" onClick={() => { this.props.history.goBack(); }}>
                                    <div className="large-button">
                                          Back To Organisation
                                    </div>
                              </div>}

                        </div>
                  </div>
            )
      }

      handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                  return;
            }

            this.setState({ alertIsOpen: false })
      };

      render() {
            const { iconUrl, playerID, playerCreated, file, userName, errors, emailOne, emailTwo, selectedOrg, gender, age, height, weight, shoesize,
                  footlength, footwidth, pod } = this.state;
            const { organisation, edit, fromAthletesSection } = this.props;

            if (playerCreated) {
                  return this.displayCreatedScreen()
            }

            let iconStyle = {
                  display: 'none',
                  width: 120,
                  height: 120,
                  borderRadius: '50%'
            }

            if (file) {
                  iconStyle.display = 'block'
            }

            let leftWidthExtra = {}
            let editCenter = "editCenter"
            let titleStyle = {
                  marginTop: 64
            }
            if (!edit) {
                  leftWidthExtra.width = 100
                  titleStyle.fontSize = 25
                  titleStyle.marginBottom = 30
                  editCenter = ""
            }

            let showRight = true
            if ((!edit) || fromAthletesSection) {
                  showRight = false
            }


            return (
                  <div style={{ width: '100%', height: '100%' }} className={`setup_player player_form right-background ${showRight ? "athletes-form-box " : ""}`}>
                        <div className={`${showRight ? "setup_player_padding" : ""}`}>
                              <div className="left" style={leftWidthExtra}>
                                    <div className='left-user-profile'>
                                          {edit && <div className="user-placeholder-parent">
                                                {!file && !iconUrl &&
                                                      <div className="user-placeholder" style={{ borderRadius: "50%", border: "1px solid #979797" }}>
                                                      </div>}

                                                {iconUrl && !file &&
                                                      <div className="default-img-container" style={{ width: 124 }}>
                                                            <img style={{ borderRadius: "50%" }} alt='icon' src={iconUrl} />
                                                      </div>
                                                }

                                                <img id="tempOrgIcon" alt='icon temp org' style={iconStyle} />
                                          </div>}

                                          {/* {!existingOrg &&  */}
                                          {edit &&
                                                <div className="file-select-parent">
                                                      <div onClick={this.triggerFileInput}>
                                                            <Button value={"Choose File"} />
                                                      </div>

                                                      <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={this.handleFileChange.bind(this)}
                                                            style={{
                                                                  opacity: '0',
                                                                  filter: 'alpha(opacity=0)',
                                                                  display: 'none'
                                                            }}
                                                            ref={this.fileInputRef}
                                                            />
                                                </div>}
                                    </div>

                                    {edit && playerID >= 0 &&
                                          <div className="right" >
                                                {
                                                      <div className="right-div-back-link" onClick={() => { this.props.hideForm() }}>
                                                            <EscapeIcon />
                                                      </div>
                                                }
                                          </div>
                                    }

                                    {showRight && playerID <= 0 &&
                                          <div className="right" >
                                                {
                                                      <div className="right-div-back-link" onClick={() => { this.props.history.goBack(); }}>
                                                            <EscapeIcon />
                                                      </div>
                                                }
                                          </div>
                                    }

                              </div>
                              <div className={"center " + editCenter}>

                                    {!edit && emailOne && <div className="profile-header">Email: <span style={{ marginLeft: 30 }}>{emailOne}</span> </div>}

                                    {edit && <div className='profile-form-info'>
                                          <div className="input_parent input_border">
                                                <input type="text" autoComplete="off" id="userName" name="userName" onChange={this.onChange} value={userName} placeholder="Name" />
                                          </div>

                                          <div className="input_parent input_border" style={{ marginTop: 30, marginBottom: 20 }}>
                                                <input type="email" autoComplete="off" id="emailOne" name="emailOne" onChange={this.onChange} value={emailOne} placeholder="Email (optional)" />
                                          </div>

                                          <div className="input_parent input_border" style={{ marginTop: 30, marginBottom: 20 }}>
                                                <input type="email" autoComplete="off" id="emailTwo" name="emailTwo" onChange={this.onChange} value={emailTwo} placeholder="Confirm Email (optional)" />
                                          </div>

                                          {organisation.orgList && <div className="input_parent input_border" style={{ marginTop: 30, marginBottom: 30 }}>
                                                <select id="org_select" onChange={(e) => this.handleUserSelect(e.target.value)}>
                                                      <option disabled hidden defaultValue={!selectedOrg} key={"select_-1"} value={-1}>Select Organisation</option>

                                                      {organisation.orgList.map((org) => {
                                                            return <option defaultValue={org.OrgID === selectedOrg} key={"select_" + org.OrgID} value={org.OrgID}>{org.Name}</option>
                                                      })}
                                                </select>
                                          </div>}
                                    </div>}


                                    {
                                          <div className='profile-form-data'>

                                                <div className="profile-row" >
                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  Gender
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type="text" disabled={!edit} autoComplete="off" id="gender" name="gender" onChange={this.onChange} value={gender} placeholder="M/F" />
                                                            </div>
                                                      </div>
                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  Age
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type={edit ? "number" : "text"} disabled={!edit} autoComplete="off" id="age" name="age" onChange={this.onChange} value={age} placeholder="0-100" />
                                                            </div>

                                                      </div>

                                                </div>

                                                <div className="profile-row" >

                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  Height
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type={edit ? "number" : "text"} disabled={!edit} autoComplete="off" id="height" name="height" onChange={this.onChange} value={height} placeholder="CM" />
                                                            </div>

                                                      </div>
                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  Weight
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type={edit ? "number" : "text"} disabled={!edit} autoComplete="off" id="weight" name="weight" onChange={this.onChange} value={weight} placeholder="KG" />
                                                            </div>

                                                      </div>
                                                </div>

                                                <div className="profile-row" >
                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  Shoe Size
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type={edit ? "number" : "text"} disabled={!edit} autoComplete="off" id="shoesize" name="shoesize" onChange={this.onChange} value={shoesize} placeholder="UK" />
                                                            </div>
                                                      </div>
                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  Foot Length
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type={edit ? "number" : "text"} disabled={!edit} autoComplete="off" id="footlength" name="footlength" onChange={this.onChange} value={footlength} placeholder="CM" />
                                                            </div>
                                                      </div>

                                                </div>
                                                <div className="profile-row" >
                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  Foot Width
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type={edit ? "number" : "text"} disabled={!edit} autoComplete="off" id="footwidth" name="footwidth" onChange={this.onChange} value={footwidth} placeholder="CM" />
                                                            </div>
                                                      </div>
                                                      <div className="profile-col" >
                                                            <div className="profile-col-headings" >
                                                                  POD to inferior malleolus
                                                            </div>
                                                            <div className="input_parent input_border" >
                                                                  <input type={edit ? "number" : "text"} disabled={!edit} autoComplete="off" id="pod" name="pod" onChange={this.onChange} value={pod} placeholder="CM" />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>}

                                    {edit && <div className="small_org_button_parent">
                                          <div className="small_org_button" style={{ width: 250 }} onClick={(e) => { this.addPlayer(e) }}>
                                                <Button value={"SAVE"} />
                                          </div>
                                    </div>}

                                    {(errors && errors.error) &&
                                          <div className="error-warning">
                                                *  {errors.error}
                                          </div>
                                    }

                              </div>
                        </div>


                  </div>
            );
      }
}




function mapStateToProps(state) {
      return {
            user: selectUser(state),
            organisation: selectOrganisation(state),
      };
}

function mapDispatchToProps(dispatch) {
      return {
            actions: bindActionCreators({ setPlayerDetails, getOrganisationsToolkit }, dispatch),
      };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerForm));