
import InfoIcon from '../icons/infoIcon/infoIcon';
import './toolTips.scss';



const Tooltip = ({ text, fillColour = "white", size }) => {
  return (
    <div className="tooltip">
      <InfoIcon 
        fillColor={fillColour}
        size={size}
      />
      <div className="tooltipText">
      <p>{text}</p>
    </div>
   
    </div>
  );
};

export default Tooltip;
