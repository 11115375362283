/* eslint-disable */
import {
  ComposedChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Legend,
  ReferenceLine,
  LabelList,
  Tooltip,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_MID_COLOR,
  NEW_BOTH_FEET_COLOR,
  NEW_BOTH_FEET_JUMP_COLOR,
} from "utils/constant";
import MaxMinMean from "components/metrics/metric-helpers/MaxMinMean";
import Toggler from "components/common/toggler/Toggler";
import Plot from "react-plotly.js";
import { useState } from "react";

export const getTooltipStyle = (payloadItem) => {
  if (payloadItem.name && payloadItem.name.toLowerCase().indexOf("left") > -1) {
    return "left";
  }
  if (
    payloadItem.name &&
    payloadItem.name.toLowerCase().indexOf("right") > -1
  ) {
    return "right";
  }
  return "both-feet";
};
export default function ComparisonBarChart(props) {
  const {
    metricName,
    data,
    sessionsData = [],
    comparisonType,
    yAxisLabel = "Time (s)",
    jumpType = "none",
    graphType,
  } = props;

  console.log(props);

  //toggler state
  const [graphToggler, setGraphToggler] = useState(false);
  const [showOutliers, setShowOutliers] = useState(false);

  const averageMaxData = calculateAverage(data, "maxValue");
  const averageAverageData = calculateAverage(data, "averageValue");
  const averageLeftMaxData = calculateAverage(data, "maxLeftValue");
  const averageLeftAverageData = calculateAverage(data, "averageLeftValue");
  const averageRightMaxData = calculateAverage(data, "maxRightValue");
  const averageRightAverageData = calculateAverage(data, "averageRightValue");

  const dataFormatted = data.map((item) => ({
    ...item,
    averageMaxData: averageMaxData,
    averageAverageData: averageAverageData,
    averageLeftMaxData: averageLeftMaxData,
    averageLeftAverageData: averageLeftAverageData,
    averageRightMaxData: averageRightMaxData,
    averageRightAverageData: averageRightAverageData,
  }));

  const changeToggled = (e) => {
    console.log(e);
  };

  // box plot data handling
  const leftFootSessionData = sessionsData
    ?.map((item) => item?.data)
    .flat()
    .map((item) => (item?.["Left Foot"] ? item?.["Left Foot"] : null));
  const rightFootSessionData = sessionsData
    ?.map((item) => item?.data)
    .flat()
    .map((item) => (item?.["Right Foot"] ? item?.["Right Foot"] : null));
  const xAxisData = sessionsData?.map((item) =>
    item.data?.map((sessionID) => String(item.sessionID))
  );
  const bothFootSessionData = sessionsData
    ?.map((item) => item?.data)
    .flat()
    .map((item) => (item?.["Both Feet"] ? item?.["Both Feet"] : null));

  const sortedData = sessionsData?.filter((item) => item.data?.length);
  const sessionIDData = sortedData.map((item) => item.sessionID);

  const sortedLeftData = sortedData
    .map((session) =>
      session.data.map((item) =>
        item?.["Left Foot"] ? item?.["Left Foot"] : null
      )
    )
    .map((item) => item.filter((value) => value !== null));
  const sortedRightData = sortedData
    .map((session) =>
      session.data.map((item) =>
        item?.["Right Foot"] ? item?.["Right Foot"] : null
      )
    )
    .map((item) => item.filter((value) => value !== null));
  const sortedBothData = sortedData
    .map((session) =>
      session.data.map((item) =>
        item?.["Both Feet"] ? item?.["Both Feet"] : null
      )
    )
    .map((item) => item.filter((value) => value !== null));

  //mins
  const sortedLeftDataMin = sortedLeftData?.map((item) =>
    item.length ? Math.min(...item) : null
  );
  const sortedRightDataMin = sortedRightData?.map((item) =>
    item.length ? Math.min(...item) : null
  );

  const sortedBothDataMin = sortedBothData?.map((item) =>
    item.length ? Math.min(...item) : null
  );

  //max
  const sortedLeftDataMax = sortedLeftData?.map((item) =>
    item.length ? Math.max(...item) : null
  );
  const sortedRightDataMax = sortedRightData?.map((item) =>
    item.length ? Math.max(...item) : null
  );

  const sortedBothDataMax = sortedBothData?.map((item) =>
    item.length ? Math.max(...item) : null
  );

  //avg
  const sortedLeftDataAvg = sortedLeftData?.map((item) =>
    Number((item?.reduce((a, b) => a + b, 0, 0) / item?.length).toFixed(3))
  );
  const sortedRightDataAvg = sortedLeftData?.map((item) =>
    Number((item?.reduce((a, b) => a + b, 0, 0) / item?.length).toFixed(3))
  );
  const sortedBothDataAvg = sortedBothData?.map((item) =>
    Number((item?.reduce((a, b) => a + b, 0, 0) / item?.length).toFixed(3))
  );

  console.log("sortedRightDataFlat : ", sortedRightData.flat());

  const getArrayOfDifferences = (arr1, arr2) => {
    //arr1 is max
    //arr2 is min
    let newArr = [];
    for (let i = 0; i < arr1.length; i++) {
      newArr.push(arr1[i] - arr2[i]);
    }

    return newArr;
  };

  console.log(sessionsData);
  console.log(sortedData);
  console.log(sessionIDData);
  console.log("sortedLeftData", sortedLeftData);
  console.log("sortedRightData", sortedRightData);
  console.log("sortedLeftDataMin", sortedLeftDataMin);
  console.log("sortedBothDataMin", sortedBothDataMin);
  console.log("sortedRightDataMin", sortedRightDataMin);
  console.log("sortedLeftDataMax", sortedLeftDataMax);
  console.log("sortedBothDataMax", sortedBothDataMax);
  console.log("sortedRightDataMax", sortedRightDataMax);
  console.log("sortedLeftDataAvg", sortedLeftDataAvg);
  console.log("sortedRightDataAvg", sortedRightDataAvg);
  console.log("sortedBothDataAvg", sortedBothDataAvg);

  //box plots
  const traceLeft = {
    y: leftFootSessionData,
    x: xAxisData.flat(1),
    name: "Left Foot",
    marker: { color: NEW_LEFT_COLOR },
    type: "box",
    jitter: 0.3,
    boxpoints: !showOutliers,
    hoverinfo: "none",
  };

  const traceRight = {
    y: rightFootSessionData,
    x: xAxisData.flat(1),
    name: "Right Foot",
    marker: { color: NEW_RIGHT_COLOR },
    type: "box",
    jitter: 0.3,
    boxpoints: !showOutliers,
    hoverinfo: "none",
  };

  const traceBoth = {
    y: bothFootSessionData,
    x: xAxisData.flat(1),
    name: "Both Feet",
    marker: { color: NEW_BOTH_FEET_COLOR },
    type: "box",
    jitter: 0.3,
    boxpoints: !showOutliers,
    hoverinfo: "none",
  };

  const traceLeftBar = {
    y: getArrayOfDifferences(sortedLeftDataMax, sortedLeftDataMin),
    x: sessionIDData.flat(1),
    base: sortedLeftDataMin, /// max value - min value of array
    text: sortedLeftDataAvg,
    type: "bar",
    name: "Left Foot",
    marker: { color: NEW_LEFT_COLOR },
    opacity: 0,
    hoverinfo: "y", // was working on tooltip here --------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    hovertemplate: `
  <b>${metricName}</b><br>
  Max: %{y}<br>
  Avg: %{text}<br>
  Min: %{base}<br>
  ${yAxisLabel}
  <extra></extra>
  `,
    showlegend: false,
  };

  const traceRightBar = {
    y: getArrayOfDifferences(sortedRightDataMax, sortedRightDataMin),
    x: sessionIDData.flat(1),
    base: sortedRightDataMin, /// max value - min value of array
    text: sortedRightDataAvg,
    type: "bar",
    name: "Left Foot",
    marker: { color: NEW_RIGHT_COLOR },
    opacity: 0,
    hoverinfo: "y", // was working on tooltip here --------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    hovertemplate: `
  <b>${metricName}</b><br>
  Max: %{y}<br>
  Avg: %{text}<br>
  Min: %{base}<br>
  ${yAxisLabel}
  <extra></extra>
  `,
    showlegend: false,
  };

  const traceBothBar = {
    y: getArrayOfDifferences(sortedBothDataMax, sortedBothDataMin),
    x: sessionIDData.flat(1),
    base: sortedBothDataMin, /// max value - min value of array
    text: sortedBothDataAvg,
    type: "bar",
    name: "Both Feet",
    marker: { color: NEW_BOTH_FEET_COLOR },
    opacity: 0,
    hoverinfo: "y", // was working on tooltip here --------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    hovertemplate: `
  <b>${metricName}</b><br>
  Max: %{y}<br>
  Avg: %{text}<br>
  Min: %{base}<br>
  ${yAxisLabel}
  <extra></extra>
  `,
    showlegend: false,
  };

  console.log("traceBoth :", traceBoth);
  console.log("traceBothBar: ", traceBothBar);

  const layout = {
    yaxis: {
      title: yAxisLabel,
      zeroline: false,
      color: NEW_AXIS_TICK,
      gridcolor: NEW_AXIS_COLOR,
    },
    legend: {
      font: {
        color: 'white' // Change the color of the legend text
      }
    },
    xaxis: { type: "category", title: "SessionID", color: "white" },
    boxmode: "group",
    barmode: "group",
    paper_bgcolor: "transparent",
    plot_bgcolor: "transparent",
    showlegend: true,
    dragmode: false,
    responsive: true,
    autosize: true,
  };

  const config = {
    displayModeBar: false,
  };

  if (data.length > 0)
    return (
      <div className="chart-table-container">
        <div className="chart-heading">
          {metricName}
          {graphType ? (
            <Toggler
              isToggled={graphToggler}
              setIsToggled={setGraphToggler}
              changeToggleDisplayed={changeToggled}
              togglerHeadings={{
                left: "Bar Chart",
                right: "Radar Chart",
              }}
            />
          ) : (
            ""
          )}
        </div>
        {
          !graphType && sessionsData.length ? (
            /*Start Box Plot chart Container --------------------------------------------------------------------*/
            <div className="box-plot-container">
              <div>
                {jumpType === "none" && (
                  <MaxMinMean
                    leftFoot={{
                      hasData: sortedLeftData.flat(),
                      min: Math.min(...sortedLeftData.flat()),
                      max: Math.max(...sortedLeftData.flat()),
                      mean:
                        sortedLeftData.flat().reduce((a, b) => a + b, 0) /
                        sortedLeftData.flat().length,
                    }}
                    rightFoot={{
                      hasData: sortedRightData.flat(),
                      min: Math.min(...sortedRightData.flat()),
                      max: Math.max(...sortedRightData.flat()),
                      mean:
                        sortedRightData.flat().reduce((a, b) => a + b, 0) /
                        sortedRightData.flat().length,
                    }}
                  />
                )}
                {jumpType === "double" && (
                  <MaxMinMean
                    footSymmetry={{
                      hasData: data,
                      min: Math.min(...data.map((item) => item.averageValue)),
                      max: Math.max(...data.map((item) => item.averageValue)),
                      mean:
                        data
                          .map((item) => item.averageValue)
                          .reduce((a, b) => a + b, 0) / data.length,
                    }}
                  />
                )}
                <Toggler
                  isToggled={showOutliers}
                  setIsToggled={setShowOutliers}
                  changeToggleDisplayed={changeToggled}
                  togglerHeadings={{
                    left: "Show Points",
                    right: "Hide Points",
                  }}
                />
              </div>

              <div className="chart-container">
                <Plot
                  data={
                    jumpType === "none"
                      ? [traceLeft, traceRight, traceLeftBar, traceRightBar]
                      : [traceBoth, traceBothBar]
                  }
                  layout={layout}
                  config={config}
                  useResizeHandler={true}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          ) : (
            /*End Bar Box Plot chart Container ===================================================================*/

            /*Start Bar chart + Radar chart Container -----------------------------------------------------------------*/
            <div>
              <div>
                {jumpType === "none" && (
                  <MaxMinMean
                    leftFoot={{
                      hasData: data,
                      min:
                        comparisonType === "max"
                          ? Math.min(...data.map((item) => item.maxLeftValue))
                          : Math.min(
                              ...data.map((item) => item.averageLeftValue)
                            ),
                      max:
                        comparisonType === "max"
                          ? Math.max(...data.map((item) => item.maxLeftValue))
                          : Math.max(
                              ...data.map((item) => item.averageLeftValue)
                            ),
                      mean:
                        comparisonType === "max"
                          ? data
                              .map((item) => item.maxLeftValue)
                              .reduce((a, b) => a + b, 0) / data.length
                          : data
                              .map((item) => item.averageLeftValue)
                              .reduce((a, b) => a + b, 0) / data.length,
                    }}
                    rightFoot={{
                      hasData: data,
                      min:
                        comparisonType === "max"
                          ? Math.min(...data.map((item) => item.maxRightValue))
                          : Math.min(
                              ...data.map((item) => item.averageRightValue)
                            ),
                      max:
                        comparisonType === "max"
                          ? Math.max(...data.map((item) => item.maxRightValue))
                          : Math.max(
                              ...data.map((item) => item.averageRightValue)
                            ),
                      mean:
                        comparisonType === "max"
                          ? data
                              .map((item) => item.maxRightValue)
                              .reduce((a, b) => a + b, 0) / data.length
                          : data
                              .map((item) => item.averageRightValue)
                              .reduce((a, b) => a + b, 0) / data.length,
                    }}
                  />
                )}
                {jumpType === "double" && (
                  <MaxMinMean
                    footSymmetry={{
                      hasData: data,
                      min:
                        comparisonType === "max"
                          ? Math.min(...data.map((item) => item.maxValue))
                          : Math.min(...data.map((item) => item.averageValue)),
                      max:
                        comparisonType === "max"
                          ? Math.max(...data.map((item) => item.maxValue))
                          : Math.max(...data.map((item) => item.averageValue)),
                      mean:
                        comparisonType === "max"
                          ? data
                              .map((item) => item.maxValue)
                              .reduce((a, b) => a + b, 0) / data.length
                          : data
                              .map((item) => item.averageValue)
                              .reduce((a, b) => a + b, 0) / data.length,
                    }}
                  />
                )}
              </div>

              <div className="chart-container">
                <ResponsiveContainer width="100%" height="100%">
                  {!graphToggler ? (
                    <ComposedChart data={data}>
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={NEW_AXIS_COLOR}
                        opacity={0.5}
                      />
                      <XAxis
                        data={data}
                        dataKey="sessionID"
                        label={
                          data.length < 50
                            ? {
                                value: "Session ID",
                                position: "bottom",
                                fill: NEW_AXIS_TICK,
                                offset: -6,
                              }
                            : false
                        }
                        tick={
                          data.length < 50 ? { fill: NEW_AXIS_TICK } : false
                        }
                        stroke={NEW_AXIS_COLOR}
                        fontSize={"10px"}
                        reversed={false}
                      />

                      <YAxis
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                        label={{
                          value: yAxisLabel,
                          angle: -90,
                          position: "insideLeft",
                          fill: NEW_AXIS_TICK,
                        }}
                        fontSize={"10px"}
                        domain={[
                          0,
                          (dataMax) => ((dataMax / 100) * 130).toFixed(1),
                        ]}
                      />

                      <Tooltip
                        content={
                          <CustomTooltip data={data} graphType={graphType} />
                        }
                      />

                      <ReferenceLine
                        y={
                          comparisonType === "max"
                            ? data
                                .map((item) => item.maxLeftValue)
                                .reduce((a, b) => a + b, 0) / data.length
                            : data
                                .map((item) => item.averageLeftValue)
                                .reduce((a, b) => a + b, 0) / data.length
                        }
                        stroke={NEW_LEFT_COLOR}
                        dot={false}
                        strokeDasharray="5 5"
                        name="Left Foot Mean"
                        opacity={0.5}
                      />
                      <ReferenceLine
                        y={
                          comparisonType === "max"
                            ? data
                                .map((item) => item.maxRightValue)
                                .reduce((a, b) => a + b, 0) / data.length
                            : data
                                .map((item) => item.averageRightValue)
                                .reduce((a, b) => a + b, 0) / data.length
                        }
                        stroke={NEW_RIGHT_COLOR}
                        dot={false}
                        strokeDasharray="5 5"
                        name="Right Foot Mean"
                        opacity={0.5}
                      />

                      <ReferenceLine
                        y={
                          comparisonType === "max"
                            ? data
                                .map((item) => item.maxValue)
                                .reduce((a, b) => a + b, 0) / data.length
                            : data
                                .map((item) => item.averageValue)
                                .reduce((a, b) => a + b, 0) / data.length
                        }
                        stroke={NEW_MID_COLOR}
                        dot={false}
                        strokeDasharray="5 5"
                        name="Both Feet Mean"
                        opacity={0.5}
                      />
                      {jumpType === "none" && (
                        <>
                          <Bar
                            dataKey={
                              comparisonType === "max"
                                ? "maxLeftValue"
                                : "averageLeftValue"
                            }
                            barSize={20}
                            name="Left Foot"
                            fill={NEW_LEFT_COLOR}
                            opacity={0.8}
                          >
                            {data.length < 51 && (
                              <LabelList
                                dataKey="athleteName"
                                fontSize={"10px"}
                                fill={"white"}
                                width={"100%"}
                                position={"top"}
                                offset={30}
                                angle={-90}
                              />
                            )}
                          </Bar>
                          <Bar
                            dataKey={
                              comparisonType === "max"
                                ? "maxRightValue"
                                : "averageRightValue"
                            }
                            barSize={20}
                            name="Right Foot"
                            fill={NEW_RIGHT_COLOR}
                            opacity={0.8}
                          />
                        </>
                      )}

                      {jumpType === "double" && (
                        <Bar
                          dataKey={
                            comparisonType === "max"
                              ? "maxValue"
                              : "averageValue"
                          }
                          barSize={20}
                          name="Both Feet"
                          fill={NEW_MID_COLOR}
                          opacity={0.8}
                        >
                          {data.length < 51 && (
                            <LabelList
                              dataKey="athleteName"
                              fontSize={"10px"}
                              fill={"white"}
                              width={"100%"}
                              position={"top"}
                            />
                          )}
                        </Bar>
                      )}

                      <Legend
                        verticalAlign="top"
                        align="right"
                        layout="horizontal"
                        wrapperStyle={{ top: -20 }}
                      />
                    </ComposedChart>
                  ) : (
                    <RadarChart outerRadius={220} data={dataFormatted}>
                      <PolarGrid
                        stroke={NEW_AXIS_COLOR}
                        strokeWidth={1}
                        opacity={0.4}
                      />
                      <PolarAngleAxis dataKey="sessionID" />
                      <PolarRadiusAxis
                        stroke={NEW_AXIS_TICK}
                        angle={90}
                        orientation="middle"
                        fontSize={10}
                        label={{
                          position: "bottom",
                          fill: { NEW_AXIS_COLOR },
                          offset: 50,
                        }}
                        //  axisLine={false}
                        tick={formatTicks}
                        tickCount={6}
                      />
                      {jumpType === "double" && (
                        <>
                          <Radar
                            name="Both Feet"
                            dataKey={
                              comparisonType === "max"
                                ? "maxValue"
                                : "averageValue"
                            }
                            fill={NEW_BOTH_FEET_COLOR}
                            fillOpacity={0.4}
                          />
                          <Radar
                            name="Both Feet Average"
                            dataKey={
                              comparisonType === "max"
                                ? "averageMaxData"
                                : "averageAverageData"
                            }
                            stroke={NEW_BOTH_FEET_COLOR}
                            fill="none"
                            strokeOpacity={0.9}
                            strokeDasharray="15 8"
                            strokeWidth={2}
                            legendType="plainline"
                          />
                        </>
                      )}
                      {jumpType === "none" && (
                        <>
                          <Radar
                            name="Right Foot"
                            dataKey={
                              comparisonType === "max"
                                ? "maxRightValue"
                                : "averageRightValue"
                            }
                            fill={NEW_RIGHT_COLOR}
                            fillOpacity={0.4}
                          />
                          <Radar
                            name="Left Foot"
                            dataKey={
                              comparisonType === "max"
                                ? "maxLeftValue"
                                : "averageLeftValue"
                            }
                            fill={NEW_LEFT_COLOR}
                            fillOpacity={0.4}
                          />
                          <Radar
                            name="Left Foot Average"
                            dataKey={
                              comparisonType === "max"
                                ? "averageLeftMaxData"
                                : "averageLeftAverageData"
                            }
                            fill="none"
                            stroke={NEW_LEFT_COLOR}
                            strokeOpacity={0.9}
                            strokeDasharray="15 8"
                            strokeWidth={2}
                            legendType="plainline"
                          />
                          <Radar
                            name="Right Foot Average"
                            dataKey={
                              comparisonType === "max"
                                ? "averageRightMaxData"
                                : "averageRightAverageData"
                            }
                            fill="none"
                            stroke={NEW_RIGHT_COLOR}
                            strokeOpacity={0.9}
                            strokeDasharray="15 8"
                            strokeWidth={2}
                            legendType="plainline"
                          />
                        </>
                      )}

                      <Tooltip content={<CustomTooltip data={data} />} />
                      <Legend
                        verticalAlign="top"
                        align="right"
                        layout="horizontal"
                        wrapperStyle={{ top: -20 }}
                      />
                    </RadarChart>
                  )}
                </ResponsiveContainer>
              </div>
            </div>
          )
          /*End Bar chart + Radar chart Container ===================================================================*/
        }
      </div>
    );
}

const formatTicks = (props) => {
  const { payload, x, y, index } = props;

  return (
    <g x={x} y={y}>
      <rect
        x={x - 13}
        y={y - 3}
        fill="#2E4256"
        width={26}
        height={15}
        rx={4}
        className="rect-style"
        position={"middle"}
      />
      <text
        fill="#FAFAFA"
        x={x - 30}
        y={y - 12}
        dy={20}
        dx={30}
        fontSize={10}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    </g>
  );
};

export const CustomTooltip = ({
  active,
  payload,
  label,
  data,
  desc,
  graphType,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="label">{`${payload[0].payload.athleteName}`}</div>

        {payload[0] && payload[0].name === "Both Feet" && (
          <div style={{ color: NEW_MID_COLOR }}>
            {`${payload[0].name}`} : {` ${payload[0].value}`}
            {desc}
          </div>
        )}

        {payload.length >= 1 && payload[0].name !== "Both Feet" && (
          <div style={{ color: NEW_LEFT_COLOR }}>
            {`${payload[0].name}`} : {` ${payload[0].value.toFixed(3)}`}
            {desc}
          </div>
        )}
        {payload.length >= 2 && payload[1].name !== "Both Feet" && (
          <div style={{ color: NEW_RIGHT_COLOR }}>
            {`${payload[1].name}`} : {` ${payload[1].value.toFixed(3)}`}
            {desc}
          </div>
        )}

        <div style={{ color: "white" }}>
          {`Session ID`} : {` ${payload[0]?.payload?.sessionID}`}
          {desc}
        </div>

        {payload[0].payload?.detail?.length > 0 && (
          <div className="details">
            Session Detail: {payload[0].payload?.detail}
          </div>
        )}
      </div>
    );
  }

  return null;
};

function calculateAverage(data, property) {
  return (
    data?.map((item) => item[property]).reduce((a, b) => a + b, 0) /
    data?.length
  );
}
