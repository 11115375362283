/* eslint-disable */
import { getHistoricalChartsTitle, getHistoricalChartsYAxisTitle } from "../../utils/helper";
import Spinner from 'components/common/spinner/spinner';
import Tooltips from 'components/common/toolTips/toolTips';
import MetricTable from 'components/metrics/metric-helpers/MetricTable';
import SLCJ from 'components/metrics/SLCJ';
import DLCJ from 'components/metrics/DLCJ';
import SLCJHEADER from 'components/metrics/metric-helpers/SLCJHeader';
import DLHEADER from 'components/metrics/metric-helpers/DLHeader';
import { useAsymmetryCalculations } from "components/common/hooks/useAsymmetryCalculations";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    setSelectedSessionId, setGraphModal
} from 'store/playerActivitySlice';
import { useState } from "react";



function calculateAverageValue(data) {
    return data.map(item => item.value).reduce((a, b) => a + b, 0) / data.length;
}

const DisplayData = ({ activity, userData, component, setPosition,tableData,setTableData,graphIsOpen,toggleGraph,setGraphIsOpen }) => {
    const dispatch = useDispatch()
    const [activeData, setActiveData] = useState({locked: false})
    const { graphModal } = useSelector(
        (state) => state.playerActivity
    );
    function GraphTableData(props) {
        const { payload } = props;
        if (!isEqual(payload,tableData) && payload.length) {
            setTableData(payload)
        }

    }
    function handleClick(sessionID) {
        dispatch(setSelectedSessionId(sessionID))
        dispatch(setGraphModal(!graphModal))
        setPosition(window.pageYOffset)
    }

    const dataBounce = tableData


    let chartData = []
    let chartDataDetail = {}
    if (activity && Array.isArray(userData.activityObject['activity' + activity.id]?.chartData)) {
        chartDataDetail = { ...userData.activityObject['activity' + activity.id] }
        chartData = [...chartDataDetail.chartData]
        if (chartData[0]?.value) {
            chartData = chartData.filter((key) => key.value !== undefined)
        }
    }

    const { averageLeftSingleCounter, averageRightSingleCounter, averageAsym } = useAsymmetryCalculations(chartDataDetail);

    if (chartData.length === 0) {
        return displayNoData(activity)
    }
    else {
        const yAxisLabel = getHistoricalChartsYAxisTitle(activity.id)

        for (var i = 0; i < chartData.length; i++) {
            chartData[i] = { ...chartData[i], symLine: 0 }
        }

        const maxValue = (Number(Math.max(...chartData.map(item => item.value)).toFixed(1)) / 100) * 120
        const minValue = (Number(Math.min(...chartData.map(item => item.value)).toFixed(1)) / 100) * 90
        const averageValue = calculateAverageValue(chartData);

        return (
            <div>
                <div className="player-profile-analytics-title" style={{ marginTop: '15px' }}>
                    <p style={{ display: 'flex', position: 'relative', textTransform: 'uppercase', marginLeft: '75px' }}>
                        <span>
                            {`${activity.name} (${getHistoricalChartsTitle(activity.id)})`}
                        </span>
                    </p>
                    {/* <div style={{ paddingLeft: '0.5rem' }}><Tooltips text={`Tooltip Info`} /></div> */}
                </div>

                <div style={{ width: '100%', height: '522px' }}>
                    <div className="metric-chart-container-parent ">
                        <div className="chart-container-padding">
                            <div className='chart-container-with-table'>
                                <div className='chart-container-without-table'>
                                    <HeaderDisplay averageLeftSingleCounter={averageLeftSingleCounter} averageRightSingleCounter={averageRightSingleCounter} activity={activity} averageValue={averageValue} userData={userData} averageAsym={averageAsym} />

                                    <div className="chart-container" style={{ padding: "0px 0px 20px 0px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ChartDisplay 
                                            component={component} 
                                            maxValue={maxValue} 
                                            minValue={minValue} 
                                            yAxisLabel={yAxisLabel} 
                                            activity={activity} 
                                            GraphTableData={GraphTableData}
                                            setTableData={setTableData}
                                            setGraphIsOpen={setGraphIsOpen}
                                            chartData={chartData} 
                                            averageLeftSingleCounter={averageLeftSingleCounter} 
                                            averageRightSingleCounter={averageRightSingleCounter} 
                                            averageAsym={averageAsym} 
                                            activeData={activeData}
                                            toggleGraph={toggleGraph}
                                            setActiveData={setActiveData} 
                                        />
                                    </div>
                                </div>
                                <MetricTable
                                    graphName={activity}
                                    tableData={dataBounce}
                                    graphIsOpen={graphIsOpen}
                                    toggleGraph={toggleGraph}
                                    openSession={handleClick}
                                    sessionLocked = {activeData.locked}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}

export default DisplayData

const HeaderDisplay = ({ activity, averageValue, averageLeftSingleCounter, averageRightSingleCounter, averageAsym, userData }) => {
    if (activity.bilateral === 1) {
        return <DLHEADER userData={userData} averageValue={averageValue} activity={activity} />;
    }

    return <SLCJHEADER averageLeftSingleCounter={averageLeftSingleCounter} averageRightSingleCounter={averageRightSingleCounter} activity={activity} averageAsym={averageAsym} />;
};

const ChartDisplay = ({ activity, GraphTableData,setTableData,setGraphIsOpen,toggleGraph, chartData, averageLeftSingleCounter, averageAsym, averageRightSingleCounter, yAxisLabel, maxValue, minValue, activeData, setActiveData }) => {
    if (activity.bilateral === 0 && chartData.length >0) {
        return <SLCJ 
                    GraphTableData={GraphTableData} 
                    chartData={chartData} 
                    activity={activity} 
                    averageLeftSingleCounter={averageLeftSingleCounter} 
                    averageAsym={averageAsym} 
                    averageRightSingleCounter={averageRightSingleCounter} 
                    setTableData={setTableData}
                    toggleGraph={toggleGraph}
                    setGraphIsOpen={setGraphIsOpen}
                    activeData={activeData}
                    setActiveData={setActiveData}
                />
    }
    if(chartData.length > 0) {

        return <DLCJ 
                    GraphTableData={GraphTableData}
                    yAxisLabel={yAxisLabel}
                    chartData={chartData}
                    toggleGraph={toggleGraph}
                    setTableData={setTableData}
                    setGraphIsOpen={setGraphIsOpen}
                    maxValue={maxValue}
                    activity={activity}
                    minValue={minValue} 
                    activeData={activeData}
                    setActiveData={setActiveData}
                />;
    }
}




export const displayNoData = (activity) => {
    return (
        <div>
            <div className="player-profile-analytics-title">
                <p style={{ display: 'inline', position: 'relative', bottom: '-6px', marginLeft: '20px' }}>
                    {activity?.name}
                </p>
            </div>

            <div className="no-data-container">
                No Data Available For This Metric
            </div>
        </div>
    )
}


export const displayLoadingCharts = (activity) => {
    return (
        <div className="player-profile-analytics-title" style={{ justifyContent: 'center' }}>

            <p style={{ display: 'inline', position: 'relative', bottom: '-6px', marginLeft: '20px' }}>
                {activity?.name ? activity.name : activity}
            </p>

            <div className="metric-container" style={{ marginTop: '20px' }}>

                <Spinner cls='spinner-user-profile' classnames={'user-profile-loader'} />

            </div>
        </div>
    )
}


export const getLatestChange = (activity, userData) => {

    if (activity.bilateral === 1) {
        let chartData = []
        if (userData.activityObject['activity' + activity.id]) {
            chartData = userData.activityObject['activity' + activity.id].chartData
        }

        if (chartData && chartData.length > 1) {

            let averageValue = chartData.map(item => item.value).reduce((a, b) => a + b, 0) / chartData.length;
            let lastItem = chartData[0]?.value

            let diff = (lastItem - averageValue).toFixed(2)

            return ((diff / averageValue) * 100).toFixed(2)
        }
    }

    return null
}


export const getUnit = (activity) => {

    if (getHistoricalChartsTitle(activity.id).toLocaleLowerCase() === "Jump Height".toLocaleLowerCase()) {
        return "cm"
    }
}
