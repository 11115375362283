import React from "react";
import GraphHeader from "../table/header/header";
//import ProgressBar from "./progressBar/progressBar";
import { motion } from "framer-motion";
import "./circularProgressTable.scss";
import OverviewRadarChart from "./radarChart/OverviewRadarChart";
import Tooltip from "../toolTips/toolTips";

const CircularProgressTable = (props) => {
  const {
    fullTableData,
    orgData,
    selectedRow,
    tableDataAcrossOrganisation,
    setPerformanceType,
    performanceType,
    timeframe,
    setTimeframe,
    data,
    comparisonType,
    maxZScore,
    setMaxZScore,
    fullAthleteDataset
  } = props;

  //select change handler
  const handleChangePerformance = (event) => {
    setPerformanceType(event.target.value);
  };

  //select change handler
  const handleChangeTime = (event) => {
    setTimeframe(event.target.value);
  };

  let fullTableDataAltered = fullTableData;

  return (
    <div className="table-one horseshose-one">
      <div className="table-home table-container-global horseshoe-container">
        <GraphHeader toShow={"Performance Overview"} />
        <div className="overview-dropdown">
          <select
            onChange={handleChangeTime}
            name="performance"
            id="performance"
          >
            <option value="AllTime">All Time</option>
            <option value="24Hour">1 Day</option>
            <option value="Week">1 Week</option>
            <option value="30Day">1 Month</option>
            <option value="90day">3 Months</option>
            <option value="180">6 Months</option>
            <option value="12Month">1 Year</option>
          </select>
          <div className="overview-dropdown_tooltip">
            {comparisonType !== "Athlete" && (
              <Tooltip
                text={
                  "The terms 'Maximum', 'Average', and 'Minimum' refer to different aspects of an athlete's performance. 'Maximum' is the highest level of performance that the athlete has achieved, and it is compared to the highest performance level within the organization during a specific period. 'Average' represents the typical performance level of the athlete, which is compared to the average performance level within the organization. Lastly, 'Minimum' refers to the lowest level of performance that the athlete has reached, and it is compared to the lowest performance level within the organization."
                }
              />
            )}
            <select
              onChange={handleChangePerformance}
              name="performance"
              id="performance"
            >
              <option value="Max">Maximum</option>
              <option value="Avg">Average</option>
              <option value="Min">Minimum</option>
            </select>
          </div>
        </div>

        <div className="outer-wrapper">
          {comparisonType !== "Athlete" && (
            <div
              className="heading-progress-table"
              style={{ minHeight: "70px" }}
            >
              <p style={{ color: "white" }}>
                {selectedRow !== null && selectedRow?.player}
              </p>
            </div>
          )}

          <motion.div
            className="radar-chart"
            initial={{ opacity: 0, y: 50, scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.2 * 0,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <OverviewRadarChart
              fullTableData={fullTableDataAltered}
              data={data}
              orgData={orgData}
              tableDataAcrossOrganisation={tableDataAcrossOrganisation}
              selectedRow={selectedRow}
              performanceType={performanceType}
              timeframe={timeframe}
              comparisonType={comparisonType}
              maxZScore={maxZScore}
              setMaxZScore={setMaxZScore}
              fullAthleteDataset={fullAthleteDataset}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CircularProgressTable);
