const LoaderIcon = (props) => {
  const { size = "16", fillOpacity = "0.6", color = "white" } = props;
  return (
    <svg
			className="loader-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
			opacity={fillOpacity}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 3a9 9 0 1 0 9 9" />
    </svg>
  );
};

export default LoaderIcon;
