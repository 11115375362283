
const Athletes = ({
    size = "24",
    color = "#94A3B3",
}) => {
  return (
    <svg width={size} height={size} className='fill-current' viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.0459 2C6.28447 2 4.0459 4.23858 4.0459 7C4.0459 9.76142 6.28447 12 9.0459 12C11.8073 12 14.0459 9.76142 14.0459 7C14.0459 4.23858 11.8073 2 9.0459 2ZM9.0459 4C10.7028 4 12.0459 5.34315 12.0459 7C12.0459 8.65685 10.7028 10 9.0459 10C7.38904 10 6.0459 8.65685 6.0459 7C6.0459 5.34315 7.38904 4 9.0459 4ZM16.0413 18.7831C15.9277 16.1223 13.7347 14 11.0459 14H7.0459L6.82901 14.0046C4.16821 14.1182 2.0459 16.3112 2.0459 19V21L2.05263 21.1166C2.11039 21.614 2.53306 22 3.0459 22C3.59818 22 4.0459 21.5523 4.0459 21V19L4.05099 18.8237C4.14224 17.2489 5.44822 16 7.0459 16H11.0459L11.2222 16.0051C12.797 16.0963 14.0459 17.4023 14.0459 19V21L14.0526 21.1166C14.1104 21.614 14.5331 22 15.0459 22C15.5982 22 16.0459 21.5523 16.0459 21V19L16.0413 18.7831ZM15.0771 2.88196C15.2141 2.34694 15.7589 2.02426 16.2939 2.16125C18.5063 2.72771 20.0537 4.72124 20.0537 7.005C20.0537 9.28876 18.5063 11.2823 16.2939 11.8487C15.7589 11.9857 15.2141 11.6631 15.0771 11.128C14.9402 10.593 15.2628 10.0482 15.7979 9.91125C17.1253 9.57137 18.0537 8.37526 18.0537 7.005C18.0537 5.63474 17.1253 4.43863 15.7979 4.09875C15.2628 3.96176 14.9402 3.41699 15.0771 2.88196ZM18.2959 14.1817C17.7611 14.0437 17.2157 14.3653 17.0776 14.9C16.9396 15.4348 17.2612 15.9802 17.7959 16.1182C19.1143 16.4586 20.0381 17.6442 20.0459 19.0057L20.0459 21L20.0526 21.1166C20.1104 21.614 20.5331 22 21.0459 22C21.5982 22 22.0459 21.5523 22.0459 21V19L22.0395 18.7735C21.9295 16.5948 20.4199 14.7301 18.2959 14.1817Z" fill={color} fillOpacity="1"/>
    </svg>
  )
}

export default Athletes
