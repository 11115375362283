/* eslint-disable */
import Button from "components/common/button/button";
import "./athletes-comparison-style.scss";
import HomePageTable from "components/common/HomePageTable/HomePageTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TestSlide from "components/common/testSlide/testSlide";
import { useSelector } from "react-redux";
import { getOrganisationsToolkit, selectOrganisation } from "store/orgSlice";



function simplifyArray(arr) {
  if(!arr.includes(undefined)){

    let result = {
      "12MonthAvg": null,
      "12MonthMaxAvg": null,
      "12MonthMax": null,
      "12MonthMin": null,
      "24HourAvg": null,
      "24HourMaxAvg": null,
      "24HourMax": null,
      "24HourMin": null,
      "30DayAvg": null,
      "30DayMaxAvg": null,
      "30DayMax": null,
      "30DayMin": null,
      "90DayAvg": null,
      "90DayMaxAvg": null,
      "90DayMax": null,
      "90DayMin": null,
      "180DayAvg": null,
      "180DayMaxAvg": null,
      "180DayMax": null,
      "180DayMin": null,
      "AllTimeAvg": null,
      "AllTimeMaxAvg": null,
      "AllTimeMax": null,
      "AllTimeMin": null,
      "WeekAvg": null,
      "WeekMaxAvg": null,
      "WeekMax": null,
      "WeekMin": null,
    };

    for (let key in result) {
      let values = arr.map((obj) => obj?.[key]).filter((val) => (val !== null || val !== undefined));

      if (values.length > 0) {
        if (key?.includes("Max")) {
          result[key] = Math.max(...values);
        } else if (key?.includes("Min")) {
          result[key] = Math.min(...values);
        } else if (key?.includes("Avg")) {
          result[key] = values.reduce((a, b) => a + b, 0) / values.length;
        }
      }
    }

    return result;
  }else{
    return {}
  }
}

// as a non super admin, athlete comparison should use the entire org of athleres data to generate zscore for a more accurate comparison
// as a super admin should aim to use all the athletes in the system as a baseline for calculating zscores in athlete comparison

function AthletesComparison(props) {
  const { modalIn, setModalIn } = props;




  const { checkedAthletesForComparison } = useSelector(
    (state) => (state.playerActivity)
  );

  let athletesData = [];
  let athleteDataSorted = [];

  let allOrgAthletesSorted = [];

  


  if(props?.organisation?.orgList?.length > 1){
    //super admin athlte array spreading an array of arrays 
    const saAthleteArray = props?.organisation?.orgList.map(item => item?.players).flat()
    saAthleteArray.forEach((player) => {
      allOrgAthletesSorted.push({
        isPlayer: true,
        player: player.player,
        overview: JSON.parse(player.overview),
        playerID: player.playerID,
        lastActive: player?.tsLastActive,
      });
    });
  }else{
    //non super admin athlte array
    const nsaAthleteArray = props?.organisation?.orgList[0]?.players;
    nsaAthleteArray?.forEach((player) => {
      allOrgAthletesSorted.push({
        isPlayer: true,
        player: player.player,
        overview: JSON.parse(player.overview),
        playerID: player.playerID,
        lastActive: player?.tsLastActive,
      });
    });
  }


  if (checkedAthletesForComparison.length > 1) {
    athletesData = checkedAthletesForComparison
      .map((item) => JSON.parse(item))
      .forEach((player) => {
        athleteDataSorted.push({
          isPlayer: true,
          player: player.player,
          overview: JSON.parse(player.overview),
          playerID: player.playerID,
          lastActive: player?.tsLastActive,
        });
      });
  }

  const sortedData = athleteDataSorted.sort((a, b) => {
    return new Date(b.lastActive) - new Date(a.lastActive);
  });

  let sortedDataGait = [];

  sortedData.forEach((player) =>
    sortedDataGait.push({
      ...player,
      overview: {
        ...player.overview,
        gait: simplifyArray([
          player.overview["1"],
          player.overview["2"],
          player.overview["3"],
        ]),
      },
    })
  );

  let allOrgAthletesSortedGait = []

  allOrgAthletesSorted.forEach((player) =>
  allOrgAthletesSortedGait.push({
    ...player,
    overview: {
      ...player.overview,
      gait: simplifyArray([
        player.overview["1"],
        player.overview["2"],
        player.overview["3"],
      ]),
    },
  })
);


  const headers = [
    "NAME",
    "DLCMJ (CM)",
    "DLRH (RSI)",
    "SLCMJ (ASYM)",
    "DJ (RSI)",
    "LOAD",
    "GAIT",
  ];

  return (
    <TestSlide modalIn={modalIn} className="player-modal comparison-modal-athlete">
      <div className="comparison-modal-container">
        <div className="header-options-container">
          <div className="comparison-modal-header">
            <div className="comparison-title">
              <h1>Performance Comparison</h1>
            </div>
            <div
              className="close-button"
              //resetting state whenever the close button is pressed on the modal
              onClick={() => {
                setModalIn(false);
              }}
            >
              <Button value={"x"} />
            </div>
          </div>
          <div className="graph-container">
            <HomePageTable
              headers={headers}
              fullTableData={sortedDataGait}
              tableData={sortedDataGait}
              comparisonType={"Athlete"}
              fullAthleteDataset={allOrgAthletesSortedGait}
            />
          </div>
        </div>
      </div>
    </TestSlide>
  );
}

function mapStateToProps(state) {
  return {
    organisation: selectOrganisation(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getOrganisationsToolkit },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AthletesComparison);
