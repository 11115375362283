const CompareIcon = (props) => {
const {size, color, fillOpacity} = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      fillOpacity={fillOpacity}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0746 8.10198C11.2246 8.25183 11.4413 8.34341 11.6663 8.34341L11.6496 8.32593C11.8663 8.32593 12.083 8.23435 12.2413 8.0845H12.2496C12.5746 7.75983 12.5746 7.22703 12.2496 6.91068L11.179 5.84582H13.3333C13.7917 5.84582 14.1667 6.21212 14.1667 6.67832V12.6473C13.1945 12.9893 12.5 13.9134 12.5 15.0033C12.5 16.3769 13.6167 17.5008 15 17.5008C16.375 17.5008 17.5 16.3769 17.5 15.0033C17.5 13.9147 16.8019 12.9913 15.8333 12.6485V6.67832C15.8333 5.29637 14.7083 4.18082 13.3333 4.18082H11.1608L12.2496 3.09783C12.5746 2.76483 12.5746 2.24035 12.2496 1.91568C11.9163 1.58351 11.3913 1.58351 11.0663 1.91651L8.56629 4.41401V4.42234C8.24129 4.74701 8.24129 5.27981 8.57462 5.60449L11.0746 8.10198ZM2.5 5.01332C2.5 6.09663 3.19454 7.02461 4.16667 7.36847V13.3383C4.16667 14.7119 5.28333 15.8358 6.66667 15.8358H8.80505L7.73372 16.9014V16.9097C7.40872 17.2344 7.40872 17.7672 7.74206 18.0919C7.89206 18.2417 8.10872 18.3333 8.33372 18.3333L8.31706 18.3158C8.53372 18.3158 8.75039 18.2243 8.90872 18.0744L11.2807 15.7048C11.5124 15.5565 11.6667 15.297 11.6667 15.0033C11.6667 14.8842 11.6422 14.7717 11.5981 14.6701C11.5575 14.5722 11.4972 14.4811 11.4171 14.4031L8.91706 11.9056C8.58372 11.5726 8.05872 11.5726 7.73372 11.9056C7.40039 12.2303 7.40039 12.7547 7.73372 13.0877V13.0886L8.82178 14.1708H6.66667C6.2 14.1708 5.83333 13.7962 5.83333 13.3383V7.36647C6.80174 7.02172 7.49982 6.09482 7.5 5.0129C7.49977 3.63115 6.37486 2.51582 5 2.51582C3.61667 2.51582 2.5 3.63137 2.5 5.01332ZM4.16667 5.01332C4.16667 4.54712 4.53333 4.18082 5 4.18082C5.45833 4.18082 5.83333 4.54712 5.83333 5.01332C5.83333 5.4712 5.45833 5.84582 5 5.84582C4.53333 5.84582 4.16667 5.4712 4.16667 5.01332ZM14.1667 15.0033C14.1667 14.5371 14.5333 14.1708 15 14.1708C15.4583 14.1708 15.8333 14.5371 15.8333 15.0033C15.8333 15.4612 15.4583 15.8358 15 15.8358C14.5333 15.8358 14.1667 15.4612 14.1667 15.0033Z"
        fill={color}
      />
    </svg>
  );
};

export default CompareIcon
