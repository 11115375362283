/* eslint-disable */

import { EyeNoneIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import ViewableIcon from "./common/icons/viewableIcon";

const LoginInput = ({
  id,
  value,
  type,
  onChange,
  placeholder,
  autoComplete,
}) => {
  const [toggglePassword, setTogglePassword] = useState(false);

  const handlePasswordToggle = () => {
    setTogglePassword((prev) => !prev);
  };

  return (
    <div className="login-input-container">
      <div className="login-input-input">
        <input
          type={
            type === "password" ? (toggglePassword ? "text" : "password") : type
          }
          autoComplete={autoComplete}
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        {type === "password" && (
          <div className="show-password-toggle" onClick={handlePasswordToggle}>
            <ViewableIcon 
                  size="22"
                  color={toggglePassword ? "white" : "#344b6f"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginInput;
