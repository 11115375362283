const CloseIcon = ({ size = "24", color = "#94A3B3" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2ZM8.70711 7.29289L8.6129 7.2097L8.5114 7.14038C8.12751 6.91161 7.62334 6.96245 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L8.585 10L7.29289 11.2929L7.2097 11.3871C6.90468 11.7794 6.93241 12.3466 7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L10 11.415L11.2929 12.7071L11.3871 12.7903C11.7794 13.0953 12.3466 13.0676 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L11.415 10L12.7071 8.70711L12.7903 8.6129C13.0953 8.22061 13.0676 7.65338 12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L10 8.585L8.70711 7.29289Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
