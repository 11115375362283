/* eslint-disable */
import { nanoid } from "@reduxjs/toolkit";
import "./mainTooltip.scss";
import { useEffect, useRef } from "react";
import UploadIcon from "../icons/uploadIcon";
import LoaderIcon from "../icons/loader";
import CheckIcon from "../icons/checkIcon";
import ViewableIcon from "../icons/viewableIcon";
import NonViewableIcon from "../icons/nonViewableIcon";

const MainTooltip = (props) => {
  const {
    tooltipText = "Main tooltip text",
    tooltipIcon = "info",
    iconType = "circle",
    iconSize = 20,
    iconColor = "white",
  } = props;

  let icon = null;

  const ref = useRef();


  //tooltip info
  if (tooltipIcon === "info") {
    icon = <InfoIcon type={iconType} size={iconSize} color={iconColor} />;
  } else if (tooltipIcon === "warning") {
    icon = <WarningIcon type={iconType} size={iconSize} color={iconColor} />;
  } else if (tooltipIcon === "upload") {
    icon = <UploadIcon type={iconType} size={iconSize} color={iconColor} />
  } else if (tooltipIcon === "loading") {
    icon = <LoaderIcon type={iconType} size={iconSize} color={iconColor} />
  } else if (tooltipIcon === "complete") {
    icon = <CheckIcon type={iconType} size={iconSize} color={iconColor} />
  }else if (tooltipIcon === "viewable") {
    icon = <ViewableIcon type={iconType} size={iconSize} color={iconColor} />
  }else if (tooltipIcon === "non-viewable") {
    icon = <NonViewableIcon type={iconType} size={iconSize} color={iconColor} />
  }

  return (
    <div className="main-tooltip" ref={ref}>
      {/* Check if ref is undefined if so by default set tootltip to flow on the left */}
      {ref?.current?.getBoundingClientRect().right ?(window.outerWidth - ref?.current?.getBoundingClientRect().right) < window.outerWidth/2
       ? (
        tooltipText.length ? <span className="tooltiptext-left">{tooltipText}</span> : ""
      ) : (
        tooltipText.length ? <span className="tooltiptext-right">{tooltipText}</span> : ""
      ) : tooltipText.length ? <span className="tooltiptext-right">{tooltipText}</span> : ""}
      <div className={`main-tooltip-icon ${tooltipIcon === "loading" ? "icon-loading" : ""}`}>{icon}</div>
    </div>
  );
};

export default MainTooltip;

//tooltip icons

const InfoIcon = (props) => {
  const { size, color, type } = props;

  let circle = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-info-circle"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
      <path d="M12 9h.01" />
      <path d="M11 12h1v4h1" />
    </svg>
  );

  let triangle = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-info-triangle"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
      <path d="M12 9h.01" />
      <path d="M11 12h1v4h1" />
    </svg>
  );

  if (type === "circle") {
    return circle;
  } else if (type === "triangle") {
    return triangle;
  }
};

const WarningIcon = (props) => {
  const { size, color, type } = props;

  let circle = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-alert-circle"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
      <path d="M12 8v4" />
      <path d="M12 16h.01" />
    </svg>
  );

  let triangle = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-alert-triangle"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 9v4" />
      <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
      <path d="M12 16h.01" />
    </svg>
  );

  if (type === "circle") {
    return circle;
  } else if (type === "triangle") {
    return triangle;
  }
};
