/* eslint-disable */

import moment from "moment";
import {
  NEW_BOTH_FEET_COLOR,
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
} from "utils/constant";

export const LoadCustomTooltip = ({
  active,
  payload,
  heading = "Heading",
  toggle,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-stance">
        <p
          className="tooltip-label"
          style={{ fontWeight: "bold", fontSize: "15px" }}
        >
          {heading}
        </p>

        <div>
          <p style={{ color: "white" }}>{`Date : ${moment(
            payload[0].payload.TSCreated
          ).format("DD/MM/YYYY")}`}</p>
          {/* <p
              style={{ color: "white" }}
            >{`Duration : ${payload[0].payload.duration}`}</p> */}
          {!toggle && (
            <>
              <p
                style={{ color: NEW_BOTH_FEET_COLOR }}
              >{`Total Load : ${payload[0].payload.value.toFixed(2)}`}</p>
              <p
                style={{ color: NEW_LEFT_COLOR }}
              >{`Left Load : ${payload[0].payload.leftValue.toFixed(2)}`}</p>
              <p
                style={{ color: NEW_RIGHT_COLOR }}
              >{`Right Load : ${payload[0].payload.rightValue.toFixed(2)}`}</p>
            </>
          )}

          {toggle && (
            <>
              <p
                style={{ color: NEW_BOTH_FEET_COLOR }}
              >{`Total Relative Load : ${payload[0].payload.toggledValue.toFixed(
                2
              )}`}</p>
            </>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export const CustomLineTooltip = ({
  active,
  payload,
  heading = "Heading",
  toggle,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-stance">
        <p
          className="tooltip-label"
          style={{ fontWeight: "bold", fontSize: "15px" }}
        >
          {heading}
        </p>
        <div>
          <p style={{ color: "white" }}>{`Date : ${moment(
            payload[0].payload.TSCreated
          ).format("DD/MM/YYYY")}`}</p>
        </div>
        <div>
          <p
            style={{ color: NEW_RIGHT_COLOR }}
          >{`Value : ${payload[0].payload.value.toFixed(2)}`}</p>
        </div>
      </div>
    );
  }

  return null;
};

export const CustomAsymLineTooltip = ({
  active,
  payload,
  heading = "Heading",
  toggle,
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-stance">
        <p
          className="tooltip-label"
          style={{ fontWeight: "bold", fontSize: "15px" }}
        >
          {heading}
        </p>
        <div>
          <p style={{ color: "white" }}>{`Date : ${moment(
            payload[0].payload.TSCreated
          ).format("DD/MM/YYYY")}`}</p>
        </div>
        {payload[0].payload.value > 0 ? (
          <div>
            <p
              style={{ color: NEW_RIGHT_COLOR }}
            >{`Value : ${payload[0].payload.value.toFixed(2)} %`}</p>
          </div>
        ) : null}
        {payload[0].payload.value < 0 ? (
          <div>
            <p
              style={{ color: NEW_LEFT_COLOR }}
            >{`Value : ${payload[0].payload.value.toFixed(2)} %`}</p>
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};
