import Button from "components/common/button/button";
import { nanoid } from "@reduxjs/toolkit";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

const SelectedSessionsTable = ({
  sessionWarningTesting,
  formattedData,
  sessionsForComparison,
  colapseTable,
  comparedSessionType,
  gaitSession,
  jumpSession,
  setColapseTable,
  setSessionWarningTesting,
}) => {
  return (
    <div className="comparison-table-container">
      <div className="comparison-table-heading">
        <div>
          <div className="heading-warning">
            <h1>Selected Sessions</h1>
            {sessionWarningTesting && (
              <MainTooltip
                tooltipText={
                  "One or more of the sessions slected for comparions may be missing data for one or more metrics"
                }
                tooltipIcon={"info"}
                iconSize={25}
                iconColor={"orange"}
              />
            )}
          </div>
          {formattedData?.filter(
            (item) =>
              item?.sessionTableData &&
              Object.keys(item?.sessionTableData).length
          ).length !== sessionsForComparison.length && (
            <p>
              One or more sessions selected is not compatible for comparison
            </p>
          )}
        </div>

        <div
          className="hide-session-table"
          onClick={() => {
            //hide table when button is pressed
            setColapseTable(!colapseTable);
            if (
              !document
                .querySelector(".comparison-table")
                .classList.contains("table-colapsed")
            ) {
              document
                .querySelector(".comparison-table")
                .classList.add("table-colapsed");
            } else {
              document
                .querySelector(".comparison-table")
                .classList.remove("table-colapsed");
            }
          }}
        >
          <Button value={colapseTable ? "Show" : "Hide"} />
        </div>
      </div>
      <div className="comparison-table table-colapsed">
        <div className="comparison-table-headings">
          <div className="name">Athlete Name</div>
          <div className="sessionWarning"></div>
          <div className="date">Date</div>
          <div className="sessionID">Session ID</div>
          <div className="sessionDetail">Session Notes</div>
          <div className="sessionTags">Tags</div>
        </div>
        {gaitSession &&
          formattedData.map((item) => {
            let sessionWarningFlight = !(
              item?.flightTimeData?.averageLeftValue > 0 &&
              item?.flightTimeData?.averageRightValue > 0 &&
              item?.flightTimeData?.maxRightValue > 0 &&
              item?.flightTimeData?.maxLeftValue > 0
            );
            let sessionWarningContact = !(
              item?.contactTimeData?.averageLeftValue > 0 &&
              item?.contactTimeData?.averageRightValue > 0 &&
              item?.contactTimeData?.maxRightValue > 0 &&
              item?.contactTimeData?.maxLeftValue > 0
            );
            let sessionWarningFlightSymmetry = !!isNaN(
              item?.flightTimeSymmetryData?.averageSymmetryValue
            );
            let sessionWarningContactSymmetry = !!isNaN(
              item?.contactTimeSymmetryData?.averageSymmetryValue
            );

            if (
              sessionWarningFlight ||
              sessionWarningContact ||
              sessionWarningFlightSymmetry ||
              sessionWarningContactSymmetry
            ) {
              if (sessionWarningTesting === false) {
                setSessionWarningTesting(true);
              }
            }

            if (item.sessionTableData) {
              return (
                <div className="table-row" key={nanoid()}>
                  <div className="name">
                    {item?.sessionTableData?.athleteName}
                  </div>
                  {(comparedSessionType === "Sprint" ||
                    comparedSessionType === "Walk" ||
                    comparedSessionType === "Jog") && (
                    <div className="sessionWarning">
                      {(sessionWarningFlight ||
                        sessionWarningContact ||
                        sessionWarningFlightSymmetry ||
                        sessionWarningContactSymmetry) && (
                        <MainTooltip
                          tooltipText={`This session is missing data, it will be excluded from comparisons in the following graphs:       (${
                            sessionWarningFlight ? "[Flight Time] " : ""
                          } ${sessionWarningContact ? "[Contact Time] " : ""} ${
                            sessionWarningContactSymmetry
                              ? "[Contact Time Asymmetry] "
                              : ""
                          } ${
                            sessionWarningFlightSymmetry
                              ? "[Flight Time Asymmetry] "
                              : ""
                          })`}
                          tooltipIcon={"info"}
                          iconSize={25}
                        />
                      )}
                    </div>
                  )}
                  <div className="date">{item?.sessionTableData?.date}</div>
                  <div className="sessionID">
                    {item?.sessionTableData?.sessionID}
                  </div>
                  <div className="sessionDetail">
                    {item?.sessionTableData?.detail}
                  </div>
                  <div className="sessionTags">
                    {item?.sessionTableData?.tags && (
                      <div className="tags">
                        {JSON.parse(item?.sessionTableData?.tags).length > 0 &&
                          JSON.parse(item?.sessionTableData?.tags)
                            .slice(0, 4)
                            .map((tag) => (
                              <div className="tag" key={nanoid()}>
                                {tag.tag}
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            }
          })}
        {jumpSession &&
          formattedData.map((item) => {
            //jump height data
            let compatibleSessionJHData =
              comparedSessionType.toLowerCase().includes("double") &&
              !comparedSessionType.toLowerCase().includes("horizontal")
                ? item.jumpHeightData === undefined ||
                  item.jumpHeightData === 0 ||
                  item.jumpHeightData.averageValue === 0 ||
                  item.jumpHeightData.maxValue === 0
                : !comparedSessionType.toLowerCase().includes("drop") &&
                  !comparedSessionType.toLowerCase().includes("depth") &&
                  !comparedSessionType.toLowerCase().includes("repeated") &&
                  !comparedSessionType.toLowerCase().includes("horizontal") &&
                  (item.jumpHeightData === undefined ||
                    item.jumpHeightData === 0 ||
                    item.jumpHeightData.averageLeftValue === 0 ||
                    item.jumpHeightData.averageRightValue === 0 ||
                    item.jumpHeightData.maxRightValue === 0 ||
                    item.jumpHeightData.maxLeftValue === 0);

            //jump height asymmetry data
            let compatibleSessionJHAData =
              comparedSessionType.toLowerCase().includes("single") &&
              !comparedSessionType.toLowerCase().includes("drop") &&
              !comparedSessionType.toLowerCase().includes("depth") &&
              !comparedSessionType.toLowerCase().includes("repeated") &&
              !comparedSessionType.toLowerCase().includes("horizontal") &&
              (item.jumpHeightAsymmetryData === false ||
                item.jumpHeightAsymmetryData === undefined ||
                item.jumpHeightAsymmetryData === 0 ||
                item.jumpHeightAsymmetryData.maxSymmetryValue === 0 ||
                item.jumpHeightAsymmetryData.averageSymmetryValue === 0);

            //jump flight time data
            let compatibleSessionJFTData = comparedSessionType
              .toLowerCase()
              .includes("double")
              ? item.flightTimeData === false ||
                item.flightTimeData === undefined ||
                item.flightTimeData === 0 ||
                item.flightTimeData.averageValue === 0 ||
                item.flightTimeData.maxValue === 0
              : item.flightTimeData === false ||
                item.flightTimeData === undefined ||
                item.flightTimeData === 0 ||
                item.flightTimeData.averageLeftValue === 0 ||
                item.flightTimeData.averageRightValue === 0 ||
                item.flightTimeData.maxRightValue === 0 ||
                item.flightTimeData.maxLeftValue === 0;

            //jump ground clearance data
            let compatibleSessionJGCData = comparedSessionType
              .toLowerCase()
              .includes("double")
              ? item.groundClearance === false ||
                item.groundClearance === undefined ||
                item.groundClearance === 0 ||
                item.groundClearance.averageValue === 0 ||
                item.groundClearance.maxValue === 0
              : item.groundClearance === false ||
                item.groundClearance === undefined ||
                item.groundClearance === 0 ||
                item.groundClearance.averageLeftValue === 0 ||
                item.groundClearance.averageRightValue === 0 ||
                item.groundClearance.maxRightValue === 0 ||
                item.groundClearance.maxLeftValue === 0;

            //jump length data
            let compatibleSessionJLData = comparedSessionType
              .toLowerCase()
              .includes("double")
              ? item.jumpLength === false ||
                item.jumpLength === undefined ||
                item.jumpLength === 0 ||
                item.jumpLength.averageValue === 0 ||
                item.jumpLength.maxValue === 0
              : item.jumpLength === false ||
                item.jumpLength === undefined ||
                item.jumpLength === 0 ||
                item.jumpLength.averageLeftValue === 0 ||
                item.jumpLength.averageRightValue === 0 ||
                item.jumpLength.maxRightValue === 0 ||
                item.jumpLength.maxLeftValue === 0;

            //jump max jump velocity data
            let compatibleSessionMJVData = comparedSessionType
              .toLowerCase()
              .includes("double")
              ? item.maxJumpVelocity === false ||
                item.maxJumpVelocity === undefined ||
                item.maxJumpVelocity === 0 ||
                item.maxJumpVelocity.averageValue === 0 ||
                item.maxJumpVelocity.maxValue === 0
              : item.maxJumpVelocity === false ||
                item.maxJumpVelocity === undefined ||
                item.maxJumpVelocity === 0 ||
                item.maxJumpVelocity.averageLeftValue === 0 ||
                item.maxJumpVelocity.averageRightValue === 0 ||
                item.maxJumpVelocity.maxRightValue === 0 ||
                item.maxJumpVelocity.maxLeftValue === 0;

            //jump mean jump velocity data
            let compatibleSessionMeanJVData = comparedSessionType
              .toLowerCase()
              .includes("double")
              ? item.meanJumpVelocity === false ||
                item.meanJumpVelocity === undefined ||
                item.meanJumpVelocity === 0 ||
                item.meanJumpVelocity.averageValue === 0 ||
                item.meanJumpVelocity.maxValue === 0
              : item.meanJumpVelocity === false ||
                item.meanJumpVelocity === undefined ||
                item.meanJumpVelocity === 0 ||
                item.meanJumpVelocity.averageLeftValue === 0 ||
                item.meanJumpVelocity.averageRightValue === 0 ||
                item.meanJumpVelocity.maxRightValue === 0 ||
                item.meanJumpVelocity.maxLeftValue === 0;

            //jump flight time asymmetry data
            let compatibleSessionJFAData =
              comparedSessionType.toLowerCase().includes("single") &&
              !comparedSessionType.toLowerCase().includes("drop") &&
              (item.flightTimeAsymmetryData === false ||
                item.flightTimeAsymmetryData === undefined ||
                item.flightTimeAsymmetryData === 0 ||
                item.flightTimeAsymmetryData.maxSymmetryValue === 0 ||
                item.flightTimeAsymmetryData.averageSymmetryValue === 0);

            //jump peak power data
            let compatibleSessionJPPData =
              comparedSessionType.toLowerCase().includes("double") &&
              !comparedSessionType.toLowerCase().includes("horizontal")
                ? item.powerData === false ||
                  item.powerData === undefined ||
                  item.powerData === 0 ||
                  item.powerData.averageValue === 0 ||
                  item.powerData.maxValue === 0
                : !comparedSessionType.toLowerCase().includes("drop") &&
                  !comparedSessionType.toLowerCase().includes("repeated") &&
                  !comparedSessionType.toLowerCase().includes("depth") &&
                  !comparedSessionType.toLowerCase().includes("horizontal") &&
                  (item.powerData === false ||
                    item.powerData === undefined ||
                    item.powerData === 0 ||
                    item.powerData.averageLeftValue === 0 ||
                    item.powerData.averageRightValue === 0 ||
                    item.powerData.maxRightValue === 0 ||
                    item.powerData.maxLeftValue === 0);

            //jump peak power asymmetry
            let compatibleSessionJPPAData =
              comparedSessionType.toLowerCase().includes("single") &&
              !comparedSessionType.toLowerCase().includes("drop") &&
              !comparedSessionType.toLowerCase().includes("depth") &&
              !comparedSessionType.toLowerCase().includes("horizontal") &&
              !comparedSessionType
                .toLowerCase()
                .includes("single leg repeated") &&
              (item.powerAsymmetryData === false ||
                item.powerAsymmetryData === undefined ||
                item.powerAsymmetryData === 0 ||
                item.powerAsymmetryData.maxSymmetryValue === 0 ||
                item.powerAsymmetryData.averageSymmetryValue === 0);

            //contact time data
            let compatibleSessionCTData =
              comparedSessionType.toLowerCase().includes("double") &&
              !comparedSessionType.toLowerCase().includes("countermovement") &&
              !comparedSessionType.toLowerCase().includes("squat") &&
              !comparedSessionType.toLowerCase().includes("horizontal")
                ? item.contactTimeData === false ||
                  item.contactTimeData === undefined ||
                  item.contactTimeData === 0 ||
                  item.contactTimeData.averageValue === 0 ||
                  item.contactTimeData.maxValue === 0
                : !comparedSessionType
                    .toLowerCase()
                    .includes("countermovement") &&
                  !comparedSessionType.toLowerCase().includes("squat") &&
                  !comparedSessionType.toLowerCase().includes("drop") &&
                  !comparedSessionType.toLowerCase().includes("repeated") &&
                  !comparedSessionType.toLowerCase().includes("depth") &&
                  !comparedSessionType.toLowerCase().includes("horizontal") &&
                  (item.contactTimeData === false ||
                    item.contactTimeData === undefined ||
                    item.contactTimeData === 0 ||
                    item.contactTimeData.averageLeftValue === 0 ||
                    item.contactTimeData.averageRightValue === 0 ||
                    item.contactTimeData.maxRightValue === 0 ||
                    item.contactTimeData.maxLeftValue === 0);

            //contact time asymmetry data
            let compatibleSessionCTAData =
              comparedSessionType.toLowerCase().includes("single") &&
              !comparedSessionType.toLowerCase().includes("squat") &&
              !comparedSessionType.toLowerCase().includes("countermovement") &&
              !comparedSessionType.toLowerCase().includes("drop") &&
              !comparedSessionType.toLowerCase().includes("depth") &&
              !comparedSessionType.toLowerCase().includes("horizontal") &&
              !comparedSessionType
                .toLowerCase()
                .includes("single leg repeated") &&
              (item.contactTimeAsymmetryData === false ||
                item.contactTimeAsymmetryData === undefined ||
                item.contactTimeAsymmetryData === 0 ||
                item.contactTimeAsymmetryData.maxSymmetryValue === 0 ||
                item.contactTimeAsymmetryData.averageSymmetryValue === 0);

            // rsi data
            //contact time data
            let compatibleSessionRSIData =
              comparedSessionType.toLowerCase().includes("double") &&
              !comparedSessionType.toLowerCase().includes("countermovement") &&
              !comparedSessionType.toLowerCase().includes("squat") &&
              !comparedSessionType.toLowerCase().includes("horizontal")
                ? item.rsiData === false ||
                  item.rsiData === undefined ||
                  item.rsiData === 0 ||
                  item.rsiData.averageValue === 0 ||
                  item.rsiData.maxValue === 0
                : !comparedSessionType
                    .toLowerCase()
                    .includes("countermovement") &&
                  !comparedSessionType.toLowerCase().includes("squat") &&
                  !comparedSessionType.toLowerCase().includes("horizontal") &&
                  (item.rsiData === false ||
                    item.rsiData === undefined ||
                    item.rsiData === 0 ||
                    item.rsiData.averageLeftValue === 0 ||
                    item.rsiData.averageRightValue === 0 ||
                    item.rsiData.maxRightValue === 0 ||
                    item.rsiData.maxLeftValue === 0);
            //contact time asymmetry data
            let compatibleSessionRSIAData =
              comparedSessionType.toLowerCase().includes("single") &&
              !comparedSessionType.toLowerCase().includes("countermovement") &&
              !comparedSessionType.toLowerCase().includes("squat") &&
              !comparedSessionType.toLowerCase().includes("horizontal") &&
              (item.rsiAsymmetryData === false ||
                item.rsiAsymmetryData === undefined ||
                item.rsiAsymmetryData === 0 ||
                item.rsiAsymmetryData.maxSymmetryValue === 0 ||
                item.rsiAsymmetryData.averageSymmetryValue === 0);
            if (
              compatibleSessionJHData ||
              compatibleSessionJHAData ||
              compatibleSessionJFTData ||
              compatibleSessionJFAData ||
              compatibleSessionJPPData ||
              compatibleSessionJPPAData ||
              compatibleSessionCTData ||
              compatibleSessionCTAData ||
              compatibleSessionRSIData ||
              compatibleSessionRSIAData
            ) {
              if (sessionWarningTesting === false) {
                setSessionWarningTesting(true);
              }
            }

            if (item.sessionTableData) {
              return (
                <div className="table-row" key={nanoid()}>
                  <div className="name">
                    {item?.sessionTableData?.athleteName}
                  </div>
                  {comparedSessionType.toLowerCase().includes("leg") && (
                    <div className="sessionWarning">
                      {(compatibleSessionJHData ||
                        compatibleSessionJHAData ||
                        compatibleSessionJFTData ||
                        compatibleSessionJFAData ||
                        compatibleSessionJPPData ||
                        compatibleSessionJPPAData ||
                        compatibleSessionCTData ||
                        compatibleSessionCTAData ||
                        compatibleSessionRSIData ||
                        compatibleSessionRSIAData ||
                        compatibleSessionJGCData ||
                        compatibleSessionJLData ||
                        compatibleSessionMJVData ||
                        compatibleSessionMeanJVData) && (
                        <MainTooltip
                          tooltipText={`This session is missing data, it will be excluded from comparisons in the following graphs: (${
                            compatibleSessionJHData ? "[Jump Height] " : ""
                          } ${
                            compatibleSessionJHAData
                              ? "[Jump Height Asymmetry] "
                              : ""
                          } 
                ${compatibleSessionJGCData ? "[Jump Ground Clearance] " : ""}
                ${compatibleSessionJLData ? "[Jump Length] " : ""}
                ${compatibleSessionMJVData ? "[Jump Max Velocity] " : ""}
                ${compatibleSessionMeanJVData ? "[Jump Mean Velocity] " : ""}
                ${compatibleSessionJFTData ? "[Jump Flight Time] " : ""} ${
                            compatibleSessionJFAData
                              ? "[Flight Time Asymmetry] "
                              : ""
                          } ${
                            compatibleSessionJPPData ? "[Peak Power] " : ""
                          } ${
                            compatibleSessionJPPAData
                              ? "[Peak Power Asymmetry] "
                              : ""
                          } ${
                            compatibleSessionCTData ? "[Contact Time] " : ""
                          } ${
                            compatibleSessionCTAData
                              ? "[Contact Time Asymmetry] "
                              : ""
                          } ${compatibleSessionRSIData ? "[RSI] " : ""} ${
                            compatibleSessionRSIAData ? "[RSI Asymmetry] " : ""
                          })`}
                          tooltipIcon={"info"}
                          iconSize={25}
                        />
                      )}
                    </div>
                  )}
                  <div className="date">{item?.sessionTableData?.date}</div>
                  <div className="sessionID">
                    {item?.sessionTableData?.sessionID}
                  </div>
                  <div className="sessionDetail">
                    {item?.sessionTableData?.detail}
                  </div>
                  <div className="sessionTags">
                    {item?.sessionTableData?.detail}
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default SelectedSessionsTable;
