/* eslint-disable */
import MainButton from "./common/reuseableButtonComponent/MainButton";
import TagsIcon from "./common/icons/tags";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { memo, useEffect, useRef, useState } from "react";
import { selectOrganisation } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import { getUsers, selectUserList } from "store/adminSlice";
import { selectedTeamList, getTeams } from "store/teamSlice";
import { getTeamGroups, selectedGroupList } from "store/groupSlice";
import {
  selectedTeamAthleteList,
  getTeamsAthletes,
} from "store/teamsAthletesSlice";
import { getOrganisationsToolkit } from "store/orgSlice";
import { useDispatch } from "react-redux";
import "../styles/teamPage.scss";
import ReactSelect from "react-select";
import Polygon from "./common/icons/downArrow";
import { nanoid } from "@reduxjs/toolkit";
import { simplifyArray, averageObjects } from "utils/helper";
import TeamRadarChart from "./common/team-management-components/team-radar-chart/TeamRadarChart";
import { UserProfile_URL } from "utils/urls";
import TeamBarChart from "./common/team-management-components/team-component-bar-chart/TeamBarChart";
import {
  TEAM_COLOR_GROUP_1,
  TEAM_COLOR_GROUP_2,
  TEAM_COLOR_GROUP_3,
  TEAM_COLOR_GROUP_4,
  TEAM_COLOR_GROUP_5,
  TEAM_COLOR_GROUP_6,
  TEAM_COLOR_GROUP_7,
  TEAM_COLOR_GROUP_8,
  TEAM_COLOR_GROUP_9,
  TEAM_COLOR_GROUP_10,
} from "utils/constant";
import { selectedOrgForTeams } from "store/selectedOrgForTeamsSlice";

const groupColours = [
  TEAM_COLOR_GROUP_1,
  TEAM_COLOR_GROUP_2,
  TEAM_COLOR_GROUP_3,
  TEAM_COLOR_GROUP_4,
  TEAM_COLOR_GROUP_5,
  TEAM_COLOR_GROUP_6,
  TEAM_COLOR_GROUP_7,
  TEAM_COLOR_GROUP_8,
  TEAM_COLOR_GROUP_9,
  TEAM_COLOR_GROUP_10,
];

//checbox code
const Checkbox = memo(
  ({ value, selectedForGraphComparison, setSelectedForGraphComparison }) => {
    const handleAthletesSelectedForAssignment = (event) => {
      if (event.target.checked) {
        setSelectedForGraphComparison((prevState) => ({
          ...prevState,
          athletesSelected: [...prevState.athletesSelected, event.target.value],
        }));
      } else {
        setSelectedForGraphComparison((prevState) => ({
          ...prevState,
          athletesSelected: prevState.athletesSelected.filter(
            (item) => item !== event.target.value
          ),
        }));
      }
    };
    return (
      <label className="container">
        <input
          type="checkbox"
          value={value}
          onChange={handleAthletesSelectedForAssignment}
          checked={selectedForGraphComparison.athletesSelected?.includes(
            String(value)
          )}
        />
        <span className="checkmark"></span>
      </label>
    );
  }
);

// selector styles
const selectStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base) => ({
    ...base,
    borderColor: base.isFocused ? "grey" : "#0F182A",
    boxShadow: "none",
    backgroundColor: "#0F182A",
    color: "white",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return {
      ...provided,
      opacity,
      transition,
      color: "white",
    };
  },
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    color: "white",
    //option background colour#
    borderRadius: 5,
    paddingTop: 5,
    marginTop: 5,
    background: isFocused
      ? "rgba(30, 151, 214, 0.10)"
      : isSelected
      ? "#1B314B"
      : undefined,
    zIndex: 1,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    marginTop: 10,
    padding: 0,
    backgroundColor: "#1B314B",
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    "::-webkit-scrollbar": {
      width: "3px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#0A111F",
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgba(30, 151, 214, 0.3)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "rgba(30, 151, 214, 0.4)",
    },
  }),
};

//table grid component
const TableGrid = ({
  interaction,
  name,
  value1,
  value2,
  value3,
  value4,
  value5,
  value6,
}) => {
  return (
    <div className="row-grid">
      <div className="interaction">{interaction}</div>
      <div className="row-name">{name}</div>
      <div className="row-value-1">{value1}</div>
      <div className="row-value-2">{value2}</div>
      <div className="row-value-3">{value3}</div>
      <div className="row-value-4">{value4}</div>
      <div className="row-value-5">{value5}</div>
      <div className="row-value-6">{value6}</div>
    </div>
  );
};

//convert team data to react select options object
const convertToReactSelectFormat = (data) => {
  return data.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

const linkTeam = (teamId, linkingArray, secondArray) => {
  if (secondArray.length) {
    let output = { isInTeam: [], isInGroup: [] };

    // Create a map for quick lookup in the second array
    let secondArrayMap = new Map();
    for (let player of secondArray) {
      secondArrayMap.set(player.playerID, player);
    }

    // Iterate over the linking array
    for (let link of linkingArray) {
      // Check if the teamId matches and if the playerid is in the second array
      if (link.teamid === teamId && secondArrayMap.has(link.playerid)) {
        // If the groupid is 0, add to isInTeam
        if (link.groupid === 0) {
          output.isInTeam.push(secondArrayMap.get(link.playerid));
        }
        // If the groupid is > 0, add to isInGroup
        else if (link.groupid > 0) {
          let player = {
            ...secondArrayMap.get(link.playerid),
            groupid: link.groupid,
          }; // Create a new object
          output.isInGroup.push(player);
        }
      }
    }

    // Sort the isInGroup array
    output.isInGroup.sort((a, b) => a.groupid - b.groupid);
    return output;
  }
};

const TeamsPage = (props) => {
  const { organisation, actions, user, history } = props;

  //state declaration
  const [teamsInOrg, setTeamsInOrg] = useState([]);
  const [groupsInTeams, setGroupsInTeams] = useState([]);
  const [athletesInTeams, setAthletesInTeams] = useState([]);
  const [athletesInSelectedTeam, setAthletesInSelectedTeam] = useState([]);
  const [groupsInSelectedTeam, setGroupsInSelectedTeam] = useState([]);
  const [selectedOrgID, setSelectedOrgID] = useState(0);
  const [selectedOrgIdData, setSelectedOrgIdData] = useState(0);

  //state declaration for selects
  const [selectedOption, setSelectedOption] = useState();
  const [selectedTimeframe, setSelectedTimeframe] = useState();
  const [selectedRange, setSelectedRange] = useState();

  const [selectedValue, setSelctedValue] = useState("AllTimeAvg");

  //state declaration for table row overview data
  const [allTeamAthletesOverview, setAllTeamAthletesOverview] = useState([]);
  const [averagedGroupOverviews, setAveragedGroupOverviews] = useState([]);

  //state declaration for the graph
  const [selectedForGraphComparison, setSelectedForGraphComparison] = useState({
    athletesInSelectedTeam: [],
    averageOfAthletesInSelectedTeam: {},
    groupsSelected: [],
    averageOfGroupsSelected: [],
    athletesSelected: [],
    comparingAllAthletesInTeam: true,
  });

  //use selectors
  const { status, teams } = useSelector((state) => state.team);
  const { groupStatus, groups } = useSelector((state) => state.group);
  const { teamAthletesStatus, teamsAthletes } = useSelector(
    (state) => state.teamAthlete
  );
  const { orgID } = useSelector((state) => state.selectedOrgForTeam);

  //which rows are open
  const [indexesOpen, setIndexesOpen] = useState([]);

  console.log(indexesOpen);

  //use refs
  const teamRef = useRef();
  const allAthleteListRef = useRef();
  const groupRef = useRef([]);

  console.log(groupRef);

  //variables for use
  const selectOptions = convertToReactSelectFormat(teamsInOrg);
  const selectedTimeframeOptions = [
    {
      value: "last",
      label: "Latest Session",
    },
    {
      value: "AllTime",
      label: "All Time",
    },
  ];

  const selectedRangeOptions = [
    {
      value: "Avg",
      label: "Average Performance",
    },
    {
      value: "Max",
      label: "Highest Performance",
    },
    {
      value: "Min",
      label: "Lowest Performance",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user.permissionLevel === 1 &&
      orgID &&
      !Number(localStorage.getItem("selectedOrgID"))
    ) {
      setSelectedOrgID(orgID);
    }
    if (
      user.permissionLevel === 1 &&
      Number(localStorage.getItem("selectedOrgID")) &&
      Number(localStorage.getItem("selectedOrgID")) > 0
    ) {
      setSelectedOrgID(Number(localStorage.getItem("selectedOrgID")));
    }
  }, [orgID]);

  useEffect(() => {
    const fetchData = () => {
      if (status === "idle" && teams?.length === 0) {
        dispatch(getTeams());
      }
    };
    fetchData();
    if (!organisation?.orgList?.length > 0 && user?.id) {
      actions.getOrganisationsToolkit(user?.id);
    }
  }, [orgID]);

  useEffect(() => {
    let orgIdData =
      selectedOrgID === 0
        ? 0
        : organisation.orgList?.map((org) => org.OrgID)?.indexOf(selectedOrgID);
    setSelectedOrgIdData(orgIdData);
  }, [selectedOrgID, orgID, organisation]);

  //use effect used to set the team org as well as get the teams assigned to the org
  useEffect(() => {
    if (teams?.length !== 0) {
      const orgTeams = teams?.filter(
        (item) => item.orgid === organisation.orgList[selectedOrgIdData]?.OrgID
      );
      setTeamsInOrg(orgTeams);
    }
  }, [organisation, teams, orgID, selectedOrgIdData]);

  //use effect used to get all the team groups as well as the athletes in the teams
  useEffect(() => {
    teamsInOrg.forEach((team) => {
      const fetchData = () => {
        if (groupStatus == "idle" && groups.length === 0) {
          dispatch(getTeamGroups(team.id));
        }
        if (teamAthletesStatus == "idle" && teamsAthletes.length === 0) {
          dispatch(getTeamsAthletes(team.id));
        }
      };
      fetchData();
    });

    setSelectedOption(selectOptions[0]);
    setSelectedTimeframe(selectedTimeframeOptions[0]);
    setSelectedRange(selectedRangeOptions[0]);
  }, [teamsInOrg, orgID, selectedOrgIdData]);

  //use effect to assign the athletes and groups to state
  useEffect(() => {
    setGroupsInTeams(groups);

    setAthletesInTeams(teamsAthletes);
  }, [groups, teamsAthletes, orgID]);

  //use effect for when the selected option changes
  // temp removed old code that was causing a crash of prod
  // useEffect(() => {
  //   if (organisation?.orgList[selectedOrgIdData]?.players) {
  //     const orgPlayersOverviewUnpacked = organisation?.orgList[
  //       selectedOrgIdData
  //     ]?.players?.map((player) => ({
  //       ...player,
  //       overview: JSON.parse(player.overview),
  //     }));

  //     const selectedOptionAthletes = linkTeam(
  //       selectedOption?.value,
  //       athletesInTeams,
  //       orgPlayersOverviewUnpacked
  //     );

  //     const athletesInTeamButNotInGroup = selectedOptionAthletes?.isInTeam;
  //     const athletesInGroupButAlsoInTeam = selectedOptionAthletes?.isInGroup;

  //     const modifiedData = [
  //       ...athletesInTeamButNotInGroup,
  //       ...athletesInGroupButAlsoInTeam,
  //     ].map((player) => ({
  //       ...player,
  //       overview: {
  //         ...player.overview,
  //         gait: simplifyArray([
  //           player?.overview?.["1"],
  //           player?.overview?.["2"],
  //           player?.overview?.["3"],
  //         ]),
  //       },
  //     }));
  //     setAthletesInSelectedTeam(modifiedData);
  //   }
  // }, [
  //   selectedOption,
  //   teamsInOrg,
  //   organisation,
  //   teams,
  //   groups,
  //   teamsAthletes,
  //   athletesInTeams,
  //   selectedOrgIdData,
  // ]);

  useEffect(() => {
    if (organisation?.orgList[selectedOrgIdData]?.players) {
      const orgPlayersOverviewUnpacked = organisation?.orgList[
        selectedOrgIdData
      ]?.players?.map((player) => ({
        ...player,
        overview: JSON.parse(player.overview),
      }));

      const selectedOptionAthletes = linkTeam(
        selectedOption?.value,
        athletesInTeams,
        orgPlayersOverviewUnpacked
      );

      // Add defensive checks
      const athletesInTeamButNotInGroup = Array.isArray(
        selectedOptionAthletes?.isInTeam
      )
        ? selectedOptionAthletes.isInTeam
        : [];
      const athletesInGroupButAlsoInTeam = Array.isArray(
        selectedOptionAthletes?.isInGroup
      )
        ? selectedOptionAthletes.isInGroup
        : [];

      const modifiedData = [
        ...athletesInTeamButNotInGroup,
        ...athletesInGroupButAlsoInTeam,
      ].map((player) => ({
        ...player,
        overview: {
          ...player.overview,
          gait: simplifyArray([
            player?.overview?.["1"],
            player?.overview?.["2"],
            player?.overview?.["3"],
          ]),
        },
      }));
      setAthletesInSelectedTeam(modifiedData);
    }
  }, [
    selectedOption,
    teamsInOrg,
    organisation,
    teams,
    groups,
    teamsAthletes,
    athletesInTeams,
    selectedOrgIdData,
  ]);

  useEffect(() => {
    //set the groups in the selected team

    const groupsInTeam = groupsInTeams.filter(
      (item) => item.teamid === selectedOption?.value
    );

    const groupsInTeamsWithAthletes = groupsInTeam.map((group) => ({
      ...group,
      athletes: [
        ...athletesInSelectedTeam.filter(
          (athlete) => athlete?.groupid === group.id
        ),
      ],
    }));

    //maybe filter throgh athlete list and creat an array of overview objects for each group, then average objects and index through them when assigning the value

    setGroupsInSelectedTeam(groupsInTeamsWithAthletes);

    if (athletesInSelectedTeam.length > 0) {
      setSelectedForGraphComparison({
        ...selectedForGraphComparison,
        athletesInSelectedTeam: [...athletesInSelectedTeam],
      });
    }
  }, [
    selectedOption,
    athletesInSelectedTeam,
    selectedTimeframe,
    selectedRange,
    selectedOrgIdData,
  ]);

  //use effect to set all team athletes overview
  useEffect(() => {
    if (athletesInSelectedTeam.length > 0) {
      const arrayOfOverviews = athletesInSelectedTeam.map(
        (item) => item.overview
      );

      const averagedTeamAthleteOverviewValues =
        averageObjects(arrayOfOverviews);
      //on the right track here  ======================================== : )

      setAllTeamAthletesOverview(averagedTeamAthleteOverviewValues);
      setSelectedForGraphComparison((prevState) => ({
        ...prevState,
        averageOfAthletesInSelectedTeam: {
          ...averagedTeamAthleteOverviewValues,
        },
      }));
    } else {
      setAllTeamAthletesOverview([]);
    }
  }, [athletesInSelectedTeam]);

  //use effect to set all group overviews
  useEffect(() => {
    if (groupsInSelectedTeam.length > 0) {
      //remap the group overview, and remove dead data for averaging
      const arrayOfOverviews = groupsInSelectedTeam
        .map((overview) => overview.athletes.map((athlete) => athlete.overview))
        .map((subArray) => {
          return subArray.filter((obj) => {
            return Object.keys(obj).length >= 2;
          });
        });

      if (arrayOfOverviews[0].length > 0) {
        //average the data in the array
        const arrayOfOverviewsAveraged = arrayOfOverviews.map((array) =>
          averageObjects(array)
        );
        setAveragedGroupOverviews(arrayOfOverviewsAveraged);
      }
    }
  }, [groupsInSelectedTeam]);

  useEffect(() => {
    // const selectedValue = `${selectedTimeframe.value}${selectedRangeOptions.value}`;
    if (selectedTimeframe?.value && selectedRange?.value) {
      setSelctedValue(`${selectedTimeframe.value}${selectedRange.value}`);
    }
  }, [selectedTimeframe, selectedRange]);

  //handler functions
  const hiddenHandler = (index = false) => {
    console.log("hidden handler trigger");
    const allRef = teamRef.current;
    const allAthletesRef = allAthleteListRef.current;

    console.log(index);

    if (!index && index !== 0) {
      if (allRef.classList.contains("all-athletes-row-header-open")) {
        allRef.classList.remove("all-athletes-row-header-open");
        // allAthleteListRef.current.classList.remove("all-athletes-in-row-open");
      } else {
        allRef.classList.add("all-athletes-row-header-open");
        // allAthleteListRef.current.classList.add("all-athletes-in-row-open");
      }

      if (allAthletesRef.classList.contains("all-athletes-in-row-open")) {
        allAthleteListRef.current.classList.remove("all-athletes-in-row-open");
      } else {
        allAthleteListRef.current.classList.add("all-athletes-in-row-open");
      }
    }

    if (index !== false) {
      if (!indexesOpen.includes(index)) {
        setIndexesOpen((prevData) => [...prevData, index]);
      } else {
        setIndexesOpen((prevData) => [
          ...prevData.filter((item) => item !== index),
        ]);
      }
    }
  };

  const handleTeamSelectedForComparison = () => {
    setSelectedForGraphComparison((prevState) => ({
      ...prevState,
      comparingAllAthletesInTeam: !prevState.comparingAllAthletesInTeam,
    }));
  };

  const handleGroupSelectedForComparison = (group, index) => {
    if (
      selectedForGraphComparison.groupsSelected.some(
        (obj) => obj.id === group.id
      )
    ) {
      setSelectedForGraphComparison((prevState) => ({
        ...prevState,
        groupsSelected: [
          ...prevState.groupsSelected.filter((item) => item.id !== group.id),
        ],
        averageOfGroupsSelected: [
          ...prevState.averageOfGroupsSelected.filter(
            (item) =>
              JSON.stringify(item) !==
              JSON.stringify(averagedGroupOverviews[index])
          ),
        ],
      }));
    } else {
      setSelectedForGraphComparison((prevState) => ({
        ...prevState,
        groupsSelected: [...prevState.groupsSelected, group],
        averageOfGroupsSelected: [
          ...prevState.averageOfGroupsSelected,
          averagedGroupOverviews[index],
        ],
      }));
    }
  };

  const handleClearSelection = () => {
    setSelectedForGraphComparison({
      ...selectedForGraphComparison,
      athletesSelected: [],
      groupsSelected: [],
      averageOfGroupsSelected: [],
    });
  };

  //go to profile page, for the table on click
  function goToPlayerProfilePage(playerID) {
    if (!playerID) {
      return;
    }
    history.push(UserProfile_URL + "/" + playerID);
  }

  // on click div class management outside of div to stop rerender from occuring
  if (selectedForGraphComparison.comparingAllAthletesInTeam) {
    teamRef?.current?.classList?.add("row-selected-for-comparison");
  } else {
    teamRef?.current?.classList?.remove("row-selected-for-comparison");
  }

  //data formatting for the metric graphs bellow the table and radar chart.
  const doubleLegCountermovementJumpData = athletesInSelectedTeam
    .filter(
      (player) =>
        Object.keys(player.overview).length > 1 &&
        player.overview?.[4]?.[selectedValue]
    )
    .map((athlete) => {
      return {
        athleteName: athlete.player,
        value: Number(athlete.overview?.[4]?.[selectedValue]?.toFixed(2)),
        athleteID: athlete.playerID,
        groupID: athlete.groupid,
      };
    })
    .sort((a, b) => b.value - a.value);

  const doubleLegRepeatedHops = athletesInSelectedTeam
    .filter(
      (player) =>
        Object.keys(player.overview).length > 1 &&
        player.overview?.[10]?.[selectedValue]
    )
    .map((athlete) => {
      return {
        athleteName: athlete.player,
        value: Number(athlete.overview?.[10]?.[selectedValue]?.toFixed(2)),
        athleteID: athlete.playerID,
        groupID: athlete.groupid,
      };
    })
    .sort((a, b) => b.value - a.value);

  const singleLegCountermovementJump = athletesInSelectedTeam
    .filter(
      (player) =>
        Object.keys(player.overview).length > 1 &&
        player.overview?.[14]?.[selectedValue]
    )
    .map((athlete) => {
      return {
        athleteName: athlete.player,
        value: Number(athlete.overview?.[14]?.[selectedValue]?.toFixed(2)),
        athleteID: athlete.playerID,
        groupID: athlete.groupid,
      };
    })
    .sort((a, b) => b.value - a.value);

  const dropJump = athletesInSelectedTeam
    .filter(
      (player) =>
        Object.keys(player.overview).length > 1 &&
        player.overview?.[5]?.[selectedValue]
    )
    .map((athlete) => {
      return {
        athleteName: athlete.player,
        value: Number(athlete.overview?.[5]?.[selectedValue]?.toFixed(2)),
        athleteID: athlete.playerID,
        groupID: athlete.groupid,
      };
    })
    .sort((a, b) => b.value - a.value);

  const loadData = athletesInSelectedTeam
    .filter(
      (player) =>
        Object.keys(player.overview).length > 1 &&
        player.overview?.["load"]?.[selectedValue]
    )
    .map((athlete) => {
      return {
        athleteName: athlete.player,
        value: Number(athlete.overview?.["load"]?.[selectedValue]?.toFixed(2)),
        athleteID: athlete.playerID,
        groupID: athlete.groupid,
      };
    })
    .sort((a, b) => b.value - a.value);

  const gaitData = athletesInSelectedTeam
    .filter(
      (player) =>
        Object.keys(player.overview).length > 1 &&
        player.overview?.["gait"]?.[selectedValue]
    )
    .map((athlete) => {
      return {
        athleteName: athlete.player,
        value: Number(athlete.overview?.["gait"]?.[selectedValue]?.toFixed(2)),
        athleteID: athlete.playerID,
        groupID: athlete.groupid,
      };
    })
    .sort((a, b) => b.value - a.value);

  const selectedGroups = selectedForGraphComparison.groupsSelected;

  return (
    <div className="team-container">
      <div className="team-body-container">
        <div className="table-controls-container">
          <div className="team-selector">
            <ReactSelect
              isSearchable={false}
              value={selectedOption}
              onChange={setSelectedOption}
              options={selectOptions}
              styles={selectStyles}
            ></ReactSelect>
          </div>
          <div
            className="team-selection-clear"
            onClick={() => handleClearSelection()}
          >
            <MainButton buttonText={"Clear Selection"} />
          </div>
          <div className="team-table-container container-shared">
            <div className="team-table">
              <div className="team-table-headings">
                <TableGrid
                  interaction={""}
                  name={<p>NAME</p>}
                  value1={<p>DLCMJ</p>}
                  value2={<p>DLRH</p>}
                  value3={<p>SLCMJ</p>}
                  value4={<p>DJ</p>}
                  value5={<p>LOAD</p>}
                  value6={<p>GAIT</p>}
                />
              </div>
              <div className="team-table-rows">
                <div className="all-athletes-row ">
                  <div className={`all-athletes-row-header`} ref={teamRef}>
                    <TableGrid
                      interaction={
                        <div
                          className="selection-row"
                          onClick={() => hiddenHandler()}
                        >
                          <Polygon />
                        </div>
                      }
                      name={
                        <p
                          className="row-name"
                          onClick={() => handleTeamSelectedForComparison()}
                        >
                          All Athletes
                        </p>
                      }
                      value1={
                        <p className="row-value">
                          {allTeamAthletesOverview?.[4]?.[selectedValue]
                            ? allTeamAthletesOverview?.[4]?.[
                                selectedValue
                              ].toFixed(1)
                            : "-"}
                        </p>
                      }
                      value2={
                        <p className="row-value">
                          {allTeamAthletesOverview?.[10]?.[selectedValue]
                            ? allTeamAthletesOverview?.[10]?.[
                                selectedValue
                              ].toFixed(1)
                            : "-"}
                        </p>
                      }
                      value3={
                        <p className="row-value">
                          {allTeamAthletesOverview?.[14]?.[selectedValue]
                            ? allTeamAthletesOverview?.[14]?.[
                                selectedValue
                              ].toFixed(1)
                            : "-"}
                        </p>
                      }
                      value4={
                        <p className="row-value">
                          {allTeamAthletesOverview?.[5]?.[selectedValue]
                            ? allTeamAthletesOverview?.[5]?.[
                                selectedValue
                              ].toFixed(1)
                            : "-"}
                        </p>
                      }
                      value5={
                        <p className="row-value">
                          {allTeamAthletesOverview?.["load"]?.[selectedValue]
                            ? allTeamAthletesOverview?.["load"]?.[
                                selectedValue
                              ].toFixed(1)
                            : "-"}
                        </p>
                      }
                      value6={
                        <p className="row-value">
                          {allTeamAthletesOverview?.["gait"]?.[selectedValue]
                            ? allTeamAthletesOverview?.["gait"]?.[
                                selectedValue
                              ].toFixed(1)
                            : "-"}
                        </p>
                      }
                    />
                  </div>
                  <div className="all-athletes-in-row" ref={allAthleteListRef}>
                    {athletesInSelectedTeam.map((item) => (
                      // 4-DLCMJ 5-DJ  10-DLRH  14-SLCMJ  load-load
                      <div key={nanoid()} className="athlete-row">
                        <TableGrid
                          interaction={
                            <div className="selection-row">
                              <Checkbox
                                value={item.playerID}
                                selectedForGraphComparison={
                                  selectedForGraphComparison
                                }
                                setSelectedForGraphComparison={
                                  setSelectedForGraphComparison
                                }
                              />
                            </div>
                          }
                          name={
                            <p
                              className="row-value"
                              onClick={() =>
                                goToPlayerProfilePage(item.playerID)
                              }
                            >
                              {item?.player}
                            </p>
                          }
                          value1={
                            <p className="row-value">
                              {item?.overview?.[4]?.[selectedValue]
                                ? item?.overview?.[4]?.[selectedValue]?.toFixed(
                                    1
                                  )
                                : "-"}
                            </p>
                          }
                          value2={
                            <p className="row-value">
                              {item?.overview?.[10]?.[selectedValue]
                                ? item?.overview?.[10]?.[
                                    selectedValue
                                  ]?.toFixed(1)
                                : "-"}
                            </p>
                          }
                          value3={
                            <p className="row-value">
                              {item?.overview?.[14]?.[selectedValue]
                                ? item?.overview?.[14]?.[
                                    selectedValue
                                  ]?.toFixed(1)
                                : "-"}
                            </p>
                          }
                          value4={
                            <p className="row-value">
                              {item?.overview?.[5]?.[selectedValue]
                                ? item?.overview?.[5]?.[selectedValue]?.toFixed(
                                    1
                                  )
                                : "-"}
                            </p>
                          }
                          value5={
                            <p className="row-value">
                              {item?.overview?.["load"]?.[selectedValue]
                                ? item?.overview?.["load"]?.[
                                    selectedValue
                                  ]?.toFixed(1)
                                : "-"}
                            </p>
                          }
                          value6={
                            <p className="row-value">
                              {item?.overview?.["gait"]?.[selectedValue]
                                ? item?.overview?.["gait"]?.[
                                    selectedValue
                                  ]?.toFixed(1)
                                : "-"}
                            </p>
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="team-groups">
                  {groupsInSelectedTeam.map((group, index) => (
                    <div
                      key={nanoid()}
                      className={`team-group ${
                        indexesOpen.includes(index) ? "team-group-open" : ""
                      }`}
                      ref={(el) => (groupRef.current[index] = el)}
                    >
                      <div
                        className={`team-goup-header ${
                          selectedForGraphComparison.groupsSelected.some(
                            (obj) => obj.id === group.id
                          )
                            ? "row-selected-for-comparison"
                            : ""
                        }`}
                      >
                        <TableGrid
                          interaction={
                            <div
                              className="selection-row"
                              onClick={() => hiddenHandler(index)}
                            >
                              <Polygon />
                            </div>
                          }
                          name={
                            <p
                              onClick={() =>
                                handleGroupSelectedForComparison(group, index)
                              }
                              className="group-title"
                            >
                              {group.name}
                            </p>
                          }
                          value1={
                            <p>
                              {averagedGroupOverviews[index]?.[4]?.[
                                selectedValue
                              ]
                                ? averagedGroupOverviews[index]?.[4]?.[
                                    selectedValue
                                  ].toFixed(1)
                                : "-"}
                            </p>
                          }
                          value2={
                            <p>
                              {averagedGroupOverviews[index]?.[10]?.[
                                selectedValue
                              ]
                                ? averagedGroupOverviews[index]?.[10]?.[
                                    selectedValue
                                  ].toFixed(1)
                                : "-"}
                            </p>
                          }
                          value3={
                            <p>
                              {averagedGroupOverviews[index]?.[14]?.[
                                selectedValue
                              ]
                                ? averagedGroupOverviews[index]?.[14]?.[
                                    selectedValue
                                  ].toFixed(1)
                                : "-"}
                            </p>
                          }
                          value4={
                            <p>
                              {averagedGroupOverviews[index]?.[5]?.[
                                selectedValue
                              ]
                                ? averagedGroupOverviews[index]?.[5]?.[
                                    selectedValue
                                  ].toFixed(1)
                                : "-"}
                            </p>
                          }
                          value5={
                            <p>
                              {averagedGroupOverviews[index]?.["load"]?.[
                                selectedValue
                              ]
                                ? averagedGroupOverviews[index]?.["load"]?.[
                                    selectedValue
                                  ].toFixed(1)
                                : "-"}
                            </p>
                          }
                          value6={
                            <p>
                              {averagedGroupOverviews[index]?.["gait"]?.[
                                selectedValue
                              ]
                                ? averagedGroupOverviews[index]?.["gait"]?.[
                                    selectedValue
                                  ].toFixed(1)
                                : "-"}
                            </p>
                          }
                        />
                      </div>
                      <div className="team-group-athletes">
                        {group.athletes.map((item) => (
                          // 4-DLCMJ 5-DJ  10-DLRH  14-SLCMJ  load-load
                          <div key={nanoid()} className="athlete-row">
                            <TableGrid
                              interaction={
                                <div className="selection-row">
                                  <Checkbox
                                    value={item.playerID}
                                    selectedForGraphComparison={
                                      selectedForGraphComparison
                                    }
                                    setSelectedForGraphComparison={
                                      setSelectedForGraphComparison
                                    }
                                  />
                                </div>
                              }
                              name={
                                <p
                                  className="row-value"
                                  onClick={() =>
                                    goToPlayerProfilePage(item.playerID)
                                  }
                                >
                                  {item?.player}
                                </p>
                              }
                              value1={
                                <p className="row-value">
                                  {item?.overview?.[4]?.[selectedValue]
                                    ? item?.overview?.[4]?.[
                                        selectedValue
                                      ]?.toFixed(1)
                                    : "-"}
                                </p>
                              }
                              value2={
                                <p className="row-value">
                                  {item?.overview?.[10]?.[selectedValue]
                                    ? item?.overview?.[10]?.[
                                        selectedValue
                                      ]?.toFixed(1)
                                    : "-"}
                                </p>
                              }
                              value3={
                                <p className="row-value">
                                  {item?.overview?.[14]?.[selectedValue]
                                    ? item?.overview?.[14]?.[
                                        selectedValue
                                      ]?.toFixed(1)
                                    : "-"}
                                </p>
                              }
                              value4={
                                <p className="row-value">
                                  {item?.overview?.[5]?.[selectedValue]
                                    ? item?.overview?.[5]?.[
                                        selectedValue
                                      ]?.toFixed(1)
                                    : "-"}
                                </p>
                              }
                              value5={
                                <p className="row-value">
                                  {item?.overview?.["load"]?.[selectedValue]
                                    ? item?.overview?.["load"]?.[
                                        selectedValue
                                      ]?.toFixed(1)
                                    : "-"}
                                </p>
                              }
                              value6={
                                <p className="row-value">
                                  {item?.overview?.["gait"]?.[selectedValue]
                                    ? item?.overview?.["gait"]?.[
                                        selectedValue
                                      ]?.toFixed(1)
                                    : "-"}
                                </p>
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="team-graph-container container-shared">
          <div className="team-graph">
            <div className="team-controls-button-time">
              <ReactSelect
                isSearchable={false}
                value={selectedTimeframe}
                onChange={setSelectedTimeframe}
                options={selectedTimeframeOptions}
                styles={selectStyles}
              ></ReactSelect>
            </div>
            <div className="team-controls-button-type">
              <ReactSelect
                isSearchable={false}
                value={selectedRange}
                onChange={setSelectedRange}
                options={selectedRangeOptions}
                styles={selectStyles}
              ></ReactSelect>
            </div>
            <div className="team-graph-and-table-container">
              <div className="team-spider-graph">
                <TeamRadarChart
                  dataForCharting={selectedForGraphComparison}
                  selectedGroups={groupsInSelectedTeam}
                  selectedValue={selectedValue}
                />
              </div>
              {/* <div className="team-graph-table"></div> */}
            </div>
          </div>
        </div>
        <div className="team-graphs-container">
          <div className="team-graph-chart">
            <TeamBarChart
              graphHeading={"Double Leg Countermovement Jump"}
              data={doubleLegCountermovementJumpData}
              label={"Height (cm)"}
              selectedGroups={groupsInSelectedTeam}
              selectedAthletes={selectedForGraphComparison.athletesSelected}
            />
          </div>
          <div className="team-graph-chart">
            <TeamBarChart
              graphHeading={"Double Leg Repeated Hops"}
              data={doubleLegRepeatedHops}
              label={"Reactive Strength Index (rsi)"}
              selectedGroups={groupsInSelectedTeam}
              selectedAthletes={selectedForGraphComparison.athletesSelected}
            />
          </div>
          <div className="team-graph-chart">
            <TeamBarChart
              graphHeading={"Single Leg Countermovement Jump"}
              data={singleLegCountermovementJump}
              label={"Height (cm)"}
              selectedGroups={groupsInSelectedTeam}
              selectedAthletes={selectedForGraphComparison.athletesSelected}
            />
          </div>
          <div className="team-graph-chart">
            <TeamBarChart
              graphHeading={"Drop Jump"}
              data={dropJump}
              label={"Reactive Strength Index (rsi)"}
              selectedGroups={groupsInSelectedTeam}
              selectedAthletes={selectedForGraphComparison.athletesSelected}
            />
          </div>
          <div className="team-graph-chart">
            <TeamBarChart
              graphHeading={"Load"}
              data={loadData}
              label={"Daily Load (a.u.)"}
              selectedGroups={groupsInSelectedTeam}
              selectedAthletes={selectedForGraphComparison.athletesSelected}
            />
          </div>
          <div className="team-graph-chart">
            <TeamBarChart
              graphHeading={"Gait"}
              data={gaitData}
              label={"Ground Contact Time"}
              selectedGroups={groupsInSelectedTeam}
              selectedAthletes={selectedForGraphComparison.athletesSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    organisation: selectOrganisation(state),
    userList: selectUserList(state),
    teamList: selectedTeamList(state),
    groupList: selectedGroupList(state),
    teamAthletesList: selectedTeamAthleteList(state),
    selectedOrgForTeams: selectedOrgForTeams(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getOrganisationsToolkit,
      getUsers,
      getTeamGroups,
      getTeamsAthletes,
      getTeams,
    },
    dispatch
  ),
});

//exporting and connecting component to redux
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamsPage)
);
