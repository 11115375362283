/* eslint-disable */
import "../styles/team-management.scss";
import { useDispatch, useSelector } from "react-redux";
import TeamManagementProfileBar from "./common/team-management-components/team-management-bar/TeamManagementProfileBar";
import { useEffect, useState } from "react";
import { getTeams } from "store/teamSlice";
import TeamManagementContainer from "./common/team-management-components/team-management-container/TeamManagementContainer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getOrganisationsToolkit,
  removePlayerFromOrg,
  removeCoach,
} from "store/orgSlice";
import { getUsers, selectUserList } from "store/adminSlice";
import { selectedTeamList } from "store/teamSlice";
import {
  getTeamGroups,
  addTeamGroup,
  selectedGroupList,
  deleteGroup,
  resetTeamGroupData,
} from "store/groupSlice";
import { selectOrganisation } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import { addTeam, deleteTeam } from "store/teamSlice";
import {
  setSelectedOrgForTeam,
  selectedOrgForTeams,
} from "store/selectedOrgForTeamsSlice";
import { resetTeamAthletesData } from "store/teamsAthletesSlice";

const ManageTeamsPage = (props) => {
  const { organisation, actions, user, teamList, groupList } = props;

  const [org, setOrg] = useState(null);
  const [listOfTeams, setListOfTeams] = useState(null);
  const [isEditingTeams, setIsEditingTeams] = useState(false);
  const [selectedOrgIdData, setSelectedOrgIdData] = useState(0);

  const { status, teams } = useSelector((state) => state.team);
  const { orgID } = useSelector((state) => state.selectedOrgForTeam);

  const [selectedOrgID, setSelectedOrgID] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user.permissionLevel === 1 &&
      orgID &&
      !Number(localStorage.getItem("selectedOrgID"))
    ) {
      setSelectedOrgID(orgID);
    }
    if (
      user.permissionLevel === 1 &&
      Number(localStorage.getItem("selectedOrgID")) &&
      Number(localStorage.getItem("selectedOrgID")) > 0
    ) {
      setSelectedOrgID(Number(localStorage.getItem("selectedOrgID")));
    }
  }, [orgID]);

  useEffect(() => {
    const selectedOrgData =
      selectedOrgID === 0
        ? 0
        : org?.map((org) => org.OrgID)?.indexOf(selectedOrgID);

    setSelectedOrgIdData(selectedOrgData);
  }, [selectedOrgID, orgID]);

  //use effect for innitial fetch of data for the page
  useEffect(() => {
    const fetchData = () => {
      if (status === "idle" && teams.length === 0) {
        dispatch(getTeams());
      }
    };
    fetchData();
    if (!organisation?.orgList?.length > 0 && user.id) {
      actions.getOrganisationsToolkit(user.id);
    }
  }, [orgID]);

  //if page is refreshed or org changes set the new orglist in here
  useEffect(() => {
    setOrg(organisation.orgList);
  }, [organisation, orgID]);

  //if page is refreshed or team list changes set the new orglist in here
  useEffect(() => {
    setListOfTeams(
      teamList.teams.filter((team) => {
        if (orgID) {
          return team.orgid === orgID;
        } else {
          return team.orgid;
        }
      })
    );
  }, [teamList, orgID]);

  useEffect(() => {
    let orgIdData =
      selectedOrgID === 0
        ? 0
        : org?.map((org) => org.OrgID)?.indexOf(selectedOrgID);
    setSelectedOrgIdData(orgIdData);
  }, [selectedOrgID, orgID, org]);

  //adding team to org
  const addTeamHandler = async (postBody) => {
    const teamAddition = await actions.addTeam(postBody);
    if (teamAddition?.meta?.requestStatus) {
      return teamAddition.meta.requestStatus;
    } else {
      return "error";
    }
  };

  const deleteTeamHandler = async (teamID) => {
    const teamDeletion = await actions.deleteTeam(teamID);
    if (teamDeletion?.meta?.requestStatus) {
      return teamDeletion.meta.requestStatus;
    } else {
      return "error";
    }
  };

  const handleOrgSelectionForTeams = (orgID) => {
    setTimeout(() => {
      actions.resetTeamAthletesData();
      actions.resetTeamGroupData();
    }, 100);
    dispatch(setSelectedOrgForTeam(orgID));
    localStorage.setItem("selectedOrgID", orgID);
  };


  return (
    <div className="team-management-container">
      <TeamManagementProfileBar
        orgData={org?.[selectedOrgIdData]}
        orgName={org?.[selectedOrgIdData]?.Name}
        orgImage={org?.[selectedOrgIdData]?.iconURL}
        listOfTeams={listOfTeams}
        isEditingTeams={isEditingTeams}
        setIsEditingTeams={setIsEditingTeams}
        groupList={groupList?.groups}
        userPermissionLevel={user?.permissionLevel}
        orgList={org}
        handleOrgSelectionForTeams={handleOrgSelectionForTeams}
        selectedOrgID={selectedOrgID}
      />
      <TeamManagementContainer
        listOfTeams={listOfTeams}
        groupList={groupList?.groups}
        orgData={org?.[selectedOrgIdData]}
        addTeamHandler={addTeamHandler}
        isEditingTeams={isEditingTeams}
        deleteTeamHandler={deleteTeamHandler}
        teamList={teamList}
        selectedOrgIdData={selectedOrgIdData}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    organisation: selectOrganisation(state),
    userList: selectUserList(state),
    teamList: selectedTeamList(state),
    groupList: selectedGroupList(state),
    selectedOrgForTeams: selectedOrgForTeams(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getOrganisationsToolkit,
      getUsers,
      addTeam,
      addTeamGroup,
      deleteTeam,
      removePlayerFromOrg,
      removeCoach,
      getTeamGroups,
      deleteGroup,
      resetTeamGroupData,
      resetTeamAthletesData,
    },
    dispatch
  ),
});

//exporting and connecting component to redux
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageTeamsPage)
);
