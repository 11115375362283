import { ReferenceArea } from "recharts";


export default function mappingTrimAreaX(coords) {
    const trimAreaColour = "white";
    return (
      <ReferenceArea
        x1={coords[0]}
        x2={coords[1]}
        fill={trimAreaColour}
        opacity={0.2}
      />
    );
  }