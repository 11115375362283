/* eslint-disable */

import Button from "components/common/button/button";
import LockIcon from "components/common/icons/lock";

export default function MetricTable(props) {
  const {
    graphName,
    tableData,
    openSession,
    sessionLocked = false,
    graphIsOpen,
    toggleGraph,
  } = props;
  let date = "";
  let sessionID = "";
  let assymValue = "";

	//double leg jump variables declaration
	let jumpHeight = "";
	let doubleLegFlightTime = "";
	let doubleLegPeakPower = "";

  //rsi variables declaration
  let rsi = "";
  let rsiContactTime = "";
  let rsiJumpHeight = "";
  let rsiFlightTime = "";

  let rightFootHeight = "";
  let leftFootHeight = "";
  let dutyFactor = "";
  let standardDeviation = "";
  let cadence = "";


  if (tableData != null && tableData.length > 0) {
    date = tableData[0].payload.date;
    sessionID =
      tableData[0].payload.sessionID || tableData[0].payload.sessionid;
    assymValue = tableData[0].payload.assymVal || tableData[0].payload.value;
		//double leg countermovement jump data
		jumpHeight = tableData[0].payload.value;
		doubleLegFlightTime = tableData[0].payload.flightTime;
		doubleLegPeakPower = tableData[0].payload.peakPower;

    //rsi data --------------------------------------------------------------------------------
    rsi = tableData[0].payload.value;
    rsiContactTime = tableData[0].payload.contactTime;
    rsiJumpHeight = tableData[0].payload.jumpHeight;
    rsiFlightTime = tableData[0].payload.flightTime;
    //Other Data ------------------------------------------------------------------------
    rightFootHeight = tableData[0].payload.rightValue;
    leftFootHeight = tableData[0].payload.leftValue;
    dutyFactor = tableData[0].payload.meanSSR;
    standardDeviation = tableData[0].payload.stdSSR;
    cadence = tableData[0].payload.cadence;
  }

  return (
    <>
      {tableData?.length > 0 && (
        <div className="metric-table-container">
          <div
            className={`metric-table metric-table ${
              graphIsOpen ? "table-open" : ""
            }`}
          >
            <div className="metric-table-section metric-table-date">
              {date && <h1>{date}</h1>}
              {!date && tableData[0]?.payload?.sessionID && (
                <h1>{tableData[0].payload.sessionID}</h1>
              )}
            </div>

            <div className="metric-table-section metric-table-summary">
              <h2 className="metric-summary-title">Session Summary</h2>
              {sessionLocked ? (
                <div className="lock-icon">
                  <LockIcon />
                </div>
              ) : (
                <div className="lock-icon"></div>
              )}
            </div>

            <div className="metric-table-section metric-section-format">
              <p>Session ID:</p>
              <p>{sessionID}</p>
            </div>
            {graphName?.name === "Double Leg Countermovement jump" && (
              <>
                <div className="metric-table-section metric-section-format">
                  <p>Height:</p>
                  <p>{jumpHeight} cm</p>
                </div>
								<div className="metric-table-section metric-section-format">
                  <p>Flight Time:</p>
                  <p>{doubleLegFlightTime} s</p>
                </div>
								<div className="metric-table-section metric-section-format">
                  <p>Peak Power:</p>
                  <p>{doubleLegPeakPower} w</p>
                </div>
              </>
            )}

            {graphName?.name === "Single Leg Countermovement Jump" && (
              <>
                <div className="metric-table-section metric-section-format">
                  <p>Right Foot: </p>
                  <p>{rightFootHeight?.toFixed(1)} cm</p>
                </div>
                <div className="metric-table-section metric-section-format">
                  <p>Left Foot: </p>
                  <p>{leftFootHeight?.toFixed(1)} cm</p>
                </div>
                <div className="metric-table-section metric-section-format">
                  <p>Asymetry {assymValue > 0 ? "Right:" : "Left:"}</p>
                  <p>
                    {assymValue?.toFixed(1) > 0
                      ? assymValue?.toFixed(1)
                      : assymValue?.toFixed(1) * -1}{" "}
                    %
                  </p>
                </div>
              </>
            )}

            {graphName?.name === "Double Leg Repeated Hops" && (
              <>
                <div className="metric-table-section metric-section-format">
                  <p>RSI:</p>
                  <p>{rsi}</p>
                </div>
                <div className="metric-table-section metric-section-format">
                  <p>Contact Time:</p>
                  <p>{rsiContactTime} s</p>
                </div>
                <div className="metric-table-section metric-section-format">
                  <p>Flight Time:</p>
                  <p>{rsiFlightTime} s</p>
                </div>
                <div className="metric-table-section metric-section-format">
                  <p>Jump Height:</p>
                  <p>{rsiJumpHeight} cm</p>
                </div>
              </>
            )}

            {graphName === "Duty Factor" && (
              <>
                <div className="metric-table-section metric-section-format">
                  <p>Duty Factor:</p>
                  <p>{dutyFactor?.toFixed(2)}</p>
                </div>
                <div className="metric-table-section metric-section-format">
                  <p>Standard Deviation:</p>
                  <p>{standardDeviation?.toFixed(3)}</p>
                </div>
                <div className="metric-table-section metric-section-format">
                  <p>Cadence:</p>
                  <p>{cadence?.toFixed(2) * 2}</p>
                </div>
              </>
            )}

            {graphName === "GroundContactTimeAsymmetry" && (
              <div className="metric-table-section metric-section-format">
                <p>Asymetry {assymValue > 0 ? "Right:" : "Left:"}</p>
                <p>
                  {assymValue?.toFixed(1) > 0
                    ? assymValue?.toFixed(1)
                    : assymValue?.toFixed(1) * -1}{" "}
                  %
                </p>
              </div>
            )}

            {/* {dutyFactor && dutyFactor !== null &&
                    <div className="metric-table-section metric-section-format">
                        <p>Duty Factor:</p>
                        <p>{dutyFactor?.toFixed(2)}</p>
                    </div>}
                    {standardDeviation &&
                    <div className="metric-table-section metric-section-format">
                        <p>Standard Deviation:</p>
                        <p>{standardDeviation.toFixed(3)}</p>
                    </div>}

                    {cadence && cadence !== null &&
                    <div className="metric-table-section metric-section-format">
                        <p>Cadence:</p>
                        <p>{cadence?.toFixed(2)}</p>
                    </div>}

                   {leftFoot &&
                    <div className="metric-table-section metric-section-format">
                        <p>Left Foot:</p>
                        <p>{leftFoot} cm</p>
                    </div>}
                    {rightFoot &&
                    <div className="metric-table-section metric-section-format">
                        <p>Right Foot:</p>
                        <p>{rightFoot} cm</p>
                    </div>}
                    {asymValue && asymValue !== 0 && !sdN ? 
                        <div className="metric-table-section metric-section-format"> 
                            <p>Asymetry {asymValue > 0 ? "Right" : asymValue < 0 ? "Left" : "Equal"}</p> 
                            <p>{asymValue } %</p> 
                        </div> : null}
                    
                    {sdN? 
                    <div className="metric-table-section metric-section-format"> 
                        <p>Height</p> 
                        <p>{asymValue } cm</p> 
                    </div> : null}

                    {rsi? 
                    <div className="metric-table-section metric-section-format"> 
                        <p>RSI</p> 
                        <p>{rsi }</p> 
                    </div> : null} */}

            <div className="metric-table-section metric-table-session-button">
              <div onClick={() => openSession(sessionID)}>
                <Button value={"Open Session"} />
              </div>
            </div>
          </div>
          <div
            className={`metric-table-toggle-button`}
            onClick={() => {
              toggleGraph();
            }}
          >
            <div>{"="}</div>
          </div>
        </div>
      )}

      {tableData === null ||
        (tableData?.length === 0 && (
          <div className="metric-table-container">
            <div
              className={`metric-table metric-table- ${
                graphIsOpen ? "table-open" : ""
              }`}
            >
              <div className="metric-table-section metric-table-no-data-selected">
                Please select or hover section of the graph to view more details
              </div>
            </div>
            <div
              className={`metric-table-toggle-button`}
              onClick={() => {
                toggleGraph();
              }}
            >
              <div>{"="}</div>
            </div>
          </div>
        ))}
    </>
  );
}
