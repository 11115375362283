const SwapIcon = (props) => {
  const { size = "16", fillOpacity = "0.6", color = "white" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fillOpacity={fillOpacity}
    >
      <path
        fillOpacity={fillOpacity}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7443 8.76692C10.8942 8.91677 11.1108 9.00835 11.3356 9.00835L11.319 8.98337C11.5355 8.98337 11.752 8.8918 11.9102 8.74195L15.0924 5.56088C15.336 5.41519 15.5 5.14874 15.5 4.84586C15.5 4.75575 15.486 4.66938 15.4601 4.58866C15.4223 4.45916 15.3522 4.33788 15.2497 4.23812V4.24644L11.9186 0.916437C11.5855 0.583437 11.0608 0.583437 10.736 0.916437C10.4029 1.24111 10.4029 1.76559 10.736 2.09859L12.6573 4.01336H6.3393C5.87294 4.01336 5.50651 4.37966 5.50651 4.84586C5.50651 5.30373 5.87294 5.67836 6.3393 5.67836H12.6405L10.736 7.57644V7.58477C10.4112 7.90944 10.4112 8.44225 10.7443 8.76692ZM4.67333 17.3334C4.44848 17.3334 4.23196 17.2418 4.08205 17.0919L0.750888 13.7619C0.417772 13.4372 0.417772 12.9045 0.74256 12.5798V12.5714L4.07373 9.24144C4.39851 8.90844 4.92317 8.90844 5.25629 9.24144C5.58108 9.56612 5.58108 10.0906 5.25629 10.4236L3.33503 12.3384H8.83768C9.29571 12.3384 9.67047 12.7047 9.67047 13.1709C9.67047 13.6287 9.29571 14.0034 8.83768 14.0034H3.35177L5.25629 15.9015C5.58108 16.2178 5.58108 16.7506 5.25629 17.0753H5.24796C5.08973 17.2251 4.8732 17.3167 4.65668 17.3167L4.67333 17.3334Z"
        fill={color}
      />
    </svg>
  );
};

export default SwapIcon;
