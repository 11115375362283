//import moment from 'moment';
import moment from "moment-timezone";
const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export function isValidEmail(email) {
  return regex.test(email);
}

export function getSessionFromList(id, sessionList) {
  if (!id) {
    return;
  }
  let thisSession;
  if (sessionList?.length >= 1) {
    thisSession = sessionList.find((s) => s.ID === parseInt(id));
  }
  return thisSession;
}

export function getSessionFromList2(id, sessionList) {
  if (!id) {
    console.log(`${id} not found`);
    return;
  }
  let thisSession;
  if (sessionList.length >= 1) {
    thisSession = sessionList.find((s) => s.ID === parseInt(id));
  }

  return thisSession;
}

export function displayDatePart(dbDate, part) {
  const clientTimezone = moment.tz.guess();
  let date = moment(dbDate).tz(clientTimezone).format("DD-MM-YYYY HH:mm:ss");

  if (part > -1) {
    let dateParts = date.split(" ");
    return dateParts[part];
  } else {
    return date;
  }

  //return date
  //return moment(new Date(dbDate)).format("DD-MM-YYYY hh:mm:ss")
}

export function displayDate(dbDate, part) {
  const clientTimezone = moment.tz.guess();
  let date = moment(dbDate).tz(clientTimezone).format("DD-MM-YYYY HH:mm:ss");

  if (part && part > -1) {
    let dateParts = date.split(" ");
    return dateParts[part];
  } else {
    return date;
  }

  //return date
  //return moment(new Date(dbDate)).format("DD-MM-YYYY hh:mm:ss")
}

export function displayDateForPlayerList(dbDate) {
  return moment(new Date(dbDate)).format("DD/MM/YYYY");
}

export function getHistoricalChartsYAxisTitle(activityID) {
  if (
    activityID === 4 ||
    activityID === 14 ||
    activityID === 9 ||
    activityID === 15
  ) {
    return "Height (cm)";
  } else if (
    activityID === 5 ||
    activityID === 16 ||
    activityID === 11 ||
    activityID === 18 ||
    activityID === 10 ||
    activityID === 17
  ) {
    return "REACTIVE STRENGTH INDEX (RSI)";
  }
  return "";
}

export function getHistoricalChartsTitle(activityID) {
  if (
    activityID === 4 ||
    activityID === 14 ||
    activityID === 9 ||
    activityID === 15
  ) {
    return "Jump Height";
  } else if (
    activityID === 5 ||
    activityID === 16 ||
    activityID === 11 ||
    activityID === 18 ||
    activityID === 10 ||
    activityID === 17
  ) {
    return "Reactive Strength Index (RSI)";
  }
  return "";
}

export function getHistoricalChartsMeasurement(activityID) {
  if (
    activityID === 4 ||
    activityID === 14 ||
    activityID === 9 ||
    activityID === 15
  ) {
    return "cm";
  } else if (
    activityID === 5 ||
    activityID === 16 ||
    activityID === 11 ||
    activityID === 18 ||
    activityID === 10 ||
    activityID === 17
  ) {
    return "";
  }
  return "";
}

export function renderCustomAxisTickForFlippedChart({ x, y, payload }) {
  let returnVal = payload.value;
  if (payload.value < 0) {
    returnVal = payload.value * -1;
  }
  //let path = 'M662.528 451.584q10.24 5.12 30.208 16.384t46.08 31.744 57.856 52.736 65.024 80.896 67.072 115.2 64.512 154.624q-15.36 9.216-31.232 21.504t-31.232 22.016-31.744 15.36-32.768 2.56q-44.032-9.216-78.336-8.192t-62.976 7.68-53.248 16.896-47.616 19.968-46.08 16.384-49.664 6.656q-57.344-1.024-110.592-16.896t-101.376-32.256-89.6-25.088-75.264 4.608q-20.48 8.192-41.984 1.024t-38.912-18.432q-20.48-13.312-39.936-33.792 37.888-116.736 86.016-199.68t92.672-136.704 78.848-81.408 43.52-33.792q9.216-5.12 10.24-25.088t-1.024-40.448q-3.072-24.576-9.216-54.272l-150.528-302.08 180.224-29.696q27.648 52.224 53.76 79.36t50.176 36.864 45.568 5.12 39.936-17.92q43.008-30.72 80.896-103.424l181.248 29.696q-20.48 48.128-45.056 99.328-20.48 44.032-47.616 97.28t-57.856 105.472q-12.288 34.816-13.824 57.344t1.536 36.864q4.096 16.384 12.288 25.6z';

  return (
    <svg x={x - 12} y={y + 4} width={24} height={24}>
      <rect x="0" y="0" width="24" height="24" fill="white" />
      <foreignObject x="0" y="0" width="24" height="24">
        <body xmlns="http://www.w3.org/1999/xhtml">
          <div>{returnVal}</div>
        </body>
      </foreignObject>
    </svg>
  );
}

export function scaleGraphHorizontalTicks(numberOfTicks) {
  const width = window.innerWidth;
  if (width >= 820 && numberOfTicks < 8) {
    return 1;
  } else {
    return 2;
  }
}

//remove duplicate objects from array that has same id's
export function removeDuplicates(arr) {
  let unique = {};
  let result = [];

  for (let i = 0; i < arr.length; i++) {
    if (!unique[arr[i].id]) {
      result.push(arr[i]);
      unique[arr[i].id] = true;
    }
  }

  return result;
}

export function simplifyArray(arr) {
  if (!arr.includes(undefined)) {
    let result = {
      "12MonthAvg": null,
      "12MonthMaxAvg": null,
      "12MonthAvgAvg": null,
      "12MonthMax": null,
      "12MonthMin": null,
      "24HourAvg": null,
      "24HourMaxAvg": null,
      "24HourAvgAvg": null,
      "24HourMax": null,
      "24HourMin": null,
      "30DayAvg": null,
      "30DayMaxAvg": null,
      "30DayAvgAvg": null,
      "30DayMax": null,
      "30DayMin": null,
      "90DayAvg": null,
      "90DayMaxAvg": null,
      "90DayAvgAvg": null,
      "90DayMax": null,
      "90DayMin": null,
      "180DayAvg": null,
      "180DayMaxAvg": null,
      "180DayAvgAvg": null,
      "180DayMax": null,
      "180DayMin": null,
      AllTimeAvg: null,
      AllTimeMaxAvg: null,
      AllTimeAvgAvg: null,
      AllTimeMax: null,
      AllTimeMin: null,
      WeekAvg: null,
      WeekMaxAvg: null,
      WeekAvgAvg: null,
      WeekMax: null,
      WeekMin: null,
      lastAvg: null,
      lastMax: null,
      lastMin: null,
    };

    for (let key in result) {
      let values = arr
        .map((obj) => obj?.[key])
        .filter((val) => val !== null && val !== undefined);

      if (values.length > 0) {
        if (key?.includes("Max")) {
          result[key] = Math.max(...values);
        } else if (key?.includes("Min")) {
          result[key] = Math.min(...values);
        } else if (key?.includes("Avg")) {
          result[key] = values.reduce((a, b) => a + b, 0) / values.length;
        }
      }
    }

    return result;
  } else {
    return {};
  }
}

export function averageObjects(arr) {
  if (
    !Array?.isArray(arr) ||
    arr?.length === 0 ||
    typeof arr?.[0] !== "object"
  ) {
    // throw new Error(
    //   "Invalid input: arr must be an array containing at least one object"
    // );
  }

  const objectTestLayout = {
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    10: {},
    14: {},
    load: {},
    gait: {},
  };
  let resultLayout = {
    "12MonthAvg": null,
    "12MonthMaxAvg": null,
    "12MonthAvgAvg": null,
    "12MonthMax": null,
    "12MonthMin": null,
    "24HourAvg": null,
    "24HourMaxAvg": null,
    "24HourAvgAvg": null,
    "24HourMax": null,
    "24HourMin": null,
    "30DayAvg": null,
    "30DayMaxAvg": null,
    "30DayAvgAvg": null,
    "30DayMax": null,
    "30DayMin": null,
    "90DayAvg": null,
    "90DayMaxAvg": null,
    "90DayAvgAvg": null,
    "90DayMax": null,
    "90DayMin": null,
    "180DayAvg": null,
    "180DayMaxAvg": null,
    "180DayAvgAvg": null,
    "180DayMax": null,
    "180DayMin": null,
    AllTimeAvg: null,
    AllTimeMaxAvg: null,
    AllTimeAvgAvg: null,
    AllTimeMax: null,
    AllTimeMin: null,
    WeekAvg: null,
    WeekMaxAvg: null,
    WeekAvgAvg: null,
    WeekMax: null,
    WeekMin: null,
    lastAvg: null,
    lastMax: null,
    lastMin: null,
  };

  let result = {};
  let keys = Object.keys(objectTestLayout);
  for (let key of keys) {
    result[key] = {};

    let subKeys = Object.keys(resultLayout);
    for (let subKey of subKeys) {
      let sum = 0;
      let count = 0;
      for (let obj of arr) {
        if (
          obj[key] &&
          obj[key][subKey] !== null &&
          obj[key][subKey] !== undefined
        ) {
          sum += obj[key][subKey];
          count++;
        }
      }
      result[key][subKey] = count > 0 ? sum / count : null;
    }
  }
  return result;
}

// A function that converts milliseconds to minutes
const formatMinutes = (value) => {
  return ((value % (60 * 60 * 1000)) / 60).toFixed(1);
};

// A function that decides which format to use based on the value
export const formatTime = (value, data) => {
  if (data?.["Time"] >= 300 || data?.["name"] >= 300 || data?.["ts"] >= 300) {
    // If the value is over 5 minutes, use minutes format
    return formatMinutes(value);
  } else {
    // Otherwise, use seconds format
    return value;
  }
};

export function msToTime(value) {
  // Calculate the hour, minute, second and millisecond components from the value
  let hour = Math.floor(value / (60 * 60 * 1000));
  let minute = Math.floor((value % (60 * 60 * 1000)) / (60 * 1000));
  let second = Math.floor((value % (60 * 1000)) / 1000);
  let millisecond = value % 1000;

  // Pad the components with leading zeros if necessary
  let hourString = hour.toString().padStart(2, "0");
  let minuteString = minute.toString().padStart(2, "0");
  let secondString = second.toString().padStart(2, "0");
  let millisecondString = millisecond.toString().padStart(3, "0");

  // Concatenate the components with spaces and return the string
  return (
    hourString +
    ":" +
    minuteString +
    ":" +
    secondString +
    ":" +
    millisecondString
  );
}

export const filterObjectsAlpabeticallyByKey = (array, key) => {
  //takes in an array, and a key
  let newArray = array.sort((a,b)=>{
    if (a?.[key].toLowerCase() < b?.[key].toLowerCase()) {
      return -1;
    }
    if (a?.[key].toLowerCase() > b?.[key].toLowerCase()) {
      return 1;
    }
    return 0;
  })

  return newArray
}