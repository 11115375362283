/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "react-vis/dist/style.css";
import { bindActionCreators } from "redux";
import {
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  Bar,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_BOTH_FEET_JUMP_COLOR,
} from "../../utils/constant";
import {
  gctMax,
  gctMin,
  getAverage,
  getSymValue,
  CustomTooltipForJumpCharts,
} from "../../utils/metrics";
import { selectSessionList2 } from "store/sessionListSlice";
import { getSessionList } from "store/sessionListSlice";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import MaxMinMean from "./metric-helpers/MaxMinMean";
import GraphSettings from "./metric-helpers/GraphSettings";
import { selectSession } from "store/sessionSlice";
import mappingTrimAreaX from "./metric-helpers/mappingTrimArea";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

class JumpLength extends React.Component {
  constructor(props) {
    super(props);

    let leftData = [];
    let rightData = [];

    this.state = {
      //hidden: true,
      hidden: false, //default to show data
      leftData: leftData,
      rightData: rightData,
      data: [],
      stepData: [],
      averageStepData: [],
      hoveredLeft: null,
      hoveredRight: null,
      loading: false,
      chartType: { graph: "line" },
      graphSettingsLoaction: "left-side",
    };
  }

  filterAverageData(arr, filterValue) {
    if (this.props.graphDomain) {
      return arr.filter(
        (item) =>
          item[filterValue] >= this.props.graphDomain[0] &&
          item[filterValue] <= this.props.graphDomain[1]
      );
    } else {
      return arr;
    }
  }

  setGraphType = (graphType) => {
    this.setState({
      chartType: { graph: graphType },
    });
  };

  displayCharts() {
    const { hidden } = this.state;
    const { graphDomain, session } = this.props;

    //if (loading)
    if (!hidden && session?.jumpData?.status === "loading")
      return displayLoadingCharts();
    else if (
      !hidden &&
      (session.jumpData.data.spatials?.jumpLength &&
        session.jumpData.data.spatials?.jumpLength.bar.length > 0) !== true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (!hidden && session.jumpData.data.spatials.jumpLength) {
      return (
        <div className="metric-container">
          <div className="metric-chart-container-parent ">
            <div className="chart-container-padding">
              <div className="chart-container">
                {session.jumpData.data.spatials.jumpLength.leftDataExists &&
                  session.jumpData.data.spatials.jumpLength.rightDataExists && (
                    <MaxMinMean
                      leftFoot={{
                        hasData:
                          session.jumpData.data.spatials.jumpLength
                            .leftDataExists,
                        min: gctMin(
                          "left",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        ),
                        max: gctMax(
                          "left",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        ),
                        mean: getAverage(
                          "left",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        ),
                      }}
                      rightFoot={{
                        hasData:
                          session.jumpData.data.spatials.jumpLength
                            .rightDataExists,
                        min: gctMin(
                          "right",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        ),
                        max: gctMax(
                          "right",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        ),
                        mean: getAverage(
                          "right",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        ),
                      }}
                    />
                  )}
                {session.jumpData.data.spatials.jumpLength
                  .bothFeetDataExists && (
                  <MaxMinMean
                    footSymmetry={{
                      hasData:
                        session.jumpData.data.spatials.jumpLength
                          .bothFeetDataExists,
                      min: getSymValue(
                        gctMin(
                          "both",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        )
                      ),
                      max: getSymValue(
                        gctMax(
                          "both",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        )
                      ),
                      mean: getSymValue(
                        getAverage(
                          "both",
                          this.filterAverageData(
                            session.jumpData.data.spatials.jumpLength.bar,
                            "ts"
                          )
                        )
                      ),
                    }}
                  />
                )}

                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={session.jumpData.data.spatials.jumpLength.bar}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid
                      strokeDasharray="4 4"
                      stroke={NEW_AXIS_COLOR}
                      vertical={false}
                      strokeWidth={2}
                    />
                    {session.jumpData.data.spatials.jumpLength.bar[0].hasOwnProperty(
                      "ts"
                    ) && session.jumpData.data.td.length > 0 ? (
                      <XAxis
                        type="number"
                        domain={this.props.graphDomain}
                        dataKey={"ts"}
                        label={{
                          value: "Rep",
                          position: "insideBottom",
                          offset: 0,
                          fill: NEW_AXIS_TICK,
                        }}
                        allowDataOverflow={true}
                        stroke={NEW_AXIS_COLOR}
                        tick={false}
                      />
                    ) : (
                      <XAxis
                        dataKey="name"
                        label={{
                          value: "Rep",
                          position: "insideBottom",
                          offset: 0,
                          fill: NEW_AXIS_TICK,
                        }}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                      />
                    )}

                    <YAxis
                      label={{
                        value: "Meters",
                        angle: -90.1,
                        position: "insideLeft",
                        dy: 35,
                        fill: NEW_AXIS_TICK,
                      }}
                      tick={{ fill: NEW_AXIS_TICK }}
                      stroke={NEW_AXIS_COLOR}
                      domain={[
                        0,
                        (dataMax) => ((dataMax / 100) * 110).toFixed(3),
                      ]}
                    />

                    {/* <Legend wrapperStyle={{position: 'relative', marginTop: '0px', marginLeft: '20px'}} margin={{ top: 20, left: 100, right: 0, bottom: 0}} /> */}
                    <Legend
                      verticalAlign="top"
                      layout="vertical"
                      wrapperStyle={{ top: -60, right: 0 }}
                    />
                    <Tooltip
                      cursor={{ fill: NEW_AXIS_COLOR, opacity: 0.6 }}
                      content={
                        <CustomTooltipForJumpCharts
                          desc={"m"}
                          data={session.jumpData.data.spatials.jumpLength.bar}
                        />
                      }
                    />
                    {session.jumpData.data.spatials.jumpLength
                      .leftDataExists && (
                      <Bar
                        dataKey="Left Foot"
                        fill={NEW_LEFT_COLOR}
                        barSize={100}
                        opacity={0.7}
                      />
                    )}
                    {session.jumpData.data.spatials.jumpLength
                      .rightDataExists && (
                      <Bar
                        dataKey="Right Foot"
                        fill={NEW_RIGHT_COLOR}
                        barSize={100}
                        opacity={0.7}
                      />
                    )}
                    {session.jumpData.data.spatials.jumpLength
                      .bothFeetDataExists && (
                      <Bar
                        dataKey="Both Feet"
                        fill={NEW_BOTH_FEET_JUMP_COLOR}
                        barSize={100}
                        opacity={0.7}
                      />
                    )}
                    {getSymValue(
                      session.jumpData.data.spatials.jumpLength.Mean
                    ) && (
                      <ReferenceLine
                        stroke={"white"}
                        strokeDasharray="5 5"
                        y={getSymValue(
                          session.jumpData.data.spatials.jumpLength.Mean
                        )}
                      />
                    )}
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div style={{ height: 80 }} />
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { hidden } = this.state;

    let widthOfWindow = window.innerWidth;
    if (
      widthOfWindow > 820 &&
      this.state.graphSettingsLoaction !== "left-side"
    ) {
      this.setState({ graphSettingsLoaction: "left-side" });
    }

    if (
      widthOfWindow <= 820 &&
      this.state.graphSettingsLoaction !== "right-side"
    ) {
      this.setState({ graphSettingsLoaction: "right-side" });
    }

    window.addEventListener("resize", () => {
      widthOfWindow = window.innerWidth;
      if (
        widthOfWindow > 820 &&
        this.state.graphSettingsLoaction !== "left-side"
      ) {
        this.setState({ graphSettingsLoaction: "left-side" });
      }

      if (
        widthOfWindow <= 820 &&
        this.state.graphSettingsLoaction !== "right-side"
      ) {
        this.setState({ graphSettingsLoaction: "right-side" });
      }
    });

    let arrowClass = "";
    if (!hidden) {
      arrowClass = "flip-div";
    }

    return (
      <div className="settings_graph">
        <div className="metric-container-parent ground-clearance">
          <div className="metric-container metric-title-div">
            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-main-title">
              Jump Length [m]
              <div style={{ paddingLeft: "0.5rem" }}>
                <MainTooltip
                  tooltipText={
                    "Jump Length tooltip"
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
              </div>
            </div>

            <div className="metric-title-extra-info">
              {/* <span>00:00:00</span> */}
            </div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>
          </div>

          {this.displayCharts()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessionList: selectSessionList2(state),
    session: selectSession(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getSessionList }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JumpLength);
