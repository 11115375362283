const DeleteIcon = ({ size = "24", color = "#94A3B3"}) => {
  return (
    <svg
      width={size} 
      height={size}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9945 1.85074C12.9182 0.815878 12.0544 0 11 0H7L6.85074 0.00548574C5.81588 0.0818349 5 0.945638 5 2V4H2.01169H1.99054H1L0.883379 4.00673C0.38604 4.06449 0 4.48716 0 5C0 5.55228 0.447715 6 1 6H1.07987L2.00345 17.083L2.00819 17.2507C2.09634 18.7511 3.40232 20 5 20H13L13.1763 19.9949C14.7511 19.9037 16 18.5977 16 17L16.9199 6H17L17.1166 5.99327C17.614 5.93551 18 5.51284 18 5C18 4.44772 17.5523 4 17 4H13V2L12.9945 1.85074ZM11 4V2H7V4H11ZM6 6H3.08649L4 17C4 17.5128 4.38604 17.9355 4.88338 17.9933L5 18H13C13.5155 18 13.9398 17.61 13.9969 17.0414L14.0035 16.917L14.9132 6H12H6ZM7 8C7.51284 8 7.93551 8.38604 7.99327 8.88338L8 9V15C8 15.5523 7.55228 16 7 16C6.48716 16 6.06449 15.614 6.00673 15.1166L6 15V9C6 8.44771 6.44772 8 7 8ZM11.9933 8.88338C11.9355 8.38604 11.5128 8 11 8C10.4477 8 10 8.44771 10 9V15L10.0067 15.1166C10.0645 15.614 10.4872 16 11 16C11.5523 16 12 15.5523 12 15V9L11.9933 8.88338Z"
        fill={color}
      />
    </svg>
  );
};

export default DeleteIcon;
