import PlayerActivity2 from "../PlayerActivity2";

export default function PlayerActivity2Wrapper(props) {
  const { match, history, playerid, fromSessionInput, athleteLoadDate } = props;

  return (
    <>
      <div className="user-profile-content">
        <PlayerActivity2
          match={match}
          history={history}
          fromSessionInput={fromSessionInput ? fromSessionInput : null}
          playerid={playerid && !isNaN(playerid) ? parseInt(playerid) : null}
					athleteLoadDate={athleteLoadDate}
        />
      </div>
    </>
  );
}
