/* eslint-disable */
import { isNull } from "lodash";
import { useEffect, useState } from "react";
import {
  RadarChart,
  PolarGrid,
  Radar,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import {
  NEW_LOW_VALUE,
  NEW_AXIS_TICK,
  NEW_BOTH_FEET_COLOR,
  NEW_HIGH_VALUE,
  NEW_RIGHT_COLOR,
  COMP_ATHLETE_1,
  COMP_ATHLETE_2,
  COMP_ATHLETE_3,
  COMP_ATHLETE_4,
  COMP_ATHLETE_5,
  COMP_ATHLETE_6,
  COMP_ATHLETE_7,
  COMP_ATHLETE_8,
  COMP_ATHLETE_9,
  COMP_ATHLETE_10,
} from "utils/constant";

function tooltipFunction(payload) {
  let elements = [];

  for (let i = 0; i < payload?.length; i++) {
    elements.push(
      <div
        style={{
          color: payload[i].fill,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ margin: "0px 10px" }}>{payload[i]?.name} :</div>
        <div style={{ margin: "0px 10px" }}>
          {payload[i]?.payload["Athlete" + i + "Value"]?.toFixed(3)}
        </div>
      </div>
    );
  }

  return elements;
}

function arrayMean(arr) {
  arr = arr?.filter((item) => item !== null && item !== undefined);
  let mean =
    arr?.reduce((acc, curr) => {
      return acc + curr;
    }, 0) / arr?.length;
  return mean;
}

const formatTicks = (props) => {
  const { payload, x, y, index } = props;

  return (
    <g x={x} y={y}>
      <rect
        x={x - 13}
        y={y - 3}
        fill="#2E4256"
        width={26}
        height={15}
        rx={4}
        className="rect-style"
        position={"middle"}
      />
      <text
        fill="#FAFAFA"
        x={x - 30}
        y={y - 12}
        dy={20}
        dx={30}
        fontSize={10}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    </g>
  );
};

//create an object for each array item and use a method to modify value method
function createObjects(arr, method, fullDataset) {
  let objects = {};
  for (let i = 0; i < arr?.length; i++) {
    objects = {
      ...objects,
      ["Athlete" + i]: method(arr[i], fullDataset),
      ["Athlete" + i + "Value"]: arr[i],
    };
  }

  return objects;
}

function OverviewRadarChart(props) {
  //multiple coulours for different athletes
  const arrayOfColours = [
    COMP_ATHLETE_1,
    COMP_ATHLETE_2,
    COMP_ATHLETE_3,
    COMP_ATHLETE_4,
    COMP_ATHLETE_5,
    COMP_ATHLETE_6,
    COMP_ATHLETE_7,
    COMP_ATHLETE_8,
    COMP_ATHLETE_9,
    COMP_ATHLETE_10,
  ];


  const {
    fullTableData,
    data,
    orgData,
    selectedRow,
    tableDataAcrossOrganisation,
    performanceType,
    timeframe,
    comparisonType = false,
    fullAthleteDataset,
  } = props;
  const [orgDataFormatted, setOrgDataFormatted] = useState([]);
  const [zScoreArray, setZScoreArray] = useState([]);
  const [domain, setDomain] = useState([-8, 8]);


  //needs to run onece at the first render to set the domain of the overview graph
  function setDomainFunc() {
    if (zScoreArray.length > 0) {
      setDomain([
        -1 * Math.max(...zScoreArray) * 1.2,
        Math.max(...zScoreArray) * 1.2,
      ]);
      setZScoreArray([]);
    }
  }

  useEffect(() => {
    const zScoreUnique = [...new Set(zScoreArray)].filter(
      (item) => !isNaN(item)
    );

    if (JSON.stringify(zScoreArray) !== JSON.stringify(zScoreUnique)) {
      setZScoreArray(zScoreUnique);
    }
  }, [zScoreArray]);

  useEffect(() => {
    if (comparisonType === "Athlete") {
      let max = [...new Set(zScoreArray)].filter((item) => !isNaN(item));

      setDomain([
        -1 * Math.max(...max.filter((item) => item !== NaN)) * 1.2,
        Math.max(...max.filter((item) => item !== NaN)) * 1.2,
      ]);
    }
  }, [fullTableData]);

  //if comparison type is not Athlete run function
  if (comparisonType !== "Athlete") {
    setDomainFunc();
  }

  useEffect(() => {
    if (orgData !== undefined || comparisonType === "Athlete") {
      setOrgDataFormatted([
        {
          subject: "DLCMJ",
          A:
            comparisonType !== "Athlete"
              ? zScoreValue(
                  selectedRow?.overview["4"]?.[
                    `${timeframe}${performanceType}`
                  ],
                  tableDataAcrossOrganisation["4"],
                  "Double Leg"
                )
              : null,
          B: 0,
          // B: orgData["4"]?.AllTimeMax,
          activity: "Double Leg Countermovement Jump - Jump Height (cm)",
          unit: "Jump Height",
          value: selectedRow?.overview["4"]?.[`${timeframe}${performanceType}`],
          orgValueUnit: `Team Average`,
          orgValue: arrayMean(tableDataAcrossOrganisation?.["4"]),
          orgAverageValue:
            fullTableData[0]?.overview["4"]?.[`${timeframe}${performanceType}`],
          orgAverageValueTitle: `${
            performanceType === "Max"
              ? "Highest in Team"
              : performanceType === "Avg"
              ? "Average in Team"
              : "Lowest in Team"
          }`,
          orgRecords: zScoreValue(
            fullTableData[0]?.overview["4"]?.[`${timeframe}${performanceType}`],
            tableDataAcrossOrganisation["4"],
            "Double leg orgRecords"
          ),
          ...createObjects(
            tableDataAcrossOrganisation["4"],
            zScoreValue,
            fullAthleteDataset["4"]
          ),
        },
        {
          subject: "SLCMJ",
          A:
            comparisonType !== "Athlete"
              ? zScoreValue(
                  selectedRow?.overview["14"]?.[
                    `${timeframe}${performanceType}`
                  ],
                  tableDataAcrossOrganisation["14"],
                  "Single Leg counter"
                )
              : null,
          B: 0,
          // B: orgData["14"]?.AllTimeMax,
          activity:
            "Single Leg Countermovement Jump - % Asymmetry (Jump Height)",
          unit: "Asymmetry",
          value:
            selectedRow?.overview["14"]?.[`${timeframe}${performanceType}`],
          orgValueUnit: `Team Average`,
          orgValue: arrayMean(tableDataAcrossOrganisation?.["14"]),
          orgAverageValue:
            fullTableData[0]?.overview["14"]?.[
              `${timeframe}${performanceType}`
            ],
          orgAverageValueTitle: `${
            performanceType === "Max"
              ? " Highest in Team"
              : performanceType === "Avg"
              ? "Average in Team"
              : " Lowest in Team"
          }`,
          orgRecords: zScoreValue(
            fullTableData[0]?.overview["14"]?.[
              `${timeframe}${performanceType}`
            ],
            tableDataAcrossOrganisation["14"],
            "Single leg counter org data"
          ),
          ...createObjects(
            tableDataAcrossOrganisation["14"],
            zScoreValue,
            fullAthleteDataset["14"]
          ),
        },
        {
          subject: "RH",
          A:
            comparisonType !== "Athlete"
              ? zScoreValue(
                  selectedRow?.overview["10"]?.[
                    `${timeframe}${performanceType}`
                  ],
                  tableDataAcrossOrganisation["10"],
                  "Repeated Hops"
                )
              : null,
          B: 0,
          // B: orgData["10"]?.AllTimeMax,
          activity: "Repeated Hops - RSI",
          unit: "RSI",
          value:
            selectedRow?.overview["10"]?.[`${timeframe}${performanceType}`],
          orgValueUnit: `Team Average`,
          orgValue: arrayMean(tableDataAcrossOrganisation?.["10"]),
          orgAverageValue:
            fullTableData[0]?.overview["10"]?.[
              `${timeframe}${performanceType}`
            ],
          orgAverageValueTitle: `${
            performanceType === "Max"
              ? " Highest in Team"
              : performanceType === "Avg"
              ? "Average in Team"
              : " Lowest in Team"
          }`,
          orgRecords: zScoreValue(
            fullTableData[0]?.overview["10"]?.[
              `${timeframe}${performanceType}`
            ],
            tableDataAcrossOrganisation["10"],
            "Repeated Hops Org"
          ),
          ...createObjects(
            tableDataAcrossOrganisation["10"],
            zScoreValue,
            fullAthleteDataset["10"]
          ),
        },
        {
          subject: "DJ",
          A:
            comparisonType !== "Athlete"
              ? zScoreValue(
                  selectedRow?.overview["5"]?.[
                    `${timeframe}${performanceType}`
                  ],
                  tableDataAcrossOrganisation["5"],
                  "Drop Jumps"
                )
              : null,
          B: 0,
          // B: orgData["10"]?.AllTimeMax,
          activity: "Drop Jumps - RSI",
          unit: "RSI",
          value: selectedRow?.overview["5"]?.[`${timeframe}${performanceType}`],
          orgValueUnit: `Team Average`,
          orgValue: arrayMean(tableDataAcrossOrganisation?.["5"]),
          orgAverageValue:
            fullTableData[0]?.overview["5"]?.[`${timeframe}${performanceType}`],
          orgAverageValueTitle: `${
            performanceType === "Max"
              ? " Highest in Team"
              : performanceType === "Avg"
              ? "Average in Team"
              : " Lowest in Team"
          }`,
          orgRecords: zScoreValue(
            fullTableData[0]?.overview["5"]?.[`${timeframe}${performanceType}`],
            tableDataAcrossOrganisation["5"],
            "Drop Jump Z score"
          ),
          ...createObjects(
            tableDataAcrossOrganisation["5"],
            zScoreValue,
            fullAthleteDataset["5"]
          ),
        },
        {
          subject: "Load",
          A:
            comparisonType !== "Athlete"
              ? zScoreValue(
                  selectedRow?.overview["load"]?.[
                    `${timeframe}${performanceType}`
                  ],
                  tableDataAcrossOrganisation["load"],
                  "Load"
                )
              : null,
          B: 0,
          // B: orgData["10"]?.AllTimeMax,
          activity: "Load - Daily Load (a.u.)",
          unit: "load",
          value:
            selectedRow?.overview["load"]?.[`${timeframe}${performanceType}`],
          orgValueUnit: `Team Average`,
          orgValue: arrayMean(tableDataAcrossOrganisation?.["load"]),
          orgAverageValue:
            fullTableData[0]?.overview["load"]?.[
              `${timeframe}${performanceType}`
            ],
          orgAverageValueTitle: `${
            performanceType === "Max"
              ? " Highest in Team"
              : performanceType === "Avg"
              ? "Average in Team"
              : " Lowest in Team"
          }`,
          orgRecords: zScoreValue(
            fullTableData[0]?.overview["load"]?.[
              `${timeframe}${performanceType}`
            ],
            tableDataAcrossOrganisation["load"],
            "Org Load"
          ),
          ...createObjects(
            tableDataAcrossOrganisation["load"],
            zScoreValue,
            fullAthleteDataset["load"]
          ),
        },
        {
          subject: "Gait",
          A:
            comparisonType !== "Athlete"
              ? zScoreValue(
                  selectedRow?.overview["gait"]?.[
                    `${timeframe}${performanceType}`
                  ],
                  tableDataAcrossOrganisation["gait"],
                  "Gait"
                )
              : null,
          B: 0,
          // B: orgData["10"]?.AllTimeMax,
          activity: "Gait - % Asymmetry (Ground Contact Time)",
          unit: "",
          value:
            selectedRow?.overview["gait"]?.[`${timeframe}${performanceType}`],
          orgValueUnit: `Team Average`,
          orgValue: arrayMean(tableDataAcrossOrganisation?.["gait"]),
          orgAverageValue:
            fullTableData[0]?.overview["gait"]?.[
              `${timeframe}${performanceType}`
            ],
          orgAverageValueTitle: `${
            performanceType === "Max"
              ? " Highest in Team"
              : performanceType === "Avg"
              ? "Average in Team"
              : " Lowest in Team"
          }`,
          orgRecords: zScoreValue(
            fullTableData[0]?.overview["gait"]?.[
              `${timeframe}${performanceType}`
            ],
            tableDataAcrossOrganisation["gait"],
            "Org Gait"
          ),
          ...createObjects(
            tableDataAcrossOrganisation["gait"],
            zScoreValue,
            fullAthleteDataset["gait"]
          ),
        },
      ]);
    }
  }, [
    orgData,
    selectedRow,
    performanceType,
    timeframe,
    tableDataAcrossOrganisation,
    data,
  ]);

  useEffect(() => {
    if (comparisonType === "Athlete") {
      let max = [...new Set(zScoreArray)].filter((item) => !isNaN(item));

      setDomain([
        -1 * Math.max(...max.filter((item) => item !== NaN)) * 1.2,
        Math.max(...max.filter((item) => item !== NaN)) * 1.2,
      ]);
    } else {
      if (zScoreArray.length > 0) {
        setDomain([
          -1 * Math.max(...zScoreArray) * 1.2,
          Math.max(...zScoreArray) * 1.2,
        ]);
      }
    }
    setZScoreArray([]);
  }, [performanceType]);

  //functins for calculating z scores

  function standardDeviation(arr) {
    //getting mean value
    let mean =
      arr?.reduce((acc, curr) => {
        return acc + curr;
      }, 0) / arr?.length;

    //assign (value - mean) ^ 2 to every item in the array
    arr = arr?.map((i) => {
      return (i - mean) ** 2;
    });

    //sum of array
    let sum = arr?.reduce((acc, curr) => acc + curr, 0);

    //calculate variance
    let variance = sum / arr?.length;

    //retun sandard deviation
    return Math.sqrt(sum / arr?.length);
  }

  function zScoreValue(value, arr, metric) {
    let usedArray = arr;

    if (!value) {
      return domain[0];
    }

    if (usedArray && usedArray.length > 0) {
      usedArray = usedArray?.filter(
        (item) => item !== null && item !== undefined && item !== 0
      );

      let zScore =
        (value - arrayMean(usedArray)) / standardDeviation(usedArray);

      let tempZScore = zScore > 0 ? zScore : -1 * zScore;

      if (!zScoreArray.includes(tempZScore) && tempZScore !== Infinity) {
        setZScoreArray((prevArray) => [...prevArray, tempZScore]);
      }

      return zScore;
    }
  }

  const CustomTooltip = ({ active, payload, label, data }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip-overview">
          <div className="label">{data.activity}</div>
          <div className="sub-label athlete-label">
            <p>
              {"Athlete Performance" + " :"}{" "}
              <span>{data.value?.toFixed(3)}</span>
            </p>
          </div>
          <div className="sub-label team-average-label">
            <p>
              {`Team ${performanceType} ${
                performanceType !== "Avg" ? "Avg" : ""
              } Performance :`}{" "}
              <span>{data.orgValue.toFixed(3)}</span>
            </p>
          </div>
          {performanceType !== "Avg" && (
            <div
              className="sub-label team-label"
              style={{
                color: `${
                  performanceType === "Max" ? NEW_HIGH_VALUE : NEW_LOW_VALUE
                }`,
              }}
            >
              <p>
                {data.orgAverageValueTitle + " :"}{" "}
                <span>{data?.orgAverageValue?.toFixed(3)}</span>
              </p>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  const CustomTooltip2 = ({ active, payload, label, data }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip-overview">
          <div className="label">{data.activity}</div>
          {tooltipFunction(payload)}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer>
      <RadarChart outerRadius={110} data={orgDataFormatted}>
        <PolarGrid stroke={NEW_AXIS_TICK} strokeWidth={1} opacity={0.4} />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis
          stroke={NEW_AXIS_TICK}
          angle={90}
          domain={domain}
          orientation="middle"
          fontSize={10}
          label={{
            position: "bottom",
            fill: NEW_AXIS_TICK,
            offset: 50,
          }}
          axisLine={false}
          tick={false}
          //ticks temp removed------------------
          // tick={formatTicks}
          // tickCount={6}
        />
        {comparisonType !== "Athlete" && (
          <>
            <Radar
              name="Athlete"
              dataKey="A"
              fill={NEW_RIGHT_COLOR}
              stroke={NEW_RIGHT_COLOR}
              fillOpacity={0.6}
            />

            <Radar
              data={orgDataFormatted}
              name="Team Average"
              dataKey="B"
              stroke={NEW_BOTH_FEET_COLOR}
              fill="none"
              strokeOpacity={0.9}
              strokeDasharray="15 8"
              strokeWidth={2}
            />

            {performanceType !== "Avg" && (
              <Radar
                name={`${"All Time " + performanceType}`}
                dataKey="orgRecords"
                stroke={
                  performanceType === "Max" ? NEW_HIGH_VALUE : NEW_LOW_VALUE
                }
                fill="none"
                strokeOpacity={0.9}
                strokeDasharray="15 8"
                strokeWidth={2}
              />
            )}
          </>
        )}

        {comparisonType === "Athlete" && (
          <>
            {fullTableData.map((item, index) => {
              return (
                <Radar
                  name={item.player}
                  dataKey={`Athlete${index}`}
                  stroke={arrayOfColours?.[index]}
                  fill={arrayOfColours?.[index]}
                  fillOpacity={0.05}
                  strokeOpacity={0.9}
                  strokeDasharray="5 1"
                  strokeWidth={1}
                />
              );
            })}
          </>
        )}
        <Legend />
        <Tooltip
          content={
            comparisonType !== "Athlete" ? (
              <CustomTooltip />
            ) : (
              <CustomTooltip2 />
            )
          }
        />
      </RadarChart>
    </ResponsiveContainer>
  );
}

export default OverviewRadarChart;
