export const API_HOST = process.env.REACT_APP_API_HOST;

// export const LEFT_COLOR = '#8884d8'; //also in global.css
// export const RIGHT_COLOR = '#82ca9d'; //also in global.css

// export const LEFT_COLOR = '#1EBAD6'; //also in global.css
// export const RIGHT_COLOR = '#C0C0C8'; //also in global.css
// export const BOTH_FEET_COLOR = '#3B3A4A'; //also in global.css


export const LEFT_COLOR = '#ED5338'; //also in global.css
export const RIGHT_COLOR = '#C0C0C8'; //also in global.css
export const BOTH_FEET_COLOR = '#1EBAD6'; //also in global.css

export const RIGHT_SWING_COLOR = '#786F72';
export const RIGHT_STANCE_COLOR = '#CCBDC2';

export const LEFT_SWING_COLOR = '#9E3725';
export const LEFT_STANCE_COLOR = '#E6764E';


//New graph color variables

//axis colors
export const NEW_AXIS_COLOR = "#34404B"
export const NEW_AXIS_TICK = "#FAFAFA"
//left and right foot colors
export const NEW_LEFT_COLOR = '#9F7DE1';
export const NEW_RIGHT_COLOR = '#49AFC8';
export const NEW_RIGHT_LEFT_MIXED = '#7496D5'
export const NEW_BOTH_FEET_COLOR = '#FFCF74';

//bothfeet jump
export const NEW_BOTH_FEET_JUMP_COLOR = '#49AFC8';

//right swing
export const NEW_RIGHT_SWING_COLOR = '#49AFC8';
export const NEW_RIGHT_STANCE_COLOR = '#4a69bd';

//left swing
export const NEW_LEFT_SWING_COLOR = '#F368E0';
export const NEW_LEFT_STANCE_COLOR = '#9F7DE1';

//high mid low colouts

export const NEW_HIGH_COLOR = '#9F7DE1';
export const NEW_MID_COLOR = '#FFCF74';
export const NEW_LOW_COLOR = '#49AFC8';
export const NEW_ERROR_RANGE_COLOR = '#F65843'

//graph exp values
export const NEW_HIGH_VALUE = '#82ca9d';
export const NEW_LOW_VALUE = '#ED5338';

//athlete comparison graph colouts

export const COMP_ATHLETE_1 = '#F97F51';
export const COMP_ATHLETE_2 = '#1B9CFC';
export const COMP_ATHLETE_3 = '#F8EFBA';
export const COMP_ATHLETE_4 = '#9AECDB';
export const COMP_ATHLETE_5 = '#B33771';
export const COMP_ATHLETE_6 = '#32ff7e';
export const COMP_ATHLETE_7= '#7d5fff';
export const COMP_ATHLETE_8 = '#ffcccc';
export const COMP_ATHLETE_9 = '#f5f6fa';
export const COMP_ATHLETE_10 = '#e84118';

export const TEAM_COLOR_GROUP_1 = "#845EC2";
export const TEAM_COLOR_GROUP_2 = "#FFC75F";
export const TEAM_COLOR_GROUP_3 = "#00C9A7";
export const TEAM_COLOR_GROUP_4 = "#FF9671";
export const TEAM_COLOR_GROUP_5 = "#F9F871";
export const TEAM_COLOR_GROUP_6 = "#4E8397";
export const TEAM_COLOR_GROUP_7 = "#926C00";
export const TEAM_COLOR_GROUP_8 = "#FEFEDF";
export const TEAM_COLOR_GROUP_9 = "#FF6F91";
export const TEAM_COLOR_GROUP_10 = "#D65DB1";

export const ATHLETE_COLOR_GROUP_1 = "#E6F4F1";
export const ATHLETE_COLOR_GROUP_2 = "#FB9039";
export const ATHLETE_COLOR_GROUP_3 = "#8BF8B9";
export const ATHLETE_COLOR_GROUP_4 = "#BF9DDD";
export const ATHLETE_COLOR_GROUP_5 = "#FC84AD";
export const ATHLETE_COLOR_GROUP_6 = "#DB9D70";
export const ATHLETE_COLOR_GROUP_7 = "#7ABA6B";
export const ATHLETE_COLOR_GROUP_8 = "#95B0B7";
export const ATHLETE_COLOR_GROUP_9 = "#812B00";
export const ATHLETE_COLOR_GROUP_10 = "#1EBAD6";


