const EditIcon = (props) => {
  const { size = "12", color = "white" } = props;

  return (
    //   <svg
    //     width={size}
    //     height={size}
    //     viewBox="0 0 16 16"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M15.4922 7.05197C15.0215 3.66525 12.3426 1.01239 8.95149 0.574826C6.33597 0.237339 3.79576 1.28206 2.16728 3.22576L2.16679 2.16668L2.16118 2.0695C2.11305 1.65505 1.76082 1.33335 1.33346 1.33335C0.873219 1.33335 0.500123 1.70644 0.500123 2.16668V5.50001L0.505729 5.5972C0.553867 6.01165 0.906093 6.33335 1.33346 6.33335H4.66679L4.76397 6.32774C5.17842 6.2796 5.50012 5.92738 5.50012 5.50001L5.49452 5.40283C5.44638 4.98838 5.09415 4.66668 4.66679 4.66668L3.1585 4.66713C4.40391 2.91461 6.53687 1.94375 8.7382 2.22779C11.384 2.56919 13.4742 4.639 13.8414 7.28139C13.9047 7.73724 14.3256 8.05543 14.7815 7.99208C15.2374 7.92873 15.5555 7.50783 15.4922 7.05197ZM7.26202 13.7722C4.61617 13.4308 2.52605 11.361 2.15884 8.71864C2.09549 8.26278 1.67458 7.94459 1.21873 8.00795C0.762872 8.0713 0.444684 8.4922 0.508035 8.94805C0.978692 12.3348 3.65758 14.9876 7.04873 15.4252C9.66464 15.7627 12.2052 14.7177 13.8336 12.7734L13.8334 13.8333L13.839 13.9305C13.8872 14.345 14.2394 14.6667 14.6668 14.6667C15.127 14.6667 15.5001 14.2936 15.5001 13.8333V10.5L15.4945 10.4028C15.4464 9.98838 15.0941 9.66668 14.6668 9.66668H11.3334L11.2363 9.67229C10.8218 9.72042 10.5001 10.0726 10.5001 10.5L10.5057 10.5972C10.5538 11.0116 10.9061 11.3333 11.3334 11.3333L12.8411 11.3337C11.5956 13.0857 9.463 14.0562 7.26202 13.7722Z"
    //       fill={color}
    //       fillOpacity="0.7"
    //     />
    //   </svg>

    <svg
      className="feather feather-edit"
      fill="none"
      height={size}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
    </svg>
  );
};

export default EditIcon;
