/* eslint-disable */
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Dot,
  Label,
  Legend,
  Line,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "./teamBarChart.scss";
import {
  TEAM_COLOR_GROUP_1,
  TEAM_COLOR_GROUP_2,
  TEAM_COLOR_GROUP_3,
  TEAM_COLOR_GROUP_4,
  TEAM_COLOR_GROUP_5,
  TEAM_COLOR_GROUP_6,
  TEAM_COLOR_GROUP_7,
  TEAM_COLOR_GROUP_8,
  TEAM_COLOR_GROUP_9,
  TEAM_COLOR_GROUP_10,
  BOTH_FEET_COLOR,
  NEW_AXIS_TICK,
  RIGHT_SWING_COLOR,
  NEW_AXIS_COLOR,
  NEW_BOTH_FEET_COLOR,
  NEW_MID_COLOR,
  NEW_BOTH_FEET_JUMP_COLOR,
} from "utils/constant";
import Toggler from "components/common/toggler/Toggler";
import { useState } from "react";

const groupColours = [
  TEAM_COLOR_GROUP_1,
  TEAM_COLOR_GROUP_2,
  TEAM_COLOR_GROUP_3,
  TEAM_COLOR_GROUP_4,
  TEAM_COLOR_GROUP_5,
  TEAM_COLOR_GROUP_6,
  TEAM_COLOR_GROUP_7,
  TEAM_COLOR_GROUP_8,
  TEAM_COLOR_GROUP_9,
  TEAM_COLOR_GROUP_10,
];

const formatTicks = (props) => {
  const { payload, x, y, index } = props;

  return (
    <g x={x} y={y}>
      <rect
        x={x - 13}
        y={y - 3}
        fill="#2E4256"
        width={26}
        height={15}
        rx={4}
        className="rect-style"
        position={"middle"}
      />
      <text
        fill="#FAFAFA"
        x={x - 30}
        y={y - 12}
        dy={20}
        dx={30}
        fontSize={10}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const TeamBarChart = (props) => {
  //props destructure
  const { graphHeading, data, label, selectedGroups, selectedAthletes } = props;

  //state declaration
  const [graphTypeToggle, setGraphTypeToggle] = useState(false);

  //variable that checks if all athleres in team are assigned to a group
  const allAthletesAssigned =
    data.filter((item) => item.groupID === undefined).length > 0 ? false : true;

  const averageValue = Number(
    (
      data.map((item) => item.value).reduce((a, b) => a + b, 0) / data.length
    ).toFixed(2)
  );

  const graphData = data.map((item) => ({ ...item, average: averageValue }));

  const changeGraphType = (e) => {};

  const formatRadiusTicks = (props) => {
    const { payload, x, y, index } = props;

    return (
      <g x={x} y={y}>
        <text
          fill={
            selectedGroups.some((obj) => obj.id === data?.[index].groupID)
              ? groupColours[
                  selectedGroups.findIndex(
                    (obj) => obj.id === data?.[index].groupID
                  )
                ]
              : BOTH_FEET_COLOR
          }
          x={x - 30}
          y={y - 12}
          dy={15}
          dx={30}
          fontSize={10}
          textAnchor="middle"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div className="team-chart-contianer">
      <div className="team-chart-heading">
        <h1>{graphHeading}</h1>
        <Toggler
          isToggled={graphTypeToggle}
          setIsToggled={setGraphTypeToggle}
          changeToggleDisplayed={changeGraphType}
          togglerHeadings={{ left: "Bar", right: "Radar" }}
        />
      </div>
      <div className="team-chart">
        <ResponsiveContainer width="100%" height="100%">
          {!graphTypeToggle ? (
            <ComposedChart data={graphData} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" stroke={NEW_AXIS_COLOR} />
              <XAxis
                type="number"
                dataKey={"value"}
                label={{
                  value: label,
                  offset: -2,
                  position: "insideBottom",
                  fill: NEW_AXIS_TICK,
                  fontSize: 12,
                  marginBottom: 20,
                  dy: 0,
                }}
                tick={{
                  fill: NEW_AXIS_TICK,
                }}
                dy={1}
              />
              <YAxis
                type="category"
                dataKey={"athleteName"}
                tick={(props) => {
                  const { x, y, payload } = props;

                  // Split the payload value into words
                  const words = payload.value.split(" ");

                  // Assinge base colour of the tick
                  // const colour = NEW_AXIS_TICK;
                  const colour =
                    selectedAthletes.length === 0
                      ? NEW_AXIS_TICK
                      : selectedAthletes.length > 0 &&
                        selectedAthletes.includes(
                          String(
                            graphData.filter(
                              (item) => item.athleteName === payload.value
                            )?.[0].athleteID
                          )
                        )
                      ? NEW_AXIS_TICK
                      : RIGHT_SWING_COLOR;

                  return (
                    <g transform={`translate(${x},${y})`}>
                      {words.map((word, index) => (
                        <text
                          key={index}
                          x={0}
                          y={index * 11} // Adjust this value to change the line spacing
                          dy={-3}
                          textAnchor="end"
                          fill={colour}
                          fontSize={11}
                        >
                          {word}
                        </text>
                      ))}
                    </g>
                  );
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" opacity={1} legendType="none">
                {graphData.map((cell, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        cell.groupID !== undefined &&
                        selectedGroups.some((obj) => obj.id === cell.groupID)
                          ? groupColours[
                              selectedGroups.findIndex(
                                (obj) => obj.id === cell.groupID
                              )
                            ]
                          : BOTH_FEET_COLOR
                      }
                      opacity={
                        selectedAthletes.length === 0
                          ? 1
                          : selectedAthletes.length > 0 &&
                            selectedAthletes.includes(String(cell.athleteID))
                          ? 1
                          : 0.2
                      }
                    />
                  );
                })}
              </Bar>

              <Line
                name="Average"
                legendType="plainline"
                stroke={NEW_AXIS_TICK}
                strokeDasharray="5 5"
              />

              <Line
                name="No Group"
                legendType="square"
                stroke={BOTH_FEET_COLOR}
              />

              {selectedGroups.map((group, index) => (
                <Line
                  name={group.name}
                  legendType="square"
                  fill={groupColours[index]}
                  color={groupColours[index]}
                  stroke={groupColours[index]}
                />
              ))}

              <ReferenceLine
                x={averageValue}
                stroke={NEW_AXIS_TICK}
                dot={false}
                strokeDasharray="5 5"
                opacity={0.6}
                strokeWidth={3}
              />
              <Legend
                wrapperStyle={{
                  paddingTop: "10px",
                }}
              />
            </ComposedChart>
          ) : (
            <RadarChart
              data={graphData}
              outerRadius={window.innerWidth <= 820 ? 100 : 140}
            >
              <PolarRadiusAxis
                stroke={NEW_AXIS_TICK}
                angle={90}
                orientation="middle"
                fontSize={10}
                dataKey={"value"}
                tick={formatTicks}
                tickCount={6}
              />

              <PolarGrid
                stroke={NEW_AXIS_COLOR}
                strokeWidth={1}
                opacity={0.4}
              />

              <PolarAngleAxis dataKey="athleteName" tick={formatRadiusTicks} />

              <Radar
                name="Average"
                legendType="plainline"
                stroke={NEW_AXIS_TICK}
                strokeDasharray="5 5"
              />

              <Radar
                legendType="none"
                dataKey={"value"}
                opacity={0.7}
                fillOpacity={0.7}
                stroke={NEW_BOTH_FEET_JUMP_COLOR}
                fill={NEW_BOTH_FEET_JUMP_COLOR}
                strokeWidth={2}
              />
              <Radar
                legendType="none"
                dataKey={"average"}
                stroke={NEW_AXIS_TICK}
                fill="none"
                strokeWidth={2}
                strokeDasharray={10}
              />

              {selectedGroups.map((group, index) => (
                <Radar
                  name={group.name}
                  legendType="square"
                  fill={groupColours[index]}
                  color={groupColours[index]}
                  stroke={groupColours[index]}
                />
              ))}
              <Legend
                wrapperStyle={{
                  paddingTop: "10px",
                }}
              />
            </RadarChart>
          )}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TeamBarChart;
