import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createOrganisation, deactivateOrgAPI, getUsersAPI, registerPod } from '../api/admin';
import { addAdminToOrgAPI, addCoachToOrgAPI, addOrganisationTagAPI } from '../api/organisations';
import { resetState } from './resetSlice';
import { getOrganisationsToolkit, updateOrg } from './orgSlice';
import { getListOfPodsAPI, removePodAPI } from 'api/admin';

const initialState = {
  orgList: [],
  userList: [],
  podList: []
};


export const getListOfPods = createAsyncThunk('pod/getListOfPods', async () => {
  try {
    const res = await getListOfPodsAPI();
    return res.data;
  } catch (error) {
    return error;
  }
});

export const createNewOrg = createAsyncThunk('admin/createNewOrg', async (newOrg, { dispatch }) => {
  try {
    const res = await createOrganisation(newOrg);
    dispatch(getOrganisationsToolkit(newOrg.userID))
    return res.data;
  } catch (error) {
    return error;
  }
});

export const getUsers = createAsyncThunk('admin/getUsers', async () => {
  try {
    const res = await getUsersAPI();
    return res.data;
  } catch (error) {
    return error;
  }
});

export const addAdminToOrg = createAsyncThunk('admin/addAdminToOrg', async ({ OrgID, postBody }, { dispatch }) => {
  try {
    const res = await addAdminToOrgAPI(OrgID, postBody);
    dispatch(updateOrg(res.data)); // Dispatch an action in organisationSlice to update 
    return res.data;
  } catch (error) {
    return error;
  }
});

export const deactivateOrg = createAsyncThunk('admin/deactivateOrg', async (OrgId, { dispatch }) => {
  try {
    const res = await deactivateOrgAPI(OrgId);
    dispatch(updateOrg(res.data)); // Dispatch an action in organisationSlice to update 
    return res.data;
  } catch (error) {
    return error;
  }
});

export const addCoachToOrg = createAsyncThunk('admin/addCoachToOrg', async ({ OrgID, postBody }) => {
  try {
    const res = await addCoachToOrgAPI(OrgID, postBody);
    return res.data;
  } catch (error) {
    return error;
  }
});

export const removePod = createAsyncThunk('admin/removePod', async (podId) => {
  try {
  
    const res = await removePodAPI(podId);

    const newPodList = res.data;
    return newPodList;
  } catch (error) {
    return error;
  }
});

export const addPod = createAsyncThunk('admin/addPod', async (postBody,{dispatch}) => {
  try {
  
    const res = await registerPod(postBody);
    const newPodList = res.data;
    dispatch(getListOfPods())
    dispatch(getOrganisationsToolkit())
    return newPodList;
  
  } catch (error) {
    return error;
  }
});

export const addTagToOrg = createAsyncThunk('/tags/addTag', async (postBody) => {
  const response = await addOrganisationTagAPI(postBody);
  return response.data;
})





export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    updateOrgList: (state, action) => {
      state.orgList = action.payload;
    },
    updateUserList: (state, action) => {
      state.userList = action.payload;
    },
    getPodList: (state, action) => {
      state.podList = action.payload;
    },
  },
  extraReducers: (builder) => {
    // createNewOrg
    builder.addCase(createNewOrg.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(createNewOrg.fulfilled, (state) => {
      state.status = 'succeeded';
      // Handle additional action after createNewOrg succeeded
    });
    builder.addCase(createNewOrg.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });

    // getUsers
    builder.addCase(getUsers.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.userList = action.payload;
      // Handle additional action after getUsers succeeded
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });

    // addAdminToOrg
    builder.addCase(addAdminToOrg.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(addAdminToOrg.fulfilled, (state) => {
      state.status = 'succeeded';
      // Handle additional action after addAdminToOrg succeeded
    });
    builder.addCase(addAdminToOrg.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });

    // deactivateOrg
    builder.addCase(deactivateOrg.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(deactivateOrg.fulfilled, (state) => {
      state.status = 'succeeded';
      // Handle additional action after deactivateOrg succeeded
    });
    builder.addCase(deactivateOrg.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });

    // addCoachToOrg
    builder.addCase(addCoachToOrg.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(addCoachToOrg.fulfilled, (state) => {
      state.status = 'succeeded';
      // Handle additional action after addCoachToOrg succeeded
    });
    builder.addCase(addCoachToOrg.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });
    builder.addCase(removePod.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(removePod.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.podList = action.payload;
    });
    builder.addCase(removePod.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    });


    builder.addCase(getListOfPods.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(getListOfPods.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.podList = action.payload;
    });
    builder.addCase(getListOfPods.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })


    .addCase(addTagToOrg.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(addTagToOrg.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.orgList = action.payload;
    })
    .addCase(addTagToOrg.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(resetState, () => {
      return initialState;
    });
  },
});



export const { updateOrgList, updateUserList, getPodList } = adminSlice.actions;
export const selectUserList = (state) => state.admin.userList;
export const selectPodList = (state) => state.admin.podList;
export const selectAdminList = (state) => state.admin;



export default adminSlice.reducer;
