export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const SIGNUP_URL = "/signup";
export const RESETPASS_URL = "/resetpass";
export const SessionInput_URL = "/session_input";
export const AdminHome_URL = "/admin_home";
export const CoachesHome_URL = "/coaches_home";
export const UsersHome_URL = "/athletes_home";
export const UserProfile_URL = "/athlete_profile";
export const SessionDetails_URL = "/session_details";
export const NewUser_URL = "/new_player";
export const ResourcesHome_URL = "/resources_home";
export const OrganisationsList_URL = "/organisations_list";
export const Teams_URL = "/teams"
export const ManageTeams_URL = "/manage_teams"
export const OrganisationDetail_URL = "/organisation_detail";
//export const ReportsHome_URL = "/reports_home";
export const ReportsHome_URL = "/session_input";
export const PodRegistration_URL = "/pod_registration";
export const PodList_URL = "/pod_list";
export const OrganisationSetup_URL = "/admin/organisation_setup";



