import './max-min-mean.scss'
export default function MaxMinMean(props){
    const {leftFoot, rightFoot, footSymmetry} = props;

    return(
        <div className="max-min-mean">
            <div className='values-format min'>
                <h3>MIN</h3>
                <div className='values'>
                    {leftFoot && leftFoot.hasData ? <p className='left-foot'>{Number(leftFoot.min).toFixed(2)}</p> : ""}
                    {rightFoot && rightFoot.hasData ? <p className='right-foot'>{Number(rightFoot.min).toFixed(2)}</p> : ""}
                    {footSymmetry && footSymmetry.hasData ? <p className='foot-sym'>{Number(footSymmetry.min).toFixed(2)}</p> : ""}
                </div>
            </div>
            <div className='values-format max'>
                <h3>MAX</h3>
                <div className='values'>
                    {leftFoot && leftFoot.hasData ? <p className='left-foot'>{Number(leftFoot.max).toFixed(2)}</p> : ""}
                    {rightFoot && rightFoot.hasData ? <p className='right-foot'>{Number(rightFoot.max).toFixed(2)}</p> : ""}
                    {footSymmetry && footSymmetry.hasData ? <p className='foot-sym'>{Number(footSymmetry.max).toFixed(2)}</p> : ""}
                </div>
            </div>
            <div className='values-format mean'>
                <h3>AVERAGE</h3>
                <div className='values'>
                    {leftFoot && leftFoot.hasData ? <p className='left-foot'>{Number(leftFoot.mean).toFixed(2)}</p> : ""}
                    {rightFoot && rightFoot.hasData ? <p className='right-foot'>{Number(rightFoot.mean).toFixed(2)}</p> : ""}
                    {footSymmetry && footSymmetry.hasData ? <p className='foot-sym'>{Number(footSymmetry.mean).toFixed(2)}</p> : ""}
                </div>
            </div>
        </div>
    )
}