export const useAsymmetryCalculations = (chartDataDetail) => {
    if (!chartDataDetail || !Array.isArray(chartDataDetail.chartData)) {
        // Return default values if chartDataDetail or chartData is not properly defined
        return { averageLeftSingleCounter: 0, averageRightSingleCounter: 0, averageAsym: 0 };
    }

    let averageLeftSingleCounter = chartDataDetail.chartData.map(item => item.leftValue).reduce((a, b) => a + b, 0) / chartDataDetail.chartData.length;
    let averageRightSingleCounter = chartDataDetail.chartData.map(item => item.rightValue).reduce((a, b) => a + b, 0) / chartDataDetail.chartData.length;

    let averageLeftAsym = chartDataDetail.chartData.filter(item => item.assymVal < 0)
    let averageRighAsym = chartDataDetail.chartData.filter(item => item.assymVal > 0)

    if (averageLeftAsym.length > 0) {
        averageLeftAsym = averageLeftAsym.map(item => item.assymVal).reduce((a, b) => a + b, 0) / averageLeftAsym.length;
    } else {
        averageLeftAsym = 0;
    }

    if (averageRighAsym.length > 0) {
        averageRighAsym = averageRighAsym.map(item => item.assymVal).reduce((a, b) => a + b, 0) / averageRighAsym.length;
    } else {
        averageRighAsym = 0;
    }

    let averageAsym;

    if (averageRighAsym > 0 && averageLeftAsym < 0) {
        averageAsym = ((averageLeftAsym + averageRighAsym) / 2).toFixed(2)
    } else if (averageRighAsym === 0) {
        averageAsym = averageLeftAsym.toFixed(2)
    } else if (averageLeftAsym === 0) {
        averageAsym = averageRighAsym.toFixed(2)
    }

    return { averageLeftSingleCounter, averageRightSingleCounter, averageAsym };
};
