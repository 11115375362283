import {
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "../../utils/constant";

function SLCJ(props) {
  const { averageAsym, GraphTableData, chartData, setTableData,setGraphIsOpen, activeData, setActiveData } = props;

  

  return (
    <>
      <ResponsiveContainer width="85%" height="100%">
        <BarChart 
          data={chartData} 
          layout="vertical"
          onClick={(e)=> {
            if(activeData.locked === false){
                if(e && e.activePayload){
                    setActiveData({locked: true})
                    setTableData(e.activePayload)
                    setGraphIsOpen(true)
                }
            }else if(activeData.locked === true){
                setActiveData({locked: false})
            }
        }}
        >
          <XAxis
            type="number"
            domain={[-100, 100]}
            tick={{ fill: NEW_AXIS_TICK }}
            stroke={NEW_AXIS_COLOR}
            label={{
              value: "% Asymmetry",
              offset: -3,
              position: "insideBottom",
              fill: NEW_AXIS_TICK,
            }}
          />

          <YAxis
            dataKey="date"
            type="category"
            tick={{ fill: NEW_AXIS_TICK, fontSize: "10px" }}
            stroke={NEW_AXIS_COLOR}
          />

          <CartesianGrid
            strokeDasharray="4 4"
            stroke={NEW_AXIS_COLOR}
            strokeWidth={1}
            opacity={0.4}
          />

          <Tooltip
            cursor={{ fill: NEW_AXIS_COLOR, opacity: ".3" }}
            // content={<GraphTableData data={chartData} component={component}/>}
            content={<GraphTableData data={chartData} />}
            trigger={activeData.locked ? "click" : "hover"}
          />
          {/* <Tooltip content={<CustomTooltipForAsymmetry data={chartData}/>} cursor={{ fill: NEW_AXIS_COLOR, opacity: ".3"}} active="false"/> */}

          <ReferenceLine
            x={
              averageAsym > 0 ? averageAsym : averageAsym < 0 ? averageAsym : 0
            }
            stroke={NEW_AXIS_TICK}
            dot={false}
            strokeDasharray="5 5"
          />

          <ReferenceLine
            x={0}
            stroke={NEW_AXIS_COLOR}
            dot={false}
            strokeDasharray="5 5"
            name="Averege Asymmetry"
          />

          <Bar dataKey="assymVal">
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry["assymVal"] < 0 ? NEW_LEFT_COLOR : NEW_RIGHT_COLOR}
                opacity={0.7}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default SLCJ;
