/* eslint-disable */
import { useState, useEffect } from "react";
import Table from "../table/table";
import GraphHeader from "../table/header/header";
import CircularProgressTable from "../circularProgressTable/circularProgressTable";
import { motion } from "framer-motion";
import { useDebounce } from "../hooks/useDebounce";
import HomePageInnerTable from "./HomePageInnerTable";
import "./HomePageTable.scss";

// remove null and undefined 
function removeNullAndUndefined(obj) {
  for (let key in obj) {
      if (Array.isArray(obj[key])) {
          obj[key] = obj[key].filter(item => item !== null && item !== undefined);
      }
  }
  return obj;
}

const HomePageTable = ({
  fullTableData,
  tableData,
  goToPlayerProfilePage = false,
  goToOrganisationProfilePage = false,
  headers,
  showButton = false,
  comparisonType = "Overview",
  fullAthleteDataset
}) => {


  //state
  const [selectedRow, setSelectedRow] = useState(null);
  const [overviewGraphData, setOverviewGraphData] = useState([]);
  const [allDataArrays, setAllDataArrays] = useState([]);
  const [selectedDataArrays, setSelectedDataArrays] = useState([]);

  // dropdown state
  const [performanceType, setPerformanceType] = useState("Max");
  const [timeframe, setTimeframe] = useState("AllTime");

  //setting domain
  const [maxZScore, setMaxZScore] = useState(0);


  //table data for z scores
  let tableDataAcrossOrganisation = [];

  function prepareDataForZScores(processedData) {
    tableDataAcrossOrganisation = processedData
      .filter((item) => item.isPlayer && Object.keys(item.overview).length > 0)
      .map((item) => item.overview);

    let keys = ["4", "10", "14", "5", "load", "gait"];
    let allTimeMaxArrays = {};
    
    keys.forEach((key) => {
      allTimeMaxArrays[key] = tableDataAcrossOrganisation.map(
        (obj) => obj[key]?.[`${timeframe}${performanceType}`]
      );
    });
    // setAllDataArrays(removeNullAndUndefined(allTimeMaxArrays));
    setAllDataArrays(allTimeMaxArrays);
  }

  function prepareSelectedDataForZScores(processedData) {
    tableDataAcrossOrganisation = processedData
      .filter((item) => item.isPlayer && Object.keys(item.overview).length > 0)
      .map((item) => item.overview);

    let keys = ["4", "10", "14", "5", "load", "gait"];
    let allTimeMaxArrays = {};
    
    keys.forEach((key) => {
      allTimeMaxArrays[key] = tableDataAcrossOrganisation.map(
        (obj) => obj[key]?.[`${timeframe}${performanceType}`]
      );
    });
    // setAllDataArrays(removeNullAndUndefined(allTimeMaxArrays));
    setSelectedDataArrays(allTimeMaxArrays);
  }

  useEffect(() => {
   
      prepareDataForZScores(fullTableData);
  
    if(comparisonType === "Athlete"){
      prepareSelectedDataForZScores(fullAthleteDataset)
    }
    
  }, [timeframe, performanceType]);

  useEffect(() => {
  
      prepareDataForZScores(fullTableData)
  
    if(comparisonType === "Athlete"){
      prepareSelectedDataForZScores(fullAthleteDataset)
    }
    let tempData = tableData.filter((item) => item.orgID);
    setOverviewGraphData(tempData[0]?.overview);
   
  }, [tableData]);

  useEffect(() => {
    if (selectedRow === null || selectedRow === undefined) {
      setSelectedRow(tableData[1]);
    }
  }, [tableData]);

  const handleClick = (row) => {
    setSelectedRow(() => row);

    if (document.querySelector(`.overview-session-selected`)) {
      document
        .querySelector(`.overview-session-selected`)
        .classList.remove("overview-session-selected");
    }
    document
      .getElementById(`player-${row.playerID}`)
      .classList.add("overview-session-selected");
  };

  const childVariants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
  };

  return (
    <>
      <motion.div
        className="table-one"
        variants={childVariants}
        initial="initial"
        animate="animate"
        transition={{
          duration: 0.6,
          delay: 0.2 * 0.1,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >

        <HomePageInnerTable
          header={
            <GraphHeader
              toShow={"Athletes"}
              link={(showButton && comparisonType !== "Athletes") ? "/athletes_home" : null}
            />
          }
          headers={headers}
          data={tableData}
          cls={"home-page-table"}
          goToPlayerProfilePage={goToPlayerProfilePage}
          goToOrganisationProfilePage={goToOrganisationProfilePage}
          clickHandler={handleClick}
          selectedRow={selectedRow}
          performanceType={performanceType}
          timeframe={timeframe}
          comparisonType={comparisonType}
          
        />
      </motion.div>

      <motion.div
        className="circular-cont"
        variants={childVariants}
        initial="initial"
        animate="animate"
        transition={{
          duration: 0.6,
          delay: 0.2 * 1,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <CircularProgressTable
          fullTableData = {fullTableData}
          data={tableData}
          tableDataAcrossOrganisation={allDataArrays}
          orgData={overviewGraphData}
          selectedRow={selectedRow}
          performanceType={performanceType}
          setPerformanceType={setPerformanceType}
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          comparisonType={comparisonType}
          maxZScore={maxZScore}
          setMaxZScore={setMaxZScore}
          fullAthleteDataset = {selectedDataArrays}
        />
      </motion.div>
    </>
  );
};

export default HomePageTable;
