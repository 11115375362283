import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectOrganisation, getOrganisationsToolkit } from 'store/orgSlice';
import { selectUser } from 'store/authSlice';
import { registerPod } from '../../api/admin';
import EscapeIcon from 'components/nav-components/nav-icons/escape-icon';
import Button from 'components/common/button/button';
import '../../styles/pods2.scss'
import { PodList_URL } from 'utils/urls';
import { Link } from 'react-router-dom';

class PodRegistration extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      BLEID:'',
      selectedOrg:undefined,
      errors: {},
      podRegistered:false
    }

    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount(){
    const { organisation,actions,user } = this.props;

    if ((!(organisation && organisation.orgList  && organisation.orgList.length > 0)) && (user.id))
    {
        //empty orgList - so call it!!!
        actions.getOrganisationsToolkit(user.id)
    }
    this.handleWindowSize()
  }

  componentDidMount () {}

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  reset() {
    this.setState({
      BLEID:'',
      selectedOrg:undefined,
      errors: {},
      podRegistered:false
    })
  }

  validateInputs() {
    const errors = {};
    errors.formErrors = false;

    if (this.state.BLEID === '' || !this.state.BLEID)
    {
      errors.error = 'You must enter the BLEID';
      errors.formErrors = true;
    }

    else if (!this.state.selectedOrg || this.state.selectedOrg < 0 )
    {
      errors.error = 'You must link the POD to an Organisation';
      errors.formErrors = true;
    }

    this.setState({ errors });
    return !errors.formErrors;
  }

  registerPod(e) {
    e.preventDefault(); 

    if (this.validateInputs()) {
      const { BLEID,selectedOrg } = this.state;
      const errors = {};
      errors.formErrors = false;

      let postBody = {
        ble:BLEID,
        orgID:parseInt(selectedOrg)
      }

      registerPod(postBody).then(res => {
        if (res.data.result > 0){
          this.setState({ podRegistered:true });
        }
        else{
          errors.error = res.data.description;
          errors.formErrors = true;
          this.setState({ errors });
        }
      })
      .catch(() => {
        errors.error = 'Error creating Pod';
        errors.formErrors = true;
        this.setState({ errors });
      });


    }
  }

  displayCreatedScreen()
  {
    return (
      <div style={{width:'100%'}} className="pod-created-sceen">
              <div className="display_text">
                        <p>New POD Registered</p>
              </div>
              <div>
                      <div className="large-button-parent" onClick={(e)  => {this.reset(e)}}>
                            <Button value={"Register New POD"} />
                      </div>

                      {parseInt(this.props.match.params.originID) > 0 && <div className="large-button-parent" onClick={() =>{ this.props.history.goBack();}}>
                            <Button value={"Back To Organisation"} />
                      </div>}

                      {this.props.user.permissionLevel === 1 && <div className="large-button-parent">
                            <Link to={PodList_URL}>
                                  <Button value={"Pod Database"} />
                            </Link>
                      </div>}

              </div>
      </div>
    )
  }


  handleOrgSelect(value) {
    this.setState({selectedOrg: value});
  }

  render() {
    const { organisation } = this.props;
    const { BLEID,selectedOrg,podRegistered,errors } = this.state;

    if (podRegistered)
    {
      return this.displayCreatedScreen()
    }

    return (
      <div style={{width:'100%',height:'100%'}} className="right-background ">
                <div className="right-container-content">

                          <div className="right-padding"> 

                                      <div className="new-pod-container">
                                            <div className="center-content" style={{width:'100%'}}>

                                                  <div className="right-title">
                                                          New POD Registration
                                                  </div>

                                                  <div className="input_parent input_border" style={{marginTop:100,marginBottom:20}}>
                                                        <input type="text" autoComplete="off"  id="BLEID" name="BLEID"   onChange={this.onChange} value={BLEID} placeholder="ENTER SOCK BLE ID"  />
                                                  </div>

                                                  <div className="input_parent input_border" style={{marginTop:10,marginBottom:30}}>

                                                          <select id="org_select" onChange={(e) => this.handleOrgSelect(e.target.value)}>
                                                              <option disabled hidden selected={!selectedOrg} key={"select_-1"} value={-1}>Organisation</option>

                                                              {organisation.orgList.map((org) => {
                                                                  return <option selected={org.OrgID === selectedOrg} key={"select_" + org.OrgID } value={org.OrgID}>{org.Name}</option>
                                                              })}
                                                          </select>
                                                  </div>

                                                  {(errors && errors.error) &&
                                                              <div className="error-warning">
                                                                      *  {errors.error}
                                                              </div>
                                                    }

                                                  <div className="small_org_button_parent" style={{marginTop:50,marginBottom:30}}>
                                                        <div className="small_org_button" style={{width:250}}  onClick={(e)  => {this.registerPod(e)}}>
                                                              <Button value={"Register New Pod"}/>
                                                        </div>
                                                  </div>

                                            </div>

                                            <div className="right-back-link" onClick={() =>{ this.props.history.goBack();}}>
                                                  <EscapeIcon />
                                            </div>

                                      </div>

                                      {/* <div className="right-title">
                                            Organisations
                                      </div> */}

                          </div>



                </div>


      </div>

    );
  }
}






function mapStateToProps(state) {
  return {
        user: selectUser(state),
        organisation: selectOrganisation(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
        actions: bindActionCreators({  getOrganisationsToolkit }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PodRegistration);

