/* eslint-disable */
import MainTooltip from "components/common/mainTooltip/MainTooltip";
import { connect } from "react-redux";
import "./metric-helpers/mainChartComponent.scss";
import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Cell,
  Line,
} from "recharts";

import { selectSessionList2 } from "store/sessionListSlice";
import { selectSession } from "store/sessionSlice";
import { NEW_AXIS_COLOR, NEW_AXIS_TICK } from "utils/constant";
import { useState } from "react";
import Toggler from "components/common/toggler/Toggler";
import GraphSettings2 from "./metric-helpers/GraphSettings2";

const CustomBar = (props) => {
  const { fill, x, y, width, height, stroke } = props;

  const alteredWidth =
    (width / 10) * props?.payload?.value?.[0]?.stabilityScore;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={alteredWidth}
        height={height}
        fill={fill}
        stroke="none"
        rx={15} // Border radius for the inner rectangle
        ry={15} // Border radius for the inner rectangle
      />

      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill="none"
        stroke={stroke}
        strokeWidth={1} // Adjust the stroke width as needed
        rx={15} // Border radius for the outer rectangle
        ry={15} // Border radius for the outer rectangle
      />
    </g>
  );
};

const MainChartComponent = (props) => {
  const {
    fixedHeight,
    chartTitle,
    tooltipText,
    chartType,
    chartOrientation,
    chartHeight = "300px",
    chartDataKey,
    bilateralChart = false,
    bilateralKeys,
    session,
    hasToggler,
    togglerValues,
    tilesforRendering,
    hasSettings = false,
    settingsSide,
    settingsChartKey = null,
  } = props;

  //state declaration
  const [toggleGraph, setToggleGraph] = useState(false);

  //helper functions
  const togglerChange = (e) => {
    console.log(e);
  };

  return chartType !== "tiles" ? (
    <div
      className="main-metric-container settings_graph"
      style={{ height: fixedHeight }}
    >
      <div className="main-metric-heading-container">
        <div className="main-metric-title-tooltip">
          <h3>{chartTitle}</h3>
          <MainTooltip tooltipText={tooltipText} />
        </div>
        {hasToggler && (
          <div className="main-metric-toggler">
            <Toggler
              isToggled={toggleGraph}
              setIsToggled={setToggleGraph}
              changeToggleDisplayed={togglerChange}
              togglerHeadings={{
                left: togglerValues.left,
                right: togglerValues.right,
              }}
            />
          </div>
        )}
      </div>
      <div className="main-metric-chart-value">
        {!toggleGraph ? (
          <h4>
            {
              session?.rawData?.data?.rawData?.[bilateralKeys.mean]?.[0]?.[
                chartDataKey
              ]
            }
          </h4>
        ) : (
          <div>
            <h4>
              {
                session?.rawData?.data?.rawData?.[bilateralKeys.left]?.[0]?.[
                  chartDataKey
                ]
              }
              <span className="span-left">L</span>
            </h4>
            <h4>
              {
                session?.rawData?.data?.rawData?.[bilateralKeys.right]?.[0]?.[
                  chartDataKey
                ]
              }
              <span className="span-right">R</span>
            </h4>
          </div>
        )}
      </div>

      <div
        className="main-metric-chart-container"
        style={{ height: chartHeight }}
      >
        <ResponsiveContainer width="100%" height="100%">
          {chartType === "bullet" ? (
            <ComposedChart
              data={session?.rawData?.data?.rawData}
              layout={chartOrientation}
            >
              <XAxis
                type="number"
                display={false}
                domain={[0, 10]}
                tickCount={11}
                tick={{ fill: NEW_AXIS_TICK }}
                stroke={NEW_AXIS_TICK}
              />
              <YAxis
                type="category"
                dataKey={chartDataKey}
                allowDuplicatedCategory={false}
                axisLine={false}
                tick={false}
                width={0}
              />

              {!toggleGraph ? (
                <>
                  <defs>
                    <linearGradient
                      id="stabilityScoreGradientMean"
                      x1="1"
                      x2="0"
                    >
                      <stop
                        offset={"0%"}
                        stopColor={"rgba(243, 156, 18,1.0)"}
                      />
                      <stop
                        offset={"50%"}
                        stopColor={"rgba(243, 156, 18,0.5)"}
                      />
                      <stop
                        offset={"100%"}
                        stopColor={"rgba(243, 156, 18,0.02)"}
                      />
                    </linearGradient>
                  </defs>

                  <Bar
                    data={[
                      {
                        name: "Static",
                        maxValue: 10,
                        value:
                          session?.rawData?.data?.rawData?.[bilateralKeys.mean],
                      },
                    ]}
                    dataKey="maxValue"
                    barSize={30}
                    fill="url(#stabilityScoreGradientMean)"
                    stroke="white"
                    radius={15}
                    x={0}
                    y={50}
                    shape={<CustomBar />}
                    barCategoryGap="50%"
                  />
                </>
              ) : (
                <>
                  <defs>
                    <linearGradient
                      id="stabilityScoreGradientLeft"
                      x1="1"
                      x2="0"
                    >
                      <stop
                        offset={"0%"}
                        stopColor={"rgba(155, 89, 182,1.0)"}
                      />
                      <stop
                        offset={"50%"}
                        stopColor={"rgba(155, 89, 182,0.5)"}
                      />
                      <stop
                        offset={"100%"}
                        stopColor={"rgba(155, 89, 182,0.2)"}
                      />
                    </linearGradient>

                    <linearGradient
                      id="stabilityScoreGradientRight"
                      x1="1"
                      x2="0"
                    >
                      <stop
                        offset={"0%"}
                        stopColor={"rgba(52, 152, 219,1.0)"}
                      />
                      <stop
                        offset={"50%"}
                        stopColor={"rgba(52, 152, 219,0.5)"}
                      />
                      <stop
                        offset={"100%"}
                        stopColor={"rgba(52, 152, 219,0.02)"}
                      />
                    </linearGradient>
                  </defs>
                  {/* <Bar /> */}
                  <Line />

                  <Bar
                    data={[
                      {
                        name: "Static",
                        leftMaxValue: 10,
                        value:
                          session?.rawData?.data?.rawData?.[bilateralKeys.left],
                      },
                    ]}
                    dataKey="leftMaxValue"
                    barSize={30}
                    fill="url(#stabilityScoreGradientLeft)"
                    stroke="white"
                    radius={15}
                    x={0}
                    y={50}
                    shape={<CustomBar />}
                  />

                  <Bar
                    data={[
                      {
                        name: "Static",
                        rightMaxValue: 10,
                        value:
                          session?.rawData?.data?.rawData?.[
                            bilateralKeys.right
                          ],
                      },
                    ]}
                    dataKey="rightMaxValue"
                    barSize={30}
                    fill="url(#stabilityScoreGradientRight)"
                    stroke="white"
                    radius={15}
                    x={0}
                    y={50}
                    shape={<CustomBar />}
                  />
                </>
              )}
            </ComposedChart>
          ) : (
            ""
          )}
        </ResponsiveContainer>
      </div>
      {hasSettings && (
        <GraphSettings2
          graphName={settingsChartKey}
          settingsPosition={settingsSide}
          hasToggler={false}
        />
      )}
    </div>
  ) : (
    <div className="main-metric-tiles settings_graph">
      {tilesforRendering.map((item, index) => (
        <div className="main-metric-tile" key={`tile-${index}`}>
          <h5>{item.metricName}</h5>
          <div className="main-metric-value">
            {!toggleGraph ? (
              <h2>
                {session?.rawData?.data?.rawData?.[
                  item.metricValuesKeys.mean
                ]?.[0]?.[item.metricValuesKeys.dataKey].toFixed(2)}{" "}
                <span className="metric-unit">{item.metricUnit}</span>
              </h2>
            ) : (
              <div className="main-metric-value-both">
                <h2>
                  {session?.rawData?.data?.rawData?.[
                    item.metricValuesKeys.left
                  ]?.[0]?.[item.metricValuesKeys.dataKey].toFixed(2)}{" "}
                  <span className="metric-unit">{item.metricUnit}</span>{" "}
                  <span className="span-left">L</span>
                </h2>
                <h2>
                  {session?.rawData?.data?.rawData?.[
                    item.metricValuesKeys.right
                  ]?.[0]?.[item.metricValuesKeys.dataKey].toFixed(2)}{" "}
                  <span className="metric-unit">{item.metricUnit}</span>{" "}
                  <span className="span-right">R</span>
                </h2>
              </div>
            )}
          </div>
        </div>
      ))}
      {hasSettings && (
        <GraphSettings2
          graphName={settingsChartKey}
          settingsPosition={settingsSide}
          hasToggler={true}
          togglerHeadings={togglerValues}
          isToggled={toggleGraph}
          setIsToggled={setToggleGraph}
          changeToggleDisplayed={togglerChange}
          defaultToggle={true}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionList: selectSessionList2(state),
    session: selectSession(state),
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainChartComponent);
