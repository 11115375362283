/* eslint-disable */
import Button from "components/common/button/button";
import TestSlide from "components/common/testSlide/testSlide";
import "./session-comparison-style.scss";
import moment from "moment";
import { useState, useEffect, useRef, useCallback } from "react";
import ComparisonBarChart from "./capared-session-graphs/ComparisonBarChart";
import ComparisonAsymmetryBarChart from "./capared-session-graphs/ComparisonAsymmetryBarChart";
import SelectedSessionsTable from "./compare-session-tables/SelectedSessionsTable";
import SelectedSessionsMetricTable from "./compare-session-tables/SelectedSessionMetricTable";
import { Responsive, WidthProvider } from "react-grid-layout";
import { toPng } from "html-to-image";
import CompareSessionBoxPlot from "./capared-session-graphs/CompareSessionBoxPlot";
import CompareSessionBoxPlotPlotly from "./capared-session-graphs/CompareSessionBoxPlotPlotly";

const ResponsiveGridLayout = WidthProvider(Responsive);

function SessionComparison(props) {
  const {
    comparingSesions = false,
    setComparingSessions,
    sessionsForComparison,
    setSessionsForComparison,
    comparedSessionType,
    setComparedSessionType,
  } = props;

  //referenece
  const ref = useRef();

  const [comparisonType, setComparisonType] = useState("average");
  const [toggleBarChart, setToggleBarChart] = useState(true);
  const [colapseTable, setColapseTable] = useState(true);
  const [sessionWarningTesting, setSessionWarningTesting] = useState(false);
  const [customGridLayout, setCustomGridLayout] = useState([]);

  let formattedData = [];
  let formattedHeadersForTable = [];
  let formattedDataForTable = [];
  let gaitSession = false;
  let jumpSession = false;

  //on click button test
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-image-name.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  useEffect(() => {
    function disableScrollModalOpen() {
      document.body.classList.add("disableScroll");
    }

    function enableScrollModalClosed() {
      document.body.classList.remove("disableScroll");
    }
    setTimeout(() => {
      if (comparingSesions) {
        disableScrollModalOpen();
      } else {
        enableScrollModalClosed();
      }
    }, 1000);
  }, [comparingSesions]);

  //gait session data manipulation
  if (
    comparedSessionType === "Sprint" ||
    comparedSessionType === "Walk" ||
    comparedSessionType === "Jog"
  ) {
    gaitSession = true;

    //formatting data
    formattedData = sessionsForComparison.map((item) => ({
      sessionTableData: item?.playerName &&
        item.sessionID &&
        item.ts && {
          athleteName: item?.playerName,
          sessionID: item?.sessionID,
          date: moment(item?.ts).format("DD-MM-YYYY"),
          detail: item?.detail,
          tags: item?.tags,
        },
      flightTimeData: item?.FlightTimeData &&
        item?.FlightTimeData?.stepData &&
        item?.FlightTimeData?.stepData?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          maxLeftValue: Math.max(
            ...item["FlightTimeData"].stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
          ),
          maxRightValue: Math.max(
            ...item["FlightTimeData"].stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
          ),
          averageLeftValue:
            item["FlightTimeData"]?.stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["FlightTimeData"].stepData
              .filter((value) => value["Left Foot"])
              .map((item) => item["Left Foot"])?.length,
          averageRightValue:
            item["FlightTimeData"]?.stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["FlightTimeData"].stepData
              .filter((value) => value["Right Foot"])
              .map((item) => item["Right Foot"])?.length,
        },
      contactTimeData: item?.ContactTimeData &&
        item?.ContactTimeData?.stepData &&
        item?.ContactTimeData?.stepData?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          maxLeftValue: Math.max(
            ...item["ContactTimeData"].stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
          ),
          maxRightValue: Math.max(
            ...item["ContactTimeData"].stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
          ),
          averageLeftValue:
            item["ContactTimeData"].stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["ContactTimeData"].stepData
              .filter((value) => value["Left Foot"])
              .map((item) => item["Left Foot"])?.length,
          averageRightValue:
            item["ContactTimeData"].stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["ContactTimeData"].stepData
              .filter((value) => value["Right Foot"])
              .map((item) => item["Right Foot"])?.length,
        },
      flightTimeSymmetryData: item?.StrideSymmetryData &&
        item?.StrideSymmetryData?.flightTime &&
        item?.StrideSymmetryData?.flightTime?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          averageSymmetryValue:
            item?.StrideSymmetryData.flightTime
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))
              .reduce((a, b) => a + b, 0) /
            item?.StrideSymmetryData.flightTime
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))?.length,
          maxSymmetryValue: item?.StrideSymmetryData.flightTime
            .filter((value) => !Number.isNaN(value["Symmetry"]))
            .map((item) => Number(item["Symmetry"]))
            .reduce((a, b) => (Math.abs(a) > Math.abs(b) ? a : b)),
        },
      contactTimeSymmetryData: item?.StrideSymmetryData &&
        item?.StrideSymmetryData?.contactTime &&
        item?.StrideSymmetryData?.contactTime?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          averageSymmetryValue:
            item?.StrideSymmetryData.contactTime
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))
              .reduce((a, b) => a + b, 0) /
            item?.StrideSymmetryData.contactTime
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))?.length,
          maxSymmetryValue: item?.StrideSymmetryData.contactTime
            .filter((value) => !Number.isNaN(value["Symmetry"]))
            .map((item) => Number(item["Symmetry"]))
            .reduce((a, b) => (Math.abs(a) > Math.abs(b) ? a : b)),
        },
      cadenceData: item?.cadenceData &&
        item?.cadenceData?.stepsCadenceChartV2 &&
        item?.cadenceData?.stepsCadenceChartV2?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          maxLeftValue: Math.max(
            ...item["cadenceData"].stepsCadenceChartV2
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
          ),
          maxRightValue: Math.max(
            ...item["cadenceData"].stepsCadenceChartV2
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
          ),
          averageLeftValue:
            item["cadenceData"].stepsCadenceChartV2
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["cadenceData"].stepsCadenceChartV2
              .filter((value) => value["Left Foot"])
              .map((item) => item["Left Foot"])?.length,
          averageRightValue:
            item["cadenceData"].stepsCadenceChartV2
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["cadenceData"].stepsCadenceChartV2
              .filter((value) => value["Right Foot"])
              .map((item) => item["Right Foot"])?.length,
        },
      peakTibialData: item?.PeakTibialAccelerationData &&
        item?.PeakTibialAccelerationData &&
        item?.PeakTibialAccelerationData?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          maxLeftValue: Math.max(
            ...item["PeakTibialAccelerationData"]
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
          ),
          maxRightValue: Math.max(
            ...item["PeakTibialAccelerationData"]
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
          ),
          averageLeftValue:
            item["PeakTibialAccelerationData"]
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["PeakTibialAccelerationData"]
              .filter((value) => value["Left Foot"])
              .map((item) => item["Left Foot"])?.length,
          averageRightValue:
            item["PeakTibialAccelerationData"]
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
              .reduce((a, b) => a + b, 0) /
            item["PeakTibialAccelerationData"]
              .filter((value) => value["Right Foot"])
              .map((item) => item["Right Foot"])?.length,
        },
      stepLengthData: item?.gaitMetricData &&
        item?.gaitMetricData?.stepLength &&
        item?.gaitMetricData?.stepLength?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          maxLeftValue: Math.max(
            ...item?.gaitMetricData?.["stepLength"]
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
          ),
          maxRightValue: Math.max(
            ...item?.gaitMetricData?.["stepLength"]
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
          ),
          averageLeftValue:
            item?.gaitMetricData?.["stepLength"]
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
              .reduce((a, b) => a + b, 0) /
            item?.gaitMetricData?.["stepLength"]
              .filter((value) => value["Left Foot"])
              .map((item) => item["Left Foot"])?.length,
          averageRightValue:
            item?.gaitMetricData?.["stepLength"]
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
              .reduce((a, b) => a + b, 0) /
            item?.gaitMetricData?.["stepLength"]
              .filter((value) => value["Right Foot"])
              .map((item) => item["Right Foot"])?.length,
        },
      stepLengthSymmetryData: item?.StrideSymmetryData &&
        item?.StrideSymmetryData?.stepLength &&
        item?.StrideSymmetryData?.stepLength?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          averageSymmetryValue:
            item?.StrideSymmetryData.stepLength
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))
              .reduce((a, b) => a + b, 0) /
            item?.StrideSymmetryData.stepLength
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))?.length,
          maxSymmetryValue: item?.StrideSymmetryData.stepLength
            .filter((value) => !Number.isNaN(value["Symmetry"]))
            .map((item) => Number(item["Symmetry"]))
            .reduce((a, b) => (Math.abs(a) > Math.abs(b) ? a : b)),
        },
      strideTimeData: item?.gaitMetricData &&
        item?.gaitMetricData?.strideArray &&
        item?.gaitMetricData?.strideArray?.stepData.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          maxLeftValue: Math.max(
            ...item?.gaitMetricData?.["strideArray"]?.stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
          ),
          maxRightValue: Math.max(
            ...item?.gaitMetricData?.["strideArray"]?.stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
          ),
          averageLeftValue:
            item?.gaitMetricData?.["strideArray"]?.stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
              .reduce((a, b) => a + b, 0) /
            item?.gaitMetricData?.["strideArray"]?.stepData
              .filter((value) => value["Left Foot"])
              .map((item) => item["Left Foot"])?.length,
          averageRightValue:
            item?.gaitMetricData?.["strideArray"]?.stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
              .reduce((a, b) => a + b, 0) /
            item?.gaitMetricData?.["strideArray"]?.stepData
              .filter((value) => value["Right Foot"])
              .map((item) => item["Right Foot"])?.length,
        },
      strideTimeSymmetryData: item?.StrideSymmetryData &&
        item?.StrideSymmetryData?.stride &&
        item?.StrideSymmetryData?.stride?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          averageSymmetryValue:
            item?.StrideSymmetryData.stride
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))
              .reduce((a, b) => a + b, 0) /
            item?.StrideSymmetryData.stride
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))?.length,
          maxSymmetryValue: item?.StrideSymmetryData.stride
            .filter((value) => !Number.isNaN(value["Symmetry"]))
            .map((item) => Number(item["Symmetry"]))
            .reduce((a, b) => (Math.abs(a) > Math.abs(b) ? a : b)),
        },
      swingTimeData: item?.gaitMetricData &&
        item?.gaitMetricData?.swingArray &&
        item?.gaitMetricData?.swingArray?.stepData.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          maxLeftValue: Math.max(
            ...item?.gaitMetricData?.["swingArray"]?.stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
          ),
          maxRightValue: Math.max(
            ...item?.gaitMetricData?.["swingArray"]?.stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
          ),
          averageLeftValue:
            item?.gaitMetricData?.["swingArray"]?.stepData
              .filter((value) => value["Left Foot"])
              .map((item) => Number(item["Left Foot"]))
              .reduce((a, b) => a + b, 0) /
            item?.gaitMetricData?.["swingArray"]?.stepData
              .filter((value) => value["Left Foot"])
              .map((item) => item["Left Foot"])?.length,
          averageRightValue:
            item?.gaitMetricData?.["swingArray"]?.stepData
              .filter((value) => value["Right Foot"])
              .map((item) => Number(item["Right Foot"]))
              .reduce((a, b) => a + b, 0) /
            item?.gaitMetricData?.["swingArray"]?.stepData
              .filter((value) => value["Right Foot"])
              .map((item) => item["Right Foot"])?.length,
        },
      swingTimeSymmetryData: item?.StrideSymmetryData &&
        item?.StrideSymmetryData &&
        item?.StrideSymmetryData?.swing?.length && {
          sessionID: item?.sessionID,
          athleteName: item?.playerName,
          detail: item?.detail,
          averageSymmetryValue:
            item?.StrideSymmetryData.swing
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))
              .reduce((a, b) => a + b, 0) /
            item?.StrideSymmetryData.swing
              .filter((value) => !Number.isNaN(value["Symmetry"]))
              .map((item) => Number(item["Symmetry"]))?.length,
          maxSymmetryValue: item?.StrideSymmetryData.swing
            .filter((value) => !Number.isNaN(value["Symmetry"]))
            .map((item) => Number(item["Symmetry"]))
            .reduce((a, b) => (Math.abs(a) > Math.abs(b) ? a : b)),
        },
    }));

    //formatting data for metric table ------------------------------------------------------------------
    formattedDataForTable = [
      comparedSessionType !== "Walk" && {
        metricName:
          comparedSessionType === "Walk" ? "Swing Time" : "Flight Time",
        layoutKey: "a",
        type: "number",
        valueCount: 2,
        metricValues: formattedData?.map((item) => {
          return { ...item.flightTimeData };
        }),
      },
      comparedSessionType !== "Walk" && {
        metricName:
          comparedSessionType === "Walk"
            ? "Swing Time Asymmetry"
            : "Flight Time Asymmetry",
        layoutKey: "b",
        type: "symmetry",
        valueCount: 1,
        metricValues: formattedData?.map((item) => {
          return { ...item.flightTimeSymmetryData };
        }),
      },
      {
        metricName: "Contact Time",
        layoutKey: "c",
        type: "number",
        valueCount: 2,
        metricValues: formattedData.map((item) => {
          return { ...item.contactTimeData };
        }),
      },
      {
        metricName: "Contact Time Asymmetry",
        layoutKey: "d",
        type: "symmetry",
        valueCount: 1,
        metricValues: formattedData.map((item) => {
          return { ...item.contactTimeSymmetryData };
        }),
      },
      {
        metricName: "Cadence",
        layoutKey: "e",
        type: "number",
        valueCount: 2,
        metricValues: formattedData.map((item) => {
          return { ...item.cadenceData };
        }),
      },
      {
        metricName: "Peak Tibial Acceleration",
        layoutKey: "f",
        type: "number",
        valueCount: 2,
        metricValues: formattedData.map((item) => {
          return { ...item.peakTibialData };
        }),
      },
      {
        metricName: "Step Length",
        layoutKey: "g",
        type: "number",
        valueCount: 2,
        metricValues: formattedData.map((item) => {
          return { ...item.stepLengthData };
        }),
      },
      {
        metricName: "Step Length Asymmetry",
        layoutKey: "h",
        type: "symmetry",
        valueCount: 1,
        metricValues: formattedData.map((item) => {
          return { ...item.stepLengthSymmetryData };
        }),
      },
      {
        metricName: "Stride Time",
        layoutKey: "i",
        type: "number",
        valueCount: 2,
        metricValues: formattedData.map((item) => {
          return { ...item.strideTimeData };
        }),
      },
      {
        metricName: "Stride Time Asymmetry",
        layoutKey: "j",
        type: "symmetry",
        valueCount: 1,
        metricValues: formattedData.map((item) => {
          return { ...item.strideTimeSymmetryData };
        }),
      },
      {
        metricName: "Swing Time",
        layoutKey: "k",
        type: "number",
        valueCount: 2,
        metricValues: formattedData.map((item) => {
          return { ...item.swingTimeData };
        }),
      },
      {
        metricName: "Swing Time Asymmetry",
        layoutKey: "l",
        type: "symmetry",
        valueCount: 1,
        metricValues: formattedData.map((item) => {
          return { ...item.swingTimeSymmetryData };
        }),
      },
    ];

    //fomratted headers for metric table
    formattedHeadersForTable = sessionsForComparison.map((item) => ({
      athleteName: item?.playerName,
      sessionID: item?.sessionID,
    }));
  }

  if (comparedSessionType.toLowerCase().includes("leg")) {
    jumpSession = true;

    formattedData = sessionsForComparison.map((item) => ({
      // the player id here to be replaced with the session id once the api has sbeen updated TO DO----------
      sessionTableData: item?.playerName &&
        item.sessionID &&
        item.ts && {
          athleteName: item?.playerName,
          sessionID: item?.sessionID,
          date: moment(item?.ts).format("DD-MM-YYYY"),
          detail: item?.detail,
        },

      jumpHeightData: comparedSessionType.toLowerCase().includes("double")
        ? item?.jumpData &&
          item?.jumpData?.Max &&
          item?.jumpData?.Mean && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.jumpData.Max,
            averageValue: item.jumpData.Mean,
          }
        : item?.jumpData &&
          (item?.jumpData?.leftDataExists ||
            item?.jumpData?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.jumpData.leftMax,
            maxRightValue: item.jumpData.rightMax,
            averageLeftValue: item.jumpData.leftMean,
            averageRightValue: item.jumpData.rightMean,
          },
      jumpHeightAsymmetryData: item?.jumpData &&
        item?.jumpData?.symDataExists && {
          sessionType: comparedSessionType,
          sessionID: item.sessionID,
          athleteName: item.playerName,
          detail: item?.detail,
          maxSymmetryValue: item.jumpData.symMax,
          averageSymmetryValue: item.jumpData.symMean,
        },
      flightTimeData: comparedSessionType.toLowerCase().includes("double")
        ? item.flightData &&
          item?.flightData?.Max &&
          item?.flightData?.Mean && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.flightData.Max,
            averageValue: item.flightData.Mean,
          }
        : item?.flightData &&
          (item?.flightData?.leftDataExists ||
            item.flightData?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.flightData.leftMax,
            maxRightValue: item.flightData.rightMax,
            averageLeftValue: item.flightData.leftMean,
            averageRightValue: item.flightData.rightMean,
          },
      flightTimeData: comparedSessionType.toLowerCase().includes("double")
        ? item.flightData &&
          item?.flightData?.Max &&
          item?.flightData?.Mean && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.flightData.Max,
            averageValue: item.flightData.Mean,
          }
        : item?.flightData &&
          (item?.flightData?.leftDataExists ||
            item.flightData?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.flightData.leftMax,
            maxRightValue: item.flightData.rightMax,
            averageLeftValue: item.flightData.leftMean,
            averageRightValue: item.flightData.rightMean,
          },
      groundClearance: comparedSessionType.toLowerCase().includes("double")
        ? item.spatials &&
          item.spatials.groundClearance &&
          item?.spatials?.groundClearance?.Max &&
          item?.spatials?.groundClearance?.Mean && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.spatials?.groundClearance.Max,
            averageValue: item.spatials?.groundClearance.Mean,
          }
        : item?.spatials &&
          item?.spatials.groundClearance &&
          (item?.spatials?.groundClearance?.leftDataExists ||
            item.spatials?.groundClearance?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.spatials?.groundClearance.leftMax,
            maxRightValue: item.spatials?.groundClearance.rightMax,
            averageLeftValue: item.spatials?.groundClearance.leftMean,
            averageRightValue: item.spatials?.groundClearance.rightMean,
          },
      jumpLength: comparedSessionType.toLowerCase().includes("double")
        ? item.spatials &&
          item.spatials.jumpLength &&
          item?.spatials?.jumpLength?.Max &&
          item?.spatials?.jumpLength?.Mean && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.spatials?.jumpLength.Max,
            averageValue: item.spatials?.jumpLength.Mean,
          }
        : item?.spatials &&
          item?.spatials.jumpLength &&
          (item?.spatials?.jumpLength?.leftDataExists ||
            item.spatials?.jumpLength?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.spatials?.jumpLength.leftMax,
            maxRightValue: item.spatials?.jumpLength.rightMax,
            averageLeftValue: item.spatials?.jumpLength.leftMean,
            averageRightValue: item.spatials?.jumpLength.rightMean,
          },
      maxJumpVelocity: comparedSessionType.toLowerCase().includes("double")
        ? item.spatials &&
          item.spatials.maxJumpVelocity &&
          item?.spatials?.maxJumpVelocity?.Max &&
          item?.spatials?.maxJumpVelocity?.Mean && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.spatials?.maxJumpVelocity.Max,
            averageValue: item.spatials?.maxJumpVelocity.Mean,
          }
        : item?.spatials &&
          item?.spatials.maxJumpVelocity &&
          item?.spatials?.maxJumpVelocity?.leftDataExists &&
          item.spatials?.maxJumpVelocity?.rightDataExists && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.spatials?.maxJumpVelocity.leftMax,
            maxRightValue: item.spatials?.maxJumpVelocity.rightMax,
            averageLeftValue: item.spatials?.maxJumpVelocity.leftMean,
            averageRightValue: item.spatials?.maxJumpVelocity.rightMean,
          },
      meanJumpVelocity: comparedSessionType.toLowerCase().includes("double")
        ? item.spatials &&
          item.spatials.meanJumpVelocity &&
          item?.spatials?.meanJumpVelocity?.Max &&
          item?.spatials?.meanJumpVelocity?.Mean && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.spatials?.meanJumpVelocity.Max,
            averageValue: item.spatials?.meanJumpVelocity.Mean,
          }
        : item?.spatials &&
          item?.spatials.meanJumpVelocity &&
          (item?.spatials?.meanJumpVelocity?.leftDataExists ||
            item.spatials?.meanJumpVelocity?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.spatials?.meanJumpVelocity.leftMax,
            maxRightValue: item.spatials?.meanJumpVelocity.rightMax,
            averageLeftValue: item.spatials?.meanJumpVelocity.leftMean,
            averageRightValue: item.spatials?.meanJumpVelocity.rightMean,
          },
      flightTimeAsymmetryData: item?.flightData &&
        item?.flightData?.symDataExists && {
          sessionType: comparedSessionType,
          sessionID: item.sessionID,
          athleteName: item.playerName,
          detail: item?.detail,
          maxSymmetryValue: item.flightData.symMax,
          averageSymmetryValue: item.flightData.symMean,
        },
      powerData: comparedSessionType.toLowerCase().includes("double")
        ? item.powerData &&
          !isNaN(item.powerData.Max) &&
          !isNaN(item.powerData.Mean) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.powerData.Max,
            averageValue: item.powerData.Mean,
          }
        : item?.powerData &&
          (item?.powerData?.leftDataExists ||
            item?.powerData?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.powerData.leftMax,
            maxRightValue: item.powerData.rightMax,
            averageLeftValue: item.powerData.leftMean,
            averageRightValue: item.powerData.rightMean,
          },
      powerAsymmetryData: item?.powerData &&
        item?.powerData?.symDataExists && {
          sessionType: comparedSessionType,
          sessionID: item.sessionID,
          athleteName: item.playerName,
          detail: item?.detail,
          maxSymmetryValue: item.powerData.symMax,
          averageSymmetryValue: item.powerData.symMean,
        },
      contactTimeData: comparedSessionType.toLowerCase().includes("double")
        ? item.contactTimeData &&
          !isNaN(item.contactTimeData.Max) &&
          !isNaN(item.contactTimeData.Mean) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.contactTimeData.Max,
            averageValue: item.contactTimeData.Mean,
          }
        : item?.contactTimeData &&
          (item?.contactTimeData?.leftDataExists ||
            item?.contactTimeData?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.contactTimeData.leftMax,
            maxRightValue: item.contactTimeData.rightMax,
            averageLeftValue: item.contactTimeData.leftMean,
            averageRightValue: item.contactTimeData.rightMean,
          },
      contactTimeAsymmetryData: item?.contactTimeData &&
        item?.contactTimeData?.symDataExists && {
          sessionType: comparedSessionType,
          sessionID: item.sessionID,
          sessionID: item.sessionID,
          athleteName: item.playerName,
          detail: item?.detail,
          maxSymmetryValue: item.contactTimeData.symMax,
          averageSymmetryValue: item.contactTimeData.symMean,
        },
      rsiData: comparedSessionType.toLowerCase().includes("double")
        ? item.rsiData &&
          !isNaN(item.rsiData.Max) &&
          !isNaN(item.rsiData.Mean) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxValue: item.rsiData.Max,
            averageValue: item.rsiData.Mean,
          }
        : item?.rsiData &&
          (item?.rsiData?.leftDataExists || item?.rsiData?.rightDataExists) && {
            sessionType: comparedSessionType,
            sessionID: item.sessionID,
            athleteName: item.playerName,
            detail: item?.detail,
            maxLeftValue: item.rsiData.leftMax,
            maxRightValue: item.rsiData.rightMax,
            averageLeftValue: item.rsiData.leftMean,
            averageRightValue: item.rsiData.rightMean,
          },
      rsiAsymmetryData: item?.rsiData &&
        item?.rsiData?.symDataExists && {
          sessionType: comparedSessionType,
          sessionID: item.sessionID,
          athleteName: item.playerName,
          detail: item?.detail,
          maxSymmetryValue: item.rsiData.symMax,
          averageSymmetryValue: item.rsiData.symMean,
        },
    }));

    //functinon checks if the metric is table row is needed
    function checkKeyInArray(array, key) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][key]) {
          return true;
        }
      }
      return false;
    }

    //formatting data for metric table double leg ------------------------------------------------
    if (comparedSessionType.toLowerCase().includes("double")) {
      formattedDataForTable = [
        {
          metricName: "Jump Height (cm)",
          layoutKey: "a",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "jumpHeightData"),
          metricValues: formattedData.map((item) => {
            return { ...item.jumpHeightData };
          }),
        },
        {
          metricName: "Flight Time (s)",
          layoutKey: "b",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "flightTimeData"),
          metricValues: formattedData.map((item) => {
            return { ...item.flightTimeData };
          }),
        },
        {
          metricName: "Contact Time (s)",
          layoutKey: "c",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "contactTimeData"),
          metricValues: formattedData.map((item) => {
            return { ...item.contactTimeData };
          }),
        },
        // Temo removed -----------------------------------------------------------------
        // {
        //   metricName: "Ground Clearance (m)",
        //   layoutKey: "c",
        //   type: "double",
        //   valueCount: 1,
        //   needsMetric: checkKeyInArray(formattedData, "groundClearance"),
        //   metricValues: formattedData.map((item) => {
        //     return { ...item.groundClearance };
        //   }),
        // },
        {
          metricName: "RSI",
          layoutKey: "d",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "rsiData"),
          metricValues: formattedData.map((item) => {
            return { ...item.rsiData };
          }),
        },
        {
          metricName: "Jump Length",
          layoutKey: "d",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "jumpLength"),
          metricValues: formattedData.map((item) => {
            return { ...item.jumpLength };
          }),
        },
        {
          metricName: "Peak Power (w)",
          layoutKey: "e",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "powerData"),
          metricValues: formattedData.map((item) => {
            return { ...item.powerData };
          }),
        },
        {
          metricName: "Max Jump Velocity (m/s)",
          layoutKey: "e",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "maxJumpVelocity"),
          metricValues: formattedData.map((item) => {
            return { ...item.maxJumpVelocity };
          }),
        },
        {
          metricName: "Mean Jump Velocity (m/s)",
          layoutKey: "f",
          type: "double",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "meanJumpVelocity"),
          metricValues: formattedData.map((item) => {
            return { ...item.meanJumpVelocity };
          }),
        },
      ];
    }

    if (comparedSessionType.toLowerCase().includes("single")) {
      formattedDataForTable = [
        {
          metricName: "Jump Height (cm)",
          layoutKey: "a",
          type: "single",
          valueCount: 2,
          needsMetric: checkKeyInArray(formattedData, "jumpHeightData"),
          metricValues: formattedData.map((item) => {
            return { ...item.jumpHeightData };
          }),
        },
        {
          metricName: "Jump Height Asymmetry",
          layoutKey: "b",
          type: "symmetry",
          valueCount: 1,
          needsMetric: checkKeyInArray(
            formattedData,
            "jumpHeightAsymmetryData"
          ),
          metricValues: formattedData.map((item) => {
            return { ...item.jumpHeightAsymmetryData };
          }),
        },
        {
          metricName: "Flight Time (s)",
          layoutKey: "c",
          type: "single",
          valueCount: 2,
          needsMetric: checkKeyInArray(formattedData, "flightTimeData"),
          metricValues: formattedData.map((item) => {
            return { ...item.flightTimeData };
          }),
        },
        {
          metricName: "Flight Time Asymmetry",
          layoutKey: "d",
          type: "symmetry",
          valueCount: 1,
          needsMetric: checkKeyInArray(
            formattedData,
            "flightTimeAsymmetryData"
          ),
          metricValues: formattedData.map((item) => {
            return { ...item.flightTimeAsymmetryData };
          }),
        },
        {
          metricName: "Peak Power (w)",
          layoutKey: "e",
          type: "single",
          valueCount: 2,
          needsMetric: checkKeyInArray(formattedData, "powerData"),
          metricValues: formattedData.map((item) => {
            return { ...item.powerData };
          }),
        },
        {
          metricName: "Peak Power Asymmetry",
          layoutKey: "f",
          type: "symmetry",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "powerAsymmetryData"),
          metricValues: formattedData.map((item) => {
            return { ...item.powerAsymmetryData };
          }),
        },
        {
          metricName: "Contact Time (s)",
          layoutKey: "g",
          type: "single",
          valueCount: 2,
          needsMetric: checkKeyInArray(formattedData, "contactTimeData"),
          metricValues: formattedData.map((item) => {
            return { ...item.contactTimeData };
          }),
        },
        {
          metricName: "Contact Time Asymmetry",
          layoutKey: "h",
          type: "symmetry",
          valueCount: 1,
          needsMetric: checkKeyInArray(
            formattedData,
            "contactTimeAsymmetryData"
          ),
          metricValues: formattedData.map((item) => {
            return { ...item.contactTimeAsymmetryData };
          }),
        },
        {
          metricName: "RSI",
          layoutKey: "i",
          type: "single",
          valueCount: 2,
          needsMetric: checkKeyInArray(formattedData, "rsiData"),
          metricValues: formattedData.map((item) => {
            return { ...item.rsiData };
          }),
        },
        {
          metricName: "RSI Asymmetry",
          layoutKey: "j",
          type: "symmetry",
          valueCount: 1,
          needsMetric: checkKeyInArray(formattedData, "rsiAsymmetryData"),
          metricValues: formattedData.map((item) => {
            return { ...item.rsiAsymmetryData };
          }),
        },
      ];
    }

    //fomratted headers for metric table
    formattedHeadersForTable = sessionsForComparison.map((item) => ({
      athleteName: item?.playerName,
      sessionID: item?.sessionID,
    }));
  }

  //filtered gait data for graph use
  const flightTimeData = formattedData
    .map((item) => item.flightTimeData)
    .filter(
      (item) =>
        item?.athleteName ||
        item?.averageLeftValue ||
        item?.averageRightValue ||
        item?.maxLeftValue ||
        item?.maxRightValue
    );

  const flightTimeSymmetryData = formattedData
    .map((item) => item.flightTimeSymmetryData)
    .filter((item) => item?.athleteName && item?.averageSymmetryValue);

  const contactTimeData = formattedData
    .map((item) => item.contactTimeData)
    .filter(
      (item) =>
        item?.athleteName ||
        item?.averageLeftValue ||
        item?.averageRightValue ||
        item?.maxLeftValue ||
        item?.maxRightValue
    );

  const contactTimeSymmetryData = formattedData
    .map((item) => item.contactTimeSymmetryData)
    .filter((item) => item?.athleteName && item?.averageSymmetryValue);

  const cadenceData = formattedData
    .map((item) => item.cadenceData)
    .filter(
      (item) =>
        item?.athleteName ||
        item?.averageLeftValue ||
        item?.averageRightValue ||
        item?.maxLeftValue ||
        item?.maxRightValue
    );

  const peakTibialData = formattedData
    .map((item) => item.peakTibialData)
    .filter(
      (item) =>
        item?.athleteName ||
        item?.averageLeftValue ||
        item?.averageRightValue ||
        item?.maxLeftValue ||
        item?.maxRightValue
    );

  const stepLengthData = formattedData
    .map((item) => item.stepLengthData)
    .filter(
      (item) =>
        item?.athleteName ||
        item?.averageLeftValue ||
        item?.averageRightValue ||
        item?.maxLeftValue ||
        item?.maxRightValue
    );

  const stepLengthSymmetryData = formattedData
    .map((item) => item.stepLengthSymmetryData)
    .filter((item) => item?.athleteName && item?.averageSymmetryValue);

  const strideTimeData = formattedData
    .map((item) => item.strideTimeData)
    .filter(
      (item) =>
        item?.athleteName ||
        item?.averageLeftValue ||
        item?.averageRightValue ||
        item?.maxLeftValue ||
        item?.maxRightValue
    );

  const strideTimeSymmetryData = formattedData
    .map((item) => item.strideTimeSymmetryData)
    .filter((item) => item?.athleteName && item?.averageSymmetryValue);

  const swingTimeData = formattedData
    .map((item) => item.swingTimeData)
    .filter(
      (item) =>
        item?.athleteName ||
        item?.averageLeftValue ||
        item?.averageRightValue ||
        item?.maxLeftValue ||
        item?.maxRightValue
    );

  const swingTimeSymmetryData = formattedData
    .map((item) => item.swingTimeSymmetryData)
    .filter((item) => item?.athleteName && item?.averageSymmetryValue);

  //filtered jump data for graph use
  const jumpHeightData = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.jumpHeightData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.jumpHeightData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );

  const jumpLengthData = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.jumpLength)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.jumpLength)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );

  const jumpHeightSymmetryData = formattedData
    .map((item) => item.jumpHeightAsymmetryData)
    .filter(
      (item) =>
        item?.sessionType ||
        item?.athleteName ||
        item?.maxSymmetryValue ||
        item?.averageSymmetryValue
    );

  const jumpFlightData = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.flightTimeData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.flightTimeData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );
  const jumpFlightSymmetryData = formattedData
    .map((item) => item.flightTimeAsymmetryData)
    .filter(
      (item) =>
        item?.sessionType ||
        item?.athleteName ||
        item?.maxSymmetryValue ||
        item?.averageSymmetryValue
    );

  const jumpPowerData = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.powerData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.powerData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );
  const jumpPowerSymmetryData = formattedData
    .map((item) => item.powerAsymmetryData)
    .filter(
      (item) =>
        item?.sessionType ||
        item?.athleteName ||
        item?.maxSymmetryValue ||
        item?.averageSymmetryValue
    );

  const jumpContactTimeData = !comparedSessionType
    .toLowerCase()
    .includes("double")
    ? formattedData
        .map((item) => item.contactTimeData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.contactTimeData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );
  const jumpContactTimeDataSymmetry = formattedData
    .map((item) => item.contactTimeAsymmetryData)
    .filter(
      (item) =>
        item?.sessionType ||
        item?.athleteName ||
        item?.maxSymmetryValue ||
        item?.averageSymmetryValue
    );

  const rsiData = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.rsiData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.rsiData)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );

  const groundClearance = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.groundClearance)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.groundClearance)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );

  const meanJumpVelocity = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.meanJumpVelocity)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.meanJumpVelocity)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );

  const maxJumpVelocity = !comparedSessionType.toLowerCase().includes("double")
    ? formattedData
        .map((item) => item.maxJumpVelocity)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.averageLeftValue ||
            item?.averageRightValue ||
            item?.maxLeftValue ||
            item?.maxRightValue
        )
    : formattedData
        .map((item) => item.maxJumpVelocity)
        .filter(
          (item) =>
            item?.sessionType ||
            item?.athleteName ||
            item?.maxValue ||
            item?.averageValue
        );

  const rsiDataSymmetry = formattedData
    .map((item) => item.rsiAsymmetryData)
    .filter(
      (item) =>
        item?.sessionType ||
        item?.athleteName ||
        item?.maxSymmetryValue ||
        item?.averageSymmetryValue
    );

    console.log("sessionsForComparison : ", sessionsForComparison)
  //box plot gait variable declaration
  let boxPlotContactTimeData = [] 
  let boxPlotCadenceData = [] 
  let boxPlotPeakTibialAccelerationData = []
  let boxPlotStepLengthData = []
  let boxPlotStrideTimeData = []
  let boxPlotSwingTimeData = []
  let boxPlotFlightTimeData = []

  //box plot jump variable declaration
  let boxPlotJumpHeightData = [] 
  let boxPlotJumpFlightTimeData = [] 
  let boxPlotJumpPowerData = [] 
  let boxPlotJumpContactTimeData = []
  let boxPlotRSIData = []
  let boxPlotJumpLengthData = []
  let boxPlotMeanJumpVelocityData = []
  let boxPlotMaxJumpVelocityData = []


  //all gait  sessions
  if(  comparedSessionType === "Sprint" ||
  comparedSessionType === "Walk" ||
  comparedSessionType === "Jog"){
    // added conditional if no data exists, caused a crash if unedefined
    boxPlotContactTimeData = sessionsForComparison?.map(item => ({data: item?.ContactTimeData?.stepData ? item?.ContactTimeData?.stepData : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotCadenceData = sessionsForComparison?.map(item => ({data: item?.cadenceData?.stepsCadenceChartV2 ? item?.cadenceData?.stepsCadenceChartV2 : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotPeakTibialAccelerationData = sessionsForComparison?.map(item => ({data: Object.keys(item?.PeakTibialAccelerationData).length ? item?.PeakTibialAccelerationData : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotStepLengthData = sessionsForComparison?.map(item => ({data: Object.keys(item?.gaitMetricData).length ? item?.gaitMetricData?.stepLength : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotStrideTimeData = sessionsForComparison?.map(item => ({data: Object.keys(item?.gaitMetricData).length ? item?.gaitMetricData?.strideArray?.stepData : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotSwingTimeData = sessionsForComparison?.map(item => ({data: Object.keys(item?.gaitMetricData).length ? item?.gaitMetricData?.swingArray?.stepData : [], sessionID: item.sessionID, athleteName: item.playerName}))
  }
  //all non walk gait sessions
  if(comparedSessionType === "Sprint" || comparedSessionType === "Jog") {
    boxPlotFlightTimeData = sessionsForComparison?.map(item => ({data: item?.FlightTimeData?.stepData ? item?.FlightTimeData?.stepData : [], sessionID: item.sessionID, athleteName: item.playerName}))
  }

  //all single leg jump sessions 
  if(comparedSessionType.toLowerCase().includes("leg")){
    boxPlotJumpHeightData = sessionsForComparison?.map(item => ({data: item?.jumpData?.bar ? item?.jumpData?.bar : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotJumpFlightTimeData = sessionsForComparison?.map(item => ({data: item?.flightData?.bar ? item?.flightData?.bar : [], sessionID: item.sessionID, athleteName: item.playerName}))  
    boxPlotJumpPowerData = sessionsForComparison?.map(item => ({data: item?.powerData?.bar ? item?.powerData?.bar : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotJumpContactTimeData = sessionsForComparison?.map(item => ({data: item?.contactTimeData?.bar ? item?.contactTimeData?.bar : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotRSIData = sessionsForComparison?.map(item => ({data: item?.rsiData?.bar ? item?.rsiData?.bar : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotJumpLengthData = sessionsForComparison?.map(item => ({data: item?.spatials?.jumpLength?.bar ? item?.spatials?.jumpLength?.bar : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotMeanJumpVelocityData = sessionsForComparison?.map(item => ({data: item?.spatials?.meanJumpVelocity?.bar ? item?.spatials?.meanJumpVelocity?.bar : [], sessionID: item.sessionID, athleteName: item.playerName}))
    boxPlotMaxJumpVelocityData = sessionsForComparison?.map(item => ({data: item?.spatials?.maxJumpVelocity?.bar ? item?.spatials?.maxJumpVelocity?.bar : [], sessionID: item.sessionID, athleteName: item.playerName})) 
  }

  if(comparedSessionType.toLowerCase().includes("double leg")){
    console.log("double leg setting ")
   
  }
  

  console.log("boxJumpHeightData : ", boxPlotJumpHeightData) 
  console.log("boxJumpFlightTImeData : ", boxPlotJumpFlightTimeData) 
  console.log("boxPlotJumpPowerData : ", boxPlotJumpPowerData) 


  return (
    <TestSlide
      modalIn={comparingSesions}
      className="activity-modal comparison-modal"
    >
      {comparingSesions && (
        <div className="comparison-modal-container">
          <div className="header-options-container">
            <div className="comparison-modal-header">
              <div className="comparison-title">
                <h1>{comparedSessionType}</h1>
              </div>
              <div
                className="close-button"
                //resetting state whenever the close button is pressed on the modal
                onClick={() => {
                  setComparedSessionType("");
                  setComparingSessions(!comparingSesions);
                  setSessionsForComparison(false);
                  setSessionWarningTesting(false);
                  setToggleBarChart(true);
                }}
              >
                <Button value={"x"} />
              </div>
            </div>
            <div className="comparison-options">
              <div className="options-buttons">
                <div
                  className="average-button"
                  onClick={() => {
                    if (comparisonType !== "average") {
                      setComparisonType("average");
                    }
                  }}
                >
                  <Button
                    value={"Average"}
                    active={comparisonType === "average"}
                    disabled={comparisonType === "average"}
                  />
                </div>

                <div
                  className="maximum-button"
                  onClick={() => {
                    if (comparisonType !== "max") {
                      setComparisonType("max");
                    }
                  }}
                >
                  <Button
                    value={"Maximum"}
                    active={comparisonType === "max"}
                    disabled={comparisonType === "max"}
                  />
                </div>
                <div
                  className="maximum-button"
                  onClick={() => {
                    setToggleBarChart(!toggleBarChart);
                  }}
                >
                  <Button value={toggleBarChart ? "Box Plot" : "Bar Chart"} />
                </div>

                <div
                  className="maximum-button"
                  onClick={() => {
                    onButtonClick();
                  }}
                >
                  <Button value={"Download Image"} />
                </div>
              </div>
            </div>
          </div>
          <div className="table-graphs-container" ref={ref}>
            <SelectedSessionsTable
              sessionWarningTesting={sessionWarningTesting}
              formattedData={formattedData}
              sessionsForComparison={sessionsForComparison}
              colapseTable={colapseTable}
              comparedSessionType={comparedSessionType}
              gaitSession={gaitSession}
              jumpSession={jumpSession}
              setColapseTable={setColapseTable}
              setSessionWarningTesting={setSessionWarningTesting}
            />

            <SelectedSessionsMetricTable
              comparisonType={comparisonType}
              formattedData={formattedData}
              formattedDataForTable={formattedDataForTable}
              formattedHeadersForTable={formattedHeadersForTable}
              jumpSession={comparedSessionType.toLowerCase().includes("leg")}
              setCustomGridLayout={setCustomGridLayout}
              comparedSessionType={comparedSessionType}
            />

            <div className="comparison-graphs-container">
              {jumpSession && (
                <div className="jump-graphs-container">
                  <ResponsiveGridLayout
                    className="layout"
                    layouts={{ lg: customGridLayout }}
                    cols={{ lg: 1 }}
                    breakpoints={{ lg: 0 }}
                    rowHeight={750}
                    autoSize={true}
                    width={1}
                    isDraggable={false}
                    isResizable={false}
                  >
                    {jumpHeightData?.length && (
                      <div
                        key={
                          comparedSessionType.toLowerCase().includes("single")
                            ? "a"
                            : "a"
                        }
                      >
                        <ComparisonBarChart
                          metricName={
                            !comparedSessionType.toLowerCase().includes("drop")
                              ? "Jump Height"
                              : "Jump Height (Flight Time) [cm]"
                          }
                          data={jumpHeightData}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Height (cm)"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotJumpHeightData}
                        />
                      </div>
                    )}
                    {jumpHeightSymmetryData?.length &&
                      comparedSessionType.toLowerCase().includes("single") && (
                        <div
                          key={
                            comparedSessionType
                              .toLowerCase()
                              .includes("single") && "b"
                          }
                        >
                          <ComparisonAsymmetryBarChart
                            metricName={"Jump Height Asymmetry"}
                            data={jumpHeightSymmetryData}
                            comparisonType={comparisonType}
                          />
                        </div>
                      )}
                    {comparisonType?.length && (
                      <div
                        key={
                          comparedSessionType.toLowerCase().includes("single")
                            ? "c"
                            : "b"
                        }
                      >
                        <ComparisonBarChart
                          metricName={"Flight Time [s] "}
                          data={jumpFlightData}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Time (s)"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotJumpFlightTimeData}
                        />
                      </div>
                    )}

                    {/* {groundClearance.length && comparisonType?.length && (
                      <div key={"c"}>
                        <ComparisonBarChart
                          metricName={"Ground Clearance [m] "}
                          data={groundClearance}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Ground Clearance (m)"}
                          graphType={toggleBarChart}
                        />
                      </div>
                    )} */}

                    {jumpLengthData.length && comparisonType?.length && (
                      <div key={"d"}>
                        <ComparisonBarChart
                          metricName={"Jump Length [m] "}
                          data={jumpLengthData}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Meters"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotJumpLengthData}
                        />
                      </div>
                    )}

                    {jumpFlightSymmetryData?.length &&
                      comparedSessionType.toLowerCase().includes("single") && (
                        <div
                          key={
                            comparedSessionType
                              .toLowerCase()
                              .includes("single") && "d"
                          }
                        >
                          <ComparisonAsymmetryBarChart
                            metricName={"Flight Time Asymmetry"}
                            data={jumpFlightSymmetryData}
                            comparisonType={comparisonType}
                          />
                        </div>
                      )}
                    {jumpPowerData?.length && (
                      <div
                        key={
                          comparedSessionType.toLowerCase().includes("single")
                            ? "e"
                            : "e"
                        }
                      >
                        <ComparisonBarChart
                          metricName={"Peak Power (Flight Time) [W]  "}
                          data={jumpPowerData}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Power (w)"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotJumpPowerData}
                        />
                      </div>
                    )}

                    {maxJumpVelocity?.length && (
                      <div key={"e"}>
                        <ComparisonBarChart
                          metricName={"Max Jump Velocity [m/s]  "}
                          data={maxJumpVelocity}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Meters/s"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotMaxJumpVelocityData}
                        />
                      </div>
                    )}

                    {meanJumpVelocity?.length && (
                      <div key={"f"}>
                        <ComparisonBarChart
                          metricName={"Mean Jump Velocity [m/s]  "}
                          data={meanJumpVelocity}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Meters/s"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotMeanJumpVelocityData}
                        />
                      </div>
                    )}

                    {jumpPowerSymmetryData?.length &&
                      comparedSessionType.toLowerCase().includes("single") && (
                        <div
                          key={
                            comparedSessionType
                              .toLowerCase()
                              .includes("single") && "f"
                          }
                        >
                          <ComparisonAsymmetryBarChart
                            metricName={"Peak Power (Flight Time) Asymmetry"}
                            data={jumpPowerSymmetryData}
                            comparisonType={comparisonType}
                          />
                        </div>
                      )}
                    {jumpContactTimeData?.length && (
                      <div
                        key={
                          comparedSessionType.toLowerCase().includes("single")
                            ? "g"
                            : "c"
                        }
                      >
                        <ComparisonBarChart
                          metricName={"Contact Time"}
                          data={jumpContactTimeData}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Time (s)"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotJumpContactTimeData}
                          
                        />
                      </div>
                    )}

                    {jumpContactTimeDataSymmetry?.length && (
                      <div
                        key={
                          comparedSessionType
                            .toLowerCase()
                            .includes("single") && "h"
                        }
                      >
                        <ComparisonAsymmetryBarChart
                          metricName={"Contact Time Asymmetry"}
                          data={jumpContactTimeDataSymmetry}
                          comparisonType={comparisonType}
                        />
                      </div>
                    )}
                    {rsiData?.length && (
                      <div
                        key={
                          comparedSessionType.toLowerCase().includes("single")
                            ? "i"
                            : "d"
                        }
                      >
                        <ComparisonBarChart
                          metricName={"Reactive strength index (RSI)"}
                          data={rsiData}
                          comparisonType={comparisonType}
                          jumpType={
                            comparedSessionType.toLowerCase().includes("double")
                              ? "double"
                              : "none"
                          }
                          yAxisLabel={"Reactive Strength Index (RSI)"}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotRSIData}
                        />
                      </div>
                    )}
                    {rsiDataSymmetry?.length && (
                      <div
                        key={
                          comparedSessionType
                            .toLowerCase()
                            .includes("single") && "j"
                        }
                      >
                        <ComparisonAsymmetryBarChart
                          metricName={"Reactive strength index (RSI) Asymmetry"}
                          data={rsiDataSymmetry}
                          comparisonType={comparisonType}
                        />
                      </div>
                    )}
                  </ResponsiveGridLayout>
                </div>
              )}

              {gaitSession && (
                <div className="gait-graphs-container">
                  <ResponsiveGridLayout
                    className="layout"
                    layouts={{ lg: customGridLayout }}
                    cols={{ lg: 1 }}
                    breakpoints={{ lg: 0 }}
                    rowHeight={750}
                    autoSize={true}
                    width={1}
                    isDraggable={false}
                    isResizable={false}
                  >
                    {comparedSessionType !== "Walk" && (
                      <div key={"a"}>
                        <ComparisonBarChart
                          metricName={
                            comparedSessionType !== "Walk"
                              ? "Flight Time"
                              : "Swing Time"
                          }
                          data={flightTimeData}
                          comparisonType={comparisonType}
                          graphType={toggleBarChart}
                          sessionsData={boxPlotFlightTimeData}

                        />
                      </div>
                    )}

                    {comparedSessionType !== "Walk" && (
                      <div key={"b"}>
                        <ComparisonAsymmetryBarChart
                          metricName={
                            comparedSessionType !== "Walk"
                              ? "Flight Time Asymmetry"
                              : "Swing Time Asymmetry"
                          }
                          data={flightTimeSymmetryData}
                          comparisonType={comparisonType}
                          layoutKey={"b"}
                        />
                      </div>
                    )}
                    <div key={"c"}>
                      <ComparisonBarChart
                        metricName={"Contact Time"}
                        data={contactTimeData}
                        comparisonType={comparisonType}
                        layoutKey={"c"}
                        graphType={toggleBarChart}
                        sessionsData={boxPlotContactTimeData}
                      />
                    </div>

                    <div key={"d"}>
                      <ComparisonAsymmetryBarChart
                        metricName={"Contact Time Asymmetry"}
                        data={contactTimeSymmetryData}
                        comparisonType={comparisonType}
                        layoutKey={"d"}
                      />
                    </div>

                    <div key={"e"}>
                      <ComparisonBarChart
                        metricName={"Cadence"}
                        data={cadenceData}
                        comparisonType={comparisonType}
                        yAxisLabel={"Cadence (spm)"}
                        layoutKey={"e"}
                        graphType={toggleBarChart}
                        sessionsData={boxPlotCadenceData}
                      />
                    </div>

                    <div key={"f"}>
                      <ComparisonBarChart
                        metricName={"Peak Tibial Acceleration"}
                        data={peakTibialData}
                        comparisonType={comparisonType}
                        yAxisLabel={"Acceleration (g)"}
                        layoutKey={"f"}
                        graphType={toggleBarChart}
                        sessionsData={boxPlotPeakTibialAccelerationData}
                      />
                    </div>

                    <div key={"g"}>
                      <ComparisonBarChart
                        metricName={"Step Length"}
                        data={stepLengthData}
                        comparisonType={comparisonType}
                        yAxisLabel={"Step Length (m)"}
                        layoutKey={"g"}
                        graphType={toggleBarChart}
                        sessionsData={boxPlotStepLengthData}
                      />
                    </div>

                    <div key={"h"}>
                      <ComparisonAsymmetryBarChart
                        metricName={"Step Length Asymmetry"}
                        data={stepLengthSymmetryData}
                        comparisonType={comparisonType}
                        layoutKey={"h"}
                      />
                    </div>

                    <div key={"i"}>
                      <ComparisonBarChart
                        metricName={"Stride Time"}
                        data={strideTimeData}
                        comparisonType={comparisonType}
                        yAxisLabel={"Stride Time (s)"}
                        layoutKey={"g"}
                        graphType={toggleBarChart}
                        sessionsData={boxPlotStrideTimeData}
                      />
                    </div>

                    <div key={"j"}>
                      <ComparisonAsymmetryBarChart
                        metricName={"Stride Time Asymmetry"}
                        data={strideTimeSymmetryData}
                        comparisonType={comparisonType}
                        layoutKey={"h"}
                      />
                    </div>

                    <div key={"k"}>
                      <ComparisonBarChart
                        metricName={"Swing Time"}
                        data={swingTimeData}
                        comparisonType={comparisonType}
                        yAxisLabel={"Swing Time (s)"}
                        layoutKey={"k"}
                        graphType={toggleBarChart}
                        sessionsData={boxPlotSwingTimeData}
                      />
                    </div>

                    <div key={"l"}>
                      <ComparisonAsymmetryBarChart
                        metricName={"Swing Time Asymmetry"}
                        data={swingTimeSymmetryData}
                        comparisonType={comparisonType}
                        layoutKey={"l"}
                      />
                    </div>
                  </ResponsiveGridLayout>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </TestSlide>
  );
}

export default SessionComparison;
