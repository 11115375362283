/* eslint-disable */
import { useEffect } from "react";
import { useState } from "react";
import "../styles/org_detail_updated.scss";
import OrgProfileBar from "./org-profile-components/OrgProfileBar";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MyTabs from "./common/mytabs/mytabs";
import { OrganisationsList_URL, UserProfile_URL } from "../utils/urls";
import {
  thisUserIsACoachOfThisOrg,
  thisUserIsAnAdminOfThisOrg,
} from "../utils/userHelper";
import "../styles/org_details2.scss";
import Button from "./common/button/button";
import { displayDate } from "../utils/helper";
import { nanoid } from "@reduxjs/toolkit";
import {
  getOrganisationsToolkit,
  removePlayerFromOrg,
  removePodFromOrg,
  removeCoach,
  removeAdmin,
} from "store/orgSlice";
import { selectOrganisation } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import {
  getUsers,
  selectUserList,
  deactivateOrg,
  selectPodList,
} from "store/adminSlice";
import AlertDialogDemo from "./common/alertDialog/alertDialog";
import DialogDemo from "components/common/modalBox/modalBox";
import CreateAthlete from "./common/createAthlete/createAthlete";
import { getSessionList } from "store/sessionListSlice";
import { getTags } from "store/tagSlice";
import {
  getOrganisationTagsAPI,
  removeOrganisationTagAPI,
} from "api/organisations";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";
import MainButton from "./common/reuseableButtonComponent/MainButton";

function OrganisationDetail(props) {
  //modal state declared
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [deactivateModalOpen2, setDeactivateModalOpen2] = useState(false);
  const [fullDataList, setFullDataList] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedAthleteID, setSelectedAthleteID] = useState(null);

  const [listItems, setListItems] = useState(
    Array.from(fullDataList.slice(0, 20))
  );

  const [deactivateRow, setDeactivateRow] = useState("");

  //state declared below
  const [selectedTab, setSelectedTab] = useState("admins");
  const [process, setProcess] = useState("detail");
  const [hasMore, setHasMore] = useState(true);

  //admins state
  const [fullAdminList, setFullAdminList] = useState([]);
  const [admins, setAdmins] = useState([]);

  //coaches state
  const [fullCoachList, setFullCoachList] = useState([]);
  const [coaches, setCoaches] = useState([]);

  //athletes state
  const [fullPlayerList, setFullPlayerList] = useState([]);
  const [players, setPlayers] = useState([]);

  //pods state
  const [fullPodList, setFullPodList] = useState([]);
  const [pods, setPods] = useState([]);

  //tags state
  const [fullTagList, setFullTagList] = useState([]);
  const [tags, setTags] = useState([]);
  const [newTagAdded, setNewTagAdded] = useState(false);

  //deletions state used to trigger rerender of tables on deactivate
  const [editForm, setEditForm] = useState(false);

  //props declared
  const { organisation, actions, user, history } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    let org = getThisOrg();
    if (org) {
      getOrganisationTagsAPI(org.OrgID).then((tags) => {
        if (tags) {
          setFullTagList(tags.data);
        } else {
          throw console.error("Error getting Tags");
        }
      });
    }
  }, [newTagAdded]);

  //use effect updates and runs when dependench states change
  useEffect(() => {
    if (!organisation?.orgList?.length > 0 && user.id) {
      actions.getOrganisationsToolkit(user.id);
    } else {
      let org = getThisOrg();
      if (org) {
        setFullPlayerList(org.players);
        setFullCoachList(org.coaches);
        setFullAdminList(org.admins);
        setFullPodList(org.pods);
        loadItemsToDisplay(selectedTab);
      }
    }
    //if a new tag has beeen added call the tag api
    if (newTagAdded) {
      let org = getThisOrg();
      //adding small delay to setting state, await for update to come through
      setTimeout(() => {
        getOrganisationTagsAPI(org.OrgID).then((tags) => {
          if (tags) {
            setFullTagList(tags.data);
          } else {
            throw console.error("Error getting Tags");
          }
        });
        setNewTagAdded(false);
      }, 1000);
    }
    //dependency states
  }, [
    fullPlayerList,
    fullCoachList,
    user.id,
    actions,
    fullAdminList,
    fullPodList,
    props.podList,
    selectedTab,
    deactivateModalOpen,
    organisation,
    newTagAdded,
  ]);

  useEffect(() => {
    // prev if statement was if (props.userList?.length >= 0 || props.userList?.length === []) {
    if (props.userList?.length >= 0) {
      actions.getUsers();
    }
  }, []);

  useEffect(() => {
    setListItems(Array.from(fullDataList.slice(0, 20)));
    setHasMore(fullDataList.length > 20);
  }, [fullDataList]);

  //get current organisation
  function getThisOrg() {
    //checks if we have and org array and if the org array isnt empty
    if (
      organisation &&
      organisation.orgList &&
      organisation.orgList.length > 0
    ) {
      //we have an org list
      for (let i = 0; i < organisation.orgList.length; i++) {
        if (organisation.orgList[i].OrgID === parseInt(props.match.params.id)) {
          return organisation.orgList[i];
        }
      }
    }
    return undefined;
  }

  //reuseable function to display
  function loadItemsToDisplay(item) {
    let listToDisplay;

    if (item === "athletes") {
      listToDisplay = fullPlayerList;
      setPlayers(listToDisplay);
    } else if (item === "coaches") {
      listToDisplay = fullCoachList;
      setCoaches(listToDisplay);
    } else if (item === "admins") {
      listToDisplay = fullAdminList;
      setAdmins(listToDisplay);
    } else if (item === "pods") {
      listToDisplay = fullPodList;
      setPods(listToDisplay);
    } else if (item === "tags") {
      listToDisplay = fullTagList;
      setTags(listToDisplay);
    }
    setFullDataList(listToDisplay);
  }

  //hide form function
  function hideForm() {
    setEditForm((prevEditForm) => !prevEditForm);
    document.body.classList.add("modal-open");
  }

  //deactivate specific item
  function deactivate(e, item) {
    e.preventDefault();

    if (selectedTab === "admins") {
      actions.removeAdmin(item.orgAdminID);
      setDeactivateModalOpen2(false);
      document.body.classList.remove("modal-open");
    } else if (selectedTab === "coaches") {
      actions.removeCoach(item.orgCoachID);
      setDeactivateModalOpen2(false);
      document.body.classList.remove("modal-open");
    } else if (selectedTab === "pods") {
      actions.removePodFromOrg(item.podID);
      setDeactivateModalOpen2(false);
      document.body.classList.remove("modal-open");
    } else if (selectedTab === "athletes") {
      actions.removePlayerFromOrg(item.playerID);
      setDeactivateModalOpen2(false);
      document.body.classList.remove("modal-open");
    } else if (selectedTab === "tags") {
      // actions.removePlayerFromOrg(item.playerID)
      setDeactivateModalOpen2(false);
      document.body.classList.remove("modal-open");
    }
  }

  //deactivate tag
  async function deactivateTag(tagId) {
    await removeOrganisationTagAPI(tagId).then((tags) => {
      if (tags) {
        dispatch(getSessionList());
        dispatch(getTags());
      } else {
        throw console.error("Error Removing Tag");
      }
    });
    //setting new tag added state which calls the tags api again
    await setNewTagAdded(true);
  }

  //deactivate organisation
  function deactivateOrg(e) {
    e.preventDefault();

    let org = getThisOrg();
    actions.deactivateOrg(org.OrgID);

    history.push(OrganisationsList_URL);
    document.body.classList.remove("modal-open");
  }

  //get id of table row
  function getRowID(row) {
    if (row.orgAdminID) return row.orgAdminID;
    else if (row.orgCoachID) return row.orgCoachID;
    else if (row.podID) return row.podID;
    else if (row.playerID) return row.playerID;
  }

  //go to profile page, for the table on click
  function goToPlayerProfilePage(playerID) {
    if (!playerID) {
      return;
    }
    history.push(UserProfile_URL + "/" + playerID);
  }

  function openModal(playerID) {
    setSelectedAthleteID(playerID);
    setModal((prevEditForm) => !prevEditForm);
    document.body.classList.add("modal-open");
  }

  //modalHandle Function
  function modalHandler(row) {
    setDeactivateRow(row);
    setDeactivateModalOpen2(true);
    document.body.classList.add("modal-open");
  }

  //display table data function
  function displayTableData(tableHeaders) {
    let headers = tableHeaders;
    let org = getThisOrg();
    let rows = [];
    let tableStyle = "";

    if (selectedTab === "admins") {
      rows = admins;
    } else if (selectedTab === "coaches") {
      rows = coaches;
    } else if (selectedTab === "pods") {
      rows = pods;
    } else if (selectedTab === "athletes") {
      rows = players;
    } else if (selectedTab === "tags") {
      rows = tags;
    }

    function fetchMoreListItems() {
      if (listItems.length >= fullDataList.length) {
        setHasMore(false);
        return;
      }

      setTimeout(() => {
        setListItems((prevState) => [
          ...prevState,
          ...fullDataList.slice(prevState.length, prevState.length + 20),
        ]);
      }, 200);
    }

   

    return (
      <div>
        {rows.length > 0 ? (
          <div className={`org-tables-headers`}>
            {/* checks if headers exist and maps over them if they do */}
            {headers
              ? headers.map((heading) => (
                  <div
                    className={`org-tables-header ${
                      headers.indexOf(heading) === 0
                        ? "first-heading"
                        : headers.indexOf(heading) === headers.length - 1
                        ? "last-heading"
                        : ""
                    }`}
                    key={`header${headers.indexOf(heading)}`}
                  >
                    {heading}
                    {/* if the heading is first in the array it adds a counter to the amount of rows under the heading */}
                    {headers.indexOf(heading) === 0 ? ` (${rows.length})` : ""}
                  </div>
                ))
              : ""}
          </div>
        ) : (
          <div className="tables-empty-message">
            {checkTables(rows, selectedTab)}
          </div>
        )}
        <InfiniteScroll
          dataLength={listItems.length}
          next={fetchMoreListItems}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          height={"55vh"}
        >
          {listItems.map((row, i) => {
            const isBatchStart = i % 20 === 0; // assuming batch size of 20
            const animationDelay = isBatchStart ? 0 : 0.1 * (i % 20);

            return (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.2,
                  delay: animationDelay,
                  ease: [0, 0.71, 0.2, 1.0],
                }}
                style={{ marginBottom: "0.5rem" }}
                key={i}
              >
                <div
                  key={nanoid()}
                  className={`org-tables-row ${tableStyle}`}
                  id={`orgUserRow_ + ${getRowID(row)}`}
                >
                  {/* pod details */}
                  {row.podID && (
                    <div className="detail-table-container-row-column">
                      {row.ble}
                    </div>
                  )}
                  {row.podID && (
                    <div className="email-column">
                      <p>{displayDate(row.tsCreated)}</p>
                    </div>
                  )}
                  {/* end pod details */}

                  {/* player details */}
                  {selectedTab === "athletes" && row.playerID && (
                    <div
                      onClick={() => {
                        goToPlayerProfilePage(row.playerID);
                      }}
                      className="detail-table-container-row-column"
                    >
                      {row.player}
                    </div>
                  )}
                  {/* end player details */}

                  {/* tag details*/}
                  {row.tag && (
                    <div className="detail-table-container-row-column">
                      {row.tag}
                    </div>
                  )}
                  {row.tag && (
                    <div className="detail-table-container-row-column">
                      {row.detail}
                    </div>
                  )}
                  {row.tag &&
                    (thisUserIsACoachOfThisOrg(org) ||
                      thisUserIsAnAdminOfThisOrg(org)) && (
                      <div className="deactivate-div-button">
                        {/* <Button value={"Edit"} warning={false} /> */}
                        <div
                          onClick={() => {
                            deactivateTag(row.id);
                          }}
                        >
                          <Button value={"De-Activate"} warning={true} />
                        </div>
                      </div>
                    )}
                  {/* end tag details*/}

                  {(row.orgAdminID || row.orgCoachID) && (
                    <div className="detail-table-container-row-column">
                      {row.fullname}
                    </div>
                  )}
                  {(row.orgAdminID || row.orgCoachID) && (
                    <div className="email-column">
                      <p>{row.email}</p>
                    </div>
                  )}
                  {row.orgAdminID && user.permissionLevel === 1 && (
                    <div
                      className="deactivate-div-button"
                      onClick={() => modalHandler(row)}
                    >
                      <Button value={"De-Activate"} warning={true} />
                    </div>
                  )}

                  {row.orgCoachID && thisUserIsAnAdminOfThisOrg(org) && (
                    <div
                      className="deactivate-div-button"
                      onClick={() => modalHandler(row)}
                    >
                      <Button value={"De-Activate"} warning={true} />
                    </div>
                  )}
                  {row.playerID && (
                    <div
                      className="email-column"
                      onClick={() => {
                        goToPlayerProfilePage(row.playerID);
                      }}
                    >
                      <p>{row.email ? row.email : "-"}</p>
                    </div>
                  )}

                  {row.playerID && (
                    <div
                      className="col-link-button"
                      onClick={() => {
                        goToPlayerProfilePage(row.playerID);
                      }}
                    >
                      <Button value={"View Profile"} />
                    </div>
                  )}

                  {row.playerID &&
                    (thisUserIsACoachOfThisOrg(org) ||
                      thisUserIsAnAdminOfThisOrg(org)) && (
                      <div className="col-link-button">
                        <CreateAthlete
                          open={row.playerID === selectedAthleteID && modal}
                          close={setModal}
                          player={row}
                          button={
                            <Button
                              value={"Edit Athlete"}
                              onClickHandler={() => openModal(row.playerID)}
                            />
                          }
                          edit={true}
                          editFromOrg={true}
                        />
                      </div>
                    )}

                  {row.playerID &&
                    (thisUserIsACoachOfThisOrg(org) ||
                      thisUserIsAnAdminOfThisOrg(org)) && (
                      <div
                        className="deactivate-div-button"
                        onClick={() => modalHandler(row)}
                      >
                        <Button value={"De-Activate"} warning={true} />
                      </div>
                    )}

                  {/*Added to pods only if superadmin*/}
                  {user.permissionLevel === 1 && row.podID && (
                    <div
                      className="deactivate-div-button"
                      onClick={() => modalHandler(row)}
                    >
                      <Button value={"De-Activate"} warning={true} />
                    </div>
                  )}

                  {
                    <AlertDialogDemo
                      type="deactivateUser"
                      open={deactivateModalOpen2}
                      setLogoutDialogOpen={setDeactivateModalOpen2}
                      proceed={deactivate}
                      deactivateRow={deactivateRow}
                    />
                  }
                </div>
              </motion.div>
            );
          })}
        </InfiniteScroll>
      </div>
    );
  }

  //current org variable assigned value
  let org = getThisOrg();

  //check if table is empty
  function checkTables(table, tab) {
    if (selectedTab === tab) {
      if (table && table && table.length === 0) {
        return `No ${tab.charAt(0).toUpperCase() + tab.slice(1)} Found`;
      }
    }
  }

  //refresh error fixed if the org variable is a falsey value
  if (!org) {
    return <div />;
  }

  //page tabs array
  const tabs = [
    {
      title: "ADMINS",
      value: "tab1",
      display: (
        <div className="org-profile-content">
          {(thisUserIsACoachOfThisOrg(org) ||
            thisUserIsAnAdminOfThisOrg(org)) &&
          user.permissionLevel !== 1
            ? displayTableData(["Admins", "Email"])
            : displayTableData(["Admins", "Email", "Action"])}
        </div>
      ),
    },
    {
      title: "COACHES",
      value: "tab2",
      display: (
        <div className="org-profile-content">
          {thisUserIsACoachOfThisOrg(org) && !thisUserIsAnAdminOfThisOrg(org)
            ? displayTableData(["Coaches", "Email"])
            : displayTableData(["Coaches", "Email", "Action"])}
        </div>
      ),
    },
    {
      title: "ATHLETES",
      value: "tab3",
      display: (
        <div className="org-profile-content">
          {/* {displayTableData(["Athletes", "Email", "Profile", "Action"])} */}
          {displayTableData(["Athletes", "Email", "Profile", "Edit", "Action"])}
          {/* {thisUserIsACoachOfThisOrg(org) && !thisUserIsAnAdminOfThisOrg(org)
            ? displayTableData(["Athletes", "Email", "Profile", "Action"])
            : displayTableData(["Athletes", "Email", "Profile", "Edit", "Action"])} */}
        </div>
      ),
    },
    {
      title: "PODS",
      value: "tab4",
      display: (
        <div className="org-profile-content">
          {user.permissionLevel === 1
            ? displayTableData(["Pods", "Date Added", "Action"])
            : displayTableData(["Pods", "Date Added"])}
        </div>
      ),
    },
    {
      title: "TAGS",
      value: "tab5",
      display: (
        <div className="org-profile-content">
          {user.permissionLevel === 1
            ? displayTableData(["Tags", "Tag Description", "Action"])
            : displayTableData(["Tags", "Tag Description", "Action"])}
        </div>
      ),
    },
  ];

  const handleUploadIconRecall = () => {
    actions.getOrganisationsToolkit(user.id)
  }

  return (
    <div className="org-container">
      <OrgProfileBar
        orgData={org}
        orgName={org.Name}
        orgImage={org.iconURL}
        orgAddButton={
          selectedTab === "admins" && thisUserIsAnAdminOfThisOrg(org) ? (
            <div className="small_org_button_parent">
              <div
                className="small_org_button"
                onClick={() => {
                  setProcess("addAdmin");
                }}
              >
                <DialogDemo
                  button={
                    <Button
                      value={"Add Administrator"}
                      onClickHandler={openModal}
                    />
                  }
                  open={modal}
                  close={setModal}
                  process={process}
                  history={history}
                  existingOrg={org}
                />
              </div>
            </div>
          ) : selectedTab === "coaches" && thisUserIsAnAdminOfThisOrg(org) ? (
            <div className="small_org_button_parent">
              <div
                className="small_org_button"
                onClick={() => {
                  setProcess("addCoach");
                }}
              >
                <DialogDemo
                  button={
                    <Button
                      maxWidth={true}
                      value={"Add Coach"}
                      onClickHandler={openModal}
                    />
                  }
                  open={modal}
                  close={setModal}
                  process={process}
                  history={history}
                  existingOrg={org}
                />
              </div>
            </div>
          ) : selectedTab === "athletes" &&
            (thisUserIsAnAdminOfThisOrg(org) ||
              thisUserIsACoachOfThisOrg(org)) ? (
            <div className="small_org_button_parent">
              <div className="small_org_button">
                <CreateAthlete
                  open={editForm}
                  close={setEditForm}
                  button={
                    <Button
                      maxWidth={true}
                      onClickHandler={hideForm}
                      value={"Add Athlete"}
                    />
                  }
                  history={history}
                  existingOrg={org}
                />
                {/* <Link to={NewUser_URL + '/' + org.OrgID + '/' + getOriginID()}>
                                            <Button value={"Add Athlete"} maxWidth={true}/>
                                        </Link> */}
              </div>
            </div>
          ) : selectedTab === "pods" && user.permissionLevel === 1 ? (
            <div className="small_org_button_parent">
              <div className="small_org_button">
                <DialogDemo
                  matchId={props.match.params}
                  process="podRegister"
                  close={setModal}
                  open={modal}
                  button={
                    <Button
                      value={"Register New POD"}
                      onClickHandler={openModal}
                    />
                  }
                />
              </div>
            </div>
          ) : selectedTab === "tags" &&
            (thisUserIsAnAdminOfThisOrg(org) ||
              thisUserIsACoachOfThisOrg(org)) ? (
            <div className="small_org_button_parent">
              <div className="small_org_button">
                <DialogDemo
                  matchId={props.match.params}
                  process="tagRegister"
                  close={setModal}
                  open={modal}
                  button={
                    <Button value={"Add New Tag"} onClickHandler={openModal} />
                  }
                  newTagAdded={setNewTagAdded}
                />
              </div>
            </div>
          ) : (
            <div />
          )
        }
        orgDeactivateButton={
          user.permissionLevel === 1 && (
            <div className="small_org_button_parent ">
              <AlertDialogDemo
                //27579
                type="deactivateOrg"
                open={deactivateModalOpen}
                setLogoutDialogOpen={setDeactivateModalOpen}
                proceed={deactivateOrg}
              />
              <div
                className="small_org_button"
                onClick={() => {
                  setDeactivateModalOpen(
                    (prevLougoutDialogOpen) => !prevLougoutDialogOpen
                  );
                  document.body.classList.add("modal-open");
                }}
              >
                <Button value={"De-Activate"} warning={true} />
              </div>
            </div>
          )
        }
        userPermissions = {user.permissionLevel}
        handleUploadIconRecall={() =>handleUploadIconRecall}
      />
      <div>
        <div className="org-table-container">
          <MyTabs
            selectedTab={selectedTab}
            setTabs={setSelectedTab}
            tabs={tabs}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    organisation: selectOrganisation(state),
    userList: selectUserList(state),
    podList: selectPodList(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getOrganisationsToolkit,
      getUsers,
      deactivateOrg,
      removePlayerFromOrg,
      removePodFromOrg,
      removeCoach,
      removeAdmin,
    },
    dispatch
  ),
});

//exporting and connecting component to redux
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrganisationDetail)
);
