/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "react-vis/dist/style.css";
import {
  Brush,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  Bar,
} from "recharts";
import {
  NEW_BOTH_FEET_COLOR,
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "../../utils/constant";
import DownArrow from "../../components/common/icons/downArrow";
import { selectSessionList2 } from "store/sessionListSlice";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import GraphSettings from "./metric-helpers/GraphSettings";
import { selectSession } from "store/sessionSlice";
import mappingTrimAreaX from "./metric-helpers/mappingTrimArea";
import { formatTime } from "utils/helper";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

//function calculates perpendicular distance if point P from a line segment AB
function perpendicularDistance(P, A, B) {
  //if A === B return the distance from P to A
  if (A[0] == B[0] && A[1] == B[1]) {
    return Math.sqrt(Math.pow(P[0] - A[0], 2) + Math.pow(P[1] - A[1], 2));
  }

  //or use the formula for the distance from a point to a line
  else {
    let numerator = Math.abs(
      (B[1] - A[1]) * P[0] - (B[0] - A[0]) * P[1] + B[0] * A[1] - B[1] * A[0]
    );
    let denominator = Math.sqrt(
      Math.pow(B[1] - A[1], 2) + Math.pow(B[0] - A[0], 2)
    );
    return numerator / denominator;
  }
}

//ramer douglas algorithm function > array of points return a simplified version of the array of points
function ramerDouglasPeucker(points, epsilon) {
  //find the point with the maximum distance from the first and last points
  let dmax = 0;
  let index = 0;
  let result = [];

  for (let i = 1; i < points.length - 1; i++) {
    let d = perpendicularDistance(
      points[i],
      points[0],
      points[points.length - 1]
    );
    if (d > dmax) {
      index = i;
      dmax = d;
    }
  }
  // if the maximum distance > than epsilon, simplify recursively
  if (dmax > epsilon) {
    //recursively call ramerDouglasPeucker on the two subarrays of points
    let result1 = ramerDouglasPeucker(points.slice(0, index + 1), epsilon);
    let result2 = ramerDouglasPeucker(points.slice(index), epsilon);

    //concat the results, exclude the duplicated points
    result = result1.concat(result2.slice(1));
  }

  //or return the first and last points as a simplified polyline
  else {
    result = [points[0], points[points.length - 1]];
  }

  return result;
}

class Force extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //hidden: true,
      hidden: false, //default to NOT be hidden - ie show when page loads!!!!!
      leftData: "dataMin",
      rightData: "dataMax",
      data: [],
      barData: [],
      averagebarData: [],
      //hovered: false,
      hoveredLeft: null,
      hoveredRight: null,
      loading: false,
      rightRef: "",
      leftRef: "",
      animation: true,
      selected: { TotalCap: true, RightCap: true, LeftCap: true },
      chartType: { graph: "line" },
    };
  }

  mappingReferenceArea(array, typeJump) {
    const areaColour = "#49AFC8";
    const leftColour = "#9d7fda";
    const rightColour = "#70acc5";
    //maps and combines all returns to one array, each array item is a reference area component
    const referenceArea = array.flatMap((item, itemIndex) =>
      item.start.map((startValue, startIndex) => {
        return (
          <ReferenceArea
            key={`${itemIndex}-${startIndex}`}
            x1={startValue}
            x2={item.end[startIndex]}
            fill={
              typeJump === "double"
                ? areaColour
                : itemIndex == 0
                ? leftColour
                : rightColour
            }
            opacity={0.4}
          />
        );
      })
    );

    return referenceArea;
  }

  //returns shaded areas on capacitance graph
  shadeJumps(excerciseType, events, data) {
    let leftStart = [];
    let leftEnd = [];
    let rightStart = [];
    let rightEnd = [];
    let bothStart = [];
    let bothEnd = [];
    let shadeArray = [
      { left: { start: [], end: [] }, right: { start: [], end: [] } },
    ];
    let shadeBothArray = [{ both: { start: [], end: [] } }];
    //setting object to the event array
    let shadedObject = events;
    let jumpBufferValue = 0.5;
    let domainBufferLimit = 0.1;
    let bufferDomainLimits = [
      data.data.capacitanceData[0].Time,
      data.data.capacitanceData[data.data.capacitanceData.length - 1].Time,
    ];

    if (
      excerciseType === "Single Leg Countermovement Jump" ||
      excerciseType === "Single Leg Squat Jump" ||
      excerciseType === "Single Leg Drop Jump" ||
      excerciseType === "Single Leg Horizontal Jump"
    ) {
      for (let key in shadedObject) {
        switch (key) {
          case "leftStartEvents":
            shadedObject[key].forEach((value) => {
              if (value - jumpBufferValue >= bufferDomainLimits[0]) {
                leftStart.push(value - jumpBufferValue);
              } else {
                leftStart.push(domainBufferLimit);
              }
            });
            break;
          case "leftEndEvents":
            shadedObject[key].forEach((value) => {
              if (value + jumpBufferValue <= bufferDomainLimits[1]) {
                leftEnd.push(value + jumpBufferValue);
              } else {
                leftEnd.push(bufferDomainLimits[1] - domainBufferLimit);
              }
            });
            break;
          case "rightStartEvents":
            shadedObject[key].forEach((value) => {
              if (value - jumpBufferValue >= bufferDomainLimits[0]) {
                rightStart.push(value - jumpBufferValue);
              } else {
                rightStart.push(domainBufferLimit);
              }
            });
            break;
          case "rightEndEvents":
            shadedObject[key].forEach((value) => {
              if (value + jumpBufferValue <= bufferDomainLimits[1]) {
                rightEnd.push(value + jumpBufferValue);
              } else {
                rightEnd.push(bufferDomainLimits[1] - domainBufferLimit);
              }
            });
            break;
          default:
            console.log(`${key} is not a valid key`);
        }
      }

      shadeArray = [
        { start: leftStart, end: leftEnd },
        { start: rightStart, end: rightEnd },
      ];

      return this.mappingReferenceArea(shadeArray);
    }

    if (
      excerciseType === "Double Leg Countermovement jump" ||
      excerciseType === "Double Leg Drop Jump" ||
      excerciseType === "Double Leg Squat Jump" ||
      excerciseType === "Double Leg Horizontal Jump"
    ) {
      for (let key in events) {
        switch (key) {
          case "leftEndEvents":
            for (let i = 0; i < events.leftEndEvents.length; i++) {
              events.leftEndEvents[i] > events.rightEndEvents[i]
                ? bothEnd.push(
                    events.leftEndEvents[i] + jumpBufferValue <=
                      bufferDomainLimits[1]
                      ? events.leftEndEvents[i] + jumpBufferValue
                      : bufferDomainLimits[1] - domainBufferLimit
                  )
                : bothEnd.push(
                    events.rightEndEvents[i] + jumpBufferValue <=
                      bufferDomainLimits[1]
                      ? events.rightEndEvents[i] + jumpBufferValue
                      : bufferDomainLimits[1] - domainBufferLimit
                  );
            }
            break;
          case "leftStartEvents":
            for (let i = 0; i < events.leftStartEvents.length; i++) {
              events.leftStartEvents[i] < events.rightStartEvents[i]
                ? bothStart.push(
                    events.leftStartEvents[i] - jumpBufferValue >=
                      bufferDomainLimits[0]
                      ? events.leftStartEvents[i] - jumpBufferValue
                      : bufferDomainLimits[0] + domainBufferLimit
                  )
                : bothStart.push(
                    events.rightStartEvents[i] - jumpBufferValue >=
                      bufferDomainLimits[0]
                      ? events.rightStartEvents[i] - jumpBufferValue
                      : bufferDomainLimits[0] + domainBufferLimit
                  );
            }
            break;
        }
      }

      shadeBothArray = [{ start: bothStart, end: bothEnd }];

      return this.mappingReferenceArea(shadeBothArray, "double");
    }

    //added conditional that checks if the jump type is correct and if data is present before returning a processed domain for the shaded area
    if (
      excerciseType === "Double Leg Repeated Hops" &&
      (events.leftStartEvents.length || events.leftEndEvents.length)
    ) {
      shadeBothArray = [
        {
          start: [
            events.leftStartEvents[0] - jumpBufferValue >= bufferDomainLimits[0]
              ? events.leftStartEvents[0] - jumpBufferValue
              : domainBufferLimit,
          ],
          end: [
            events.leftEndEvents[events.leftEndEvents.length - 1] +
              jumpBufferValue <=
            bufferDomainLimits[1]
              ? events.leftEndEvents[events.leftEndEvents.length - 1] +
                jumpBufferValue
              : bufferDomainLimits[1] - domainBufferLimit,
          ],
        },
      ];

      return this.mappingReferenceArea(shadeBothArray, "double");
    }

    if (excerciseType === "Single Leg Repeated Hops") {
      for (let key in shadedObject) {
        switch (key) {
          case "leftStartEvents":
            shadedObject[key].forEach((value) => {
              if (value - jumpBufferValue >= bufferDomainLimits[0]) {
                leftStart.push(value - jumpBufferValue);
              } else {
                leftStart.push(domainBufferLimit);
              }
            });
            break;
          case "leftEndEvents":
            shadedObject[key].forEach((value) => {
              if (value + jumpBufferValue <= bufferDomainLimits[1]) {
                leftEnd.push(value + jumpBufferValue);
              } else {
                leftEnd.push(bufferDomainLimits[1] - domainBufferLimit);
              }
            });
            break;
          case "rightStartEvents":
            shadedObject[key].forEach((value) => {
              if (value - jumpBufferValue >= bufferDomainLimits[0]) {
                rightStart.push(value - jumpBufferValue);
              } else {
                rightStart.push(domainBufferLimit);
              }
            });
            break;
          case "rightEndEvents":
            shadedObject[key].forEach((value) => {
              if (value + jumpBufferValue <= bufferDomainLimits[1]) {
                rightEnd.push(value + jumpBufferValue);
              } else {
                rightEnd.push(bufferDomainLimits[1] - domainBufferLimit);
              }
            });
            break;
          default:
            console.log(`${key} is not a valid key`);
        }
      }

      shadeArray = [
        {
          start: [events.leftStartEvents[0]],
          end: [events.leftEndEvents[events.leftEndEvents.length - 1]],
        },
        {
          start: [events.rightStartEvents[0]],
          end: [events.rightEndEvents[events.rightEndEvents.length - 1]],
        },
      ];

      return this.mappingReferenceArea(shadeArray);
    }
  }

  toggleChart() {
    const { hidden } = this.state;
    this.setState({ hidden: !hidden });
  }

  leftHover = (v) => {
    this.setState({
      hoveredLeft: { v, name: "Foot: left" },
      hoveredRight: null,
    });
  };

  removeLeftHover = () => {
    this.setState({
      hoveredLeft: null,
      hoveredRight: null,
    });
  };

  rightHover = (v) => {
    this.setState({
      hoveredRight: { v, name: "Foot: right" },
      hoveredLeft: null,
    });
  };

  removeRightHover = () => {
    this.setState({
      hoveredRight: null,
      hoveredLeft: null,
    });
  };

  setGraphType = (graphType) => {
    this.setState({
      chartType: { graph: graphType },
    });
  };

  zoom() {
    let { rightRef, leftRef } = this.state;
    const { data } = this.state;

    //if the area that the user is trying to zoom into is greater than 0.1 on the x-axis or rightRef (where you let go of mouse) is empty, then
    if (Math.abs(rightRef - leftRef) < 0.1 || rightRef === "") {
      this.setState(() => ({
        rightRef: "",
        leftRef: "",
      }));
      return;
    }

    if (leftRef > rightRef) [leftRef, rightRef] = [rightRef, leftRef];

    this.setState(() => ({
      rightRef: "",
      leftRef: "",
      data: data.slice(),
      leftData: leftRef,
      rightData: rightRef,
    }));
  }

  zoomOut() {
    const { data } = this.state;
    this.setState(() => ({
      rightRef: "",
      leftRef: "",
      data: data.slice(),
      leftData: "dataMin",
      rightData: "dataMax",
    }));
  }

  chartMouseDown(e) {
    try {
      this.setState({ leftRef: e.activeLabel });
    } catch (error) {
      this.setState({ leftRef: "dataMin" });
    }
  }
  charMouseMove(e) {
    try {
      this.state.leftRef && this.setState({ rightRef: e.activeLabel });
    } catch (error) {
      this.state.leftRef && this.setState({ rightRef: "" });
    }
  }

  updateLegend(label) {
    const { selected } = this.state;
    let tempArr = selected;
    tempArr[label] = !tempArr[label];
    this.setState({ selected: tempArr });
  }

  //used to set x-axis tick interval to 1/20th the total size of the x-axis
  scaleX(d, right, left) {
    if (right === "dataMax" || left === "dataMin") {
      return d.length / 20;
    }
    let x = (Math.abs(right - left) + 1.2) / d.slice(-1)[0].Time; //converts the (user-selected) reference area to a percentage of the x axis
    //The added 1.2 is to make the tick marks a little further apart as you zoom in more and more
    return (x * d.length) / 20;
  }

  scaleCapacitanceData(domain, data) {
    const dataWithinDomain = data.filter(
      (item) => item["Time"] >= domain[0] && item["Time"] <= domain[1]
    );

    if (!domain || this.props.isTrimOpen) {
      return data;
    } else {
      return dataWithinDomain;
    }
  }

  displayCharts(sessionDataType) {
    const { hidden, selected } = this.state;
    const {
      sessionList,
      graphDomain = false,
      displayTotal = false,
      session,
      trimDomain = false,
      isTrimOpen = false,
    } = this.props;

    if (
      !hidden &&
      (session.runData.status === "loading" ||
        session.jumpData.status === "loading" ||
        session.rawData.status === "loading")
    )
      return displayLoadingCharts();
    else if (
      !hidden &&
      (session[sessionDataType] &&
        session[sessionDataType].data?.capacitanceData &&
        session[sessionDataType].data?.capacitanceData &&
        session[sessionDataType].data?.capacitanceData.length > 0) !== true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (session[sessionDataType].data.capacitanceData) {
      const exerciseType = sessionList.sessionList?.data.filter(
        (item) => item.ID === session[sessionDataType].sessionID
      )[0]?.ActivityName;

      const rawCapData = session[sessionDataType].data?.capacitanceData;

      const testValues = rawCapData
        .map((item) => [item["Time"], item["LeftCap"]])
        .filter((item) => item["Time"] !== null);

      console.log(session[sessionDataType].data?.capacitanceData);

      return (
        <>
          <div className="metric-container">
            <div className="metric-chart-container-parent ">
              <div className="chart-container-padding">
                {/* <button onClick={this.zoomOut.bind(this)} style={{ position: 'relative', top: -10, left: 20 }}>
                zoom out
              </button> */}

                <div className="chart-container">
                  <div className="container-pdf-flex">
                    <ResponsiveContainer width="100%" height="100%">
                      <ComposedChart
                        // width={500}
                        // height={600}
                        //data={averageStepData}
                        data={this.scaleCapacitanceData(
                          graphDomain,
                          session[sessionDataType].data?.capacitanceData
                        )}
                        //data={raw}
                        // data={ramerAlgoValues}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 25,
                        }}
                      >
                        <XAxis
                          allowDataOverflow={true}
                          //color of data ticks on axis
                          tick={{ fill: NEW_AXIS_TICK }}
                          stroke={NEW_AXIS_COLOR}
                          dataKey="Time"
                          type="number"
                          tickCount={15}
                          interval={0}
                          overflow={"true"}
                          domain={
                            graphDomain && !isTrimOpen ? graphDomain : [0]
                          }
                          tickFormatter={(value) =>
                            formatTime(value, rawCapData[rawCapData.length - 2])
                          }
                        >
                          <Label
                            value={
                              rawCapData[rawCapData.length - 2]?.["Time"] > 300
                                ? "Time (min)"
                                : "Time (s)"
                            }
                            offset={-20}
                            position="insideBottom"
                            fill={NEW_AXIS_TICK}
                          />
                        </XAxis>

                        {/*                       
                      <YAxis
                        allowDataOverflow={true}
                        style={{ userSelect: 'none' }}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                      >
                      </YAxis> */}
                        {trimDomain &&
                          isTrimOpen &&
                          mappingTrimAreaX(trimDomain)}
                        {session[sessionDataType].data.events &&
                          this.shadeJumps(
                            exerciseType,
                            session[sessionDataType].data.events,
                            session[sessionDataType]
                          )}

                        {/* <Brush dataKey="Time" height={30} travellerWidth={10} stroke={"rgba(30, 151, 214, 0.2)"} fill={"transparent"} onChange={(e)=> {
                        console.log(e)
                      }}/> */}

                        <Legend
                          onClick={(e) => this.updateLegend(e.dataKey)}
                          iconType={"circle"}
                          wrapperStyle={{
                            position: "relative",
                            marginTop: "10px",
                            marginLeft: "20px",
                            cursor: "pointer",
                          }}
                          margin={{ top: 20, left: 100, right: 0, bottom: 0 }}
                        />
                        <div>style={{ height: 10 }} </div>

                        {displayTotal && (
                          <Line
                            type="monotone"
                            dataKey={"TotalCap"}
                            name="Total"
                            stroke={
                              selected["TotalCap"]
                                ? NEW_BOTH_FEET_COLOR
                                : "ffffff"
                            }
                            dot={false}
                            strokeWidth={1}
                            connectNulls
                          />
                        )}
                        {this.state.chartType.graph === "line" && (
                          <Line
                            connectNulls
                            type="monotone"
                            dataKey={"LeftCap"}
                            name="Left Foot"
                            stroke={
                              selected["LeftCap"] ? NEW_LEFT_COLOR : "ffffff"
                            }
                            dot={false}
                            strokeWidth={1}
                          />
                        )}
                        {this.state.chartType.graph === "line" && (
                          <Line
                            connectNulls
                            type="monotone"
                            dataKey={"RightCap"}
                            name="Right Foot"
                            stroke={
                              selected["RightCap"] ? NEW_RIGHT_COLOR : "ffffff"
                            }
                            dot={false}
                            strokeWidth={1}
                          />
                        )}
                        {this.state.chartType.graph === "bar" && (
                          <Bar
                            dataKey={"LeftCap"}
                            name="Left Foot"
                            fill={
                              selected["LeftCap"] ? NEW_LEFT_COLOR : "ffffff"
                            }
                          />
                        )}
                        {this.state.chartType.graph === "bar" && (
                          <Bar
                            dataKey={"RightCap"}
                            name="Right Foot"
                            fill={
                              selected["RightCap"] ? NEW_RIGHT_COLOR : "ffffff"
                            }
                          />
                        )}
                      </ComposedChart>
                    </ResponsiveContainer>
                    {this.props.toggleableTextArea}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  render() {
    const { hidden } = this.state;
    const { sessionList } = this.props;
    let arrowClass = "";
    if (!hidden) {
      arrowClass = "flip-div";
    }

    let thisSession;

    let sessionId;
    if (this.props.match?.params?.id) {
      sessionId = parseInt(this.props.match.params.id);
    } else {
      sessionId = this.props.sessionid;
    }

    let sessionDataType = "runData";
    // session List set the type to get the correct 
    if (sessionList.sessionList) {
      thisSession = sessionList.sessionList?.data?.find(
        (s) => s.ID === sessionId
      );

      if (thisSession?.jumpTypeID === 18) {
        sessionDataType = "other";
      } else if (
        thisSession?.jumpTypeID > 0 ||
        thisSession?.activityTypeID === 2
      ) {
        sessionDataType = "jumpData";
      } else if (
        thisSession?.activityTypeID === 5
      ) {
        sessionDataType = "rawData";
      }
    }

    return (
      <div className="settings_graph">
        <div className="metric-container-parent force-data">
          <div
            className="metric-container metric-title-div"
            onClick={() => {
              this.toggleChart();
            }}
          >
            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-main-title">
              Pressure Trace{" "}
              <div style={{ paddingLeft: "0.5rem" }}>
                <MainTooltip
                  tooltipText={
                    "Vertical pressure trace for each individual foot."
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
              </div>
            
            </div>

            <div className="metric-title-extra-info">
              {/* <span>00:00:00</span> */}
            </div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon">
              {/* <DownArrow size={20} className={arrowClass} /> */}
            </div>
          </div>

          {this.displayCharts(sessionDataType)}
        </div>
        {/*         
        <GraphSettings
          graphName = {'pressureTrace'} 
          settingsPosition = {'right-side'}
          chartType = {this.state.chartType}
          setGraphType = {this.setGraphType}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessionList: selectSessionList2(state),
    session: selectSession(state),
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Force);
