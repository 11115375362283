/* eslint-disable */
import MainButton from "components/common/reuseableButtonComponent/MainButton";
import "./teamManagementProfileBar.scss";
import TagsIcon from "components/common/icons/tags";
import TeamsIcon from "components/nav-components/nav-icons/teams";
import Athletes from "components/nav-components/nav-icons/athletes";
import MoveIcon from "components/common/icons/moveIcon";
import CoachIcon from "components/common/icons/coachIcon";
import OrganisationsIcon from "components/nav-components/nav-icons/organisations";
import MainModal from "components/common/mainModal/MainModal";
import { useState } from "react";
import ReactSelect from "react-select";

// selector styles
const selectStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base) => ({
    ...base,
    borderColor: base.isFocused ? "grey" : "#0F182A",
    boxShadow: "none",
    backgroundColor: "#0F182A",
    color: "white",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return {
      ...provided,
      opacity,
      transition,
      color: "white",
    };
  },
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    color: "white",
    //option background colour#
    borderRadius: 5,
    paddingTop: 5,
    marginTop: 5,
    background: isFocused
      ? "rgba(30, 151, 214, 0.10)"
      : isSelected
      ? "#1B314B"
      : undefined,
    zIndex: 1,
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    marginTop: 10,
    padding: 0,
    backgroundColor: "#1B314B",
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    "::-webkit-scrollbar": {
      width: "3px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#0A111F",
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgba(30, 151, 214, 0.3)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "rgba(30, 151, 214, 0.4)",
    },
  }),
};

const TeamManagementProfileBar = (props) => {
  const {
    orgData,
    orgName,
    orgImage,
    listOfTeams,
    isEditingTeams,
    setIsEditingTeams,
    groupList,
    userPermissionLevel,
    orgList,
    handleOrgSelectionForTeams,
    selectedOrgID,
  } = props;

  const [selectedOrganisation, setSelectedOrganisation] = useState();

  const orgInitials = orgName
    ?.split(" ")
    ?.map((name) => name[0])
    ?.join("");
  const orgNameSplit = orgName?.split(" ");
  const orgPlayerCount = orgData?.players?.length;
  const orgCoachCount = orgData?.coaches?.length;

  const selectOptions = orgList?.map((org) => ({
    label: org.Name,
    value: org.OrgID,
  }));

  const listOfTeamIDs = listOfTeams?.map((team) => team.id);

  //get list of groups, this variable is used to get the number of groups associated with the org
  const listOfGroups = groupList.filter((group) =>
    listOfTeamIDs?.includes(group.teamid)
  );

  //get list of teams, this variable is used to get the number of teams associated with the org
  const sortedListOfTeams = listOfTeams?.filter(team => team.orgid === selectedOrgID)


  return (
    <div className="team-management-profile-bar">
      <div className="bar-container">
        <div className="team-management-profile">
          <div className="team-organisation-profile-image">
            <div className="org-profile-image">
              {orgImage ? (
                <img src={orgImage} alt="organisation initals" />
              ) : (
                <p>{orgInitials}</p>
              )}
            </div>
            <div className="org-name">
              <div className="org-profile-name">
                <h2>
                  {orgNameSplit?.[0]}
                  <br />
                  {orgNameSplit?.[1]}
                  <br />
                  {orgNameSplit?.[2]}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="team-management-stats">
          <div className="org-stats">
            <div className="org-stat">
              <h4>Teams</h4>
              <p>{sortedListOfTeams?.length}</p>
            </div>

            <div className="org-stat">
              <h4>Groups</h4>
              <p>{listOfGroups?.length}</p>
            </div>

            <div className="org-stat">
              <h4>Athletes</h4>
              <p>{orgPlayerCount}</p>
            </div>

            <div className="org-stat">
              <h4>Coaches</h4>
              <p>{orgCoachCount}</p>
            </div>
          </div>
        </div>
        <div className="team-management-settings">
          {/* <MainButton
            buttonPop={true}
            buttonText={"Tags"}
            ButtonIcon={TagsIcon}
          /> */}
          {userPermissionLevel === 1 && (
            <MainModal
              button={
                <MainButton
                  buttonPop={true}
                  buttonText={"Select Org"}
                  ButtonIcon={OrganisationsIcon}
                />
              }
              modalBody={
                <div className="select-organisation-container">
                  <div className="select-org-header">
                    <h3>Select Organisation</h3>
                    <p>
                      Select a organisation from the dropdown below. The
                      selected organisation will dictate what team data is
                      displayed.
                    </p>
                  </div>
                  <div className="select-org-body">
                    <div className="org-select">
                      <ReactSelect
                        isSearchable={false}
                        value={selectedOrganisation}
                        onChange={setSelectedOrganisation}
                        options={selectOptions}
                        styles={selectStyles}
                      ></ReactSelect>
                    </div>
                    <div
                      className="org-select-button"
                      onClick={() =>
                        handleOrgSelectionForTeams(selectedOrganisation?.value)
                      }
                    >
                      <MainButton
                        buttonText={"Select Organisation"}
                        ButtonIcon={OrganisationsIcon}
                      />
                    </div>
                  </div>
                </div>
              }
            />
          )}
          <div
            onClick={() => {
              setIsEditingTeams(!isEditingTeams);
            }}
          >
            <MainButton
              buttonPop={true}
              buttonText={"Edit Teams"}
              ButtonIcon={TeamsIcon}
              buttonActiveBorder={isEditingTeams}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamManagementProfileBar;
