import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { getOrganisationTagsAPI } from 'api/organisations';
import { getTagListForUserAPI } from "api/user";
import { resetState } from "./resetSlice";

const initialState = {
  tags: [],
  status: "idle",
};

export const getTags = createAsyncThunk("tags/getTags", async () => {
  const response = await getTagListForUserAPI();
  return response.data;
});

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTags.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload;
      state.status = "succeeded";
    });
    builder
      .addCase(getTags.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(resetState, () => {
        return initialState;
      });
  },
});

export const selectedTagList = (state) => state.tag;

export default tagSlice.reducer;
