import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRunDataAPI } from "../api/session";

export const getRunData = createAsyncThunk(
  "runData/getRunData",
  async (sessionID) => {
    const response = await getRunDataAPI(sessionID);
    return response;
  }
); 

const runInitialState = {
  loaded: false,
  sessionID: 0,
  data: {},
  status: "idle",
};

export const runDataSlice = createSlice({
    name: "runData",
    initialState: runInitialState,
    reducers: {
      resetRunData: (state) => {
        Object.assign(state, runInitialState);
      },
      setRunData: (state, action) => {
        state.loaded = true;
        state.sessionID = action.payload.sessionID;
        state.data = action.payload.data;
        state.status = "succeeded";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getRunData.pending, (state) => {
          state.status = "loading";
        })
        .addCase(getRunData.fulfilled, (state, action) => {
          state.loaded = true;
          state.status = "succeeded";
          state.sessionID = action.meta.arg;
          state.data = action.payload;
        })
        .addCase(getRunData.rejected, (state) => {
          state.status = "failed";
        });
    },
  });
  

export const { resetRunData, setRunData, updateFootstrikeData } = runDataSlice.actions;

export const selectRunData = (state) => state.runData;

export default runDataSlice.reducer;
