import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { authSlice } from './authSlice';
import { routerReducer } from 'react-router-redux';
import { adminSlice } from './adminSlice';
import sessionReducer from './sessionSlice';
import orgSliceReducer from './orgSlice';
import activityReducer from './activitySlice';
import userReducer from './userSlice';
import athleteReducer from './athleteSlice';
import playerActivityReducer from './playerActivitySlice';
import sessionListReducer from './sessionListSlice';
import tagSliceReducer from './tagSlice'
import teamSliceReducer from './teamSlice';
import groupSliceReducer from './groupSlice';
import teamsAthletesSliceReducer from './teamsAthletesSlice';
import selectedOrgForTeamSliceReducer from './selectedOrgForTeamsSlice'

const configureStoreProd = (initialState) => {
  const middlewares = [thunk];

  return configureStore({
    reducer: {
      routing: routerReducer,
      auth: authSlice.reducer,
      admin: adminSlice.reducer,
      session: sessionReducer,
      sessionList: sessionListReducer, 
      organisation: orgSliceReducer,
      activity: activityReducer,
      user: userReducer,
      athlete: athleteReducer,
      playerActivity: playerActivityReducer,
      tag: tagSliceReducer,
      team: teamSliceReducer,
      group: groupSliceReducer,
      teamAthlete: teamsAthletesSliceReducer,
      selectedOrgForTeam: selectedOrgForTeamSliceReducer,
    },
    middleware: middlewares,
    preloadedState: initialState,
  });
};

const configureStoreDev = (initialState) => {

  return configureStore({
    reducer: {
      routing: routerReducer,
      auth: authSlice.reducer,
      admin: adminSlice.reducer,
      session: sessionReducer, 
      sessionList: sessionListReducer, 
      organisation: orgSliceReducer,
      activity: activityReducer,
      user: userReducer,
      athlete: athleteReducer,
      playerActivity: playerActivityReducer,
      tag: tagSliceReducer,
      team: teamSliceReducer,
      group: groupSliceReducer,
      teamAthlete: teamsAthletesSliceReducer,
      selectedOrgForTeam: selectedOrgForTeamSliceReducer,
    },
    devTools: {
      actionSanitizer: (action) => action.type === 'session/getSessionList' ? null : action,
      stateSanitizer: (state) => ({ ...state, session: { ...state.session, sessionList: undefined }})
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    }),  
    preloadedState: initialState,
  });
};

const configureStoreFunction =
  process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStoreFunction;

