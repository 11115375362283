/* eslint-disable */
import './graph-settings.scss'
import Settings from 'components/nav-components/nav-icons/settings'
import BarChartIcon from 'components/common/icons/graphIcons/barChart'
import LineChartIcon from 'components/common/icons/graphIcons/lineChart'


export default function GraphSettings(props) {
    const { graphName, settingsPosition, chartType, setChartType } = props

    function openGraphSettings() {
        if( !document.getElementById(graphName).classList.contains('graph-settings-open')){
            document.getElementById(graphName).classList.add('graph-settings-open')
        }else{
            document.getElementById(graphName).classList.remove('graph-settings-open')    
        }
        
    }
    return(
        <div className={`graph-setttings-sidebar ${settingsPosition}`} id={graphName}>
            <div className='settings-icon' onClick={() => openGraphSettings()}>
                <Settings 
                    size = {'24'}
                    fillOpacity = {'1'}
                />
            </div>
            <div className={`chart-icon icon-hover ${chartType.graph === 'bar' && 'active'}`} onClick={() =>setChartType('bar')}>
                <BarChartIcon 
                    size = {'24'}
                    fillOpacity = {'1'}
                    fill = {'white'}
                />
            </div>
            <div className={`chart-icon icon-hover ${chartType.graph === 'line' && 'active'}`} onClick={() =>setChartType('line')}>
                <LineChartIcon 
                    size = {'34'}
                    fillOpacity = {'1'}
                    fill = {'white'}
                />
            </div>
        </div>
    )
}