import { useDispatch, useSelector } from "react-redux";
import { setCheckedAthletesForComparison } from "store/playerActivitySlice";

/* eslint-disable */
function OrderColumnsAthleteTable({
  clearCheckTitle = "Select",
  athleteName = "Athlete",
  athleteAge = "Age",
  athleteWeight = "Weight (kg)",
  athleteHeight = "Height (cm)",
  athleteShoeSize = "Shoe Size (uk)",
}) {

  const { checkedAthletesForComparison } = useSelector(
    (state) => state.playerActivity
  );

  const dispatch = useDispatch();

  function clearChecklist(){
    dispatch(setCheckedAthletesForComparison([]));

    let inputs = document.querySelectorAll(".checkboxes");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
  }

  return (
    <>
      <div className="session-list-header player-activity">
        <div className="column-check-header-div">
          <div className="checkbox-outside-table" onClick={checkedAthletesForComparison.length ? ()=> clearChecklist(): undefined}>
            <div className={`clear-header clearable ${checkedAthletesForComparison.length < 1 ? "": "clearable-active"}`}>{checkedAthletesForComparison.length < 1 ? clearCheckTitle : `Clear (${checkedAthletesForComparison.length})`}</div>
          </div>
        </div>
        <div className="column-data-header-div">
          <div className="header-data-row athlete-column">
            <div className="clear-header">{athleteName}</div>
          </div>

          <div className="header-data-row athlete-age">
            <div className="clear-header">{athleteAge}</div>
          </div>

          <div className="header-data-row athlete-weight">
            <div className="clear-header">{athleteWeight}</div>
          </div>

          <div className="header-data-row athlete-height">
            <div className="clear-header">{athleteHeight}</div>
          </div>
          <div className="header-data-row athlete-shoe-size">
            <div className="clear-header">{athleteShoeSize}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderColumnsAthleteTable;
