import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getJumpDataAPI,
  getRawMetrics,
  getRunDataAPI,
} from "../api/session";
import { resetState } from "./resetSlice";
export const getJumpData = createAsyncThunk(
  "session/getJumpData",
  async (sessionID) => {
    const response = await getJumpDataAPI(sessionID);
    return response;
  }
);
export const getRunData = createAsyncThunk(
  "session/getRunData",
  async (sessionID) => {
    const response = await getRunDataAPI(sessionID);
    return response;
  }
);

export const getRawMetricData = createAsyncThunk(
  "session/rawMetricData",
  async (sessionID) => {
    const response = await getRawMetrics(sessionID);
    return response;
  }
);
export const jumpInitialState = {
  loaded: false,
  status: "idle",
  sessionID: 0,
  data: {
    flightData: {
      bar: [],
    },
  },
  jumpHeightData: {
    bar: [],
  },
};
export const runInitialState = {
  loaded: false,
  sessionID: 0,
  data: {},
  status: "idle",
};

export const rawInitialState = {
  loaded: false,
  sessionID: 0,
  data: {},
  status: "idle",
};

export const initialState = {
  jumpData: jumpInitialState,
  runData: runInitialState,
  rawData: rawInitialState,
};
const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    resetJumpData: (state) => {
      state.jumpData = jumpInitialState;
    },
    resetRunData: (state) => {
      state.runData = runInitialState;
    },
    resetRawData: (state) => {
      state.rawData = rawInitialState;
    },
    resetAllData: (state) => {
      state.jumpData = jumpInitialState;
      state.runData = runInitialState;
      state.rawData = rawInitialState;
    },
    setJumpData: (state, action) => {
      state.jumpData = {
        loaded: true,
        sessionID: action.payload.sessionID,
        data: { ...action.payload.data },
      };
    },
    setRunData: (state, action) => {
      state.runData = {
        loaded: true,
        sessionID: action.payload.sessionID,
        data: action.payload.data,
      };
    },
    setRawData: (state, action) => {
      state.rawData = {
        loaded: true,
        sessionID: action.payload.sessionID,
        data: action.payload.data,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRunData.rejected, (state) => {
        state.runData = {
          status: "failed",
        };
      })
      .addCase(getRunData.pending, (state) => {
        state.runData = {
          ...state.runData,
          status: "loading",
        };
      })
      .addCase(getRunData.fulfilled, (state, action) => {
        state.runData = {
          loaded: true,
          status: "succeeded",
          sessionID: action.meta.arg,
          data: { ...action.payload },
        };
      })
      .addCase(getJumpData.rejected, (state) => {
        state.jumpData = {
          status: "failed",
        };
      })
      .addCase(getJumpData.pending, (state) => {
        state.jumpData = {
          ...state.jumpData,
          status: "loading",
        };
      })
      .addCase(getJumpData.fulfilled, (state, action) => {
        state.jumpData = {
          loaded: true,
          status: "succeeded",
          sessionID: action.meta.arg,
          data: { ...action.payload },
        };
      })
      .addCase(getRawMetricData.rejected, (state) => {
        state.rawData = {
          status: "failed",
        };
      })
      .addCase(getRawMetricData.pending, (state) => {
        state.rawData = {
          ...state.rawData,
          status: "loading",
        };
      })
      .addCase(getRawMetricData.fulfilled, (state, action) => {
        state.rawData = {
          loaded: true,
          status: "succeeded",
          sessionID: action.meta.arg,
          data: { ...action.payload },
        };
      })
      .addCase(resetState, () => {
        return initialState;
      });
  },
});
export const {
  startAction,
  endAction,
  resetAllData,
  resetJumpData,
  resetRunData,
  resetRawData,
  setJumpData,
  setRunData,
  setRawData
} = sessionSlice.actions;
export const selectJumpData = (state) => state.session.jumpData;
export const selectRunData = (state) => state.session.runData;
export const selectRawData = (state) => state.session.rawData;
export const selectSession = (state) => state.session;
export default sessionSlice.reducer;