/* eslint-disable */
const CalibrationIcon = ({
  size = "18",
  color = "#4B5667",
  reverse = false,
}) => {
  const style = reverse ? { transform: "scaleX(-1)" } : {};

  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-sock"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
        <path d="M12 12m-8 0a8 8 0 1 0 16 0a8 8 0 1 0 -16 0" />
        <path d="M12 2l0 2" />
        <path d="M12 20l0 2" />
        <path d="M20 12l2 0" />
        <path d="M2 12l2 0" />
      </svg>
    </div>
  );
};

export default CalibrationIcon;
