import { ReferenceArea } from "recharts";


export default function mappingTrimAreaY(coords) {
    const trimAreaColour = "white";
    return (
      <ReferenceArea
        y1={coords[0]}
        y2={coords[1]}
        fill={trimAreaColour}
        opacity={0.2}
      />
    );
  }