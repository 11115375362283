/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "react-vis/dist/style.css";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  CustomTooltipForAsymmetry,
  CustomTooltipForJumpCharts,
  getSymValue,
  CustomTooltipV2,
  gctMax,
  gctMin,
  getAverage,
  weHaveDataForThisFoot,
} from "../../utils/metrics";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_BOTH_FEET_JUMP_COLOR,
} from "../../utils/constant";
import DownArrow from "../../components/common/icons/downArrow";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import MaxMinMean from "./metric-helpers/MaxMinMean";
import Toggler from "components/common/toggler/Toggler";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

function formatXAxis(value) {
  return Math.abs(Math.round(value * 100) / 100);
}

class RSI extends React.Component {
  constructor(props) {
    super(props);

    let leftData = [];
    let rightData = [];

    this.state = {
      //hidden: true,
      hidden: false, //default to NOT be hidden - ie show when page loads!!!!!
      leftData: leftData,
      rightData: rightData,
      data: [],
      barData: [],
      averagebarData: [],
      //hovered: false,
      hoveredLeft: null,
      hoveredRight: null,
      loading: false,
      graphTypeToggle: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  filterAverageData(arr, filterValue) {
    if (this.props.graphDomain) {
      return arr.filter(
        (item) =>
          item[filterValue] >= this.props.graphDomain[0] &&
          item[filterValue] <= this.props.graphDomain[1]
      );
    } else {
      return arr;
    }
  }

  toggleChart() {
    const { hidden } = this.state;

    this.setState({ hidden: !hidden });
  }

  leftHover = (v) => {
    this.setState({
      hoveredLeft: { v, name: "Foot: left" },
      hoveredRight: null,
    });
  };

  removeLeftHover = () => {
    this.setState({
      hoveredLeft: null,
      hoveredRight: null,
    });
  };

  rightHover = (v) => {
    this.setState({
      hoveredRight: { v, name: "Foot: right" },
      hoveredLeft: null,
    });
  };

  removeRightHover = () => {
    this.setState({
      hoveredRight: null,
      hoveredLeft: null,
    });
  };

  changeGraphType = (e) => {};

  displayCharts() {
    const { hidden } = this.state;
    const { sessionList } = this.props;

    const displayedDataKey = this.state.graphTypeToggle
      ? "mRSIData"
      : "rsiData";

    if (!hidden && sessionList.jumpData.loaded === false) {
      return displayLoadingCharts();
    } else if (
      !hidden &&
      (!sessionList.jumpData.data ||
        !sessionList.jumpData.data[displayedDataKey] ||
        !sessionList.jumpData.data[displayedDataKey].bar ||
        sessionList.jumpData.data[displayedDataKey].bar.length === 0)
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (
      !hidden &&
      sessionList.jumpData.data &&
      sessionList.jumpData.data[displayedDataKey]
    ) {
      return (
        <div className="metric-container">
          <div className="metric-chart-container-parent">
            <div className="chart-container-padding">
              <div className="rsi-grid">
                <div
                  className={
                    sessionList.jumpData.data[displayedDataKey].symDataExists
                      ? "rsi"
                      : "rsi-full"
                  }
                >
                  {sessionList.jumpData.data[displayedDataKey].leftDataExists &&
                    sessionList.jumpData.data[displayedDataKey]
                      .rightDataExists && (
                      <MaxMinMean
                        leftFoot={{
                          hasData:
                            sessionList.jumpData.data[displayedDataKey]
                              .leftDataExists,
                          min: gctMin(
                            "left",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          ),
                          max: gctMax(
                            "left",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          ),
                          mean: getAverage(
                            "left",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          ),
                        }}
                        rightFoot={{
                          hasData:
                            sessionList.jumpData.data[displayedDataKey]
                              .rightDataExists,
                          min: gctMin(
                            "right",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          ),
                          max: gctMax(
                            "right",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          ),
                          mean: getAverage(
                            "right",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          ),
                        }}
                      />
                    )}

                  {sessionList.jumpData.data[displayedDataKey]
                    .bothFeetDataExists && (
                    <MaxMinMean
                      footSymmetry={{
                        hasData:
                          sessionList.jumpData.data[displayedDataKey]
                            .bothFeetDataExists,
                        min: getSymValue(
                          gctMin(
                            "both",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          )
                        ),
                        max: getSymValue(
                          gctMax(
                            "both",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          )
                        ),
                        mean: getSymValue(
                          getAverage(
                            "both",
                            this.filterAverageData(
                              sessionList.jumpData.data[displayedDataKey].bar,
                              "ts"
                            )
                          )
                        ),
                      }}
                    />
                  )}

                  <div className="chart-container">
                    <div className="container-pdf-flex">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={300}
                          data={sessionList.jumpData.data[displayedDataKey].bar}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid
                            strokeDasharray="4 4"
                            stroke={NEW_AXIS_COLOR}
                            vertical={false}
                            strokeWidth={2}
                          />

                          {sessionList.jumpData.data[displayedDataKey] &&
                          sessionList.jumpData.data[displayedDataKey].bar[0] &&
                          sessionList.jumpData.data[
                            displayedDataKey
                          ].bar[0].hasOwnProperty("ts") &&
                          sessionList.jumpData.data.td.length > 0 ? (
                            <XAxis
                              type="number"
                              domain={this.props.graphDomain}
                              dataKey={"ts"}
                              label={{
                                value: "Rep",
                                position: "insideBottom",
                                offset: 0,
                                fill: NEW_AXIS_TICK,
                              }}
                              allowDataOverflow={true}
                              stroke={NEW_AXIS_COLOR}
                              tick={false}
                            />
                          ) : (
                            <XAxis
                              dataKey="name"
                              label={{
                                value: "Rep",
                                position: "insideBottom",
                                offset: 0,
                                fill: NEW_AXIS_TICK,
                              }}
                              tick={{ fill: NEW_AXIS_TICK }}
                              stroke={NEW_AXIS_COLOR}
                            />
                          )}

                          <YAxis
                            label={{
                              value: this.state.graphTypeToggle
                                ? "Modified Reactive Strength Index (mRSI)"
                                : "Reactive strength index (RSI)",
                              angle: -90.1,
                              position: "insideLeft",
                              dy: 80,
                              fill: NEW_AXIS_TICK,
                            }}
                            tick={{ fill: NEW_AXIS_TICK }}
                            stroke={NEW_AXIS_COLOR}
                            domain={[
                              0,
                              (dataMax) => ((dataMax / 100) * 110)?.toFixed(1),
                            ]}
                          />

                          <Legend
                            verticalAlign="top"
                            layout="vertical"
                            wrapperStyle={{ top: -60, right: 0 }}
                          />

                          <Tooltip
                            cursor={{ fill: NEW_AXIS_COLOR, opacity: 0.6 }}
                            content={
                              <CustomTooltipForJumpCharts
                                desc={""}
                                data={
                                  sessionList.jumpData.data[displayedDataKey]
                                    .bar
                                }
                              />
                            }
                          />
                          {sessionList.jumpData.data[displayedDataKey]
                            .leftDataExists && (
                            <Bar
                              dataKey="Left Foot"
                              fill={NEW_LEFT_COLOR}
                              barSize={100}
                              opacity={0.7}
                            />
                          )}
                          {sessionList.jumpData.data[displayedDataKey]
                            .rightDataExists && (
                            <Bar
                              dataKey="Right Foot"
                              fill={NEW_RIGHT_COLOR}
                              barSize={100}
                              opacity={0.7}
                            />
                          )}
                          {sessionList.jumpData.data[displayedDataKey]
                            .bothFeetDataExists && (
                            <Bar
                              dataKey="Both Feet"
                              fill={NEW_BOTH_FEET_JUMP_COLOR}
                              barSize={100}
                              opacity={0.7}
                            />
                          )}
                          {getSymValue(
                            sessionList.jumpData.data[displayedDataKey].Mean
                          ) && (
                            <ReferenceLine
                              stroke="white"
                              strokeDasharray="5 5"
                              y={getSymValue(
                                sessionList.jumpData.data[displayedDataKey].Mean
                              )}
                            />
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                      {this.props.toggleableTextArea}
                    </div>
                  </div>
                </div>

                <div className="rsi-asym">
                  {sessionList.jumpData.data[displayedDataKey]
                    .symDataExists && (
                    <MaxMinMean
                      footSymmetry={{
                        hasData:
                          sessionList.jumpData.data[displayedDataKey]
                            .symDataExists,
                        min: getSymValue(
                          sessionList.jumpData.data[displayedDataKey].symMin
                        )?.toFixed(1),
                        max: getSymValue(
                          sessionList.jumpData.data[displayedDataKey].symMax
                        )?.toFixed(1),
                        mean: getSymValue(
                          sessionList.jumpData.data[displayedDataKey].symMean
                        )?.toFixed(1),
                      }}
                    />
                  )}

                  {sessionList.jumpData.data[displayedDataKey]
                    .symDataExists && (
                    <div className="chart-container asym-container">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={300}
                          data={
                            sessionList.jumpData.data[displayedDataKey]
                              .symmetryChartData
                          }
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                          stackOffset="sign"
                          layout="vertical"
                        >
                          <CartesianGrid
                            strokeDasharray="4 4"
                            stroke={NEW_AXIS_COLOR}
                            horizontal={false}
                            strokeWidth={2}
                          />

                          <XAxis
                            type="number"
                            tickFormatter={formatXAxis}
                            tick={{ fill: NEW_AXIS_TICK }}
                            stroke={NEW_AXIS_COLOR}
                            domain={this.props.xRange}
                            label={{
                              value: "Asymmetry %",
                              position: "insideBottom",
                              fill: NEW_AXIS_TICK,
                              offset: 0,
                            }}
                          />
                          {sessionList.jumpData.data[displayedDataKey] &&
                          sessionList.jumpData.data?.[displayedDataKey]
                            ?.symmetryChartData[0] &&
                          sessionList.jumpData.data?.[
                            displayedDataKey
                          ]?.symmetryChartData[0]?.hasOwnProperty("ts") &&
                          sessionList.jumpData.data.td.length > 0 ? (
                            <YAxis
                              type="number"
                              domain={this.props.graphDomain}
                              dataKey={"ts"}
                              stroke={NEW_AXIS_COLOR}
                              label={{
                                value: "Reps",
                                angle: -90,
                                position: "insideLeft",
                                dy: 20,
                                fill: NEW_AXIS_TICK,
                                offset: 30,
                              }}
                              allowDataOverflow={true}
                              tick={false}
                            />
                          ) : (
                            <YAxis
                              width={70}
                              dataKey="name"
                              type="category"
                              tick={{ fill: NEW_AXIS_TICK }}
                              stroke={NEW_AXIS_COLOR}
                              label={{
                                value: "Reps",
                                angle: -90,
                                position: "insideLeft",
                                dy: 20,
                                fill: NEW_AXIS_TICK,
                                offset: 30,
                              }}
                            />
                          )}

                          <Tooltip
                            cursor={{ fill: NEW_AXIS_COLOR, opacity: 0.6 }}
                            content={
                              <CustomTooltipForAsymmetry
                                data={
                                  sessionList.jumpData.data[displayedDataKey]
                                    .symmetryChartData
                                }
                              />
                            }
                          />

                          <Bar dataKey="Symmetry" barSize={100} opacity={0.7}>
                            {sessionList.jumpData.data[
                              displayedDataKey
                            ].symmetryChartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  entry["Symmetry"] < 0
                                    ? NEW_LEFT_COLOR
                                    : NEW_RIGHT_COLOR
                                }
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  // displayCharts() {
  //   const { hidden } = this.state;
  //   const { sessionList } = this.props;

  //   const displayedDataKey = this.state.graphTypeToggle
  //     ? "mRSIData"
  //     : "rsiData";

  //   if (!hidden && sessionList.jumpData.loaded === false)
  //     return displayLoadingCharts();
  //   else if (
  //     !hidden &&
  //     (sessionList.jumpData.data &&
  //       sessionList.jumpData.data?.[displayedDataKey] &&
  //       sessionList.jumpData.data?.[displayedDataKey].bar &&
  //       sessionList.jumpData.data?.[displayedDataKey].bar.length > 0) !== true
  //   ) {
  //     return (
  //       <div className="no-data-container">
  //         No Data Available For This Metric
  //       </div>
  //     );
  //   } else if (
  //     !hidden &&
  //     sessionList.jumpData.data &&
  //     sessionList.jumpData.data?.[displayedDataKey]
  //   ) {
  //     return (
  //       <div className="metric-container">
  //         <div className="metric-chart-container-parent ">
  //           <div className="chart-container-padding">
  //             <div className="rsi-grid">
  //               <div
  //                 className={
  //                   sessionList.jumpData.data?.[displayedDataKey].symDataExists
  //                     ? "rsi"
  //                     : "rsi-full"
  //                 }
  //               >
  //                 {sessionList.jumpData.data?.[displayedDataKey]
  //                   .leftDataExists &&
  //                   sessionList.jumpData.data?.[displayedDataKey]
  //                     .rightDataExists && (
  //                     <MaxMinMean
  //                       leftFoot={{
  //                         hasData:
  //                           sessionList.jumpData.data?.[displayedDataKey]
  //                             .leftDataExists,
  //                         min: gctMin(
  //                           "left",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         ),
  //                         max: gctMax(
  //                           "left",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         ),
  //                         mean: getAverage(
  //                           "left",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         ),
  //                       }}
  //                       rightFoot={{
  //                         hasData:
  //                           sessionList.jumpData.data?.[displayedDataKey]
  //                             .rightDataExists,
  //                         min: gctMin(
  //                           "right",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         ),
  //                         max: gctMax(
  //                           "right",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         ),
  //                         mean: getAverage(
  //                           "right",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         ),
  //                       }}
  //                     />
  //                   )}

  //                 {sessionList.jumpData.data?.[displayedDataKey]
  //                   .bothFeetDataExists && (
  //                   <MaxMinMean
  //                     footSymmetry={{
  //                       hasData:
  //                         sessionList.jumpData.data?.[displayedDataKey]
  //                           .bothFeetDataExists,
  //                       min: getSymValue(
  //                         gctMin(
  //                           "both",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         )
  //                       ),
  //                       max: getSymValue(
  //                         gctMax(
  //                           "both",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         )
  //                       ),
  //                       mean: getSymValue(
  //                         getAverage(
  //                           "both",
  //                           this.filterAverageData(
  //                             sessionList.jumpData.data?.[displayedDataKey].bar,
  //                             "ts"
  //                           )
  //                         )
  //                       ),
  //                     }}
  //                   />
  //                 )}

  //                 <div className="chart-container">
  //                   <div className="container-pdf-flex">
  //                     <ResponsiveContainer width="100%" height="100%">
  //                       <BarChart
  //                         width={500}
  //                         height={300}
  //                         data={
  //                           sessionList.jumpData.data?.[displayedDataKey].bar
  //                         }
  //                         margin={{
  //                           top: 5,
  //                           right: 30,
  //                           left: 20,
  //                           bottom: 5,
  //                         }}
  //                       >
  //                         <CartesianGrid
  //                           strokeDasharray="4 4"
  //                           stroke={NEW_AXIS_COLOR}
  //                           vertical={false}
  //                           strokeWidth={2}
  //                         />

  //                         {sessionList.jumpData.data?.[
  //                           displayedDataKey
  //                         ] && sessionList.jumpData.data?.[
  //                           displayedDataKey
  //                         ].bar[0].hasOwnProperty("ts") &&
  //                         sessionList.jumpData.data.td.length > 0 ? (
  //                           <XAxis
  //                             type="number"
  //                             domain={this.props.graphDomain}
  //                             dataKey={"ts"}
  //                             label={{
  //                               value: "Rep",
  //                               position: "insideBottom",
  //                               offset: 0,
  //                               fill: NEW_AXIS_TICK,
  //                             }}
  //                             allowDataOverflow={true}
  //                             stroke={NEW_AXIS_COLOR}
  //                             tick={false}
  //                           />
  //                         ) : (
  //                           <XAxis
  //                             dataKey="name"
  //                             label={{
  //                               value: "Rep",
  //                               position: "insideBottom",
  //                               offset: 0,
  //                               fill: NEW_AXIS_TICK,
  //                             }}
  //                             tick={{ fill: NEW_AXIS_TICK }}
  //                             stroke={NEW_AXIS_COLOR}
  //                           />
  //                         )}

  //                         <YAxis
  //                           label={{
  //                             value: this.state.graphTypeToggle
  //                               ? "Modified Reactive Strength Index (mRSI)"
  //                               : "Reactive strength index (RSI)",
  //                             angle: -90.1,
  //                             position: "insideLeft",
  //                             dy: 80,
  //                             fill: NEW_AXIS_TICK,
  //                           }}
  //                           tick={{ fill: NEW_AXIS_TICK }}
  //                           stroke={NEW_AXIS_COLOR}
  //                           domain={[
  //                             0,
  //                             (dataMax) => ((dataMax / 100) * 110)?.toFixed(1),
  //                           ]}
  //                         />

  //                         {/* <Legend  wrapperStyle={{position: 'relative', marginTop: '0px', marginLeft: '20px'}} margin={{ top: 20, left: 100, right: 0, bottom: 0}} /> */}
  //                         <Legend
  //                           verticalAlign="top"
  //                           layout="vertical"
  //                           wrapperStyle={{ top: -60, right: 0 }}
  //                         />

  //                         <Tooltip
  //                           cursor={{ fill: NEW_AXIS_COLOR, opacity: 0.6 }}
  //                           content={
  //                             <CustomTooltipForJumpCharts
  //                               desc={""}
  //                               data={
  //                                 sessionList.jumpData.data?.[displayedDataKey]
  //                                   .bar
  //                               }
  //                             />
  //                           }
  //                         />
  //                         {sessionList.jumpData.data?.[displayedDataKey]
  //                           .leftDataExists && (
  //                           <Bar
  //                             dataKey="Left Foot"
  //                             fill={NEW_LEFT_COLOR}
  //                             barSize={100}
  //                             opacity={0.7}
  //                           />
  //                         )}
  //                         {sessionList.jumpData.data?.[displayedDataKey]
  //                           .rightDataExists && (
  //                           <Bar
  //                             dataKey="Right Foot"
  //                             fill={NEW_RIGHT_COLOR}
  //                             barSize={100}
  //                             opacity={0.7}
  //                           />
  //                         )}
  //                         {sessionList.jumpData.data?.[displayedDataKey]
  //                           .bothFeetDataExists && (
  //                           <Bar
  //                             dataKey="Both Feet"
  //                             fill={NEW_BOTH_FEET_JUMP_COLOR}
  //                             barSize={100}
  //                             opacity={0.7}
  //                           />
  //                         )}
  //                         {getSymValue(
  //                           sessionList.jumpData.data?.[displayedDataKey].Mean
  //                         ) && (
  //                           <ReferenceLine
  //                             stroke="white"
  //                             strokeDasharray="5 5"
  //                             y={getSymValue(
  //                               sessionList.jumpData.data?.[displayedDataKey]
  //                                 .Mean
  //                             )}
  //                           />
  //                         )}
  //                       </BarChart>
  //                     </ResponsiveContainer>
  //                     {this.props.toggleableTextArea}
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="rsi-asym">
  //                 {sessionList.jumpData.data?.[displayedDataKey]
  //                   .symDataExists && (
  //                   <MaxMinMean
  //                     footSymmetry={{
  //                       hasData:
  //                         sessionList.jumpData.data?.[displayedDataKey]
  //                           .symDataExists,
  //                       min: getSymValue(
  //                         sessionList.jumpData.data?.[displayedDataKey].symMin
  //                       )?.toFixed(1),
  //                       max: getSymValue(
  //                         sessionList.jumpData.data?.[displayedDataKey].symMax
  //                       )?.toFixed(1),
  //                       mean: getSymValue(
  //                         sessionList.jumpData.data?.[displayedDataKey].symMean
  //                       )?.toFixed(1),
  //                     }}
  //                   />
  //                 )}

  //                 {sessionList.jumpData.data?.[displayedDataKey]
  //                   .symDataExists && (
  //                   <div className="chart-container asym-container">
  //                     <ResponsiveContainer width="100%" height="100%">
  //                       <BarChart
  //                         width={500}
  //                         height={300}
  //                         data={
  //                           sessionList.jumpData.data?.[displayedDataKey]
  //                             .symmetryChartData
  //                         }
  //                         margin={{
  //                           top: 5,
  //                           right: 30,
  //                           left: 20,
  //                           bottom: 5,
  //                         }}
  //                         stackOffset="sign"
  //                         layout="vertical"
  //                       >
  //                         <CartesianGrid
  //                           strokeDasharray="4 4"
  //                           stroke={NEW_AXIS_COLOR}
  //                           horizontal={false}
  //                           strokeWidth={2}
  //                         />

  //                         <XAxis
  //                           type="number"
  //                           tickFormatter={formatXAxis}
  //                           tick={{ fill: NEW_AXIS_TICK }}
  //                           stroke={NEW_AXIS_COLOR}
  //                           domain={this.props.xRange}
  //                           label={{
  //                             value: "Asymmetry %",
  //                             position: "insideBottom",
  //                             fill: NEW_AXIS_TICK,
  //                             offset: 0,
  //                           }}
  //                         />
  //                         {sessionList.jumpData.data &&  sessionList.jumpData.data?.[
  //                           displayedDataKey
  //                         ].symmetryChartData[0]?.hasOwnProperty("ts") &&
  //                         sessionList.jumpData.data.td.length > 0 ? (
  //                           <YAxis
  //                             type="number"
  //                             domain={this.props.graphDomain}
  //                             dataKey={"ts"}
  //                             stroke={NEW_AXIS_COLOR}
  //                             label={{
  //                               value: "Reps",
  //                               angle: -90,
  //                               position: "insideLeft",
  //                               dy: 20,
  //                               fill: NEW_AXIS_TICK,
  //                               offset: 30,
  //                             }}
  //                             allowDataOverflow={true}
  //                             tick={false}
  //                           />
  //                         ) : (
  //                           <YAxis
  //                             width={70}
  //                             dataKey="name"
  //                             type="category"
  //                             tick={{ fill: NEW_AXIS_TICK }}
  //                             stroke={NEW_AXIS_COLOR}
  //                             label={{
  //                               value: "Reps",
  //                               angle: -90,
  //                               position: "insideLeft",
  //                               dy: 20,
  //                               fill: NEW_AXIS_TICK,
  //                               offset: 30,
  //                             }}
  //                           />
  //                         )}

  //                         <Tooltip
  //                           cursor={{ fill: NEW_AXIS_COLOR, opacity: 0.6 }}
  //                           content={
  //                             <CustomTooltipForAsymmetry
  //                               data={
  //                                 sessionList.jumpData.data?.[displayedDataKey]
  //                                   .symmetryChartData
  //                               }
  //                             />
  //                           }
  //                         />

  //                         <Bar dataKey="Symmetry" barSize={100} opacity={0.7}>
  //                           {sessionList.jumpData.data?.[
  //                             displayedDataKey
  //                           ].symmetryChartData.map((entry, index) => (
  //                             <Cell
  //                               key={`cell-${index}`}
  //                               fill={
  //                                 entry["Symmetry"] < 0
  //                                   ? NEW_LEFT_COLOR
  //                                   : NEW_RIGHT_COLOR
  //                               }
  //                             />
  //                           ))}
  //                         </Bar>
  //                       </BarChart>
  //                     </ResponsiveContainer>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }
  // }

  render() {
    const { hidden } = this.state;

    let arrowClass = "";
    if (!hidden) {
      arrowClass = "flip-div";
    }

    return (
      <div className="metric-container-parent reactive-strength-index">
        <div className="metric-container metric-title-div">
          <div className="metric-title-left-padding" />

          <div className="metric-title-left-icon"></div>

          <div className="metric-title-left-padding" />

          <div className="metric-title-main-title">
            {this.state.graphTypeToggle
              ? "Modified Reactive Strength Index (mRSI)"
              : "Reactive strength index (RSI)"}
            <div className="title-with-toggler-flex">
              <div className="with-toggle-title">
                <div style={{ paddingLeft: "0.5rem" }}>
                  <MainTooltip
                    tooltipText={
                      this.state.graphTypeToggle
                        ? "The ratio of jump height to contact time"
                        : "The ratio of flight time to contact time for each jump"
                    }
                    tooltipIcon={"info"}
                    iconSize={25}
                  />
                </div>
              </div>
              {/* contitional toggler that only appears if both datasets are present */}
              {this.props.sessionList.jumpData.data?.mRSIData &&
                this.props.sessionList.jumpData.data?.rsiData && (
                  <Toggler
                    isToggled={this.state.graphTypeToggle}
                    setIsToggled={(value) =>
                      this.setState({ graphTypeToggle: value })
                    }
                    changeToggleDisplayed={this.changeGraphType}
                    togglerHeadings={{ left: "RSI", right: "MRSI" }}
                  />
                )}
            </div>
          </div>

          <div className="metric-title-left-padding" />

          <div className="metric-title-left-icon"></div>
        </div>

        {this.displayCharts()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionList: state.session,
  };
}

export default connect(mapStateToProps)(RSI);
