import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { AppContainer } from 'react-hot-loader';
import { syncHistoryWithStore } from 'react-router-redux';
import Root from './components/Root';
//import './styles/app.less';
import configureStore from './store/configureStore';
import { checkAuth } from './store/authSlice';

//require('./favicon.ico'); // Tell webpack to load favicon.ico



require('./styles/main.scss');

// debugger;
const store = configureStore();

// Create an enhanced history that syncs navigation events with the store

const history = syncHistoryWithStore(createBrowserHistory(), store);


if (localStorage.user) // if we have user in local storage - add it to redux store!!!
{
  store.dispatch(checkAuth());
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.Fragment>
    <Root store={store} history={history} />
  </React.Fragment>
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default; // eslint-disable-line global-require
    ReactDOM.createRoot(document.getElementById('root')).render(
      <AppContainer>
      <React.Fragment>
        <NewRoot store={store} history={history} />
      </React.Fragment>
      </AppContainer>,
    );
  });
}