/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAthletesHistoricalActivity, selectUserData } from "store/userSlice";
import Spinner from "components/common/spinner/spinner";
import DisplayData from "components/profileFunctions/profileFunctions";
import StanceToStrideRatio from "components/metrics/StanceToStrideRatio";
import DailyLoad from "components/metrics/DailyLoad";
import OverviewMetricTable from "components/metrics/OverviewMetricTable";
import GroundContactTimeAsymmetryOverview from "components/metrics/GroundContactTimeAsymmetryOverview";

const AthleteActivityOverviewItem = (props) => {
  const {
    activity,
    gaitData,
    loadData,
    asymData,
    athleteid,
    setPosition,
    overviewTableData,
    handleLoadClick
  } = props;

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [graphIsOpen, setGraphIsOpen] = useState(true);
  const prevActivityIdRef = useRef(null); // Initial value is null

  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);


  useEffect(() => {
    if (
      activity?.id &&
      activity.id !== prevActivityIdRef.current &&
      !("activity" + activity.id in userData.activityObject)
    ) {
      getChartData(activity.id);
      prevActivityIdRef.current = activity.id; // Store the activity ID that we've fetched
    }
  }, [activity, userData]);

  useEffect(() => {
    if (
      loading === true &&
      activity &&
      activity.id &&
      "activity" + activity.id in userData.activityObject
    ) {
      setLoading(false);
    }
  }, [loading, userData, activity]);

  const getChartData = async (activityID) => {
    if (activityID > 0) {
      if ("activity" + activityID in userData.activityObject) {
        console.log(
          "activity" + activityID in userData.activityObject,
          "activityObject hasOwnProp"
        );
      } else {
        setLoading(true);
        console.log(
          "getAthletesHistoricalActivity call in item",
          "userData.userid:",
          userData.userid,
          "athleteid:",
          athleteid,
          "activityID:",
          activityID
        );
        dispatch(
          getAthletesHistoricalActivity({
            userid: athleteid,
            activityid: activityID,
          })
        );
      }
    }
  };

  const toggleGraph = () => {
    setGraphIsOpen((prevState) => !prevState);
  };



  return (
    <div className={"column activity-overview "}>
      <div className={"column activity-overview "}>
        {overviewTableData && (
          <div style={{ width: "95%" }}>
            {!overviewTableData.length > 0 ? (
              <Spinner
                cls="spinner-user-profile"
                classnames={"user-profile-loader"}
              />
            ) : (
              <OverviewMetricTable overviewTableData={overviewTableData} />
            )}
          </div>
        )}
        {loadData && (
          <div className="gait-activities">
            <DailyLoad data={loadData} handleLoadClick={handleLoadClick}/>
          </div>
        )}

        {!gaitData &&
          !loadData &&
          !asymData &&
          !overviewTableData &&
          !loading && 
          (
            <div
              className="activity-overview-chart-container"
              style={{ marginTop: "10px" }}
            >
              {loading ? (
                <Spinner
                  cls="spinner-user-profile"
                  classnames={"user-profile-loader"}
                />
              ) : (
                <DisplayData
                  activity={activity}
                  userData={userData}
                  setPosition={setPosition}
                  setGraphIsOpen={setGraphIsOpen}
                  setTableData={setTableData}
                  toggleGraph={toggleGraph}
                  graphIsOpen={graphIsOpen}
                  tableData={tableData}
                />
              )}
            </div>
          )}
        {gaitData && gaitData.length && (
          <div className="gait-activities">
            <StanceToStrideRatio
              data={gaitData}
              graphIsOpen={graphIsOpen}
              toggleGraph={toggleGraph}
              setPosition={setPosition}
              setGraphIsOpen={setGraphIsOpen}
            />
          </div>
        )}

        {asymData && asymData.length && (
          <div className="gait-activities">
            <GroundContactTimeAsymmetryOverview
              data={asymData}
              setPosition={setPosition}
              graphIsOpen={graphIsOpen}
              toggleGraph={toggleGraph}
              setGraphIsOpen={setGraphIsOpen}
            />
          </div>
        )}
      </div>{" "}
    </div>
  );
};

export default AthleteActivityOverviewItem;
