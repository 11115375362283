import SignUpForm from './SignUpForm';

const SignUpPage = () => (
  <div className="loginContainer">

          <div className="right_login">
                    <SignUpForm />
          </div>
  </div>
);

export default SignUpPage;
