/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "react-vis/dist/style.css";
import { bindActionCreators } from "redux";
import {
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "../../utils/constant";
import {
  gctMax,
  gctMin,
  getAverage,
  getTooltipStyle,
  weHaveDataForThisFoot,
} from "../../utils/metrics";
import { selectSessionList2 } from "store/sessionListSlice";
import { getSessionList } from "store/sessionListSlice";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import MaxMinMean from "./metric-helpers/MaxMinMean";
import GraphSettings from "./metric-helpers/GraphSettings";
import { selectSession } from "store/sessionSlice";
import mappingTrimAreaX from "./metric-helpers/mappingTrimArea";
import { formatTime } from "utils/helper";
import WarningTooltip from "components/common/warningTooltip/WarningTooltip";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

const CustomTooltipForCadence = ({ active, payload, label, data }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="label">Time (s) : {`${label}`}</div>

        {payload.length >= 1 && (
          <div className={`${getTooltipStyle(payload[0])}`}>
            Stride Length (m) : {` ${payload[0].value}`}
          </div>
        )}
      </div>
    );
  }

  return null;
};

class StrideLength extends React.Component {
  constructor(props) {
    super(props);

    let leftData = [];
    let rightData = [];

    this.state = {
      //hidden: true,
      hidden: false, //default to show data
      leftData: leftData,
      rightData: rightData,
      data: [],
      stepData: [],
      averageStepData: [],
      //hovered: false,
      hoveredLeft: null,
      hoveredRight: null,
      loading: false,
      chartType: { graph: "line" },
      graphSettingsLoaction: "left-side",
    };
  }

  componentDidMount() {}

  filterAverageData(arr, filterValue) {
    if (this.props.graphDomain) {
      return arr.filter(
        (item) =>
          item[filterValue] >= this.props.graphDomain[0] &&
          item[filterValue] <= this.props.graphDomain[1]
      );
    } else {
      return arr;
    }
  }

  setGraphType = (graphType) => {
    this.setState({
      chartType: { graph: graphType },
    });
  };

  displayCharts() {
    const { hidden } = this.state;
    const { graphDomain, session, trimDomain, isTrimOpen = false } = this.props;

    //if (loading)
    if (!hidden && session.runData.status === "loading")
      return displayLoadingCharts();
    else if (
      !hidden &&
      (session.runData.data.gaitMetricData.strideLength &&
        session.runData.data.gaitMetricData.strideLength &&
        session.runData.data.gaitMetricData.strideLength.length > 0) !== true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (!hidden && session.runData.data.gaitMetricData.strideLength) {
      return (
        <div className="metric-container">
          <div className="metric-chart-container-parent ">
            <div className="chart-container-padding">
              <div className="chart-container">
                <MaxMinMean
                  leftFoot={{
                    hasData:
                      session.runData.data.gaitMetricData &&
                      weHaveDataForThisFoot(
                        "left",
                        session.runData.data.gaitMetricData.strideLength
                      ),
                    min: gctMin(
                      "left",
                      this.filterAverageData(
                        session.runData.data.gaitMetricData.strideLength,
                        "name"
                      )
                    ),
                    max: gctMax(
                      "left",
                      this.filterAverageData(
                        session.runData.data.gaitMetricData.strideLength,
                        "name"
                      )
                    ),
                    mean: getAverage(
                      "left",
                      this.filterAverageData(
                        session.runData.data.gaitMetricData.strideLength,
                        "name"
                      )
                    ),
                  }}
                  rightFoot={{
                    hasData:
                      session.runData.data.gaitMetricData &&
                      weHaveDataForThisFoot(
                        "right",
                        session.runData.data.gaitMetricData.strideLength
                      ),
                    min: gctMin(
                      "right",
                      this.filterAverageData(
                        session.runData.data.gaitMetricData.strideLength,
                        "name"
                      )
                    ),
                    max: gctMax(
                      "right",
                      this.filterAverageData(
                        session.runData.data.gaitMetricData.strideLength,
                        "name"
                      )
                    ),
                    mean: getAverage(
                      "right",
                      this.filterAverageData(
                        session.runData.data.gaitMetricData.strideLength,
                        "name"
                      )
                    ),
                  }}
                />
                <div className="container-pdf-flex">
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                      width={500}
                      height={300}
                      //data={averageStepData}
                      data={session.runData.data.gaitMetricData.strideLength}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={NEW_AXIS_COLOR}
                      />

                      <XAxis
                        label={{
                          value:
                            session.runData.data.gaitMetricData.strideLength[
                              session.runData.data.gaitMetricData.strideLength
                                .length - 2
                            ]?.["name"] > 300
                              ? "Time (min)"
                              : "Time (s)",
                          position: "bottom",
                          fill: NEW_AXIS_TICK,
                          offset: -6,
                        }}
                        dataKey="name"
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                        type="number"
                        tickCount={15}
                        interval={2}
                        domain={graphDomain && !isTrimOpen ? graphDomain : [0]}
                        allowDataOverflow
                        tickFormatter={(value) =>
                          formatTime(
                            value,
                            session.runData.data.gaitMetricData.strideLength[
                              session.runData.data.gaitMetricData.strideLength
                                .length - 2
                            ]
                          )
                        }
                      />

                      <YAxis
                        label={{
                          value: "Stride Length (m)",
                          angle: -90.1,
                          position: "insideLeft",
                          fill: NEW_AXIS_TICK,
                        }}
                        //domain={this.props.yRange}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                      />

                      {this.props.trimDomain &&
                        isTrimOpen &&
                        mappingTrimAreaX(this.props.trimDomain)}
                      <Tooltip
                        content={
                          <CustomTooltipForCadence
                            data={
                              session.runData.data.gaitMetricData.strideLength
                            }
                          />
                        }
                      />
                      {/* <Legend wrapperStyle={{ position: 'relative', marginTop: '0px', marginLeft: '20px' }} /> */}
                      <Legend
                        verticalAlign="top"
                        layout="vertical"
                        wrapperStyle={{ top: -60, right: 0 }}
                      />
                      {this.state.chartType.graph === "line" && (
                        <>
                          <Line
                            connectNulls={false}
                            dataKey="Left Foot"
                            stroke={NEW_LEFT_COLOR}
                            dot={
                              session.runData.data.gaitMetricData.strideLength
                                .length > 50
                                ? false
                                : true
                            }
                          />
                          <Line
                            connectNulls={false}
                            dataKey="Right Foot"
                            stroke={NEW_RIGHT_COLOR}
                            dot={
                              session.runData.data.gaitMetricData.strideLength
                                .length > 50
                                ? false
                                : true
                            }
                          />
                        </>
                      )}

                      {this.state.chartType.graph === "bar" && (
                        <>
                          <Bar
                            dataKey="Left Foot"
                            fill={NEW_LEFT_COLOR}
                            barSize={5}
                            opacity={0.7}
                          />
                          <Bar
                            dataKey="Right Foot"
                            fill={NEW_RIGHT_COLOR}
                            barSize={5}
                            opacity={0.7}
                          />
                        </>
                      )}
                    </ComposedChart>
                  </ResponsiveContainer>
                  {this.props.toggleableTextArea}
                </div>
              </div>
              <div style={{ height: 80 }} />
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { hidden } = this.state;

    let widthOfWindow = window.innerWidth;
    if (
      widthOfWindow > 820 &&
      this.state.graphSettingsLoaction !== "left-side"
    ) {
      this.setState({ graphSettingsLoaction: "left-side" });
    }

    if (
      widthOfWindow <= 820 &&
      this.state.graphSettingsLoaction !== "right-side"
    ) {
      this.setState({ graphSettingsLoaction: "right-side" });
    }

    window.addEventListener("resize", () => {
      widthOfWindow = window.innerWidth;
      if (
        widthOfWindow > 820 &&
        this.state.graphSettingsLoaction !== "left-side"
      ) {
        this.setState({ graphSettingsLoaction: "left-side" });
      }

      if (
        widthOfWindow <= 820 &&
        this.state.graphSettingsLoaction !== "right-side"
      ) {
        this.setState({ graphSettingsLoaction: "right-side" });
      }
    });

    let arrowClass = "";
    if (!hidden) {
      arrowClass = "flip-div";
    }

    return (
      <div className="settings_graph">
        <div className="metric-container-parent step-length">
          <div className="metric-container metric-title-div">
            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-main-title">
              Stride Length [m]
              <div style={{ paddingLeft: "0.5rem" }}>
                <MainTooltip
                  tooltipText={
                    "Stride length is the distance covered between the initial contact of one foot and the next contact of the same foot through the swing phase."
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
              </div>
              <div>
                <WarningTooltip
                  warningText={"This metric is in development."}
                  warningIconSize={20}
                  warningIconColor={"#e67e22"}
                  warningIconType={"infoFilled"}
                />
              </div>
            </div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>
          </div>

          {this.displayCharts()}
        </div>
        {this.props.showSettings && (
          <GraphSettings
            graphName={"strideLength"}
            settingsPosition={this.state.graphSettingsLoaction}
            chartType={this.state.chartType}
            setChartType={this.setGraphType}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessionList: selectSessionList2(state),
    session: selectSession(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getSessionList }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StrideLength);
