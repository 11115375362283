/* eslint-disable */

function OrgTableHeadings(props) {
  const {
    heading1 = "Organisation",
    heading2 = "Admins",
    heading3 = "Coaches",
    heading4 = "Athletes",
    heading5 = "Pods",
  
  } = props;

 

  return (
    <>
      <div className="org-list-header">
        <div className="heading main-heading">
          <div className="text-header">{heading1}</div>
        </div>
        <div className="heading">
          <div className="text-header">{heading2}</div>
        </div>
        <div className="heading">
          <div className="text-header">{heading3}</div>
        </div>
        <div className="heading">
          <div className="text-header">{heading4}</div>
        </div>
        <div className="heading">
          <div className="text-header">{heading5}</div>
        </div>

      
      </div>
    </>
  );
}

export default OrgTableHeadings;
