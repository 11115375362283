
const Overview = ({
    size = "24",
    color = "#94A3B3",
}) => {
  return (
<svg width={size} height={size} className='fill-current' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M6 3H8C9.65685 3 11 4.34315 11 6V7C11 8.65685 9.65685 10 8 10H6C4.34315 10 3 8.65685 3 7V6C3 4.34315 4.34315 3 6 3ZM8 5H6C5.44772 5 5 5.44772 5 6V7C5 7.55228 5.44772 8 6 8H8C8.55228 8 9 7.55228 9 7V6C9 5.44772 8.55228 5 8 5ZM6 12H8C9.65685 12 11 13.3431 11 15V18C11 19.6569 9.65685 21 8 21H6C4.34315 21 3 19.6569 3 18V15C3 13.3431 4.34315 12 6 12ZM8 14H6C5.44772 14 5 14.4477 5 15V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V15C9 14.4477 8.55228 14 8 14ZM18 3H16C14.3431 3 13 4.34315 13 6V18C13 19.6569 14.3431 21 16 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3ZM16 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H16C15.4477 19 15 18.5523 15 18V6C15 5.44772 15.4477 5 16 5Z" fill={color}/>
</svg>



  )
}

export default Overview
