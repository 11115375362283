import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAthletesHistoricalActivitiesAPI, getAthletesHistoricalActivityAPI } from '../api/user';
import { resetState } from './resetSlice';
export const getAthletesHistoricalActivities = createAsyncThunk(
  'user/getAthletesHistoricalActivities',
  async (userid) => {
    return await getAthletesHistoricalActivitiesAPI(userid);
  }
);

export const getAthletesHistoricalActivity = createAsyncThunk(
  'user/getAthletesHistoricalActivity',
  async ({ userid, activityid }) => {
    return await getAthletesHistoricalActivityAPI(userid, activityid);
  }
);

const initialState = {
  userid: -1,
  userHistoricalActivities: [],
  userHistoricalActivitiesLoaded: false,
  activityObject: {},
  status: 'idle',
  error: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserData: (state) => { 
      state.userid = -1;
      state.userHistoricalActivities = [];
      state.userHistoricalActivitiesLoaded = false;
      state.activityObject = {};
      state.error = null;
      state.status = 'idle'
     }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAthletesHistoricalActivities.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAthletesHistoricalActivities.fulfilled, (state, action) => {
        state.userHistoricalActivities = [...action.payload];
        state.userHistoricalActivitiesLoaded = true;
        state.userid = action.meta.arg;
        state.status = 'succeeded';
      })
      .addCase(getAthletesHistoricalActivities.rejected, (state, action) => {
        state.error = action.error;
        state.status = 'failed';
      })
      .addCase(getAthletesHistoricalActivity.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAthletesHistoricalActivity.fulfilled, (state, action) => {
        state.activityObject = {
          ...state.activityObject,
          ['activity' + action.meta.arg.activityid]: { ...action.payload }
        };
        state.status = 'succeeded';
      })
      .addCase(getAthletesHistoricalActivity.rejected, (state, action) => {
        state.error = action.error;
        state.status = 'failed';
      })
      .addCase(resetState, () => {
        return initialState;
      });  }
});

export const { resetUserData } = userSlice.actions;
export const selectUserData = (state) => state.user;


export default userSlice.reducer;
