/* eslint-disable */
import React, { useReducer, useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import "./createAthlete.scss";
import TextInput from "../input/input";
import { selectUser } from "store/authSlice";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  selectOrganisation,
  setPlayerDetails,
  getOrganisationsToolkit,
} from "store/orgSlice";
import Button from "../button/button";
import CreatedAthlete from "./athleteCreated/athleteCreated";
import { useAddPlayer } from "../hooks/useAddPlayer";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

const initialState = {
  playerID: -1,
  selectedOrg: undefined,
  file: undefined,
  userName: "",
  emailOne: "",
  emailTwo: "",
  errors: {},
  gender: "",
  age: 0,
  playerCreated: false,
  newPlayerID: 0,
  updateSuccess: false,
  alertIsOpen: false,
  height: 0,
  weight: 0,
  shoesize: 0,
  footlength: 0,
  footwidth: 0,
  pod: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case "updateField":
      return { ...state, [action.fieldName]: action.fieldValue };
    case "updateState":
      return { ...state, ...action.payload };
    case "updateSelectedOrg":
      return { ...state, selectedOrg: action.payload.selectedOrg };
    case "updateFile":
      return { ...state, file: action.file };
    case "resetState":
      return initialState;
    case "setPlayer": {
      const { player } = action.payload;
      return {
        ...state,
        playerID: player.playerID,
        selectedOrg: player.oid,
        file: undefined,
        iconUrl: player.playerIconURL,
        userName: player.player,
        emailOne: player.email,
        emailTwo: player.email,
        gender: player.gender ? player.gender : undefined,
        age: player.age > 0 ? player.age : undefined,
        playerCreated: false,
        newPlayerID: 0,
        height: player.height > 0 ? player.height : undefined,
        weight: player.weight > 0 ? player.weight : undefined,
        shoesize: player.shoeSize > 0 ? player.shoeSize : undefined,
        footlength: player.footLength > 0 ? player.footLength : undefined,
        footwidth: player.footWidth > 0 ? player.footWidth : undefined,
        pod: player.pod > 0 ? player.pod : undefined,
        dob: player.dob ? player.dob : undefined,
      };
    }
    default:
      throw new Error("Unknown action type");
  }
}

const CreateAthlete = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [date, setDate] = useState(null);

  const [
    isSubmitting,
    errorMessage,
    newPlayerID,
    submissionStatus,
    addPlayer,
    resetSumbissionStatus,
  ] = useAddPlayer();

  const { edit, player } = props;

  useEffect(() => {
    if (player) {
      dispatch({ type: "setPlayer", payload: { player } });
    }
  }, [player, props.match.params.id]);

  useEffect(() => {
    if (props.match.params.id && !edit) {
      const selectedOrg = props.match.params.id || undefined;
      dispatch({ type: "updateSelectedOrg", payload: { selectedOrg } });
    }
  }, [props.match.params.id, state.selectedOrg, edit]);

  function onChange(event) {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    dispatch({ type: "updateField", fieldName, fieldValue });
  }

  function onChangeDate(event) {
    const fieldName = event.target.name;
    const fieldValue = event.target.value + "T00:00:00.000Z";

    dispatch({ type: "updateField", fieldName, fieldValue });
  }

  function handleUserSelect(selectedOrg) {
    dispatch({ type: "updateSelectedOrg", payload: { selectedOrg } });
  }

  function handleFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      dispatch({ type: "updateFile", file });

      var image = document.getElementById("tempOrgIcon");
      image.src = URL.createObjectURL(file);
    }
  }
  const fileInputRef = React.useRef(null);

  const triggerFileInput = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  let iconStyle = {
    display: "inline",
    cursor: "pointer",
    width: 120,
  };

  const profileRows = [
    { heading: "Gender", id: "gender", placeholder: "M/F", type: "text" },
    // { heading: "Age", id: "age", placeholder: "0-100" },
    { heading: "Height", id: "height", placeholder: "CM" },
    { heading: "Weight", id: "weight", placeholder: "KG" },
    { heading: "Shoe Size", id: "shoesize", placeholder: "UK" },
    // { heading: "Foot Length", id: "footlength", placeholder: "CM" },
    // { heading: "Foot Width", id: "footwidth", placeholder: "CM" },
    // { heading: "POD", id: "pod", placeholder: "CM" },
  ];

  const textInputs = [
    { label: "Name", name: "userName", type: "text" },
    { label: "Email", name: "emailOne", type: "email" },
    { label: "Confirm Email", name: "emailTwo", type: "email" },
  ];

  const reset = () => {
    //if not in edit mode reset the state
    if (!edit) {
      dispatch({ type: "resetState" });
    }
  };
  const orgSelection = edit ? player.oid : props.match.params.id;
  const close = () => {
    props.close((prevLogoutDialogOpen) => !prevLogoutDialogOpen);
    document.body.classList.remove("modal-open");
    resetSumbissionStatus();
    reset();
  };

  return (
    <Dialog.Root modal={true} open={props.open}>
      <Dialog.Trigger asChild>
        <span>{props.button}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent2"
          style={{ touchAction: "pan-y" }}
        >
          {state.updateSuccess ? (
            <CreatedAthlete
              newPlayerID={state.newPlayerID}
              userName={state.userName}
              close={close}
              reset={reset}
            />
          ) : (
            <>
              <Dialog.Title className="DialogTitle">
                {edit ? "Edit Athlete Details" : "Create New Athlete"}
              </Dialog.Title>
              <Dialog.Description className="DialogDescription">
                {!edit
                  ? "Create new athlete to your organisation here. Once you click create they will be available under athletes tab"
                  : submissionStatus
                  ? "Changes saved successfully"
                  : "Edit the athlete details here. Once you click save the changes will be made"}
              </Dialog.Description>
              <div className="left">
                <div className="organisation-placeholder-parent">
                  {!state.file && !state.iconUrl && (
                    <div
                      onClick={triggerFileInput}
                      className="organisation-placeholder"
                    ></div>
                  )}
                  {/* conditional chceck if the state metioned is empty dont render image */}
                  {state.iconUrl && (
                    <img
                      id="tempOrgIcon"
                      style={iconStyle}
                      src={state.iconUrl}
                    />
                  )}
                </div>

                <div className="file-select-parent">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
              </div>

              <div className="container-fieldset">
                {textInputs.map((input, index) => {
                  return (
                    <fieldset className="Fieldset athleteset" key={index}>
                      <TextInput
                        cls={"reports-search athlete"}
                        type={input.type}
                        label={input.label}
                        nameVal={input.name}
                        value={state[input.name]}
                        setter={onChange}
                        disabled={
                          edit &&
                          state?.[input.name].length > 0 &&
                          state?.[input.name].length > 0 &&
                          state?.emailOne === state?.emailTwo &&
                          (input.name === "emailOne" ||
                            input.name === "emailTwo")
                            ? true
                            : false
                        }
                      />
                    </fieldset>
                  );
                })}

                <fieldset className="Fieldset athleteset">
                  {props.organisation.orgList && (
                    <div className="input_parent input_border">
                      <select
                        id="org_select"
                        onChange={(e) => handleUserSelect(e.target.value)}
                      >
                        {props.match.params.id ? (
                          <>
                            <option
                              disabled
                              hidden
                              defaultValue={true}
                              key={"select_-1"}
                              value={-1}
                            >
                              Select Organisation
                            </option>
                            {props.organisation.orgList.map((org) => {
                              if (org.OrgID === parseInt(orgSelection)) {
                                return (
                                  <option
                                    defaultValue={
                                      org.OrgID === state.selectedOrg
                                    }
                                    key={"select_" + org.OrgID}
                                    value={org.OrgID}
                                  >
                                    {org.Name}
                                  </option>
                                );
                              }
                              return null;
                            })}
                          </>
                        ) : (
                          <>
                            <option
                              defaultValue={true}
                              key={"select_-1"}
                              value={-1}
                            >
                              Select Organisation
                            </option>
                            {props.organisation.orgList.map((org) => {
                              return (
                                <option
                                  defaultValue={org.OrgID === state.selectedOrg}
                                  key={"select_" + org.OrgID}
                                  value={org.OrgID}
                                >
                                  {org.Name}
                                </option>
                              );
                            })}
                          </>
                        )}
                      </select>
                    </div>
                  )}
                </fieldset>
                {/* following thisw format 1992-01-21T00:00:00.000Z */}
                <fieldset className="dob-fieldset">
                  <label className="dob-label" for="dob">
                    Date of Birth:
                  </label>
                  <input
                    id="dob"
                    name="dob"
                    className="athlete-dob-datepicker"
                    type="date"
                    value={state["dob"]?.replace("T00:00:00.000Z", "")}
                    placeholder="Date of Birth"
                    onChange={onChangeDate}
                  />
                </fieldset>
              </div>

              <div className="profile-form-data">
                {profileRows.map((row, index) => (
                  <div className="profile-row" key={index}>
                    <div className="profile-col" key={index}>
                      <div className="profile-col-headings">{row.heading}</div>
                      <div className="input_parent input_border">
                        <input
                          type={row.id === "gender" ? "text" : "number"}
                          autoComplete="off"
                          id={row.id}
                          name={row.id}
                          onChange={onChange}
                          value={state[row.id]}
                          placeholder={row.placeholder}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {state.errors && state.errors.error && (
                <div className="error-warning">* {state.errors.error}</div>
              )}
              {errorMessage && (
                <div className="error-warning">* {state.errors.error}</div>
              )}

              <div
                style={{
                  display: "flex",
                  marginTop: 25,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClickHandler={() => {
                    addPlayer(state, props, dispatch);
                    props.editFromOrg && close();
                  }}
                  value={
                    edit
                      ? isSubmitting
                        ? "Saving..."
                        : submissionStatus
                        ? "Changes Saved"
                        : "Save Edit"
                      : isSubmitting
                      ? "Creating..."
                      : "Create"
                  }
                />
              </div>
              <button className="IconButton" onClick={close} aria-label="Close">
                <Cross2Icon />
              </button>
            </>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

function mapStateToProps(state) {
  return {
    user: selectUser(state),
    organisation: selectOrganisation(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { setPlayerDetails, getOrganisationsToolkit },
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAthlete)
);
