
import MainButton from 'components/common/reuseableButtonComponent/MainButton'
import { Link } from 'react-router-dom'

const GraphHeader = ({toShow, link = null}) => {
    return (
        <>
            <div className='main-header'>

                <div className='show'>
                    {toShow}
                </div>

                <div>
                    {link ?
                        <Link to={link} >
                            <MainButton buttonText = {"View All"} buttonPop = {true}/>
                            {/* <Button value={'View All'}/>     */}
                        </Link> :
                        null    
                    }
                    
                    
                </div>

            </div>
        </>
    )
}

export default GraphHeader