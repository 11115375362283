/* eslint-disable */
import DndIcon from "components/common/icons/dndIcon";
import "./teamComponent.scss";
import DeleteIcon from "components/common/icons/deleteIcon";
import MainButton from "components/common/reuseableButtonComponent/MainButton";
import PlusIcon from "components/common/icons/plusIcon";
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import MainModal from "components/common/mainModal/MainModal";
import { selectUser } from "store/authSlice";
import { selectOrganisation } from "store/orgSlice";
import { selectUserList } from "store/adminSlice";
import { selectedTeamList } from "store/teamSlice";
import {
  getTeamsAthletes,
  selectedTeamAthleteList,
  setAthleteInTeam,
  deleteAthleteInTeam,
} from "store/teamsAthletesSlice";
import {
  getTeamGroups,
  addTeamGroup,
  selectedGroupList,
  deleteGroup,
} from "store/groupSlice";
import { nanoid } from "@reduxjs/toolkit";
import { IconButton } from "rsuite";
import DownloadIcon from "components/common/icons/downloadIcon";
import Polygon from "components/common/icons/downArrow";
import * as Dialog from "@radix-ui/react-dialog";
import CloseIcon from "components/common/icons/closeIcon";
import XIcon from "components/common/icons/xIcon";
import { UserProfile_URL } from "utils/urls";
import {
  setSelectedOrgForTeam,
  selectedOrgForTeams,
} from "store/selectedOrgForTeamsSlice";

// get initials of the athlete name
const athleteInitials = (athleteName) => {
  return athleteName
    ?.split(" ")
    ?.map((name) => name[0])
    ?.join("");
};

const linkTeam = (teamId, linkingArray, secondArray, listOfGroupsIDs) => {
  let output = { isInTeam: [], isInGroup: [] };

  // Create a map for quick lookup in the second array
  let secondArrayMap = new Map();
  for (let player of secondArray) {
    secondArrayMap.set(player.playerID, player);
  }

  // Iterate over the linking array
  for (let link of linkingArray) {
    // Check if the teamId matches and if the playerid is in the second array
    if (link.teamid === teamId && secondArrayMap.has(link.playerid)) {
      // If the groupid is 0 or if the group does not exist in the groups in the org
      if (link.groupid === 0 || !listOfGroupsIDs.includes(link.groupid)) {
        output.isInTeam.push(secondArrayMap.get(link.playerid));
      }
      // If the groupid is > 0, add to isInGroup
      else if (link.groupid > 0) {
        let player = {
          ...secondArrayMap.get(link.playerid),
          groupid: link.groupid,
        }; // Create a new object
        output.isInGroup.push(player);
      }
    }
  }

  // Sort the isInGroup array
  output.isInGroup.sort((a, b) => a.groupid - b.groupid);

  return output;
};

const TeamGroups = (props) => {
  const {
    teamId,
    groupList,
    isEditingTeams = false,
    deleteGroupHandler,
    athletesInThisTeamAndGroup,
    removeAthleteFromGroupHandler,
    removeAthleteFromTeam,
    history,
  } = props;

  const [deleteStatus, setDeleteStatus] = useState("");

  const ref = useRef([]);
  const headRef = useRef([]);

  const listOfGroupsInTeam = groupList?.groups.filter(
    (item) => item.teamid === teamId
  );

  const hiddenHandler = (index) => {
    const divRef = ref.current[index];
    const headderRef = headRef.current[index];

    if (divRef.classList.contains("hidden")) {
      divRef.classList.remove("hidden");
      divRef.classList.add("not-hidden");
    } else {
      divRef.classList.add("hidden");
      divRef.classList.remove("not-hidden");
    }

    if (headderRef.classList.contains("group-button-open")) {
      headderRef.classList.remove("group-button-open");
    } else {
      headderRef.classList.add("group-button-open");
    }
  };

  //go to profile page, for the table on click
  function goToPlayerProfilePage(playerID) {
    if (!playerID) {
      return;
    }
    history.push(UserProfile_URL + "/" + playerID);
  }

  //look into adding the athletes to needed teem in this component
  return (
    <div className="groups-container">
      {athletesInThisTeamAndGroup.isInTeam.length > 0 && (
        <div className="team-body-athletes">
          {athletesInThisTeamAndGroup.isInTeam.map((item) => (
            <div className="athlete-in-team" key={nanoid()}>
              <div className="athlete-profile">
                <div className="athlete-profile-image">
                  {item?.playerIconURL ? (
                    <img src={item?.playerIconURL} alt="organisation initals" />
                  ) : (
                    <p>{athleteInitials(item.player)}</p>
                  )}
                </div>
                <h5 onClick={() => goToPlayerProfilePage(item.playerID)}>
                  {item.player}
                </h5>
              </div>
              {isEditingTeams && (
                <div className="remove-athlete-button">
                  <MainModal
                    button={<XIcon size="18" />}
                    modalBody={
                      <div className="delete-team-modal-body">
                        <div className="delete-modal-info">
                          <h3>Delete {item.player} from Team?</h3>
                          <p>
                            Deleting {item.player} from the team{" "}
                            <span>will exclude </span>
                            their performance from the team averages, they may
                            be added back in the future.
                          </p>
                        </div>
                        <div className="delete-modal-controls">
                          <div
                            onClick={() => {
                              removeAthleteFromTeam(teamId, item.playerID);
                            }}
                          >
                            <MainButton
                              buttonText={"Delete Player"}
                              ButtonIcon={DeleteIcon}
                              buttonImportant={true}
                            />
                          </div>
                          {/* Dialog close added to close the modal from an external child component */}
                          <Dialog.Close>
                            <div onClick={() => close()}>
                              <MainButton
                                buttonText={"Cancel"}
                                ButtonIcon={CloseIcon}
                              />
                            </div>
                          </Dialog.Close>
                        </div>
                      </div>
                    }
                    responseResetState={setDeleteStatus}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {listOfGroupsInTeam.map((item, index) => (
        <div
          className={`group ${
            listOfGroupsInTeam.length !== listOfGroupsInTeam.indexOf(item) + 1
              ? "group-devider"
              : ""
          }`}
          key={nanoid()}
        >
          <div
            ref={(el) => (headRef.current[index] = el)}
            className={`group-content-head ${
              athletesInThisTeamAndGroup.isInGroup.length > 0
                ? "group-button-open "
                : ""
            } ${
              listOfGroupsInTeam.indexOf(item) === 0 &&
              athletesInThisTeamAndGroup.isInTeam.length === 0
                ? "group-on-top"
                : listOfGroupsInTeam.length !==
                    listOfGroupsInTeam.indexOf(item) + 1 ||
                  athletesInThisTeamAndGroup.isInTeam.length > 0
                ? "group-in-middle"
                : "group-on-bottom"
            }`}
          >
            <div className="group-heading">
              <h4>{item.name}</h4>
            </div>
            <div className="group-controls">
              {isEditingTeams && (
                <div className="delete-group-button">
                  <MainModal
                    button={<DeleteIcon size="16" />}
                    modalBody={
                      <div className="delete-team-modal-body">
                        <div className="delete-modal-info">
                          <h3>Delete, {item.name}?</h3>
                          <p>
                            Deleting a group will <span>permanently</span>{" "}
                            delete data associated with the group, all athletes
                            will remain in the team associated with the team?
                          </p>
                        </div>
                        <div className="delete-modal-controls">
                          <div
                            onClick={() => {
                              deleteGroupHandler(item.id, teamId);
                            }}
                          >
                            <MainButton
                              buttonText={"Delete Group"}
                              ButtonIcon={DeleteIcon}
                              buttonImportant={true}
                            />
                          </div>
                          {/* Dialog close added to close the modal from an external child component */}
                          <Dialog.Close>
                            <div onClick={() => close()}>
                              <MainButton
                                buttonText={"Cancel"}
                                ButtonIcon={CloseIcon}
                              />
                            </div>
                          </Dialog.Close>
                        </div>
                      </div>
                    }
                    responseResetState={setDeleteStatus}
                  />
                </div>
              )}

              <div
                className="open-group-button"
                onClick={() => hiddenHandler(index)}
              >
                <Polygon size="16" />
              </div>
            </div>
          </div>
          {athletesInThisTeamAndGroup.isInGroup.filter(
            (athlete) => athlete.groupid === item.id
          ).length > 0 ? (
            <div
              className="group-body-athletes not-hidden"
              ref={(el) => (ref.current[index] = el)}
            >
              {athletesInThisTeamAndGroup.isInGroup
                .filter((athlete) => athlete.groupid === item.id)
                .map((item) => (
                  <div className="athlete-in-group" key={nanoid()}>
                    <div className="athlete-profile">
                      <div className="athlete-profile-image">
                        {item?.playerIconURL ? (
                          <img
                            src={item?.playerIconURL}
                            alt="organisation initals"
                          />
                        ) : (
                          <p>{athleteInitials(item.player)}</p>
                        )}
                      </div>
                      <h5 onClick={() => goToPlayerProfilePage(item.playerID)}>
                        {item.player}
                      </h5>
                    </div>
                    {isEditingTeams && (
                      <div className="remove-athlete-button">
                        <MainModal
                          button={<XIcon size="18" />}
                          modalBody={
                            <div className="delete-team-modal-body">
                              <div className="delete-modal-info">
                                <h3>Remove {item.player} from class?</h3>
                                <p>
                                  Removing {item.player} from the class{" "}
                                  <span>will not remove </span>
                                  them from the team, only from the class.
                                </p>
                              </div>
                              <div className="delete-modal-controls">
                                <div
                                  onClick={() => {
                                    removeAthleteFromGroupHandler(
                                      teamId,
                                      item.playerID
                                    );
                                  }}
                                >
                                  <MainButton
                                    buttonText={"Remove from Group"}
                                    ButtonIcon={DeleteIcon}
                                    buttonImportant={true}
                                  />
                                </div>
                                {/* Dialog close added to close the modal from an external child component */}
                                <Dialog.Close>
                                  <div onClick={() => close()}>
                                    <MainButton
                                      buttonText={"Cancel"}
                                      ButtonIcon={CloseIcon}
                                    />
                                  </div>
                                </Dialog.Close>
                              </div>
                            </div>
                          }
                          responseResetState={setDeleteStatus}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          ) : (
            <div ref={(el) => (ref.current[index] = el)} className="hidden">
              <span className="no-athletes-in-group">
                No Athletes have been added to this group
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const TeamComponent = (props) => {
  const {
    teamName,
    teamDetails,
    teamId,
    isEditingTeams,
    editingModal,
    actions,
    groupList,
    assignedAthletes,
    athleteList,
    teamAthletesList,
    history,
    handleRestAthleteState,
    selectedOrgIdData,
    orgID,
  } = props;


  //state declaration
  const [newTeamGroupFormBody, setNewTeamGroupFormBody] = useState({
    teamGroupName: "",
    teamGroupDetails: "",
  });
  const [hasClasses, setHasClasses] = useState(false);

  const { groupStatus, groups } = useSelector((state) => state.group);
  const { teamAthletesStatus, teamsAthletes } = useSelector(
    (state) => state.teamAthlete
  );

  const dispatch = useDispatch();

  //use effect for innitial fetch of data for the page
  useEffect(() => {
      const fetchData = () => {
        if (groupStatus == "idle" && groups.length === 0) {
          dispatch(getTeamGroups(teamId));
        }
        if (teamAthletesStatus == "idle" && teamsAthletes.length === 0) {
          dispatch(getTeamsAthletes(teamId));
        }
      };
      fetchData();
  }, []);

  useEffect(() => {
    if (selectedOrgIdData !== 0) {
      if (groupStatus == "idle" && groups.length === 0) {
        dispatch(getTeamGroups(teamId));
      }
      if (teamAthletesStatus == "idle" && teamsAthletes.length === 0) {
        dispatch(getTeamsAthletes(teamId));
      }
    }
  }, [selectedOrgForTeams]);

  const postBody = {
    name: newTeamGroupFormBody.teamGroupName,
    detail: newTeamGroupFormBody.teamGroupDetails,
    teamid: teamId,
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setNewTeamGroupFormBody((prevData) => ({ ...prevData, [name]: value }));
  };

  //group handler
  const addTeamGroupHandler = async (postBody) => {
    if (postBody.name.length > 0 && postBody.teamid) {
      const teamGroupAddition = await actions.addTeamGroup(postBody);
      if (teamGroupAddition?.meta?.requestStatus) {
        return teamGroupAddition.meta.requestStatus;
      } else {
        return "error";
      }
    }
  };

  //reset postbody state
  const resetFormState = () => {
    setNewTeamGroupFormBody((prevForm) => ({
      ...prevForm,
      teamGroupName: "",
      teamGroupDetails: "",
    }));
  };

  //delete group api
  const deleteGroupHandler = async (groupID, teamID) => {
    const groupDeletion = await actions.deleteGroup({ groupID, teamID });
    if (groupDeletion?.meta?.requestStatus) {
      return groupDeletion.meta.requestStatus;
    } else {
      return "error";
    }
  };

  //remove athlete from group api
  const removeAthleteFromGroupHandler = async (teamID, athleteID) => {
    const postBody = {
      teamid: teamID,
      playerid: athleteID,
    };
    const removalFromGroup = await actions.setAthleteInTeam(postBody);
    if (removalFromGroup?.meta?.requestStatus) {
      return removalFromGroup.meta.requestStatus;
    } else {
      return "error";
    }
  };

  //remove athlete from team api
  const removeAthleteFromTeam = async (teamID, athleteID) => {
    const postBody = {
      teamid: teamID,
      playerid: athleteID,
    };
    const removalFromTeam = await actions.deleteAthleteInTeam(postBody);
    await handleRestAthleteState();
    if (removalFromTeam?.meta?.requestStatus) {
      return removalFromTeam.meta.requestStatus;
    } else {
      return "error";
    }
  };

  const listOfGroupsInTeam = groupList?.groups.filter(
    (item) => item.teamid === teamId
  );

  const listOfAthletesInThisTeam = teamAthletesList?.teamsAthletes.filter(
    (item) => item.teamid === teamId
  );

  const listOfGroupsIDs = groupList.groups.map((item) => item.id);

  const athletesInThisTeamAndGroup = linkTeam(
    teamId,
    listOfAthletesInThisTeam,
    assignedAthletes,
    listOfGroupsIDs
  );

  return (
    <div className="team-component" id={teamId}>
      <div className="team-component-header">
        <div className="team-title">
          {/* drag and drop icon commented out */}
          {/* {isEditingTeams && <DndIcon size="20" />} */}
          <h4>{teamName}</h4>
        </div>
        {isEditingTeams && (
          <div className="team-editing">
            <div className="remove-team">{editingModal}</div>
          </div>
        )}
      </div>
      <div className="teams-vertical-line-connection"></div>
      <div className="team-component-body">
        <TeamGroups
          teamId={teamId}
          groupList={groupList}
          isEditingTeams={isEditingTeams}
          deleteGroupHandler={deleteGroupHandler}
          assignedAthletes={assignedAthletes}
          athleteList={athleteList}
          teamAthletesList={teamAthletesList}
          athletesInThisTeamAndGroup={athletesInThisTeamAndGroup}
          removeAthleteFromGroupHandler={removeAthleteFromGroupHandler}
          removeAthleteFromTeam={removeAthleteFromTeam}
          history={history}
        />
      </div>

      {(listOfGroupsInTeam.length > 0 ||
        athletesInThisTeamAndGroup.isInTeam.length > 0) && (
        <div className="teams-vertical-line-connection"></div>
      )}

      <div className="team-component-button">
        <MainModal
          button={
            <MainButton
              buttonPop={true}
              buttonText={"Add New Class"}
              ButtonIcon={PlusIcon}
            />
          }
          modalBody={
            <div className="add-team-modal-body">
              <h3>Create New Class</h3>
              <input
                type="text"
                name="teamGroupName"
                id="teamGroupName"
                placeholder="Class Name"
                defaultValue={newTeamGroupFormBody.teamGroupName}
                onChange={handleFormChange}
              />
              <textarea
                placeholder="Class Details"
                defaultValue={newTeamGroupFormBody.teamGroupDetails}
                onChange={handleFormChange}
                name="teamGroupDetails"
              />
              <div
                onClick={() => {
                  addTeamGroupHandler(postBody), resetFormState();
                }}
              >
                <MainButton
                  buttonText={"Create New Class"}
                  ButtonIcon={PlusIcon}
                  buttonPop={true}
                  buttonDisabled={
                    !(
                      postBody.name.length > 0 &&
                      postBody.teamid &&
                      !listOfGroupsInTeam.filter(
                        (item) => item.name === postBody.name
                      ).length > 0
                    )
                  }
                  buttonTooltip={
                    postBody.name.length > 0 && postBody.orgid
                      ? "Please enter a valid class name"
                      : "A class of the same name already exists"
                  }
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    organisation: selectOrganisation(state),
    userList: selectUserList(state),
    teamList: selectedTeamList(state),
    groupList: selectedGroupList(state),
    teamAthletesList: selectedTeamAthleteList(state),
    selectedOrgForTeams: selectedOrgForTeams(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addTeamGroup,
      getTeamGroups,
      deleteGroup,
      getTeamsAthletes,
      setAthleteInTeam,
      deleteAthleteInTeam,
    },
    dispatch
  ),
});

//exporting and connecting component to redux
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamComponent)
);
