import { useState } from "react";

export function useValidation() {
  const [errors, setErrors] = useState({});

  function validateInputs(state, dispatch) {
    const newErrors = {};
    newErrors.formErrors = false;


    if (!state.userName || state.userName.length === 0) {
      newErrors.error = "You must enter the athletes name";
      newErrors.formErrors = true;
    } else if (!state.selectedOrg) {
      newErrors.error = "You must select an organisation";
      newErrors.formErrors = true;
    } else if (state.emailOne.length > 0) {
      if (state.emailOne !== state.emailTwo) {
        newErrors.error = "Emails do not match";
        newErrors.formErrors = true;
      } else if (
        (!state.emailOne.includes("@") && !state.emailTwo.includes("@")) ||
        (!state.emailOne.includes(".") && !state.emailTwo.includes("."))
      ) {
        newErrors.error = "Please enter a valid email";
        newErrors.formErrors = true;
      }
    }

    setErrors(newErrors);
    dispatch({ type: "updateState", payload: { errors: newErrors } });

    return !newErrors.formErrors;
  }

  return [errors, validateInputs];
}
