/* eslint-disable */
import React from "react";
import { motion } from "framer-motion";
import Spinner from "components/common/spinner/spinner";
import OrderColumns from "components/common/orderColumns/OrderColumns";
import InfiniteScroll from "react-infinite-scroll-component";
import "./activityTable.scss";
import { useSelector } from "react-redux";
import ActivityTableRow from "./activityTableRow";

//commit test

const DisplayTableData = (props) => {
  const {
    sessionsToDisplay = [],
    fullSessionList,
    searchList,
    sessionListStatus,
    permissionLevel,
  } = props;
  const [listItems, setListItems] = React.useState(
    Array.from(sessionsToDisplay.slice(0, 20))
  );
  const [hasMore, setHasMore] = React.useState(true);

  const { currentSearchTerm } = useSelector((state) => state.playerActivity);

  React.useEffect(() => {
    setListItems(Array.from(sessionsToDisplay.slice(0, 20)));
    setHasMore(sessionsToDisplay.length > 20);
  }, [sessionsToDisplay]);

  function fetchMoreListItems() {
    if (listItems.length >= sessionsToDisplay.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setListItems((prevState) => [
        ...prevState,
        ...sessionsToDisplay.slice(prevState.length, prevState.length + 20),
      ]);
    }, 200);
  }

  if (sessionListStatus === "idle" || sessionListStatus === "loading") {
    return (
      <Spinner cls="spinner-user-profile" classnames={"user-profile-loader"} />
    );
  }

  // check if there are no records to display
  if (
    searchList?.length === 0 ||
    (fullSessionList?.length === 0 && currentSearchTerm?.length < 1)
  ) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.6,
          delay: 0.1 * 1,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ marginBottom: "0.5rem" }}
      >
        <div className="record-not-found" style={{ paddingTop: "6rem" }}>
          <h1>No Records Found</h1>
        </div>
      </motion.div>
    );
  }
  return (
    <>
      <OrderColumns
        searchList={searchList}
        fullSessionList={fullSessionList}
      />
      <InfiniteScroll
        dataLength={listItems.length}
        next={fetchMoreListItems}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        height={"60vh"}
      >
        {listItems.map((session, i) => {
          return (
            <ActivityTableRow
              key={session.ID}
              session={session}
              i={i}
              fullSessionList={fullSessionList}
              permissionLevel={permissionLevel}
            />
          );
        })}
      </InfiniteScroll>
    </>
  );
};

export default React.memo(DisplayTableData);
