/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addTeamAPI,
  deleteTeamAPI,
  getTeamListAPI,
} from "api/teams";
import { resetState } from "./resetSlice";

const initialState = {
  teams: [],
  status: "idle",
};

export const getTeams = createAsyncThunk("teams/getTeams", async () => {
  const response = await getTeamListAPI();
  return response.data;
});

export const addTeam = createAsyncThunk(
  "teams/addTeam",
  async (postBody, { dispatch }) => {
    try {
      const response = await addTeamAPI(postBody);
      await dispatch(getTeams());
      return await response.data;
    } catch (error) {
      return await error;
    }
  }
);

export const deleteTeam = createAsyncThunk(
  "teams/deleteTeam",
  async (teamID, { dispatch }) => {
    try {
      const response = await deleteTeamAPI(teamID);
      dispatch(getTeams());
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getTeams.fulfilled, (state, action) => {
      state.teams = action.payload;
      state.status = "succeeded";
    });
    builder
      .addCase(getTeams.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(resetState, () => {
        return initialState;
      });
  },
});



export const selectedTeamList = (state) => state.team;

export default teamSlice.reducer;

