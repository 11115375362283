
const OrganisationsIcon = ({
    size = "24",
    color = "#94A3B3",
}) => {
  return (



<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill={color}>
    <rect fill='none' height={size} width={size}/><path d="M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z"/></svg>

  )
}

export default OrganisationsIcon




