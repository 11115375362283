
const EscapeIcon = ({
    size = "18",
    color = "currentColor",
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4119 11.9999L17.7119 7.70994V7.70994C18.104 7.31782 18.104 6.68206 17.7119 6.28994C17.3198 5.89782 16.684 5.89782 16.2919 6.28994L12.0019 10.5899L7.71189 6.28994L7.71189 6.28994C7.31977 5.89782 6.68401 5.89782 6.29189 6.28994C5.89977 6.68206 5.89977 7.31782 6.29189 7.70994V7.70994L10.5919 11.9999L6.29189 16.2899V16.2899C5.89977 16.6789 5.89717 17.312 6.28609 17.7041C6.28802 17.7061 6.28995 17.708 6.29189 17.7099H6.29189C6.68081 18.1021 7.31397 18.1047 7.70609 17.7157C7.70803 17.7138 7.70997 17.7119 7.71189 17.7099L12.0019 13.4099L16.2919 17.7099V17.7099C16.6808 18.1021 17.314 18.1047 17.7061 17.7157C17.708 17.7138 17.71 17.7119 17.7119 17.7099V17.7099C18.104 17.321 18.1066 16.6879 17.7177 16.2957C17.7158 16.2938 17.7138 16.2919 17.7119 16.2899L13.4119 11.9999Z" fill={color}/>
    </svg>

  )
}

export default EscapeIcon

