import {useState} from 'react';
import { connect } from 'react-redux';
import { Link, withRouter,useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
//import PropTypes from 'prop-types';
import { resetPassAPI, sendResetPassEmail } from '../api/auth';
import logo from '../assets/images/DanuTitle.png';
import { LOGIN_URL, SIGNUP_URL } from "../utils/urls";
import LoginInput from './LoginInput';
import Button from './common/button/button';
import { clearAuth, userState } from 'store/authSlice';

const ResetPassForm = (props) => {

  const history = useHistory();
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [emailSent, setEmailSent] = useState(0);
  const [emailReset, setEmailReset] = useState(0);
  const [emailResetError, setEmailResetError] = useState(null);
  const [errors, setErrors] = useState({});


  const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const goToLogIn = () => {
    props.actions.clearAuth();
    history.push(LOGIN_URL);
  };


  const goToSignIn = () => {
    props.actions.clearAuth();
    props.history.push(SIGNUP_URL);
  };

  const onChange = (e) => {
    setIdentifier(e.target.value);
  };
  const onChangePass = (e) => {
    setPassword(e.target.value);
  };

  const onChangeConfirm = (e) => {
    setConfirmPassword(e.target.value);
  };


  const resetpass = (e) => {
    e.preventDefault();

    if (props.match.params.cc) {
      resetPassComplete();
    } else {
      resetPassSendEmail();
    }
  };

  const resetPassSendEmail = () => {
    if (validateUserInputs()) {
      sendResetPassEmail(identifier).then((res) => {
        if (res.data.emailSent) {
          setEmailSent(1);
        } else {
          setEmailSent(-1);
        }
      })
        .catch((error) => {
          try {
            setEmailResetError(error.response.data.error.message );
          } catch (ex) {
            setEmailResetError( 'Error sending email' );
          }
        });
    }
  };


  const resetPassComplete = () => {
    if (validateUserInputs('part2')) {
      let resetObj = {
        key: props.match.params.cc,
        email: identifier,
        newpassword: password,
      };

      resetPassAPI(resetObj).then((res) => {
        if (res.data.emailSent) {
          setEmailReset(1);
        } else {
          setEmailReset(-1);
        }
      })
        .catch((error) => {
          try {
            setEmailResetError(error.response.data.error.message );
          } catch (ex) {
            setEmailResetError( 'Error resetting email' );
          }
        });
    }
  };


  const isValidEmail = (email) => {
    return regex.test(email);
  };


  const validateUserInputs = (part2) => {
    let errors = {};
    errors.formErrors = false;

    if ((!identifier) || (identifier.length === 0)) {
      errors.error = 'You must enter an email';
      errors.formErrors = true;
    } else if ((!isValidEmail(identifier))) {
      errors.error = 'You must enter a valid email';
      errors.formErrors = true;
    }

    if (part2)
    {
      if ((!password) || (password.length === 0)) {
        errors.error = 'You must enter a password';
        errors.formErrors = true;
      } else if (password !== confirmpassword) {
        errors.error = 'Password and confirm password must match';
        errors.formErrors = true;
      }
    }

    setErrors(errors);
    return !errors.formErrors;
  };

 

    if (emailSent)
    {
      return (
           <div className="login-container">

            <img src={logo} className="rightLogo" alt='rightlogo' />

              <div className="login-title" >
                Check your email to complete sign-up
              </div>

              <Link to={LOGIN_URL}>
                    <Button value={"Retrun to home"}/>
              </Link>
           </div>
      )
    }

    if (emailReset)
    {
      return (
           <div className="login-container">

              <img src={logo} className="rightLogo" alt='rightlogo' />

              <div className="login-title" >
                  Your password has been reset!
              </div>

              {/* <Link to={LOGIN_URL} className="login-button signup-button" style={{width:276}}>
                    Return to Login
              </Link> */}
               <div onClick={() => goToLogIn()}>
                    <Button value={"Return to Login"} />
              </div>
           </div>
      )
    }

    let emailConfirmCode = props.match.params.cc

      return (
        <div className="login-container">

                <div className='logo-container'>
                <img src={logo} className="rightLogo" alt="Recently updated indicator" />
                </div>

                {!emailConfirmCode && <div className="login-title">Forgotten your password?</div>}
                {emailConfirmCode && <div className="login-title">Enter New Password</div>}

                <LoginInput title={'Email'} id={'identifier'} onChange={onChange} value={identifier} type='text' placeholder={'Email'}/>
                {emailConfirmCode && <LoginInput  title={'Enter New Password'} id={'password'} onChange={onChangePass} value={password} type='password' placeholder={'New Password'}/>}
                {emailConfirmCode && <LoginInput  title={'Re-Enter New Password'} id={'confirmpassword'} onChange={onChangeConfirm} value={confirmpassword} type='password' placeholder={'Re-Enter New Password'}/>}



                 {(errors && errors.error) &&
                        <div className="login-error">
                                  {errors.error}
                        </div>
                  }

                  {emailResetError  &&
                        <div className="login-error">
                                  {emailResetError}
                        </div>
                  }

                 {(props.user.authenticationError && props.user.authenticationError.data.error.message) &&
                        <div className="login-error">
                                  {props.user.authenticationError.data.error.message}
                        </div>
                  }

                 {!(props.user.authenticationError && props.user.authenticationError.data.error.message)
                    && props.user.user && !props.user.user.token
                      && (props.user.user.status > 400 && props.user.user.status < 500)
                      &&
                        <div className="login-error">
                                  Invalid Details
                        </div>
                  }

                <div className="signup-button" onClick={(e) => { resetpass(e); }}>
                    <Button value={"Submit"}/>
                 </div>

                  <div className="login-extra" style={{marginTop:100}}>
                          <div className="login-extra-link" onClick={() => {goToLogIn()}} >Login</div>
                          <div className="login-divider">|</div>
                          <div className="login-extra-link" onClick={() => {goToSignIn()}} >Create an account</div>
                 </div>
        </div>
    );

    //end normal sign up  (i.e not confirm code)

}


function mapStateToProps(state) {
  return {
    user: userState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({clearAuth}, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassForm));
