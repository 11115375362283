import React from 'react';

class CoachesHome extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}

  }

  componentDidMount () {}

  render() {

    return (
            <div style={{width:'100%',height:'100%'}} className="center-content right-background">
                  Coaches Home
            </div>
    );
  }
}

export default CoachesHome;

