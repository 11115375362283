const PlusIcon = ({ size = "24", color = "#94A3B3" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3ZM18 5H6C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5ZM15 11C15.5523 11 16 11.4477 16 12C16 12.5128 15.614 12.9355 15.1166 12.9933L15 13H13V15C13 15.5523 12.5523 16 12 16C11.4872 16 11.0645 15.614 11.0067 15.1166L11 15V13H9C8.44772 13 8 12.5523 8 12C8 11.4872 8.38604 11.0645 8.88338 11.0067L9 11H11V9C11 8.44772 11.4477 8 12 8C12.5128 8 12.9355 8.38604 12.9933 8.88338L13 9V11H15Z"
        fill={color}
      />
    </svg>
  );
};

export default PlusIcon;
