import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const breadcrumbPaths = [
  { path: "/", name: "Overview" },
  { path: "/athletes_home", name: "Athletes" },
  { path: "/session_input", name: "Reports" },
  { path: "/organisations_list", name: "Organisations" },
  { path: "/admin_home", name: "Admin" },
  { path: "/athlete_profile", name: "Athlete Profile" },
  { path: "/pod_list", name: "Pod Database" },
  { path: "/pod_registration", name: "Pod Database" },
  { path: "/organisation_detail", name: "Organisations" },
  { path: "/manage_teams", name: "Manage Teams" },
  { path: "/teams", name: "Teams" },
];

const breadcrumbNames = {
  "/athlete_profile": "Athletes",
  "/organisation_detail": "Organisations",
  "/pod_registration": "Pod Database",
};

const childVariants = {
  initial: { opacity: 0, x: -50 },
  animate: { opacity: 1, x: 0 },
};

export default function Breadcrumbs() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <ul>
      {breadcrumbPaths.map(({ path, name }) => {
        if (!currentPath.includes(path)) return null;
        let breadcrumbName = breadcrumbNames[path] || name;
        let breadcrumbPath = breadcrumbNames[path] === 'Organisations' ? '/organisations_list' : breadcrumbNames[path] ? "/athletes_home" : path;


        return (
          <div key={path} style={{display:'flex'}}>
            <motion.div
              variants={childVariants}
              initial="initial"
              animate="animate"
              transition={{
                duration: 0.6,
                delay: 0.2 * 0,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <Link to={breadcrumbPath}>{breadcrumbName}</Link>
            </motion.div>
            <motion.span
              variants={childVariants}
              initial="initial"
              animate="animate"
              transition={{
                duration: 0.6,
                delay: 0.2 * 1,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <span>{'|'}</span> 
            </motion.span>
            </div>
        );
      })}
    </ul>
  );
}
