import './input.scss'
function TextInput({ cls,type = 'text',nameVal, label,setter,value,name='form', disabled = false }) {


    return (
      <div className={`input-container ${cls}`}>
        <input type={type} id={nameVal} name={nameVal} value={value} onChange={setter} disabled={disabled}/>
        <label className={value && 'filled'} htmlFor={name}>
          {label}
        </label>
      </div>
    );
  }


export default TextInput