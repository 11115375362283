import '../customReportTextArea/custom-layout-textarea.scss'

function CustomLayoutTextArea(props) {
    const {isOpen, isBoarderVisible} = props;

    return(
        <textarea className={`custom-layout-text-area ${isOpen && 'text-area-open'}  ${isBoarderVisible && 'text-area-border'}`} defaultValue={'Notes'}></textarea>
    )
}

export default CustomLayoutTextArea