/* eslint-disable */ 
import { Brush, ComposedChart, LineChart, ResponsiveContainer } from "recharts"
import { selectSessionList2 } from "store/sessionListSlice";
import { connect } from 'react-redux';
import { debounce } from "lodash";
import { selectSession } from "store/sessionSlice";

//git deploy test
function MetricBrush(props) { 

    const {sessionList, setGraphDomain, trimDomain} = props;
    let data = [];
    let dataTest = [];

    if(sessionList?.runData?.loaded){
        if(sessionList?.runData?.data?.capacitanceData && Object.keys(sessionList.runData.data.capacitanceData).length > 0){
            data = sessionList?.runData?.data?.capacitanceData?.filter(item => item['Time']);

            if(trimDomain[0] && trimDomain[1]){
                data = data.filter(item => (item['Time'] > trimDomain[0]) && (item['Time'] < trimDomain[1]))
            }
        }
        //generates data for the timeline of the brush
        if(data && data.length){
           for(let i = 0; i <= Math.round(data[data.length-1]['Time']); i++) {

                    dataTest.push({Time: i})
                
         } 
        }
        
    }
    

    return (
        <div className="metric-brush">
            <ResponsiveContainer
            height={50}>
                <LineChart
                    data={dataTest
                    }
                    >
                    <Brush
                        onChange={(e)=> {
                            if(dataTest.length > 100){
                                setTimeout(()=> {
                                    setGraphDomain([Math.floor(dataTest[e.startIndex]['Time']), Math.round(dataTest[e.endIndex]['Time'])])
                                },1500)
                            }else{
                                setGraphDomain([Math.floor(dataTest[e.startIndex]['Time']), Math.round(dataTest[e.endIndex]['Time'])])     
                            }
                        
                        }}
                        height={40} 
                        travellerWidth={15} 
                        stroke={"rgba(30, 151, 214, 0.2)"} 
                        fill={"transparent"}
                    />
                </LineChart>
                
            </ResponsiveContainer>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      sessionList: selectSession(state),
    };
  }
  
  const mapDispatchToProps = () => ({
  });
  
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(MetricBrush);
  