import "./warningTooltip.scss";
import WarningIcon from "../icons/warningIcon";
import InfoIcon from "../icons/infoIcon/infoIcon";
import InfoIconFilled from "../icons/infoIconFilled";

const WarningTooltip = (props) => {
  const {
    warningText,
    warningIconSize = 16,
    warningIconColor = "yellow",
    warningIconType = false,
    warningForTag = false,
  } = props;

  const iconType =
    warningIconType === "info" ? (
      <InfoIcon size={warningIconSize} />
    ) : warningIconType === "infoFilled" ? (
      <InfoIconFilled size={warningIconSize} fillColor={warningIconColor} />
    ) : (
      <WarningIcon size={warningIconSize} color={warningIconColor} />
    );

  return (
    <div style={{ display: "flex" }}>
      <div className={`hover-icon  ${warningForTag && "hover-icon-tag-text"}`}>
        <span
          className={`tooltip-text ${warningForTag && "tooltip-tag-text"}`}
          id="bottom"
        >
          {warningText}
        </span>
        {iconType}
      </div>
    </div>
  );
};

export default WarningTooltip;
