/* eslint-disable */
import { useSelector, useDispatch } from "react-redux";
import { getJumpData, getRawMetricData, getRunData } from "store/sessionSlice";
import { useEffect } from "react";
export const useCheckForJumpData = () => {
    const { sessionList, selectedSessionId,sessions } = useSelector((state) => ({
        selectedSessionId: state.playerActivity.selectedSessionId,
        sessionList: state.session,
        sessions:state.sessionList
        //lastFetchedId : state.session.runData.sessionID
    }));

    const dispatch = useDispatch();

    const checkForJumpData = () => {
        // if(lastFetchedId && lastFetchedId === selectedSessionId){
        //    return
        // }
        if (
            sessions.status === "idle" ||
            sessions.status === "loading" ||
            selectedSessionId === null
        ) {
            return;
        }
        let thisSession;
        if (sessions.status === "succeeded") {
            thisSession = sessions.sessionList?.data.find(
                (s) => s.ID === Number(selectedSessionId)
            );
        }

        // if (
        //     selectedSessionId && selectedSessionId ===
        //     (sessionList.runData.sessionID || sessionList.jumpData.sessionID)
        // ) {
        //     return
        // }

        if (
            thisSession &&
            (thisSession.jumpTypeID > 0 || thisSession.activityTypeID == 2)
        ) {
            console.log('hook calls for jumpData activityTypeID == 2',thisSession.ID)
            dispatch(getJumpData(thisSession.ID))
        } else if (
            thisSession && (thisSession.runTypeID > 0 || thisSession.activityTypeID == 1)
        ) {
            console.log('hook calls for runData activityTypeID === 1',thisSession.ID)

            dispatch(getRunData(thisSession.ID));
        } else if (thisSession && thisSession.activityID == 21) {
            console.log('hook calls for runData activityID == 21',thisSession.activityID)

            //for other - call rundata API as capacitance will be returned here
            dispatch(getRunData(thisSession.ID));
        }
        else if (thisSession && thisSession.activityTypeID == 5) {
            console.log('hook calls for rawData activityTypeID == 5',thisSession.activityID)
            //for other - call rundata API as capacitance will be returned here
            dispatch(getRawMetricData(thisSession.ID));
        }
    }

    useEffect(() => {
        checkForJumpData();
    }, []); // Run only once on mount

    return checkForJumpData;
};
