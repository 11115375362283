import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlayerForm from './PlayerForm';
import { selectOrganisation, setPlayerDetails, getOrganisationsToolkit } from 'store/orgSlice';
import { selectUser } from 'store/authSlice';


class NewUser extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}
  }


  componentDidMount () {}

  render() {

    return (
            <div style={{width:'100%',height:'100%'}}>
                  <PlayerForm edit="true" />
            </div>
    );
  }
}



function mapStateToProps(state) {
  return {
        user: selectUser(state),
        organisation: selectOrganisation(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
        actions: bindActionCreators({ setPlayerDetails, getOrganisationsToolkit }, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewUser);

