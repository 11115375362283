/* eslint-disable */
import "./graph-settings.scss";
import Settings from "components/nav-components/nav-icons/settings";
import BarChartIcon from "components/common/icons/graphIcons/barChart";
import LineChartIcon from "components/common/icons/graphIcons/lineChart";
import Toggler from "components/common/toggler/Toggler";

export default function GraphSettings2(props) {
  const {
    graphName,
    settingsPosition,
    chartType,
    setChartType,
    hasToggler = false,
    togglerHeadings = {
      left: "left",
      right: "right",
    },
    isToggled,
    setIsToggled,
    changeToggleDisplayed,
    defaultToggle = false
  } = props;

  function openGraphSettings() {
    if (
      !document
        .getElementById(graphName)
        .classList.contains("graph-settings-open")
    ) {
      document.getElementById(graphName).classList.add("graph-settings-open");
    } else {
      document
        .getElementById(graphName)
        .classList.remove("graph-settings-open");
    }
  }
  return (
    <div
      className={`graph-setttings-sidebar ${settingsPosition}${defaultToggle ? " graph-settings-open": ""}`}
      id={graphName}
    >
      <div className="settings-icon" onClick={() => openGraphSettings()}>
        <Settings size={"24"} fillOpacity={"1"} />
      </div>
      {hasToggler && (
        <div
          className={`chart-icon icon-hover`}
          onClick={() => console.log("clicked")}
        >
          <Toggler
            togglerHeadings={togglerHeadings}
            isToggled={isToggled}
            setIsToggled={setIsToggled}
            changeToggleDisplayed={changeToggleDisplayed}
          />
        </div>
      )}
    </div>
  );
}
