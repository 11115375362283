import * as AlertDialog from '@radix-ui/react-alert-dialog';
import './alertDialog.scss';
import Button from '../button/button';

const AlertDialogDemo = (props) => {

  const { open, setLogoutDialogOpen, proceed, type, deactivateRow, setDiscardSessionModalOpen } = props;


  const close = () => {
    setLogoutDialogOpen(false),
    document.body.classList.remove('modal-open');
  }

  const closeDiscard = () => 
  {
    setDiscardSessionModalOpen(false)
    document.body.classList.remove('modal-open');
  }
  const title = {
    'deactivateOrg': <><AlertDialog.Title className="AlertDialogTitle">Are you absolutely sure?</AlertDialog.Title>
      <AlertDialog.Description className="AlertDialogDescription">
        You can&apos;t revert this action back, Are you sure you want to deactivate the organisation?
      </AlertDialog.Description><div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
        <AlertDialog.Cancel asChild>
          <Button value='Cancel' maxWidth={true} onClickHandler={close} />
        </AlertDialog.Cancel>
        <AlertDialog.Action asChild>
          <button className="btn-g" style={{ width: '25%' }} onClick={(e) => { proceed(e, deactivateRow); }} >Yes</button>
        </AlertDialog.Action>
      </div> </>,
    'deactivateUser': <>          <AlertDialog.Title className="AlertDialogTitle">Are you absolutely sure?</AlertDialog.Title>
      <AlertDialog.Description className="AlertDialogDescription">
        You can&apos;t revert this action back, Are you sure you want to deactivate the user?
      </AlertDialog.Description><div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
        <AlertDialog.Cancel asChild>
          <Button value='Cancel' maxWidth={true} onClickHandler={close} />
        </AlertDialog.Cancel>
        <AlertDialog.Action asChild>
          <button className="btn-g" style={{ width: '25%' }} onClick={(e) => { proceed(e, deactivateRow); }} >Yes</button>
        </AlertDialog.Action>
      </div> </>,
    'logout': <><AlertDialog.Title className="AlertDialogTitle">Are you absolutely sure?</AlertDialog.Title>
      <AlertDialog.Description className="AlertDialogDescription">
        You can login back anytime, Are you sure you want to log out?
      </AlertDialog.Description> <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
        <AlertDialog.Cancel asChild>
          <Button value='Cancel' maxWidth={true} onClickHandler={close} />
        </AlertDialog.Cancel>
        <AlertDialog.Action asChild>
          <button className="btn-g" style={{ width: '25%' }} onClick={(e) => { proceed(e, deactivateRow); close() }} >Yes</button>
        </AlertDialog.Action>
      </div></>,
    'pod': <><AlertDialog.Title className="AlertDialogTitle">Are you absolutely sure?</AlertDialog.Title>
      <AlertDialog.Description className="AlertDialogDescription">
        You can&apos;t revert this action back, Are you sure you want to deactivate the pod?
      </AlertDialog.Description><div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
        <AlertDialog.Cancel asChild>
          <Button value='Cancel' maxWidth={true} onClickHandler={close} />
        </AlertDialog.Cancel>
        <AlertDialog.Action asChild>
          <button className="btn-g" style={{ width: '25%' }} onClick={(e) => { proceed(e, deactivateRow); close() }} >Yes</button>
        </AlertDialog.Action>
      </div> </>,
    'tag': <><AlertDialog.Title className="AlertDialogTitle">Are you absolutely sure?</AlertDialog.Title>
    <AlertDialog.Description className="AlertDialogDescription">
      You can&apos;t revert this action back, Are you sure you want to deactivate the tag?
    </AlertDialog.Description><div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
      <AlertDialog.Cancel asChild>
        <Button value='Cancel' maxWidth={true} onClickHandler={close} />
      </AlertDialog.Cancel>
      <AlertDialog.Action asChild>
        <button className="btn-g" style={{ width: '25%' }} onClick={(e) => { proceed(e, deactivateRow); close() }} >Yes</button>
      </AlertDialog.Action>
    </div> </>,
    'discardSession': <><AlertDialog.Title className="AlertDialogTitle">Are you absolutely sure?</AlertDialog.Title>
    <AlertDialog.Description className="AlertDialogDescription">
      You can&apos;t revert this action back, Are you sure you want to discard the session?
    </AlertDialog.Description><div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
      <AlertDialog.Cancel asChild>
        <Button value='Cancel' maxWidth={true} onClickHandler={closeDiscard} />
      </AlertDialog.Cancel>
      <AlertDialog.Action asChild>
        <button className="btn-g" style={{ width: '25%' }} onClick={(e) => { proceed(e, deactivateRow); closeDiscard() }} >Yes</button>
      </AlertDialog.Action>
    </div> </>
  }
  const TITLE = title[type]

  return (
    <AlertDialog.Root open={open}>

      <AlertDialog.Portal>
        <AlertDialog.Overlay className="AlertDialogOverlay" />
        <AlertDialog.Content className="AlertDialogContent">
          {TITLE}

        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}



export default AlertDialogDemo;