import React from "react";
import {
  LineChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import {
  BOTH_FEET_COLOR,
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "../../utils/constant";
import { getHistoricalChartsTitle, scaleGraphHorizontalTicks } from "utils/helper";

function DLCJ({
  GraphTableData,
  chartData,
  activity,
  yAxisLabel,
  maxValue,
  minValue,
  activeData, 
  setActiveData,
  setTableData,
  setGraphIsOpen,
  toggleGraph
}) {
  return (
    <>
      <ResponsiveContainer width="90%" height="95%">
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onClick={(e)=> {
            if(activeData.locked === false){
                if(e){
                    setActiveData({locked: true})
                    setTableData(e.activePayload)
                    setGraphIsOpen(true)
                }
            }else if(activeData.locked === true){
                setActiveData({locked: false})
            }
        }}
        >
          <CartesianGrid
            strokeDasharray="4 4"
            stroke={NEW_AXIS_COLOR}
            strokeWidth={1}
            opacity={0.4}
          />

          <XAxis
            dataKey="date"
            tick={{ fill: NEW_AXIS_TICK }}
            stroke={NEW_AXIS_COLOR}
            fontSize={".75rem"}
            reversed={true}
            interval={scaleGraphHorizontalTicks(chartData.length)}
          ></XAxis>
          {getHistoricalChartsTitle(activity?.id).toLocaleLowerCase() ===
            "Jump Height".toLocaleLowerCase() && (
            <YAxis
              label={{
                value: yAxisLabel,
                angle: -90,
                position: "insideLeft",
                fill: "white",
                fontSize: "14px",
              }}
              tick={{ fill: NEW_AXIS_TICK }}
              stroke={NEW_AXIS_COLOR}
              domain={[Math.round(minValue) - ( Math.round(minValue)/100)*10, Math.round(maxValue) +( Math.round(maxValue)/100)*10]}
            />
          )}

          {getHistoricalChartsTitle(activity?.id).toLocaleLowerCase() !==
            "Jump Height".toLocaleLowerCase() && (
            <YAxis
              label={{
                value: yAxisLabel,
                dy: 100,
                angle: -90,
                position: "insideLeft",
                fill: "white",
                fontSize: "14px",
              }}
              tick={{ fill: NEW_AXIS_TICK }}
              stroke={NEW_AXIS_COLOR}
              domain={[0, Math.round(maxValue) +( Math.round(maxValue)/100)*10]}
            />
          )}

          {activity?.bilateral === 1 && (
            <ReferenceArea
              y1={chartData[0].sdN}
              y2={chartData[0].sdP}
              opacity={0.2}
              strokeDasharray="5 5"
              stroke="white"
            />
          )}

          {activity?.bilateral === 1 && (
            <Line
              activeDot={{ onClick: () => toggleGraph() }}
              legendType="none"
              type="linear"
              dataKey="value"
              stroke={BOTH_FEET_COLOR}
              dot={true}
              name={getHistoricalChartsTitle(activity?.id)}
              connectNulls={false}
            />
          )}

          <Line
            name="95% Confidence Interval"
            dataKey="null123"
            stroke="grey"
            strokeDasharray="5 5"
            opacity={0.1}
          />

          {activity?.bilateral === 0 && (
            <Line type="linear" dataKey="leftValue" stroke={NEW_LEFT_COLOR} connectNulls={false}/>
          )}
          {activity?.bilateral === 0 && (
            <Line type="linear" dataKey="rightValue" stroke={NEW_RIGHT_COLOR} connectNulls={false}/>
          )}

          {activity?.bilateral === 1 && (
            <Legend
              verticalAlign="top"
              align="right"
              iconType="plainline"
              layout="horizontal"
              wrapperStyle={{ top: -20, right: 0, fontSize: 11 }}
            />
          )}

          <Tooltip
            cursor={{ fill: NEW_AXIS_COLOR, opacity: ".3" }}
            // content={<GraphTableData data={chartData} component={component}/>}
            content={<GraphTableData data={chartData} />}
            trigger={activeData.locked ? "click" : "hover"}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

export default React.memo(DLCJ);
