/* eslint-disable */
import "./warningIndicator.scss";
import InfoIcon from "../icons/infoIcon/infoIcon";

const WarningIndicator = (props) => {
  const { warningIcon = InfoIcon, warningText = "Temp Text" } = props;


  return (
    <div className="warning-indicator">
      {warningIcon}
      <span className="warning-indicator-text">{warningText}</span>
    </div>
  );
};

export default WarningIndicator;
