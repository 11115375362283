import { motion } from 'framer-motion';
import './button.scss'

const buttonVariants = {
  rest: {
    scale: 1,
    opacity: 1,
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
  },
  pressed: {
    scale: 0.9,
    opacity: 0.8,
    boxShadow: "0px 0px 0px 5px rgba(0,0,0,0.1)",
  },
};

const buttonTransition = {
  duration: 0.2,
  ease: "easeInOut",
};

const Button = ({ type='button', value, icon, onClickHandler, warning, maxWidth, disabled = false, loadingAnimation = false, loadingValue, active = false }) => {

  return (
    <motion.button
    variants={buttonVariants}
    transition={buttonTransition}
    whileHover="rest"
    type={type}
    whileTap="pressed"
      onClick={onClickHandler}
      className={!loadingAnimation ? `btn-g ${value === 'No' ? 'btn-dialog' : ''} ${value === 'View All' ? 'btn-view' : 'btn-report'} ${warning ? "btn-warning" : ""} ${active ? "btn-active" : ""} ${maxWidth ? "btn-max-width" : ""}` : `btn-g btn-warning-pulse ${value === 'View All' ? 'btn-view' : 'btn-report'} ${maxWidth ? "btn-max-width" : ""}`}
      disabled={disabled}
    >
      {icon} {icon ? <span style={{ paddingLeft: '2px', paddingRight: '2px' }} /> : ''}{!loadingAnimation ? value : loadingValue}
    </motion.button>
  )
}



export default Button