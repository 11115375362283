/* eslint-disable */
import { useXarrow } from "react-xarrows";
import { useRef } from "react";
import Xarrow from "react-xarrows";
import { NEW_LEFT_COLOR, NEW_RIGHT_COLOR } from "utils/constant";
// Note! some of the feet changes have already been aded including the alternative foot svg parts
export default function FootStrikeFoot(props) {
  const { whichFoot, footData } = props;

  const TopAlternate = (props) => {
    const {
      width = 110,
      fill = "blue",
      stroke = "none",
      strokeWidth = "10px",
      opacity = 1,
    } = props;
    return (
      <svg
        width={width}
        height={width}
        viewBox={`70 25 200 200`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // fill={fill}
          // stroke={stroke}
          // strokeWidth={strokeWidth}
          strokeOpacity={opacity}
          style={{
            fill: "white",
            stroke: fill,
            strokeWidth: strokeWidth,
            strokeMiterlimit: 6,
            paintOrder: "fill",
            fillOpacity: 0,
            width: "40px",
          }}
          d="M 166.585 178.692 C 206.72 170.397 240.476 173.178 240.476 173.178 C 243.089 125.374 242.26 92.87 225.251 66.416 C 225.251 66.416 214.762 51.554 206.14 47.292 C 195.629 42.096 182.516 39.827 171.053 42.293 C 159.45 44.789 148.419 51.133 139.724 59.211 C 124.38 73.466 114.142 92.905 105.89 112.155 C 97.627 131.43 91.197 152.277 90.852 173.246 C 90.669 184.345 93.44 206.507 93.44 206.507 C 93.44 206.507 135.543 185.107 166.585 178.692 Z"
        ></path>
      </svg>
    );
  };

  const MidAlternate = ({
    width = 100,
    fill = "blue",
    stroke = "none",
    strokeWidth = "0",
    opacity = 1,
  }) => {
    return (
      <svg
        width={width}
        height={width}
        viewBox={`70 170 200 190`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // fill={fill}
          // stroke={stroke}
          // strokeWidth={strokeWidth}
          strokeOpacity={opacity}
          style={{
            fill: "white",
            stroke: fill,
            strokeWidth: strokeWidth,
            strokeMiterlimit: 6,
            paintOrder: "fill",
            fillOpacity: 0,
            width: "40px",
          }}
          d="M 113.929 315.53 C 113.929 315.53 110.317 283.852 107.143 268.484 C 104.038 253.452 96.491 223.684 96.491 223.684 C 96.491 223.684 136.838 203.398 160.714 197.055 C 197.656 187.241 239.035 187.97 239.035 187.97 C 239.035 187.97 234.226 209.914 229.95 220.238 C 225.782 230.303 217.346 244.583 213.745 254.864 C 208.638 269.446 206.239 279.037 206.454 294.486 C 206.669 309.906 215.226 339.912 215.226 339.912 C 215.226 339.912 181.015 337.764 164.474 333.647 C 147.442 329.408 113.929 315.53 113.929 315.53 Z"
        ></path>
      </svg>
    );
  };

  const BackAlternate = ({
    width = 100,
    fill = "blue",
    stroke = "none",
    strokeWidth = "0",
    opacity = 1,
  }) => {
    return (
      <svg
        width={width}
        height={width}
        viewBox={`70 320 190 180`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // fill={fill}
          // stroke={stroke}
          // strokeWidth={strokeWidth}
          strokeOpacity={opacity}
          style={{
            fill: "white",
            stroke: fill,
            strokeWidth: strokeWidth,
            strokeMiterlimit: 6,
            paintOrder: "fill",
            fillOpacity: 0,
            width: "40px",
          }}
          d="M 113.636 333.912 C 113.636 333.912 143.85 347.3 160.401 352.13 C 179.526 357.711 219.612 360.276 219.612 360.276 C 219.612 360.276 225.342 374.844 226.504 382.519 C 228.38 394.909 229.841 408.034 226.504 420.113 C 223.037 432.663 217.329 446.475 206.454 453.634 C 194.574 461.454 178.313 460.675 164.16 459.273 C 152.297 458.098 138.926 455.938 130.326 447.682 C 121.596 439.302 119.667 425.687 117.168 413.847 C 111.426 386.64 113.636 333.912 113.636 333.912 Z"
        ></path>
      </svg>
    );
  };

  const colours = {
    leftFoot: NEW_LEFT_COLOR,
    rigthFoot: NEW_RIGHT_COLOR,
  };

  const checkHighest = (value) => {
    let testArr = [...footData];
    testArr = testArr.sort((a, b) => a - b);

    if (testArr.indexOf(value) === 0) {
      return 0.3;
    }

    if (testArr.indexOf(value) === 1) {
      return 0.6;
    }

    if (testArr.indexOf(value) === 2) {
      return 1;
    }
  };

  //Style for the foot arrows
  // size of the arrow head
  const headSize = 0;
  // path types > "smooth" | "grid" | "straight"
  const arrowPath = "straight";
  // width of the arrow line
  const arrowStrokeWidth = 1;
  //arrow dashness boolean
  const arrowDashness = false;

  //feet variables
  const footStrokeWidth = 12;

  if (whichFoot === "left") {
    const topFootLeftRef = useRef(null);
    const midFootLeftRef = useRef(null);
    const backFootLeftRef = useRef(null);
    return (
      // <div className="foot-segments foot-segments-left">
      //   <div className="top-segment">
      //     {/* <div> */}
      //     <div style={{ transform: "scaleX(-1)" }}>
      //       <Top
      //         fill={colours.leftFoot}
      //         stroke={colours.leftFoot}
      //         opacity={checkHighest(footData[0])}
      //       />
      //     </div>
      //     <h4 style={{ transform: "scaleX(1)" }}>{footData[0]}</h4>
      //   </div>
      //   <div className="mid-segment">
      //     {/* <div> */}
      //     <div style={{ transform: "scaleX(-1)" }}>
      //       <Mid
      //         fill={colours.leftFoot}
      //         stroke={colours.leftFoot}
      //         opacity={checkHighest(footData[1])}
      //       />
      //     </div>
      //     <h4 style={{ transform: "scaleX(1)" }}>{footData[1]}</h4>
      //   </div>
      //   <div className="back-segment">
      //       {/* <div> */}
      //       <div style={{ transform: "scaleX(-1)" }}>
      //       <Back
      //         fill={colours.leftFoot}
      //         stroke={colours.leftFoot}
      //         opacity={checkHighest(footData[2])}
      //       />
      //     </div>
      //     <h4 style={{ transform: "scaleX(1)", marginTop: "-80%" }}>
      //       {footData[2]}
      //     </h4>
      //   </div>
      // </div>
      <div className="foot-segments-withValues withValues-left">
        <div className="foot-segments foot-segments-left">
          <div className="top-segment" ref={topFootLeftRef}>
            <TopAlternate
              fill={colours.leftFoot}
              stroke={colours.leftFoot}
              opacity={checkHighest(footData[0])}
              strokeWidth={footStrokeWidth}
            />
          </div>
          <div style={{ opacity: checkHighest(footData[0]) }}>
            <Xarrow
              color={colours.leftFoot}
              start={topFootLeftRef}
              end={"top-foot-left"}
              headSize={headSize}
              path={arrowPath}
              strokeWidth={arrowStrokeWidth}
              dashness={arrowDashness}
            />
          </div>

          <div className="mid-segment" ref={midFootLeftRef}>
            <MidAlternate
              fill={colours.leftFoot}
              stroke={colours.leftFoot}
              opacity={checkHighest(footData[1])}
              strokeWidth={footStrokeWidth}
            />
          </div>
          <div style={{ opacity: checkHighest(footData[1]) }}>
            <Xarrow
              color={colours.leftFoot}
              start={midFootLeftRef}
              end={"mid-foot-left"}
              headSize={headSize}
              path={arrowPath}
              strokeWidth={arrowStrokeWidth}
              dashness={arrowDashness}
            />
          </div>
          <div className="back-segment" ref={backFootLeftRef}>
            <BackAlternate
              fill={colours.leftFoot}
              stroke={colours.rigleftFootthFoot}
              opacity={checkHighest(footData[2])}
              strokeWidth={footStrokeWidth}
            />
          </div>
          <div style={{ opacity: checkHighest(footData[2]) }}>
            <Xarrow
              color={colours.leftFoot}
              start={backFootLeftRef}
              end={"back-foot-left"}
              headSize={headSize}
              path={arrowPath}
              strokeWidth={arrowStrokeWidth}
              dashness={arrowDashness}
            />
          </div>
        </div>
        <div className="foot-values">
          <h4
            style={{
              color: colours.leftFoot,
              opacity: checkHighest(footData[0]),
            }}
            id="top-foot-left"
            className="top-foot-heading"
          >
            {footData[0]}
          </h4>
          <h4
            style={{
              color: colours.leftFoot,
              opacity: checkHighest(footData[1]),
            }}
            id="mid-foot-left"
            className="mid-foot-heading"
          >
            {footData[1]}
          </h4>
          <h4
            style={{
              color: colours.leftFoot,
              opacity: checkHighest(footData[2]),
            }}
            id="back-foot-left"
            className="back-foot-heading"
          >
            {footData[2]}
          </h4>
        </div>
      </div>
    );
  }

  if (whichFoot === "right") {
    const topFootRef = useRef(null);
    const midFootRef = useRef(null);
    const backFootRef = useRef(null);
    return (
      <div className="foot-segments-withValues">
        <div className="foot-segments foot-segments-right">
          <div
            className="top-segment"
            ref={topFootRef}
            style={{ transform: "scaleX(-1)" }}
          >
            <TopAlternate
              fill={colours.rigthFoot}
              stroke={colours.rigthFoot}
              opacity={checkHighest(footData[0])}
              strokeWidth={footStrokeWidth}
            />
          </div>
          <div style={{ opacity: checkHighest(footData[0]) }}>
            <Xarrow
              color={colours.rigthFoot}
              start={topFootRef}
              end={"top-foot"}
              headSize={headSize}
              path={arrowPath}
              strokeWidth={arrowStrokeWidth}
              dashness={arrowDashness}
            />
          </div>

          <div
            className="mid-segment"
            ref={midFootRef}
            style={{ transform: "scaleX(-1)" }}
          >
            <MidAlternate
              fill={colours.rigthFoot}
              stroke={colours.rigthFoot}
              opacity={checkHighest(footData[1])}
              strokeWidth={footStrokeWidth}
            />
          </div>
          <div style={{ opacity: checkHighest(footData[1]) }}>
            <Xarrow
              color={colours.rigthFoot}
              start={midFootRef}
              end={"mid-foot"}
              headSize={headSize}
              path={arrowPath}
              strokeWidth={arrowStrokeWidth}
              dashness={arrowDashness}
            />
          </div>
          <div
            className="back-segment"
            ref={backFootRef}
            style={{ transform: "scaleX(-1)" }}
          >
            <BackAlternate
              fill={colours.rigthFoot}
              stroke={colours.rigthFoot}
              opacity={checkHighest(footData[2])}
              strokeWidth={footStrokeWidth}
            />
          </div>
          <div style={{ opacity: checkHighest(footData[2]) }}>
            <Xarrow
              color={colours.rigthFoot}
              start={backFootRef}
              end={"back-foot"}
              headSize={headSize}
              path={arrowPath}
              strokeWidth={arrowStrokeWidth}
              dashness={arrowDashness}
            />
          </div>
        </div>
        <div className="foot-values">
          <h4
            style={{
              color: colours.rigthFoot,
              opacity: checkHighest(footData[0]),
            }}
            id="top-foot"
            className="top-foot-heading"
          >
            {footData[0]}
          </h4>
          <h4
            style={{
              color: colours.rigthFoot,
              opacity: checkHighest(footData[1]),
            }}
            id="mid-foot"
            className="mid-foot-heading"
          >
            {footData[1]}
          </h4>
          <h4
            style={{
              color: colours.rigthFoot,
              opacity: checkHighest(footData[2]),
            }}
            id="back-foot"
            className="back-foot-heading"
          >
            {footData[2]}
          </h4>
        </div>
      </div>
    );
  }
}
