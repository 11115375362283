/* eslint-disable */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrganisationDetail_URL } from "../utils/urls";
import "../styles/org_list2.scss";
import { useEffect, useState } from "react";
import Button from "./common/button/button";
import { getOrganisationsToolkit } from "store/orgSlice";
import { selectOrganisation } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import useWhyDidYouUpdate from "./common/hooks/useWhydidyouUpdate";
import DialogDemo from "./common/modalBox/modalBox";
import ReuseableInfiniteScrollingTable from "./infiniteTable/ReuseableTable";
import OrgTableHeadings from "./common/organisationTable/OrgTableHeadings";
import { motion } from "framer-motion";

function OrganisationsList2(props) {
  useWhyDidYouUpdate("organisationList", props);

  //organisations state
  const [fullOrgList, setFullOrgList] = useState([]);
	const [filteredOrgList, setFilteredOrgList] = useState([])

  //search state
  const [searchList, setSearchList] = useState([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");

  //slider
  const [editForm, setEditForm] = useState(false);

  //props declared
  const { actions, organisation, history, user } = props;

  useEffect(() => {
    // if was   (organisation.orgList === [] || organisation.orgList.length === 0) &&
    if (
      (organisation.orgList || organisation.orgList.length === 0) &&
      organisation.status !== "loading" &&
      organisation.status !== "succeeded" &&
      user.id
    ) {
      actions.getOrganisationsToolkit(user.id);
    }
  }, [organisation.orgList, organisation.status, user.id, actions]);

  //use effect used to set organisations states
  useEffect(() => {
    setFullOrgList(organisation.orgList);

    //temp org list
    let tempfullOrgList = fullOrgList;

    //checks if search term exists and if the length is more than 0
    if (currentSearchTerm && currentSearchTerm.length > 0) {
      //filters over the temp org list
      tempfullOrgList = tempfullOrgList.filter((row) => {
        return (
          (row.Name + "")
            .toLowerCase()
            .indexOf(currentSearchTerm.toLowerCase()) > -1
        );
      });
      //only sets the search list if it differs from the tempFullOrg list length
      if (JSON.stringify(searchList) !== JSON.stringify(tempfullOrgList)) {
        setSearchList(tempfullOrgList);
				setFilteredOrgList(tempfullOrgList);
      }
    } else{
			setFilteredOrgList(tempfullOrgList);
		}
  }, [
    fullOrgList,
    organisation.orgList,
    currentSearchTerm,
    searchList,
  ]);

  //org detail page
  function goToOrgDetailPage(org) {
    history.push(OrganisationDetail_URL + "/" + org.OrgID);
  }

  //search function sets current search term to state called onChange in search input
  function handleSearchChange(e) {
    setCurrentSearchTerm(e.target.value.trim());
  }

  //hide form function
  function hideForm() {
    setEditForm((prevEditForm) => !prevEditForm);
    document.body.classList.add("modal-open");
  }

	 //display table data function
	 function displayTableData() {
		return(
			filteredOrgList.length > 0
				? filteredOrgList.map((org, i) => {
						return (
							<motion.div
								initial={{ opacity: 0, scale: 0.5 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{
									duration: 0.6,
									delay: 0.1 * i,
									ease: [0, 0.71, 0.2, 1.01],
								}}
								key={i}
							>
								<div
									className="org-item-container"
									key={org.OrgID}
									onClick={() => {
										goToOrgDetailPage(org);
									}}
								>
									<div className="org-tables-row">
										<div className="org-col org-col-name">{org.Name}</div>
										<div className="org-col">{org.admins.length}</div>
	
										<div className="org-col">{org.coaches.length}</div>
	
										<div className="org-col">{org.players.length}</div>
	
										<div className="org-col org-col-last">
											{org.pods.length}
										</div>
									</div>
								</div>
							</motion.div>
						);
					})
				: "No Organisations Found"
		)
  }

  return (
    <div className="org-container">
      <div className="organisations-head">
        <h1>Organisations</h1>

        <div className="organisation-search">
          <div className="search-input-parent">
            <input
              type="text"
              autoComplete="off"
              id="searchInput"
              onChange={(e) => handleSearchChange(e)}
              placeholder="Search Organisations"
            />
          </div>

          {user.permissionLevel === 1 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DialogDemo
                process="detail"
                close={setEditForm}
                button={
                  <Button
                    onClickHandler={hideForm}
                    value={"Register Organisation"}
                  />
                }
                open={editForm}
              />
            </div>
          )}
        </div>
      </div>
			<div className="org-table-container">
				<ReuseableInfiniteScrollingTable 
					dataToDisplay={fullOrgList}
					headingComponet={<OrgTableHeadings/>}	
					rowComponent={displayTableData}
				/>
			</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    organisation: selectOrganisation(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getOrganisationsToolkit }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsList2);
