/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserProfile_URL } from "../../utils/urls";
import { getOrganisationsToolkit } from "store/orgSlice";
import AthletesTable from "components/athletes/athletesTable/athletesTable";
import MyTabs from "components/common/mytabs/mytabs";
import "./UsersHome2.scss";
import Pagination from "components/common/Pagination/Pagination";
import Table from "components/common/table/table";
import GraphHeader from "components/common/table/header/header";
import CircularProgressTable from "components/common/circularProgressTable/circularProgressTable";
import { selectOrganisation } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityTableRow from "components/activityTable/activityTableRow";
import PlayerTableRow from "components/activityTable/playerTableRow";
import OrderColumns from "components/common/orderColumns/OrderColumns";
import OrderColumnsAthleteTable from "components/common/orderColumns/OrderColumnsAthleteTable";
import AthletesComparison from "components/compare-athletes/AthletesComparison";

const UsersHome2 = (props) => {
  const pageLength = 10;
  const [value, setValue] = React.useState("");
  const [checkedAthletesList, setCheckedAthletesList] = useState([]);
  const [modalIn, setModalIn] = React.useState(false);
  const [collection, setCollection] = React.useState([]);
  const [editForm, setEditForm] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const filteredData = useMemo(() => {
    if (value && value.length >= 1) {
      return collection.filter((row) => {
        return (
          (row.player + "").toLowerCase().indexOf(value.toLowerCase()) > -1
        );
      });
    }
    return collection;
  }, [value, collection]);
  const [listItems, setListItems] = React.useState(
    Array.from(filteredData.slice(0, 20))
  );

  React.useEffect(() => {
    setListItems(Array.from(filteredData.slice(0, 20)));
    setHasMore(filteredData.length > 20);
  }, [filteredData]);

  const { organisation, user, actions } = props;

  useEffect(() => {
    if (
      //removed, causing needless warning, left prev version in case it was importand or used
      // (organisation.orgList === [] || organisation.orgList.length === 0) &&
      organisation.orgList.length === 0 &&
      organisation.status !== "loading" &&
      user.id
    ) {
      actions.getOrganisationsToolkit(user.id);
    }
  }, [organisation.orgList, organisation.status, user.id, actions]);

  useEffect(() => {
    if (organisation.orgList.length > 0) {
      const playerList = organisation.orgList
        .map((players) => players.players)
        .flat();
      playerList.sort((a, b) =>
        a.player.toLowerCase().localeCompare(b.player.toLowerCase())
      );
      setCollection(playerList.slice());
    }
  }, [organisation.orgList]);

  function goToPlayerProfilePage(player) {
    props.history.push(UserProfile_URL + "/" + player);
  }

  const showForm = useCallback(() => {
    setEditForm(() => !editForm);
  }, [editForm]);

  function disableScrollModalOpen() {
    if (editForm) {
      document.body.classList.add("disableScroll");
    }
  }

  function enableScrollModalClosed() {
    if (!editForm) {
      document.body.classList.remove("disableScroll");
    }
  }

  if (editForm) {
    disableScrollModalOpen();
  }

  if (!editForm) {
    enableScrollModalClosed();
  }

  const handleChange = (e) => {
    setValue(e);
  };

  function fetchMoreListItems() {
    if (listItems.length >= filteredData.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setListItems((prevState) => [
        ...prevState,
        ...filteredData.slice(prevState.length, prevState.length + 20),
      ]);
    }, 200);
  }


  const fields = {
    player: "Athletes",
    gait: "gait",
    jump: "jump",
    balance: "balance",
  };

  const tabs = [
    {
      title: "Athletes",
      value: "tab1",
      length: filteredData.length,
      display: (
        <>
          <OrderColumnsAthleteTable
            checkedAthletesList={checkedAthletesList}
            setCheckedAthletesList={setCheckedAthletesList}
          />

          <InfiniteScroll
            dataLength={listItems.length}
            next={fetchMoreListItems}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            height={"60vh"}
          >
            {listItems.map((player, i) => {
              return (
                <PlayerTableRow
                  key={player.ID}
                  player={player}
                  i={i}
                  fullSessionList={filteredData}
                  link={goToPlayerProfilePage}
                />
              );
            })}
          </InfiniteScroll>
        </>
      ),
    },
    {
      title: "Teams",
      value: "tab2",
      display: (
        <div className="graph-container">
          {" "}
          <div className="table-one">
            {" "}
            <Table
              fields={fields}
              cls={"home-page-table"}
              header={
                <GraphHeader toShow={"Athletes"} link={"/athletes_home"} />
              }
              headers={["Athletes", "Gait", "Jump", "Balance"]}
              data={filteredData.slice(0, 10)}
            />
          </div>{" "}
          <div className="circular-cont">
            {" "}
            <CircularProgressTable />{" "}
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="users-home-container">
        <MyTabs
          header={true}
          setValue={handleChange}
          tabs={tabs}
          showForm={showForm}
          open={editForm}
          list={filteredData}
          setModalIn={setModalIn}
        />
        <AthletesComparison modalIn={modalIn} setModalIn={setModalIn} />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    organisation: selectOrganisation(state),
    user: selectUser(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getOrganisationsToolkit }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersHome2);
