import Report from '../common/icons/report'
import './welcome.scss'
import { motion } from 'framer-motion';
import MainButton from 'components/common/reuseableButtonComponent/MainButton';

const Welcome = ({name, action = null, user}) => {
    const myArray = name?.split(" ");
    let word = myArray ? myArray[0] : [];

    const childVariants = {
      initial: { opacity: 0, x: -50 },
      animate: { opacity: 1, x: 0 }
    };
    
  return (
    <>
        <div className='welcome-container'>
        <div className='h-j'>   <motion.p
          className="welcome"
          variants={childVariants}
          initial="initial"
          animate="animate"
          transition={{
            duration: 0.6,
            delay: 0.2 * 0,
            ease: [0, 0.71, 0.2, 1.01]
          }}
        >Hi </motion.p>    <motion.p
        className="welcome"
        variants={childVariants}
        initial="initial"
        animate="animate"
        transition={{
          duration: 0.6,
          delay: 0.2 * 1,
          ease: [0, 0.71, 0.2, 1.01]
        }}
      > {word}</motion.p></div>
          {user.permissionLevel === 1 && action &&<div onClick={()=> action()}>
            <MainButton buttonText={"Pod Database"} ButtonIcon={Report}/>
            {/* <Button value={'Pod Database'} icon={<Report/>}/> */}
          </div>}
          
        </div>

    </>
  )
}





export default Welcome