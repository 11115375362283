/* eslint-disable */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { setCheckedAthletesForComparison } from "store/playerActivitySlice";
import moment from "moment";

const PlayerTableRow = ({ player, i, link }) => {
  const { checkedAthletesForComparison } = useSelector(
    (state) => state.playerActivity
  );
  const dispatch = useDispatch();
  const setCheckedAthletesForCompare = (data) =>
    dispatch(setCheckedAthletesForComparison(data));

  const isBatchStart = i % 20 === 0; // assuming batch size of 20
  const animationDelay = isBatchStart ? 0 : 0.1 * (i % 20);
  const checkedAthletesForComparisonList = checkedAthletesForComparison;

  const handleChecked = (checked, value) => {
    let tempArray;

    if (checked && !checkedAthletesForComparisonList.includes(value)) {
      tempArray = [...checkedAthletesForComparisonList, value];

      setCheckedAthletesForCompare(tempArray);
    } else {
      tempArray = checkedAthletesForComparisonList.filter(
        (item) => item !== value
      );

      setCheckedAthletesForCompare(tempArray);

      // setCheckedAthletesList(tempArray);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.6,
        delay: animationDelay,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      style={{ marginBottom: "0.5rem" }}
      key={i}
    >
      {" "}
      <div className="session-list-row">
        <div className="session-list-flex">
          {/* Checkbox Column --------------------------------------------------------------- */}
          <div className="column-check-div">
            <div className="checkbox-outside-table">
              <input
                className="checkboxes"
                type={"checkbox"}
                id={player.playerID}
                value={JSON.stringify(player)}
                onChange={(value) =>
                  handleChecked(value.target.checked, value.target.defaultValue)
                }
              />
            </div>
            <label htmlFor={player.playerID} className="column-checkbox">
              <div
                className={`clickable-initials-bubble  ${
                  Array.isArray(checkedAthletesForComparison) &&
                  checkedAthletesForComparison.includes(JSON.stringify(player))
                    ? "clickable-initials-bubble-selected"
                    : ""
                }`}
              >
                <div
                  className={`initials-bubble ${
                    Array.isArray(checkedAthletesForComparison) &&
                    checkedAthletesForComparison.includes(
                      JSON.stringify(player)
                    )
                      ? "initials-bubble-selected"
                      : ""
                  }`}
                >
                  <p>
                    {Array.isArray(checkedAthletesForComparison) &&
                    checkedAthletesForComparison.includes(
                      JSON.stringify(player)
                    )
                      ? "✓"
                      : ""}
                  </p>
                </div>
              </div>
            </label>
          </div>

          <div
            className="column-data-div"
            onClick={() => link(player.playerID)}
          >
            {/* Player Name Column --------------------------------------------------------------- */}
            <div className="data-row athlete-column">
              <p>{player.player || player.playerName}</p>
            </div>

            {/* Player age Column --------------------------------------------------------------- */}
            <div className="data-row athlete-age">
              <p>{player.dob ? moment().diff(player.dob, "years") : player.age}</p>
            </div>

            {/* Player weight Column --------------------------------------------------------------- */}
            <div className="data-row athlete-weight">
              <p>{player?.weight ? player.weight : "-"}</p>
            </div>

            {/* Player height  Column --------------------------------------------------------------- */}
            <div className="data-row athlete-height">
              <p>{player?.height ? player.height : "-"}</p>
            </div>

            {/* Player shoe size  Column --------------------------------------------------------------- */}
            <div className="data-row athlete-shoe-size">
              <p>{player?.shoeSize ? player.shoeSize : "-"}</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(PlayerTableRow);
