/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { connect } from 'react-redux';
import './modalBox.scss';
import TextInput from '../input/input';
import { isValidEmail } from 'utils/helper';
import DisplayCreatedScreen from '../displayCreatedScreen/displayCreatedScreen';
import { bindActionCreators } from 'redux';
import { postFile } from 'api/apiHelper';
import { getOrganisationsToolkit, resetOrganisationDetailView } from 'store/orgSlice';
import { selectUserList, getUsers, addAdminToOrg, addCoachToOrg, createNewOrg, addPod, addTagToOrg } from 'store/adminSlice';
import { selectUser } from 'store/authSlice';
import { selectOrganisation } from 'store/orgSlice';
import Button from '../button/button';
import Spinner from '../spinner/spinner';



const DialogDemo = (props) => {
    const { button, existingOrg, process, newTagAdded } = props
    const [selectedUser, setSelectedUser] = useState();
    const [file, setFile] = useState();
    const [newOrg, setNewOrg] = useState(0);
    const [state, setState] = React.useState({
        orgName: "",
        userName: "",
        emailOne: "",
        emailTwo: "",
        tagName: "",
        tagDetail: "",
        BLEID: '',
        tagRegistered: false,
        podRegistered: false,
        selectedOrg: undefined,
        loading: false,
        adminAdded: false,
        coachAdded: false,
    })


    const [errors, setErrors] = useState({});
    const [orgCreated, setOrgCreated] = useState(false);
    const [newUserAddedComplete, setNewUserAddedComplete] = useState(0);
    const reset = () => {
        setSelectedUser();
        setFile();
        setNewOrg(0);
        setState({})
        setErrors({});
        setOrgCreated(false);
        setNewUserAddedComplete(0);
    };


    useEffect(() => {
        if (props.matchId?.id) {
            const selectedOrg = props.matchId.id || undefined;
            setState({ ...state, selectedOrg: selectedOrg })
        }

    }, [props.matchId?.id, state.selectedOrg]);


    const validateInputs = () => {
        const errors = {};
        errors.formErrors = false;

        if ((!state.orgName || state.orgName.length === 0) && !existingOrg) {
            errors.error = 'You must enter the organisation name';
            errors.formErrors = true;
            setState({ ...state, loading: false })
        }

        if (state?.emailOne?.length === 0) {
            errors.error = 'Please enter email';
            errors.formErrors = true;
            setState({ ...state, loading: false })
        }
  
        if (!isValidEmail(state.emailOne)) {
            errors.error = 'Email is not valid';
            errors.formErrors = true;
            setState({ ...state, loading: false })
        }

        if (state.emailOne !== state.emailTwo && !selectedUser) {
            errors.error = 'Emails do not match';
            errors.formErrors = true;
            setState({ ...state, loading: false })
        }

        if (selectedUser && (state.userName || state.emailOne || state.emailTwo)) {
            errors.error = 'You cannot select a user and Invite a user at the same time!';
            errors.formErrors = true;
            setState({ ...state, loading: false })
        }
        setErrors(errors);
        return !errors.formErrors;
    };

    function validateInputsPod() {
        const errors = {};
        errors.formErrors = false;

        if (state.BLEID === '') {
            errors.error = 'You must enter the BLEID';
            errors.formErrors = true;
        }

        else if (!state.selectedOrg || state.selectedOrg < 0) {
            errors.error = 'You must link the POD to an Organisation';
            errors.formErrors = true;
        }

        setErrors(errors);
        return !errors.formErrors;
    }

    function validateInputsTags() {
        const errors = {}
        errors.formErrors = false;
        if (state.tagName === '') {
            errors.error = 'You must enter the Tag Name';
            errors.formErrors = true;
        }

        setErrors(errors);
        return !errors.formErrors;
    }

    const handleFileChange = event => {
        if (event.target.files.length > 0) {
            setFile(event.target.files[0]);

            var image = document.getElementById('tempOrgIcon');
            image.src = URL.createObjectURL(event.target.files[0]);
        }
    };

    const fileInputRef = useRef(null);


    const triggerFileInput = event => {
        event.preventDefault()
        fileInputRef.current.click();

    }

    function addAdmin(e) {
        e.preventDefault();
        if (validateInputs()) {
          
            const { userName, emailOne, selectedUser } = state;
            const { existingOrg, process, actions } = props;

            let postBody = {
                name: existingOrg.Name,
                adminName: userName,
                email: emailOne,
                userID: selectedUser
            }

            setState({ ...state, loading: true })

            if (process === "addAdmin") {
                actions.addAdminToOrg({ OrgID: existingOrg.OrgID, postBody })
                    .then(() => {
                        handleAddResult(1);
                        setState({ ...state, adminAdded: true })
                        setNewOrg(1)
                    })
                    .catch((error) => {
                        setState({ ...state, loading: false })
                        handleAddResult(-1);
                    });
            }
            else if (process === "addCoach") {
                actions.addCoachToOrg({ OrgID: existingOrg.OrgID, postBody })
                    .then(() => {
                        handleAddResult(1);
                        setState({ ...state, coachAdded: true })
                        setNewOrg(1)
                    })
                    .catch((error) => {
                        setState({ ...state, loading: false })
                        handleAddResult(-1);
                    });
            }

        }

    }

    async function addTag(e) {
        e.preventDefault()
        setState({ ...state, loading: true }) 

        if(validateInputsTags()) {
            
            
            let postBody = {
                tag: state.tagName,
                detail: state.tagDetail,
                orgid: parseInt(state.selectedOrg),
                createdByUserID: props.user.id
            }
            try {
                const data = await props.actions.addTagToOrg(postBody)
                if(data.payload.length > 0){
                    setState({ ...state, loading: false, tagRegistered: true })
                    setNewOrg(1)
                    newTagAdded(true)
                    return
                }
            }
            catch {
                setErrors('An Error has occured adding the tag')
            }
        }
        setState({ ...state, loading: false })
       
    }



    async function addPod(e) {
        e.preventDefault()
        setState({ ...state, loading: true })
        if (validateInputsPod()) {

            let postBody = {
                ble: state.BLEID,
                orgID: parseInt(state.selectedOrg)
            }
            try {
                const data = await props.actions.addPod(postBody)
                if (data.payload.result > 0) {
                    handleAddResult(1);
                    setState({ ...state, loading: false, podRegistered: true })
                    setNewOrg(1)
                    return
                }
            }
            catch {
                setErrors('An Error Occured')
            }
        }
        setState({ ...state, loading: false })
    }

   
    function handleAddResult(success) {
        const { emailOne } = state;
        if (emailOne && emailOne !== "") {
            // we have sent an invite for a new user
            setNewUserAddedComplete({ newUserAddedComplete: success });
        }
        else {
            // we have set up an existing user
            props.actions.resetOrganisationDetailView();
        }
    }

    function createOrg(e) {
        setState({ ...state, loading: true })
        e.preventDefault();

        if (validateInputs()) {
            const { orgName, userName, emailOne } = state;
            const { user, actions } = props;

            let newOrg = {
                name: orgName,
                adminName: userName,
                email: emailOne,
                userID: user.id
            }

            actions.createNewOrg(newOrg)
                .then(res => {
                    const { file } = state;
                    if (file && res.payload.orgID > 0) {
                        addIcon(res.payload.orgID);
                    }

                    setOrgCreated(true)
                    setNewOrg(res.payload.orgID)
                    setState({ ...state, loading: false })

                })
                .catch((error) => {
                    setState({ ...state, loading: false })
                    setOrgCreated(false)
                    setNewOrg(-1)
                });
        }
    }

    function addIcon(orgID) {
        const { file } = state;
        let url = `organisation/addImage/${orgID}`

        postFile(url, file)
            .then(res => {
                if (res.data.updated) {
                    // success
                } else {
                    // failure
                }
            })
    }

    function onChange(e) {
        setState({ ...state, [e.target.name]: e.target.value });
    }


    function handleUserSelect(selectedOrg) {
        setState({ ...state, selectedOrg: selectedOrg });
    }


    let iconStyle = {
        display: 'none',
        width: 120,
    }

    if (file) {
        iconStyle.display = 'inline'
    }







    const registers = {
        'addAdmin': <> <Dialog.Title className="DialogTitle">Register Admin</Dialog.Title>
            <Dialog.Description className="DialogDescription">
                You can add a new Admin and it&apos;d be registered under organisations
            </Dialog.Description></>,
        'addCoach': <> <Dialog.Title className="DialogTitle">Register New Coach</Dialog.Title>
            <Dialog.Description className="DialogDescription">
                You can add a new Coach and it&apos;d be registered under your organisation
            </Dialog.Description></>,
        'detail': <> <Dialog.Title className="DialogTitle">Register New Organisation</Dialog.Title>
            <Dialog.Description className="DialogDescription">
                You can create a new Organisation and it&apos;d be registered under Organisations
            </Dialog.Description></>,
        'podRegister': <> <Dialog.Title className="DialogTitle">Register New Pod</Dialog.Title>
            <Dialog.Description className="DialogDescription">
                You can register a new Pod and it&apos;d be registered under Pod Database
            </Dialog.Description></>,
        'tagRegister': <> <Dialog.Title className="DialogTitle">Register New Tag</Dialog.Title>
        <Dialog.Description className="DialogDescription">
            You can register a new here, tags can be assigned to session which can improve the filtering process.
        </Dialog.Description></>
}
    const inputs = {
        'addAdmin':     
            <div className='modal-form-container'>
                <fieldset className="Fieldset">
                    <TextInput setter={onChange} cls={'reports-search'} type={'text'} value={state.userName} nameVal={'userName'} label='Administrator' />  
                </fieldset>
            </div>,
        'addCoach':     
            <div className='modal-form-container'>
                <fieldset className="Fieldset">
                    <TextInput setter={onChange} cls={'reports-search'} type={'text'} value={state.userName} nameVal={'userName'} label='Coach' /> 
                </fieldset>
            </div>,
        'detail':       
            <div className='modal-form-container'>
                <fieldset className="Fieldset">
                    <TextInput setter={onChange} cls={'reports-search'} value={state.orgName} nameVal={'orgName'} label='Organisation Name' /> 
                </fieldset> 
                <fieldset className="Fieldset">
                    <TextInput setter={onChange} cls={'reports-search'} type={'text'} value={state.userName} nameVal={'userName'} label='Administrator' />
                </fieldset>
            </div>,
        'podRegister':  
            <div className='modal-form-container'>
                <fieldset className="Fieldset">
                    <TextInput setter={onChange} cls={'reports-search'} value={state.BLEID} nameVal={'BLEID'} label='BLEID' /> 
                </fieldset> 
                <fieldset className="Fieldset">
                    <fieldset className='Fieldset athleteset'>
                            {props.organisation.orgList && (
                            <div
                                className="input_parent input_border"
                                style={{ marginTop: 30, marginBottom: 30 }}
                            >
                                <select id="org_select" onChange={(e) => handleUserSelect(e.target.value)}>
                                {props.matchId?.id ? (
                                    <>
                                    <option disabled hidden defaultValue={true} key={"select_-1"} value={-1}>
                                        Select Organisation
                                    </option>
                                    {props.organisation.orgList.map((org) => {
                                        if (org.OrgID === parseInt(props.matchId.id)) {
                                        return (
                                            <option
                                            defaultValue={org.OrgID === state.selectedOrg}
                                            key={"select_" + org.OrgID}
                                            value={org.OrgID}
                                            >
                                            {org.Name}
                                            </option>
                                        );
                                        }
                                        return null;
                                    })}
                                    </>
                                ) : (
                                    <>
                                    <option defaultValue={true} key={"select_-1"} value={-1}>
                                        Select Organisation
                                    </option>
                                    {props.organisation.orgList.map((org) => {
                                        return (
                                        <option
                                            defaultValue={org.OrgID === state.selectedOrg}
                                            key={"select_" + org.OrgID}
                                            value={org.OrgID}
                                        >
                                            {org.Name}
                                        </option>
                                        );
                                    })}
                                    </>
                                )}
                                </select>
                            </div>
                            )}
                    </fieldset>
                </fieldset>
            </div>,
        'tagRegister':
            <div className='modal-form-container'>
                <fieldset className="Fieldset">
                    <TextInput setter={onChange} cls={'reports-search'} value={state.tagName} nameVal={'tagName'} label='Tag Name' /> 
                </fieldset> 
                <fieldset className="Fieldset">
                    <TextInput setter={onChange} cls={'reports-search'} type={'text'} value={state.tagDetail} nameVal={'tagDetail'} label='Tag Detail' />
                </fieldset>
            </div>,                        
    }

    const buttons = {
        'addAdmin': <><Button maxWidth={true} onClickHandler={addAdmin} value={state.loading ? <Spinner /> : 'Add Admin'} /> </>,
        'addCoach': <><Button maxWidth={true} onClickHandler={addAdmin} value={state.loading ? <Spinner /> : 'Add Coach'} /></>,
        'detail': <><Button maxWidth={true} onClickHandler={createOrg} value={state.loading ? <Spinner /> : 'Create Organisation'} /></>,
        'podRegister': <><Button maxWidth={true} onClickHandler={addPod} value={state.loading ? <Spinner /> : 'Register POD'} /></>,
        'tagRegister': <><Button maxWidth={true} onClickHandler={addTag} value={state.loading ? <Spinner /> : 'Register Tag'} /></>
    }


    const close = () => {
        props.close(prevLogoutDialogOpen => !prevLogoutDialogOpen); document.body.classList.remove('modal-open');
      }

    const TITLE = registers[process]
    const FORMS = inputs[process]
    const BUTTONS = buttons[process]
    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                <span >
                    {button}
                </span>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className="DialogContent-general">
                    {orgCreated ?
                        <>
                            <Dialog.Title className="DialogTitle">Successful!</Dialog.Title>
                            <DisplayCreatedScreen type='Org' newOrg={newOrg} emailOne={state.emailOne} orgName={existingOrg?.Name ? existingOrg?.Name : state.orgName} userName={state.userName} orgCreated={orgCreated} reset={reset} />
                        </>
                        : state.adminAdded ?
                            <>
                                <Dialog.Title className="DialogTitle">Successful!</Dialog.Title>
                                <DisplayCreatedScreen type='Admin' newOrg={newOrg} emailOne={state.emailOne} orgName={existingOrg?.Name ? existingOrg?.Name : state.orgName} userName={state.userName} orgCreated={state.adminAdded} reset={reset} />
                            </>
                            : state.coachAdded ?
                                <>
                                    <Dialog.Title className="DialogTitle">Successful!</Dialog.Title>
                                    <DisplayCreatedScreen newOrg={newOrg} emailOne={state.emailOne} orgName={existingOrg?.Name ? existingOrg?.Name : state.orgName} userName={state.userName} orgCreated={state.coachAdded} type='Coach' reset={reset} />
                                </>
                                : state.podRegistered ?
                                    <>
                                        <Dialog.Title className="DialogTitle">Successful!</Dialog.Title>
                                        <DisplayCreatedScreen newOrg={newOrg} close={close} podRegistered={state.podRegistered} type='POD' reset={reset} />
                                    </>
                                    
                                    : state.tagRegistered ?
                                        <>
                                            <Dialog.Title className="DialogTitle">Successful!</Dialog.Title>
                                            <DisplayCreatedScreen newOrg={newOrg} close={close} tagRegistered={state.tagRegistered} type='tag' reset={reset} />
                                        </> :
                                        <>
                                            {TITLE}


                                        <div className="left">
                                            {!process === 'podRegister' && (
                                                <div className="organisation-placeholder-parent">
                                                    {!file && <div onClick={triggerFileInput} className="organisation-placeholder"></div>}
                                                    <img
                                                    alt='iconstyle'
                                                        id="tempOrgIcon"
                                                        style={iconStyle}

                                                    />
                                                </div>
                                            )}
                                            {(!process === 'podRegister' || !process === 'tagRegister') && (
                                                <div className="file-select-parent">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleFileChange}
                                                        style={{ display: 'none' }}
                                                        ref={fileInputRef}
                                                    />
                                                </div>
                                            )}
                                        </div>


                                        {FORMS}


                                        {(process === 'addCoach' || process === 'addAdmin' || process === 'detail') && 
                                            <div className='modal-form-container'>    
                                                <fieldset className="Fieldset">
                                                    <TextInput setter={onChange} cls={'reports-search'} value={state.emailOne} nameVal={'emailOne'} label='Email' />
                                                </fieldset>
                                                <fieldset className="Fieldset">
                                                    <TextInput setter={onChange} cls={'reports-search'} value={state.emailTwo} nameVal={'emailTwo'} label='Confirm Email' />
                                                </fieldset>
                                            </div>
                                        }
                                        {(errors && errors.error) &&
                                            <div className="error-warning">
                                                *  {errors.error}
                                            </div>
                                        }
                                        <div style={{ display: 'flex', marginTop: 25, justifyContent: 'flex-end' }}>
                                            <Dialog.Close asChild>
                                                {BUTTONS}

                                            </Dialog.Close>
                                        </div>
                                    </>

                    }

                    <Dialog.Close asChild onClick={close}>
                        <button className="IconButton" aria-label="Close">
                            <Cross2Icon />
                        </button>
                    </Dialog.Close>



                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )

}




const mapStateToProps = (state) => {
    return {
        userList: selectUserList(state),
        user: selectUser(state),
        organisation: selectOrganisation(state),

    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getOrganisationsToolkit, resetOrganisationDetailView, getUsers, addAdminToOrg, addCoachToOrg, createNewOrg, addPod, addTagToOrg }, dispatch),
});



export default connect(mapStateToProps, mapDispatchToProps)(DialogDemo);
