/* eslint-disable */
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import Cookies from "js-cookie";
import { API_HOST } from "../../utils/constant";
import { filterObjectsAlpabeticallyByKey, getSessionFromList } from "../../utils/helper";

export const downloadData = (sessionList, id, permissionLevel) => {
  downloadCSVReport(sessionList, id);

  if (permissionLevel === 1 || permissionLevel === 2) {
    //if admin download
    downloadNormalisedFile(id, sessionList);
  }
};

export const downloadSessions = (
  searchList,
  e,
  selectedActivity,
  selectedAthlete,
  startFilterDate,
  endFilterDate
) => {
  let url = `${API_HOST}/sessionData/getSockSessionData`;
  var zip = new JSZip();

  e.target.disabled = true;

  const headers = {
    Pragma: "no-cache", //needed for IE 11
    Authorization: `Bearer ${Cookies.get("id_token")}`,
  };

  if (searchList.length > 200) {
    alert("You cannot download more than 200 at a time");
  } else {
    let promiseArray = [];
    let delayIncrement = 500;
    let delay = 0;

    for (let i = 0; i < searchList.length; i++) {
      let session = searchList[i];

      let tempPromise = new Promise((resolve) =>
        setTimeout(resolve, delay)
      ).then(() => {
        //now - after the delay - we call the API!
        console.log("about to call API for sessionID  " + session.ID);
        console.log("time : " + new Date().toISOString());

        return axios({
          url,
          method: "POST",
          data: { sessionID: session.ID, datatype: "combined" },
          responseType: "blob",
          headers,
        }).catch(function () {
          console.log("error for sessionID  " + session.ID);
        });
      });

      promiseArray.push(tempPromise);

      // After every 20 downloads increase delay by 250 milliseconds to reduce server load
      if (i > 0 && i % 20 === 0) {
        delayIncrement = delayIncrement + 250;
      }

      delay += delayIncrement;
    }

    let fileName =
      getBatchFileName(
        selectedActivity,
        selectedAthlete,
        startFilterDate,
        endFilterDate,
        "rawData"
      ) + ".zip";
    Promise.all(promiseArray).then(function (results) {
      console.log("all functions called - now do zip!!!!");

      results.forEach((result) => {
        if (result && result.config && result.config.data && result.data) {
          zip.file(
            `Session${
              JSON.parse(result.config.data).sessionID
            }_rawData_combined.csv`,
            new Blob([result.data])
          );
        }
      });
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, fileName);
      });
      e.target.disabled = false;
    });
  } //end else
};

export const getPageName = () => {
  if (window.location.href.indexOf("session_input") > -1) {
    return "/session_input/";
  } else if (window.location.href.indexOf("athlete_profile") > -1) {
    return "/athlete_profile/";
  }
  return "";
};

export const getBatchFileName = (
  selectedActivity,
  selectedAthlete,
  startFilterDate,
  endFilterDate,
  fileName
) => {
  if (selectedActivity !== null) {
    fileName = fileName + "_" + selectedActivity.label.trim();
  }
  if (selectedAthlete !== null) {
    fileName = fileName + "_" + selectedAthlete.label.trim();
  }

  if (startFilterDate !== null && startFilterDate !== "") {
    fileName = fileName + "_from_" + startFilterDate.toLowerCase().trim();
  }

  if (endFilterDate !== null && endFilterDate !== "") {
    fileName = fileName + "_to_" + endFilterDate.toLowerCase().trim();
  }

  return fileName;
};

export const downloadCSVReport = (sessionList, id) => {
  let url = `${API_HOST}/sessionData/getReportsInCSVFormat`;

  const headers = {
    Pragma: "no-cache", //needed for IE 11
    Authorization: `Bearer ${Cookies.get("id_token")}`,
  };

  // Download left foot data
  let postBody = {
    sessionID: id,
    datatype: "",
  };

  let thisSession = getSessionFromList(parseInt(id), sessionList);

  let fileName = "";
  if (thisSession.ActivityName) {
    fileName = `${getNamewithNoSpaces(
      thisSession.playerName
    )}_${getNamewithNoSpaces(thisSession.ActivityName)}_Report_${id}.csv`;
  } else {
    fileName = `${getNamewithNoSpaces(
      thisSession.playerName
    )}_Report_${id}.csv`;
  }

  axios({
    url,
    method: "POST",
    data: postBody,
    responseType: "blob", // important,
    headers,
  }).then((response) => {
    let data = response.data;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // for IE
      var csvData = new Blob([response.data], { type: "text/csv" });
      window.navigator.msSaveOrOpenBlob(csvData, fileName);
    } else {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    }
  });
}; //end downloadFiles

export const getNamewithNoSpaces = (input) => {
  if (input) {
    return input.replace(/\s+/g, "");
  }
};

export const downloadNormalisedFile = (id, sessionList) => {
  let url = `${API_HOST}/sessionData/getSockNormalisedData`;

  const headers = {
    Pragma: "no-cache", //needed for IE 11
    Authorization: `Bearer ${Cookies.get("id_token")}`,
  };

  // Download left foot data
  let postBody = {
    sessionID: id,
    datatype: "left",
  };

  //let fileName = `Session${id}_left_normalisedData.csv`
  let fileName = "";

  let thisSession = getSessionFromList(parseInt(id), sessionList);

  if (thisSession?.ActivityName) {
    fileName = `${getNamewithNoSpaces(
      thisSession.playerName
    )}_${getNamewithNoSpaces(
      thisSession.ActivityName
    )}_normalisedData_${id}.csv`;
  } else {
    fileName = `${getNamewithNoSpaces(
      thisSession?.playerName
    )}_normalisedData_${id}.csv`;
  }

  axios({
    url,
    method: "POST",
    data: postBody,
    responseType: "blob", // important,
    headers,
  }).then((response) => {
    let data = response.data;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // for IE
      var csvData = new Blob([response.data], { type: "text/csv" });
      window.navigator.msSaveOrOpenBlob(csvData, fileName);
    } else {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    }
  });
  downloadFile(id, sessionList, "combinedZero");
};

export const downloadFile = (id, sessionList, datatype) => {
  //let url  = `${API_HOST}/sessionData/getSockSessionData/${id}`
  let url = `${API_HOST}/sessionData/getSockSessionData`;
  let postBody = {
    sessionID: id,
    datatype: datatype,
  };

  let thisSession = getSessionFromList(id, sessionList);
  let fileName = "";
  if (thisSession?.ActivityName) {
    fileName = `${getNamewithNoSpaces(
      thisSession.playerName
    )}_${getNamewithNoSpaces(
      thisSession.ActivityName
    )}_rawData_${id}_${datatype}.csv`;
  } else {
    fileName = `${getNamewithNoSpaces(
      thisSession?.playerName
    )}_rawData_${id}_${datatype}.csv`;
  }

  const headers = {
    Pragma: "no-cache", //needed for IE 11
    Authorization: `Bearer ${Cookies.get("id_token")}`,
  };

  axios({
    url,
    method: "POST",
    data: postBody,
    responseType: "blob", // important,
    headers,
  }).then((response) => {
    let data = response.data;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // for IE
      var csvData = new Blob([response.data], { type: "text/csv" });
      window.navigator.msSaveOrOpenBlob(csvData, fileName);
    } else {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      if (datatype === "combined") {
        this.downloadFile(id, "combinedZero");
      }
    }
  });
};

export const downloadCSVReportsV2 = (
  searchList,
  e,
  selectedActivity,
  selectedAthlete,
  startFilterDate,
  endFilterDate
) => {
  let url = `${API_HOST}/sessionData/getReportsInCSVFormatV2/`;

  let fileName =
    getBatchFileName(
      selectedActivity,
      selectedAthlete,
      startFilterDate,
      endFilterDate,
      "DanuMetrics"
    ) + ".csv";
  e.target.disabled = true;

  // let postBody = {
  //   sessionIDList: searchList,
  //   datatype: ""
  // }

  for (let i = 0; i < searchList.length; i++) {
    let thisSearchSession = searchList[i];
    if (i === 0) {
      url = url + thisSearchSession.ID;
    } else {
      url = url + "," + thisSearchSession.ID;
    }
  }

  const headers = {
    Pragma: "no-cache", //needed for IE 11
    Authorization: `Bearer ${Cookies.get("id_token")}`,
  };

  if (searchList.length > 200) {
    alert("You cannot download more than 200 at a time");
    e.target.disabled = false;
  } else {
    axios({
      url,
      method: "GET",
      //data: postBody,
      responseType: "blob", // important,
      headers,
    }).then((response) => {
      let data = response.data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // for IE
        var csvData = new Blob([response.data], { type: "text/csv" });
        window.navigator.msSaveOrOpenBlob(csvData, fileName);
      } else {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      }
      e.target.disabled = false;
    });
  }
};

export const sleep = (milliSeconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, milliSeconds);
  });
};

// add activity typeID in here when adding new categories
export const mapOptionsToCategory = (option) => {
  let optionID = option.activityTypeID;
  switch (optionID) {
    //gait
    case 1:
      return 0;
    //jumps => double if bilateral single if not
    case 2:
      if (option.bilateral) {
        return 1;
      }
      return 2;
    // velocity
    case 3:
      return 3;
    //balance
    case 4:
      return 5;
    //misc
    default:
      return 4;
  }
};
//-----------------------------------------------------------
export const options = (
  activityList,
  setGroupOptions,
  groupOptions,
  setGroups
) => {

console.log( activityList,

  groupOptions)

  let tempActList = [];
  //add new categorries in here, make sure they follow mapOptionsToCategory order
  let arr = [
    { label: "Gait", options: [] },
    { label: "Double Leg Jumps", options: [] },
    { label: "Single Leg Jumps", options: [] },
    { label: "Velocity", options: [] },
    { label: "Balance", options: [] },
    { label: "Misc", options: [] },
  ];

  function checkIfGroupIsSet(group) {
    if (
      !JSON.stringify(groupOptions).includes(
        JSON.stringify(group.options).slice(
          1,
          JSON.stringify(group.options).length - 1
        )
      )
    ) {
      //setting options under group selected
      setGroupOptions((value) =>
        value.concat(group.options.filter((grpOpt) => !value.includes(grpOpt)))
      );
      //setting group labels
      setGroups((prev) => {
        if (!prev.includes(group.label)) {
          return [...prev, group.label];
        } else {
          return prev;
        }
      });
    } else {
      let stringToRemove = JSON.stringify(group.options).slice(
        1,
        JSON.stringify(group.options).length - 1
      );
      //sets selected groups replace string objects with empty string object and filters out empty objects
      setGroupOptions(
        JSON.parse(
          JSON.stringify(groupOptions).replace(stringToRemove, "{}")
        ).filter((element) => {
          //checks of object is empty and removes it
          if (Object.keys(element).length !== 0) {
            return true;
          }
          return false;
        })
      );
      //remove item from group array
      setGroups((prev) => prev.filter((item) => item !== group.label));

      if (groupOptions === "") {
        setGroups([]);
        setGroupOptions([]);
      }
    }
  }

  tempActList = activityList;
  console.log(tempActList)
  //TODO: If filtering, only display activites that are in searchlist (activityList-activites && sessionlist-activities)
  tempActList.forEach((activity) => {
    arr[mapOptionsToCategory(activity)].options.push({
      value: activity.id,
      label: activity.name,
    });
  });
  let tempGroupList = [];
  tempGroupList = arr.map((group) => ({
    label: (() => {
      return (
        <div onClick={() => checkIfGroupIsSet(group)} className="testDropdown">
          <div
            className={`group-select-tab ${
              JSON.stringify(groupOptions).includes(
                JSON.stringify(group.options).slice(
                  1,
                  JSON.stringify(group.options).length - 1
                )
              )
                ? "group-selected-tab_selected"
                : ""
            }`}
          >
            <p>{group.label}</p>
            <p>
              {JSON.stringify(groupOptions).includes(
                JSON.stringify(group.options).slice(
                  1,
                  JSON.stringify(group.options).length - 1
                )
              )
                ? "-"
                : "+"}
            </p>
          </div>
        </div>
      );
    })(),
    options: group.options,
  }));
  return tempGroupList;
  // returns
  // return arr
};
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const getPlayerOptions = (
  orgList,
  setOrgGroupOptions,
  orgGroupOptions,
  setOrgGroups
) => {



  function checkIfGroupIsSet(group) {
    if (
      !JSON.stringify(orgGroupOptions).includes(
        JSON.stringify(group.options).slice(
          1,
          JSON.stringify(group.options).length - 1
        )
      )
    ) {
      //setting options under group selected
      setOrgGroupOptions((value) =>
        value.concat(group.options.filter((grpOpt) => !value.includes(grpOpt)))
      );
      //setting group labels
      setOrgGroups((prev) => {
        if (!prev.includes(group.label)) {
          return [...prev, group.label];
        } else {
          return prev;
        }
      });
    } else {
      let stringToRemove = JSON.stringify(group.options).slice(
        1,
        JSON.stringify(group.options).length - 1
      );
      //sets selected groups replace string objects with empty string object and filters out empty objects
      setOrgGroupOptions(
        JSON.parse(
          JSON.stringify(orgGroupOptions).replace(stringToRemove, "{}")
        ).filter((element) => {
          //checks of object is empty and removes it
          if (Object.keys(element).length !== 0) {
            return true;
          }
          return false;
        })
      );
      //remove item from group array
      setOrgGroups((prev) => prev.filter((item) => item !== group.label));

      if (orgGroupOptions === "") {
        setOrgGroups([]);
        setOrgGroupOptions([]);
      }
    }
  }
  if (orgList.length >= 1) {
    let data = [];
    for (var i = 0; i < orgList.length; i++) {
      var temp = { label: orgList[i].Name, options: [] };
      for (var j = 0; j < orgList[i].players.length; j++) {
        temp.options.push({
          value: orgList[i].players[j].playerID,
          label: orgList[i].players[j].player,
        });
      }
      data.push(temp);
    }

    // return data
    let tempOrgGroupList = filterObjectsAlpabeticallyByKey(data, "label").map((group) => ({
      label: (() => {
        return (
          <div
            onClick={() => checkIfGroupIsSet(group)}
            className="testDropdown"
          >
            <div
              className={`group-select-tab ${
                JSON.stringify(orgGroupOptions).includes(
                  JSON.stringify(group.options).slice(
                    1,
                    JSON.stringify(group.options).length - 1
                  )
                )
                  ? "group-selected-tab_selected"
                  : ""
              }`}
            >
              <p>{group.label}</p>
              <p>
                {JSON.stringify(orgGroupOptions).includes(
                  JSON.stringify(group.options).slice(
                    1,
                    JSON.stringify(group.options).length - 1
                  )
                )
                  ? "-"
                  : "+"}
              </p>
            </div>
          </div>
        );
      })(),
      options: filterObjectsAlpabeticallyByKey(group.options, "label"),
    }));

    return tempOrgGroupList;
  } else {
    return [];
  }
};



export const getTagOptions = (tagList, setTag) => {
  let tags = tagList.tags;



  // return data
  let tempOrgGroupList = tags.map((tag) => ({
    label: (() => {
      return (
        <div
          onClick={() => {
            setTag(tag);
          }}
          className="testDropdown"
        >
          <div className={`group-select-tab `}>
            <p>{tag.tag}</p>
          </div>
        </div>
      );
    })(),
  }));

  return tempOrgGroupList;
};

export const getTagsOptions = (
  tagList,
  setTagGroupOptions,
  tagGroupOptions,
  setTagGroups
) => {
  function checkIfGroupIsSet(group) {
    if (
      !JSON.stringify(tagGroupOptions).includes(
        JSON.stringify(group.options).slice(
          1,
          JSON.stringify(group.options).length - 1
        )
      )
    ) {
      //setting options under group selected
      setTagGroupOptions((value) =>
        value.concat(group.options.filter((grpOpt) => !value.includes(grpOpt)))
      );
      //setting group labels
      setTagGroups((prev) => {
        if (!prev.includes(group.label)) {
          return [...prev, group.label];
        } else {
          return prev;
        }
      });
    } else {
      let stringToRemove = JSON.stringify(group.options).slice(
        1,
        JSON.stringify(group.options).length - 1
      );
      //sets selected groups replace string objects with empty string object and filters out empty objects
      setTagGroupOptions(
        JSON.parse(
          JSON.stringify(tagGroupOptions).replace(stringToRemove, "{}")
        ).filter((element) => {
          //checks of object is empty and removes it
          if (Object.keys(element).length !== 0) {
            return true;
          }
          return false;
        })
      );
      //remove item from group array
      setTagGroups((prev) => prev.filter((item) => item !== group.label));

      if (tagGroupOptions === "") {
        setTagGroups([]);
        setTagGroupOptions([]);
      }
    }
  }

  function sortObjects(arr) {
    let result = [];
    let groups = {};
    arr.forEach((obj) => {
      let name = obj["Name"];
      if (!groups.hasOwnProperty(name)) {
        groups[name] = { Name: name, tags: [] };
        result.push(groups[name]);
      }
      let tag = Object.fromEntries(
        Object.entries(obj).filter(([k, v]) => k !== "Name")
      );
      groups[name].tags.push(tag);
    });
    return result;
  }

  const tagDataFormatted = sortObjects(tagList);

  if (tagDataFormatted.length >= 1) {
    let data = [];
    for (var i = 0; i < tagDataFormatted.length; i++) {
      var temp = { label: tagDataFormatted[i].Name, options: [] };
      for (var j = 0; j < tagDataFormatted[i].tags.length; j++) {
        temp.options.push({
          value: tagDataFormatted[i].tags[j].id,
          label: tagDataFormatted[i].tags[j].tag,
        });
      }
      data.push(temp);
    }

    console.log(data)
    // return data
    let tempTagGroupList = filterObjectsAlpabeticallyByKey(data, "label").map((group) => ({
      label: (() => {
        return (
          <div
            onClick={() => checkIfGroupIsSet(group)}
            className="testDropdown"
          >
            <div
              className={`group-select-tab ${
                JSON.stringify(tagGroupOptions).includes(
                  JSON.stringify(group.options).slice(
                    1,
                    JSON.stringify(group.options).length - 1
                  )
                )
                  ? "group-selected-tab_selected"
                  : ""
              }`}
            >
              <p>{group.label}</p>
              <p>
                {JSON.stringify(tagGroupOptions).includes(
                  JSON.stringify(group.options).slice(
                    1,
                    JSON.stringify(group.options).length - 1
                  )
                )
                  ? "-"
                  : "+"}
              </p>
            </div>
          </div>
        );
      })(),
      options: group.options,
    }));

    return tempTagGroupList;
  } else {
    return [];
  }
};


