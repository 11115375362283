import { useMemo, useState } from 'react';

const useGetPlayer = (orgList, playerId) => {
  const [player, setPlayer] = useState(null);

  useMemo(() => {
    if (orgList && orgList.length > 0) {
      for (let i = 0; i < orgList.length; i++) {
        const thisOrg = orgList[i];

        for (let p = 0; p < thisOrg.players.length; p++) {
          const thisPlayer = thisOrg.players[p];

          if (thisPlayer.playerID === parseInt(playerId)) {
            setPlayer(thisPlayer);
            return;
          }
        }
      }
    }

    setPlayer(null);
  }, [orgList, playerId]);

  return player;
};

export default useGetPlayer;

