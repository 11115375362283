/* eslint-disable */
import { useState } from "react";
import {
  ComposedChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Legend,
  Cell,
  LabelList,
  Tooltip,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "utils/constant";

export const CustomTooltipForAsymmetry = ({ active, payload }) => {
  if (active && payload && payload?.length) {
    let className = "right";
    let display = "";

    let displayValue = Math.round(payload[0].value * 100) / 100;
    if (payload[0].value > 0) {
      display = "Right Foot";
      className = "right";
    } else {
      displayValue = (Math.round(payload[0].value * 100) / 100) * -1;
      display = "Left Foot";
      className = "left";
    }

    return (
      <div className="custom-tooltip">
        <div className="label">{`${payload[0].payload.athleteName}`}</div>

        <div className={className}>
          {`${display}`}: {` ${displayValue}`}
        </div>
        <div className={className}>
          {`SessionID:  ${payload[0].payload.sessionID}`}
        </div>

        {payload[0].payload.detail?.length > 0 && (
          <div className="details">
            Session Detail: {payload[0].payload.detail}
          </div>
        )}
      </div>
    );
  }

  return null;
};

//takes in a array and decides the x axis max value of the grahphs based on max values
function chartXdomainScale(arr, comparisonType) {
  let newArr = arr.map((item) => {
    if (comparisonType === "max") {
      return item.maxSymmetryValue < 0
        ? item.maxSymmetryValue * -1
        : item.maxSymmetryValue;
    } else {
      return item.averageSymmetryValue < 0
        ? item.averageSymmetryValue * -1
        : item.averageSymmetryValue;
    }
  });

  return Math.max(...newArr) < 50 ? [-50, 50] : [-100, 100];
}

function formatXAxis(value) {
  const valueRounded = Math.round(value * 100) / 100;
  return Math.abs(valueRounded);
}

export default function ComparisonAsymmetryBarChart(props) {
  const { metricName, data, comparisonType } = props;

  if (data?.length > 0)
    return (
      <div className="chart-table-container asym">
        <div className="chart-heading">{metricName}</div>
        <div className="chart-container">
          <ResponsiveContainer>
            <ComposedChart
              width={700}
              height={500}
              data={data}
              stackOffset="sign"
              layout="vertical"
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={NEW_AXIS_COLOR}
                opacity={0.5}
                horizontal={false}
              />

              <XAxis
                type="number"
                tickFormatter={formatXAxis}
                tick={{ fill: NEW_AXIS_TICK }}
                stroke={NEW_AXIS_COLOR}
                domain={chartXdomainScale(data, comparisonType)}
                label={{
                  value: "% Asymmetry",
                  offset: -3,
                  position: "insideBottom",
                  fill: NEW_AXIS_TICK,
                }}
              />

              <YAxis
                dataKey="sessionID"
                type="category"
                label={
                  data.length < 40
                    ? {
                        value: "Session ID",
                        angle: -90,
                        position: "insideLeft",
                        dy: 20,
                        fill: NEW_AXIS_TICK,
                        offset: 0,
                      }
                    : false
                }
                tick={data.length < 40 ? { fill: NEW_AXIS_TICK } : false}
                stroke={NEW_AXIS_COLOR}
                tickLine={true}
              />

              <Legend
                verticalAlign="top"
                align="right"
                layout="horizontal"
                wrapperStyle={{ top: -20 }}
              />

              <Tooltip
                content={<CustomTooltipForAsymmetry data={data} />}
                cursor={false}
              />
              <Bar
                name={
                  comparisonType === "max" ? " Maximum Right" : "Average Right"
                }
                fill={NEW_RIGHT_COLOR}
                dataKey="null123"
              />

              <Bar
                dataKey={
                  comparisonType === "max"
                    ? "maxSymmetryValue"
                    : "averageSymmetryValue"
                }
                barSize={100}
                opacity={0.7}
                legendType="none"
              >
                {data.length < 40 ? (
                  <LabelList
                    dataKey="athleteName"
                    fontSize={"10px"}
                    fill={"white"}
                    position={"right"}
                    clockWise={1.4}
                    width={100}
                  />
                ) : (
                  <></>
                )}
                {data.map((entry, index) =>
                  comparisonType === "max" ? (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry["maxSymmetryValue"] < 0
                          ? NEW_LEFT_COLOR
                          : NEW_RIGHT_COLOR
                      }
                      name={entry["maxSymmetryValue"] < 0 ? "Left" : "Right"}
                    />
                  ) : (
                    comparisonType === "average" && (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry["averageSymmetryValue"] < 0
                            ? NEW_LEFT_COLOR
                            : NEW_RIGHT_COLOR
                        }
                        name={
                          entry["averageSymmetryValue"] < 0 ? "Left" : "Right"
                        }
                      />
                    )
                  )
                )}
              </Bar>

              <Bar
                name={
                  comparisonType === "max" ? " Maximum Left" : "Average Left"
                }
                fill={NEW_LEFT_COLOR}
                dataKey="null123"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
}
