import { API_HOST } from '../utils/constant';
import { api } from './api';

export const postFile = (url, file) => {
  let actualURL = `${API_HOST}${url}`
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  const formData = new FormData();
  formData.append('file', file)

  return api.post(actualURL, formData, config).then(res => {
    return res
  })
}
