import Polygon from "components/common/icons/downArrow";
import "./tableHeadItem.scss";

const TableHeadItem = ({
  index,
  item,
  filterDataHandle,
  comparisonType = false,
  filteringByWhichIndex,
  isDescending,
}) => {

  return (
    <th
      scope="col"
      className={`head-table ${index === 0 ? "head-table-name" : ""}`}
    >
      <div
        className={`head-item ${
          comparisonType === "Athlete" &&
          index === filteringByWhichIndex &&
          "head-item-selected"
        }`}
        onClick={() => {
          comparisonType === "Athlete" && filterDataHandle(index);
        }}
      >
        {item}
        <div>
          {comparisonType === "Athlete" && index === filteringByWhichIndex && (
            <Polygon direction={isDescending ? "up" : "down"} size={11} />
          )}
        </div>
      </div>
    </th>
  );
};

export default TableHeadItem;
