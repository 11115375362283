import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userState } from 'store/authSlice';
import { selectOrganisation } from 'store/orgSlice';
import { getOrganisationsToolkit } from 'store/orgSlice';

class AdminHome extends React.Component {

  constructor(props) {
    super(props)

    this.state = {}

  }

  UNSAFE_componentWillMount() {
    const { organisationActions, userDetails, orgList } = this.props;


    if (!orgList || !orgList.orgList) {
      organisationActions.getOrganisationsToolkit(userDetails.user.id)
    }
  }

  componentDidMount() { }

  goToOrgRegisterPage() {
    this.props.history.push("admin/organisation_setup");
  }

  goToPodRegisterPage() {
    this.props.history.push("pod_list");
  }

  render() {
    const { orgList } = this.props;

    let organisationsRegistered = 0
    let coachesRegistered = 0
    let athletesRegistered = 0

    orgList && orgList.orgList && orgList.orgList.forEach(organisation => {
      organisation.coaches && organisation.coaches.forEach( () => {
        coachesRegistered++;
      });
      organisation.players && organisation.players.forEach(() => {
        athletesRegistered++;
      });
      organisationsRegistered++;
    });



    return (
    <div className="right-background lady-left-background">
      <div className="admin-home">

        <div className="top-container">

          <div className="activity-card-container">

            <div className="green-card left-green" onClick={() => { this.goToOrgRegisterPage(); }}>
              Organisation <br />
              Register
            </div>

          </div>

          <div className="danu-cards">

            <div className="danu-card">
              <div className="danu-card-header">
                Organisations  Registered
              </div>

              <div className="danu-card-body">
                {organisationsRegistered}
              </div>
            </div>

            <div className="danu-card">
              <div className="danu-card-header">
                Registered Coaches
              </div>

              <div className="danu-card-body">
                {coachesRegistered}
              </div>
            </div>

            <div className="danu-card">
              <div className="danu-card-header">
                Registered Athletes
              </div>

              <div className="danu-card-body">
                {athletesRegistered}
              </div>
            </div>

            <div className="green-card right-green" style={{marginTop:75}} onClick={() => { this.goToPodRegisterPage(); }}>
                  Sock/POD <br />
                  Register
            </div>

          </div>

        </div>

        {/* <div className="bottom-buttons">

          <div className="spaceDive" />

          <div className="button" onClick={() => { this.goToOrgRegisterPage(); }}>
            Organisation <br />
            Register
          </div>

          <div className="middleSpaceDive" />

          <div className="button" onClick={() => { this.goToPodRegisterPage(); }}>
            Sock/POD <br />
            Register
          </div>

        </div> */}


      </div>
    </div>
    );
  }
}




const mapStateToProps = (state) => ({
  userDetails: userState(state),
  userOrganisations: selectOrganisation(state),
});

const mapDispatchToProps = (dispatch) => ({
  organisationActions: bindActionCreators({ getOrganisationsToolkit }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);

