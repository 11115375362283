import logoutIcon from '../../../assets/images/nav/logout.png'

const Dropdown = ({setLogoutDialogOpen}) => {

    window.onclick = function (e) {
        if (!e.target.matches('.dropbtn') && !e.target.matches('.nav-hamburger')) {
            const myDropdown = document.querySelector("#nav-dropdown");
            const mobileNav = document.querySelector("#navItems");
            const mobileNavBar = document.querySelector("#nav-hamBar");

            if (myDropdown && myDropdown.classList.contains('dropdown-visible')) {
                myDropdown.classList.remove('dropdown-visible');
            }

            if (mobileNav && mobileNav.classList.contains('active')) {
                mobileNav.classList.remove('active');
            }

            if (mobileNavBar && mobileNavBar.classList.contains('test')) {
                mobileNavBar.classList.remove('test');
            }
        }
    };

    
  return (
    <div className="nav-profile-dropdown" id='nav-dropdown'>
    <ul>
        {/* <li>
            {userDetail == 1 &&
                <Link to={AdminHome_URL} onClick={(e) => showHideNav(e)}>
                    <div className={pathName == "/admin_home" || pathName.indexOf("organisation_setup") >= 0
                        || pathName.indexOf("pod_list") >= 0 || pathName.indexOf("pod_registration") >= 0 ? "nav-item active" : "nav-item"}>
                        <img alt="Administration icon" src={pathName == "/admin_home" || pathName.indexOf("organisation_setup") >= 0
                            || pathName.indexOf("pod_list") >= 0 || pathName.indexOf("pod_registration") >= 0 ? adminIconSelected : adminIcon} />
                        <div className="nav-link-title-container">
                            <div className="nav-link-txt-container">Admin</div>
                        </div>
                    </div>
                </Link>
            }
        </li> */}
        <li>
            <div className='nav-item' onClick={() => { setLogoutDialogOpen(prevLougoutDialogOpen => !prevLougoutDialogOpen);document.body.classList.add('modal-open') }}>
                <img alt="Logout icon" src={logoutIcon} />
                <div className="nav-link-title-container">
                    <div className="nav-link-txt-container">
                        Logout
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>
  )
}

export default Dropdown