/* eslint-disable */
import { useState } from "react";
import { useSelector } from "react-redux";
import "react-vis/dist/style.css";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import "video-react/dist/video-react.css";
import { selectSessionList, selectSessionList2 } from "store/sessionListSlice";
import DownArrow from "../../components/common/icons/downArrow";
import {
  displayLoadingCharts,
  displayNoData,
} from "components/profileFunctions/profileFunctions";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

const Video = () => {
  const { jumpData, runData, rawData } = useSelector((state) => ({
    jumpData: state.session.jumpData,
    runData: state.session.runData,
    rawData: state.session.rawData
  }));

  console.log(rawData)

  const [hidden, setHidden] = useState(false);

  const toggleChart = () => {
    setHidden(!hidden);
  };

  const displayVideo = () => {
    let videoURL = "";
    if (jumpData && jumpData.data && jumpData.data?.multimediaData?.videoURL) {
      videoURL = jumpData.data?.multimediaData?.videoURL;
    } else if (
      runData &&
      runData.data &&
      runData.data?.multimediaData?.videoURL
    ) {
      videoURL = runData.data?.multimediaData?.videoURL;
    }
    if (
      jumpData &&
      jumpData?.data &&
      jumpData.data?.heatMapVideoURL &&
      !(jumpData && jumpData.data && jumpData.data?.multimediaData?.videoURL)
    ) {
      videoURL = jumpData.data.heatMapVideoURL;
    } else if (
      runData &&
      runData?.data &&
      runData.data?.heatMapVideoURL &&
      !(runData && runData.data && runData.data?.multimediaData?.videoURL)
    ) {
      videoURL = runData.data.heatMapVideoURL;
    }

    if( 
      rawData &&
      rawData?.data &&
      rawData?.data?.multimediaData &&
      rawData?.data?.multimediaData?.videoURL
    ){
      videoURL =  rawData?.data?.multimediaData?.videoURL
    }

  
    if (runData.status === "loading" || jumpData.status === "loading" || rawData.status === "loading")
      return displayLoadingCharts();

    if (videoURL === "" || videoURL === null) {
      return displayNoData();
    }
    if (!hidden) {
      return (
        <div className="metric-container metric-container-video">
          <div className="metric-chart-container-parent-video ">
            <div className="chart-container-padding">
              <div
                className={`chart-container chart-container-video ${
                  !jumpData.data?.multimediaData?.videoURL &&
                  runData.data?.multimediaData?.videoURL &&
                  !runData &&
                  !runData?.data &&
                  !runData.data?.heatMapVideoURL &&
                  !jumpData &&
                  !jumpData?.data &&
                  !jumpData.data?.heatMapVideoURL
                    ? "no-data-container"
                    : ""
                }`}
              >
                {!jumpData.data?.multimediaData?.videoURL &&
                !runData.data?.multimediaData?.videoURL &&
                !runData &&
                !runData?.data &&
                !runData.data?.heatMapVideoURL &&
                !jumpData &&
                !jumpData?.data &&
                !jumpData.data?.heatMapVideoURL ? (
                  displayNoData()
                ) : (
                  <Player
                    fluid={false}
                    autoPlay={true}
                    playsInline={true}
                    loop
                    muted
                  >
                    <source src={videoURL} type="video/mp4" />
                    <ControlBar disableCompletely={true}>
                      <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                    </ControlBar>
                  </Player>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  let arrowClass = "";
  if (!hidden) {
    arrowClass = "flip-div";
  }

  return (
    <div className="metric-container-parent">
      <div className="metric-container metric-title-div">
        <div className="metric-title-left-padding" />
        <div className="metric-title-left-icon"></div>
        <div className="metric-title-left-padding" />
        <div className="metric-title-main-title">
          Pressure Mapping
          <div style={{ paddingLeft: "0.5rem" }}>
            {" "}
            <MainTooltip
              tooltipText={
                "Video visualisation of the foot pressure mapping, with the orange line visualising the centre of pressure"
              }
              tooltipIcon={"info"}
              iconSize={25}
            />
          </div>
        </div>

        <div className="metric-title-left-padding" />
        <div className="metric-title-left-icon"></div>
      </div>
      {displayVideo()}
    </div>
  );
};

export default Video;
