/* eslint-disable */
import TestSlide from "../testSlide/testSlide";
import "./custom-graph-layout-modal.scss";
import { useSelector, useDispatch } from "react-redux";
import { useCheckForJumpData } from "../hooks/useCheckForJumpData";
import { useCallback, useEffect, useRef, useState } from "react";
import { getSessionFromList } from "utils/helper";
import * as metricRules from "../../../utils/metrics";
import { getSessionList } from "store/sessionListSlice";
import { getJumpData, getRunData, resetAllData } from "store/sessionSlice";
import Force from "components/metrics/ForceData";
import { Responsive, WidthProvider } from "react-grid-layout";
import PictureMaps from "components/metrics/PictureMaps";
import DriveIndex from "components/metrics/DriveIndex";
import Spinner from "../spinner/spinner";
import FlightTime from "components/metrics/FlightTime";
import FlightTimeAsymmetry from "components/metrics/FlightTimeAsymmetry";
import GroundContactTime from "components/metrics/GroundContactTime";
import GroundContactTimeAsymmetry from "components/metrics/GroundContactTimeAsymmetry";
import CadenceReport from "components/metrics/CadenceReport";
import PeakTibialAcceleration from "components/metrics/PeakTibialAccelerationReport";
import SwingStanceFootStrike from "components/metrics/SwingStanceFootStrike";
import RSI from "components/metrics/RSI";
import JumpHeight from "components/metrics/JumpHeight";
import FlightTimeJump from "components/metrics/FlightTimeJump";
import ContactTimeJump from "components/metrics/ContactTimeJump";
import Power from "components/metrics/Power";
import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import { msToTime, displayDate } from "utils/helper";
import { nanoid } from "@reduxjs/toolkit";
import logo from "../../../assets/images/DanuTitle.png";
import { userState } from "store/authSlice";
import { updateUserLayoutAPI } from "api/user";
import GroundClearance from "components/metrics/GroundClearance";
import GroundClearanceAsymmetry from "components/metrics/GroundClearanceAsymmetry";
import StepLength from "components/metrics/StepLength";
import StepLengthAsymmetry from "components/metrics/StepLengthAsymmetry";
import StrideVelocity from "components/metrics/StrideVelocity";
import StrideVelocityAsymmetry from "components/metrics/StrideVelocityAsymmetry";
import GroundClearanceJump from "components/metrics/GroundClearanceJump";
import JumpLength from "components/metrics/JumpLength";
import JumpVelocity from "components/metrics/JumpVelocity";
import CustomLayoutTextArea from "../customReportTextArea/CustomLayoutTextArea";
import EditIcon from "../icons/editIcon";
import StrideTime from "components/metrics/StrideTime";
import StrideTimeAsymmetry from "components/metrics/StrideTimeAsymmetry";
import SwingTime from "components/metrics/SwingTime";
import SwingTimeAsymmetry from "components/metrics/SwingTimeAsymmetry";
import moment from "moment";
import StrideLengthAsymmetry from "components/metrics/StrideLengthAsymmetry";
import StrideLength from "components/metrics/StrideLength";
import MainChartComponent from "components/metrics/MainChartComponent";

const ResponsiveGridLayout = WidthProvider(Responsive);

//takes in a array and decides the x axis max value of the grahphs based on max values
function chartXdomainScale(arr) {
  let newArr = arr.map((item) =>
    item.Symmetry < 0 ? item.Symmetry * -1 : item.Symmetry
  );
  // return([-1*(Math.max(...newArr))-10, Math.max(...newArr)+10])

  return Math.max(...newArr) < 50 ? [-50, 50] : [-100, 100];
}

function chartYdomainScale(arr) {
  //creates array of from specific object keys
  let newArr = arr.map(
    (item) => Number(item["Left Foot"]) || Number(item["Right Foot"])
  );
  //filteres out NaN values#
  newArr = newArr.filter((item) => !isNaN(item));

  //sets the range from 0 - the max value of the array fixed to two devimal places and turned to number
  return [
    0,
    Number((Math.max(...newArr) + (Math.max(...newArr) / 100) * 10).toFixed(2)),
  ];
}

function combineArrays(mainArray, addedArray) {
  // Create a set of all 'i' values in the main array
  let iValues = new Set(mainArray.map((obj) => obj.i));

  // Filter out objects from the added array whose 'i' value is already in the main array
  let uniqueAddedObjects = addedArray.filter((obj) => !iValues.has(obj.i));

  // Return the combined array
  return [...mainArray, ...uniqueAddedObjects];
}

function CustomGraphLayoutModal(props) {
  //declaration of properties
  const { modalIn, setModal, sessionID, match } = props;

  //state declaration
  const [selectedIDState, setSelectedIDState] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isControlLoading, setIsControlLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [graphIDs, setGraphIDs] = useState([]);
  const [disabledGraphIDs, setDisabledGraphIDs] = useState([]);
  const [graphLayout, setGraphLayout] = useState([]);
  const [graphLayoutBeforeSave, setGraphLayoutBeforeSave] = useState([]);
  const [strideVelocityDisplayed, setStrideVelocityDisplayed] =
    useState("meanStrideVelocity");
  const [jumpVelocityDisplayed, setJumpVelocityDisplayed] =
    useState("meanJumpVelocity");
  const [cadenceFrequencyToggler, setCadenceFrequencyToggler] =
    useState("cadenceData");
  const [toggleNotes, setToggleNotes] = useState(false);
  const [toggledNotes, setToggledNotes] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph4: false,
    graph5: false,
    graph6: false,
    graph7: false,
    graph8: false,
    graph9: false,
    graph10: false,
    graph11: false,
    graph12: false,
    graph13: false,
    graph14: false,
    graph15: false,
    graph16: false,
    graph17: false,
    graph18: false,
    graph19: false,
    graph20: false,
  });

  const loggedUserData = useSelector(userState);

  //reference
  const ref = useRef();

  console.log(ref)
  console.log(ref.current)
  console.log("client Width :", ref.current?.clientWidth)
  console.log("client Height :", ref.current?.clientHeight)

  //remove index from array
  function removeIndex(array, index) {
    let newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  }

  //get items within specific div
  function getGraphsRenedered() {
    let elements = document
      ?.querySelector(".layout")
      ?.querySelectorAll(".grid-card");
    let elementsIDs = [];

    for (let i = 0; i < elements?.length; i++) {
      elementsIDs?.push(elements[i].id);
    }

    if (elementsIDs?.length > 0) {
      setGraphIDs(elementsIDs);
    }
  }

  function toggleViewOfGraph(togglerID, toggledID) {
    setIsLoading(true);
    let tempArray = [...disabledGraphIDs];

    if (
      !document
        .getElementById(togglerID)
        .classList.contains("disabled-graph-toggler")
    ) {
      document
        .getElementById(togglerID)
        .classList.add("disabled-graph-toggler");
    } else {
      document
        .getElementById(togglerID)
        .classList.remove("disabled-graph-toggler");
    }

    if (tempArray.includes(toggledID)) {
      tempArray = removeIndex(tempArray, tempArray.indexOf(toggledID));
      setDisabledGraphIDs([...tempArray]);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      tempArray.push(toggledID);
      setDisabledGraphIDs([...tempArray]);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  const { sessionList, selectedSessionId, session, playerActivity } =
    useSelector((state) => ({
      graphModal: state.playerActivity.graphModal,
      selectedSessionId: state.playerActivity.selectedSessionId,
      sessionList: state.sessionList,
      session: state.session,
      playerActivity: state.playerActivity,
    }));

  const checkForJumpData = useCheckForJumpData();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      sessionList.sessionList.length === 0 &&
      sessionList.status === "idle" &&
      sessionList.status !== "loading"
    ) {
      dispatch(getSessionList());
    }
    if (
      selectedSessionId !==
      (session.runData.sessionID || session.jumpData.sessionID)
    ) {
      checkForJumpData();
    }
  }, [modalIn, sessionList?.sessionList?.data, selectedSessionId]);

  useEffect(() => {
    if (
      modalIn &&
      playerActivity.selectedSessionId &&
      playerActivity.selectedSessionId !==
        (session.runData.sessionID || session.jumpData.sessionID)
    ) {
      checkForJumpData();
    }
  }, [modalIn, sessionList?.sessionList?.data, selectedSessionId]);

  let thisSession;

  if (sessionList.sessionList.data) {
    thisSession = getSessionFromList(
      selectedSessionId,
      sessionList.sessionList?.data
    );
  }

  console.log(thisSession);

  //on click button test
  // const onButtonClick = useCallback(
  //   (sessionData) => {
  //     setIsDownloading(true);
  //     if (ref.current === null) {
  //       setIsDownloading(false);
  //       return;
  //     }

  //     toPng(ref.current, { cacheBust: true })
  //       .then((dataUrl) => {
  //         const link = document.createElement("a");
  //         const pdf = new jsPDF(
  //           "p",
  //           "px",
  //           [ref.current.clientWidth, ref.current.clientHeight],
  //           true
  //         );

  //         // link.download = "my-image-name.png";
  //         // link.href = dataUrl;
  //         // link.click();

  //         pdf.addImage(
  //           dataUrl,
  //           "PNG",
  //           0,
  //           0,
  //           ref.current.clientWidth,
  //           ref.current.clientHeight
  //         );

  //         pdf.save(
  //           `${sessionData.playerName || sessionData.player}_${
  //             sessionData.ActivityName
  //           }_${moment(sessionData.tsCreated).format("DD-MM-YYYY")}.pdf`
  //         );
  //         setIsDownloading(false);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   },
  //   [ref]
  // );
  const onButtonClick = useCallback(
    (sessionData) => {
      setIsDownloading(true);
      if (ref.current === null) {
        setIsDownloading(false);
        return;
      }
  
      toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          const pdf = new jsPDF(
            "p",
            "px",
            [ref.current.clientWidth, ref.current.clientHeight],
            true
          );
  
          const imgProps = pdf.getImageProperties(dataUrl);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
          pdf.addImage(
            dataUrl,
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
  
          pdf.save(
            `${sessionData.playerName || sessionData.player}_${
              sessionData.ActivityName
            }_${moment(sessionData.tsCreated).format("DD-MM-YYYY")}.pdf`
          );
          setIsDownloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDownloading(false); // Ensure isDownloading is reset in case of error
        });
    },
    [ref]
  );

  useEffect(() => {
    if (modalIn) {
      if (thisSession?.ActivityType === "Run") {
        dispatch(getRunData(thisSession.ID));
      }

      if (thisSession?.ActivityType === "Jump") {
        dispatch(getJumpData(thisSession.ID));
      }

      if (selectedIDState !== selectedSessionId) {
        setSelectedIDState(selectedSessionId);
      }
      setTimeout(() => {
        setIsLoading(false);
        {
          modalIn &&
            setTimeout(() => {
              getGraphsRenedered(), setIsControlLoading(false);
            }, 500);
        }
      }, 1000);
    }

    if (
      (session.runData.loaded === true || session.jumpData.loaded === true) &&
      selectedIDState === selectedSessionId
    ) {
      dispatch(resetAllData());
      setIsLoading(true);
    }
  }, [selectedSessionId]);

  useEffect(() => {
    let savedLayoutUserData =
      loggedUserData?.user?.layout && JSON.parse(loggedUserData?.user?.layout);
    if (localStorage.getItem("userLayout")) {
      const localData = localStorage.getItem("userLayout");
      savedLayoutUserData = JSON.parse(localStorage.getItem("userLayout"));
    }
    if (
      savedLayoutUserData?.pdf?.[thisSession?.ActivityName?.toLowerCase()]
        ?.layout?.lg?.length > 0 &&
      modalIn === true
    ) {
      setGraphLayout({
        lg: [
          ...savedLayoutUserData?.pdf?.[
            thisSession?.ActivityName?.toLowerCase()
          ]?.layout?.lg,
        ],
      });
    }
    if (
      savedLayoutUserData?.pdf?.[thisSession?.ActivityName?.toLowerCase()]
        ?.disabledGraphIDs?.length > 0 &&
      modalIn === true
    ) {
      setTimeout(() => {
        setDisabledGraphIDs(
          savedLayoutUserData?.pdf?.[thisSession?.ActivityName?.toLowerCase()]
            ?.disabledGraphIDs
        );
      }, 2000);
    }
  }, [modalIn === true]);

  const layout2 = {
    lg: [
      {
        w: 6,
        h: 4,
        x: 0,
        y: 0,
        i: "1",
        moved: false,
        static: false,
        minH: 4,
      },
      //feet images
      {
        w: 6,
        h: 2,
        x: 0,
        y: 4,
        i: "2",
        moved: false,
        static: false,
        minH: 2,
      },
      //drive index
      {
        w: 6,
        h: 5,
        x: 0,
        y: 6,
        i: "3",
        moved: false,
        static: false,
        minH: 2,
      },
      //flight time
      {
        w: 6,
        h: 5,
        x: 0,
        y: 11,
        i: "4",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 16,
        i: "5",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 21,
        i: "6",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 26,
        i: "7",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 31,
        i: "8",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 36,
        i: "9",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 4,
        x: 0,
        y: 41,
        i: "10",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 45,
        i: "11",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 50,
        i: "12",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 55,
        i: "13",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 60,
        i: "14",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 65,
        i: "15",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 70,
        i: "16",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 75,
        i: "17",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 80,
        i: "18",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 85,
        i: "19",
        moved: false,
        static: false,
        minH: 5,
      },
      {
        w: 6,
        h: 5,
        x: 0,
        y: 90,
        i: "20",
        moved: false,
        static: false,
        minH: 5,
      },
    ],
  };

  //layout for (Sprint, Jog, Walk)
  const layout =
    !graphLayout.lg > 0
      ? {
          lg: layout2.lg,
        }
      : graphLayout;
  //declaration of variables
  let asymRangeArray = [];
  let xAxisDomainConcatArray = [];
  let asymJumpRangeArray = [];

  const strideSymmetryData = session?.runData?.data?.StrideSymmetryData;
  if (strideSymmetryData && strideSymmetryData.length !== 0) {
    const contactTime = strideSymmetryData.contactTime;
    const flightTime = strideSymmetryData.flightTime;

    if (
      contactTime &&
      contactTime.length !== 0 &&
      flightTime &&
      flightTime.length !== 0
    ) {
      // Spreads the two arrays into one
      xAxisDomainConcatArray = [...contactTime, ...flightTime];

      // Sets the value of range array to the result of the chart function by passing the concat array as a parameter
      asymRangeArray = chartXdomainScale(xAxisDomainConcatArray);
    }
  }

  const jumpData = session.jumpData?.data;
  if (jumpData) {
    const jumpSymmetryChartData = jumpData.jumpData?.symmetryChartData;
    const flightSymmetryChartData = jumpData.flightData?.symmetryChartData;
    const powerSymmetryChartData = jumpData.powerData?.symmetryChartData;
    const rsiSymmetryChartData = jumpData.rsiData?.symmetryChartData;
    const contactTimeSymmetryChartData =
      jumpData.contactTimeData?.symmetryChartData;

    if (
      jumpSymmetryChartData &&
      flightSymmetryChartData &&
      powerSymmetryChartData
    ) {
      xAxisDomainConcatArray = [
        ...jumpSymmetryChartData,
        ...flightSymmetryChartData,
        ...powerSymmetryChartData,
      ];
      asymJumpRangeArray = chartXdomainScale(xAxisDomainConcatArray);
    }
    if (
      rsiSymmetryChartData &&
      flightSymmetryChartData &&
      contactTimeSymmetryChartData
    ) {
      xAxisDomainConcatArray = [
        ...rsiSymmetryChartData,
        ...flightSymmetryChartData,
        ...contactTimeSymmetryChartData,
      ];
      asymJumpRangeArray = chartXdomainScale(xAxisDomainConcatArray);
    }
  }

  async function updateUserLayout() {
    await setGraphLayout({ lg: [...graphLayoutBeforeSave] });

    const loggedInUserLayout = await JSON.parse(loggedUserData?.user.layout);
    const localStorageData = JSON.parse(localStorage.getItem("userLayout"));
    const activityType = await thisSession?.ActivityName.toLowerCase();

    const postBody = await {
      layout: JSON.stringify({
        pdf: {
          ...loggedInUserLayout.pdf,
          [activityType]: {
            layout: { lg: [...graphLayoutBeforeSave] },
            disabledGraphIDs: disabledGraphIDs,
          },
        },
        comparison: { ...loggedInUserLayout.comparison },
        sessionLayout: { ...loggedInUserLayout.sessionLayout },
        athleteOverview: { ...localStorageData.athleteOverview },
      }),
    };
    // const postBody = await {
    //   layout: JSON.stringify({})
    // }
    const fullLayoutWithDisabledElements = await combineArrays(
      graphLayoutBeforeSave,
      layout2.lg
    );

    await localStorage.setItem("userLayout", postBody.layout);
    await updateUserLayoutAPI(postBody);
  }

  function addTextToggler(graphSelected) {
    // setToggledNotes({[graphSelected]: !toggleNotes[graphSelected], ...toggledNotes})
    setToggledNotes({
      ...toggledNotes,
      [graphSelected]: !toggledNotes[graphSelected],
    });
  }

  function togglerComponent(toggledGraph, toggleNotes, textToggler) {
    //toggleGraph e.g: toggledNotes.graph1
    //toggleNotes: the toggleNotes state value
    //textToggler:  e.g. "graph1"
    return (
      <>
        <CustomLayoutTextArea
          isOpen={toggledGraph}
          isBoarderVisible={toggleNotes}
        />
        {toggleNotes && (
          <button
            className="edit-button"
            onClick={() => addTextToggler(textToggler)}
          >
            <EditIcon size={20} />
          </button>
        )}
      </>
    );
  }

  console.log(session);

  return (
    <TestSlide modalIn={modalIn} className="activity-modal pdf-download-modal">
      <div className="pdf-modal-container">
        <div className="pdf-modal-header">
          <h1>Create Custom Report</h1>
          <button
            onClick={() => {
              setModal(!modalIn);
              setIsLoading(true);
              dispatch(resetAllData());
              setGraphIDs([]);
              setIsControlLoading(true);
              setDisabledGraphIDs([]);
              setGraphLayout([]);
              setGraphLayoutBeforeSave([]);
              setIsCustomizing(false);
              setToggleNotes(false);
              setToggledNotes({
                graph1: false,
                graph2: false,
                graph3: false,
                graph4: false,
                graph5: false,
                graph6: false,
                graph7: false,
                graph8: false,
                graph9: false,
                graph10: false,
                graph11: false,
                graph12: false,
                graph13: false,
                graph14: false,
                graph15: false,
                graph16: false,
                graph17: false,
              });
            }}
          >
            x
          </button>
        </div>
        <div className="pdf-modal-options">
          <div className="pdf-modal-options-buttons">
            <button
              className={`pdf-download-button`}
              onClick={() => onButtonClick(thisSession)}
              disabled={isDownloading}
            >
              {!isDownloading ? "Download Report" : "Downloading..."}
            </button>
            <button
              className={`pdf-download-button`}
              onClick={() => {
                if (isCustomizing) {
                  updateUserLayout();
                }
                setIsCustomizing(!isCustomizing);
              }}
            >
              {!isCustomizing ? "Format Layout" : "Save Layout"}
            </button>
            <button
              className={`pdf-download-button`}
              onClick={() => {
                setToggleNotes((prevValue) => !prevValue);
              }}
            >
              {!toggleNotes ? "Notes On" : "Notes Off"}
            </button>
          </div>
          <div
            className={`pdf-modal-options-control ${
              isControlLoading
                ? "options-control-closed"
                : "options-control-open"
            }`}
          >
            {isControlLoading ? (
              <Spinner />
            ) : (
              <div className="options-table">
                <div className="options-table-headings">
                  <h3>Toggle Graphs for Rendering</h3>
                </div>
                <div className="options-table-items">
                  {graphIDs.map((item) => (
                    <div
                      className={`options-table-item ${
                        disabledGraphIDs.includes(item) &&
                        "disabled-graph-toggler"
                      }`}
                      key={`option-${item}`}
                      id={`option-${item}`}
                      onClick={() => toggleViewOfGraph(`option-${item}`, item)}
                    >
                      {/* removing - and removing the last word from the id */}
                      {item
                        .split("-")
                        .join(" ")
                        .slice(0, item.length - 5)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {!isLoading && (
          <div>
            <div className="pdf-graph-container" ref={ref}>
              <div className="pdf-layout-header">
                <div className="header-session-names">
                  <div className="name-card primary">
                    <p>Athlete Name</p>
                    <h3>{thisSession?.name || thisSession?.playerName}</h3>
                  </div>
                  <div className="name-card primary">
                    <p>Activity Type</p>
                    <h3>{thisSession?.ActivityName}</h3>
                  </div>
                </div>
                <div className="header-session-names">
                  <div className="name-card secondary">
                    <p>Session ID</p>
                    <h3>{thisSession?.ID}</h3>
                  </div>
                  <div className="name-card secondary">
                    <p>Session Details</p>
                    <h3>
                      {thisSession?.detail?.length > 0
                        ? thisSession?.detail
                        : "-"}
                    </h3>
                  </div>
                  {JSON.parse(thisSession?.tags).length > 0 && (
                    <div className="name-card secondary">
                      <p>Session Duration</p>
                      <h3>
                        {thisSession?.duration
                          ? msToTime(thisSession?.duration).split(".")[0]
                          : "-"}
                      </h3>
                    </div>
                  )}
                </div>
                <div className="header-session-names">
                  {!JSON.parse(thisSession?.tags).length > 0 && (
                    <div className="name-card secondary">
                      <p>Session Duration</p>
                      <h3>
                        {thisSession?.duration
                          ? msToTime(thisSession?.duration).split(".")[0]
                          : "-"}
                      </h3>
                    </div>
                  )}
                  <div className="name-card secondary">
                    <p>Date</p>
                    <h3>{displayDate(thisSession.tsCreated)}</h3>
                  </div>
                  {JSON.parse(thisSession?.tags).length > 0 && (
                    <div className="name-card secondary">
                      <p>Tags</p>
                      <div className="tags">
                        {JSON.parse(thisSession?.tags).length > 0 &&
                          JSON.parse(thisSession?.tags)
                            .slice(0, 4)
                            .map((tag) => (
                              <div className="tag" key={nanoid()}>
                                {tag.tag}
                              </div>
                            ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="header-session-names">
                  <div className="name-card-logo">
                    <img src={logo} alt="Logo" />
                  </div>
                </div>
              </div>
              <ResponsiveGridLayout
                className="layout"
                layouts={graphLayout.length > 0 ? graphLayout : layout}
                // layouts={layout}
                cols={{ lg: 6 }}
                breakpoints={{ lg: 0 }}
                onLayoutChange={(e) => {
                  setGraphLayoutBeforeSave(combineArrays(e, layout2.lg));
                  // some work to be added in here
                }}
                rowHeight={110}
                isDraggable={isCustomizing}
                isResizable={isCustomizing}
              >
                {/* graph exists for both jump and runs */}
                {!disabledGraphIDs?.includes("Force-Graph") && (
                  <div id="Force-Graph" key={"1"} className="grid-card">
                    <Force
                      sessionid={thisSession?.ID}
                      displayTotal={
                        thisSession?.ActivityType === "Jump" ? true : false
                      }
                      toggleableTextArea={togglerComponent(
                        toggledNotes.graph1,
                        toggleNotes,
                        "graph1"
                      )}
                    />
                  </div>
                )}
                {/* graph exists for both jump and runs
                <div key={"2"} className="grid-card pressure-mapping-pictures">
                  <PictureMaps />
                </div> */}

                {thisSession.ActivityType === "Balance" &&
                  !disabledGraphIDs.includes("Stability-Score") &&
                  session?.rawData?.data &&
                  thisSession?.ActivityType === "Balance" && (
                    <div id="Stability-Score" key={"2"} className="grid-card">
                      {session?.rawData?.data.rawData && (
                        <MainChartComponent
                          fixedHeight={"230px"}
                          chartTitle={"Stability Score"}
                          tooltipText={"Stability Score Tooltip"}
                          chartType={"bullet"}
                          chartOrientation={"vertical"}
                          chartHeight={"150px"}
                          chartDataKey={"stabilityScore"}
                          bilateralChart={true}
                          bilateralKeys={{
                            left: "leftResults",
                            right: "rightResults",
                            mean: "meanResults",
                          }}
                          hasToggler={true}
                          togglerValues={{ left: "Mean", right: "Individual" }}
                          settingsChartKey={"stability-score"}
                        />
                      )}
                    </div>
                  )}

                {thisSession.ActivityType === "Balance" &&
                  !disabledGraphIDs.includes("Single-Leg-Stance") &&
                  session?.rawData?.data &&
                  thisSession?.ActivityType === "Balance" && (
                    <div id="Single-Leg-Stance" key={"3"} className="grid-card-tiles">
                      {session?.rawData?.data.rawData && (
                        <MainChartComponent
                          fixedHeight={"250px"}
                          chartType={"tiles"}
                          tilesforRendering={[
                            {
                              metricName: "Area of Ellipse",
                              metricValuesKeys: {
                                mean: "meanResults",
                                left: "leftResults",
                                right: "rightResults",
                                dataKey: "areaOfEllipse",
                              },
                              metricUnit: "CM²",
                            },
                            {
                              metricName: "Length of Ellipse",
                              metricValuesKeys: {
                                mean: "meanResults",
                                left: "leftResults",
                                right: "rightResults",
                                dataKey: "lengthOfEllipse",
                              },
                              metricUnit: "CM",
                            },
                            {
                              metricName: "Width of Ellipse",
                              metricValuesKeys: {
                                mean: "meanResults",
                                left: "leftResults",
                                right: "rightResults",
                                dataKey: "widthOfEllipse",
                              },
                              metricUnit: "CM",
                            },
                            {
                              metricName: "Medio Lateral Range",
                              metricValuesKeys: {
                                mean: "meanResults",
                                left: "leftResults",
                                right: "rightResults",
                                dataKey: "medioLateralRange",
                              },
                              metricUnit: "CM",
                            },
                            {
                              metricName: "Total Displacement",
                              metricValuesKeys: {
                                mean: "meanResults",
                                left: "leftResults",
                                right: "rightResults",
                                dataKey: "totalDisplacement",
                              },
                              metricUnit: "CM",
                            },
                          ]}
                          hasSettings={true}
                          settingsSide={"right-side"}
                          settingsChartKey={"tiled-balance-metrics"}
                          togglerValues={{ left: "Mean", right: "Individual" }}
                        />
                      )}
                    </div>
                  )}
                {/* graph exists for runs */}
                {thisSession.ActivityName === "Sprint" &&
                  !disabledGraphIDs.includes("Drive-Index-Graph") &&
                  session?.runData?.data?.gaitMetricData?.driveData &&
                  thisSession?.ActivityType === "Run" && (
                    <div id="Drive-Index-Graph" key={"3"} className="grid-card">
                      {session?.runData?.data?.gaitMetricData?.driveData && (
                        <DriveIndex
                          runData={session.runData}
                          showSettings={false}
                          toggleableTextArea={togglerComponent(
                            toggledNotes.graph3,
                            toggleNotes,
                            "graph3"
                          )}
                        />
                      )}
                    </div>
                  )}
                {/* graph exists for jump */}
                {!disabledGraphIDs.includes("RSI-Graph") &&
                  metricRules.DisplayRSI(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) && (
                    <div id="RSI-Graph" key={"3"} className="grid-card">
                      {session?.jumpData?.data?.rsiData && (
                        <RSI
                          sessionid={selectedSessionId}
                          xRange={asymJumpRangeArray}
                          showSettings={false}
                          toggleableTextArea={togglerComponent(
                            toggledNotes.graph3,
                            toggleNotes,
                            "graph3"
                          )}
                        />
                      )}
                    </div>
                  )}
                {/* graph exists for run */}
                {!disabledGraphIDs.includes("Flight-Time-Graph") &&
                  session.runData.data &&
                  thisSession?.ActivityType === "Run" &&
                  thisSession?.ActivityName !== "Walk" && (
                    <div id="Flight-Time-Graph" key={"4"} className="grid-card">
                      <FlightTime
                        sessionid={selectedSessionId}
                        showSettings={false}
                        match={match}
                        isWalk={
                          thisSession?.ActivityName === "Walk" ? true : false
                        }
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph4,
                          toggleNotes,
                          "graph4"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for jump */}
                {!disabledGraphIDs.includes("Jump-Height-Graph") &&
                  metricRules.DisplayJumpHeight(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) &&
                  thisSession?.ActivityType === "Jump" && (
                    <div id="Jump-Height-Graph" key={"4"} className="grid-card">
                      <JumpHeight
                        sessionid={selectedSessionId}
                        xRange={asymJumpRangeArray}
                        showSettings={false}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph4,
                          toggleNotes,
                          "graph4"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for run */}
                {!disabledGraphIDs.includes("Flight-Time-Asymmetry-Graph") &&
                  session.runData.data &&
                  thisSession?.ActivityType === "Run" &&
                  thisSession?.ActivityName !== "Walk" && (
                    <div
                      id="Flight-Time-Asymmetry-Graph"
                      key={"5"}
                      className="grid-card test-symm"
                    >
                      <FlightTimeAsymmetry
                        sessionid={selectedSessionId}
                        showSettings={false}
                        match={match}
                        xRange={asymRangeArray}
                        isWalk={thisSession?.ActivityName === "Walk" && true}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph5,
                          toggleNotes,
                          "graph5"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for jump */}
                {!disabledGraphIDs.includes("Jump-Flight-Time-Graph") &&
                  metricRules.DisplayFlightTimeJump(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) &&
                  thisSession?.ActivityType === "Jump" && (
                    <div
                      id="Jump-Flight-Time-Graph"
                      key={"5"}
                      className="grid-card"
                    >
                      <FlightTimeJump
                        thisSession={thisSession}
                        sessionid={selectedSessionId}
                        xRange={asymJumpRangeArray}
                        showSettings={false}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph5,
                          toggleNotes,
                          "graph5"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for horizontal jump */}
                {/* {!disabledGraphIDs.includes("Ground-Clearance-Graph") &&
                  metricRules.DisplayGroundClearanceJump(
                    thisSession.activityTypeID,
                    thisSession.activityID
                  ) &&
                  thisSession?.ActivityType === "Jump" && (
                    <div
                      id="Ground-Clearance-Graph"
                      key={"6"}
                      className="grid-card"
                    >
                      <GroundClearanceJump
                        sessionid={selectedSessionId}
                        showSettings={false}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph6,
                          toggleNotes,
                          "graph6"
                        )}
                      />
                    </div>
                  )} */}
                {/* graph exists for run */}
                {!disabledGraphIDs.includes("Ground-Contact-Time-Graph") &&
                  session.runData.data &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Ground-Contact-Time-Graph"
                      key={"6"}
                      className="grid-card"
                    >
                      <GroundContactTime
                        sessionid={selectedSessionId}
                        showSettings={false}
                        match={match}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph6,
                          toggleNotes,
                          "graph6"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for jump */}
                {!disabledGraphIDs.includes("Ground-Contact-Time-Jump-Graph") &&
                  metricRules.DisplayContactTimeJump(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) && (
                    <div
                      id="Ground-Contact-Time-Jump-Graph"
                      key={"6"}
                      className="grid-card"
                    >
                      <ContactTimeJump
                        sessionid={selectedSessionId}
                        xRange={asymJumpRangeArray}
                        showSettings={false}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph6,
                          toggleNotes,
                          "graph6"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for run */}
                {!disabledGraphIDs.includes(
                  "Ground-Contact-Time-Asymmetry-Graph"
                ) &&
                  session.runData.data &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Ground-Contact-Time-Asymmetry-Graph"
                      key={"7"}
                      className="grid-card"
                    >
                      <GroundContactTimeAsymmetry
                        sessionid={selectedSessionId}
                        showSettings={false}
                        match={match}
                        xRange={asymRangeArray}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph7,
                          toggleNotes,
                          "graph7"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for jump */}
                {!disabledGraphIDs.includes("Power-Graph") &&
                  metricRules.DisplayPower(
                    thisSession.jumpTypeID,
                    thisSession.activityID
                  ) && (
                    <div id="Power-Graph" key={"7"} className="grid-card">
                      <Power
                        sessionid={selectedSessionId}
                        xRange={asymJumpRangeArray}
                        showSettings={false}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph7,
                          toggleNotes,
                          "graph7"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for horizontal jump */}
                {!disabledGraphIDs.includes("Jump-Length-Graph") &&
                  metricRules.DisplayJumpLength(
                    thisSession.activityTypeID,
                    thisSession.activityID
                  ) && (
                    <div id="Jump-Length-Graph" key={"7"} className="grid-card">
                      <JumpLength
                        sessionid={selectedSessionId}
                        showSettings={false}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph7,
                          toggleNotes,
                          "graph7"
                        )}
                      />
                    </div>
                  )}
                {/* graph exists for horizontal jump */}
                {!disabledGraphIDs.includes("Jump-Velocity-Graph") &&
                  metricRules.DisplayJumpVelocity(
                    thisSession.activityTypeID,
                    thisSession.activityID
                  ) && (
                    <div
                      id="Jump-Velocity-Graph"
                      key={"8"}
                      className="grid-card"
                    >
                      <JumpVelocity
                        sessionid={selectedSessionId}
                        showSettings={false}
                        jumpVelocityDisplayed={jumpVelocityDisplayed}
                        setJumpVelocityDisplayed={setJumpVelocityDisplayed}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph8,
                          toggleNotes,
                          "graph8"
                        )}
                      />
                    </div>
                  )}

                {!disabledGraphIDs.includes("Cadence-Graph") &&
                  session.runData.data &&
                  thisSession?.ActivityType === "Run" && (
                    <div id="Cadence-Graph" key={"8"} className="grid-card">
                      <CadenceReport
                        sessionid={selectedSessionId}
                        showSettings={false}
                        match={match}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph8,
                          toggleNotes,
                          "graph8"
                        )}
                        cadenceFrequencyToggler={cadenceFrequencyToggler}
                        setCadenceFrequencyToggler={setCadenceFrequencyToggler}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Peak-Tibial-Acceleration-Graph") &&
                  session.runData.data &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Peak-Tibial-Acceleration-Graph"
                      key={"9"}
                      className="grid-card"
                    >
                      <PeakTibialAcceleration
                        sessionid={selectedSessionId}
                        showSettings={false}
                        match={match}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph9,
                          toggleNotes,
                          "graph9"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes(
                  "Foot-Strike-Ground-Reaction-Graph"
                ) &&
                  session.runData.data &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Foot-Strike-Ground-Reaction-Graph"
                      key={"10"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <SwingStanceFootStrike
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph10,
                          toggleNotes,
                          "graph10"
                        )}
                      />
                    </div>
                  )}

                {/* {!disabledGraphIDs.includes("Ground-Clearance-Graph") &&
                  session.runData.data &&
                  session.runData.data?.gaitMetricData?.groundClearance &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Ground-Clearance-Graph"
                      key={"11"}
                      className="grid-card"
                    >
              
                      <GroundClearance
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph11,
                          toggleNotes,
                          "graph11"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes(
                  "Ground-Clearance-Asymmetry-Graph"
                ) &&
                  session.runData.data &&
                  session.runData.data?.StrideSymmetryData?.groundClearance &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Ground-Clearance-Asymmetry-Graph"
                      key={"12"}
                      className="grid-card"
                    >
                      <GroundClearanceAsymmetry
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph12,
                          toggleNotes,
                          "graph12"
                        )}
                      />
                    </div>
                  )} */}
                {!disabledGraphIDs.includes("Stride-Length-Graph") &&
                  session.runData.data &&
                  session.runData.data?.gaitMetricData?.groundClearance &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Stride-Length-Graph"
                      key={"11"}
                      className="grid-card"
                    >
                      <StrideLength
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph11,
                          toggleNotes,
                          "graph11"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Stride-Length-Asymmetry-Graph") &&
                  session.runData.data &&
                  session.runData.data?.StrideSymmetryData?.groundClearance &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Stride-Length-Asymmetry-Graph"
                      key={"12"}
                      className="grid-card"
                    >
                      <StrideLengthAsymmetry
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph12,
                          toggleNotes,
                          "graph12"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Step-Length-Graph") &&
                  session.runData.data &&
                  session.runData.data?.gaitMetricData?.stepLength &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Step-Length-Graph"
                      key={"13"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <StepLength
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph13,
                          toggleNotes,
                          "graph13"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Step-Length-Asymmetry-Graph") &&
                  session.runData.data &&
                  session.runData.data?.StrideSymmetryData?.stepLength &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Step-Length-Asymmetry-Graph"
                      key={"14"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <StepLengthAsymmetry
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph14,
                          toggleNotes,
                          "graph14"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Stride-Velocity-Graph") &&
                  session.runData.data &&
                  (session.runData.data.gaitMetricData?.meanStrideVelocity ||
                    session.runData.data.gaitMetricData?.maxStrideVelocity) &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Stride-Velocity-Graph"
                      key={"15"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <StrideVelocity
                        strideVelocityDisplayed={strideVelocityDisplayed}
                        setStrideVelocityDisplayed={setStrideVelocityDisplayed}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph15,
                          toggleNotes,
                          "graph15"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes(
                  "Stride-Velocity-Asymmetry-Graph"
                ) &&
                  session.runData.data &&
                  (session.runData.data.StrideSymmetryData
                    ?.meanStrideVelocity ||
                    session.runData.data.StrideSymmetryData
                      ?.maxStrideVelocity) &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Stride-Velocity-Asymmetry-Graph"
                      key={"16"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <StrideVelocityAsymmetry
                        strideVelocityDisplayed={strideVelocityDisplayed}
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph16,
                          toggleNotes,
                          "graph16"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Stride-Time-Graph") &&
                  session.runData.data &&
                  session.runData.data.gaitMetricData?.strideArray?.stepData &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Stride-Time-Graph"
                      key={"17"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <StrideTime
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph17,
                          toggleNotes,
                          "graph17"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Stride-Time-Asymmetry-Graph") &&
                  session.runData.data &&
                  session.runData.data.StrideSymmetryData?.stride &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Stride-Time-Asymmetry-Graph"
                      key={"18"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <StrideTimeAsymmetry
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph18,
                          toggleNotes,
                          "graph18"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Swing-Time-Graph") &&
                  session.runData.data &&
                  session.runData.data.gaitMetricData?.swingArray?.stepData &&
                  thisSession?.ActivityType === "Run" && (
                    <div id="Swing-Time-Graph" key={"19"} className="grid-card">
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <SwingTime
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph19,
                          toggleNotes,
                          "graph19"
                        )}
                      />
                    </div>
                  )}
                {!disabledGraphIDs.includes("Swing-Time-Asymmetry-Graph") &&
                  session.runData.data &&
                  session.runData.data.StrideSymmetryData?.swing &&
                  thisSession?.ActivityType === "Run" && (
                    <div
                      id="Swing-Time-Asymmetry-Graph"
                      key={"20"}
                      className="grid-card"
                    >
                      {/* <FootstrikeReport sessionid={sessionId} /> */}
                      <SwingTimeAsymmetry
                        toggleableTextArea={togglerComponent(
                          toggledNotes.graph20,
                          toggleNotes,
                          "graph20"
                        )}
                      />
                    </div>
                  )}
              </ResponsiveGridLayout>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="pdf-loader-container">
            <Spinner />
          </div>
        )}
      </div>
    </TestSlide>
  );
}

export default CustomGraphLayoutModal;
