/* eslint-disable */
import React, { useState } from "react";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import "react-vis/dist/style.css";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceArea,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_MID_COLOR,
} from "../../utils/constant";

import { gctMin, gctMax, getAverage } from "utils/metrics";

import DownArrow from "../../components/common/icons/downArrow";
import Tooltips from "components/common/toolTips/toolTips";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectSessionList2 } from "store/sessionListSlice";
import { getSessionList } from "store/sessionListSlice";
import MaxMinMean from "./metric-helpers/MaxMinMean";
import GraphSettings from "./metric-helpers/GraphSettings";
import mappingTrimAreaX from "./metric-helpers/mappingTrimArea";
import { formatTime } from "utils/helper";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

const DriveIndex = ({
  runData,
  graphDomain,
  yRange,
  showSettings = true,
  trimDomain,
  isTrimOpen = false,
  toggleableTextArea
}) => {
  const [hidden, setHidden] = React.useState(false);
  const [chartType, setChartType] = useState({ graph: "line" });

  const toggleChart = () => {
    setHidden((hidden) => !hidden);
  };

  const setGraphType = (graphType) => {
    setChartType({ graph: graphType });
  };

  const CustomTooltipForCadence = ({ active, payload, label, data }) => {
    function combineObjects(array) {
      // use reduce to iterate over the array and accumulate a single object
      return array.reduce(function (result, obj) {
        // get the data key of the current object
        let key = obj.dataKey;
        // create a new property in result with the data key as the name
        result[key] = {};
        // copy the properties of obj to result[key] except for dataKey and payload
        Object.keys(obj).forEach(function (k) {
          if (k !== "dataKey" && k !== "payload") {
            result[key][k] = obj[k];
          }
        });
        // create a new property in result[key] with the name 'colour'
        result[key].colour = {};
        // copy the properties of obj.payload to result[key].colour
        Object.assign(result[key].colour, obj.payload);

        return result;
      }, {}); // initialize result as an empty object
    }

    const newPayload = combineObjects(payload);

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {newPayload["driveIndex"] && (
            <div className="label">
              {`${newPayload["driveIndex"].colour.foot}`}
            </div>
          )}

          {/* Contact time payload---------------------------------------- */}

          {payload.length >= 1 && newPayload["contactTime"] && (
            <div className={`left`}>
              {`Contact Time`} :{" "}
              {` ${newPayload["contactTime"].colour["contactTime"]}`}s
            </div>
          )}
          {/* Flight time payload---------------------------------------- */}
          {payload.length >= 1 && newPayload["flightTime"] && (
            <div className={`right`}>
              {`Flight Time`} :{" "}
              {` ${newPayload["flightTime"].colour["flightTime"]}`}s
            </div>
          )}
          {/* Drive index payload---------------------------------------- */}
          {payload.length >= 1 && newPayload["driveIndex"] && (
            <div className={`both`}>
              {`Drive index`} :{" "}
              {` ${newPayload["driveIndex"].colour["driveIndex"]}`}
            </div>
          )}

          {label !== null && <div className="label">Time: {label} s</div>}
        </div>
      );
    }

    return null;
  };

  const filterAverageData = (arr, filterValue) => {
    if (graphDomain) {
      return arr.filter(
        (item) =>
          item[filterValue] >= graphDomain[0] &&
          item[filterValue] <= graphDomain[1]
      );
    } else {
      return arr;
    }
  };

  const displayCharts = () => {
    const contactTimeData = runData.data?.ContactTimeData?.stepData;
    const flightTimeData = runData.data?.FlightTimeData?.stepData;
    const driveIndex = runData.data?.gaitMetricData?.driveData;

    const sortByKey = (arr, key) => {
      // Sort the array by comparing the values of the key
      arr?.sort((a, b) => {
        // Return a negative number if a[key] is less than b[key]
        if (a?.[key] < b?.[key]) {
          return -1;
        }

        // Return a positive number if a[key] is greater than b[key]
        if (a?.[key] > b?.[key]) {
          return 1;
        }

        // Return zero if a[key] is equal to b[key]
        return 0;
      });

      // Return the sorted array
      return arr;
    };

    const driveIndexMapped = driveIndex?.map((obj) => {
      //get key that isnt name
      let key = Object.keys(obj).find((k) => {
        return k != "name";
      });

      //return new object with the new format
      return {
        "Contact Name": obj.name,
        driveIndex: obj[key],
        foot: key,
      };
    });

    const contactTimeMapped = contactTimeData?.map((obj) => {
      //get key that isnt name
      let key = Object.keys(obj).find((k) => {
        return k != "name";
      });

      //return new object with the new format
      return {
        "Contact Name": obj.name,
        contactTime: obj[key],
        foot: key,
      };
    });

    const flightTimeMapped = flightTimeData?.map((obj) => {
      let key = Object.keys(obj).find((k) => {
        return k != "name";
      });

      //return new object with the new format
      return {
        "Contact Name": obj.name,
        flightTime: obj[key],
        foot: key,
      };
    });
    sortByKey(contactTimeMapped, "Contact Name");
    sortByKey(flightTimeMapped, "Contact Name");
    sortByKey(driveIndexMapped, "Contact Name");

    if (runData.loaded === false) return displayLoadingCharts();
    else if (
      (contactTimeData &&
        flightTimeData &&
        contactTimeData.length &&
        flightTimeData.length > 0) !== true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (runData.data?.ContactTimeData) {
      //mapping over new average datasets
      const minMaxminMaxMeanFlightTime = flightTimeMapped.map((item) => ({
        "Right Foot": item.flightTime,
        "Flight Name": item["Contact Name"],
      }));

      const minMaxMeanContactTime = contactTimeMapped.map((item) => ({
        "Left Foot": item.contactTime,
        "Contact Name": item["Contact Name"],
      }));

      return (
        <div className="metric-container">
          <div className="metric-chart-container-parent ">
            <div className="chart-container-padding">
              <MaxMinMean
                leftFoot={{
                  hasData: minMaxMeanContactTime.length > 0,
                  min: gctMin(
                    "left",
                    filterAverageData(minMaxMeanContactTime, "Contact Name")
                  ),
                  max: gctMax(
                    "left",
                    filterAverageData(minMaxMeanContactTime, "Contact Name")
                  ),
                  mean: getAverage(
                    "left",
                    filterAverageData(minMaxMeanContactTime, "Contact Name")
                  ),
                }}
                rightFoot={{
                  hasData: minMaxminMaxMeanFlightTime.length > 0,
                  min: gctMin(
                    "right",
                    filterAverageData(minMaxminMaxMeanFlightTime, "Flight Name")
                  ),
                  max: gctMax(
                    "right",
                    filterAverageData(minMaxminMaxMeanFlightTime, "Flight Name")
                  ),
                  mean: getAverage(
                    "right",
                    filterAverageData(minMaxminMaxMeanFlightTime, "Flight Name")
                  ),
                }}
              />

              <div className="chart-container">
                <div className="container-pdf-flex">
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                      width={500}
                      height={600}
                      data={driveIndexMapped}
                      margin={{
                        top: 30,
                        right: 0,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={NEW_AXIS_COLOR}
                      />

                      <XAxis
                        label={{
                          value: driveIndexMapped[driveIndexMapped.length -2]["Time"] > 300 ? "Time (min)" : "Time (s)",
                          position: "bottom",
                          fill: NEW_AXIS_TICK,
                          offset: -6,
                        }}
                        dataKey="Contact Name"
                        type="number"
                        tickCount={10}
                        interval={0}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                        domain={graphDomain && !isTrimOpen ? graphDomain : [0]}
                        allowDataOverflow
                        tickFormatter={(value) => formatTime(value, driveIndexMapped[driveIndexMapped.length -2])}
                      />
                      <YAxis
                        yAxisId="left"
                        orientation="left"
                        label={{
                          value: "Time (s)",
                          angle: -90,
                          position: "insideLeft",
                          fill: NEW_AXIS_TICK,
                        }}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                        domain={[
                          0,
                          (dataMax) => ((dataMax / 100) * 110).toFixed(2),
                        ]}
                      />
                      <YAxis
                        yAxisId="right"
                        orientation="right"
                        label={{
                          value: "Drive Index",
                          angle: -90,
                          position: "outsideLeft",
                          fill: NEW_AXIS_TICK,
                        }}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                        domain={[
                          0,
                          (dataMax) => ((dataMax / 100) * 110).toFixed(0),
                        ]}
                      />

                      
                      {chartType.graph === "line" && (
                        <>
                          <Bar
                            data={driveIndexMapped}
                            yAxisId="right"
                            dataKey="driveIndex"
                            barSize={4}
                            name="Drive Index"
                            fill={NEW_MID_COLOR}
                            opacity={0.4}
                          />

                          <Line
                            data={contactTimeMapped}
                            yAxisId="left"
                            dataKey="contactTime"
                            name="Contact Time"
                            stroke={NEW_LEFT_COLOR}
                            dot={driveIndexMapped.length > 100 ? false : true}
                            connectNulls={false}
                          />

                          <Line
                            data={flightTimeMapped}
                            yAxisId="left"
                            dataKey="flightTime"
                            name="Flight Time"
                            stroke={NEW_RIGHT_COLOR}
                            dot={driveIndexMapped.length > 100 ? false : true}
                            connectNulls={false}
                          />
                        </>
                      )}



                      {chartType.graph === "bar" && (
                        <>
                          <Bar
                            data={contactTimeMapped}
                            yAxisId="left"
                            dataKey="contactTime"
                            fill={NEW_LEFT_COLOR}
                            barSize={4}
                            opacity={0.7}
                          />

                          <Bar
                            data={flightTimeMapped}
                            yAxisId="left"
                            dataKey="flightTime"
                            name="Flight Time"
                            fill={NEW_RIGHT_COLOR}
                            barSize={4}
                            opacity={0.7}
                          />

                          <Line
                            data={driveIndexMapped}
                            yAxisId="right"
                            dataKey="driveIndex"
                            name="Drive Index"
                            stroke={NEW_MID_COLOR}
                            dot={driveIndexMapped.length > 100 ? false : true}
                            connectNulls={false}
                          />
                        </>
                      )}
                      
                      <Legend
                        verticalAlign="top"
                        layout="vertical"
                        wrapperStyle={{ top: -60, right: 0 }}
                      />

                      <Tooltip content={<CustomTooltipForCadence />} />
                    </ComposedChart>
                  </ResponsiveContainer>
                  {toggleableTextArea}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  let arrowClass = "";
  if (!hidden) {
    arrowClass = "flip-div";
  }

  return (
    <div className="settings_graph">
      <div className="metric-container-parent force-data">
        <div
          className="metric-container metric-title-div"
          onClick={() => {
            toggleChart();
          }}
        >
          <div className="metric-title-left-padding" />

          <div className="metric-title-left-icon">
            {/* <img style={{ width: 20, height: 20 }} alt='forceicon' src={forceIcon} /> */}
          </div>

          <div className="metric-title-left-padding" />

          <div className="metric-title-main-title">
            Drive Index{" "}
            <div style={{ paddingLeft: "0.5rem" }}>
              <MainTooltip 
                  tooltipText={
                    "Contact and flight times averaged over both legs for the recorded session"
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
              />
            </div>
          </div>

          <div className="metric-title-extra-info">
            {/* <span>00:00:00</span> */}
          </div>

          <div className="metric-title-left-padding" />

          <div className="metric-title-left-icon"></div>
        </div>

        {displayCharts()}
      </div>
      {showSettings && (
        <GraphSettings
          graphName={"driveIndex"}
          settingsPosition={"right-side"}
          chartType={chartType}
          setChartType={setGraphType}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionList: selectSessionList2(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getSessionList }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriveIndex);
