/* eslint-disable */
const SockIcon = ({ size = "18", color = "#4B5667", reverse = false }) => {

   const style = reverse ? {transform: "scaleX(-1)"} : {}

  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-sock"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M13 3v6l4.798 5.142a4 4 0 0 1 -5.441 5.86l-6.736 -6.41a2 2 0 0 1 -.621 -1.451v-9.141h8z" />
        <path d="M7.895 15.768c.708 -.721 1.105 -1.677 1.105 -2.768a4 4 0 0 0 -4 -4" />
      </svg>
    </div>
  );
};

export default SockIcon;
