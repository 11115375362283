/* eslint-disable */
import "./mainModal.scss";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import MainButton from "../reuseableButtonComponent/MainButton";

const MainModal = (props) => {
  const {
    button,
    modalBody = false,
    responseResetState = false,
    onOpen = false,
    modalButtons = false,
  } = props;

  return (
    <Dialog.Root
      onOpenChange={(isOpen) => {
        if (isOpen) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "visible";
        }
        {
          onOpen && onOpen();
        }
        if (!isOpen) {
          responseResetState && responseResetState("");
        }
      }}
    >
      <Dialog.Trigger asChild>
        <span>{button}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="main-dialog-overlay" />
        <Dialog.Content className="main-modal">
          <div className="close-container">
            <Dialog.Close
              onClick={() => {
                close, responseResetState && responseResetState("");
              }}
              asChild
            >
              <button className="main-modal-close-icon" aria-label="Close">
                <Cross2Icon />
              </button>
            </Dialog.Close>
          </div>
          <div className="main-modal-container">{modalBody && modalBody}</div>
          <div className="main-modal-buttons">
            {modalButtons ? (
              <Dialog.Close asChild>{modalButtons}</Dialog.Close>
            ) : null}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default MainModal;
