import "./spinner.scss";

const Spinner = ({ cls = "", classnames, style = {} }) => {
  return (
    <>
      <div style={style} className={`spinner-container ${classnames}`}>
        <span className={`loaderTest ${cls}`}></span>
      </div>
    </>
  );
};

export default Spinner;
