const FilterIcon = (props) => {

	const  {size, color} = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58877 0.333087L12.4166 0.333296C12.4792 0.333296 12.5415 0.340352 12.6024 0.354266L12.6924 0.380255C13.1788 0.550851 13.5589 0.93693 13.722 1.42596C13.8687 1.86608 13.8246 2.34501 13.6053 2.74865L13.5257 2.88025L13.4499 2.97719L9.49994 7.3208V12.8333C9.49994 13.4888 8.78562 13.8757 8.24287 13.5512L8.1666 13.5L4.83327 11C4.64966 10.8623 4.53216 10.6559 4.50565 10.4308L4.49994 10.3333V7.32163L0.549985 2.97719L0.474146 2.88025C0.187358 2.45192 0.11491 1.91498 0.277919 1.42596C0.426109 0.981387 0.753729 0.621898 1.17753 0.432039L1.30222 0.381878L1.39219 0.355305L1.4916 0.338296C1.5264 0.334426 1.55768 0.332881 1.58877 0.333087ZM12.0858 1.99996H1.91327L5.94988 6.4394C6.06609 6.56723 6.13912 6.72732 6.16023 6.89711L6.1666 6.99996V9.91663L7.83327 11.1666V6.99996C7.83327 6.82721 7.88693 6.65962 7.98551 6.51979L8.04998 6.4394L12.0858 1.99996Z"
        fill={color}
      />
    </svg>
  );
};

export default FilterIcon
