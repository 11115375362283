import Button from '../button/button';
import * as Dialog from '@radix-ui/react-dialog';


const DisplayCreatedScreen = ({newOrg,emailOne,orgName,userName,orgCreated,reset,type,podRegistered,tagRegistered,close}) => {
  const types = type ==='Admin' ? 'Administrator' : type ==='Org' ? 'Organisation' : type === 'POD' ? 'POD' : type === 'tag' ? 'Tag' : 'Coach' 
  return (
          <div className='org-created-message'>
            <div className="display-message">
              {orgCreated && newOrg >= 1 &&
                <div>
                  <p style={{fontWeight:'600'}}>New  {types} has been added</p><br/>
                  {emailOne &&
                    <div>
                      <p>An email has been sent to {emailOne}.</p>
                      <p>Once {userName} creates an account on Danu they will be set up as an administrator for organisation
                        <b>{" " + orgName}</b>.</p>
                    </div>}
                </div>
              }
              {podRegistered && newOrg >= 1 && <div>
                <p style={{fontWeight:'600'}}>New  POD has been added</p><br/></div>}

                {tagRegistered && newOrg >= 1 && <div>
                <p style={{fontWeight:'600'}}>New  Tag has been added</p><br/></div>}
              
              {newOrg < 0 && <p>Error Creating New {types}</p>}
            </div>
            <div>
              <div className="org-msg-btn" onClick={(e) => { reset(e) }}>
                <Button value={`Create New ${types}`}/>
              </div>
    
              <Dialog.Close asChild onClick={close}>
              <div className="org-msg-btn" >
                <Button value={`View List Of ${types}`}/>
    
              </div>
              </Dialog.Close>
            </div>
          </div>
        )
      }

export default DisplayCreatedScreen