import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getJumpDataAPI } from "../api/session";

export const getJumpData = createAsyncThunk(
  "jumpData/getJumpData",
  async (sessionID) => {
    const response = await getJumpDataAPI(sessionID);
    return response;
  }
);

const jumpInitialState = {
  loaded: false,
  status: "idle",
  sessionID: 0,
  data: {
    flightData: {
      bar: [],
    },
  },
  jumpHeightData: {
    bar: [],
  },
};

export const jumpDataSlice = createSlice({
    name: "jumpData",
    initialState: jumpInitialState,
    reducers: {
      resetJumpData: (state) => {
        Object.assign(state, jumpInitialState);
        console.log(state)
      },
      setJumpData: (state, action) => {
        state.loaded = true;
        state.sessionID = action.payload.sessionID;
        state.data = action.payload.data;
        state.status = "succeeded";
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getJumpData.pending, (state) => {
          state.status = "loading";
        })
        .addCase(getJumpData.fulfilled, (state, action) => {
          state.loaded = true;
          state.status = "succeeded";
          state.sessionID = action.meta.arg;
          state.data = action.payload;
        })
        .addCase(getJumpData.rejected, (state) => {
          state.status = "failed";
        });
    },
  });
  

export const { resetJumpData, setJumpData } = jumpDataSlice.actions;

export const selectJumpData = (state) => state.jumpData;

export default jumpDataSlice.reducer;
