import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postFile } from '../../api/apiHelper';
import { isValidEmail } from '../../utils/helper';
import Button from 'components/common/button/button';
import './podReg.scss'
import { getOrganisationsToolkit,resetOrganisationDetailView } from 'store/orgSlice';
import { selectUserList,getUsers,addAdminToOrg,addCoachToOrg,createNewOrg} from 'store/adminSlice';
import { selectUser } from 'store/authSlice';


class OrganisationSetup extends React.Component {
  fileInputRef = React.createRef();

  constructor(props) {
    super(props)

    this.state = {
      selectedUser: undefined,
      file: undefined,
      newOrg: 0,
      orgName: '',
      userName: '',
      emailOne: '',
      emailTwo: '',
      errors: {},
      orgCreated: false,
      newUserAddedComplete: 0
    };

    this.onChange = this.onChange.bind(this);
  }



  // componentDidMount() {
    
  //   this.props.actions.getUsers()
  // }

  reset() {
    this.setState({
      selectedUser: undefined,
      file: undefined,
      newOrg: 0,
      orgName: '',
      userName: '',
      emailOne: '',
      emailTwo: '',
      errors: {},
      orgCreated: false,
      newUserAddedComplete: 0
    })
  }

  validateInputs() {
    const { existingOrg } = this.props;
    const errors = {};
    errors.formErrors = false;

    if (

      ((!this.state.orgName) || (this.state.orgName.length === 0))
      && (!existingOrg)

    ) {
      errors.error = 'You must enter the organisation name';
      errors.formErrors = true;
    }
    //no longer force linking an org to an admin
    // else if (((!this.state.userName) || (this.state.userName.length === 0))  && (!this.state.selectedUser)) {
    //   errors.error = 'You must enter the name of the administrator';
    //   errors.formErrors = true;
    // }

    else if (this.state.emailOne.length > 0 && (!isValidEmail(this.state.emailOne)) && (!this.state.selectedUser)) {
      errors.error = 'You must enter a valid email';
      errors.formErrors = true;
    }
    else if ((this.state.emailOne !== this.state.emailTwo) && (!this.state.selectedUser)) {
      errors.error = 'Emails do not match';
      errors.formErrors = true;
    }

    else if (
      this.state.selectedUser &&
      (this.state.userName || this.state.emailOne || this.state.emailTwo)
    ) {
      errors.error = 'You cannot select a user and Invite a user at the same time!';
      errors.formErrors = true;
    }


    this.setState({ errors });
    return !errors.formErrors;
  }

  handleFileChange(event) {
    if (event.target.files.length > 0) {
      this.setState({ file: event.target.files[0] })

      var image = document.getElementById('tempOrgIcon');
      image.src = URL.createObjectURL(event.target.files[0]);


    }
  }

  triggerFileInput = event => {
    event.preventDefault()
    this.fileInputRef.current.click();
  }


  addAdmin(e) {
    e.preventDefault();

    if (this.validateInputs()) {
      const { userName, emailOne,selectedUser } = this.state;
      const { existingOrg, process } = this.props;

      let postBody = {
        name: existingOrg.Name,
        adminName: userName,
        email: emailOne,
        userID: selectedUser
      }

      if (process === "addAdmin") {
        this.props.actions.addAdminToOrg({OrgID:existingOrg.OrgID, postBody}).then(() => {
          this.handleAddResult(1);
        })
          .catch((error) => {
            console.log(error);
            this.handleAddResult(-1);
          });
      }
      else if (process === "addCoach") {
        this.props.actions.addCoachToOrg({OrgID:existingOrg.OrgID, postBody}).then(() => {
          this.handleAddResult(1);
        })
          .catch((error) => {
            console.log(error);
            this.handleAddResult(-1);
          });
      }
    }
  }

  handleAddResult(success) {
    const { emailOne } = this.state;
    if (emailOne && emailOne !== "") {
      //we have sent an invite for a new user
      this.setState({ newUserAddedComplete: success });
    }
    else {
      //we have set up an existing user
      this.props.actions.resetOrganisationDetailView();
    }
  }


  createOrg(e) {

    e.preventDefault();

    if (this.validateInputs()) {

      const { orgName, userName, emailOne } = this.state;
      const {user} = this.props

      let newOrg = {
        name: orgName,
        adminName: userName,
        email: emailOne,
        userID: user.id
      }

      this.props.actions.createNewOrg(newOrg).then(res => {
        const { file } = this.state;
        if (file && res.payload.orgID > 0) {
          this.addIcon(res.payload.orgID)
        }
        this.setState({ orgCreated: true, newOrg: res.payload.orgID });
      })
        .catch((error) => {
          console.log(error);
          this.setState({ orgCreated: false, newOrg: -1 });
        });
    }
  }

  addIcon(orgID) {
    const { file } = this.state;
    let url = `organisation/addImage/${orgID}`

    postFile(url, file).then(res => {
      if (res.data.updated) {
        //success
      } else {
        //failure
      }
    })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }



  displayCreatedScreen() {
    const { newOrg, emailOne, orgName, userName,orgCreated } = this.state;


    return (
      <div className='org-created-message'>
        <div className="display-message">
          {orgCreated && newOrg >= 1 &&
            <div>
              <p>New Organisation Created</p>
              {emailOne &&
                <div>
                  <p>An email has been sent to {emailOne}.</p>
                  <p>Once {userName} creates an account on Danu they will be set up as an administrator for organisation
                    <b>{" " + orgName}</b>.</p>
                </div>}
            </div>
          }
          {newOrg < 0 && <p>Error Creating New Organisation</p>}
        </div>
        <div>
          <div className="org-msg-btn" onClick={(e) => { this.reset(e) }}>
            <Button value={"Create New Organisation"}/>
          </div>

          <div className="org-msg-btn" onClick={() => window.location.reload()}>
            <Button value={"View List Of Organisations"}/>

          </div>

        </div>
      </div>
    )
  }

  getLevel() {
    const { process } = this.props;

    if (process === "addAdmin") {
      return "an administrator"
    }
    else if (process === "addCoach") {
      return "a coach"
    }
  }

  displayUserAddedScreen() {
    const { emailOne, userName, newUserAddedComplete } = this.state;
    const { existingOrg, process } = this.props;



    return (
      <div className='org-created-message '>
        <div className="display-message">

          {newUserAddedComplete === 1 &&
            <div>
              <p>An email has been sent to {emailOne}.</p>
              <p>Once {userName} creates an account on Danu they will be set up as {this.getLevel()} for organisation
                <b>{" " + existingOrg.Name}</b>.</p>
            </div>}

          {newUserAddedComplete === -1 && <p style={{ width: 600, textAlign: 'center' }}>Error linking user to this organisation</p>}
        </div>
        <div>
          {process === "addAdmin" && <div className="org-msg-btn" onClick={(e) => { this.reset(e) }}>
            <Button value={"Add Another Adminstrator"} />
          </div>}

          {process === "addCoach" && <div className="org-msg-btn" onClick={(e) => { this.reset(e) }}>
            <Button value={"Add Another Coach"} />
          </div>}
        </div>
      </div>
    )
  }


  handleUserSelect(value) {
    this.setState({ selectedUser: value });
  }

  render() {
    const {  orgName, userName, emailOne, emailTwo, errors, orgCreated, file, newUserAddedComplete } = this.state;
    const { userList, existingOrg, process } = this.props;

    if (orgCreated) {
      return this.displayCreatedScreen()
    }

    if (newUserAddedComplete !== 0) {
      return this.displayUserAddedScreen()
    }

    let dropDownSelectionList = []
    if (!existingOrg) {
      dropDownSelectionList = userList
    }
    else {
      let checkList = existingOrg.admins
      if (process === "addCoach") {
        checkList = existingOrg.coaches
      }
      for (var i = 0; i < userList.length; i++) {
        let thisUser = userList[i]
        let thisUserExists = false
        for (var j = 0; j < checkList.length; j++) {
          if (thisUser.id === checkList[j].userid) {
            thisUserExists = true
          }
        }
        if (!thisUserExists) {
          dropDownSelectionList.push(thisUser)
        }
      }
    }


    let iconStyle = {
      display: 'none',
      width: 120,
      //height:120,
      //borderRadius:'50%'
    }



    if (file) {
      iconStyle.display = 'inline'
    }

    return (
      <div className={`${ process === "addAdmin" ? "setup_organisation_admin" :
                          process === "addCoach" ? "setup_organisation_admin" : "setup_organisation"} right-background`}>
        
        <div className="left">
          {!existingOrg && <div className="organisation-placeholder-parent">

            {!file && <div className="organisation-placeholder">

            </div>}

            <img id="tempOrgIcon" alt='iconstyle' style={iconStyle} />
          </div>}


          {!existingOrg && <div className="file-select-parent">
            <div className="file-select-button" onClick={this.triggerFileInput}>
              <Button value={"Choose File"}/>
            </div>

            <input
              type="file"
              accept="image/*"
              onChange={this.handleFileChange.bind(this)}
              style={{
                opacity: '0',
                filter: 'alpha(opacity=0)',
                display: 'none'
              }}
              ref={this.fileInputRef}
              />
          </div>}
        </div>

        <div className="center editCenter">

          <div className="extra-white">

            {!existingOrg && <div className="input_parent input_border">
              <input type="text" id="orgName" name="orgName" onChange={this.onChange} value={orgName} placeholder="Organisation Name" />
            </div>}

            {process === "addAdmin" && <div className="adminTitle">
              Invite New User to be Adminstrator
            </div>}

            {process === "addCoach" && <div className="adminTitle">
              Invite New User to be a coach
            </div>}

            <div className="input_parent input_border">
              <input type="text" id="userName" name="userName" onChange={this.onChange} value={userName} placeholder="Name Of Administrator" />
            </div>

            <div className="input_parent input_border">
              <input type="email" autoComplete="off" id="emailOne" name="emailOne" onChange={this.onChange} value={emailOne} placeholder="Enter Valid Email Address" />
            </div>

            <div className="input_parent input_border">
              <input type="email" autoComplete="off" id="emailTwo" name="emailTwo" onChange={this.onChange} value={emailTwo} placeholder="Confirm  Email Adddress" />
            </div>

            {(errors && errors.error) &&
              <div className="error-warning">
                *  {errors.error}
              </div>
            }
            <div className='organisation-form-buttons'>
              {!existingOrg && <div className="small_org_button_parent" style={{ marginTop: 50, marginBottom: 30 }}>
              <div className="small_org_button" onClick={(e) => { this.createOrg(e) }}>
                <Button value={"CREATE"}/>
              </div>
            </div>}

            <div className="right">
              {!existingOrg && <div className="cancel-new-org-parent">
                <div className="cancel-new-org-button" onClick={(e) => { this.reset(e) }}>
                <Button value={"CLEAR"} warning={true}/>
                </div>
              </div>}
            </div>
            </div>
            

            {existingOrg && process === "addAdmin" && <div className="small_org_button_parent" style={{ marginTop: 50, marginBottom: 30 }}>
              <div className="small_org_button" style={{ width: 250 }} onClick={(e) => { this.addAdmin(e) }}>
                <Button value={"Add New Administrator"}/>
              </div>
            </div>}

            {existingOrg && process === "addCoach" && <div className="small_org_button_parent" style={{ marginTop: 50, marginBottom: 30 }}>
              <div className="small_org_button" style={{ width: 250 }} onClick={(e) => { this.addAdmin(e) }}>
                <Button value={"Add New Coach"}/>
              </div>
            </div>}
          </div>
        </div>

      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
      userList: selectUserList(state),
      user:selectUser(state),
      };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({getOrganisationsToolkit,resetOrganisationDetailView,getUsers,addAdminToOrg,addCoachToOrg,createNewOrg}, dispatch),
});






export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSetup);

