import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginAPI, signUpAPI } from '../api/auth';
import Cookies from 'js-cookie';
import { getOrganisationsToolkit } from './orgSlice';

const initialState = {
  isAuthenticated: false,
  user: {},
  userLayout: "",
  authenticationError: null,
  awaitingEmailVerification: false,
  loginInProgress: false,
  loading:false
};


export const login = createAsyncThunk('auth/login', async (data, { dispatch }) => {

  const loginData = {
    email: data.identifier,
    password: data.password,
  };
  const response = await loginAPI(loginData);
  if (response && response.data && response.data.token) {
    localStorage.setItem('user', JSON.stringify(response.data));
    localStorage.setItem('userLayout', response.data.layout);
    Cookies.set('id_token', response.data.token);
    const newUserId = response.data.id;
    dispatch(setCurrentUser(response.data));
    dispatch(getOrganisationsToolkit(newUserId));
    return response.data;
  }else {
    throw new Error(response?.data?.description || 'Unknown error');
  }

  
});


export const signup = createAsyncThunk('auth/signup', async (data, { dispatch }) => {
  try {
    const signUpData = {
      email: data.identifier,
      password: data.password,
      fullname: data.fullname,
      username: data.identifier,
      inviteCode: data.invitationcode,
    };
    dispatch(signupStarted());
    const res = await signUpAPI(signUpData);
    if (res.data.errorID) {
      dispatch(signupFailure(res.data));
    } else {
      dispatch(signupSuccess());
    }
  } catch (error) {
    dispatch(signupFailure(error.response?.data?.error?.message || 'Signup error'));
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.userLayout = action.payload.layout;
      state.authenticationError = null;
      state.awaitingEmailVerification = false;
    },
    checkAuth: (state) => {
      const user = JSON.parse(localStorage.getItem('user'));
      const userLayout = JSON.parse(localStorage.getItem('userLayout'));
      if (user) {
        state.isAuthenticated = true;
        state.user = user;
        state.userLayout = userLayout;
      }
    },
    clearAuth: (state) => {
      localStorage.removeItem('user');
      localStorage.removeItem('userLayout');
      Cookies.remove('id_token');

      state.isAuthenticated = false;
      state.user = {};
      state.userLayout = "";
      state.authenticationError = null;
      state.awaitingEmailVerification = false;
      state.loginInProgress = false;
    },
    clearErrors: (state) => {
      state.authenticationError = null;
      state.awaitingEmailVerification = false;
      state.loginInProgress = false;
    },
    loginStarted: (state) => {
      state.loginInProgress = true;
      state.authenticationError = null;
    },
    loginSuccess: (state) => {
      state.loginInProgress = false;
      state.authenticationError = null;
    },
    loginFailure: (state, action) => {
      state.loginInProgress = false;
      state.authenticationError = action.payload;
    },
    signupStarted: (state) => {
      state.loginInProgress = true;
      state.authenticationError = null;
    },
    signupSuccess: (state) => {
      state.loginInProgress = false;
      state.authenticationError = null;
      state.awaitingEmailVerification = true;
    },
    signupFailure: (state, action) => {
      state.loginInProgress = false;
      state.authenticationError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
      state.userLayout = action.payload;
      state.isAuthenticated = true;
      state.loading = false;
    }).addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.userLayout = "";
      state.error = action.error.message;
      state.authenticationError = true;
    }).addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signup.fulfilled, () => {
      // Handle additional action after signup succeeded
    });
  },
});

export const { setCurrentUser,clearErrors, clearAuth, signupStarted, checkAuth, loginStarted, loginSuccess, loginFailure, signupSuccess, signupFailure } = authSlice.actions



export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;
export const selectUserLayout = (state) => state.auth.userLayout;
export const selectAuthenticationError = (state) => state.auth.authenticationError;
export const selectAwaitingEmailVerification = (state) => state.auth.awaitingEmailVerification;
export const selectLoginInProgress = (state) => state.auth.loginInProgress;
export const userState = (state) => state.auth