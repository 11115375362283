/* eslint-disable */
import { Brush, ComposedChart, LineChart, ResponsiveContainer } from "recharts";
import { connect } from "react-redux";
import { selectSession } from "store/sessionSlice";
import { useEffect, useState } from "react";

function MetricTrimBrush(props) {
  const { sessionList, setGraphTrim, sessionID, graphDomain, isTrimOpen } = props;


  let data = [];
  let dataTest = [];
  if (
    sessionList?.runData?.loaded &&
    sessionList?.runData?.sessionID === sessionID
  ) {
    if (
      sessionList?.runData?.data?.capacitanceData &&
      Object.keys(sessionList.runData.data.capacitanceData).length > 0
    ) {
      data = sessionList?.runData?.data?.capacitanceData?.filter(
        (item) => item["Time"]
      );

      //removed when triming data
      // if (graphDomain) {
      //   data = data.filter(
      //     (item) =>
      //       item["Time"] > graphDomain[0] && item["Time"] < graphDomain[1]
      //   );
      // }
    }

    //generates data for the timeline of the brush
    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        dataTest.push({ Time: data[i]["Time"] });
      }
    }
  }

  if (
    sessionList?.jumpData?.loaded &&
    sessionList?.jumpData?.sessionID === sessionID
  ) {
    if (
      sessionList?.jumpData?.data?.capacitanceData &&
      Object.keys(sessionList.jumpData.data.capacitanceData).length > 0
    ) {
      data = sessionList?.jumpData?.data?.capacitanceData?.filter(
        (item) => item["Time"]
      );
    }
    //generates data for the timeline of the brush
    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        dataTest.push({ Time: data[i]["Time"] });
      }
    }
  }

  return (
    <div className="metric-trim-brush">
      <ResponsiveContainer height={50}>
        <LineChart data={sessionList?.runData?.data?.capacitanceData}>
          <Brush
            onChange={(e) => {   
              setGraphTrim([
                dataTest?.[e.startIndex]?.["Time"],
                dataTest?.[e.endIndex -5]?.["Time"],
              ])
            }}
            startIndex={ props?.graphDomain?.[0] && sessionList?.runData?.data?.capacitanceData?.findIndex(p => p?.['Time'] === props?.graphDomain?.[0])} 
            endIndex={ props?.graphDomain?.[1] && sessionList?.runData?.data?.capacitanceData?.findIndex(p => p?.['Time'] === props?.graphDomain?.[1])}
            height={40}
            travellerWidth={15}
            stroke={"rgba(30, 151, 214, 0.2)"}
            fill={"transparent"}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sessionList: selectSession(state),
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MetricTrimBrush);
