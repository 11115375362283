// InfoIcon.jsx

const InfoIcon = ({ fillColor = 'white', size="24", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={fillColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="9" />
      <path d="M12 15v-5" />
      <path d="M12 7h0.01" />
    </svg>
  );
};

export default InfoIcon;
