import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
//import PropTypes from 'prop-types';
import { clearAuth, selectAuthenticationError } from "store/authSlice";
import { validateEmail } from "../api/auth";
import logo from "../assets/images/DanuTitle.png";
import { LOGIN_URL } from "../utils/urls";
import LoginInput from "./LoginInput";
import Button from "./common/button/button";
import { userState, signup } from "store/authSlice";

class SignUpForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      identifier: "",
      password: "",
      confirmpassword: "",
      emailVerified: 0,
      invitationcode: "",
      fullname: "",
      errors: {},
      buttonDisabled: false,
    };

    this.register = this.register.bind(this);
    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
    this.regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  }

  UNSAFE_componentWillMount() {
    let emailConfirmCode = this.props.match.params.cc;
    if (emailConfirmCode) {
      validateEmail(emailConfirmCode).then((res) => {
        if (res.data.confirmed) {
          this.setState({ emailVerified: 1 });
        } else {
          this.setState({ emailVerified: -1 });
        }
      });
    }
  }

  goToLogIn() {
    this.props.actions.clearAuth();
    this.props.history.push(LOGIN_URL);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  register(e) {
    e.preventDefault();

    if (this.validateUserInputs()) {
      this.props.actions.register(this.state);
    }
  }

  login(e) {
    e.preventDefault();
    if (this.props.signuperrors?.description) {
      this.props.actions.clearAuth();
    }
    if (this.validateUserInputs()) {
      this.setState({ buttonDisabled: true });
      this.props.actions.signup(this.state);
    }
  }

  isValidEmail(email) {
    return this.regex.test(email);
  }

  validateUserInputs() {
    const errors = {};
    errors.formErrors = false;
    errors.error = "";
    if (!this.state.fullname || this.state.fullname.length === 0) {
      errors.error = "You must enter your fullname";
      errors.formErrors = true;
    } else if (!this.state.identifier || this.state.identifier.length === 0) {
      errors.error = "You must enter an email";
      errors.formErrors = true;
    } else if (!this.isValidEmail(this.state.identifier)) {
      errors.error = "You must enter a valid email";
      errors.formErrors = true;
    } else if (!this.state.password || this.state.password.length === 0) {
      errors.error = "You must enter a password";
      errors.formErrors = true;
    }
    // else if ((!this.state.invitationcode) || (this.state.invitationcode.length === 0)) {
    //   errors.error = 'You must enter an invitationcode';
    //   errors.formErrors = true;
    // }
    // else if (this.state.invitationcode[0] !== this.state.invitationcode[0].toUpperCase()) {
    //   errors.error = 'The first letter of the invitation code must be capitalized';
    //   errors.formErrors = true;
    // }
    else if (
      !this.state.confirmpassword ||
      this.state.confirmpassword.length === 0
    ) {
      errors.error = "You must confirm your password";
      errors.formErrors = true;
    } else if (this.state.confirmpassword !== this.state.password) {
      errors.error = "Passwords must match";
      errors.formErrors = true;
    }

    this.setState({ errors });
    return !errors.formErrors;
  }

  scrollToTop() {
    setTimeout(() => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 100);
  }

  render() {
    // deconstruct variables from state
    const {
      identifier,
      password,
      confirmpassword,
      fullname,
      errors,
      emailVerified,
    } = this.state;
    const { user, signuperrors } = this.props;

    if(signuperrors && this.state.buttonDisabled === true){
      this.setState({ buttonDisabled: false });
    }

    if (user.awaitingEmailVerification) {
      this.scrollToTop();
      return (
        <div className="login-container">
          <img src={logo} className="rightLogo" alt="rightlogo" />

          <div className="login-title">
            Check your email to Complete Sign-up
          </div>
          {/* Removed as it may confuse user when creating account, they may not read the instructions */}
          {/* <div onClick={() => {this.goToLogIn();}}>
                    <Button value={"Return to Login"} />
              </div> */}
        </div>
      );
    }

    let emailConfirmCode = this.props.match.params.cc;
    if (emailConfirmCode) {
      this.scrollToTop();

      if (emailVerified === 0) {
        return <div />; //waiting for api response
      } else if (emailVerified === -1) {
        return (
          <div className="login-container">
            <img src={logo} className="rightLogo" alt="rightlogo" />

            <div className="login-title">Invalid Code</div>

            <div
              onClick={() => {
                this.goToLogIn();
              }}
            >
              <Button value={"Return to Login"} />
            </div>
          </div>
        );
      } else if (emailVerified === 1) {
        return (
          <div className="login-container">
            <img src={logo} className="rightLogo" alt="rightlogo" />

            <div className="login-title">Your email has been validated!</div>

            <div
              onClick={() => {
                this.goToLogIn();
              }}
            >
              <Button value={"Return to Login"} />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="login-container ">
          {/* <div className="login-container login-input-container"> */}

          <div className="logo-container">
            <img
              src={logo}
              className="rightLogo"
              alt="Recently updated indicator"
            />
          </div>

          <LoginInput
            title={"Full name"}
            id={"fullname"}
            onChange={this.onChange}
            value={fullname}
            type="text"
            placeholder={"Full Name"}
          />
          <LoginInput
            title={"Email"}
            id={"identifier"}
            onChange={this.onChange}
            value={identifier}
            type="text"
            placeholder={"Email"}
          />
          <LoginInput
            title={"Password"}
            id={"password"}
            onChange={this.onChange}
            value={password}
            type="password"
            placeholder={"Password"}
          />
          <LoginInput
            title={"Confirm Password"}
            id={"confirmpassword"}
            onChange={this.onChange}
            value={confirmpassword}
            type="password"
            placeholder={"Confirm Password"}
          />
          {/* <LoginInput  title={'Invitation Code'} id={'invitationcode'} onChange={this.onChange} value={invitationcode} type='text' placeholder={'Invitation Code'}/> */}

          {signuperrors && (
            <div className="login-error">{signuperrors?.description}</div>
          )}

          {errors && errors?.formErrors === true && (
            <div className="login-error">{errors?.error}</div>
          )}

          <div
            className="signup-button"
            onClick={(e) => {
              !this.state.buttonDisabled
                ? this.login(e)
                : console.log("Button Disabled");
            }}
          >
            <Button value={"Create Account"} />
          </div>

          <div className="login-extra">
            {/* <Link className="login-extra-link LoginLink" to={LOGIN_URL}>Login</Link>      */}
            <div className="existing-user-login">
              Existing User?{" "}
              <span
                className="login-extra-link"
                onClick={() => {
                  this.goToLogIn();
                }}
              >
                {" "}
                Login Here
              </span>
            </div>
          </div>

          <div className="signup-extra">
            By creating an account you are agreeing to these{" "}
            <span className="signup-extra-terms">terms and conditions</span>
          </div>
        </div>
      );
    } //end normal sign up  (i.e not confirm code)
  }
}

const mapStateToProps = (state) => {
  return {
    user: userState(state),
    signuperrors: selectAuthenticationError(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ clearAuth, signup }, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
);
