/* eslint-disable */
import MainTooltip from "../mainTooltip/MainTooltip";
import "./uploadingIndicationButton.scss";

const UploadingIndicationButton = (props) => {
  const { clickHandler, uploadStatus, tooltipText, uploadInProgress, whichIndexUploadInit, index} = props;

  console.log(uploadStatus)

  let colorBasedOnStatus = "#ecf0f1";

  let uploadIcon = "upload"

  //handling icon change on loading 
  if(uploadStatus.bilateral){

    if(uploadStatus.statusLeft === 0 && uploadStatus.statusRight === 0 && whichIndexUploadInit === index){ //and in here equal
      uploadIcon = "loading"
      colorBasedOnStatus = "#f1c40f"
    }

    if(uploadStatus.statusLeft > 0 || uploadStatus.statusRight > 0 &&
        (uploadStatus.statusLeft !== 3 && uploadStatus.statusRight !== 3)){
        uploadIcon = "loading"
        colorBasedOnStatus = "#f1c40f"
    }
    if(uploadStatus.statusLeft === 3 && uploadStatus.statusRight === 3){
        uploadIcon = "complete"
        colorBasedOnStatus = "#1dd1a1"
    }
  }else{
    if(uploadStatus.statusLeft > 0 || uploadStatus.statusRight > 0){
        uploadIcon = "loading"
        colorBasedOnStatus = "#f1c40f"
    }
    if(uploadStatus.statusLeft === 3 || uploadStatus.statusRight === 3){
        uploadIcon = "complete"
        colorBasedOnStatus = "#1dd1a1"
    }
  }

  if(uploadStatus.failed){
    colorBasedOnStatus = "#e74c3c"
    uploadIcon = "warning"
  }

  console.log(props);
  return (
    <div onClick={() => clickHandler()} className="upload-button">
      <MainTooltip
        tooltipIcon={uploadIcon}
        tooltipText={tooltipText}
        iconColor={colorBasedOnStatus}
      />
    </div>
  );
};

export default UploadingIndicationButton;
