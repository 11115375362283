/* eslint-disable */

import { useRef, useState } from "react";
import "./orgProfileBar.scss";
import { postFile } from "api/apiHelper";

export default function OrgProfileBar(props) {
  const { orgData, orgName, orgImage, orgAddButton, orgDeactivateButton, userPermissions, handleUploadIconRecall } =
    props;

  const orgInitials = orgName
    .split(" ")
    .map((name) => name[0])
    .join("");
  const orgNameSplit = orgName.split(" ");

  const orgCoachCount = orgData.coaches.length;
  const orgAdminCount = orgData.admins.length;
  const orgPlayerCount = orgData.players.length;
  const orgPodCount = orgData.pods.length;


  const [file, setFile] = useState();


  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);

      var image = document.getElementById("orgIcon");
      image.src = URL.createObjectURL(event.target.files[0]);
      setTimeout(()=> {
        addIcon(orgData.OrgID)
      }, 1000)
    }
  };

  const fileInputRef = useRef(null);

  const triggerFileInput = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  function addIcon(orgID) {
    const fileState = file;
    let url = `/organisation/addImage/${orgID}`;

    postFile(url, fileState).then((res) => {
      if (res.data.updated) {
        // success
        console.log(res)
        //handleUploadIconRecall();
      } else {
        // failure
        console.log(res)
      }
    });
  }

  return (
    <div className="org-profile-bar">
      <div className="org-profile">
        <div className="org-profile-image" id="orgIcon" onClick={userPermissions === 1 ? triggerFileInput : null}>
          {orgImage ? (
            <img src={orgImage} alt="organisation initals" />
          ) : (
            <p>{orgInitials}</p>
          )}
        </div>
        <div className="file-select-parent">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
        </div>
        <div className="org-name">
          <div className="org-profile-name">
            <h2>
              {orgNameSplit[0]}
              <br />
              {orgNameSplit[1]}
              <br />
              {orgNameSplit[2]}
            </h2>
          </div>
          <div className="org-profile-location">
            {/* <h3>
                            Org Location, City
                        </h3> */}
          </div>
        </div>
      </div>

      <div className="org-stats">
        <div className="org-stat">
          <h4>ADMINS</h4>
          <p>{orgAdminCount}</p>
        </div>

        <div className="org-stat">
          <h4>PLAYERS</h4>
          <p>{orgPlayerCount}</p>
        </div>

        <div className="org-stat">
          <h4>PODS</h4>
          <p>{orgPodCount}</p>
        </div>

        <div className="org-stat">
          <h4>COACHES</h4>
          <p>{orgCoachCount}</p>
        </div>
      </div>

      <div className="org-edit">
        <div>{orgAddButton}</div>
        <div>{orgDeactivateButton}</div>
      </div>
    </div>
  );
}
