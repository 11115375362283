import "../../styles/stance_stride_ratio.scss";
import {
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_HIGH_COLOR,
  NEW_MID_COLOR,
  NEW_LOW_COLOR,
  NEW_ERROR_RANGE_COLOR,
} from "../../utils/constant";
import {
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Scatter,
  ErrorBar,
  Legend,
} from "recharts";
import MetricTable from "./metric-helpers/MetricTable";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSessionId, setGraphModal } from "store/playerActivitySlice";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

export default function StanceToStrideRatio(props) {
  const dispatch = useDispatch();
  const { data, setGraphIsOpen, setPosition, graphIsOpen, toggleGraph } = props;
  const style = { height: "160px", padding: "0px 0px 10px 0px" };
  const [tooltipData, setTooltipData] = useState([]);
  const { graphModal } = useSelector((state) => state.playerActivity);

  const [activeData, setActiveData] = useState({ locked: false });

  function handleClick(sessionID) {
    dispatch(setSelectedSessionId(sessionID));
    dispatch(setGraphModal(!graphModal));
    setPosition(window.pageYOffset);
  }

  const modifiedData = { High: [], Medium: [], Low: [] };

  //multiplaying x2 before asigning it in to the graphing range
  data.filter((item) => {
    item.cadence * 2 <= 110
      ? (modifiedData.Low = [item, ...modifiedData.Low])
      : item.cadence * 2 > 110 && item.cadence * 2 <= 180
      ? (modifiedData.Medium = [item, ...modifiedData.Medium])
      : (modifiedData.High = [item, ...modifiedData.High]);
  });

  // const CustomTooltip = ({ active, payload, heading = "Heading", accentColor = "white"}) => {

  //     const style = {color: accentColor}
  //     if (active && payload && payload.length) {
  //       return (
  //         <div className="custom-tooltip-stance">
  //             <p className='tooltip-label'>{heading}</p>
  //             <p style={style}>{`${payload[0].name} : ${payload[0].value.toFixed(2)}`}</p>
  //             <p style={{color: NEW_ERROR_RANGE_COLOR}}>{`Standard Deviation : ${payload[0].payload.stdSSR.toFixed(2)}`}</p>
  //         </div>
  //       );
  //     }

  //     return null;
  // }

  function GraphTableData(props) {
    const { payload } = props;
    if (
      activeData.locked === false &&
      payload.length > 0 &&
      JSON.stringify(payload) !== JSON.stringify(tooltipData)
    ) {
      setTimeout(() => {
        setTooltipData(payload);
      }, 100);
    }
  }

  const displayLegend = (data) => {
    return (
      <div className="metric-legend-horizontal" style={style}>
        <ResponsiveContainer width="85%">
          <ComposedChart
            width={500}
            height={200}
            data={data.Medium}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {modifiedData.High.length > 0 && (
              <Line
                name="High Cadence"
                dataKey="null123"
                stroke={NEW_HIGH_COLOR}
                strokeDasharray="5 5"
                opacity={0.1}
                legendType="circle"
              />
            )}
            {modifiedData.Medium.length > 0 && (
              <Line
                name="Medium Cadence"
                dataKey="null123"
                stroke={NEW_MID_COLOR}
                strokeDasharray="5 5"
                opacity={0.1}
                legendType="circle"
              />
            )}
            {modifiedData.Low.length > 0 && (
              <Line
                name="Low Cadence"
                dataKey="null123"
                stroke={NEW_LOW_COLOR}
                strokeDasharray="5 5"
                opacity={0.1}
                legendType="circle"
              />
            )}
            <Line
              name="Standard Deviation"
              dataKey="null123"
              stroke={NEW_ERROR_RANGE_COLOR}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="cross"
            />
            <Legend
              verticalAlign="top"
              align="right"
              layout="horizontal"
              wrapperStyle={{ top: -10, right: 0, fontSize: 11 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const displayGraph = (data, color, yLabel = "Cadence") => {
    return (
      <div style={style}>
        <ResponsiveContainer width="95%" height="95%">
          <ComposedChart
            width={500}
            height={200}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            onClick={(e) => {
              if (
                activeData.locked === false &&
                JSON.stringify(setTooltipData) !==
                  JSON.stringify(e.activePayload)
              ) {
                if (e && e.activePayload) {
                  setActiveData({ locked: true });
                  setTooltipData(e.activePayload);
                  setGraphIsOpen(true);
                }
              } else if (activeData.locked === true) {
                setActiveData({ locked: false });
              }
            }}
          >
            <CartesianGrid
              strokeDasharray="4 4"
              stroke={NEW_AXIS_COLOR}
              strokeWidth={1}
              opacity={0.4}
            />
            <XAxis
              dataKey="sessionID"
              tick={{ fill: NEW_AXIS_TICK }}
              stroke={NEW_AXIS_COLOR}
              padding={{ left: 40 }}
              interval={data.length > 10 ? 3 : 0}
              tooltipshow={false}
            />

            <YAxis
              dataKey="meanSSR"
              tick={{ fill: NEW_AXIS_TICK }}
              stroke={NEW_AXIS_COLOR}
              label={{
                value: yLabel,
                angle: -90,
                position: "left",
                offset: 0,
                fill: "white",
                fontSize: "14px",
                dy: -45,
                dx: 10,
              }}
            />

            <Scatter
              strokeWidth={1}
              dataKey="meanSSR"
              stroke="none"
              fill={color}
              legendType="none"
            >
              <ErrorBar
                dataKey="stdSSR"
                width={6}
                strokeWidth={1}
                stroke={NEW_ERROR_RANGE_COLOR}
                direction="y"
              />
            </Scatter>

            {/* <Tooltip 
                                    content={
                                    <CustomTooltip 
                                        heading = 'High Swing Stance Ratio'
                                        accentColor = {color}
                                    />}
                                /> */}

            <Tooltip
              cursor={{ fill: "NEW_AXIS_COLOR", opacity: ".3" }}
              // content={<GraphTableData data={chartData} component={component}/>}
              content={<GraphTableData data={data} />}
              active="false"
            />
            <Line
              type="linear"
              dataKey="meanSSR"
              stroke={color}
              fill={color}
              connectNulls={false}
              legendType="none"
              activeDot={{
                r: 5,
              }}
            ></Line>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className={"column activity-overview "}>
      <div className="activity-overview-chart-container">
        <div
          className="player-profile-analytics-title"
          style={{
            margin: "0 0 0 75px",
            padding: "20px 0 20px 0",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              display: "flex",
              position: "relative",
              textTransform: "uppercase",
            }}
          >
            Duty Factor
          </p>
          <div style={{ paddingLeft: "0.5rem" }}>
            <MainTooltip
              tooltipText={
                "The duty factor is a representation of how long on average the foot is in contact with the ground during each stride. A higher value would indicate a more explosive propulsive force, with a longer swing time. The stance to stride ratio has been grouped here based on the average cadence in each session."
              }
              tooltipIcon={"info"}
              iconSize={25}
            />
          </div>
        </div>
        <div style={{ width: "100%", height: "550px" }}>
          <div className="metric-chart-container-parent">
            <div className="chart-container-padding">
              <div className="chart-container-with-table">
                <div className="chart-container-without-table">
                  <div className="legend" style={{ height: "20px" }}>
                    {displayLegend(modifiedData)}
                  </div>

                  {modifiedData.High.length > 0 &&
                    displayGraph(
                      modifiedData.High,
                      NEW_HIGH_COLOR,
                      "Duty Factor",
                      "top"
                    )}

                  {modifiedData.Medium.length > 0 &&
                    displayGraph(
                      modifiedData.Medium,
                      NEW_MID_COLOR,
                      "Duty Factor",
                      "mid"
                    )}

                  {modifiedData.Low.length > 0 &&
                    displayGraph(
                      modifiedData.Low,
                      NEW_LOW_COLOR,
                      "Duty Factor",
                      "bot"
                    )}

                  <div className="graph-x-label">
                    <p>Session ID</p>
                  </div>
                </div>
                <MetricTable
                  graphName={"Duty Factor"}
                  tableData={tooltipData}
                  graphIsOpen={graphIsOpen}
                  toggleGraph={toggleGraph}
                  openSession={handleClick}
                  sessionLocked={activeData.locked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
