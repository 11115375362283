import { getUnit } from "components/profileFunctions/profileFunctions";
import { getHistoricalChartsTitle } from "utils/helper";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

function SLCJHEADER({
  averageLeftSingleCounter,
  averageRightSingleCounter,
  activity,
  averageAsym,
}) {
  return (
    <>
      <div className="chart-container-header">
        <div className="single-leg-header-details">
          <div className="single-leg-header-details_left">
            <div className="single-detail-container">
              <div className="single-detail-left-title"> Left </div>

              <div className="single-detail-padding">
                {averageLeftSingleCounter.toFixed(1)}
                {getUnit(activity)}
                <div style={{ paddingLeft: "0.5rem" }}>
                  <MainTooltip
                    tooltipText={`Average Left Single Leg ${getHistoricalChartsTitle(
                      activity.id
                    )} (cm)`}
                    tooltipIcon={"info"}
                    iconSize={25}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="single-leg-header-details_center">
            <div className="single-detail-container">
              {averageAsym > 0 && (
                <div className="single-detail-padding single-detail-right-title asym-foot">
                  {" "}
                  Right{" "}
                </div>
              )}

              {averageAsym < 0 && (
                <div className="single-detail-padding single-detail-left-title">
                  <span className="asym-val">Left</span>
                </div>
              )}
              <div className="single-detail-padding">
                {" "}
                {averageAsym < 0 ? averageAsym * -1 : averageAsym}%{" "}
                <div style={{ paddingLeft: "0.5rem" }}>
                  <MainTooltip
                    tooltipText={`Average Jump Percentage Asymmetry`}
                    tooltipIcon={"info"}
                    iconSize={25}
                  />
                </div>
              </div>

              {averageAsym === 0 && (
                <div className="single-detail-padding"> 0% </div>
              )}
            </div>
          </div>

          <div className="single-leg-header-details_right">
            <div className="single-detail-container">
              <div className="single-detail-right-title"> Right </div>

              <div className="single-detail-padding">
                {averageRightSingleCounter.toFixed(1)}
                {getUnit(activity)}
                <div style={{ paddingLeft: "0.5rem" }}>
                  <MainTooltip
                    tooltipText={`Average Right Single Leg ${getHistoricalChartsTitle(
                      activity.id
                    )} (cm)`}
                    tooltipIcon={"info"}
                    iconSize={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SLCJHEADER;
