const AddAthleteIcon = (props) => {
  const { size, color } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8C9.20914 8 11 6.20914 11 4C11 1.79086 9.20914 0 7 0C4.79086 0 3 1.79086 3 4C3 6.20914 4.79086 8 7 8ZM18 3H17V2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2V3H14C13.4477 3 13 3.44772 13 4C13 4.55229 13.4477 5 14 5H15V6C15 6.55229 15.4477 7 16 7C16.5523 7 17 6.55229 17 6V5H18C18.5523 5 19 4.55229 19 4C19 3.44772 18.5523 3 18 3ZM14 17C14 17.5523 13.5523 18 13 18H1C0.447715 18 0 17.5523 0 17C0 13.134 3.13401 10 7 10C10.866 10 14 13.134 14 17Z"
        fill={color}
      />
    </svg>
  );
};

export default AddAthleteIcon;
