//import Notifications from "../nav-icons/notifications";

export default function NavProfile(props) {
    
    const {dropDownState, user, userProfileIcon} = props;

    //variable containing the users initials, to be added on user profile when an image is non existant
    const userInitials = user.fullname.split(" ").map(item => item[0]).join("");

    return (
        <div className="nav-profile">
            {/* {
                <Notifications 
                    size="24"
                />
            } */}
           
            {
                <div> 
                      {userProfileIcon ? 
                        <img className="nav-profile-image dropbtn" onClick={dropDownState} src={userProfileIcon} alt="profile"/>: 
                        <div className="nav-profile-image dropbtn" onClick={dropDownState}>
                            <p className="dropbtn">{userInitials}</p>
                        </div>
                       } 
                    
                        {/* <img className="nav-profile-image" src={userIcon} alt="profile" /> :  */}
                        {/* <div className="nav-profile-image dropbtn" onClick={dropDownState}>
                            <p className="dropbtn">{userInitials}</p>
                        </div> */}
                        { /* <img className="nav-profile-image" src={image} alt="profile" /> */}
                        {/* <div className="nav-profile-image">
                            <p>{userInitials}</p>
                        </div> */}
                </div>
               
            }
       
           
        </div>
    )
}