/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "react-vis/dist/style.css";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
  NEW_BOTH_FEET_JUMP_COLOR,
} from "../../utils/constant";
import {
  CustomTooltipForAsymmetry,
  CustomTooltipForJumpCharts,
  getSymValue,
  CustomTooltipV2,
  gctMax,
  gctMin,
  getAverage,
  weHaveDataForThisFoot,
} from "../../utils/metrics";
import DownArrow from "../../components/common/icons/downArrow";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import MaxMinMean from "./metric-helpers/MaxMinMean";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

function formatXAxis(value) {
  return Math.abs(Math.round(value * 100) / 100);
}

class PowerJump extends React.Component {
  constructor(props) {
    super(props);

    let leftData = [];
    let rightData = [];

    this.state = {
      //hidden: true,
      hidden: false, //default to NOT be hidden - ie show when page loads!!!!!
      leftData: leftData,
      rightData: rightData,
      data: [],
      barData: [],
      averagebarData: [],
      //hovered: false,
      hoveredLeft: null,
      hoveredRight: null,
      loading: false,
      legState: "right",
    };
    //window.addEventListener('resize', this.handleWindowSize.bind(this))
  }

  UNSAFE_componentWillMount() {
    //this.handleWindowSize()
  }

  componentDidMount() {
    const { sessionList } = this.props;
    if (
      sessionList?.jumpData?.data?.flightData?.symmetryChartData?.[0]
        ?.Symmetry < 0
    ) {
      this.setState({ legState: "left" });
    }
  }

  filterAverageData(arr, filterValue) {
    if (this.props.graphDomain) {
      return arr.filter(
        (item) =>
          item[filterValue] >= this.props.graphDomain[0] &&
          item[filterValue] <= this.props.graphDomain[1]
      );
    } else {
      return arr;
    }
  }

  toggleChart() {
    const { hidden } = this.state;

    this.setState({ hidden: !hidden });
  }

  leftHover = (v) => {
    this.setState({
      hoveredLeft: { v, name: "Foot: left" },
      hoveredRight: null,
    });
  };

  removeLeftHover = () => {
    this.setState({
      hoveredLeft: null,
      hoveredRight: null,
    });
  };

  rightHover = (v) => {
    this.setState({
      hoveredRight: { v, name: "Foot: right" },
      hoveredLeft: null,
    });
  };

  removeRightHover = () => {
    this.setState({
      hoveredRight: null,
      hoveredLeft: null,
    });
  };

  displayCharts() {
    const { hidden } = this.state;
    const { sessionList } = this.props;

    if (!hidden && sessionList.jumpData.loaded === false)
      return displayLoadingCharts();
    else if (
      !hidden &&
      (sessionList.jumpData.data &&
        sessionList.jumpData.data.powerData &&
        sessionList.jumpData.data.powerData.bar &&
        sessionList.jumpData.data.powerData.bar.length > 0) !== true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (
      !hidden &&
      sessionList.jumpData.data &&
      sessionList.jumpData.data.powerData
    ) {
      return (
        <div className="metric-container">
          <div className="metric-chart-container-parent ">
            <div className="chart-container-padding">
              <div className="peak-power-grid">
                <div
                  className={
                    sessionList.jumpData.data.powerData.symDataExists
                      ? "peak-power"
                      : "peak-power-full"
                  }
                >
                  {sessionList.jumpData.data.powerData.leftDataExists &&
                    sessionList.jumpData.data.powerData.rightDataExists && (
                      // <MaxMinMean
                      //   leftFoot={{
                      //     hasData:
                      //       sessionList.jumpData.data.powerData.leftDataExists,
                      //     min: Math.round(
                      //       sessionList.jumpData.data.powerData.leftMin
                      //     ),
                      //     max: Math.round(
                      //       sessionList.jumpData.data.powerData.leftMax
                      //     ),
                      //     mean: Math.round(
                      //       sessionList.jumpData.data.powerData.leftMean
                      //     ),
                      //   }}
                      //   rightFoot={{
                      //     hasData:
                      //       sessionList.jumpData.data.powerData.rightDataExists,
                      //     min: Math.round(
                      //       sessionList.jumpData.data.powerData.rightMin
                      //     ),
                      //     max: Math.round(
                      //       sessionList.jumpData.data.powerData.rightMax
                      //     ),
                      //     mean: Math.round(
                      //       sessionList.jumpData.data.powerData.rightMean
                      //     ),
                      //   }}
                      // />

                      <MaxMinMean
                        leftFoot={{
                          hasData:
                            sessionList.jumpData.data.powerData.leftDataExists,
                          min: gctMin(
                            "left",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          ),
                          max: gctMax(
                            "left",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          ),
                          mean: getAverage(
                            "left",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          ),
                        }}
                        rightFoot={{
                          hasData:
                            sessionList.jumpData.data.powerData.rightDataExists,
                          min: gctMin(
                            "right",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          ),
                          max: gctMax(
                            "right",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          ),
                          mean: getAverage(
                            "right",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          ),
                        }}
                      />
                    )}

                  {sessionList.jumpData.data.powerData.bothFeetDataExists && (
                    <MaxMinMean
                      footSymmetry={{
                        hasData:
                          sessionList.jumpData.data.powerData
                            .bothFeetDataExists,
                        min: getSymValue(
                          gctMin(
                            "both",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          )
                        ),
                        max: getSymValue(
                          gctMax(
                            "both",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          )
                        ),
                        mean: getSymValue(
                          getAverage(
                            "both",
                            this.filterAverageData(
                              sessionList.jumpData.data.powerData.bar,
                              "ts"
                            )
                          )
                        ),
                      }}
                    />
                  )}
                  <div className="chart-container">
                    <div className="container-pdf-flex ">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={500}
                        height={300}
                        data={sessionList.jumpData.data.powerData.bar}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid
                          strokeDasharray="4 4"
                          stroke={NEW_AXIS_COLOR}
                          vertical={false}
                          strokeWidth={2}
                        />

                        {sessionList.jumpData.data.powerData.bar[0].hasOwnProperty(
                          "ts"
                        ) && sessionList.jumpData.data.td.length > 0 ? (
                          <XAxis
                            type="number"
                            domain={this.props.graphDomain}
                            dataKey={"ts"}
                            label={{
                              value: "Rep",
                              position: "insideBottom",
                              offset: 0,
                              fill: NEW_AXIS_TICK,
                            }}
                            allowDataOverflow={true}
                            stroke={NEW_AXIS_COLOR}
                            tick={false}
                          />
                        ) : (
                          <XAxis
                            dataKey="name"
                            label={{
                              value: "Rep",
                              position: "insideBottom",
                              offset: 0,
                              fill: NEW_AXIS_TICK,
                            }}
                            tick={{ fill: NEW_AXIS_TICK }}
                            stroke={NEW_AXIS_COLOR}
                          />
                        )}

                        <YAxis
                          label={{
                            value: "Power (w)",
                            angle: -90.1,
                            position: "insideLeft",
                            dy: 35,
                            fill: NEW_AXIS_TICK,
                          }}
                          tick={{ fill: NEW_AXIS_TICK }}
                          stroke={NEW_AXIS_COLOR}
                          domain={[
                            0,
                            (dataMax) => ((dataMax / 100) * 110).toFixed(0),
                          ]}
                        />
                        {/* <Tooltip /> */}
                        {/* <Legend wrapperStyle={{ position: 'relative', marginTop: '0px', marginLeft: '20px' }} margin={{ top: 20, left: 100, right: 0, bottom: 0 }} /> */}
                        <Legend
                          verticalAlign="top"
                          layout="vertical"
                          wrapperStyle={{ top: -60, right: 0 }}
                        />
                        <Tooltip
                          cursor={{ fill: NEW_AXIS_COLOR, opacity: 0.6 }}
                          content={
                            <CustomTooltipForJumpCharts
                              desc={" watts"}
                              data={sessionList.jumpData.data.powerData.bar}
                            />
                          }
                        />
                        {sessionList.jumpData.data.powerData.leftDataExists && (
                          <Bar
                            dataKey="Left Foot"
                            fill={NEW_LEFT_COLOR}
                            barSize={100}
                            opacity={0.7}
                          />
                        )}
                        {sessionList.jumpData.data.powerData
                          .rightDataExists && (
                          <Bar
                            dataKey="Right Foot"
                            fill={NEW_RIGHT_COLOR}
                            barSize={100}
                            opacity={0.7}
                          />
                        )}
                        {sessionList.jumpData.data.powerData
                          .bothFeetDataExists && (
                          <Bar
                            dataKey="Both Feet"
                            fill={NEW_BOTH_FEET_JUMP_COLOR}
                            barSize={100}
                            opacity={0.7}
                          />
                        )}
                        {getSymValue(
                          sessionList.jumpData.data.powerData.Mean
                        ) && (
                          <ReferenceLine
                            stroke={"white"}
                            strokeDasharray="5 5"
                            y={getSymValue(
                              sessionList.jumpData.data.powerData.Mean
                            )}
                          />
                        )}
                      </BarChart>
                    </ResponsiveContainer>
                    {this.props.toggleableTextArea}          
                    </div>
                  </div>
                </div>

                <div className="peak-power-asym">
                  {sessionList.jumpData.data.powerData.symDataExists && (
                    <MaxMinMean
                      footSymmetry={{
                        hasData:
                          sessionList.jumpData.data.powerData.symDataExists,
                        min: getSymValue(
                          sessionList.jumpData.data.powerData.symMin
                        ).toFixed(1),
                        max: getSymValue(
                          sessionList.jumpData.data.powerData.symMax
                        ).toFixed(1),
                        mean: getSymValue(
                          sessionList.jumpData.data.powerData.symMean
                        ).toFixed(1),
                      }}
                    />
                  )}

                  {sessionList.jumpData.data.powerData.symDataExists && (
                    <div className="chart-container asym-container">
                      <div className="container-pdf-flex ">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={300}
                          data={
                            sessionList.jumpData.data.powerData
                              .symmetryChartData
                          }
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                          stackOffset="sign"
                          layout="vertical"
                        >
                          <CartesianGrid
                            strokeDasharray="4 4"
                            stroke={NEW_AXIS_COLOR}
                            horizontal={false}
                            strokeWidth={2}
                          />

                          <XAxis
                            type="number"
                            tickFormatter={formatXAxis}
                            tick={{ fill: NEW_AXIS_TICK }}
                            stroke={NEW_AXIS_COLOR}
                            domain={this.props.xRange}
                            label={{
                              value: "Asymmetry %",
                              position: "insideBottom",
                              fill: NEW_AXIS_TICK,
                              offset: 0,
                            }}
                          />

                          {sessionList.jumpData.data.powerData.symmetryChartData[0].hasOwnProperty(
                            "ts"
                          ) && sessionList.jumpData.data.td.length > 0 ? (
                            <YAxis
                              type="number"
                              domain={this.props.graphDomain}
                              dataKey={"ts"}
                              stroke={NEW_AXIS_COLOR}
                              label={{
                                value: "Reps",
                                angle: -90,
                                position: "insideLeft",
                                dy: 20,
                                fill: NEW_AXIS_TICK,
                                offset: 30,
                              }}
                              allowDataOverflow={true}
                              tick={false}
                            />
                          ) : (
                            <YAxis
                              width={70}
                              dataKey="name"
                              type="category"
                              tick={{ fill: NEW_AXIS_TICK }}
                              stroke={NEW_AXIS_COLOR}
                              label={{
                                value: "Reps",
                                angle: -90,
                                position: "insideLeft",
                                dy: 20,
                                fill: NEW_AXIS_TICK,
                                offset: 30,
                              }}
                            />
                          )}

                          <Tooltip
                            cursor={{ fill: NEW_AXIS_COLOR, opacity: 0.6 }}
                            content={
                              <CustomTooltipForAsymmetry
                                data={
                                  sessionList.jumpData.data.powerData
                                    .symmetryChartData
                                }
                              />
                            }
                          />

                          <Bar dataKey="Symmetry" barSize={100} opacity={0.7}>
                            {sessionList.jumpData.data.powerData.symmetryChartData.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    entry["Symmetry"] < 0
                                      ? NEW_LEFT_COLOR
                                      : NEW_RIGHT_COLOR
                                  }
                                />
                              )
                            )}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      {/* {this.props.toggleableTextArea} */}
                      </div>
                      
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { hidden } = this.state;

    let arrowClass = "";
    if (!hidden) {
      arrowClass = "flip-div";
    }

    return (
      <div className="metric-container-parent peak-power">
        <div
          className="metric-container metric-title-div"
        >
          <div className="metric-title-left-padding" />

          <div className="metric-title-left-icon"></div>

          <div className="metric-title-left-padding" />

          <div
            className="metric-title-main-title"
            style={{ position: "relative", top: "-5px" }}
          >
            Peak Power (Flight Time) [W] &nbsp;
            <MainTooltip
                  tooltipText={
                    "Peak power is calculated based on flight time using Sayer’s equation"
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
            {/* <a data-tip data-for="registerTip" style={{ color: 'GrayText', textTransform: "lowercase" }} >
              <img src={toolTipIcon} style={{ width: '21px', height: '21px', position: 'relative', top: '6px' }} />
            </a>

            <ReactTooltip id="registerTip" place="bottom" effect="solid" type="info" clickable={false} backgroundColor='#1EBAD6'>
              <p style={{ display: "inline-block" }}>Peak power is calculated based on flight time using Sayer's equation</p>
            </ReactTooltip> */}
          </div>

          <div className="metric-title-extra-info">
            {/* <span>00:00:00</span> */}
          </div>

          <div className="metric-title-left-padding" />

          <div className="metric-title-left-icon">
          </div>
        </div>

        {this.displayCharts()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionList: state.session,
  };
}

export default connect(mapStateToProps)(PowerJump);
