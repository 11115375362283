import { Link } from 'react-router-dom';


const NotFoundPage = () => (
  <>
 <div className="notfound-container">
        <h1 className="notfound-title">Page not found</h1>
        <p className="notfound-subheading">
          The page you tried to access does not exist.
        </p>
        <Link to={'/'} >
          <div className="notfound-link">
            Go to Home Page
          </div>
          </Link>
      </div>
  </>
);

export default NotFoundPage;
