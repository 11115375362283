/* eslint-disable */
import DownIcon from "components/common/icons/downIcon";
import "./athleteOverviewTable.scss";
import Settings from "components/nav-components/nav-icons/settings";
import { useEffect, useRef, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import moment from "moment";
import MainButton from "components/common/reuseableButtonComponent/MainButton";
import CounterOrSelector from "components/counter-or-selector/CounterOrSelector";
import { DateRangePicker } from "react-dates";
import MainModal from "components/common/mainModal/MainModal";
import SwapIcon from "components/common/icons/swapIcon";
import Polygon from "components/common/icons/downArrow";
import { updateUserLayoutAPI } from "api/user";
import { Dialog } from "@radix-ui/react-dialog";
import MainTooltip from "components/common/mainTooltip/MainTooltip";
import { NEW_RIGHT_COLOR } from "utils/constant";
import EscapeIcon from "components/nav-components/nav-icons/escape-icon";
import BookmarkIcon from "components/common/icons/bookmarkIcon";

//table cell component
const CustomizeableTableCell = ({
  value,
  bilateral = false,
  leftValue,
  rightValue,
  fullDataSet,
  appearanceOption,
  dataSetIndex,
  metricName = false,
  measurementUnit,
}) => {
  const getColourFunction = (arr, value, returnType, key, multi = false) => {
    const arrayOfColours = [
      `rgba(52, 152, 219,${calculatePercentage(arr, value, returnType)})`, //rgba(52, 152, 219,1)
      `rgba(6, 82, 221,${calculatePercentage(arr, value, returnType)})`, //rgba(6, 82, 221,1.0)
      `rgba(217, 128, 250,${calculatePercentage(arr, value, returnType)})`, //rgba(217, 128, 250,1.0)
      `rgba(247, 159, 31,${calculatePercentage(arr, value, returnType)})`, //rgba(247, 159, 31,1.0)
      `rgba(225, 95, 65,${calculatePercentage(arr, value, returnType)})`, //rgba(225, 95, 65,1.0)
      `rgba(114, 170, 65,${calculatePercentage(arr, value, returnType)})`, //rgba(114, 170, 65, 1)
      `rgba(86, 42, 165,${calculatePercentage(arr, value, returnType)})`, //rgba(86, 42, 165, 1)
      `rgba(119, 139, 235,${calculatePercentage(arr, value, returnType)})`, //rgba(119, 139, 235,1.0)
      `rgba(245, 205, 121,${calculatePercentage(arr, value, returnType)})`, //rgba(245, 205, 121,1.0)
      `rgba(120, 224, 143,${calculatePercentage(arr, value, returnType)})`, //rgba(120, 224, 143,1.0)
      `rgba(205, 132, 241,${calculatePercentage(arr, value, returnType)})`, //rgba(205, 132, 241,1.0)
      `rgba(108, 92, 231,${calculatePercentage(arr, value, returnType)})`, //rgba(108, 92, 231,1.0)
      `rgba(250, 177, 160,${calculatePercentage(arr, value, returnType)})`, //rgba(250, 177, 160,1.0)
      `rgba(85, 239, 196,${calculatePercentage(arr, value, returnType)})`, //rgba(85, 239, 196,1.0)
      `rgba(229, 80, 57,${calculatePercentage(arr, value, returnType)})`, //rgba(229, 80, 57,1.0)
    ];

    if (multi === false) {
      return arrayOfColours[0];
    } else {
      return arrayOfColours[key];
    }
  };

  const dataset =
    bilateral === false
      ? fullDataSet.map((item) => item.value)
      : fullDataSet.flatMap((item) => [item.leftValue, item.rightValue]);

  function calculatePercentage(arr, value, returnType = "percentage") {
    if (arr?.length === 0) {
      //console.log("The array cannot be empty.");
      return;
    }

    const newArr = arr.map((value) => (value > 0 ? value : value * -1));

    const minVal = Math.min(...newArr);
    const maxVal = Math.max(...newArr);

    if (minVal === maxVal) {
      //console.log("All values in the array are the same.");
      return returnType === "percentage" ? "100%" : 1;
    }

    // Normalize value
    let normalizedValue = (value - minVal) / (maxVal - minVal);

    // Ensure minimum percentage is 10%
    let percentage = 10 + normalizedValue * 90;

    // Clamp to 100%
    percentage = Math.min(percentage, 100);

    if (returnType === "percentage") {
      return percentage.toFixed(0) + "%";
    } else if (returnType === "decimal") {
      return (percentage / 100).toFixed(1);
    } else {
      console.log(
        "Invalid return type specified. Use 'percentage' or 'decimal'."
      );
      return;
    }
  }

  return (
    <>
      {appearanceOption === 0 ? (
        <div className="customizeable_table_cell">
          {!bilateral ? (
            <div className="single_value">
              {measurementUnit && measurementUnit?.includes("%") ? (
                <p>{value > 0 ? value + " L" : value * -1 + " R"}</p>
              ) : (
                <p>{value}</p>
              )}
            </div>
          ) : (
            ""
          )}
          {bilateral ? (
            <div className="multiple_value">
              <div className="values">
                <div>{leftValue}L</div>
                <div>{rightValue}R</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : null}

      {appearanceOption === 1 ? (
        <div className="customizeable_table_cell">
          {!bilateral ? (
            <div className="single_value_bar">
              {measurementUnit && measurementUnit?.includes("%") ? (
                value > 0 ? (
                  <div>
                    {value} <span className="span_left">{"L"}</span>
                  </div>
                ) : (
                  <div>
                    {value * -1} <span className="span_right">{" R"}</span>
                  </div>
                )
              ) : (
                <p>{value}</p>
              )}
              <div
                className={`${
                  measurementUnit && measurementUnit?.includes("%")
                    ? value > 0
                      ? "bar_left"
                      : "bar_right"
                    : "bar"
                }`}
                style={{ width: calculatePercentage(dataset, value) }}
              ></div>
            </div>
          ) : (
            ""
          )}
          {bilateral ? (
            <div className="multiple_value">
              <div className="values">
                <div className="left_value">
                  {leftValue}
                  <span>L</span>
                </div>
                <div className="right_value">
                  {rightValue}
                  <span>R</span>
                </div>
              </div>
              <div
                className="bar_left"
                style={{ width: calculatePercentage(dataset, leftValue) }}
              ></div>
              <div
                className="bar_right"
                style={{ width: calculatePercentage(dataset, rightValue) }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : null}
      {appearanceOption === 2 ? (
        <div
          className="customizeable_table_cell"
          style={
            !bilateral
              ? {
                  backgroundColor: getColourFunction(
                    dataset,
                    value,
                    "decimal",
                    dataSetIndex,
                    false
                  ),
                }
              : {
                  backgroundColor: getColourFunction(
                    dataset,
                    (Number(leftValue) + Number(rightValue) / 2).toFixed(2),
                    "decimal",
                    dataSetIndex,
                    false
                  ),
                }
          }
        >
          {!bilateral ? (
            <div className="single_value">
              {" "}
              {measurementUnit && measurementUnit?.includes("%") ? (
                <p>{value > 0 ? value + " L" : value * -1 + " R"}</p>
              ) : (
                <p>{value}</p>
              )}
            </div>
          ) : (
            ""
          )}
          {bilateral ? (
            <div className="multiple_value">
              <div className="values">
                <div>{leftValue}L</div>
                <div>{rightValue}R</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : null}
      {appearanceOption === 3 ? (
        <div
          className="customizeable_table_cell"
          style={
            !bilateral
              ? {
                  backgroundColor: getColourFunction(
                    dataset,
                    value,
                    "decimal",
                    dataSetIndex,
                    true
                  ),
                }
              : {
                  backgroundColor: getColourFunction(
                    dataset,
                    (Number(leftValue) + Number(rightValue) / 2).toFixed(2),
                    "decimal",
                    dataSetIndex,
                    true
                  ),
                }
          }
        >
          {!bilateral ? (
            <div className="single_value">
              {" "}
              {measurementUnit && measurementUnit?.includes("%") ? (
                <p>{value > 0 ? value + " L" : value * -1 + " R"}</p>
              ) : (
                <p>{value}</p>
              )}
            </div>
          ) : (
            ""
          )}
          {bilateral ? (
            <div className="multiple_value">
              <div className="values">
                <div>{leftValue}L</div>
                <div>{rightValue}R</div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : null}
    </>
  );
};

//modal settings component
const TableSettingsModalBody = ({
  allMetrics,
  selectedMetricsForToggleState,
  setSelectedMetricsForToggleState,
  disabledMetrics,
  setDisabledMetrics,
  appearanceSelected,
  setAppearanceSelected,
  updateOverviewMetricsAndAppearanceLayout,
}) => {
  const allMetricsNamedArray = allMetrics.map((metric) => metric.metric);

  const demoDataArray = [
    [{ value: 26.55 }, { value: 41.32 }, { value: 80.98 }],
    [{ value: 5.37 }, { value: 1.52 }, { value: 25.4 }],
    [{ value: 29.87 }, { value: 30.14 }, { value: 34.19 }],
    [{ value: 1.88 }, { value: 2.65 }, { value: 3.1 }],
    [{ value: 2.02 }, { value: 3.1 }, { value: 5.3 }],
  ];

  const options = [
    "Number-only",
    "Bar",
    "Colour (Single)",
    "Colour (Multiple)",
  ];

  const [showingMetricSettings, setShowingMetricSettings] = useState(true);

  const handleTabClick = (whichTab) => {
    if (whichTab === "metric" && showingMetricSettings === false) {
      setShowingMetricSettings(true);
    }

    if (whichTab === "appearance" && showingMetricSettings === true) {
      setShowingMetricSettings(false);
    }
  };

  const handleMetricClick = (metric, whichContainer) => {
    if (whichContainer === "displayed") {
      if (!selectedMetricsForToggleState.displayedMetrics.includes(metric)) {
        setSelectedMetricsForToggleState((prevMetrics) => ({
          ...prevMetrics,
          displayedMetrics: [...prevMetrics.displayedMetrics, metric],
        }));
      }
    }
    if (selectedMetricsForToggleState.displayedMetrics.includes(metric)) {
      setSelectedMetricsForToggleState((prevMetrics) => ({
        ...prevMetrics,
        displayedMetrics: prevMetrics.displayedMetrics.filter(
          (m) => m !== metric
        ),
      }));
    }

    if (whichContainer === "hidden") {
      if (!selectedMetricsForToggleState.hiddenMetrics.includes(metric)) {
        setSelectedMetricsForToggleState((prevMetrics) => ({
          ...prevMetrics,
          hiddenMetrics: [...prevMetrics.hiddenMetrics, metric],
        }));
      }
    }
    if (selectedMetricsForToggleState.hiddenMetrics.includes(metric)) {
      setSelectedMetricsForToggleState((prevMetrics) => ({
        ...prevMetrics,
        hiddenMetrics: prevMetrics.hiddenMetrics.filter((m) => m !== metric),
      }));
    }
  };

  const handleMetricSwap = () => {
    if (selectedMetricsForToggleState.displayedMetrics.length > 0) {
      setDisabledMetrics((prev) => [
        ...prev,
        ...selectedMetricsForToggleState.displayedMetrics,
      ]);
    }

    if (selectedMetricsForToggleState.hiddenMetrics.length > 0) {
      setDisabledMetrics((prev) => {
        const updatedPrev = prev.filter(
          (metric) =>
            !selectedMetricsForToggleState.hiddenMetrics.includes(metric)
        );

        return [...updatedPrev];
      });
    }
    setSelectedMetricsForToggleState({
      hiddenMetrics: [],
      displayedMetrics: [],
    });
  };

  function onChange(i) {
    if (appearanceSelected !== i) {
      setAppearanceSelected(i);
    }
  }

  return (
    <div className="table_settings_modal_body_container">
      <div className="table_settings_modal_tabs">
        <div
          className={`table_settings_modal_metrics_tab ${
            showingMetricSettings ? "active_modal_tab" : ""
          }`}
          onClick={() => handleTabClick("metric")}
        >
          Metrics
        </div>
        <div
          className={`table_settings_modal_metrics_tab ${
            !showingMetricSettings ? "active_modal_tab" : ""
          }`}
          onClick={() => handleTabClick("appearance")}
        >
          Appearance
        </div>
      </div>
      <div className="table_settings_modal_body">
        {showingMetricSettings ? (
          <div className="table_settings_modal_metrics_body">
            <div className="table_settings_modal_metrics table_settings_modal_metrics_hidden">
              <div className="table_settings_section_heading">
                Hidden Metrics
              </div>
              <div className="modal_metrics_container hidden_metrics_container">
                {disabledMetrics.map((metric) => (
                  <div
                    className={`modal_metric ${
                      selectedMetricsForToggleState.hiddenMetrics.includes(
                        metric
                      )
                        ? "modal_metric_selected"
                        : ""
                    }`}
                    key={nanoid()}
                    onClick={() => handleMetricClick(metric, "hidden")}
                  >
                    {metric}
                  </div>
                ))}
              </div>
            </div>
            <div className="table_settings_modal_metrics_swapper">
              <div
                className={`swap_metrics_button ${
                  selectedMetricsForToggleState.displayedMetrics.length > 0 ||
                  selectedMetricsForToggleState.hiddenMetrics.length > 0
                    ? "swap_metrics_button_clickable"
                    : ""
                }`}
                onClick={() => {
                  selectedMetricsForToggleState.displayedMetrics.length > 0 ||
                  selectedMetricsForToggleState.hiddenMetrics.length > 0
                    ? handleMetricSwap()
                    : "";
                }}
              >
                <SwapIcon
                  size={24}
                  fillOpacity={
                    selectedMetricsForToggleState.displayedMetrics.length > 0 ||
                    selectedMetricsForToggleState.hiddenMetrics.length > 0
                      ? "1"
                      : "0.7"
                  }
                />
              </div>
            </div>
            <div className="table_settings_modal_metrics table_settings_modal_metrics_shown">
              <div className="table_settings_section_heading">
                Displayed Metrics
              </div>
              <div className="modal_metrics_container displayed_metrics_container">
                {allMetricsNamedArray.map((metric) => {
                  if (!disabledMetrics.includes(metric)) {
                    return (
                      <div
                        className={`modal_metric ${
                          selectedMetricsForToggleState.displayedMetrics.includes(
                            metric
                          )
                            ? "modal_metric_selected"
                            : ""
                        }`}
                        key={nanoid()}
                        onClick={() => handleMetricClick(metric, "displayed")}
                      >
                        {metric}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ) : null}
        {!showingMetricSettings ? (
          <div className="table_settings_modal_appearance_body">
            <div className="table_settings_appearance_section">
              <div className="section_title">Table Appearance</div>
              <div className="appearance_section_actions">
                {options.map((o, i) => (
                  <div className="option" key={nanoid()}>
                    <label key={i}>
                      <input
                        type="checkbox"
                        checked={i === appearanceSelected}
                        onChange={() => onChange(i)}
                      />
                      <p>{o}</p>
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="table_settings_preview_section">
              <div className="section_title">Table Appearance</div>
              <div className="preview_section_table">
                {demoDataArray.map((group, index) => (
                  <div className="section_table_row" key={nanoid()}>
                    {group.map((cell) => (
                      <div className="section_table_cell" key={nanoid()}>
                        <CustomizeableTableCell
                          value={cell.value}
                          fullDataSet={group}
                          appearanceOption={appearanceSelected}
                          dataSetIndex={index}
                        />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const TagsSelectionModalBody = ({
  uniqueArrayOfTags,
  selectedTags,
  handleTagClick,
}) => {
  const [searchText, setSearchText] = useState("");

  return (
    <div className="tags_selection_modal_container">
      <div className="tag_section_search">
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="tag_sction_tag_container">
        {uniqueArrayOfTags
          .filter((tag) =>
            tag.tag
              .split(" ")
              .join("")
              .toLocaleLowerCase()
              .includes(searchText.split(" ").join("").toLocaleLowerCase())
          )
          .map((tag) => (
            <div
              className={`modal_tag ${
                selectedTags.includes(tag.TID) ? "modal_tag_active" : ""
              }`}
              key={tag.TID}
              onClick={() => handleTagClick(tag.TID)}
            >
              <p>{tag.tag}</p>
              {selectedTags.includes(tag.TID) ? (
                <div className="remove_icon">
                  <EscapeIcon />
                </div>
              ) : null}
            </div>
          ))}
        {uniqueArrayOfTags.length === 0 ? (
          <p className="tags_warning">
            No Tags have been assigned to any of this athletes sessions.
          </p>
        ) : (
          ""
        )}
        {uniqueArrayOfTags.length > 0 &&
        uniqueArrayOfTags.filter((tag) =>
          tag.tag
            .split(" ")
            .join("")
            .toLocaleLowerCase()
            .includes(searchText.split(" ").join("").toLocaleLowerCase())
        ).length === 0 ? (
          <p className="tags_warning">
            There are no tags by that name assigned to this athlete.
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

//hook for click off filter modal
const useOutsideAlerted = (ref, setToggler) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggler(false);
      }
    };

    //bind to event
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const AthleteOverviewMainTable = (props) => {
  const {
    tableData,
    tableDataFullDates,
    filteringMethod,
    setFilteringMethod,
    numberOfLatestSessions,
    setNumberOfLatestSessions,
    rangeFiltering,
    setRangeFiletering,
    handleDatesChange,
    filterDate,
    focusedInput,
    setFocusedInput,
    handleFocusChange,
    handleIsOutsideRange,
    disabledMetrics,
    setDisabledMetrics,
    maxMinAvg,
    setMaxMinAvg,
    loggedUserData,
    isLoading,
    handleTagSelectedForFilter,
    filteringByTags,
    uniqueArrayOfTags,
    selectedTags,
    handleTagClick,
    handleTagClear,
  } = props;

  const arrayOfDates = Array.from(
    new Set(
      tableData
        .map((item) => item.data)
        .flat()
        .map((date) => {
          const d = new Date(date.TSCreated);
          return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
        })
    )
  )
    .map((time) => new Date(time))
    .sort((a, b) => b.getTime() - a.getTime())
    .map((date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    });

  //scroll sync state
  const [scrollSync, setScrollSync] = useState({
    isSyncingTopScroll: false,
    isSyncingBottomScroll: false,
    isSyncingSideScroll: false,
  });
  const [toggleRows, setToggleRows] = useState(false);

  const [selectedMetricsForToggleState, setSelectedMetricsForToggleState] =
    useState({
      hiddenMetrics: [],
      displayedMetrics: [],
    });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  //table date modal toggle state
  const [filterToggleModal, setFilterToggleModal] = useState(false);
  const [layoutToggleModal, setLayoutToggleModal] = useState(false);
  const [tagsSearch, setTagsSearch] = useState("");

  // appearance state
  const [appearanceSelected, setAppearanceSelected] = useState(0);

  //scroll use Effect
  useEffect(() => {
    let leftDiv = document.getElementById("scroll-date-rows");
    let cellDiv = document.getElementById("scroll-cell-rows");
    let topDiv = document.getElementById("scroll-metric-headings");

    //scroll sync
    if (cellDiv) {
      cellDiv.onscroll = () => {
        if (!scrollSync.isSyncingBottomScroll) {
          setScrollSync((prev) => ({ ...prev, isSyncingBottomScroll: true }));
          topDiv.scrollLeft = cellDiv.scrollLeft;
        }
        if (!scrollSync.isSyncingSideScroll) {
          setScrollSync((prev) => ({ ...prev, isSyncingSideScroll: true }));
          leftDiv.scrollTop = cellDiv.scrollTop;
        }
        setScrollSync((prev) => ({ ...prev, isSyncingBottomScroll: false }));
        setScrollSync((prev) => ({ ...prev, isSyncingSideScroll: false }));
      };
    }

    if (topDiv) {
      topDiv.onscroll = () => {
        if (!scrollSync.isSyncingTopScroll) {
          setScrollSync((prev) => ({ ...prev, isSyncingTopScroll: true }));
          cellDiv.scrollLeft = topDiv.scrollLeft;
        }
        setScrollSync((prev) => ({ ...prev, isSyncingTopScroll: false }));
      };
    }
  }, [isLoading]);

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem("userLayout")
    ).athleteOverview;

    if (
      localStorageData &&
      localStorageData.hasOwnProperty("appearanceSelected")
    ) {
      setAppearanceSelected(localStorageData.appearanceSelected);
    }
  }, []);

  //reference declared
  const filterModalRef = useRef(null);
  useOutsideAlerted(filterModalRef, setFilterToggleModal);

  //toggle handler
  const handleTableToggle = () => {
    setToggleRows((prev) => !prev);
  };

  function ordinal_suffix_of(i) {
    let j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const generateRows = () => {
    const arrayOfRows = [];
    if (filteringMethod.latestSessions) {
      for (let i = 0; i < numberOfLatestSessions + 1; i++) {
        const row = (
          <div className="athlete_overview_main_table_row" key={nanoid()}>
            {i === 0
              ? "Latest Session"
              : `${ordinal_suffix_of(i)}
               Session`}
          </div>
        );
        if (i === 0) {
          i++;
        }
        arrayOfRows.push(row);
      }
    }

    if (filteringMethod.dateRange || filteringMethod.presetRange) {
      // return arrayOfDates.map((date) => (
      //   <div className="athlete_overview_main_table_row" key={nanoid()}>
      //     {date}
      //   </div>
      // ));

      for (let i = 0; i < arrayOfDates.length; i++) {
        const row = (
          <div className="athlete_overview_main_table_row" key={nanoid()}>
            {arrayOfDates[i]}
          </div>
        );
        arrayOfRows.push(row);
      }
    }

    if (
      (filteringMethod.dateRange || filteringMethod.presetRange) &&
      arrayOfDates.length < 8
    ) {
      for (let i = 0; i <= 8 - arrayOfDates.length; i++) {
        const row = (
          <div className="athlete_overview_main_table_row row_na">N/A</div>
        );
        if (i === 0) {
          i++;
        }
        arrayOfRows.push(row);
      }
    }

    if (filteringMethod.latestSessions && numberOfLatestSessions < 8) {
      for (let i = 0; i < 8 - numberOfLatestSessions + 1; i++) {
        const row = (
          <div className="athlete_overview_main_table_row row_na">N/A</div>
        );
        if (i === 0) {
          i++;
        }
        arrayOfRows.push(row);
      }
    }

    return arrayOfRows;
  };

  const generateRowsLoading = () => {
    const arrayOfRows = [];
    for (let i = 0; i < 8 + 1; i++) {
      const row = (
        <div className="athlete_overview_main_table_row row_na">—</div>
      );
      if (i === 0) {
        i++;
      }
      arrayOfRows.push(row);
    }

    return arrayOfRows;
  };

  const generateBlankRows = (rowsTaken, bilateral) => {
    const arrayOfRows = [];
    if (filteringMethod.latestSessions && bilateral) {
      for (let i = 0; i < numberOfLatestSessions - rowsTaken; i++) {
        arrayOfRows.push(
          <div className="athlete_overview_main_table_cell_placeholder na_opacity">
            <p>—</p>
          </div>
        );
      }
    } else {
      for (let i = 0; i < numberOfLatestSessions - rowsTaken; i++) {
        arrayOfRows.push(
          <div className="athlete_overview_main_table_cell_placeholder athlete_overview_main_table_cell_bilateral na_opacity">
            <p>—</p>
            <p>—</p>
          </div>
        );
      }
    }
    return arrayOfRows;
  };

  const generateNaBlankRows = () => {
    const arrayOfRows = [];
    if (
      (filteringMethod.dateRange || filteringMethod.presetRange) &&
      arrayOfDates.length < 8
    ) {
      for (let i = 0; i < 8 - arrayOfDates.length; i++) {
        const row = (
          <div className="athlete_overview_main_table_cell_placeholder row_na">
            N/A
          </div>
        );
        arrayOfRows.push(row);
      }
    }

    if (filteringMethod.latestSessions && numberOfLatestSessions < 8) {
      for (let i = 0; i < 8 - numberOfLatestSessions + 1; i++) {
        const row = (
          <div className="athlete_overview_main_table_cell_placeholder row_na">
            N/A
          </div>
        );
        if (i === 0) {
          i++;
        }
        arrayOfRows.push(row);
      }
    }
    return arrayOfRows;
  };

  const getAverage = (arr) => {
    return arr.reduce((sum, value) => sum + value, 0) / arr.length;
  };

  //user session layout --------------------------------------------------------------------------
  async function updateOverviewLayout() {
    const loggedInUserLayout = await JSON.parse(loggedUserData?.user.layout);

    const postBody = await {
      layout: JSON.stringify({
        pdf: { ...loggedInUserLayout.pdf },
        comparison: { ...loggedInUserLayout.comparison },
        sessionLayout: { ...loggedInUserLayout.sessionLayout },
        athleteOverview: {
          ...JSON.parse(localStorage.getItem("userLayout")).athleteOverview,
          filteringMethod: filteringMethod,
          rangeFiltering: rangeFiltering,
          maxMinAvg: maxMinAvg,
          numberOfLatestSessions: numberOfLatestSessions,
        },
      }),
    };
    await localStorage.setItem("userLayout", postBody.layout);
    await updateUserLayoutAPI(postBody);
  }

  //user overview layout displayed metrics and appearance
  async function updateOverviewMetricsAndAppearanceLayout() {
    const loggedInUserLayout = await JSON.parse(loggedUserData?.user.layout);

    const postBody = await {
      layout: JSON.stringify({
        pdf: { ...loggedInUserLayout.pdf },
        comparison: { ...loggedInUserLayout.comparison },
        sessionLayout: { ...loggedInUserLayout.sessionLayout },
        athleteOverview: {
          ...JSON.parse(localStorage.getItem("userLayout")).athleteOverview,
          disabledMetrics: disabledMetrics,
          appearanceSelected: appearanceSelected,
        },
      }),
    };
    await localStorage.setItem("userLayout", postBody.layout);
    await updateUserLayoutAPI(postBody);
    await setLayoutToggleModal(true);
  }

  const firstDate = filterDate.startDate
    ? new Date(filterDate.startDate).toDateString()
    : null;
  const secondDate = filterDate.endDate
    ? new Date(filterDate.endDate).toDateString()
    : null;

  const renderDay = (day) => {
    return (
      <div
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span>{day.format("D")}</span>
        {tableDataFullDates.includes(moment(day._d).format("DD/MM/YYYY")) ? (
          <div
            style={{
              height: "8px",
              width: "8px",
              backgroundColor: NEW_RIGHT_COLOR,
              borderRadius: "4px",
            }}
          ></div>
        ) : (
          <div style={{ height: "8px", width: "8px" }}></div>
        )}
      </div>
    );
  };

  return !isLoading ? (
    <div className="athlete_overview_main_table_container ">
      <div className="athlete_overview_main_table_buttons">
        {maxMinAvg === "avg" ? (
          <MainTooltip
            tooltipText={
              "When Average is selected the all the graphs below with the exception of Load cannot open session when points or bars are clicked."
            }
          />
        ) : null}
        <div className="athlete_overview_table_dropdown">
          <div
            className="dropdown_button"
            onClick={() => setDropdownOpen((prev) => !prev)}
          >
            <Polygon direction={!dropdownOpen ? "down" : "up"} />
            <p>
              {maxMinAvg === "max"
                ? "Maximum"
                : maxMinAvg === "avg"
                ? "Average"
                : "Minimum"}
            </p>
          </div>
          <div
            className={`dropdown_body ${
              !dropdownOpen ? "dropdown_body_closed" : ""
            }`}
          >
            <div
              className="dropdown_body_option"
              onClick={() => {
                setDropdownOpen((prev) => !prev);
                setMaxMinAvg("max");
              }}
            >
              <p className={`${maxMinAvg === "max" ? "active_option" : ""}`}>
                Maximum
              </p>
            </div>
            <div className="dropdown_divider"></div>
            <div
              className="dropdown_body_option"
              onClick={() => {
                setDropdownOpen((prev) => !prev);
                setMaxMinAvg("avg");
              }}
            >
              <p className={`${maxMinAvg === "avg" ? "active_option" : ""}`}>
                Average
              </p>
            </div>
            <div className="dropdown_divider"></div>
            <div
              className="dropdown_body_option"
              onClick={() => {
                setDropdownOpen((prev) => !prev);
                setMaxMinAvg("min");
              }}
            >
              <p className={`${maxMinAvg === "min" ? "active_option" : ""}`}>
                Minimum
              </p>
            </div>
          </div>
        </div>
        <div
          className="athlete_overview_table_button"
          onClick={() => setLayoutToggleModal(false)}
        >
          <MainModal
            button={<BookmarkIcon size="24" color="white" fillOpacity="1" />}
            modalBody={
              <TagsSelectionModalBody
                uniqueArrayOfTags={uniqueArrayOfTags}
                selectedTags={selectedTags}
                handleTagClick={handleTagClick}
              />
            }
            modalButtons={
              <div className="table_settings_modal_buttons">
                <div onClick={() => handleTagClear()}>
                  <MainButton buttonText={"Clear"} />
                </div>
              </div>
            }
          />
        </div>
        <div
          className="athlete_overview_table_button"
          onClick={() => setLayoutToggleModal(false)}
        >
          <MainModal
            button={<Settings size="24" color="white" fillOpacity="1" />}
            modalBody={
              <TableSettingsModalBody
                allMetrics={tableData}
                selectedMetricsForToggleState={selectedMetricsForToggleState}
                setSelectedMetricsForToggleState={
                  setSelectedMetricsForToggleState
                }
                disabledMetrics={disabledMetrics}
                setDisabledMetrics={setDisabledMetrics}
                appearanceSelected={appearanceSelected}
                setAppearanceSelected={setAppearanceSelected}
                updateOverviewMetricsAndAppearanceLayout={
                  updateOverviewMetricsAndAppearanceLayout
                }
              />
            }
            modalButtons={
              <div className="table_settings_modal_buttons">
                <div onClick={() => updateOverviewMetricsAndAppearanceLayout()}>
                  <MainButton buttonText={"Save Layout"} />
                </div>
              </div>
            }
          />
        </div>
        <div
          className={`athlete_overview_table_button ${
            toggleRows
              ? "athlete_overview_table_button_rotate_down"
              : "athlete_overview_table_button_rotate_up"
          }`}
          onClick={handleTableToggle}
        >
          <Polygon size={20} />
        </div>
      </div>
      <div className={`athlete_overview_main_table_headings`}>
        <div className="athlete_overview_main_table_viewed">
          <div
            className={`athlete_overview_table_display_modal ${
              filterToggleModal
                ? "athlete_overview_table_display_modal_open"
                : ""
            }`}
            ref={filterModalRef}
          >
            <div className="filter_modal_heading">
              <h5>Sort By</h5>
            </div>
            <div className="filter_modal_options">
              <div className="counter_options">
                <div
                  className={`counter date_counter option ${
                    filteringMethod.presetRange ? "current_counter" : ""
                  }`}
                  onClick={() =>
                    setFilteringMethod((prev) => ({
                      unset: false,
                      latestSessions: false,
                      presetRange: true,
                      dateRange: false,
                    }))
                  }
                >
                  <div className="filter_modal_heading">
                    <h5>Date</h5>
                    <p>View all sessions from</p>
                  </div>
                  <div className="counter_controls">
                    <div className="multiple_controls">
                      <CounterOrSelector
                        selectorType={"number_scale"}
                        value={rangeFiltering.number}
                        setValue={setRangeFiletering}
                        isSelected={filteringMethod.presetRange}
                        selectorWidth={40}
                      />
                      <CounterOrSelector
                        selectorType={"selection_scale"}
                        value={rangeFiltering.selected}
                        setValue={setRangeFiletering}
                        isSelected={filteringMethod.presetRange}
                        selectorWidth={80}
                        selections={rangeFiltering.selections}
                        values={rangeFiltering}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`counter session_counter option ${
                    filteringMethod.latestSessions ? "current_counter" : ""
                  }`}
                  onClick={() =>
                    setFilteringMethod((prev) => ({
                      unset: false,
                      latestSessions: true,
                      presetRange: false,
                      dateRange: false,
                    }))
                  }
                >
                  <div className="filter_modal_heading">
                    <h5>Sessions</h5>
                    <p>
                      View Last <span>{numberOfLatestSessions}</span> sessions
                    </p>
                  </div>
                  <div className="counter_controls">
                    <CounterOrSelector
                      selectorType={"number"}
                      value={numberOfLatestSessions}
                      setValue={setNumberOfLatestSessions}
                      isSelected={filteringMethod.latestSessions}
                      selectorWidth={70}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`counter date_picker_option option ${
                  filteringMethod.dateRange ? "current_counter" : ""
                }`}
                onClick={() =>
                  setFilteringMethod((prev) => ({
                    unset: false,
                    latestSessions: false,
                    presetRange: false,
                    dateRange: true,
                  }))
                }
              >
                <div className="session_counter">
                  <div className="filter_modal_heading">
                    <h5>Specific Range</h5>
                  </div>
                  <div
                    className={`${
                      filteringMethod.dateRange
                        ? "date_range_container_active"
                        : "date_range_container"
                    } `}
                  >
                    <DateRangePicker
                      startDate={filterDate?.startDate}
                      minimumNights={0}
                      startDateId="start_date_overview"
                      endDate={filterDate?.endDate}
                      endDateId="end_date_overview"
                      onDatesChange={handleDatesChange}
                      numberOfMonths={1}
                      keepOpenOnDateSelect={true}
                      focusedInput={focusedInput ? focusedInput : "startDate"}
                      onFocusChange={(focusedInput) =>
                        setFocusedInput(focusedInput)
                      }
                      displayFormat={"DD/MM/YYYY"}
                      isOutsideRange={handleIsOutsideRange}
                      renderDayContents={renderDay}
                    />
                  </div>
                </div>
              </div>
              <div className="tag_options">
                <div className="tag_options_heading">
                  <h5>Tags</h5>
                </div>
                <div className="tag_options_search">
                  <input
                    type="text"
                    name="tag-search"
                    value={tagsSearch}
                    id="tagSearch"
                    placeholder="Search..."
                    onChange={(e) => setTagsSearch(e.target.value)}
                  />
                </div>
                <div className="tag_options_container">
                  {uniqueArrayOfTags
                    .filter((item) =>
                      item.tag
                        .split(" ")
                        .join("")
                        .toLowerCase()
                        .includes(tagsSearch.toLocaleLowerCase())
                    )
                    .map((item) => (
                      <div
                        className={`tag ${
                          filteringByTags.includes(item.TID)
                            ? "tag_selected"
                            : ""
                        }`}
                        key={item.TID}
                        onClick={() => handleTagSelectedForFilter(item)}
                      >
                        {filteringByTags.includes(item.TID) ? (
                          <div className="close_icon">
                            <EscapeIcon size="15" />
                          </div>
                        ) : (
                          ""
                        )}

                        <p>{item.tag}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="filter_modal_buttons">
              <div
                onClick={() => {
                  updateOverviewLayout(), setFilterToggleModal((prev) => !prev);
                }}
              >
                <MainButton buttonText={"Save Preference"} />
              </div>
            </div>
          </div>
          <div
            className="athlete_overview_table_cell athlete_overview_table_heading athlete_overview_main_table_controler"
            onClick={() => setFilterToggleModal((prev) => !prev)}
          >
            {filteringMethod.latestSessions ? (
              <div className="athlete_overview_table_heading athlete_overview_main_table_controler_headings">
                <p>Displaying </p>
                <p>{numberOfLatestSessions} Sessions</p>
              </div>
            ) : null}
            {filteringMethod.presetRange ? (
              <div className="athlete_overview_table_heading athlete_overview_main_table_controler_headings">
                <p>Displaying </p>
                <p>{`${rangeFiltering.number} ${rangeFiltering.selected}${
                  rangeFiltering.number > 1 ? "s" : ""
                }`}</p>
              </div>
            ) : null}
            {filteringMethod.dateRange ? (
              firstDate || secondDate ? (
                <div className="athlete_overview_table_heading athlete_overview_main_table_controler_headings">
                  <p>{firstDate}</p>
                  <p>—</p>
                  <p>{secondDate}</p>
                </div>
              ) : (
                <div className="athlete_overview_table_heading athlete_overview_main_table_controler_headings">
                  <p>Please select date range</p>
                </div>
              )
            ) : null}
            <div>
              <Polygon size={20} />
            </div>
          </div>
        </div>
        <div
          className={`athlete_overview_table_metric_cells ${
            toggleRows ? "athlete_overview_main_table_headings_scrollable" : ""
          }`}
          id="scroll-metric-headings"
        >
          {tableData.map((tableHeading) => (
            <div
              className="athlete_overview_table_metric_heading"
              key={nanoid()}
            >
              <div className="athlete_overview_table_cell athlete_overview_table_heading_cell">
                {tableHeading.metric + ` (${tableHeading.measurementUnit})`}
              </div>
              {tableHeading.bilateral ? (
                <div className="athlete_overview_table_heading_value na_opacity">
                  {!tableHeading.measurementUnit.includes("%") ? (
                    tableHeading.data.length ? (
                      getAverage(
                        tableHeading.data.map(
                          (item) => item[tableHeading.primaryKey]
                        )
                      ).toFixed(2)
                    ) : (
                      <p>—</p>
                    )
                  ) : tableHeading.data.length ? (
                    getAverage(
                      tableHeading.data.map(
                        (item) => item[tableHeading.primaryKey]
                      )
                    ) > 0 ? (
                      getAverage(
                        tableHeading.data.map(
                          (item) => item[tableHeading.primaryKey]
                        )
                      ).toFixed(2) + " L"
                    ) : (
                      (
                        getAverage(
                          tableHeading.data.map(
                            (item) => item[tableHeading.primaryKey]
                          )
                        ) * -1
                      ).toFixed(2) + " R"
                    )
                  ) : (
                    <p>—</p>
                  )}
                </div>
              ) : (
                <div className="athlete_overview_table_heading_value athlete_overview_table_heading_values">
                  {tableHeading.data.length ? (
                    <>
                      <p>
                        {getAverage(
                          tableHeading.data.map((item) => {
                            return item[tableHeading.secondaryKeys[0]];
                          })
                        ).toFixed(2)}
                        L
                      </p>
                      <p>
                        {getAverage(
                          tableHeading.data.map((item) => {
                            return item[tableHeading.secondaryKeys[1]];
                          })
                        ).toFixed(2)}
                        R
                      </p>
                    </>
                  ) : (
                    <>
                      <p style={{ opacity: 0.4 }}>—</p>
                      <p style={{ opacity: 0.4 }}>—</p>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`athlete_overview_main_table_rows ${
          toggleRows ? "athlete_overview_main_table_rows_closed " : ""
        }`}
      >
        <div
          className="athlete_overview_main_table_rows_order athlete_overview_main_table_rows_date"
          id="scroll-date-rows"
        >
          {generateRows()}
        </div>
        <div
          className="athlete_overview_main_table_rows_cells"
          id="scroll-cell-rows"
        >
          {filteringMethod.latestSessions
            ? tableData.map((metric, index) => {
                return (
                  <div
                    className="athlete_overview_main_table_rows_order"
                    key={nanoid()}
                  >
                    {metric.data.map((session) => {
                      return metric.bilateral ? (
                        <div
                          className="athlete_overview_main_table_cell"
                          key={nanoid()}
                        >
                          {
                            <CustomizeableTableCell
                              value={session.value?.toFixed(2)}
                              fullDataSet={metric.data}
                              appearanceOption={appearanceSelected}
                              dataSetIndex={index}
                              metricName={metric.metric}
                              measurementUnit={metric.measurementUnit}
                            />
                          }
                          {/* {session.value?.toFixed(2)} */}
                        </div>
                      ) : (
                        <div
                          className="athlete_overview_main_table_cell athlete_overview_main_table_cell_bilateral"
                          key={nanoid()}
                        >
                          <CustomizeableTableCell
                            bilateral={true}
                            leftValue={session?.[
                              metric.secondaryKeys[0]
                            ].toFixed(2)}
                            rightValue={session?.[
                              metric.secondaryKeys[1]
                            ].toFixed(2)}
                            fullDataSet={metric.data}
                            appearanceOption={appearanceSelected}
                            dataSetIndex={index}
                            metricName={metric.metric}
                          />
                        </div>
                      );
                    })}
                    {generateBlankRows(metric.data.length, metric.bilateral)}
                    {generateNaBlankRows()}
                  </div>
                );
              })
            : null}

          {filteringMethod.dateRange || filteringMethod.presetRange
            ? tableData.map((metric, index) => {
                return (
                  <div
                    className="athlete_overview_main_table_rows_order"
                    key={nanoid()}
                  >
                    {arrayOfDates.map((date) => {
                      const matchedSession = metric.data.find((session) => {
                        return (
                          moment(session.TSCreated).format("DD/MM/YYYY") ===
                          date
                        );
                      });

                      if (matchedSession) {
                        if (metric.bilateral) {
                          return (
                            <div
                              className="athlete_overview_main_table_cell"
                              key={nanoid()}
                            >
                              {/* {matchedSession.value?.toFixed(2)} */}
                              <CustomizeableTableCell
                                value={matchedSession.value?.toFixed(2)}
                                fullDataSet={metric.data}
                                appearanceOption={appearanceSelected}
                                dataSetIndex={index}
                                metricName={metric.metric}
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="athlete_overview_main_table_cell athlete_overview_main_table_cell_bilateral"
                              key={nanoid()}
                            >
                              <CustomizeableTableCell
                                bilateral={true}
                                leftValue={matchedSession?.[
                                  metric.secondaryKeys[0]
                                ].toFixed(2)}
                                rightValue={matchedSession?.[
                                  metric.secondaryKeys[1]
                                ].toFixed(2)}
                                fullDataSet={metric.data}
                                appearanceOption={appearanceSelected}
                                dataSetIndex={index}
                                metricName={metric.metric}
                              />
                            </div>
                          );
                        }
                      } else {
                        if (metric.bilateral) {
                          return (
                            <div
                              className="athlete_overview_main_table_cell_placeholder na_opacity"
                              key={nanoid()}
                            >
                              <p>—</p>
                            </div>
                          );
                        } else {
                          return (
                            <div className="athlete_overview_main_table_cell_placeholder athlete_overview_main_table_cell_bilateral">
                              <div className="na_opacity">
                                <p>—</p>
                              </div>
                              <div className="na_opacity">
                                <p>—</p>
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                    {generateNaBlankRows()}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  ) : (
    <div className="athlete_overview_main_table_container ">
      <div className="athlete_overview_main_table_buttons shimmer">
        <div className="athlete_overview_table_dropdown">
          <div
            className="dropdown_button"
            onClick={() => setDropdownOpen((prev) => !prev)}
          ></div>
        </div>
        <div className="athlete_overview_table_button"></div>
        <div
          className={`athlete_overview_table_button ${
            toggleRows
              ? "athlete_overview_table_button_rotate_down"
              : "athlete_overview_table_button_rotate_up"
          }`}
        ></div>
          <div
          className={`athlete_overview_table_button ${
            toggleRows
              ? "athlete_overview_table_button_rotate_down"
              : "athlete_overview_table_button_rotate_up"
          }`}
        ></div>
      </div>
      <div className="athlete_overview_main_table_headings shimmer">
        <div className="athlete_overview_main_table_viewed">
          <div
            className={`athlete_overview_table_display_modal ${
              filterToggleModal
                ? "athlete_overview_table_display_modal_open"
                : ""
            }`}
          ></div>
          <div className="athlete_overview_table_cell athlete_overview_table_heading athlete_overview_main_table_controler"></div>
        </div>
        <div className="athlete_overview_table_metric_cells">
          {tableData.map((tableHeading) => (
            <div
              className="athlete_overview_table_metric_heading"
              key={nanoid()}
            >
              <div className="athlete_overview_table_cell athlete_overview_table_heading_cell "></div>
              <div className="athlete_overview_table_heading_value na_opacity">
                <p>—</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`athlete_overview_main_table_rows shimmer ${
          toggleRows ? "athlete_overview_main_table_rows_closed " : ""
        }`}
      >
        <div className="athlete_overview_main_table_rows_order athlete_overview_main_table_rows_date">
          {generateRowsLoading()}
        </div>
        <div className="athlete_overview_main_table_rows_cells">
          {tableData.map((metric, index) => {
            return (
              <div
                className="athlete_overview_main_table_rows_order"
                key={nanoid()}
              >
                {metric.data.map((session) => {
                  return (
                    <div
                      className="athlete_overview_main_table_cell_placeholder na_opacity"
                      key={nanoid()}
                    >
                      <p>—</p>
                    </div>
                  );
                })}
                {generateBlankRows(metric.data.length, true)}
                {generateNaBlankRows()}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AthleteOverviewMainTable;
