/* eslint-disable */
import React from "react";
import Button from "../../button/button";
import Search from "../../../nav-components/nav-search/Search";
import { useWindowSize } from "../../hooks/useWindowSize";
import "./athleteHeader.scss";
import CreateAthlete from "components/common/createAthlete/createAthlete";
import { useSelector } from "react-redux";
import CompareIcon from "components/common/icons/compareIcon";
import AddAthleteIcon from "components/common/icons/addAthletesIcon";
import MainButton from "components/common/reuseableButtonComponent/MainButton";

const AthleteGraphHeader = ({
  value,
  onChangeHandler,
  onClickHandler,
  setModalIn,
}) => {
  const width = useWindowSize();
  const [modal, setModal] = React.useState(false);
  const { checkedAthletesForComparison } = useSelector(
    (state) => state.playerActivity
  );

  function hideForm() {
    setModal((prevEditForm) => !prevEditForm);
    document.body.classList.add("modal-open");
  }

  if (width.width < 590) {
    return (
      <div className="athlete-graph-container-mobile">
        <div className="players-home-text-mobile">
          <CreateAthlete
            close={setModal}
            onClickHandler={onClickHandler}
            button={
              // <div>
              //   <MainButton buttonText = {"Add New Athlete"} />
              // </div>
              <Button value={"Add New Athlete"} onClickHandler={hideForm} />
            }
            open={modal}
          />
        </div>
        <div className="divider" />
        <div className="players-home-items-container">
          <div className="search-players-home-container">
            <Search
              placeHolder={"Search"}
              value={value}
              onChangeHandler={onChangeHandler}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="athlete-graph-container">
      <div className="players-home-items-container">
        {checkedAthletesForComparison.length > 1 ? (
          <div onClick={() => setModalIn(true)}>
            <MainButton
              buttonText={"Compare"}
              ButtonIcon={CompareIcon}
              buttonIconSize={14}
              buttonPop={true}
            />
          </div>
        ) : (
          <MainButton
            buttonText={"Compare"}
            ButtonIcon={CompareIcon}
            buttonIconSize={14}
            buttonDisabled={true}
            buttonTooltip={"Select 2 or more Athletes for comparison"}
          />
        )}
        <div className="search-players-home-container">
          <Search
            placeHolder={"Search Athletes"}
            value={value}
            onChangeHandler={onChangeHandler}
          />
        </div>
        <CreateAthlete
          close={setModal}
          onClickHandler={onClickHandler}
          button={
            <div onClick={() => hideForm()}>
              <MainButton
                buttonText={"Add Athlete"}
                ButtonIcon={AddAthleteIcon}
                buttonIconSize={14}
                buttonPop={true}
              />
            </div>
          }
          open={modal}
        />
      </div>
    </div>
  );
};

export default AthleteGraphHeader;
