/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAthleteHistoricalGait,
  getAthleteHistoricalLoad,
  getAthleteHistoricalGroundContact,
  selectAthlete,
} from "store/athleteSlice";
import AthleteActivityOverviewList from "./AthleteActivityOverviewList";
import { usePrevious } from "components/common/hooks/usePrevious";
import useWhyDidYouUpdate from "components/common/hooks/useWhydidyouUpdate";
import { useWindowSize } from "components/common/hooks/useWindowSize";

const AthleteOverview = (props) => {
  const { width, height } = useWindowSize();

  const { athleteid, athlete, actions, handleLoadClick } = props;
  useWhyDidYouUpdate("AthleteOverview", props);
  const prevUrl = usePrevious(athleteid);

  console.log(prevUrl)

  console.log(props)

  //heightAvailableState
  const [pageVerticalSpaceAvailable, setPageVerticalSpaceAvailable] =
    useState(0);

  // check window height ratios, used to adjust height of the container for each graph
  const getGraphContainerHeight = () => {
    let navBarHeight = document.querySelector(".navBar")?.offsetHeight || 0;
    let breadCrumbsHeight =
      document.querySelector(".nav-breadcrumbs")?.offsetHeight || 0;
    let userProfileBar =
      document.querySelector(".user-profile-bar")?.offsetHeight || 0;
    let tabHeader = document.querySelector(".tab-header")?.offsetHeight || 0;
    let heightTaken = navBarHeight + breadCrumbsHeight + tabHeader;
    let heightAvailable = height - heightTaken;
    setPageVerticalSpaceAvailable(heightAvailable); // Update the state here
  };

  if (pageVerticalSpaceAvailable === 0) {
    getGraphContainerHeight();
  }

  useEffect(() => {
    getGraphContainerHeight(); // Call function to initialize the value
  }, [height]); // Recalculate when height changes

  useEffect(() => {
    if (athlete.loadedAthleteIds === athleteid) {
      return;
    }
    if (
      athlete.athleteHistoricalGait.status === "idle" ||
      (prevUrl === null && athleteid) ||
      (prevUrl !== null && prevUrl !== athleteid)
    ) {
      actions.getAthleteHistoricalGait(athleteid);
    }

    if (
      athlete.athleteHistoricalLoad.status === "idle" ||
      (prevUrl === null && athleteid) ||
      (prevUrl !== null && prevUrl !== athleteid)
    ) {
      actions.getAthleteHistoricalLoad(athleteid);
    }

    if (
      athlete.athleteHistoricalGroundContact.status === "idle" ||
      (prevUrl === null && athleteid) ||
      (prevUrl !== null && prevUrl !== athleteid)
    ) {
      actions.getAthleteHistoricalGroundContact(athleteid);
    }

    getGraphContainerHeight();
  }, [athleteid]);

  return (
    <div className="overview-parent">
      <AthleteActivityOverviewList
        history={props.history}
        athleteid={athleteid}
        handleLoadClick={handleLoadClick}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    athlete: selectAthlete(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAthleteHistoricalGait,
      getAthleteHistoricalLoad,
      getAthleteHistoricalGroundContact,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(AthleteOverview);
