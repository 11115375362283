/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "react-vis/dist/style.css";
import {
  Bar,
  ComposedChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  ReferenceLine,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "../../utils/constant";
import { CustomTooltipForAsymmetry } from "../../utils/metrics";
import DownArrow from "../../components/common/icons/downArrow";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import GraphSettings from "./metric-helpers/GraphSettings";
import { selectSession } from "store/sessionSlice";
import mappingTrimAreaY from "./metric-helpers/mappingTrimAreaY";
import { formatTime } from "utils/helper";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

function formatXAxis(value) {
  const valueRounded = Math.round(value * 100) / 100;
  return Math.abs(valueRounded);
}

class GroundClearanceAsymmetry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //hidden: true,
      hidden: false, //default to show data
      hovered: false,
      loading: false,
      chartInfo: [],
      leftExtraValue: false,
      rightExtraValue: false,
      legState: "test",
      chartType: { graph: "bar" },
      graphSettingsLoaction: "right-side",
    };
  }

  componentDidMount() {
    const { session } = this.props;

    if (
      session?.jumpData?.data?.flightData?.symmetryChartData &&
      session?.jumpData?.data?.flightData?.symmetryChartData[0]?.Symmetry < 0
    ) {
      this.setState({ legState: "left" });
    } else {
      this.setState({ legState: "right" });
    }
  }

  setGraphType = (graphType) => {
    this.setState({
      chartType: { graph: graphType },
    });
  };

  displayCharts() {
    let { hidden } = this.state;
    const { session, graphDomain, isTrimOpen = false } = this.props;

    if (!hidden && session.runData.status === "loading")
      return displayLoadingCharts();
    else if (
      !hidden &&
      (session.runData.data.StrideSymmetryData &&
        session.runData.data.StrideSymmetryData.groundClearance &&
        session.runData.data.StrideSymmetryData.groundClearance.length > 0) !=
        true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (!hidden && session.runData.data.StrideSymmetryData) {
      //creating a new variable which contains the dataset
      const dataTesting =
        session?.runData?.data?.StrideSymmetryData.groundClearance;

      const gradientOffset = () => {
        const dataMax = Math.max(...dataTesting.map((i) => i["Symmetry"]));
        const dataMin = Math.min(...dataTesting.map((i) => i["Symmetry"]));
        if (dataMax <= 0) {
          return 0;
        } else if (dataMin >= 0) {
          return 1;
        } else {
          return dataMax / (dataMax - dataMin);
        }
      };

      let offset = gradientOffset();

      return (
        <div className="metric-container">
          <div className="metric-chart-container-parent">
            <div className="chart-container-padding">
              <div className="chart-container flip-twin-left">
              <div className="container-pdf-flex">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={700}
                    height={400}
                    data={
                      session.runData.data.StrideSymmetryData.groundClearance
                    }
                    stackOffset="sign"
                    layout="vertical"
                  >
                    <defs>
                      <linearGradient
                        id="groundClearanceGradient"
                        x1="1"
                        x2="0"
                      >
                        <stop offset={offset} stopColor={NEW_RIGHT_COLOR} />
                        <stop offset={offset} stopColor={NEW_LEFT_COLOR} />
                      </linearGradient>
                    </defs>

                    <XAxis
                      type="number"
                      domain={this.props.xRange}
                      tickFormatter={formatXAxis}
                      tick={{ fill: NEW_AXIS_TICK }}
                      stroke={NEW_AXIS_COLOR}
                      label={{
                        value: "% Asymmetry",
                        offset: -3,
                        position: "insideBottom",
                        fill: NEW_AXIS_TICK,
                      }}
                    />

                    {session.runData.data.StrideSymmetryData.groundClearance[0].hasOwnProperty(
                      "ts"
                    ) ? (
                      <YAxis
                        type="number"
                        domain={graphDomain && !isTrimOpen ? graphDomain : [0]}
                        dataKey={"ts"}
                        label={{
                          value: session.runData.data.StrideSymmetryData.groundClearance[ session.runData.data.StrideSymmetryData.groundClearance.length -2]["ts"] > 300 ? "Time (min)" :"Time (s)",
                          angle: -90.1,
                          position: "insideRight",
                          offset: 40,
                          fill: NEW_AXIS_TICK,
                        }}
                        allowDataOverflow={true}
                        stroke={NEW_AXIS_COLOR}
                        tick={{ fill: NEW_AXIS_TICK }}
                        tickFormatter={(value) => formatTime(value,  session.runData.data.StrideSymmetryData.groundClearance[ session.runData.data.StrideSymmetryData.groundClearance.length -2])}
                        // tick={false}
                      />
                    ) : (
                      <YAxis
                        type="category"
                        label={{
                          value: "Steps",
                          angle: -90.1,
                          position: "insideRight",
                          offset: 40,
                          fill: NEW_AXIS_TICK,
                        }}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                        tickFormatter={(value) => formatTime(value,  session.runData.data.StrideSymmetryData.groundClearance[ session.runData.data.StrideSymmetryData.groundClearance.length -2])}
                      />
                    )}
                    {this.props.trimDomain &&
                      isTrimOpen &&
                      mappingTrimAreaY(this.props.trimDomain)}
                    <Tooltip
                      content={
                        <CustomTooltipForAsymmetry
                          data={
                            session.runData.data.StrideSymmetryData
                              .groundClearance
                          }
                        />
                      }
                      cursor={{ fill: NEW_AXIS_COLOR, strokeWidth: 2 }}
                    />

                    <ReferenceLine
                      x="0"
                      stroke={NEW_AXIS_COLOR}
                      strokeWidth={1}
                      opacity={0.6}
                      strokeDasharray="3 3"
                    />

                    {this.state.chartType.graph === "bar" && (
                      <Bar dataKey="Symmetry" barSize={100} opacity={0.7}>
                        {session.runData.data.StrideSymmetryData.groundClearance.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={
                                entry["Symmetry"] < 0
                                  ? NEW_LEFT_COLOR
                                  : NEW_RIGHT_COLOR
                              }
                            />
                          )
                        )}
                      </Bar>
                    )}

                    {this.state.chartType.graph === "line" && (
                      <Line
                        dataKey="Symmetry"
                        stroke="url(#groundClearanceGradient)"
                        dot={false}
                      />
                    )}
                  </ComposedChart>
                </ResponsiveContainer>
                {this.props.toggleableTextArea}
              </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { hidden } = this.state;

    let arrowClass = "";
    if (!hidden) {
      arrowClass = "flip-div";
    }

    return (
      <div className="settings_graph">
        <div className="metric-container-parent ground-clearance-symmetry">
          <div className="metric-container metric-title-div">
            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-main-title">
              Ground Clearance Asymmetry [%]{" "}
              <div style={{ paddingLeft: "0.5rem" }}>
                {" "}
                <MainTooltip
                  tooltipText={
                    "Ground clearance asymmetry tooltip"
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
              </div>
            </div>

            <div className="metric-title-extra-info">
              {/* <span>00:00:00</span> */}
            </div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>
          </div>

          {this.displayCharts()}
        </div>
        {this.props.showSettings && (
          <GraphSettings
            graphName={"groundClearanceSymmetry"}
            settingsPosition={this.state.graphSettingsLoaction}
            chartType={this.state.chartType}
            setChartType={this.setGraphType}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: selectSession(state),
  };
}

export default connect(mapStateToProps)(GroundClearanceAsymmetry);
