/* eslint-disable */
import Polygon from "components/common/icons/downArrow";
import "./counterOrSelector.scss";

//arrow counter / selector
const CounterOrSelector = ({
  selectorType,
  value,
  setValue,
  isSelected,
  selectorWidth,
  selections,
  values,
}) => {
  const handleNumberChange = (action) => {
    if (action === "increase") {
      setValue((prev) => prev + 1);
    }

    if (action === "decrease") {
      if (value !== 1) {
        setValue((prev) => prev - 1);
      }
    }
  };

  const handleScaleNumberChange = (action) => {
    if (action === "increase") {
      setValue((prev) => ({ ...prev, number: prev.number + 1 }));
    }

    if (action === "decrease") {
      if (value !== 1) {
        setValue((prev) => ({ ...prev, number: prev.number - 1 }));
      }
    }
  };

  const handleSelectionValueChange = (action) => {
    const selectionArr = selections;
    const selectedValue = value;
    const currentIndex = selectionArr.indexOf(selectedValue);
    const nextValue = selectionArr[currentIndex + 1];
    const prevValue = selectionArr[currentIndex - 1];

    if (action === "increase") {
      if (nextValue) {
        setValue((prev) => ({
          ...prev,
          selected: nextValue,
        }));
      }
    }

    if (action === "decrease") {
      if (prevValue) {
        setValue((prev) => ({
          ...prev,
          selected: prevValue,
        }));
      }
    }
  };

  return (
    <div className="counter_or_selector_container">
      {selectorType === "number" ? (
        <div
          className={`counter_or_selector_body`}
          style={{ width: selectorWidth }}
        >
          <div
            className={`counter_flex counter_control counter_increase ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
            onClick={() => handleNumberChange("increase")}
          >
            <Polygon size={20} direction={"up"} />
          </div>
          <div
            className={`counter_flex counter_value ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
          >
            <input
              type="text"
              value={value}
              min={"1"}
              onChange={(value) => setValue(Number(value.target.value))}
            />
          </div>
          <div
            className={`counter_flex counter_control counter_decrease ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
            onClick={() => handleNumberChange("decrease")}
          >
            {value > 1 ? <Polygon size={20} direction={"down"} /> : "—"}
          </div>
        </div>
      ) : (
        ""
      )}
      {selectorType === "number_scale" ? (
        <div
          className={`counter_or_selector_body`}
          style={{ width: selectorWidth }}
        >
          <div
            className={`counter_flex counter_control counter_increase ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
            onClick={() => handleScaleNumberChange("increase")}
          >
            <Polygon size={20} direction={"up"} />
          </div>
          <div
            className={`counter_flex counter_value ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
          >
            {<input
              type="text"
              value={value}
              min={"1"}
              onChange={(value) => setValue((prev) => ({ ...prev, number: Number(value.target.value) }))}
            />}
          </div>
          <div
            className={`counter_flex counter_control counter_decrease ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
            onClick={() => handleScaleNumberChange("decrease")}
          >
            {value > 1 ? <Polygon size={20} direction={"down"} /> : "—"}
          </div>
        </div>
      ) : null}

      {selectorType === "selection_scale" ? (
        <div
          className={`counter_or_selector_body`}
          style={{ width: selectorWidth }}
        >
          <div
            className={`counter_flex counter_control counter_increase ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
            onClick={() => handleSelectionValueChange("increase")}
          >
            {selections.indexOf(value) < selections.length - 1 ? (
              <Polygon size={20} direction={"up"} />
            ) : (
              "—"
            )}
          </div>
          <div
            className={`counter_flex counter_value ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
          >
            {values.number > 1 ? value + "s" : value}
          </div>
          <div
            className={`counter_flex counter_control counter_decrease ${
              isSelected ? "counter_border_selected" : "counter_border"
            }`}
            onClick={() => handleSelectionValueChange("decrease")}
          >
            {selections.indexOf(value) > 0 ? (
              <Polygon size={20} direction={"down"} />
            ) : (
              "—"
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CounterOrSelector;
