import { getLatestChange } from "components/profileFunctions/profileFunctions";
import { getHistoricalChartsMeasurement } from "utils/helper";
import downRedIcon from "assets/images/red-down-arrow.png";
import upGreenIcon from "assets/images/up-green-arrow.png";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

function DLHEADER({ averageValue, userData, activity }) {
  let diffClass = "chart-container-detail-diff-up-color";

  if (getLatestChange(activity, userData) < 0) {
    diffClass = "chart-container-detail-diff-down-color";
  }

  return (
    <>
      <div className="chart-container-header">
        &nbsp;
        <div className="chart-container-detail">
          <div className="chart-container-detail-last-value">
            <p className="value-title">AVERAGE</p>
            <p>
              {averageValue.toFixed(1)}{" "}
              {getHistoricalChartsMeasurement(activity.id)}
            </p>
          </div>
          {getLatestChange(activity, userData) && (
            <div className="chart-container-detail-diff">
              <div className="chart-container-detail-diff-value-arrow">
                <div
                  className={
                    "chart-container-detail-diff-down-value " + diffClass
                  }
                >
                  {getLatestChange(activity, userData)}%
                </div>
                <div className="chart-container-detail-diff-image-container">
                  {getLatestChange(activity, userData) > 0 && (
                    <img
                      style={{ width: 12, height: 12 }}
                      alt="down icon to click"
                      src={upGreenIcon}
                    />
                  )}
                  {getLatestChange(activity, userData) < 0 && (
                    <img
                      style={{ width: 12, height: 12 }}
                      alt="up icon to click"
                      src={downRedIcon}
                    />
                  )}
                </div>
              </div>
              <div style={{ paddingLeft: "0.5rem" }}>
                 <MainTooltip
                  tooltipText={
                    `Latest session percentage ${
                      getLatestChange(activity, userData) < 0
                        ? "decrease"
                        : "increase"
                    } from average`
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DLHEADER;
