import "./userProfileBar.scss";
import Button from "components/common/button/button";
import { useState } from "react";
import CreateAthlete from "components/common/createAthlete/createAthlete";
import moment from "moment";
export default function UserProfileBar(props) {
  const [modal, setModal] = useState(false);

  const { orgData, userData, history } = props;

  console.log(userData)

  const userInititals = userData?.player
    ?.split(" ")
    .map((name) => name[0])
    .join("");
  const userNameSplit = userData?.player?.split(" ");

  const { age, height, weight, dob } = userData;

  // getting the organisation of the player
  const orgName = orgData;
  const testOrg = orgName?.map((org) =>
    org.players.filter((players) => players.playerID === userData.playerID)
  );
  const orgIndex = testOrg.indexOf(
    testOrg.find((element) => element.length > 0)
  );
  const organisationName = orgName[orgIndex].Name;

  function editForm() {
    setModal((prevEditForm) => !prevEditForm);
    document.body.classList.add("modal-open");
  }
  return (
    <div className="user-profile-bar">
      <div className="user-profile">
        <div className="user-profile-image">
          {userData.playerIconURL !== null &&
          userData.playerIconURL.length > 1 ? (
            <img
              src={userData.playerIconURL}
              alt="user profile"
              id="player-profile-icon"
            />
          ) : (
            <p>{userInititals}</p>
          )}
        </div>

        <div className="user-profile-name_org">
          <div className="user-profile-name">
            <div className="athlete-name">
              {/* limit the number of names displayed on the athlete profile bar */}
              {userNameSplit.length <= 3 ? (
                userNameSplit.map((name, i) => (
                  <div key={i}>
                    <h2>{name}&nbsp;</h2>
                    <br />
                  </div>
                ))
              ) : userNameSplit.length === 4 ? (
                //if the athlete has more than 3 values
                <>
                  {userNameSplit.slice(0, 2).map((name, i) => (
                    <div key={i}>
                      <h2>{name}</h2>
                      <br />
                    </div>
                  ))}
                  <div style={{ display: "flex" }}>
                    {userNameSplit
                      .slice(2, userNameSplit.length)
                      .map((name, i) => (
                        <div key={i}>
                          <h2 style={{ marginRight: 10 }}>{name}&nbsp;</h2>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                //if athlete has more 4 or more names
                <>
                  {userNameSplit.slice(0, 2).map((name, i) => (
                    <div key={i}>
                      <h2>{name}&nbsp;</h2>
                      <br />
                    </div>
                  ))}
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex" }}>
                      {userNameSplit.slice(2, 4).map((name, i) => (
                        <div key={i}>
                          <h2 style={{ marginRight: 10 }}>{name}</h2>
                        </div>
                      ))}
                      {userNameSplit
                        .slice(4, userNameSplit.length)
                        .map((name, i) => (
                          <div key={i}>
                            <h2 style={{ marginRight: 10 }}>...</h2>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="user-profile-org">
            <h3>{organisationName}</h3>
          </div>
        </div>
        <div className="user-edit-mobile">
          <CreateAthlete
            open={modal}
            close={setModal}
            player={userData}
            button={<Button value={"Edit Athlete"} onClickHandler={editForm} />}
            edit={true}
            history={history}
            existingOrg={orgData}
          />
        </div>
      </div>
      <div className="user-stats">
        <div className="user-stat">
          <h4>TEAM</h4>
          <p>Team 1</p>
        </div>
        <div className="user-stat">
          <h4>AGE</h4>
          <p>{dob ? moment().diff(dob, "years") : age}</p>
        </div>
        <div className="user-stat">
          <h4>HEIGHT</h4>
          <p>{height} cm</p>
        </div>
        <div className="user-stat">
          <h4>POSITION</h4>
          <p>Position 1</p>
        </div>
        {/* <div className='user-stat'>
                    <h4>GENDER</h4>
                    <p>
                        {   
                            gender.toLowerCase() === "m" ? "Male" :
                            gender.toLowerCase() === "f" ? "Female" : "Other"
                        }
                    </p>
                </div> */}
        <div className="user-stat">
          <h4>WEIGHT</h4>
          <p>{weight} kg</p>
        </div>
      </div>
      <div className="user-edit">
        <CreateAthlete
          open={modal}
          close={setModal}
          player={userData}
          button={<Button value={"Edit Athlete"} onClickHandler={editForm} />}
          edit={true}
          history={history}
          existingOrg={orgData}
        />
      </div>
    </div>
  );
}
