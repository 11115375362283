/* eslint-disable */
import LoaderIcon from "../icons/loader";
import "../reuseableButtonComponent/mainButton.scss";
import { motion } from "framer-motion";
import Spinner from "../spinner/spinner";

{
  /* 
BUTTON USAGE EXAMPLE
<MainButton
  buttonText="Tags"
  ButtonIcon={TagsIcon}
  buttonIconSize={24}
  buttonIconColour={"white"}
  buttonFontSize={14}
  buttonDisabled={false}
  buttonTooltip={"Why this fellar disabled 🤠"}
  buttonPop={true}
/>; */
}

const buttonVariants = {
  rest: {
    scale: 1,
    opacity: 1,
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",
  },
  pressed: {
    scale: 0.95,
    opacity: 1,
    boxShadow: "0px 0px 0px 5px rgba(0,0,0,0.1)",
  },
};

const buttonTransition = {
  duration: 0.1,
  ease: "easeInOut",
};

const MainButton = (props) => {
  const {
    buttonText = "Button Text",
    ButtonIcon = false,
    buttonIconSize = 24,
    buttonIconColour = "white",
    buttonFontSize = 14,
    buttonDisabled = false,
    buttonTooltip = "",
    buttonPop = false,
    buttonImportant = false,
    buttonLoading = false,
    buttonActiveBorder = false,
  } = props;
  return (
    <motion.div
      variants={buttonVariants}
      transition={buttonTransition}
      whileTap="pressed"
      whileHover="rest"
      className={`main-button ${buttonDisabled ? "main-button-disabled" : ""} ${
        buttonPop ? "main-button-pop" : ""
      } ${buttonImportant ? "main-button-important" : ""} ${buttonActiveBorder ? "main-button-active-border" : ""}`}
    >
      <div className="main-button-contents">
        {ButtonIcon ? (
          <div
            className={`main-button-icon ${
              buttonLoading ? "main-icon-loading" : ""
            }`}

          >
            {!buttonLoading ? (
              <ButtonIcon
                size={buttonIconSize}
                color={buttonIconColour}
                fillOpacity={buttonDisabled ? "0.6" : "1"}
              />
            ) : (
              // <LoaderIcon
              //   size={buttonIconSize}
              //   color={buttonIconColour}
              //   fillOpacity={buttonDisabled ? "0.6" : "1"}
              // />
              <Spinner style={{width: buttonIconSize, height: buttonIconSize}}/>
            )}
          </div>
        ) : (
          null
        )}
        <p
          className={`main-button-text ${
            buttonDisabled ? "main-button-text-disabled" : ""
          } ${ButtonIcon ? "main-button-text-icon-padding" : ""}`}
          style={{ fontSize: buttonFontSize }}
        >
          {buttonText}
        </p>
        {buttonDisabled ? (
          <div className="main-button-tooltip">
            <div className="main-button-tooltip-text">
              <p>{buttonTooltip}</p>
            </div>
          </div>
        ) : (
          null
        )}
      </div>
    </motion.div>
  );
};

export default MainButton;
