/* eslint-disable */
const FileIcon = ({ size = "18", color = "#4B5667", reverse = false }) => {
  const style = reverse ? { transform: "scaleX(-1)" } : {};

  return (
    <div style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="icon icon-tabler icons-tabler-outline icon-tabler-sock"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        <path d="M11 14h1v4h1" />
        <path d="M12 11h.01" />
      </svg>
    </div>
  );
};

export default FileIcon;
