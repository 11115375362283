/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteAthleteInTeamAPI,
  getTeamAthletesAPI,
  setAthleteInTeamAPI,
  setAthletesInTeamAPI,
} from "api/teams";
import { resetState } from "./resetSlice";

const initialState = {
  teamsAthletes: [],
  teamAthletesStatus: "idle",
};

//removes duplated player id's
function removeDuplicates(arr) {
  let unique = {};
  let result = [];

  for (let i = 0; i < arr.length; i++) {
    if (!unique[arr[i].playerid]) {
      result.push(arr[i]);
      unique[arr[i].playerid] = true;
    }
  }

  return result;
}

function updateArray(oldArray, newArray) {
  // Create a map of old values for quick lookup
  let oldValues = {};
  oldArray?.forEach((item) => {
    oldValues[item.playerid] = item;
  });

  // Update old array and add new items
  newArray?.forEach((newItem) => {
    // Update existing item or add new item
    oldValues[newItem.playerid] = newItem;
  });

  // Convert the map back to an array
  let updatedArray = Object?.values(oldValues);
  return updatedArray;
}

export const getTeamsAthletes = createAsyncThunk(
  "teamsAthletes/getTeamsAthletes",
  async (teamID) => {
    const response = await getTeamAthletesAPI(teamID);
    return response.data;
  }
);

export const deleteAthleteInTeam = createAsyncThunk(
  "teamsAthletes/deleteTeamAthlete",
  async (postBody, { dispatch }) => {
    try {
      const response = await deleteAthleteInTeamAPI(postBody);
      await dispatch(getTeamsAthletes(postBody.teamid));
      return await response.data;
    } catch (error) {
      return await error;
    }
  }
);

export const setAthleteInTeam = createAsyncThunk(
  "teamsAthletes/setAthleteInTeam",
  async (postBody, { dispatch }) => {
    try {
      const response = await setAthleteInTeamAPI(postBody);
      await dispatch(getTeamsAthletes(postBody.teamid));
      return await response.data;
    } catch (error) {
      return await error;
    }
  }
);

export const setAthletesInTeam = createAsyncThunk(
  "teamsAthletes/setAthletesInTeam",
  async (postBody, { dispatch }) => {
    try {
      const response = await setAthletesInTeamAPI(postBody);
      await dispatch(getTeamsAthletes(postBody.teamid));
      return await response.data;
    } catch (error) {
      return await error;
    }
  }
);

const teamsAthletesSlice = createSlice({
  name: "teamAthlete",
  initialState,
  reducers: {
    resetTeamAthletesData: (state) => {
      Object.assign(state, initialState);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTeamsAthletes.pending, (state) => {
      state.teamAthletesStatus = "loading";
    });
    builder.addCase(getTeamsAthletes.fulfilled, (state, action) => {
      state.teamsAthletes = updateArray(state.teamsAthletes, action.payload);
      state.teamAthletesStatus = "succeeded";
    });
    builder
      .addCase(getTeamsAthletes.rejected, (state) => {
        state.teamAthletesStatus = "failed";
      })
      .addCase(resetState, () => {
        return initialState;
      });
  },
});

export const {resetTeamAthletesData} = teamsAthletesSlice.actions

export const selectedTeamAthleteList = (state) => state.teamAthlete;

export default teamsAthletesSlice.reducer;
