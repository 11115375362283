import PlayerActivity2Wrapper from "./users/PlayerActivity2Wrapper/playerActivity2Wrapper";


const SessionInput = (props) => {
  
  return (
    <>
      {/* <PlayerActivity match={props.match} history={props.history} playerid={parseInt(props.match.params.id)} fromSessionInput />  */}
      <PlayerActivity2Wrapper
        match={props.match}
        history={props.history}
        fromSessionInput
      />
    </>
  );
};

export default SessionInput;
