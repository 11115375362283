import { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { UserProfile_URL } from 'utils/urls';
import { selectOrganisation } from 'store/orgSlice';



function SearchBar(props) {
    const [searchBar, setSearchBar] = useState("");
    const [searchFocus, setSearchFocus] = useState(false)

    useEffect(() => {
        if (props.location.pathname !== props.history.location.pathname) {
            setSearchBar("");
        }
    }, [props.location.pathname, props.history.location.pathname]);

    function handleSearchBarChange(e) {
        setSearchBar(e.target.value);
    }

    function goToPlayerProfilePage(player) {
        props.history.push(UserProfile_URL + '/' + player.playerID);
        setSearchBar("")
    }

    function searchFocused() {
        setSearchFocus(true)
    }

    function unFocus() {
        setSearchFocus(false)
    }

    return (
        <>
            <input id='nav-search' className="nav-search" placeholder="Search athletes" type="text" onChange={(event) => { handleSearchBarChange(event) }} value={searchBar} onFocus={() => searchFocused()} onBlur={() => setTimeout(() => { unFocus() }, 200)} />
            <div className='nav-search-dropdown'>
                {
                    searchBar && searchFocus
                        ? props.userOrganisations.orgList
                            .flatMap(({ players }) => players)
                            .filter(({ player }) => {
                                const searchTerm = searchBar.toLowerCase();
                                return (
                                    player.toLowerCase().startsWith(searchTerm) &&
                                    player.toLowerCase() !== searchTerm
                                );
                            })
                            .slice(0, 10)
                            .map(({ player, playerID }, index) => (
                                <div onClick={() => goToPlayerProfilePage({ playerID })} className="nav-search-dropdown-row" key={index}>
                                    <p>{player}</p>
                                </div>
                            ))
                        : null
                }
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    userOrganisations: selectOrganisation(state),
});
// You'll need to connect this component to the Redux store if you want it to have access to the userOrganisations state
export const SearchBarConnected = withRouter(connect(mapStateToProps)(SearchBar));
