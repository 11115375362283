/* eslint-disable */
import "./athleteOverview.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAthleteHistoricalGait,
  getAthleteHistoricalLoad,
  getAthleteHistoricalGroundContact,
  selectAthlete,
  getAthleteHistoricalRawSessions,
} from "store/athleteSlice";
import { usePrevious } from "../hooks/usePrevious";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { getSessionList } from "store/sessionListSlice";
import { resetAllData } from "store/sessionSlice";
import { useCheckForJumpData } from "../hooks/useCheckForJumpData";
import { getAthletesHistoricalActivity, selectUserData } from "store/userSlice";
import AthleteOverviewTable from "./athleteOverviewTable/AthleteOverviewTable";
import PlayerActivityDetails from "components/users/PlayerActivityDetails2";
import { setGraphModal, setSelectedSessionId } from "store/playerActivitySlice";
import { selectUser, userState } from "store/authSlice";
import { updateUserLayoutAPI } from "api/user";
import AthleteProfileChart from "./athleteProfileCharts/AthleteProfileChart";
import {
  LoadCustomTooltip,
  CustomLineTooltip,
  CustomAsymLineTooltip,
} from "./athleteOverviewHelpers/athleteOverviewCustomTooltips";
import { msToTime } from "utils/helper";
import AthleteOverviewMainTable from "./athleteOverviewTable/AthleteOverviewMainTable";
import AthleteOverviewChart from "./athleteOverviewCharts/AthleteOverviewChart";
import moment from "moment";

const AthleteOverview2 = (props) => {
  const { athleteID, handleLoadClick, handleLoadClickTable, athlete, actions } =
    props;

  const dispatch = useDispatch();
  const checkForJumpData = useCheckForJumpData();
  const { graphModal, sessionList, selectedSessionId, session } = useSelector(
    (state) => ({
      graphModal: state.playerActivity.graphModal,
      selectedSessionId: state.playerActivity.selectedSessionId,
      sessionList: state.sessionList,
      session: state.session,
    })
  );
  const userData = useSelector(selectUserData);

  const loggedUserData = useSelector(userState);

  // State declaration
  const [filterDate, setFilterDate] = useState({
    startDate: null,
    endDate: null,
  });

  const [disabledMetrics, setDisabledMetrics] = useState([]);

  //table metrics
  const [numberOfLatestSessions, setNumberOfLatestSessions] = useState(8);
  const [filteringMethod, setFilteringMethod] = useState({
    latestSessions: false,
    presetRange: false,
    dateRange: true,
  });
  const [rangeFiltering, setRangeFiletering] = useState({
    number: 1,
    selected: "Day",
    selections: ["Day", "Week", "Month", "Year"],
  });
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [overviewMetricData, setOverviewMetricData] = useState([]);
  const [dateOverviewMetricData, setDateOverviewMetricData] = useState([]);
  const [maxMinAvg, setMaxMinAvg] = useState("max");

  //loading state
  const [isLoading, setIsLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const timeoutRef = useRef(null);

  //tags selected state
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteringByTags, setFilteringByTags] = useState([]);
  const [uniqueArrayOfTags, setUniqueArrayOfTags] = useState([]);

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem("userLayout")
    ).athleteOverview;
    if (
      localStorageData &&
      localStorageData?.hasOwnProperty("numberOfLatestSessions")
    ) {
      setNumberOfLatestSessions(localStorageData.numberOfLatestSessions);
    }
    if (localStorageData && localStorageData?.hasOwnProperty("maxMinAvg")) {
      setMaxMinAvg(localStorageData.maxMinAvg);
    }
    if (localStorageData?.hasOwnProperty("filteringMethod")) {
      setFilteringMethod(localStorageData.filteringMethod);
    }
    if (
      localStorageData &&
      localStorageData?.hasOwnProperty("rangeFiltering")
    ) {
      setRangeFiletering(localStorageData.rangeFiltering);
    }
    if (
      localStorageData &&
      localStorageData?.hasOwnProperty("disabledMetrics")
    ) {
      setDisabledMetrics(localStorageData.disabledMetrics);
    }
  }, []);

  useEffect(() => {
    if (athlete.loadedAthleteIds === athleteID) return;

    const fetchHistoricalData = () => {
      if (
        athlete.athleteHistoricaRawSessions.status === "idle" ||
        athlete.loadedAthleteIds !== athleteID
      ) {
        actions.getAthleteHistoricalRawSessions(athleteID);
      }
    };

    fetchHistoricalData();
  }, [athleteID]);

  useEffect(() => {
    const fetchSessionList = async () => {
      if (
        sessionList.sessionList.length === 0 &&
        sessionList.status === "idle" &&
        sessionList.status !== "loading"
      ) {
        actions.getSessionList();
      }

      if (session.runData.loaded || session.jumpData.loaded) {
        dispatch(resetAllData());
      }

      if (
        selectedSessionId !==
        (session.runData.sessionID || session.jumpData.sessionID)
      ) {
        checkForJumpData();
      }
    };

    fetchSessionList();
  }, [graphModal, sessionList.sessionList.data, selectedSessionId]);

  useEffect(() => {
    const newOverviewMetricData = [];

    const rawData =
      filteringByTags.length > 0
        ? athlete.athleteHistoricaRawSessions.data.filter((session) =>
            JSON.parse(session.tags).some((tag) =>
              filteringByTags.includes(tag.TID)
            )
          )
        : athlete.athleteHistoricaRawSessions.data;

    const athleteHistoricalLoad = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, []).slice(0, numberOfLatestSessions)
      : filteringMethod.presetRange
      ? sortDataByActivity(rawData, [])
      : sortDataByActivity(rawData, []);

    const loadData = athleteHistoricalLoad.map((item) =>
      processArray(
        item,
        [
          "rawMetrics.loadResults.totalLoad",
          "rawMetrics.loadResults.relativeLoad",
          "rawMetrics.loadResults.rightLoad",
          "rawMetrics.loadResults.leftLoad",
        ],
        maxMinAvg,
        ["value", "toggledValue", "rightValue", "leftValue"]
      )
    );

    // 1, 2 , 3
    const athleteHistoricalGroundContact = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [1, 2, 3]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [1, 2, 3]);

    const groundContactData = processNestedArrays(
      athleteHistoricalGroundContact,
      maxMinAvg,
      ["symmetryResults", "leftResults", "rightResults"],
      ["contactTime", "contactTime", "contactTime"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.symmetryResults.contactTime",
          "rawMetrics.leftResults.contactTime",
          "rawMetrics.rightResults.contactTime",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    // 3
    const walkGroundContactTime = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [3]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [3]);

    const walkGroundContactData = processNestedArrays(
      walkGroundContactTime,
      maxMinAvg,
      ["meanResults", "leftResults", "rightResults"],
      ["contactTime", "contactTime", "contactTime"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.meanResults.contactTime",
          "rawMetrics.leftResults.contactTime",
          "rawMetrics.rightResults.contactTime",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    // 2
    const jogGroundContactTime = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [2]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [2]);

    const jogGroundContactData = processNestedArrays(
      jogGroundContactTime,
      maxMinAvg,
      ["meanResults", "leftResults", "rightResults"],
      ["contactTime", "contactTime", "contactTime"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.meanResults.contactTime",
          "rawMetrics.rightResults.contactTime",
          "rawMetrics.leftResults.contactTime",
        ],
        maxMinAvg,
        ["value", "rightValue", "leftValue"]
      )
    );

    // 1
    const sprintGroundContactTime = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [1]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [1]);

    const sprintGroundContactData = processNestedArrays(
      sprintGroundContactTime,
      maxMinAvg,
      ["meanResults", "leftResults", "rightResults"],
      ["contactTime", "contactTime", "contactTime"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.meanResults.contactTime",
          "rawMetrics.rightResults.contactTime",
          "rawMetrics.leftResults.contactTime",
        ],
        maxMinAvg,
        ["value", "rightValue", "leftValue"]
      )
    );

    // 4
    const doubleLegCountermovementJumpData = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [4]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [4]);

    const dLCountermovementJumpData = processNestedArrays(
      doubleLegCountermovementJumpData,
      maxMinAvg,
      ["combinedResults", "leftResults", "rightResults"],
      ["jumpHeight", "jumpHeight", "jumpHeight"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.combinedResults.jumpHeight",
          "rawMetrics.leftResults.jumpHeight",
          "rawMetrics.rightResults.jumpHeight",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    // 10
    const doubleLegRepeatedHopsData = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [10]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [10]);

    const dLRepeatedHopsData = processNestedArrays(
      doubleLegRepeatedHopsData,
      maxMinAvg,
      ["combinedResults", "leftResults", "rightResults"],
      ["RSI", "RSI", "RSI"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.combinedResults.RSI",
          "rawMetrics.leftResults.RSI",
          "rawMetrics.rightResults.RSI",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    // 5
    const doubleLegDropJump = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [5]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [5]);

    const dlDropJump = processNestedArrays(
      doubleLegDropJump,
      maxMinAvg,
      ["combinedResults", "leftResults", "rightResults"],
      ["RSI", "RSI", "RSI"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.combinedResults.RSI",
          "rawMetrics.leftResults.RSI",
          "rawMetrics.rightResults.RSI",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );
    // 9
    const doubleLegSquatJump = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [9]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [9]);

    const dlSquatJump = processNestedArrays(
      doubleLegSquatJump,
      maxMinAvg,
      ["combinedResults", "leftResults", "rightResults"],
      ["jumpHeight", "jumpHeight", "jumpHeight"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.combinedResults.jumpHeight",
          "rawMetrics.leftResults.jumpHeight",
          "rawMetrics.rightResults.jumpHeight",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    //17
    const singleLegRepeatedHops = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [17]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [17]);

    const slRepeatedHops = processNestedArrays(
      singleLegRepeatedHops,
      maxMinAvg,
      ["symmetryResults", "leftResults", "rightResults"],
      ["RSI", "RSI", "RSI"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.symmetryResults.RSI",
          "rawMetrics.leftResults.RSI",
          "rawMetrics.rightResults.RSI",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    // 15
    const singleLegSquatJump = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [15]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [15]);

    const sLSquatJump = processNestedArrays(
      singleLegSquatJump,
      maxMinAvg,
      ["symmetryResults", "leftResults", "rightResults"],
      ["jumpHeight", "jumpHeight", "jumpHeight"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.symmetryResults.jumpHeight",
          "rawMetrics.leftResults.jumpHeight",
          "rawMetrics.rightResults.jumpHeight",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    // 14
    const singleLegCountermovementJumpData = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [14]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [14]);

    const slCountermovementJumpData = processNestedArrays(
      singleLegCountermovementJumpData,
      maxMinAvg,
      ["symmetryResults", "leftResults", "rightResults"],
      ["jumpHeight", "jumpHeight", "jumpHeight"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.symmetryResults.jumpHeight",
          "rawMetrics.leftResults.jumpHeight",
          "rawMetrics.rightResults.jumpHeight",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    //16
    const singleLegDropJumpData = filteringMethod.latestSessions
      ? sortDataByActivity(rawData, [16]).slice(0, numberOfLatestSessions)
      : sortDataByActivity(rawData, [16]);

    const sLDropJumpData = processNestedArrays(
      singleLegDropJumpData,
      maxMinAvg,
      ["symmetryResults", "leftResults", "rightResults"],
      ["RSI", "RSI", "RSI"]
    ).map((item) =>
      processArray(
        item,
        [
          "rawMetrics.symmetryResults.RSI",
          "rawMetrics.leftResults.RSI",
          "rawMetrics.rightResults.RSI",
        ],
        maxMinAvg,
        ["value", "leftValue", "rightValue"]
      )
    );

    {
      !disabledMetrics.includes("Daily Load") &&
        newOverviewMetricData.push({
          metric: "Daily Load",
          toggledMetric: "Relative Load",
          data: loadData?.length ? loadData : [],
          isMetric: false,
          primaryKey: "value",
          primaryToggleKey: "toggledValue",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: true,
          togglerKeys: ["Load", "Relative Load"],
          tooltip:
            "Daily load represents to individuals physical effort throughout the day, either by individual limb or accumulative load",
          tooltipToggled:
            "Relative Load is daily load relative to the duration of the sessions collected that day",
          chartValueAxisLabel: "Total Load (a.u.)",
          chartValueAxisLabelToggled: "Total Relative Load (a.u.)",
          chartCategoryAxisLabel: "",
          chartType: "bar",
          xAxisKey: "TSCreated",
          handleElementClick: handleLoadClick,
          customTooltip: LoadCustomTooltip,
          hasSideToggler: false,
          measurementUnit: "a.u",
        });
    }

    {
      !disabledMetrics.includes("Ground Contact") &&
        newOverviewMetricData.push({
          metric: "Ground Contact",
          data: groundContactData?.length ? groundContactData : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip:
            "Ground contact time asymmetry is defined as the absolute difference between the ground contact time measured for each foot expressed as a percentage of the maximum ground contact time recorded for either foot. The side with the lower ground contact time value will be more asymmetric",
          chartValueAxisLabel: "% Asymmetry",
          chartCategoryAxisLabel: "",
          chartType: "asym-bar",
          xAxisKey: "TSCreated",
          customTooltip: CustomAsymLineTooltip,
          hasSideToggler: true,
          tooltipLeft: "Left Contact Time",
          tooltipRight: "Right Contact Time",
          tooltipValue: "Ground Contact",
          measurementUnit: "%",
        });
    }

    {
      !disabledMetrics.includes("Sprint Ground Contact Time") &&
        newOverviewMetricData.push({
          metric: "Sprint Ground Contact Time",
          data: sprintGroundContactData?.length ? sprintGroundContactData : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: false,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Ground contact time",
          chartValueAxisLabel: "Time (s)",
          chartCategoryAxisLabel: "",
          chartType: "line",
          xAxisKey: "TSCreated",
          customTooltip: CustomLineTooltip,
          hasSideToggler: true,
          tooltipLeft: "Left Contact Time",
          tooltipRight: "Right Contact Time",
          tooltipValue: "Ground Contact",
          measurementUnit: "s",
        });
    }

    {
      !disabledMetrics.includes("Jog Ground Contact Time") &&
        newOverviewMetricData.push({
          metric: "Jog Ground Contact Time",
          data: jogGroundContactData?.length ? jogGroundContactData : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: false,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Ground contact time",
          chartValueAxisLabel: "Time (s)",
          chartCategoryAxisLabel: "",
          chartType: "line",
          xAxisKey: "TSCreated",
          customTooltip: CustomLineTooltip,
          hasSideToggler: true,
          tooltipLeft: "Left Contact Time",
          tooltipRight: "Right Contact Time",
          tooltipValue: "Ground Contact",
          measurementUnit: "s",
        });
    }

    {
      !disabledMetrics.includes("Walk Ground Contact Time") &&
        newOverviewMetricData.push({
          metric: "Walk Ground Contact Time",
          data: walkGroundContactData?.length ? walkGroundContactData : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: false,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Ground contact time",
          chartValueAxisLabel: "Time (s)",
          chartCategoryAxisLabel: "",
          chartType: "line",
          xAxisKey: "TSCreated",
          customTooltip: CustomLineTooltip,
          hasSideToggler: true,
          tooltipLeft: "Left Contact Time",
          tooltipRight: "Right Contact Time",
          tooltipValue: "Ground Contact",
          measurementUnit: "s",
        });
    }

    {
      !disabledMetrics.includes("Double Leg Countermovement Jump") &&
        newOverviewMetricData.push({
          metric: "Double Leg Countermovement Jump",
          data: dLCountermovementJumpData?.length
            ? dLCountermovementJumpData
            : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Double Leg Countermovement Jump (cm)",
          chartValueAxisLabel: "Height (cm)",
          chartCategoryAxisLabel: "",
          chartType: "line",
          customTooltip: CustomLineTooltip,
          xAxisKey: "TSCreated",
          hasSideToggler: true,
          tooltipValue: "Jump Height",
          tooltipLeft: "Left Foot Height",
          tooltipRight: "Right Foot Height",
          measurementUnit: "cm",
        });
    }

    {
      !disabledMetrics.includes("Double Leg Repeated Hops") &&
        newOverviewMetricData.push({
          metric: "Double Leg Repeated Hops",
          data: dLRepeatedHopsData?.length ? dLRepeatedHopsData : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Double Leg Repeated Hops (RSI)",
          chartValueAxisLabel: "Reactive Strength Index (RSI)",
          chartCategoryAxisLabel: "",
          chartType: "line",
          customTooltip: CustomLineTooltip,
          xAxisKey: "TSCreated",
          hasSideToggler: true,
          tooltipValue: "RSI",
          tooltipLeft: "Left Foot RSI",
          tooltipRight: "Right Foot RSI",
          measurementUnit: "RSI",
        });
    }

    {
      !disabledMetrics.includes("Double Leg Drop Jump") &&
        newOverviewMetricData.push({
          metric: "Double Leg Drop Jump",
          data: dlDropJump?.length ? dlDropJump : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Double Leg Drop Jump (RSI)",
          chartValueAxisLabel: "Reactive Strength Index (RSI)",
          chartCategoryAxisLabel: "",
          chartType: "line",
          xAxisKey: "TSCreated",
          customTooltip: CustomLineTooltip,
          hasSideToggler: true,
          tooltipValue: "RSI",
          tooltipLeft: "Left Foot RSI",
          tooltipRight: "Right Foot RSI",
          measurementUnit: "RSI",
        });
    }

    {
      !disabledMetrics.includes("Double Leg Squat Jump") &&
        newOverviewMetricData.push({
          metric: "Double Leg Squat Jump",
          data: dlSquatJump?.length ? dlSquatJump : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Double Leg Squat Jump (Height)",
          chartValueAxisLabel: "Height (cm)",
          chartCategoryAxisLabel: "",
          chartType: "line",
          xAxisKey: "TSCreated",
          customTooltip: CustomLineTooltip,
          hasSideToggler: true,
          tooltipValue: "Jump Height",
          tooltipLeft: "Left Foot Height",
          tooltipRight: "Right Foot Height",
          measurementUnit: "cm",
        });
    }

    {
      !disabledMetrics.includes("Single Leg Repeated Hops") &&
        newOverviewMetricData.push({
          metric: "Single Leg Repeated Hops",
          data: singleLegRepeatedHops?.length ? slRepeatedHops : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Single Leg Repeated Hops (%)",
          chartValueAxisLabel: "% Asymmetry",
          chartCategoryAxisLabel: "",
          chartType: "asym-bar",
          xAxisKey: "TSCreated",
          customTooltip: CustomAsymLineTooltip,
          hasSideToggler: true,
          tooltipValue: "RSI",
          tooltipLeft: "Left Foot RSI",
          tooltipRight: "Right Foot RSI",
          measurementUnit: "%",
        });
    }

    {
      !disabledMetrics.includes("Single Leg Squat Jump") &&
        newOverviewMetricData.push({
          metric: "Single Leg Squat Jump",
          data: singleLegSquatJump?.length ? sLSquatJump : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip:
            "Single Leg Squat Jump (%) negative values indicate asymmetry left, positive values indicate asymmetry right",
          chartValueAxisLabel: "% Asymmetry",
          chartCategoryAxisLabel: "",
          chartType: "asym-bar",
          xAxisKey: "TSCreated",
          hasSideToggler: true,
          customTooltip: CustomAsymLineTooltip,
          tooltipLeft: "Left Foot Height",
          tooltipRight: "Right Foot Height",
          tooltipValue: "Jump Height",
          measurementUnit: "%",
        });
    }

    {
      !disabledMetrics.includes("Single Leg Countermovement Jump") &&
        newOverviewMetricData.push({
          metric: "Single Leg Countermovement Jump",
          data: singleLegCountermovementJumpData?.length
            ? slCountermovementJumpData
            : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip:
            "Single Leg Countermovement Jump (%) negative values indicate asymmetry left, positive values indicate asymmetry right",
          chartValueAxisLabel: "% Asymmetry",
          chartCategoryAxisLabel: "",
          chartType: "asym-bar",
          xAxisKey: "TSCreated",
          hasSideToggler: true,
          customTooltip: CustomAsymLineTooltip,
          tooltipLeft: "Left Foot Height",
          tooltipRight: "Right Foot Height",
          tooltipValue: "Jump Height Asymmetry",
          measurementUnit: "%",
        });
    }
    {
      !disabledMetrics.includes("Single Leg Drop Jump") &&
        newOverviewMetricData.push({
          metric: "Single Leg Drop Jump",
          data: singleLegDropJumpData?.length ? sLDropJumpData : [],
          isMetric: true,
          primaryKey: "value",
          bilateral: true,
          secondaryKeys: ["leftValue", "rightValue"],
          toggleable: false,
          tooltip: "Single Leg Drop Jump (%)",
          chartValueAxisLabel: "% Asymmetry",
          chartCategoryAxisLabel: "",
          chartType: "asym-bar",
          xAxisKey: "TSCreated",
          hasSideToggler: true,
          customTooltip: CustomAsymLineTooltip,
          tooltipLeft: "Left Foot RSU",
          tooltipRight: "Right Foot RSU",
          tooltipValue: "Jump Height Asymmetry",
          measurementUnit: "%",
        });
    }

    setOverviewMetricData(newOverviewMetricData);

    //sym res jump height
  }, [
    athlete,
    numberOfLatestSessions,
    filteringMethod,
    disabledMetrics,
    maxMinAvg,
    filteringByTags,
  ]);

  useEffect(() => {
    const arrayOfTags =
      maxMinAvg !== "avg" && athlete.athleteHistoricaRawSessions.data.length
        ? athlete.athleteHistoricaRawSessions.data.flatMap((item) =>
            JSON.parse(item.tags)
          )
        : [];

    const uniqueTIDs = new Set();

    const uniqueArrayOfTagsVar =
      maxMinAvg !== "avg" && arrayOfTags.length
        ? arrayOfTags.filter((item) => {
            if (
              item &&
              item.TID !== null &&
              item.TID !== undefined &&
              !uniqueTIDs.has(item.TID)
            ) {
              uniqueTIDs.add(item.TID);
              return true;
            }
            return false;
          })
        : [];

    setUniqueArrayOfTags(uniqueArrayOfTagsVar);
  }, [athlete]);

  useEffect(() => {
    // Update the last updated time whenever overviewMetricData changes
    setLastUpdated(Date.now());

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to log "rerender" after 3 seconds
    timeoutRef.current = setTimeout(() => {
      const currentTime = Date.now();
      // Check if 3 seconds have passed since the last update
      if (currentTime - lastUpdated >= 3000) {
        setIsLoading(false);
      }
    }, 3000);

    // Clean up the timeout on component unmount or before running the effect again
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [overviewMetricData]);

  const getPastDate = (number, period) => {
    const currentDate = new Date();

    switch (period.toLowerCase()) {
      case "day":
      case "days":
        currentDate.setDate(currentDate.getDate() - number);
        break;
      case "week":
      case "weeks":
        currentDate.setDate(currentDate.getDate() - number * 7);
        break;
      case "month":
      case "months":
        currentDate.setMonth(currentDate.getMonth() - number);
        break;
      case "year":
      case "years":
        currentDate.setFullYear(currentDate.getFullYear() - number);
        break;
      default:
        throw new Error(
          "Invalid period. Use 'Day', 'Week', 'Month', or 'Year'."
        );
    }

    return currentDate;
  };

  const sortDataByActivity = (data, activityId) => {
    let returnedData = [];
    if (data.length) {
      if (activityId.length) {
        returnedData = data
          .filter((item) => activityId.includes(item.activity))
          .map((item) => ({
            ...item,
            rawMetrics: JSON.parse(item.rawMetrics),
          }));
      } else {
        returnedData = data?.map((item) => ({
          ...item,
          rawMetrics: JSON.parse(item.rawMetrics),
        }));
      }
    }

    if (filteringMethod.dateRange) {
      const startDate = filterDate.startDate
        ? new Date(filterDate.startDate)
        : null;
      const endDate = filterDate.endDate ? new Date(filterDate.endDate) : null;

      if (overviewMetricData.length) {
        const newMetricDataSorted = returnedData.filter((item) => {
          const metricDate = item?.TSCreated ? new Date(item?.TSCreated) : null;
          if (
            (startDate &&
              metricDate &&
              metricDate.getTime() <= startDate.getTime()) ||
            (endDate && metricDate && metricDate.getTime() >= endDate.getTime())
          ) {
            return false;
          }
          return true;
        });

        return groupByDate(newMetricDataSorted);
      }
    }

    if (filteringMethod.presetRange) {
      return groupByDate(
        filterByDateRange(
          returnedData,
          getPastDate(rangeFiltering.number, rangeFiltering.selected)
        )
      );
    }

    return groupByDate(returnedData);
  };
  //filter by date range
  const filterByDateRange = (dataArray, pastDateStr) => {
    const presentDate = new Date();
    const pastDate = new Date(pastDateStr);

    if (isNaN(pastDate.getTime())) {
      console.log("Invalid past date format");
    }

    return dataArray.filter((item) => {
      const itemDate = new Date(item.TSCreated);
      return itemDate <= presentDate && itemDate >= pastDate;
    });
  };

  //process nested arrays
  //nested array changed 10/7/24 so that it considers the extreme - values as max if its larger than the + value
  const processNestedArrays = (
    data,
    operator,
    targetKeys,
    secondTargetKeys
  ) => {
    if (!Array.isArray(data)) {
      console.log("Data must be an array of arrays of objects.");
    }
    if (
      !Array.isArray(targetKeys) ||
      !Array.isArray(secondTargetKeys) ||
      targetKeys.length !== secondTargetKeys.length
    ) {
      console.log(
        "targetKeys and secondTargetKeys must be arrays of the same length."
      );
    }

    return data.map((subArray) => {
      if (!Array.isArray(subArray)) {
        console.log(
          "Each element of the main array must be an array of objects."
        );
      }

      return subArray.map((obj) => {
        targetKeys.forEach((targetKey, index) => {
          const secondTargetKey = secondTargetKeys[index];

          if (
            obj.rawMetrics &&
            obj.rawMetrics[targetKey] &&
            Array.isArray(obj.rawMetrics[targetKey])
          ) {
            let metricsArray = obj.rawMetrics[targetKey];
            let processedResult;

            if (operator === "max" || operator === "min") {
              let selectedObj = null;

              metricsArray.forEach((item) => {
                if (item.hasOwnProperty(secondTargetKey)) {
                  if (selectedObj === null) {
                    if (
                      operator === "min" &&
                      item[secondTargetKey] !== 0 &&
                      item[secondTargetKey] !== null
                    ) {
                      selectedObj = item;
                    } else if (operator === "max") {
                      selectedObj = item;
                    }
                  } else {
                    if (operator === "max") {
                      if (
                        Math.abs(item[secondTargetKey]) >
                        Math.abs(selectedObj[secondTargetKey])
                      ) {
                        selectedObj = item;
                      }
                    } else if (operator === "min") {
                      if (
                        Math.abs(item[secondTargetKey]) <
                          Math.abs(selectedObj[secondTargetKey]) &&
                        item[secondTargetKey] !== 0 &&
                        item[secondTargetKey] !== null
                      ) {
                        selectedObj = item;
                      }
                    }
                  }
                }
              });

              processedResult = selectedObj;
            } else if (operator === "avg") {
              let sumObj = {};
              let countObj = {};

              metricsArray.forEach((item) => {
                for (let key in item) {
                  if (item[key] !== null) {
                    if (!sumObj[key]) {
                      sumObj[key] = 0;
                      countObj[key] = 0;
                    }
                    sumObj[key] += item[key];
                    countObj[key] += 1;
                  }
                }
              });

              processedResult = {};
              for (let key in sumObj) {
                processedResult[key] = sumObj[key] / countObj[key];
              }
            }
            if (processedResult) {
              obj.rawMetrics[targetKey] = processedResult;
            }
          }
        });

        return obj;
      });
    });
  };

  const processArray = (arrays, keys, operator, keyLabels) => {
    if (!Array.isArray(arrays) || arrays.length === 0) {
      console.log("Input must be a non-empty array of arrays.");
    }
    if (
      !Array.isArray(keys) ||
      !Array.isArray(keyLabels) ||
      keys.length !== keyLabels.length
    ) {
      console.log("Keys and keyLabels must be arrays of the same length.");
    }

    const flattenedData = arrays.flat();
    if (flattenedData.length === 0) {
      console.log("Input arrays must contain objects.");
    }

    function getValue(obj, nestedKey) {
      const keyParts = nestedKey.split(".");
      let currentObj = obj;
      for (const k of keyParts) {
        if (
          currentObj === undefined ||
          currentObj === null ||
          !currentObj.hasOwnProperty(k)
        ) {
          console.log(`Key "${nestedKey}" does not exist in the objects.`);
        }
        currentObj = currentObj[k];
      }
      return currentObj;
    }

    const results = keys.reduce((acc, key, index) => {
      const keyLabel = keyLabels[index];
      switch (operator) {
        case "avg":
          const totalSum = flattenedData.reduce((sum, obj) => {
            const value = getValue(obj, key);
            return sum + value;
          }, 0);
          const avg = totalSum / flattenedData.length;
          acc[keyLabel] = avg;
          break;
        case "min":
          const nonZeroData = flattenedData.filter(
            (obj) => getValue(obj, key) !== 0
          );
          if (nonZeroData.length === 0) {
            console.log("All values are zero.");
          }
          const minValue = Math.min(
            ...nonZeroData.map((obj) => getValue(obj, key))
          );
          const minObject = nonZeroData.find(
            (obj) => getValue(obj, key) === minValue
          );
          const remainingKeysMin = minObject
            ? Object.keys(minObject).filter((k) => k !== "rawMetrics")
            : [];
          acc[keyLabel] = minValue;
          remainingKeysMin.forEach((k) => {
            if (!acc.hasOwnProperty(k)) {
              acc[k] = minObject[k];
            }
          });
          break;
        case "max":
          const maxValue = Math.max(
            ...flattenedData.map((obj) => getValue(obj, key))
          );
          const maxObject = flattenedData.find(
            (obj) => getValue(obj, key) === maxValue
          );
          const remainingKeysMax = maxObject
            ? Object.keys(maxObject).filter((k) => k !== "rawMetrics")
            : [];
          acc[keyLabel] = maxValue;
          remainingKeysMax.forEach((k) => {
            if (!acc.hasOwnProperty(k)) {
              acc[k] = maxObject[k];
            }
          });
          break;
        default:
          throw new Error(
            "Invalid operator. Supported operators: avg, min, max."
          );
      }
      return acc;
    }, {});

    results.TSCreated = flattenedData[0].TSCreated;
    return results;
  };

  //group array of objects by date
  const groupByDate = (dataArray) => {
    function extractDate(tsCreated) {
      return tsCreated.split("T")[0];
    }

    const dateMap = dataArray.reduce((acc, item) => {
      const date = extractDate(item.TSCreated);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});

    // Sort the dates in descending order
    const sortedDates = Object.keys(dateMap).sort(
      (a, b) => new Date(b) - new Date(a)
    );

    // Create the sorted array of arrays
    const sortedArray = sortedDates.map((date) => dateMap[date]);

    return sortedArray;
  };

  const closeModal = () => {
    dispatch(setGraphModal(!graphModal));
    dispatch(setSelectedSessionId(null));
    setTimeout(() => {
      window.scrollTo({ top: Math.round(scrollPosition), behavior: "smooth" });
    }, 100);
  };

  const handleIsOutsideRange = useCallback(() => false, []);
  const handleDatesChange = useCallback(({ startDate, endDate }) => {
    setFilterDate({ startDate, endDate });
    setFilteringMethod((prev) => ({
      latestSessions: false,
      presetRange: false,
      dateRange: true,
    }));
  }, []);

  const handleFocusChange = useCallback(
    (focusedInput) => setFocusedInput(focusedInput),
    []
  );

  const allDates =
    athlete.athleteHistoricaRawSessions.data.length > 0
      ? athlete.athleteHistoricaRawSessions.data.map((item) =>
          moment(item.TSCreated).format("DD/MM/YYYY")
        )
      : [];

  // selectedTags, setSelectedTags
  const handleTagClick = (tagID) => {
    console.log(tagID)
    if (!selectedTags.includes(tagID)) {
      setSelectedTags((prev) => [...prev, tagID]);
    } else {
      setSelectedTags((prev) => [...prev.filter((item) => item !== tagID)]);
    }
  };

  const handleTagClear = () => {
    setSelectedTags([])
  }

  const handleTagSelectedForFilter = (tag) => {
   
    if (!filteringByTags.includes(tag.TID)) {
      setFilteringByTags((prevTags) => [...prevTags, tag.TID]);
    }
    if (filteringByTags.includes(tag.TID)) {
      setFilteringByTags((prevTags) => [
        ...prevTags.filter((item) => item !== tag.TID),
      ]);
    }
  };

  return (
    <div className="athlete_overview_container">
      <div className="athlete_overview_table">
        <AthleteOverviewMainTable
          tableData={overviewMetricData}
          tableDataFullDates={allDates}
          filteringMethod={filteringMethod}
          setFilteringMethod={setFilteringMethod}
          numberOfLatestSessions={numberOfLatestSessions}
          setNumberOfLatestSessions={setNumberOfLatestSessions}
          rangeFiltering={rangeFiltering}
          setRangeFiletering={setRangeFiletering}
          handleDatesChange={handleDatesChange}
          filterDate={filterDate}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          handleFocusChange={handleFocusChange}
          handleIsOutsideRange={handleIsOutsideRange}
          disabledMetrics={disabledMetrics}
          setDisabledMetrics={setDisabledMetrics}
          maxMinAvg={maxMinAvg}
          setMaxMinAvg={setMaxMinAvg}
          loggedUserData={loggedUserData}
          isLoading={isLoading}
          handleTagSelectedForFilter={handleTagSelectedForFilter}
          filteringByTags={filteringByTags}
          uniqueArrayOfTags={uniqueArrayOfTags}
          selectedTags={selectedTags}
          handleTagClick={handleTagClick}
          handleTagClear={handleTagClear}
        />
      </div>
      {overviewMetricData
        .filter(
          (item) =>
            item.data.length > 0 && !disabledMetrics.includes(item.metric)
        )
        .map((metric) => (
          <AthleteOverviewChart
            chartTitle={metric.metric}
            chartTittleToggled={metric.toggledMetric}
            chartData={metric.data}
            isMetric={metric.isMetric}
            primaryKey={metric.primaryKey}
            primaryKeyToggled={metric.primaryToggleKey}
            secondaryKeys={metric.secondaryKeys}
            bilateral={metric.bilateral}
            hasToggler={metric.toggleable}
            togglerKeys={metric.togglerKeys}
            tooltip={metric.tooltip}
            tooltipToggled={metric.tooltipToggled}
            chartAxisValueLabel={metric.chartValueAxisLabel}
            chartAxisValueLabelToggled={metric.chartValueAxisLabelToggled}
            isLoading={isLoading}
            chartType={metric.chartType}
            xAxisKey={metric.xAxisKey}
            handleChartElementClick={metric.handleElementClick}
            ChartCustomTooltip={metric.customTooltip}
            hasSideToggler={metric.hasSideToggler}
            tooltipLeft={metric.tooltipLeft}
            tooltipRight={metric.tooltipRight}
            tooltipValue={metric.tooltipValue}
            maxMinAvg={maxMinAvg}
            measurementUnit={metric.measurementUnit}
            selectedTags={selectedTags}
            handleTagClick={handleTagClick}
          />
        ))}
      <PlayerActivityDetails
        closeModal={closeModal}
        sessionId={selectedSessionId}
        fromPlayerProfile
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: selectUserData(state),
  athlete: selectAthlete(state),
  user: selectUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAthleteHistoricalGait,
      getAthleteHistoricalLoad,
      getAthleteHistoricalGroundContact,
      getAthleteHistoricalRawSessions,
      getSessionList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AthleteOverview2);

[
  { id: 1, name: "Sprint" },
  { id: 2, name: "Jog" },
  { id: 3, name: "Walk" },
  { id: 4, name: "Double Leg Countermovement jump" },
  { id: 5, name: "Double Leg Drop Jump" },
  { id: 9, name: "Double Leg Squat Jump" },
  { id: 10, name: "Double Leg Repeated Hops" },
  { id: 11, name: "Double Leg Depth Jump" },
  { id: 14, name: "Single Leg Countermovement Jump" },
  { id: 15, name: "Single Leg Squat Jump" },
  { id: 16, name: "Single Leg Drop Jump" },
  { id: 17, name: "Single Leg Repeated Hops" },
  { id: 18, name: "Single Leg Depth Jump" },
  { id: 21, name: "Other" },
  { id: 22, name: "Isometric Calf Test " },
  { id: 35, name: "Bench press" },
  { id: 36, name: "Pull-up" },
  { id: 37, name: "Military press" },
  { id: 38, name: "Squat" },
  { id: 39, name: "Deadlift" },
  { id: 40, name: "Leg-press" },
  { id: 41, name: "Quiet Stance" },
  { id: 42, name: "Single Leg Stance" },
  { id: 43, name: "Agility" },
  { id: 44, name: "Free Run" },
];
