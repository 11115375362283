const ManageTeamsIcon = ({ size = "24", color = "#94A3B3" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.375 6.49999C11.375 5.90168 10.89 5.41666 10.2917 5.41666H4.33333L4.20699 5.42394C3.66821 5.48652 3.25 5.94442 3.25 6.49999C3.25 7.0983 3.73502 7.58332 4.33333 7.58332H10.2917L10.418 7.57604C10.9568 7.51346 11.375 7.05556 11.375 6.49999ZM11.375 10.8333C11.375 10.235 10.89 9.74999 10.2917 9.74999H4.33333L4.20699 9.75728C3.66821 9.81986 3.25 10.2778 3.25 10.8333C3.25 11.4316 3.73502 11.9167 4.33333 11.9167H10.2917L10.418 11.9094C10.9568 11.8468 11.375 11.3889 11.375 10.8333ZM10.2917 14.0833C10.89 14.0833 11.375 14.5683 11.375 15.1667C11.375 15.7222 10.9568 16.1801 10.418 16.2427L10.2917 16.25H4.33333C3.73502 16.25 3.25 15.765 3.25 15.1667C3.25 14.6111 3.66821 14.1532 4.20699 14.0906L4.33333 14.0833H10.2917ZM11.375 19.5C11.375 18.9017 10.89 18.4167 10.2917 18.4167H4.33333L4.20699 18.4239C3.66821 18.4865 3.25 18.9444 3.25 19.5C3.25 20.0983 3.73502 20.5833 4.33333 20.5833H10.2917L10.418 20.576C10.9568 20.5135 11.375 20.0556 11.375 19.5ZM21.6667 5.41666C22.265 5.41666 22.75 5.90168 22.75 6.49999C22.75 7.05556 22.3318 7.51346 21.793 7.57604L21.6667 7.58332H15.7083C15.11 7.58332 14.625 7.0983 14.625 6.49999C14.625 5.94442 15.0432 5.48652 15.582 5.42394L15.7083 5.41666H21.6667ZM22.75 10.8333C22.75 10.235 22.265 9.74999 21.6667 9.74999H15.7083L15.582 9.75728C15.0432 9.81986 14.625 10.2778 14.625 10.8333C14.625 11.4316 15.11 11.9167 15.7083 11.9167H21.6667L21.793 11.9094C22.3318 11.8468 22.75 11.3889 22.75 10.8333ZM21.6667 14.0833C22.265 14.0833 22.75 14.5683 22.75 15.1667C22.75 15.7222 22.3318 16.1801 21.793 16.2427L21.6667 16.25H15.7083C15.11 16.25 14.625 15.765 14.625 15.1667C14.625 14.6111 15.0432 14.1532 15.582 14.0906L15.7083 14.0833H21.6667ZM22.75 19.5C22.75 18.9017 22.265 18.4167 21.6667 18.4167H15.7083L15.582 18.4239C15.0432 18.4865 14.625 18.9444 14.625 19.5C14.625 20.0983 15.11 20.5833 15.7083 20.5833H21.6667L21.793 20.576C22.3318 20.5135 22.75 20.0556 22.75 19.5Z"
        fill={color}
      />
    </svg>
  );
};

export default ManageTeamsIcon;
