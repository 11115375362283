import { api } from "./api";

//get teamList api
export const getTeamListAPI = () => {
  return api.get("/teams/getTeamList").then(res => {
    return res;
  });
};

//Remove Team
export const deleteTeamAPI = (teamID) => {
  return api.get(`/teams/deleteTeam/${teamID}`).then(res => {
    return res;
  });
};

//Create new team API
export const addTeamAPI = (postBody) => {
  return api.post("/teams/addTeam", postBody).then(res => {
    return res;
  });
};

//get team group api
export const getTeamGroupListAPI = (teamID) => {
  return api.get(`/teams/getTeamGroups/${teamID}`).then(res => {
    return res;
  });
};

//delete team group api
export const deleteTeamGroupAPI = (groupID) => {
  return api.get(`/teams/deleteTeamGroup/${groupID}`).then(res => {
    return res;
  });
};


//Create new team group API
export const addTeamGroupAPI = (postBody) => {
  return api.post("/teams/addTeamGroup", postBody).then(res => {
    return res;
  });
};

//get athletes in team
export const getTeamAthletesAPI = (teamID) => {
  return api.get(`teams/getTeamAthletes/${teamID}`).then(res => {
    return res;
  });
};

//set athlete in team
export const deleteAthleteInTeamAPI = (postBody) => {
  return api.post("teams/deleteTeamAthlete", postBody).then(res => {
    return res;
  });
};


//set athlete in team
export const setAthleteInTeamAPI = (postBody) => {
  return api.post("/teams/setAthleteInTeam", postBody).then(res => {
    return res;
  });
};

//set athlete in team
export const setAthletesInTeamAPI = (postBody) => {
  return api.post("/teams/setAthletesInTeam", postBody).then(res => {
    return res;
  });
};







