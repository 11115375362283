import LoginForm from './LoginForm';

const LoginPage = () => (
  <div className="loginContainer">

   

    <div className="right_login">
      <LoginForm />
    </div>
  </div>
);

export default LoginPage;
