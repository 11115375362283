/* eslint-disable */
import { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrganisationsToolkit } from "../store/orgSlice";
import { getSessionList } from "../store/sessionListSlice";
import Welcome from "./welcome/welcome";
import ChildBox from "./boxes/childBox/childBox";
import GridBox from "./boxes/boxes";
import { UserProfile_URL, OrganisationDetail_URL } from "../utils/urls";
import { selectSessionList2 } from "../store/sessionListSlice";
import { selectOrganisation } from "../store/orgSlice";
import { selectUser } from "store/authSlice";
import { selectActivityList } from "store/activitySlice";
import { Link } from "react-router-dom";
import HomePageTable from "./common/HomePageTable/HomePageTable";
import { simplifyArray } from "utils/helper";

const HomePage = (props) => {
  const { organisation, user, actions, sessionList } = props;

  const athletesData = useMemo(
    () =>
      organisation.orgList.length > 0
        ? organisation?.orgList
            .map((item) => item.players.length)
            .reduce((a, b) => a + b)
        : 0,
    [organisation]
  );

  const tableData = useMemo(() => {
    let playerList = [];
    if (organisation && organisation.orgList) {
      organisation.orgList.forEach((org) => {
        playerList.push({
          isPlayer: false,
          player: org.Name,
          overview: JSON.parse(org.overview),
          orgID: org.OrgID,
        });
        if (org.players) {
          org.players.forEach((player) => {
            playerList.push({
              isPlayer: true,
              player: player.player,
              overview: JSON.parse(player.overview),
              playerID: player.playerID,
              lastActive: player?.tsLastActive,
            });
          });
        }
      });
    }
   

    const sortedData = playerList.sort((a, b) => {
      return new Date(b.lastActive) - new Date(a.lastActive);
    });

    let sortedDataGait = []

    sortedData.forEach((player) => (
      sortedDataGait.push({
      ...player,
      overview: {
        ...player.overview,
        gait: simplifyArray([
          player?.overview?.["1"],
          player?.overview?.["2"],
          player?.overview?.["3"],
        ]),
      },
    })));


    // const finalData = sortedDataGait
    const finalData = sortedDataGait.slice(0, 10);
    return finalData;
  }, [organisation]);

  const fullTableData = useMemo(() => {
    let playerList = [];
    if (organisation && organisation.orgList) {
      organisation.orgList.forEach((org) => {
        playerList.push({
          isPlayer: false,
          player: org.Name,
          overview: JSON.parse(org.overview),
          orgID: org.OrgID,
        });
        if (org.players) {
          org.players.forEach((player) => {
            playerList.push({
              isPlayer: true,
              player: player.player,
              overview: JSON.parse(player.overview),
              playerID: player.playerID,
              lastActive: player?.tsLastActive,
            });
          });
        }
      });
    }

  
    const sortedData = playerList.sort((a, b) => {
      return new Date(b.lastActive) - new Date(a.lastActive);
    });

    let sortedDataGait = []

    sortedData.forEach((player) => (
      sortedDataGait.push({
      ...player,
      overview: {
        ...player.overview,
        gait: simplifyArray([
          player?.overview?.["1"],
          player?.overview?.["2"],
          player?.overview?.["3"],
        ]),
      },
    })));
    
    //remove from here if there are any issuyes -------------------------------------------------------
    //const finalData = sortedData
    const finalData = sortedDataGait;
    return finalData;
  }, [organisation]);

  function goToPlayerProfilePage(player) {
    props.history.push(UserProfile_URL + "/" + player);
  }

  function goToOrganisation(orgID) {
    props.history.push(OrganisationDetail_URL + "/" + orgID);
  }

  useEffect(() => {
    if (
      !sessionList?.data &&
      sessionList.status !== "succeeded" &&
      sessionList.status !== "loading" &&
      user.id
    ) {
      actions.getSessionList();
    }
    if (user.id && organisation.status === "idle") {
      actions.getOrganisationsToolkit(user.id);
    }
  }, [
    user.id,
    organisation.orgList,
    sessionList?.data,
    actions,
    organisation.status,
    sessionList.status,
  ]);

  const headers = [
    "NAME",
    "DLCMJ (CM)",
    "DLRH (RSI)",
    "SLCMJ (ASYM)",
    "DJ (RSI)",
    "LOAD",
    "GAIT"
  ];
  const fields = {
    player: "Name",
    DLCMJ: "Double Leg Counter Movement Jump",
    DLRH: "Double Leg Counter Repeated Hops",
    SLCMJ: "Single Leg Counter Movement Jump",
    DJ: "Drop Jumps",
    load: "Load",
    gait: "Gait"
  };

  function goToPodRegisterPage() {
    props.history.push("pod_list");
  }

  return (
    <div className="dash-container">
      <div className="">
        <Welcome
          name={user.fullname}
          action={goToPodRegisterPage}
          user={user}
        />
        <GridBox>
          <ChildBox
            value={organisation.orgList && organisation.orgList.length}
            headline="Teams"
          />
          <Link to={"/athletes_home"}>
            <ChildBox value={athletesData} headline="Athletes" />
          </Link>
          <Link to={"/session_input"}>
            <ChildBox
              value={sessionList.sessionList && sessionList.sessionList?.data?.length ? sessionList.sessionList?.data?.length : "0"}
              headline="Sessions"
            />
          </Link>
          <ChildBox value="0" headline="Injured Athletes" />
          <ChildBox value="0" headline="Alerts" />
        </GridBox>
      </div>

      <div className="graph-container">
        <HomePageTable
          fullTableData={fullTableData}
          tableData={tableData}
          goToPlayerProfilePage={goToPlayerProfilePage}
          goToOrganisationProfilePage={goToOrganisation}
          headers={headers}
          fields={fields}
          showButton={organisation.orgList.length > 0}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    sessionList: selectSessionList2(state),
    organisation: selectOrganisation(state),
    user: selectUser(state),
    activityList: selectActivityList(state),
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getOrganisationsToolkit, getSessionList },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
