/* eslint-disable */
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import {
  RadarChart,
  PolarGrid,
  Radar,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  ResponsiveContainer,
  Tooltip,
  Line,
} from "recharts";
import {
  NEW_LOW_VALUE,
  NEW_AXIS_TICK,
  NEW_BOTH_FEET_COLOR,
  NEW_HIGH_VALUE,
  NEW_RIGHT_COLOR,
  ATHLETE_COLOR_GROUP_1,
  ATHLETE_COLOR_GROUP_2,
  ATHLETE_COLOR_GROUP_3,
  ATHLETE_COLOR_GROUP_4,
  ATHLETE_COLOR_GROUP_5,
  ATHLETE_COLOR_GROUP_6,
  ATHLETE_COLOR_GROUP_7,
  ATHLETE_COLOR_GROUP_8,
  ATHLETE_COLOR_GROUP_9,
  ATHLETE_COLOR_GROUP_10,
  TEAM_COLOR_GROUP_1,
  TEAM_COLOR_GROUP_2,
  TEAM_COLOR_GROUP_3,
  TEAM_COLOR_GROUP_4,
  TEAM_COLOR_GROUP_5,
  TEAM_COLOR_GROUP_6,
  TEAM_COLOR_GROUP_7,
  TEAM_COLOR_GROUP_8,
  TEAM_COLOR_GROUP_9,
  TEAM_COLOR_GROUP_10,
} from "utils/constant";

function findKeyFromValue(value, obj) {
  // Iterate over all keys in the object
  for (let key in obj) {
    // Check if the value of the key is equal to the input value
    if (obj[key] === value) {
      // Return the key
      return key;
    }
  }
  // Return null if the value is not found
  return null;
}

const CustomTooltip = ({ active, payload, label, data }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip-overview">
        <div className="label">{data.activity}</div>
        <div className="sub-label athlete-label">
          {payload[0].name === "Team" && (
            <p>
              {"Team Performance" + " :"}{" "}
              <span>{data.teamValue?.toFixed(1)}</span>
              {" " + data.unit}
            </p>
          )}
          {payload
            .filter((item) => item.name !== "Team")
            .map((item, index) => {
              return (
                <p key={nanoid()} style={{ color: item.color }}>
                  {item.name +
                    " : " +
                    item.payload[
                      `${findKeyFromValue(item.value, item.payload)}Value`
                    ]?.toFixed(1) +
                    " " +
                    item.payload.unit}
                </p>
              );
            })}
        </div>
      </div>
    );
  }

  // return null;
};

const prepareDataForZScores = (processedData, selectedValue) => {
  const tableDataAcrossOrganisation = processedData
    ?.filter((item) => Object.keys(item.overview).length > 0)
    .map((item) => item.overview);

  let keys = ["4", "10", "14", "5", "load", "gait"];
  let allTimeMaxArrays = {};

  keys.forEach((key) => {
    allTimeMaxArrays[key] = tableDataAcrossOrganisation.map(
      (obj) => obj[key]?.[selectedValue]
    );
  });
  // setAllDataArrays(removeNullAndUndefined(allTimeMaxArrays));
  return allTimeMaxArrays;
};

const TeamRadarChart = (props) => {
  const { dataForCharting, selectedGroups, selectedValue } = props;

  const [fullTeamData, setFullTeamData] = useState([]);
  const [fullGroupData, setFullGroupData] = useState([]);
  const [graphDataFormatted, setGraphDataFormatted] = useState([]);
  const [domain, setDomain] = useState([-4, 4]);
  const [selectedAthletes, setSelectedAthletes] = useState([]);


  

  useEffect(() => {
    if (dataForCharting.athletesInSelectedTeam.length > 0) {
      const prepForZScores = prepareDataForZScores(
        dataForCharting.athletesInSelectedTeam, selectedValue
      );

      setFullTeamData(prepForZScores);
    }

    if (dataForCharting.averageOfGroupsSelected.length > 0) {
      const prepareGroupsForZscores = [];
      dataForCharting.groupsSelected.forEach((group) =>
        prepareGroupsForZscores.push(prepareDataForZScores(group.athletes))
      );
      setFullGroupData(prepareGroupsForZscores);
    }

    if (dataForCharting.athletesSelected.length > 0) {
      let athletesSelectedModifiedData =
        dataForCharting.athletesInSelectedTeam.filter((player) =>
          dataForCharting.athletesSelected.includes(
            JSON.stringify(player.playerID)
          )
        );
      setSelectedAthletes(athletesSelectedModifiedData);
    }
  }, [dataForCharting]);

  useEffect(() => {
    setGraphDataFormatted([
      {
        subject: "DLCMJ",
        activity: "Double Leg Countermovement Jump - Jump Height (cm)",
        measuredUnit: "Jump Height",
        unit: "cm",
        teamValue:
          dataForCharting?.averageOfAthletesInSelectedTeam?.["4"]?.[
            selectedValue
          ],
        teamZscore: calculateZScore(
          dataForCharting?.averageOfAthletesInSelectedTeam?.["4"]?.[
            selectedValue
          ],
          fullTeamData?.["4"]
        ),
        ...calculateGroupZscore(
          dataForCharting.averageOfGroupsSelected,
          "4",
          calculateZScore,
          fullTeamData["4"],
          selectedValue
        ),
        ...calculateAthleteZscore(
          selectedAthletes,
          "4",
          calculateZScore,
          fullTeamData["4"],
          selectedValue
        ),
      },
      {
        subject: "SLCMJ",
        activity: "Single Leg Countermovement Jump - % Asymmetry (Jump Height)",
        measuredUnit: "Asymmetry",
        unit: "%",
        teamValue:
          dataForCharting?.averageOfAthletesInSelectedTeam?.["14"]?.[
            selectedValue
          ],
        teamZscore: calculateZScore(
          dataForCharting?.averageOfAthletesInSelectedTeam?.["14"]?.[
            selectedValue
          ],
          fullTeamData?.["14"]
        ),
        ...calculateGroupZscore(
          dataForCharting.averageOfGroupsSelected,
          "14",
          calculateZScore,
          fullTeamData["14"],
          selectedValue
        ),
        ...calculateAthleteZscore(
          selectedAthletes,
          "14",
          calculateZScore,
          fullTeamData["14"],
          selectedValue
        ),
      },
      {
        subject: "RH",
        activity: "Repeated Hops - RSI",
        measuredUnit: "RSI",
        unit: "",
        teamValue:
          dataForCharting?.averageOfAthletesInSelectedTeam?.["10"]?.[
            selectedValue
          ],
        teamZscore: calculateZScore(
          dataForCharting?.averageOfAthletesInSelectedTeam?.["10"]?.[
            selectedValue
          ],
          fullTeamData?.["10"]
        ),
        ...calculateGroupZscore(
          dataForCharting.averageOfGroupsSelected,
          "10",
          calculateZScore,
          fullTeamData["10"],
          selectedValue
        ),
        ...calculateAthleteZscore(
          selectedAthletes,
          "10",
          calculateZScore,
          fullTeamData["10"],
          selectedValue
        ),
      },
      {
        subject: "DJ",
        activity: "Drop Jumps - RSI",
        measuredUnit: "RSI",
        unit: "",
        teamValue:
          dataForCharting?.averageOfAthletesInSelectedTeam?.["5"]?.[
            selectedValue
          ],
        teamZscore: calculateZScore(
          dataForCharting?.averageOfAthletesInSelectedTeam?.["5"]?.[
            selectedValue
          ],
          fullTeamData?.["5"]
        ),
        ...calculateGroupZscore(
          dataForCharting.averageOfGroupsSelected,
          "5",
          calculateZScore,
          fullTeamData["5"],
          selectedValue
        ),
        ...calculateAthleteZscore(
          selectedAthletes,
          "5",
          calculateZScore,
          fullTeamData["5"],
          selectedValue
        ),
      },
      {
        subject: "Load",
        activity: "Load - Daily Load (a.u.)",
        measuredUnit: "load",
        unit: "",
        teamValue:
          dataForCharting?.averageOfAthletesInSelectedTeam?.["load"]?.[
            selectedValue
          ],
        teamZscore: calculateZScore(
          dataForCharting?.averageOfAthletesInSelectedTeam?.["load"]?.[
            selectedValue
          ],
          fullTeamData?.["load"]
        ),
        ...calculateGroupZscore(
          dataForCharting.averageOfGroupsSelected,
          "load",
          calculateZScore,
          fullTeamData["load"],
          selectedValue
        ),
        ...calculateAthleteZscore(
          selectedAthletes,
          "load",
          calculateZScore,
          fullTeamData["load"],
          selectedValue
        ),
      },
      {
        subject: "Gait",
        activity: "Gait - % Asymmetry (Ground Contact Time)",
        measuredUnit: "gait",
        unit: "",
        teamValue:
          dataForCharting?.averageOfAthletesInSelectedTeam?.["gait"]?.[
            selectedValue
          ],
        teamZscore: calculateZScore(
          dataForCharting?.averageOfAthletesInSelectedTeam?.["gait"]?.[
            selectedValue
          ],
          fullTeamData?.["gait"]
        ),
        ...calculateGroupZscore(
          dataForCharting.averageOfGroupsSelected,
          "gait",
          calculateZScore,
          fullTeamData["gait"],
          selectedValue
        ),
        ...calculateAthleteZscore(
          selectedAthletes,
          "gait",
          calculateZScore,
          fullTeamData["gait"],
          selectedValue
        ),
      },
    ]);
  }, [fullTeamData, dataForCharting]);

  useEffect(() => {
    if (dataForCharting.averageOfGroupsSelected.length > 0) {
    }
  }, [dataForCharting]);

  function calculateZScore(value, array) {
    array = array?.filter((item) => item !== null && item !== undefined);
    let sum = array?.reduce((a, b) => a + b, 0);

    let mean = sum / array?.length;
    let squareDiff = array?.map((number) => Math.pow(number - mean, 2));
    let sumSquareDiff = squareDiff?.reduce((a, b) => a + b, 0);
    let variance = sumSquareDiff / array?.length;
    let stdDev = Math.sqrt(variance);
    let zScore = (value - mean) / stdDev;
    return zScore;
  }

  function calculateGroupZscore(array, key, method, comparisonArray, whenKey) {
    let objects = {};
    for (let i = 0; i < array.length; i++) {
      objects = {
        ...objects,
        ["Group" + i]: method(array?.[i]?.[key]?.[whenKey], comparisonArray) !== -Infinity ? method(array?.[i]?.[key]?.[whenKey], comparisonArray) : null,
        ["Group" + i + "Value"]: array?.[i]?.[key]?.[whenKey],
        ["Group" + i + "Name"]: dataForCharting?.groupsSelected[i]?.name,
      };
    }

    return objects;
  }

  function calculateAthleteZscore(
    array,
    key,
    method,
    comparisonArray,
    whenKey
  ) {
    let objects = {};

    for (let i = 0; i < array.length; i++) {
      objects = {
        ...objects,
        ["Athlete" + i]: method(
          array[i]?.overview?.[key]?.[whenKey],
          comparisonArray
        ),
        ["Athlete" + i + "Value"]: array[i]?.overview?.[key]?.[whenKey],
        ["Athlete" + i + "Name"]: selectedAthletes?.[i]?.player,
      };
    }

    return objects;
  }

  const newArray = dataForCharting.athletesInSelectedTeam.map(
    (item) => item.overview
  );

  const arrayOfGroupColours = [
    TEAM_COLOR_GROUP_1,
    TEAM_COLOR_GROUP_2,
    TEAM_COLOR_GROUP_3,
    TEAM_COLOR_GROUP_4,
    TEAM_COLOR_GROUP_5,
    TEAM_COLOR_GROUP_6,
    TEAM_COLOR_GROUP_7,
    TEAM_COLOR_GROUP_8,
    TEAM_COLOR_GROUP_9,
    TEAM_COLOR_GROUP_10,
  ];

  const arrayOfAthleteColours = [
    ATHLETE_COLOR_GROUP_1,
    ATHLETE_COLOR_GROUP_2,
    ATHLETE_COLOR_GROUP_3,
    ATHLETE_COLOR_GROUP_4,
    ATHLETE_COLOR_GROUP_5,
    ATHLETE_COLOR_GROUP_6,
    ATHLETE_COLOR_GROUP_7,
    ATHLETE_COLOR_GROUP_8,
    ATHLETE_COLOR_GROUP_9,
    ATHLETE_COLOR_GROUP_10,
  ];

  return (
    <ResponsiveContainer height={500}>
      <RadarChart outerRadius={140} data={graphDataFormatted}>
        <PolarGrid stroke={NEW_AXIS_TICK} strokeWidth={1} opacity={0.4} />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis
          stroke={NEW_AXIS_TICK}
          angle={90}
          domain={domain}
          orientation="middle"
          fontSize={10}
          label={{
            position: "bottom",
            fill: NEW_AXIS_TICK,
            offset: 50,
          }}
          axisLine={false}
          tick={false}
        />

        <Legend />
        {dataForCharting.comparingAllAthletesInTeam && (
          <Radar
            name="Team"
            dataKey="teamZscore"
            fill={NEW_RIGHT_COLOR}
            stroke={NEW_RIGHT_COLOR}
            fillOpacity={0.6}
            strokeWidth={1}
            legendType="square"
          />
        )}

        {dataForCharting.groupsSelected.length && (
          <>
            {dataForCharting.groupsSelected.map((group, index) => {
              return (
                <>
                  <Radar
                    name={group.name}
                    dataKey={`Group${index}`}
                    fill={"none"}
                    stroke={
                      arrayOfGroupColours[
                        selectedGroups.findIndex((obj) => obj.id === group.id)
                      ]
                    } //makes sure that the color of the line is always the same index no matter the possition in the array of the team
                    fillOpacity={0.6}
                    strokeWidth={3}
                    legendType="rect"
                    key={nanoid()}
                  />
                </>
              );
            })}
          </>
        )}

        {dataForCharting.athletesSelected.length && (
          <>
            {dataForCharting.athletesSelected.map((athlete, index) => {
              return (
                <Radar
                  name={
                    dataForCharting?.athletesInSelectedTeam?.filter(
                      (item) => item?.playerID === Number(athlete)
                    )?.[0]?.player
                  }
                  dataKey={`Athlete${index}`}
                  fill={"none"}
                  stroke={arrayOfAthleteColours[index]}
                  strokeDasharray="10 5"
                  fillOpacity={0.6}
                  strokeWidth={1}
                  legendType="line"
                  key={nanoid()}
                />
              );
            })}
          </>
        )}
        <Tooltip content={<CustomTooltip data={graphDataFormatted} />} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default TeamRadarChart;
