import "../toggler/toggler.scss";

function Toggler(props) {
  const { isToggled, setIsToggled, changeToggleDisplayed, togglerHeadings } =
    props;

  return (
    <div className="graph-toggler">
      <p className={`toggle-title ${isToggled ? "" : "toggle-title-active"}`}>
        {togglerHeadings.left}
      </p>
      <div className="metric-title-left">
        <label className="switch">
          <input
            type="checkbox"
            onChange={(e) => {
              changeToggleDisplayed(e.target.checked),
                setIsToggled(e.target.checked);
            }}
          />
          <span className="slider round"></span>
        </label>
      </div>
      <p className={`toggle-title ${isToggled ? "toggle-title-active" : ""}`}>
        {togglerHeadings.right}
      </p>
    </div>
  );
}

export default Toggler;

{/*
Toggler format  
<Toggler
  isToggled={loadToggle}
  setIsToggled={setLoadToggle}
  changeToggleDisplayed={changeLoadDisplayed}
  togglerHeadings={{ left: "Daily Load", right: "Relative Load" }}
/>; 

*/}
