/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  AdminHome_URL,
  HOME_URL,
  ManageTeams_URL,
  OrganisationsList_URL,
  PodList_URL,
  ReportsHome_URL,
  Teams_URL,
  UserProfile_URL,
  UsersHome_URL,
} from "utils/urls";
import { motion } from "framer-motion";
import Breadcrumbs from "components/Breadcrumbs";
import Overview from "components/nav-components/nav-icons/overview";
import Athletes from "components/nav-components/nav-icons/athletes";
import Reports from "components/nav-components/nav-icons/reports";
import OrganisationsIcon from "components/nav-components/nav-icons/organisations";
import NavProfile from "components/nav-components/nav-profile/NavProfile";
import adminIcon from "assets/images/nav/admin.svg";
import adminIconSelected from "assets/images/nav/admin-selected.svg";
import "styles/navBar.scss";
import Dropdown from "../dropdown/dropdown";
import { getOrganisationsToolkit } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import { clearAuth } from "store/authSlice";
import { selectOrganisation } from "store/orgSlice";
import { resetState } from "store/resetSlice";
import AlertDialogDemo from "../alertDialog/alertDialog";
import CustomDrawer from "../DrawerTest/drawerTest";
import { SearchBarConnected } from "./searchBar";
import {
  setCheckedAthletesForComparison,
  setCheckedSessions2,
  setCheckedSessionsForComparison,
} from "store/playerActivitySlice";
import TeamsIcon from "components/nav-components/nav-icons/teams";
import ManageTeamsIcon from "components/nav-components/nav-icons/manage-icon";

//colour variables for nav elements
const navActiveColor = "#49AFC8";
const navColor = "#94A3B3";

let navLinks = [
  {
    path: HOME_URL,
    component: Overview,
    title: "Overview",
    checkPath: ["/", "/pod_lists"],
  },
  {
    path: UsersHome_URL,
    component: Athletes,
    title: "Athletes",
    checkPath: ["/athlete_profile", "/athletes_home"],
  },
  {
    path: ReportsHome_URL,
    component: Reports,
    title: "Reports",
    checkPath: ["/session_input"],
  },
  {
    path: Teams_URL,
    component: TeamsIcon,
    title: "Teams",
    checkPath: ["/teams"],
  },
  {
    path: ManageTeams_URL,
    component: ManageTeamsIcon,
    title: "Manage",
    checkPath: ["/manage_teams"],
  },
  {
    path: OrganisationsList_URL,
    component: OrganisationsIcon,
    title: "Organisations",
    checkPath: ["/organisations_list", "/organisation_detail"],
  },
];

function Navbar(props) {
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  //const [searchBar, setSearchBar] = useState("");
  // const [searchFocus, setSearchFocus] = useState(false)
  const { user } = props;
  const userProfileIcon = user.iconURL;


  const dispatch = useDispatch();

  //use effect clears store whenever the page is changed, the selected items change on the athletes page as well as the reports page
  useEffect(() => {
    dispatch(setCheckedAthletesForComparison([]));
    dispatch(setCheckedSessions2([]));
    dispatch(setCheckedSessionsForComparison([]));
  }, [props?.location]);

  useEffect(() => {
    if (
      props?.userOrganisations?.status === "succeeded" &&
      props?.user?.permissionLevel === 0 &&
      !props.userOrganisations?.orgList[0]?.isAdmin &&
      !props.userOrganisations?.orgList[0]?.isCoach
    ) {
      if (props?.history?.location?.pathname !== "/session_input" || props?.history?.location?.pathname !== `/athlete_profile/${props?.userOrganisations?.orgList[0]?.players[0]?.playerID}`) {
          props.history.push("/session_input")
      }
    }
  }, [props?.userOrganisations?.status]);

  useEffect(()=> {
    if (props?.history?.location?.pathname !== "/session_input" &&
        props?.userOrganisations?.status === "succeeded" &&
        props?.user?.permissionLevel === 0 &&
        props?.userOrganisations?.orgList[0]?.isAthlete &&
        !props.userOrganisations?.orgList[0]?.isAdmin &&
        !props.userOrganisations?.orgList[0]?.isCoach
      ) {
      if(props?.history?.location?.pathname !== `/athlete_profile/${props?.userOrganisations?.orgList[0]?.players[0]?.playerID}`){
        props.history.push("/session_input")
      }
  }
  }, [ props?.history?.location?.pathname])

  //in here for athlete
  if (
    props?.userOrganisations?.status === "succeeded" &&
    props?.userOrganisations?.orgList?.length === 1 && // new perm level for athlete insted
    props.userOrganisations.orgList[0].players.length === 1 &&
    props?.user?.permissionLevel === 0 &&
    // props?.user.fullname === 
    // props?.userOrganisations?.orgList[0]?.players[0].player && // changes to 
    props?.userOrganisations?.orgList[0]?.isAthlete &&
    !props.userOrganisations?.orgList[0]?.isAdmin &&
    !props.userOrganisations?.orgList[0]?.isCoach
  ) {
    navLinks = [
      // {
      //   path: HOME_URL,
      //   component: Overview,
      //   title: "Overview",
      //   checkPath: ["/", "/pod_lists"],
      // },
      {
        // path: UserProfile_URL+`/${1}`,
        path: UserProfile_URL + `/${props.userOrganisations.orgList[0].players[0].playerID}`,
        component: Athletes,
        title: "Overview",
        checkPath: [`${UserProfile_URL}/${props.userOrganisations.orgList[0].players[0].playerID}}`],
      },
      {
        path: ReportsHome_URL,
        component: Reports,
        title: "Reports",
        checkPath: ["/session_input"],
      },
    ];
  }

  let pathName = null;
  if (props.location) {
    pathName = props.location.pathname;
  }

  const childVariants = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
  };

  function logout(e) {
    e.preventDefault();
    props.actions.clearAuth();
    props.actions.resetState();
    setLogoutDialogOpen(false);
  }

  function toggleMenu() {
    setMenuIsOpen((prevMenuIsOpen) => !prevMenuIsOpen);
  }

  const showHideNav = () => {
    document.querySelector("#navItems").classList.toggle("active");
    document.querySelector("#nav-hamBar").classList.toggle("test");
  };

  const showDropdown = () => {
    document
      .querySelector("#nav-dropdown")
      .classList.toggle("dropdown-visible");
  };

  const NavLink = ({ path, component, title, checkPath }) => {
    const currentPath = window.location.pathname;
    let active = false;
    if (path === HOME_URL || path === PodList_URL) {
      active =
        (checkPath.includes(currentPath) ||
          currentPath.startsWith("/pod_registration") ||
          currentPath.startsWith("/pod_list")) &&
        path === HOME_URL;
    } else {
      for (const check of checkPath) {
        if (currentPath.startsWith(check)) {
          const prefix = currentPath.substring(0, check.length);
          if (prefix === check) {
            active = true;
            break;
          }
        }
      }
    }

    return (
      <Link to={path}>
        <div className={active ? "nav-item active" : "nav-item"}>
          {active
            ? React.createElement(component, { color: navActiveColor })
            : React.createElement(component, { color: navColor })}
          <div className="nav-link-title-container">
            <div className="nav-link-txt-container">{title}</div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div>
      <div className="navBar">
        <div className="sideNav-container">
          <div className="nav-tablet-mobile">
            <div
              className="nav-hamburger"
              onClick={() => {
                showHideNav();
              }}
            >
              <div id="nav-hamBar" className="hamburger-bar"></div>
            </div>

            <div className="nav-logo-search">
              <Link to={HOME_URL}>
                <motion.div
                  className="nav-logo"
                  variants={childVariants}
                  initial="initial"
                  animate="animate"
                  transition={{
                    duration: 0.6,
                    delay: 0.3 * 1,
                    ease: [0, 0.81, 0.5, 1.01],
                  }}
                ></motion.div>
              </Link>
              {navLinks.length > 2 && <SearchBarConnected />}
            </div>
            <CustomDrawer open={menuIsOpen} onClose={(e) => toggleMenu(e)}>
              <div
                style={{
                  backgroundColor: "#161626",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Link to={HOME_URL} onClick={(e) => toggleMenu(e)}>
                  <div
                    className={
                      pathName === "/" ? "nav-item active" : "nav-item"
                    }
                  >
                    <div className="nav-link-title-container">
                      <div className="nav-link-txt-container">Home</div>
                    </div>
                  </div>
                </Link>

                <Link to={HOME_URL} onClick={(e) => toggleMenu(e)}>
                  <div
                    className={
                      pathName === "/athletes_home" ||
                      pathName.indexOf("athlete_profile") >= 0
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <div className="nav-link-title-container">
                      <div className="nav-link-txt-container">Athletes</div>
                    </div>
                  </div>
                </Link>
              </div>
            </CustomDrawer>
          </div>

          <div id="navItems" className="item-list">
            {navLinks.map((link) => (
              <NavLink key={link.title} {...link} />
            ))}

            <div
              className="nav-item logout-btn"
              onClick={() => {
                setLogoutDialogOpen(
                  (prevLougoutDialogOpen) => !prevLougoutDialogOpen
                );
                document.body.classList.add("modal-open");
              }}
            >
              <div className="nav-link-title-container">
                <div className="nav-link-txt-container">Logout</div>
              </div>
            </div>

            <AlertDialogDemo
              open={logoutDialogOpen}
              proceed={logout}
              type="logout"
              setLogoutDialogOpen={setLogoutDialogOpen}
            />
          </div>
          <div className="nav-dropdown">
            {
              <NavProfile
                dropDownState={() => showDropdown()}
                user={user}
                userProfileIcon={userProfileIcon}
              />
            }
            <Dropdown
              userDetail={user.permissionLevel}
              pathName={pathName}
              showHideNav={showDropdown}
              to={AdminHome_URL}
              a
              dminIconSelected={adminIconSelected}
              adminIcon={adminIcon}
              setLogoutDialogOpen={setLogoutDialogOpen}
            />
          </div>
        </div>
      </div>
      {props?.userOrganisations?.status === "succeeded" &&
        props?.userOrganisations?.orgList?.length !== 0 && (
          <div className="nav-breadcrumbs">{<Breadcrumbs />}</div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: selectUser(state),
  userOrganisations: selectOrganisation(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getOrganisationsToolkit, clearAuth, resetState },
    dispatch
  ),
});

export default React.memo(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
);
