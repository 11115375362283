/* eslint-disable */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import {
  setSelectedSessionId,
  setGraphModal,
  setCheckedSessions2,
  setCheckedSessionsForComparison,
} from "store/playerActivitySlice";
import { displayDatePart, msToTime } from "utils/helper";
import { nanoid } from "@reduxjs/toolkit";

//commit to trigger build for node 17 test -second attempt

const ActivityTableRow = ({ session, i }) => {
  const { graphModal, checkedSessions, checkedSessionForComparison } =
    useSelector((state) => state.playerActivity);
  const dispatch = useDispatch();
  const setCheckedSessions = (data) => dispatch(setCheckedSessions2(data));
  const setCheckedSessionsForCompare = (data) =>
    dispatch(setCheckedSessionsForComparison(data));

  const isBatchStart = i % 20 === 0; // assuming batch size of 20
  const animationDelay = isBatchStart ? 0 : 0.1 * (i % 20);
  const checkedSessionList = checkedSessions;
  const checkedSessionsForComparisonList = checkedSessionForComparison;

  function handleSessionClick(sessionID) {
    dispatch(setSelectedSessionId(sessionID));
    dispatch(setGraphModal(!graphModal));
  }
  const handleChecked = (checked, value) => {
    let newCheckedSessions;
    let newCheckedSessionsForComparions;
    if (checked && !checkedSessionList.includes(value)) {
      newCheckedSessions = [...checkedSessionList, value];
      setCheckedSessions(newCheckedSessions);
    } else {
      newCheckedSessions = checkedSessionList.filter((item) => item !== value);
      setCheckedSessions(newCheckedSessions);
    }
    //wip
    if (checked && !checkedSessionList.includes(value)) {
      newCheckedSessionsForComparions = [
        ...checkedSessionsForComparisonList,
        session.ActivityName,
      ];
      setCheckedSessionsForCompare(newCheckedSessionsForComparions);
    } else {
      let index = checkedSessionsForComparisonList.indexOf(
        session.ActivityName
      );

      newCheckedSessionsForComparions = [...checkedSessionsForComparisonList];
      newCheckedSessionsForComparions.splice(index, 1);
      setCheckedSessionsForCompare(newCheckedSessionsForComparions);
    }
  };

  console.log(session)

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.6,
        delay: animationDelay,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      style={{ marginBottom: "0.5rem" }}
      key={i}
    >
      {" "}
      <div className="session-list-row">
        <div className="session-list-flex">
          <div className="checkbox-outside-table">
            <input
              className="checkboxes"
              type={"checkbox"}
              id={session.ID}
              value={session.ID}
              onChange={(value) =>
                handleChecked(value.target.checked, value.target.defaultValue)
              }
            />
          </div>

          {/* Checkbox Column --------------------------------------------------------------- */}
          <label htmlFor={session.ID} className="column-checkbox">
            <div
              className={`clickable-initials-bubble  ${
                Array.isArray(checkedSessionList) &&
                checkedSessionList.includes(String(session.ID))
                  ? "clickable-initials-bubble-selected"
                  : ""
              }`}
            >
              <div
                className={`initials-bubble ${
                  Array.isArray(checkedSessionList) &&
                  checkedSessionList.includes(String(session.ID))
                    ? "initials-bubble-selected"
                    : ""
                }`}
              >
                <p>
                  {Array.isArray(checkedSessionList) &&
                  checkedSessionList.includes(String(session.ID))
                    ? "✓"
                    : ""}
                </p>
              </div>
            </div>
          </label>

          {/* Date Column --------------------------------------------------------------- */}
          <div
            className="date-column"
            onClick={() => {
              handleSessionClick(session.ID);
            }}
          >
            {displayDatePart(session.tsCreated, 0)} <br />{" "}
            {displayDatePart(session?.tsCreated, 1)}
          </div>

          <div
            className="duration-column"
            onClick={() => {
              handleSessionClick(session.ID);
            }}
          >
            {msToTime(session?.duration)?.slice(0, 8)}
          </div>

          {/* ID Column --------------------------------------------------------------- */}
          <div
            className="id-column"
            onClick={() => {
              handleSessionClick(session.ID);
            }}
          >
            <div className="id-column-content">
              <p>{session.ID}</p>
            </div>
          </div>

          {/* Player Name Column --------------------------------------------------------------- */}
          <div
            className="player-column"
            onClick={() => {
              handleSessionClick(session.ID);
            }}
          >
            <p>{session.playerName}</p>
          </div>

          {/* Activity Name Column --------------------------------------------------------------- */}
          <div
            className="activity-name-column"
            onClick={() => {
              handleSessionClick(session.ID);
            }}
          >
            <p>{session?.activity ? session.activity : session.ActivityName}</p>
            {session.name !== session.playerName && <p>{session.name}</p>}
          </div>

          {/*Notes  Column --------------------------------------------------------------- */}
          <div
            className="notes-column"
            onClick={() => {
              handleSessionClick(session.ID);
            }}
          >
            {session.detail && <p>{session.detail}</p>}
          </div>

          {/* Tags Column --------------------------------------------------------------- */}
          <div className="tags-column">
            {session.tags && (
              <div className="tags">
                {JSON.parse(session.tags).length > 0 &&
                  JSON.parse(session.tags)
                    .slice(0, 4)
                    .map((tag) => (
                      <div className="tag" key={nanoid()}>
                        {tag.tag}
                      </div>
                    ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default React.memo(ActivityTableRow);
