/* eslint-disable */
import "./FilteringModal.scss";
import * as Dialog from "@radix-ui/react-dialog";
import Polygon from "../icons/downArrow";
import { useEffect, useState } from "react";
import ResetIcon from "../icons/reset";
const FilteringModal = (props) => {
  const {
    button,
    exerciseTypeFilter = false,
    athleteFilter = false,
    tagFilter = false,
    resetFilter,
  } = props;

  const [filterExpanded, setFilterExpanded] = useState({
    activity: true,
    athletes: false,
    tags: false,
  });

 


  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <span>{button}</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="filteringModal">
          <div className="filter-modal-container">
            {/* {exerciseTypeFilter}
							{athleteFilter}
              {tagFilter} */}
              <div className="filter-sections">
                <div
                  id={"filter-activity"}
                  className={`filter filter-activity ${
                    filterExpanded.activity && "open"
                  }`}
                >
                  <div
                    className="filter-heading"
                    onClick={window.innerWidth <= 820 ? () =>
                      setFilterExpanded({
                        activity: true,
                        athletes: false,
                        tags: false,
                      }) : () => console.log("test")
                    }
                  >
                    <Polygon />
                    <h3>Activity</h3>
                  </div>
                  <div className={`filtering-modal-container ${!athleteFilter ? 'filtering-modal-container-without-athletes' : ''}`}>
                    {(filterExpanded.activity || window.innerWidth >= 821) && exerciseTypeFilter}
                  </div>
                </div>
                {athleteFilter && (
                  <div
                    id={"filter-athletes"}
                    className={`filter filter-athletes ${
                      filterExpanded.athletes && "open"
                    }`}
                  >
                    <div
                      className="filter-heading"
                      onClick={window.innerWidth <= 820 ?  () =>
                        setFilterExpanded({
                          activity: false,
                          athletes: true,
                          tags: false,
                        } ) : () => console.log("test")
                      }
                    >
                      <Polygon />
                      <h3>Athletes</h3>
                    </div>
                    <div className={`filtering-modal-container`}>
                      {(filterExpanded.athletes || window.innerWidth >= 821) && athleteFilter}
                    </div>
                  </div>
                )}
                <div
                  id={"filter-tags"}
                  className={`filter filter-tags ${filterExpanded.tags && "open"}`}
                >
                  <div
                    className="filter-heading"
                    onClick={window.innerWidth <= 820 ? () =>
                      setFilterExpanded({
                        activity: false,
                        athletes: false,
                        tags: true,
                      }) : () => console.log("test")
                    }
                  >
                    <Polygon />
                    <h3>Tags</h3>
                  </div>
                  <div className={`filtering-modal-container ${!athleteFilter ? 'filtering-modal-container-without-athletes' : ''}`}>
                    {(filterExpanded.tags || window.innerWidth >= 821) && tagFilter}
                  </div>
                </div>
              </div>
            <div
              className={`filter-activity-reset`}
              onClick={() => resetFilter()}
            >
              <div className="filter-heading">
                <ResetIcon size={"16px"} color={"white"} />
                <h3>Reset</h3>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default FilteringModal;
