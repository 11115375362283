import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
  Legend,
  Line,
  ComposedChart,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "../../utils/constant";
// import { CustomTooltipForAsymmetry } from '../../utils/metrics';
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import { useState } from "react";
import MetricTable from "./metric-helpers/MetricTable";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSessionId, setGraphModal } from "store/playerActivitySlice";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

function chartXdomainScale(arr) {
  let newArr = arr.map((item) =>
    item.value < 0 ? item.value * -1 : item.value
  );

  return Math.max(...newArr) < 25
    ? [-25, 25]
    : Math.max(...newArr) >= 25 && Math.max(...newArr) < 50
    ? [-50, 50]
    : Math.max(...newArr) >= 50 && Math.max(...newArr) < 75
    ? [-75, 75]
    : [-100, 100];
}

export default function GroundContactTimeAsymmetryOverview(props) {
  const dispatch = useDispatch();
  const { data, setGraphIsOpen, setPosition, graphIsOpen, toggleGraph } = props;
  const [activeData, setActiveData] = useState({ locked: false });
  const style = { height: "150px", paddingBottom: "10px" };
  const [tooltipData, setTooltipData] = useState(null);
  const { graphModal } = useSelector((state) => state.playerActivity);

  function handleClick(sessionID) {
    dispatch(setSelectedSessionId(sessionID));
    dispatch(setGraphModal(!graphModal));
    setPosition(window.pageYOffset);
  }

  //limits the number of sessions displyed
  const dataLimited = data.slice(0, 10);
  //removes null values and maps over the asym values of the array
  const filteredFromNullArray = dataLimited
    .filter((item) => item.value !== null)
    .map((item) => item.value);

  //averages the data and displapyes the data withing two decimal points
  const averageAsym = (
    filteredFromNullArray.reduce((a, b) => a + b, 0) /
    filteredFromNullArray.length
  ).toFixed(2);
  if (data.status === "loading") return displayLoadingCharts();

  function GraphTableData(props) {
    const { payload } = props;
    if (
      activeData.locked === false &&
      payload.length > 0 &&
      JSON.stringify(payload) !== JSON.stringify(tooltipData)
    ) {
      setTooltipData(payload);
    }
  }

  const displayLegend = (data) => {
    return (
      <div className="metric-legend-horizontal" style={style}>
        <ResponsiveContainer>
          <ComposedChart
            height={200}
            data={data.Medium}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {/*Start of Placeholder lines used to label legend*/}
            <Line
              name="Average Asymmetry"
              dataKey="null123"
              stroke={NEW_AXIS_TICK}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="plainline"
            />

            <Line
              name="Left Foot"
              dataKey="null123"
              stroke={NEW_LEFT_COLOR}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="square"
            />
            <Line
              name="Right Foot"
              dataKey="null123"
              stroke={NEW_RIGHT_COLOR}
              strokeDasharray="5 5"
              opacity={0.1}
              legendType="square"
            />
            {/*End of Placeholder lines used to label legend*/}

            <Legend
              verticalAlign="top"
              align="right"
              layout="horizontal"
              wrapperStyle={{ top: -20, right: 0, fontSize: 11 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <div className={"column activity-overview "}>
      <div className="activity-overview-chart-container">
        <div className="chart-title-detail">
          <div
            className="player-profile-analytics-title"
            style={{
              margin: "0 0 0 75px",
              padding: "20px 0 20px 0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                display: "flex",
                position: "relative",
                textTransform: "uppercase",
              }}
            >
              Ground Contact Time Asymmetry [%]
            </p>
            <div style={{ paddingLeft: "0.5rem" }}>
              <MainTooltip
                tooltipText={
                  "Ground contact time asymmetry is defined as the absolute difference between the ground contact time measured for each foot expressed as a percentage of the maximum ground contact time recorded for either foot. The side with the lower ground contact time value will be more asymmetric "
                }
                tooltipIcon={"info"}
                iconSize={25}
              />
            </div>
          </div>
        </div>
        <div style={{ width: "100%", height: "500px" }}>
          <div className="metric-chart-container-parent">
            <div className="chart-container-padding">
              <div className="chart-container-with-table">
                <div className="chart-container-without-table">
                  <div className="chart-container-detail">
                    <div className="chart-container-detail-last-value">
                      <div className="value-title">AVERAGE</div>
                      <div>
                        <span
                          style={{
                            color:
                              averageAsym > 0
                                ? NEW_RIGHT_COLOR
                                : NEW_LEFT_COLOR,
                          }}
                        >
                          {averageAsym > 0 ? "R  " : "L  "}
                        </span>
                        {averageAsym > 0 ? averageAsym : averageAsym * -1} %
                        <span>
                          <MainTooltip
                            tooltipText={
                              `Average Asymmetry biased to the ${
                                averageAsym > 0 ? "right" : "left"
                              }`
                            }
                            tooltipIcon={"info"}
                            iconSize={25}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="legend"
                    style={{ height: "20px", width: "90%" }}
                  >
                    {displayLegend(dataLimited)}
                  </div>
                  <div
                    className="chart-container"
                    style={{
                      margin: "0 25px 20px 0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <ResponsiveContainer width="90%" height="95%">
                      <BarChart
                        data={dataLimited}
                        layout="vertical"
                        padding="20px"
                        onClick={(e) => {
                          if (activeData.locked === false) {
                            if (e && e.activePayload) {
                              setActiveData({ locked: true });
                              setTooltipData(e.activePayload);
                              setGraphIsOpen(true);
                            }
                          } else if (activeData.locked === true) {
                            setActiveData({ locked: false });
                          }
                        }}
                      >
                        <XAxis
                          type="number"
                          domain={chartXdomainScale(dataLimited)}
                          tick={{ fill: NEW_AXIS_TICK }}
                          stroke={NEW_AXIS_COLOR}
                          tickCount={11}
                          label={{
                            value: "% Asymmetry",
                            offset: -3,
                            position: "insideBottom",
                            fill: NEW_AXIS_TICK,
                          }}
                        />

                        <YAxis
                          dataKey="date"
                          type="category"
                          tick={{ fill: NEW_AXIS_TICK, fontSize: "9px" }}
                          stroke={NEW_AXIS_COLOR}
                        />

                        <CartesianGrid
                          strokeDasharray="4 4"
                          stroke={NEW_AXIS_COLOR}
                          strokeWidth={1}
                          opacity={0.4}
                        />

                        {/* <Tooltip 
                                                            content={<CustomTooltipForAsymmetry data={dataLimited}/>} 
                                                            cursor={{ fill: NEW_AXIS_COLOR, opacity: ".3"}}
                                                            
                                                            /> */}

                        <Tooltip
                          cursor={{ fill: NEW_AXIS_COLOR, opacity: ".3" }}
                          // content={<GraphTableData data={chartData} component={component}/>}
                          content={<GraphTableData data={dataLimited} />}
                          trigger={activeData.locked ? "click" : "hover"}
                        />
                        <ReferenceLine
                          x={averageAsym}
                          stroke={NEW_AXIS_TICK}
                          dot={false}
                          strokeDasharray="5 5"
                          name="Averege Asymmetry"
                        />
                        <ReferenceLine
                          x={0}
                          stroke={NEW_AXIS_COLOR}
                          dot={false}
                          strokeDasharray="5 5"
                          name="Averege Asymmetry"
                        />

                        <Bar
                          dataKey="value"
                          legendType="none"
                          // onClick= {
                          //     (event, payload) => {
                          //         openSessionDetails(dataLimited[payload].sessionid,component)

                          // }}
                        >
                          {dataLimited.map((entry, index) => (
                            <Cell
                              key={`groundCOntact-asym-${index}`}
                              fill={
                                entry["value"] < 0
                                  ? NEW_LEFT_COLOR
                                  : NEW_RIGHT_COLOR
                              }
                              opacity={0.7}
                              legendType="none"
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                <MetricTable
                  graphName={"GroundContactTimeAsymmetry"}
                  tableData={tooltipData}
                  graphIsOpen={graphIsOpen}
                  toggleGraph={toggleGraph}
                  openSession={handleClick}
                  sessionLocked={activeData.locked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
