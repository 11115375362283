import React from 'react';
import { connect } from 'react-redux';
import { selectIsAuthenticated } from 'store/authSlice';
//import PropTypes from 'prop-types';
// this is a wrapper component


// git test

export default function withAuthentication(ComposedComponent) {

  class Authenticate extends React.Component {

    componentDidMount() {
      this.checkLoginStatus(this.props);
    }

    componentDidUpdate(nextProps) {
      this.checkLoginStatus(nextProps);
    }

    checkLoginStatus(props) {
      if ( (this.props.location.pathname.indexOf('/login') > -1) || (this.props.location.pathname.indexOf('/signup') > -1) || (this.props.location.pathname.indexOf('/resetpass') > -1) ){
        // we are loading login page - check if we are already logged in
        // - if we are - then automatically redirect user to home page
        if (props.isAuthenticated) {
          this.props.history.push('/');
          //forces refresh to clear previous login state
          location.reload();
        }
      } else {
        // we are not on Login page - so make sure we ARE logged in
        this.redirectIfLoggedOut(props);
      }
    }

    redirectIfLoggedOut(props) {
      if (!props.isAuthenticated) {
        this.props.history.push('/login');
      }
    }

    render() {
      return (
        <ComposedComponent {...this.props} />
      );
    }
  }

  // Authenticate.propTypes = {
  //   location: PropTypes.shape({
  //     pathname: PropTypes.string.isRequired,
  //   }).isRequired,
  // };

  // Authenticate.contextTypes = {
  //   router: PropTypes.object.isRequired,
  // };



  function mapStateToProps(state) {
    return {
      isAuthenticated: selectIsAuthenticated(state),
    };
  }


  return connect(mapStateToProps)(Authenticate);
}
