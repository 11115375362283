/* eslint-disable */

const LineChartIcon = (props) => {
    const {size = '16', fillOpacity = '0.6', fill = "#000000"} = props;

    return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7C13 7.2444 12.9136 7.46599 12.7686 7.63981C12.5836 7.86158 12.3084 8 12 8C11.7507 8 11.5249 7.91006 11.3495 7.75958C11.134 7.57466 11 7.30352 11 7ZM12 4C10.3431 4 9 5.34315 9 7C9 7.54509 9.14582 8.05652 9.39983 8.49694L7.08183 11.2013C6.74599 11.0714 6.381 11 6 11C4.34315 11 3 12.3431 3 14C3 15.6569 4.34315 17 6 17C7.65685 17 9 15.6569 9 14C9 13.4549 8.85418 12.9435 8.60017 12.5031L10.9182 9.79873C11.254 9.92864 11.619 10 12 10C12.56 10 13.0845 9.84611 13.5329 9.57911L15.1865 10.9571C15.066 11.2822 15 11.6337 15 12C15 13.6569 16.3431 15 18 15C19.6569 15 21 13.6569 21 12C21 10.3431 19.6569 9 18 9C17.44 9 16.9155 9.15389 16.4671 9.42089L14.8135 8.04286C14.934 7.7178 15 7.36627 15 7C15 5.34315 13.6569 4 12 4ZM17.2314 11.3602C17.4164 11.1384 17.6916 11 18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.7556 17.0864 11.534 17.2314 11.3602ZM5 14C5 13.4477 5.44772 13 6 13C6.24931 13 6.47507 13.0899 6.65047 13.2404C6.86601 13.4253 7 13.6965 7 14C7 14.5523 6.55228 15 6 15C5.44772 15 5 14.5523 5 14ZM4 18C3.44772 18 3 18.4477 3 19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H4Z" fill={fill}/>
		</svg>
    )
}

export default LineChartIcon