import { api } from './api'

export const getAthletesHistoricalActivitiesAPI = (athleteID) => {
  return api.get(`/session-metric-data/getAthletesHistoricalActivities/${athleteID}`).then(res => {
    if (res.data) {
      return res.data.activities
    }
    return []
  })
}

export const getAthletesHistoricalActivityAPI = (athleteID, activityid) => {
  return api.get(`/session-metric-data/getAthleteHistoricalJumpChartData/${athleteID}/${activityid}/`).then(res => {
    if (res.data) {

      if (res.data.sdP && res.data.sdP > 0) {
        for (var i = 0; i < res.data.chartData.length; i++) {
          let thisChartItem = res.data.chartData[i]
          thisChartItem.sdP = res.data.sdP
          thisChartItem.sdN = res.data.sdN
        }
      }

      return res.data
    }
    return []
  })
}

//getting all the tags associated with user
export const getTagListForUserAPI = () => {
  return api.get(`/tags/getTagListForUser`).then(res => {
    return res
  })
   
}

//api for saving user saved layout update
export const updateUserLayoutAPI = (data) => {
  return api.post(`/updateLayout`, data).then(res => {
    return res
  })
   
}



// export const getAthleteHistoricalGaitChartOverViewDataAPI = (athleteID, activityid) => {
//   return api.get(`https://d36w898lq40ykt.cloudfront.net/session-metric-data/getAthleteHistoricalGaitChartOverViewData/164/`).then(res => {
//     if (res.data) {

//       if (res.data.sdP && res.data.sdP > 0) {
//         for (var i = 0; i < res.data.chartData.length; i++) {
//           let thisChartItem = res.data.chartData[i]
//           thisChartItem.sdP = res.data.sdP
//           thisChartItem.sdN = res.data.sdN
//         }
//       }

//       return res.data
//     }
//     return []
//   })
// }

//https://d36w898lq40ykt.cloudfront.net/session-metric-data/getAthleteHistoricalGaitChartOverViewData/164/


