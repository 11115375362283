import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSessionListAPI, updateSessionDetailAPI } from "../api/session";
import { resetState } from "./resetSlice";

export const getSessionList = createAsyncThunk(
  "sessionList/getSessionList",
  async () => {
    const response = await getSessionListAPI();
    return response;
  }
);

export const getupdateSessionDetail = createAsyncThunk(
  "sessionList/updateSessionDetail",
  async (session, { dispatch }) => {
    const response = await updateSessionDetailAPI(session);
    if (response.data.affectedRows === 1) {
      dispatch(getSessionList());
    }
    return response;
  }
);

const initialState = {
  sessionList: [],
  status: "idle",
};

const sessionListSlice = createSlice({
  name: "sessionList",
  initialState,
  reducers: {
    updateSessionDetail: (state, action) => {
      let tempSessionList = [...state.sessionList];
      tempSessionList = tempSessionList.map((session) => {
        if (session.ID === action.payload.id) {
          return {
            ...session,
            detail: [...action.payload.detail],
          };
        }
        return session;
      });
      state.sessionList = [...tempSessionList];
    },
    updateFootstrikeData: (state, action) => {
      const { left, right } = action.payload;

      if (left) {
        state.runData.data.FootstrikeData = {
          ...state.runData.data.FootstrikeData,
          left: left,
          loaded: false,
        };
      }

      if (right) {
        state.runData.data.FootstrikeData = {
          ...state.runData.data.FootstrikeData,
          right: right,
          loaded: true,
        };
      }
    },
    setSessionList: (state, action) => {
      state.sessionList = action.payload;
      state.status = "succeeded";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessionList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSessionList.fulfilled, (state, action) => {
        state.sessionList = action.payload;
        state.status = "succeeded";
      })
      .addCase(getSessionList.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getupdateSessionDetail.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(getupdateSessionDetail.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(resetState, () => {
        return initialState;
      });
  },
});

export const { updateSessionDetail, updateFootstrikeData, setSessionList } =
  sessionListSlice.actions;
export const selectSessionList2 = (state) => state.sessionList;
export const selectSessionList = (state) => state.sessionList.sessionList;
export default sessionListSlice.reducer;
