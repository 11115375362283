/* eslint-disable */
import "../../styles/overviewMetricTable.scss";
import Polygon from "components/common/icons/downArrow";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

export default function OverviewMetricTable(props) {
  const { overviewTableData } = props;

  function handleSubTableVisibility(key) {
    if (
      !document
        .getElementById(`sub-table-${key}`)
        .classList.contains("sub-table-open")
    ) {
      document
        .getElementById(`sub-table-${key}`)
        .classList.add("sub-table-open");
      document
        .getElementById(`table-row-${key}`)
        .classList.add("row-sub-table-open");
      document
        .getElementById(`table-row-arrow-${key}`)
        .classList.add("row-arrow-open");
    } else {
      document
        .getElementById(`sub-table-${key}`)
        .classList.remove("sub-table-open");
      document
        .getElementById(`table-row-${key}`)
        .classList.remove("row-sub-table-open");
      document
        .getElementById(`table-row-arrow-${key}`)
        .classList.remove("row-arrow-open");
    }
  }

  return (
    <div className="overview-metric-table-container">
      <div className="overview-metric-table">
        <div className="table-headings">
          <div className="main-heading">
            <div className="headings">Metric Title</div>
          </div>
          <div className="middle-headings">
            <div className="headings">10</div>
            <div className="headings">9</div>
            <div className="headings">8</div>
            <div className="headings">7</div>
            <div className="headings">6</div>
            <div className="headings">5</div>
            <div className="headings">4</div>
            <div className="headings">3</div>
            <div className="headings">2</div>
          </div>
          <div className="latest-heading">
            <div className="headings">Latest Session</div>
          </div>
          <div className="average-heading">
            <div className="headings">Average</div>
          </div>
        </div>

        {overviewTableData.map((item) => (
          <>
            <div
              className="table-row"
              key={item.metricID}
              id={`table-row-${item.metricID}`}
            >
              <div className="table-row-heading">
                <div
                  className="heading"
                  onClick={() => {
                    handleSubTableVisibility(`${item.metricID}`);
                  }}
                >
                  <div
                    className="heading-arrow"
                    id={`table-row-arrow-${item.metricID}`}
                  >
                    <Polygon />
                  </div>
                  <div className="heading-centering">
                    <p>{item?.metric}</p>
                  </div>
                </div>
                <div>
                  <MainTooltip
                    tooltipText={item.tooltip}
                    tooltipIcon={"info"}
                    iconSize={25}
                  />
                </div>
              </div>
              {item.data.length &&
                item.metric !== "SL CMJ (%)" &&
                item.metric !== "Ground Contact (%)" && (
                  <div className="row-middle-headings">
                    {item?.data?.map((item) => (
                      <div className="headings" key={Math.random()}>
                        {item?.value === undefined
                          ? "-"
                          : item?.value !== "-"
                          ? item?.value?.toFixed(2)
                          : "-"}
                      </div>
                    ))}
                  </div>
                )}
              {item.data.length &&
                (item.metric === "SL CMJ (%)" ||
                  item.metric === "Ground Contact (%)") && (
                  <div className="row-middle-headings">
                    {item?.data?.map((item) => (
                      <div className="headings" key={Math.random()}>
                        {item?.value === undefined
                          ? "-"
                          : item?.value !== "-"
                          ? item?.value?.toFixed(2) > 0
                            ? `${item?.value?.toFixed(1)}R`
                            : `${item?.value?.toFixed(1)}L`
                          : "-"}
                      </div>
                    ))}
                  </div>
                )}

              <div className="row-latest-heading">
                <div className="headings">
                  {item?.latestValue?.value === undefined
                    ? "-"
                    : item?.latestValue?.value !== "-"
                    ? item?.latestValue?.value?.toFixed(2)
                    : "-"}
                </div>

                {item.data.length &&
                  item.metric !== "SL CMJ (%)" &&
                  item.metric !== "Ground Contact (%)" && (
                    <div
                      className={`arrow arrow-${
                        item?.latestValue?.value &&
                        item?.latestValue?.value !== "-" &&
                        item?.latestValue?.value?.toFixed(2) >
                          item?.data[item?.data.length - 1]
                          ? "up"
                          : "down"
                      }`}
                    >
                      {item?.latestValue?.value?.toFixed(2) >
                      item?.data[item?.data.length - 1].value
                        ? "↑"
                        : "↓"}
                    </div>
                  )}

                {item.data.length &&
                  (item.metric === "SL CMJ (%)" ||
                    item.metric === "Ground Contact (%)") && (
                    <div
                      className={`arrow arrow-${
                        item?.latestValue?.value &&
                        item?.latestValue?.value !== "-" &&
                        item?.latestValue?.value?.toFixed(2) >
                          item?.data[item?.data.length - 1]
                          ? "up"
                          : "down"
                      }`}
                    >
                      {item?.latestValue?.value?.toFixed(2) > 0 ? " R" : " L"}
                    </div>
                  )}
              </div>

              <div className="row-average-heading">
                <div className="headings">
                  {item?.dataAverage?.value === undefined
                    ? "-"
                    : item?.dataAverage?.value !== "-"
                    ? item?.dataAverage?.value?.toFixed(2)
                    : "-"}
                </div>

                {item.data.length &&
                  item.metric !== "SL CMJ (%)" &&
                  item.metric !== "Ground Contact (%)" && (
                    <div
                      className={`arrow arrow-${
                        item?.dataAverage?.value &&
                        item?.dataAverage?.value !== "-" &&
                        item?.dataAverage?.value?.toFixed(2) >
                          item?.data[item?.data.length - 1]
                          ? "up"
                          : "down"
                      }`}
                    >
                      {item?.dataAverage?.value?.toFixed(2) <
                      item?.latestValue?.value?.toFixed(2)
                        ? "↑"
                        : "↓"}
                    </div>
                  )}

                {item.data.length &&
                  (item.metric === "SL CMJ (%)" ||
                    item.metric === "Ground Contact (%)") && (
                    <div
                      className={`arrow arrow-${
                        item?.dataAverage?.value &&
                        item?.dataAverage?.value !== "-" &&
                        item?.dataAverage?.value?.toFixed(2) >
                          item?.data[item?.data.length - 1]
                          ? "up"
                          : "down"
                      }`}
                    >
                      {item?.dataAverage?.value?.toFixed(2) > 0 ? " R" : " L"}
                    </div>
                  )}
              </div>
            </div>
            <div className="sub-table" id={`sub-table-${item.metricID}`}>
              {item.subValues.map((item) => (
                <div className="table-sub-row" key={Math.random()}>
                  <div className="sub-row-heading">
                    <div className="sub-heading">{item?.subRowHeading}</div>
                  </div>

                  <div className="sub-row-middle-headings">
                    {item?.subRowData.map((item) => (
                      <div className="headings" key={Math.random()}>
                        {item?.subValue !== "-" ? item?.subValue : "-"}
                      </div>
                    ))}
                  </div>
                  <div className="sub-row-latest-heading">
                    <div className="headings">
                      {item?.lastestSubRowData?.subValue}
                    </div>
                  </div>
                  <div className="sub-row-average-heading">
                    {item?.averageSubValue ? (
                      <div className="headings">{item?.averageSubValue}</div>
                    ) : (
                      <div className="headings">{"-"}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
