import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearAuth } from 'store/authSlice';
import AppRouter from "./AppRouter";
import NavBar from './common/navBar/navBar';
import { selectUser } from 'store/authSlice';


const AppContainer = (props) => {

  
  let containerClass = "loggedInContainer"
  let rootContainerClass = ""

  if (!(props.userDetails && props.userDetails.token)) {
    //the user is not logged in !!!!
    containerClass = "notloggedInContainer"
    rootContainerClass = "root-contain"
    return (
      <>
        <div className="wrapper">
          <div className={rootContainerClass}>
            <div className={containerClass}>
              <AppRouter />
            </div>
          </div>
        </div>
      </>
    );
  }
  else {
    //user IS logged in
    return (
      <>
        <div className="wrapper">
          <div className={containerClass}>
            <NavBar />
            <AppRouter />
          </div>
        </div>
      </>
    );
  }
}




function mapStateToProps(state) {
  return {
    userDetails: selectUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(clearAuth, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
