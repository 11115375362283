import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateFootstrikeData } from "store/sessionListSlice";
import "./metric-helpers/swing-stance-foot-strike.scss";
import FootStrikeFoot from "./metric-helpers/FootStrikeFoot";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import {
  NEW_RIGHT_SWING_COLOR,
  NEW_RIGHT_STANCE_COLOR,
  NEW_LEFT_SWING_COLOR,
  NEW_LEFT_STANCE_COLOR,
} from "../../utils/constant";
import { selectSession } from "store/sessionSlice";
import MainTooltip from "components/common/mainTooltip/MainTooltip";
function SwingStanceFootStrike(props) {
  const { session } = props;
  let footstrikeData = null;
  if (session.runData.data.FootstrikeData) {
    footstrikeData = session.runData.data.FootstrikeData;
  }
  //data variables
  let leftFootData = [0, 0, 0];
  let rightFootData = [0, 0, 0];
  let leftPieData = {};
  let rightPieData = {};
  //conditional assignment > checkes if data exists before it assigns it to variables
  if (session.runData.data && session.runData.data?.FootstrikeData) {
    if (session.runData.data.FootstrikeData?.left) {
      leftFootData = [
        footstrikeData?.left.Front,
        footstrikeData?.left.Middle,
        footstrikeData?.left.Back,
      ];
    }
    if (session.runData.data.FootstrikeData?.right) {
      rightFootData = [
        footstrikeData?.right.Front,
        footstrikeData?.right.Middle,
        footstrikeData?.right.Back,
      ];
    }
  }
  //conditional assignment > checkes if data exists before it assigns it to variables
  if (session.runData.data && session?.runData?.data?.SwingStanceData) {
    if (
      session.runData.data.SwingStanceData.totalRatio?.left
        .leftTotalFlightTime ||
      session.runData.data.SwingStanceData.totalRatio?.left
        .leftTotalFlightTime ||
      session.runData.data.SwingStanceData.totalRatio?.right
        .rightTotalFlightTime ||
      session.runData.data.SwingStanceData.totalRatio?.right
        .rightTotalContactTime
    ) {
      leftPieData = [
        {
          name: "Swing",
          value:
            session.runData.data.SwingStanceData.totalRatio?.left
              .leftTotalFlightTime,
        },
        {
          name: "Stance",
          value:
            session.runData.data.SwingStanceData.totalRatio?.left
              .leftTotalContactTime,
        },
      ];

      rightPieData = [
        {
          name: "Swing",
          value:
            session.runData.data.SwingStanceData.totalRatio?.right
              .rightTotalFlightTime,
        },
        {
          name: "Stance",
          value:
            session.runData.data.SwingStanceData.totalRatio?.right
              .rightTotalContactTime,
        },
      ];
    }

    if (
      session.runData.data.SwingStanceData.totalRatio?.left.stanceRatio ||
      session.runData.data.SwingStanceData.totalRatio?.left.stanceRatio ||
      session.runData.data.SwingStanceData.totalRatio?.right.swingRatio ||
      session.runData.data.SwingStanceData.totalRatio?.right.swingRatio
    ) {
      leftPieData = [
        {
          name: "Swing",
          value:
            session.runData.data.SwingStanceData.totalRatio?.left.swingRatio,
        },
        {
          name: "Stance",
          value:
            session.runData.data.SwingStanceData.totalRatio?.left.stanceRatio,
        },
      ];

      rightPieData = [
        {
          name: "Swing",
          value:
            session.runData.data.SwingStanceData.totalRatio?.right.swingRatio,
        },
        {
          name: "Stance",
          value:
            session.runData.data.SwingStanceData.totalRatio?.right.stanceRatio,
        },
      ];
    }
  }
  //custom tooltip for pie
  function CustomTooltipPie({ active, payload, data }) {
    if (active && payload && payload.length) {
      let total = data[0].value + data[1].value;
      let percentage = ((payload[0].value / total) * 100).toFixed(2);

      return (
        <div className="custom-tooltip">
          <div className="label">
            {`${payload[0].name}`} : {` ${percentage}`}
          </div>
        </div>
      );
    }

    return null;
  }

  //mapping foot components
  function mappedFeet(leftFoot = [0, 0, 0], rightFoot = [0, 0, 0]) {
    return (
      <div className="feet">
        <div className="left-foot">
          <FootStrikeFoot whichFoot={"left"} footData={leftFoot} />
        </div>
        <div className="right-foot">
          <FootStrikeFoot whichFoot={"right"} footData={rightFoot} />
        </div>
      </div>
    );
  }
  //mapping pie chart
  function displayPie(pieData, pieHeading, foot) {
    //pie consts
    const LEFT_COLORS = [NEW_LEFT_STANCE_COLOR, NEW_LEFT_SWING_COLOR];
    const RIGHT_COLORS = [NEW_RIGHT_STANCE_COLOR, NEW_RIGHT_SWING_COLOR];
    const RADIAN = Math.PI / 180;
    if (session.runData.status === "loading") {
      return displayLoadingCharts();
    } else if (
      (session.runData.data.SwingStanceData &&
        session.runData.data.SwingStanceData.totalRatio &&
        session.runData.data.SwingStanceData.totalRatio?.left != null &&
        session.runData.data.SwingStanceData.totalRatio?.right != null) != true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (session.runData.data.SwingStanceData) {
      const renderCustomizedLabel = (payload) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, percent } = payload;

        // * moves the lables either closer or further from the center of the piechart
        const radius = innerRadius + (outerRadius - innerRadius) * -1.1;
        let x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (x > cx) {
          x = x - 15;
        } else {
          x = x + 15;
        }
        // let title = 'Swing'
        // if (index == 1) {
        //     title = 'Stance'
        // }
        return (
          <text
            fontSize={16}
            fontWeight={"bold"}
            x={x}
            y={y}
            fill={payload.fill}
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };

      return (
        <div className="metric-chart-twin-pie-container">
          <div className="chart-container-padding">
            <div className="chart-container-header">{pieHeading}</div>

            <div className="pie-chart-container">
              <ResponsiveContainer width="100%" height="100%">
                {pieData && (
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      // innerRadius={70}
                      // outerRadius={95}
                      innerRadius="70%"
                      outerRadius="95%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      paddingAngle={10}
                      stroke="none"
                      opacity={0.8}
                    >
                      {pieData &&
                        Object.keys(pieData).length &&
                        pieData.map((entry, index) =>
                          foot === "left" ? (
                            <Cell
                              key={`cell-${index}`}
                              fill={LEFT_COLORS[index % LEFT_COLORS.length]}
                            />
                          ) : (
                            <Cell
                              key={`cell-${index}`}
                              fill={RIGHT_COLORS[index % RIGHT_COLORS.length]}
                            />
                          )
                        )}
                    </Pie>
                    <Tooltip content={<CustomTooltipPie data={pieData} />} />
                    <Legend />
                  </PieChart>
                )}
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      );
    }
  }
  if (session.runData.data.FootstrikeData) {
    return (
      <div className="metric-container-parent">
        <div className="metric-container swing-strike-container">
          <div className="left-pie-contained">
            {displayPie(leftPieData, "Left Foot Swing Stance Ratio", "left")}
          </div>
          <div className="feet-contained">
            <div className="metric-title-main-title">
              Foot Strike{" "}
              <div style={{ paddingLeft: "0.5rem" }}>
                <MainTooltip
                  tooltipText={
                    "The foot strike infographic displays the number of footstrikes that occurred in the fore-, mid- and rear-foot during the recorded session. The swing stance ratio shows the percentage of the gait cycle that each foot spends in stance (contact with the ground) and swing respectively."
                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
              </div>
            </div>
            {mappedFeet(leftFootData, rightFootData)}
          </div>
          <div className="right-pie-contained">
            {displayPie(rightPieData, "Right Foot Swing Stance Ratio", "right")}
          </div>
        </div>
      </div>
    );
  } else if (!session.runData.data.FootstrikeData) {
    return (
      <div className="metric-container-parent">
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    session: selectSession(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sessionActions: bindActionCreators({ updateFootstrikeData }, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwingStanceFootStrike);
