/* eslint-disable */
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getAthletesHistoricalActivities,
  resetUserData,
  selectUserData,
} from "store/userSlice";
import AthleteActivityOverviewItem from "./AthleteActivityOverviewItem";
import { selectAthlete } from "store/athleteSlice";
import useWhyDidYouUpdate from "components/common/hooks/useWhydidyouUpdate";
import { getSessionList } from "store/sessionListSlice";
import { useCallback, useEffect, useState } from "react";
import PlayerActivityDetails from "./PlayerActivityDetails2";
import { setGraphModal, setSelectedSessionId } from "store/playerActivitySlice";

// import required modules
import { useCheckForJumpData } from "components/common/hooks/useCheckForJumpData";
import { resetAllData } from "store/sessionSlice";
import { reduce } from "lodash";

//helper funciton
import { msToTime } from "utils/helper";

const AthleteActivityOverviewList = (props) => {
  useWhyDidYouUpdate("AthleteActivityOverviewList", props);
  const {
    userData,
    athleteid,
    athlete,
    heightOfSwiper = "100vh",
    handleLoadClick,
  } = props;
  const { graphModal, sessionList, selectedSessionId, session } = useSelector(
    (state) => ({
      graphModal: state.playerActivity.graphModal,
      selectedSessionId: state.playerActivity.selectedSessionId,
      sessionList: state.sessionList,
      session: state.session,
    })
  );
  const dispatch = useDispatch();
  const checkForJumpData = useCheckForJumpData();

  const [scrollPosition, setScrollPosition] = useState(0);
  const userJumpData = useSelector(selectUserData).activityObject;

  //setting the scroll height to state, to be remembered for the modal close action
  const settingScrollHeight = useCallback(
    (value) => {
      setScrollPosition(value);
    },
    [scrollPosition]
  );

  useEffect(() => {
    if (
      sessionList.sessionList.length === 0 &&
      sessionList.status === "idle" &&
      sessionList.status !== "loading"
    ) {
      dispatch(getSessionList());
    }
    if (session.runData.loaded === true || session.jumpData.loaded === true) {
      dispatch(resetAllData());
    }
    if (
      selectedSessionId !==
      (session.runData.sessionID || session.jumpData.sessionID)
    ) {
      checkForJumpData();
    }
  }, [graphModal, sessionList?.sessionList?.data, selectedSessionId]);

  function closeModal() {
    dispatch(setGraphModal(!graphModal));
    dispatch(setSelectedSessionId(null));
    //scrolling to the original possition of the graph before the modal was open
    setTimeout(() => {
      window.scrollTo({ top: Math.round(scrollPosition), behavior: "smooth" });
    }, 100);
  }

  if (
    userData.userHistoricalActivitiesLoaded === true &&
    userData.userHistoricalActivities.length === 0
  ) {
    return (
      <div className="not-found-graph">
        No Graph Data Found Go & Hit The Gym First
      </div>
    );
  }

  //Overview Page Jumps
  let jumps = Object.entries(userData.userHistoricalActivities)
    .filter((activity) => {
      return (
        (activity[1].activityTypeID == 2) ||
        (activity[1].activityTypeID == 2 ) ||
        (activity[1].activityTypeID == 2 )
      );
    })
    .map((activity) => activity[1]);


    console.log("JUMPS : ", jumps)

  const athleteHistoricalLoad = athlete.athleteHistoricalLoad;
  const athleteHistoricalGait = athlete.athleteHistoricalGait;
  const athleteHistoricalGroundContact = athlete.athleteHistoricalGroundContact;
  const doubleLegCountermovementJumpData = userJumpData?.activity4?.chartData;
  const doubleLegRepeatedHopsData = userJumpData?.activity10?.chartData;
  const singleLegCountermovementJumpData = userJumpData?.activity14?.chartData;

  //load date converter
  function convertToEpoch(timeString) {
    const date = new Date(timeString);

    const epochTime = date.getTime();
    return epochTime;
  }
  let loadData = [];

  if (
    athleteHistoricalLoad &&
    athleteHistoricalLoad.data.length > 0 &&
    athleteHistoricalLoad?.status === "succeeded"
  ) {
    loadData = athleteHistoricalLoad.data
      .map((item) => ({
        date: convertToEpoch(item.date),
        totalLoad: item.totalLoad,
        totalRelativeLoad: item?.totalRelativeLoad,
        totalRightLoad: item.totalRightLoad,
        totalLeftLoad: item.totalLeftLoad,
        duration: msToTime(item.totalDurationForThisDate).slice(0,8),
      }))
      .sort((a, b) => {
        return a.date - b.date;
      });
  }

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  //reuseable array population for table data
  function formatData(
    data,
    metricID,
    metric,
    tooltip,
    mainValue,
    secondValue = null,
    thirdValue = null,
    secondHeading,
    thirdHeading,
    date = true,
    fourthValue = null,
    fourthHeading
  ) {
    let dataFormatted;

    //average data reducer
    const reduceAverages = (arr) => {
      let valueSum = 0;
      let secondValueSum = 0;
      let thirdValueSum = 0;
      let fourthValueSum = 0;
      let count1 = 0;
      let count2 = 0;
      let count3 = 0;
      let count4 = 0;

      for (let obj of arr) {
        if (typeof obj.value === "number") {
          valueSum += obj.value;
          count1++;
        }
        if (typeof obj.secondValue === "number") {
          secondValueSum += obj.secondValue;
          count2++;
        }
        if (typeof obj.thirdValue === "number") {
          thirdValueSum += obj.thirdValue;
          count3++;
        }
        if (typeof obj.fourthValue === "number") {
          fourthValueSum += obj.fourthValue;
          count4++;
        }
      }
      return {
        value: valueSum / count1,
        secondValue: secondValueSum / count2,
        thirdValue: thirdValueSum / count3,
        fourthValue: fourthValueSum / count4,
      };
    };

    if (date) {
      dataFormatted = data?.map((item) => ({
        date: item.date,
        value: item[mainValue],
        secondValue: item[secondValue],
        thirdValue: item[thirdValue],
        fourthValue: item[fourthValue],
      }));
    } else {
      dataFormatted = data?.map((item) => ({
        date: "test" + String(item.sessionID) + "test",
        value: item[mainValue],
        secondValue: item[secondValue],
        thirdValue: item[thirdValue],
        fourthValue: item[fourthValue],
      }));
    }

    while (dataFormatted.length < 10) {
      dataFormatted.push({ date: "-", value: "-" });
    }

    dataFormatted.reverse();

    const extraData = [
      {
        subRowHeading: date ? "Date" : "Session ID",
        subRowData: dataFormatted
          .map((item) => ({
            subValue:
              item.date !== "-"
                ? item.date.slice(4, item.date.length - 4)
                : "-",
          }))
          .slice(0, 9),
        lastestSubRowData: dataFormatted
          .map((item) => ({
            subValue:
              item.date !== "-"
                ? item.date.slice(4, item.date.length - 4)
                : "-",
          }))
          .slice(9, 10)[0],
      },
    ];

    if (secondValue) {
      extraData.push({
        subRowHeading: secondHeading,
        subRowData: dataFormatted
          .map((item) => ({
            subValue: item.secondValue
              ? secondValue === "totalDurationForThisDate"
                ? msToTime(item.secondValue).split(".")[0].slice(0,8)
                : item.secondValue?.toFixed(2)
              : "-",
          }))
          .slice(0, 9),
        lastestSubRowData: dataFormatted
          .map((item) => ({
            subValue: item.secondValue
              ? secondValue === "totalDurationForThisDate"
                ? msToTime(item.secondValue).split(".")[0].slice(0,8)
                : item.secondValue?.toFixed(2)
              : "-",
          }))
          .slice(9, 10)[0],
        averageSubValue:
          secondValue === "totalDurationForThisDate"
            ? msToTime(reduceAverages(dataFormatted).secondValue).split(".")[0].slice(0,8)
            : reduceAverages(dataFormatted).secondValue
            ? reduceAverages(dataFormatted).secondValue.toFixed(2)
            : "-",
      });
    }

    if (thirdValue) {
      extraData.push({
        subRowHeading: thirdHeading,
        subRowData: dataFormatted
          .map((item) => ({
            subValue: item.thirdValue
              ? thirdValue === "cadence"
                ? item.thirdValue?.toFixed(2) * 2
                : item.thirdValue.toFixed(2)
              : "-",
          }))
          .slice(0, 9),
        lastestSubRowData: dataFormatted
          .map((item) => ({
            subValue: item.thirdValue
              ? thirdValue === "cadence"
                ? item.thirdValue?.toFixed(2) * 2
                : item.thirdValue.toFixed(2)
              : "-",
          }))
          .slice(9, 10)[0],
        averageSubValue: reduceAverages(dataFormatted).thirdValue
          ? reduceAverages(dataFormatted).thirdValue.toFixed(2)
          : "-",
      });
    }

    if (fourthValue) {
      extraData.push({
        subRowHeading: fourthHeading,
        subRowData: dataFormatted
          .map((item) => ({
            subValue: item.fourthValue
              ? fourthValue === "cadence"
                ? item.fourthValue?.toFixed(2) * 2
                : item.fourthValue.toFixed(2)
              : "-",
          }))
          .slice(0, 9),
        lastestSubRowData: dataFormatted
          .map((item) => ({
            subValue: item.fourthValue
              ? fourthValue === "cadence"
                ? item.fourthValue?.toFixed(2) * 2
                : item.fourthValue.toFixed(2)
              : "-",
          }))
          .slice(9, 10)[0],
        averageSubValue: reduceAverages(dataFormatted).fourthValue
          ? reduceAverages(dataFormatted).fourthValue.toFixed(2)
          : "-",
      });
    }

    overviewTableData.push({
      metricID,
      metric,
      tooltip,
      data: dataFormatted.slice(0, 9),
      dataAverage: reduceAverages(dataFormatted),
      latestValue: dataFormatted.slice(9, 10)[0],
      subValues: extraData.slice(0, 9),
    });
  }

  //manipulation of data for the overview data table
  const overviewTableData = [];

  if (
    athleteHistoricalLoad &&
    athleteHistoricalLoad.data.length > 0 &&
    athleteHistoricalLoad?.status === "succeeded"
  ) {
    formatData(
      athleteHistoricalLoad.data,
      1,
      "Daily Load",
      "Daily load represents to individuals physical effort throughout the day, either by individual limb or accumulative load.",
      "totalLoad",
      "totalDurationForThisDate",
      "totalLeftLoad",
      "Duration",
      "Total Left Load",
      true,
      "totalRightLoad",
      "Total Right Load"
    );
  }

  if (
    doubleLegCountermovementJumpData &&
    doubleLegCountermovementJumpData.length > 0 &&
    athleteHistoricalLoad.status === "succeeded"
  ) {
    formatData(
      doubleLegCountermovementJumpData,
      2,
      "DL CMJ (Height)",
      "Double Leg Countermovement Jump (cm)",
      "value",
      "peakPower",
      "flightTime",
      "Peak Power (w)",
      "Flight Time (s)"
    );
  }

  if (
    doubleLegRepeatedHopsData &&
    doubleLegRepeatedHopsData.length > 0 &&
    athleteHistoricalLoad.status === "succeeded"
  ) {
    formatData(
      doubleLegRepeatedHopsData,
      3,
      "DL RH (RSI)",
      "Double Leg Repeated Hops (RSI)",
      "value",
      "contactTime",
      "flightTime",
      "Contact Time (s)",
      "Flight Time (s)",
      true,
      "jumpHeight",
      "Jump Height (cm)"
    );
  }

  if (
    singleLegCountermovementJumpData &&
    singleLegCountermovementJumpData.length > 0 &&
    athleteHistoricalLoad?.status === "succeeded"
  ) {
    formatData(
      singleLegCountermovementJumpData,
      4,
      "SL CMJ (%)",
      "Single Leg Countermovement Jump (%) negative values indicate asymmetry left, positive values indicate asymmetry right ",
      "assymVal",
      "rightValue",
      "leftValue",
      "Right Foot Height (cm)",
      "Left Foot Height (cm)"
    );
  }

  if (
    athleteHistoricalGroundContact?.status === "succeeded" &&
    athleteHistoricalGroundContact?.data.length > 0 &&
    athleteHistoricalLoad?.status === "succeeded"
  ) {
    formatData(
      athleteHistoricalGroundContact?.data?.slice(0, 10),
      5,
      "Ground Contact (%)",
      "Contact time asymmetry refers to the difference in the duration that each foot is in contact with the ground during a running or walking cycle.",
      "value"
    );
  }

  if (
    athleteHistoricalGait.status === "succeeded" &&
    athleteHistoricalGait?.data.length > 0 &&
    athleteHistoricalLoad.status === "succeeded"
  ) {
    const modifiedData = { High: [], Medium: [], Low: [] };
    let highCadenceModifiedData;
    let mediumCadenceModifiedData;
    let lowCadenceModifiedData;
    //multiplaying x2 before asigning it in to the graphing range
    athleteHistoricalGait?.data?.filter((item) => {
      item.cadence * 2 <= 110
        ? (modifiedData.Low = [item, ...modifiedData.Low])
        : item.cadence * 2 > 110 && item.cadence * 2 <= 180
        ? (modifiedData.Medium = [item, ...modifiedData.Medium])
        : (modifiedData.High = [item, ...modifiedData.High]);
    });

    if (modifiedData.High.length > 0) {
      highCadenceModifiedData = modifiedData["High"]?.slice(0, 10);

      formatData(
        highCadenceModifiedData,
        6,
        "High Duty Factor",
        "The duty factor is a representation of how long on average the foot is in contact with the ground during each stride. A higher value would indicate a more explosive propulsive force, with a longer swing time. The stance to stride ratio has been grouped here based on the average cadence in each session.",
        "meanSSR",
        "stdSSR",
        "cadence",
        "Standard Deviation",
        "Cadence",
        false
      );
    }

    if (modifiedData.Medium.length > 0) {
      mediumCadenceModifiedData = modifiedData["Medium"]?.slice(0, 10);

      formatData(
        mediumCadenceModifiedData,
        7,
        "Medium Duty Factor",
        "The duty factor is a representation of how long on average the foot is in contact with the ground during each stride. A higher value would indicate a more explosive propulsive force, with a longer swing time. The stance to stride ratio has been grouped here based on the average cadence in each session.",
        "meanSSR",
        "stdSSR",
        "cadence",
        "Standard Deviation",
        "Cadence",
        false
      );
    }

    if (modifiedData.Low.length > 0) {
      lowCadenceModifiedData = modifiedData["Low"]?.slice(0, 10);

      formatData(
        lowCadenceModifiedData,
        8,
        "Low Duty Factor",
        "The duty factor is a representation of how long on average the foot is in contact with the ground during each stride. A higher value would indicate a more explosive propulsive force, with a longer swing time. The stance to stride ratio has been grouped here based on the average cadence in each session.",
        "meanSSR",
        "stdSSR",
        "cadence",
        "Standard Deviation",
        "Cadence",
        false
      );
    }
  }

  return (
    <div className="activity-overview-parent">
      <div className="activity-graphs-flex">
        <div className="gait-activities">
          <AthleteActivityOverviewItem overviewTableData={overviewTableData} />
        </div>
        {/* </SwiperSlide>} */}

        {athleteHistoricalLoad && (
          <div className="gait-activities">
            <AthleteActivityOverviewItem
              athleteid={athleteid}
              loadData={loadData}
              setPosition={settingScrollHeight}
              handleLoadClick={handleLoadClick}
            />
          </div>
        )}

        {/*Mapping over jumps*/}
        {jumps.map((item, index) => {
          return (
            <AthleteActivityOverviewItem
              athleteid={athleteid}
              activity={item}
              setPosition={settingScrollHeight}
            />
          );
        })}

        {athleteHistoricalGait.status === "succeeded" &&
          athleteHistoricalGait.data.length && (
            <div className="gait-activities">
              <AthleteActivityOverviewItem
                athleteid={athleteid}
                gaitData={athleteHistoricalGait.data}
                setPosition={settingScrollHeight}
              />
            </div>
          )}
        {athleteHistoricalGroundContact.status === "succeeded" &&
          athleteHistoricalGroundContact.data.length && (
            <div className="gait-activities">
              <AthleteActivityOverviewItem
                athleteid={athleteid}
                asymData={athleteHistoricalGroundContact.data}
                setPosition={settingScrollHeight}
              />
            </div>
          )}
        {/* </Swiper> */}
      </div>
      <PlayerActivityDetails
        closeModal={closeModal}
        sessionId={selectedSessionId}
        fromPlayerProfile
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: selectUserData(state),
    athlete: selectAthlete(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      resetUserData,
      getAthletesHistoricalActivities,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AthleteActivityOverviewList);
