/* eslint-disable */
import { useEffect, useMemo, useState } from "react";

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getOrganisationsToolkit } from "store/orgSlice";
import { selectOrganisation } from "store/orgSlice";
import { selectUser } from "store/authSlice";
import {
  resetUserData,
  getAthletesHistoricalActivities,
} from "store/userSlice";

import AthleteOverview from "./AthleteOverview";
// import PlayerActivity from './PlayerActivity';
import UserProfileBar from "components/user-profile-components/UserProfileBar";
import "../../styles/user_profile_activity.scss";
import MyTabs from "components/common/mytabs/mytabs";
import useGetPlayer from "components/common/hooks/useGetPlayer";
import useWhyDidYouUpdate from "components/common/hooks/useWhydidyouUpdate";
import { usePrevious } from "components/common/hooks/usePrevious";
import PlayerActivity2Wrapper from "./PlayerActivity2Wrapper/playerActivity2Wrapper";
import AthleteOverview2 from "components/common/athleteProfile/AthleteOverview2";

function UserProfile(props) {
  const { user, actions, match, orgList, history } = props;
  const athleteid = parseInt(match.params.id);
  const player = useGetPlayer(orgList.orgList, match.params.id);
  const prevUrl = usePrevious(athleteid);
  useWhyDidYouUpdate("UserProfile", props);


  const [athleteLoadDate, setAthleteLoadDate] = useState("");

  function handleLoadClick(loadPayload) {

    setAthleteLoadDate(loadPayload.payload.TSCreated);

    setTimeout(()=>{
      setAthleteLoadDate("")
    },500)
  }

  function handleLoadClickTable(date) {
    setAthleteLoadDate(date);

    setTimeout(()=>{
      setAthleteLoadDate("")
    },500)
  }

  useEffect(() => {
    if (athleteid && orgList.orgList.length === 0 && user.id) {
      if(user.permissionLevel !== 0){
        actions.getOrganisationsToolkit(user.id);
      }
    }
  }, [orgList.orgList, athleteid, player, user]);

  useEffect(() => {
    const isAthleteRoute =
      history.location.pathname.includes("/athlete_profile/");
    if ((prevUrl && prevUrl !== athleteid) || isAthleteRoute) {
      actions.resetUserData();
      actions.getAthletesHistoricalActivities(athleteid);
    }
  }, [athleteid, prevUrl]);

  const tabs = useMemo(
    () => [
      {
        title: "Overview",
        value: "tab1",
        display: (
          <div className="user-profile-content">
            <AthleteOverview2
              history={history}
              athleteID={athleteid}
              handleLoadClick={handleLoadClick}
              handleLoadClickTable={handleLoadClickTable}
            />

                        
             {/* <AthleteOverview
              history={history}
              athleteid={athleteid}
              handleLoadClick={handleLoadClick}
            /> */}
           
          </div>
        ),
      },
      {
        title: "Sessions",
        value: "tab2",
        display: (
          <PlayerActivity2Wrapper
            match={match}
            history={history}
            playerid={parseInt(athleteid)}
            athleteLoadDate={athleteLoadDate}
          />
        ),
      },
    ],
    [match, history, athleteLoadDate]
  );

  //hide form function

  if (!player) {
    return <div />;
  }
  return (
    <div className="user-profile-container">
      <UserProfileBar
        orgData={orgList.orgList}
        userData={player}
        history={history}
        param={athleteid}
      />

      <div style={{ overflow: "hidden", backgroundColor: "#0A111F" }}>
        <MyTabs
          key={athleteid}
          headerClass="userProfile"
          tabs={tabs}
          athleteLoadDate={athleteLoadDate}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: selectUser(state),
    // userData: selectUserData(state),
    orgList: selectOrganisation(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getOrganisationsToolkit,
      resetUserData,
      getAthletesHistoricalActivities,
    },
    dispatch
  ),
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
);
