/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "react-vis/dist/style.css";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
} from "recharts";
import {
  NEW_LEFT_COLOR,
  NEW_RIGHT_COLOR,
  NEW_AXIS_COLOR,
  NEW_AXIS_TICK,
} from "../../utils/constant";
import {
  gctMax,
  gctMin,
  getAverage,
  getTooltipStyle,
  weHaveDataForThisFoot,
} from "../../utils/metrics";
import DownArrow from "../../components/common/icons/downArrow";
import { displayLoadingCharts } from "components/profileFunctions/profileFunctions";
import MaxMinMean from "./metric-helpers/MaxMinMean";
import GraphSettings from "./metric-helpers/GraphSettings";
import mappingTrimAreaX from "./metric-helpers/mappingTrimArea";
import { formatTime } from "utils/helper";
import MainTooltip from "components/common/mainTooltip/MainTooltip";

const CustomTooltipForCadence = ({ active, payload, label, data }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {data.length && (
          <div className="label">Time (s) : {`${label.toFixed(1)}`}</div>
        )}

        {payload.length >= 1 && (
          <div className={`${getTooltipStyle(payload[0])}`}>
            {`${payload[0].name}`} :{" "}
            {` ${Math.round(payload[0].value * 100) / 100}`} g{" "}
          </div>
        )}
        {payload.length >= 2 && (
          <div className={`${getTooltipStyle(payload[1])}`}>
            {`${payload[1].name}`} :{" "}
            {` ${Math.round(payload[1].value * 100) / 100}`} g{" "}
          </div>
        )}
      </div>
    );
  }

  return null;
};

class PeakTibialAcceleration extends React.Component {
  constructor(props) {
    super(props);

    let leftData = [];
    let rightData = [];

    this.state = {
      //hidden: true,
      hidden: false, //default to show data
      leftData: leftData,
      rightData: rightData,
      stepData: [],
      cadenceData: [],
      stepsCadenceChartV2: [],
      //hovered: false,
      hoveredLeft: null,
      hoveredRight: null,
      loading: false,
      runData: {},
      chartType: { graph: "line" },
    };
  }

  setGraphType = (graphType) => {
    this.setState({
      chartType: { graph: graphType },
    });
  };

  filterAverageData(arr, filterValue) {
    if (this.props.graphDomain) {
      return arr.filter(
        (item) =>
          item[filterValue] >= this.props.graphDomain[0] &&
          item[filterValue] <= this.props.graphDomain[1]
      );
    } else {
      return arr;
    }
  }

  toggleChart() {
    const { hidden } = this.state;

    this.setState({ hidden: !hidden });
  }

  leftHover = (v) => {
    this.setState({
      hoveredLeft: { v, name: "Foot: left" },
      hoveredRight: null,
    });
  };

  removeLeftHover = () => {
    this.setState({
      hoveredLeft: null,
      hoveredRight: null,
    });
  };

  rightHover = (v) => {
    this.setState({
      hoveredRight: { v, name: "Foot: right" },
      hoveredLeft: null,
    });
  };

  removeRightHover = () => {
    this.setState({
      hoveredRight: null,
      hoveredLeft: null,
    });
  };

  displayCharts() {
    const { hidden } = this.state;
    const {
      graphDomain,
      sessionList,
      trimDomain,
      isTrimOpen = false,
    } = this.props;

    if (!hidden && sessionList.runData.status === "loading")
      return displayLoadingCharts();
    else if (
      !hidden &&
      (sessionList.runData.data.PeakTibialAccelerationData &&
        sessionList.runData.data.PeakTibialAccelerationData.length > 0) !== true
    ) {
      return (
        <div className="no-data-container">
          No Data Available For This Metric
        </div>
      );
    } else if (!hidden && sessionList.runData.data.PeakTibialAccelerationData) {
      return (
        <div className="metric-container">
          <div className="metric-chart-container-parent ">
            <div className="chart-container-padding">
              <div className="chart-container">
                <MaxMinMean
                  leftFoot={{
                    hasData:
                      sessionList.runData.data.PeakTibialAccelerationData &&
                      weHaveDataForThisFoot(
                        "left",
                        sessionList.runData.data.PeakTibialAccelerationData
                      ),
                    min: gctMin(
                      "left",
                      this.filterAverageData(
                        sessionList.runData.data.PeakTibialAccelerationData,
                        "name"
                      )
                    ),
                    max: gctMax(
                      "left",
                      this.filterAverageData(
                        sessionList.runData.data.PeakTibialAccelerationData,
                        "name"
                      )
                    ),
                    mean: getAverage(
                      "left",
                      this.filterAverageData(
                        sessionList.runData.data.PeakTibialAccelerationData,
                        "name"
                      )
                    ),
                  }}
                  rightFoot={{
                    hasData:
                      sessionList.runData.data.PeakTibialAccelerationData &&
                      weHaveDataForThisFoot(
                        "right",
                        sessionList.runData.data.PeakTibialAccelerationData
                      ),
                    min: gctMin(
                      "right",
                      this.filterAverageData(
                        sessionList.runData.data.PeakTibialAccelerationData,
                        "name"
                      )
                    ),
                    max: gctMax(
                      "right",
                      this.filterAverageData(
                        sessionList.runData.data.PeakTibialAccelerationData,
                        "name"
                      )
                    ),
                    mean: getAverage(
                      "right",
                      this.filterAverageData(
                        sessionList.runData.data.PeakTibialAccelerationData,
                        "name"
                      )
                    ),
                  }}
                />
                <div className="container-pdf-flex">
                  <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                      width={500}
                      height={600}
                      //data={stepsCadenceChartV2}
                      data={sessionList.runData.data.PeakTibialAccelerationData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      syncId={"test1"}
                    >
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={NEW_AXIS_COLOR}
                      />
                      {/* <XAxis label={{ value: 'Time (s)', position: 'bottom' }} domain={[0, 'dataMax']} type="number" dataKey="name" /> */}

                      <XAxis
                        label={{
                          value: sessionList.runData.data.PeakTibialAccelerationData[sessionList.runData.data.PeakTibialAccelerationData.length -2]?.["name"] > 300 ? "Time (min)" : "Time (s)",
                          position: "bottom",
                          fill: NEW_AXIS_TICK,
                          offset: -6,
                        }}
                        dataKey="name"
                        type="number"
                        tickCount={15}
                        interval={3}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                        domain={graphDomain && !isTrimOpen ? graphDomain : [0]}
                        allowDataOverflow
                        tickFormatter={(value) => formatTime(value, sessionList.runData.data.PeakTibialAccelerationData[sessionList.runData.data.PeakTibialAccelerationData.length -2])}
                      />

                      <YAxis
                        label={{
                          value: "Acceleration (g)",
                          angle: -90,
                          position: "insideLeft",
                          fill: NEW_AXIS_TICK,
                        }}
                        tick={{ fill: NEW_AXIS_TICK }}
                        stroke={NEW_AXIS_COLOR}
                      />
                      {/* <Tooltip /> */}
                      {/* <Legend wrapperStyle={{ position: 'relative', marginTop: '0px', marginLeft: '20px' }} margin={{ top: 20, left: 100, right: 0, bottom: 0 }} /> */}
                      <Legend
                        verticalAlign="top"
                        layout="vertical"
                        wrapperStyle={{ top: -60, right: 0 }}
                      />
                      <Tooltip
                        content={
                          <CustomTooltipForCadence
                            data={
                              sessionList.runData.data.PeakTibialAccelerationData
                            }
                          />
                        }
                      />
                      {this.props.trimDomain &&
                        isTrimOpen &&
                        mappingTrimAreaX(this.props.trimDomain)}
                      {this.state.chartType.graph === "line" && (
                        <>
                          <Line
                            connectNulls={false}
                            dataKey="Left Foot"
                            stroke={NEW_LEFT_COLOR}
                            dot={
                              sessionList.runData.data.PeakTibialAccelerationData
                                .length > 50
                                ? false
                                : true
                            }
                          />
                          <Line
                            connectNulls={false}
                            dataKey="Right Foot"
                            stroke={NEW_RIGHT_COLOR}
                            dot={
                              sessionList.runData.data.PeakTibialAccelerationData
                                .length > 50
                                ? false
                                : true
                            }
                          />
                        </>
                      )}

                      {this.state.chartType.graph === "bar" && (
                        <>
                          <Bar
                            dataKey="Left Foot"
                            fill={NEW_LEFT_COLOR}
                            barSize={5}
                            opacity={0.7}
                          />
                          <Bar
                            dataKey="Right Foot"
                            fill={NEW_RIGHT_COLOR}
                            barSize={5}
                            opacity={0.7}
                          />
                        </>
                      )}
                    </ComposedChart>
                  </ResponsiveContainer>
                  {this.props.toggleableTextArea}      
                </div>
              </div>

              <div style={{ height: 80 }} />
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    const { hidden } = this.state;

    let arrowClass = "";
    if (!hidden) {
      arrowClass = "flip-div";
    }

    return (
      <div className="settings_graph">
        <div className="metric-container-parent peak-tibial">
          <div className="metric-container metric-title-div">
            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-main-title">
              Peak Tibial Acceleration [g]{" "}
              <div style={{ paddingLeft: "0.5rem" }}>
                <MainTooltip
                  tooltipText={
                    "Peak tibial acceleration refers to the maximum rate of increase in speed experienced by the tibia at initial contact during gait, typically measured to assess the impact forces transmitted through the legs."

                  }
                  tooltipIcon={"info"}
                  iconSize={25}
                />
              </div>
            </div>

            <div className="metric-title-extra-info">
              {/* <span>00:00:00</span> */}
            </div>

            <div className="metric-title-left-padding" />

            <div className="metric-title-left-icon"></div>
          </div>

          {this.displayCharts()}
        </div>
        {this.props.showSettings && (
          <GraphSettings
            graphName={"peakTibialAcceleration"}
            settingsPosition={"right-side"}
            chartType={this.state.chartType}
            setChartType={this.setGraphType}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    sessionList: state.session,
  };
}

export default connect(mapStateToProps)(PeakTibialAcceleration);
