/* eslint-disable */
import MainButton from "components/common/reuseableButtonComponent/MainButton";
import TeamComponent from "../team-component/TeamComponent";
import "./teamManagementContainer.scss";
import { nanoid } from "@reduxjs/toolkit";
import PlusIcon from "components/common/icons/plusIcon";
import MainModal from "components/common/mainModal/MainModal";
import { useEffect, useState, memo } from "react";
import DeleteIcon from "components/common/icons/deleteIcon";
import CloseIcon from "components/common/icons/closeIcon";
import * as Dialog from "@radix-ui/react-dialog";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setAthletesInTeam } from "store/teamsAthletesSlice";
import { selectedTeamAthleteList } from "store/teamsAthletesSlice";
import { resetTeamAthletesData } from "store/teamsAthletesSlice";


const addGroupsToTeams = (teams, groups) => {
  // Create a map of team ids to groups
  let teamGroups = {};
  for (let group of groups) {
    if (!teamGroups[group.teamid]) {
      teamGroups[group.teamid] = [];
    }
    teamGroups[group.teamid].push(group);
  }

  // Add the groups to the corresponding team
  let newTeams = teams?.map((team) => {
    // Create a new object that contains all properties of the team
    let newTeam = Object.assign({}, team);

    if (teamGroups[team.id]) {
      newTeam.groups = teamGroups[team.id];
    }

    return newTeam;
  });

  return newTeams;
};

const Checkbox = memo(
  ({
    value,
    playerName,
    athletesSelectedForAssignment,
    setAthletesSelectedForAssignment,
  }) => {
    const handleAthletesSelectedForAssignment = (event) => {
      if (event.target.checked) {
        setAthletesSelectedForAssignment([
          ...athletesSelectedForAssignment,
          event.target.value,
        ]);
      } else {
        setAthletesSelectedForAssignment(
          athletesSelectedForAssignment.filter(
            (item) => item !== event.target.value
          )
        );
      }
    };
    return (
      <label className="container">
        {" "}
        {playerName}
        <input
          type="checkbox"
          value={value}
          onChange={handleAthletesSelectedForAssignment}
          checked={athletesSelectedForAssignment?.includes(String(value))}
        />
        <span className="checkmark"></span>
      </label>
    );
  }
);


const TeamManagementContainer = (props) => {
  const {
    listOfTeams,
    orgData,
    addTeamHandler,
    isEditingTeams,
    deleteTeamHandler,
    groupList,
    teamAthletesList,
    actions,
    selectedOrgIdData,
    orgID,
    teamList,
  } = props;



  //state declaration
  const [newTeamFormBody, setNewTeamFormBody] = useState({
    teamName: "",
    teamDetails: "",
  });
  const [deleteStatus, setDeleteStatus] = useState("");
  const [addTeamStatus, setAddTeamStatus] = useState("");
  const [unassignedAthletesList, setUnassignedAthletesList] = useState([]);
  const [assignedAthletes, setAssignedAthletes] = useState([]);
  const [athletesSelectedForAssignment, setAthletesSelectedForAssignment] =
    useState("");
  const [teamsWithGroups, setTeamsWithGroups] = useState([]);
  const [assignedTeamGroup, setAssignedTeamGroup] = useState({
    teamID: "",
    groupID: "",
  });
  const [athletesBatchAssignmentPostBody, setAthletesBatchAssigmentPostBody] =
    useState({
      teamid: "",
      playerDetails: [],
    });

  useEffect(() => {
    setUnassignedAthletesList(orgData?.players);
  }, [orgData, selectedOrgIdData]);

  useEffect(() => {

      const assignedAthleteIDs = teamAthletesList?.teamsAthletes?.map(
        (item) => item?.playerid
      );
      const unassignedList = orgData?.players.filter((item) => {
        if (!assignedAthleteIDs.includes(item.playerID)) {
          return item;
        } else {
        }
      });

      const assignedList = orgData?.players.filter((item) => {
        if (assignedAthleteIDs.includes(item.playerID)) {
          return item;
        } else {
        }
      });
      if (
        teamAthletesList?.teamsAthletes?.length >= 0 &&
        orgData?.players?.length > 0
      ) {

      setUnassignedAthletesList(unassignedList);
      setAssignedAthletes([...assignedList]);
    }
  }, [listOfTeams, teamAthletesList, groupList, selectedOrgIdData]);

  useEffect(() => {
    let teamWithGroups = addGroupsToTeams(filteredListOfTeams, groupList);
    setTeamsWithGroups(teamWithGroups);
  }, [listOfTeams, groupList, selectedOrgIdData]);

  useEffect(() => {
    if (assignedTeamGroup?.teamID && athletesSelectedForAssignment?.length > 0) {
      setAthletesBatchAssigmentPostBody({
        teamid: assignedTeamGroup.teamID,
        playerDetails: athletesSelectedForAssignment?.map((item) => ({
          playerid: Number(item),
          teamid: assignedTeamGroup.teamID,
          ...(assignedTeamGroup.groupID && {
            groupid: assignedTeamGroup.groupID,
          }),
        })),
      });
    }
  }, [assignedTeamGroup, athletesSelectedForAssignment, selectedOrgIdData]);

  //varriables ----------------------------------------------------------------

  const filteredListOfTeams = listOfTeams?.filter(
    (item) => item.orgid === orgData?.OrgID
  );

  const postBody = {
    name: newTeamFormBody?.teamName,
    detail: newTeamFormBody?.teamDetails,
    orgid: orgData?.OrgID,
  };

  //handlers -----------------------------------------------------------------------

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setNewTeamFormBody((prevData) => ({ ...prevData, [name]: value }));
  };

  const handler = async (event, arg1) => {
    if (event === "delete") {
      const status = await deleteTeamHandler(arg1);
      await location.reload();
      await setDeleteStatus(status);
    }

    if (event === "add") {
      if (
        arg1.name?.length > 0 &&
        arg1.orgid &&
        !listOfTeams.filter((item) => item.name === postBody.name)?.length > 0
      ) {
        const status = await addTeamHandler(arg1);
        await setAddTeamStatus(status);
      }
    }
  };

  const handleTeamGroupSelection = (value) => {
    setAssignedTeamGroup(JSON.parse(value.target.value));
  };

  const handleModalReset = () => {
    setAthletesSelectedForAssignment("");
    setAssignedTeamGroup({
      teamID: "",
      groupID: "",
    });
    setAthletesBatchAssigmentPostBody({
      teamid: "",
      playerDetails: [],
    });
  };

  const handleRestAthleteState = () => {
    actions.resetTeamAthletesData();
  };


  //api calls  ------------------------------------------------------------------------------

  const assignAthletesGroupTeams = async (postBody) => {
    if (postBody?.teamid && postBody?.playerDetails?.length > 0) {
      const assignAthletes = await actions.setAthletesInTeam(postBody);
      await setAthletesSelectedForAssignment("");
      await setAssignedTeamGroup({
        teamID: "",
        groupID: "",
      });
      await setAthletesBatchAssigmentPostBody({
        teamid: "",
        playerDetails: [],
      });
      if (assignAthletes?.meta?.requestStatus) {
        return assignAthletes.meta.requestStatus;
      } else {
        return "error";
      }
    }
  };


  

  return (
    <div className="team-management-body-container">
      <div className="teams-body-container">
        <h3 className="container-heading">Teams</h3>
        <div className="team-body">
          {filteredListOfTeams?.map((item) => (
            <div key={nanoid()}>
              <TeamComponent
                teamName={item.name}
                teamDetails={item.details}
                teamId={item.id}
                isEditingTeams={isEditingTeams}
                assignedAthletes={assignedAthletes}
                editingModal={
                  <MainModal
                    button={<DeleteIcon size="20" />}
                    modalBody={
                      <div className="delete-team-modal-body">
                        <div className="delete-modal-info">
                          <h3>Delete, {item.name}?</h3>
                          <p>
                            Deleting a team will <span>permanently</span> delete
                            data associated with the team, are you sure?
                          </p>
                        </div>
                        <div className="delete-modal-controls">
                          <div onClick={() => handler("delete", item.id)}>
                            <MainButton
                              buttonText={"Delete Team"}
                              ButtonIcon={DeleteIcon}
                              buttonImportant={true}
                            />
                          </div>
                          {/* Dialog close added to close the modal from an external child component */}
                          <Dialog.Close>
                            <div onClick={() => close()}>
                              <MainButton
                                buttonText={"Cancel"}
                                ButtonIcon={CloseIcon}
                              />
                            </div>
                          </Dialog.Close>
                        </div>
                      </div>
                    }
                    responseResetState={setDeleteStatus}
                  />
                }
                athleteList={orgData?.players}
                teamAthletesList={teamAthletesList}
                handleRestAthleteState={handleRestAthleteState}
                selectedOrgIdData={selectedOrgIdData}
                teamList={teamList}
                orgID={orgID}
              />
            </div>
          ))}

          <MainModal
            button={
              <MainButton
                buttonText={"Add New Team"}
                ButtonIcon={PlusIcon}
                buttonPop={true}
              />
            }
            modalBody={
              addTeamStatus !== "fulfilled" || addTeamStatus === "error" ? (
                <div className="add-team-modal-body">
                  <h3>Create New Team</h3>
                  <input
                    type="text"
                    name="teamName"
                    id="teamName"
                    placeholder="Team Name"
                    defaultValue={newTeamFormBody.teamName}
                    onChange={handleFormChange}
                  />
                  <textarea
                    placeholder="Team Details"
                    defaultValue={newTeamFormBody.teamDetails}
                    onChange={handleFormChange}
                    name="teamDetails"
                  />
                  <div
                    onClick={() => {
                      handler("add", postBody);
                    }}
                  >
                    <MainButton
                      buttonText={"Create New Team"}
                      ButtonIcon={PlusIcon}
                      buttonPop={true}
                      buttonDisabled={
                        !(
                          postBody.name?.length > 0 &&
                          postBody.orgid &&
                          !listOfTeams.filter(
                            (item) => item.name === postBody.name
                          )?.length > 0
                        )
                      }
                      buttonTooltip={
                        !(postBody.name?.length > 0 && postBody.orgid)
                          ? "Please enter a valid team name"
                          : "A team of the same name already exists"
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="add-team-modal-body">
                  <h3>Team Successfully Created</h3>
                  <div
                    onClick={() => {
                      setAddTeamStatus("");
                    }}
                  >
                    <MainButton
                      buttonText={"Create Another Team"}
                      ButtonIcon={PlusIcon}
                      buttonPop={true}
                    />
                  </div>
                </div>
              )
            }
            responseResetState={setAddTeamStatus}
          />
        </div>
      </div>
      <div className="other-teams-body-container">
        <h3 className="container-heading">Unassigned Athletes</h3>
        <div className="unasigned-athletes-body">
          <div className="assign-button">
            <MainModal
              button={
                <MainButton
                  buttonText={"Assign to a Team"}
                  ButtonIcon={PlusIcon}
                  buttonPop={true}
                />
              }
              modalBody={
                <div className="assign-athletes-modal-body">
                  <div className="assign-modal-info">
                    <h3>Assign Athletes to a Team?</h3>
                    <p>
                      Select one or more athletes from the list on the left and
                      assign them to a team or a group within the team.
                    </p>
                  </div>
                  <div className="assign-modal-body">
                    <div className="modal-body-half body-modal-athletes">
                      <div className="body-modal-background unassigned athletes-modal">
                        <div className="assign-modal-body-heading">
                          <h4>Unassigned Athletes</h4>
                        </div>
                        <div className="assign-modal-body-athletes">
                          {unassignedAthletesList?.length > 0 ? (
                            unassignedAthletesList?.map((item) => (
                              <div className="modal-athlete" key={item?.playerID}>
                                <Checkbox
                                  value={item?.playerID}
                                  playerName={item?.player}
                                  athletesSelectedForAssignment={
                                    athletesSelectedForAssignment
                                  }
                                  setAthletesSelectedForAssignment={
                                    setAthletesSelectedForAssignment
                                  }
                                />
                                
                              </div>
                            ))
                          ) : (
                            <div className="empty-div">
                              All athletes have been assigned to a team
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="body-modal-background assigned athletes-modal">
                        <div className="assign-modal-body-heading">
                          <h4>Assigned Athletes</h4>
                        </div>
                        <div className="assign-modal-body-athletes">
                          {assignedAthletes?.length > 0 ? (
                            assignedAthletes?.map((item) => (
                              <div className="modal-athlete" key={item?.playerID}>
                                <Checkbox
                                  value={item?.playerID}
                                  playerName={item?.player}
                                  athletesSelectedForAssignment={
                                    athletesSelectedForAssignment
                                  }
                                  setAthletesSelectedForAssignment={
                                    setAthletesSelectedForAssignment
                                  }
                                />
                              </div>
                            ))
                          ) : (
                            <div className="empty-div">
                              No athletes have beeen assigned to a team yet
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="body-modal-background modal-body-half teams-and-groups-for-assignment-modal">
                      <div className="assign-modal-body-heading">
                        <h4>Teams</h4>
                      </div>
                      <div className="assign-modal-body-teams">
                        {teamsWithGroups?.map((item) => {
                          return (
                            <div key={nanoid()} className="assign-modal-team">
                              <label className="container">
                                {" "}
                                {item?.name}
                                <input
                                  type="checkbox"
                                  value={JSON.stringify({
                                    teamID: item?.id,
                                  })}
                                  onChange={(e) => handleTeamGroupSelection(e)}
                                  checked={
                                    assignedTeamGroup?.teamID == item?.id
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                              {item?.groups?.length > 0 && (
                                <div className="assign-modal-team-groups">
                                  {item?.groups?.map((group) => (
                                    <div
                                      className="assign-modal-team-group"
                                      key={nanoid()}
                                    >
                                      <label className="container">
                                        {" "}
                                        {group?.name}
                                        <input
                                          type="checkbox"
                                          value={JSON.stringify({
                                            teamID: item?.id,
                                            groupID: group?.id,
                                          })}
                                          onChange={(e) => {
                                            handleTeamGroupSelection(e);
                                          }}
                                          checked={
                                            assignedTeamGroup?.groupID ===
                                            group?.id
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="assign-athletes-modal-controls">
                    <div className="assign-modal-button assign-cancel-button">
                      <Dialog.Close>
                        <div onClick={() => handleModalReset()}>
                          <MainButton
                            buttonText={"Cancel Change"}
                            buttonImportant={true}
                          />
                        </div>
                      </Dialog.Close>
                    </div>

                    <div className="assign-modal-button assign-confirm-button">
                      <div
                        onClick={() =>
                          assignAthletesGroupTeams(
                            athletesBatchAssignmentPostBody
                          )
                        }
                      >
                        <MainButton
                          buttonText={"Confirm Change"}
                          buttonPop={true}
                          buttonDisabled={
                            athletesBatchAssignmentPostBody?.teamid &&
                            athletesBatchAssignmentPostBody.playerDetails
                              ?.length > 0
                              ? false
                              : true
                          }
                          buttonTooltip={
                            "Please select one or more athletes and the team or group you'd like to assign them to."
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              responseResetState={handleModalReset}
            />
          </div>
          <div className="unasigned-athletes-list">
            {unassignedAthletesList?.map((item) => (
              <div key={item?.playerID} className="unassigned-athlete">
                <p>{item?.player}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    teamAthletesList: selectedTeamAthleteList(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setAthletesInTeam,
      resetTeamAthletesData,
    },
    dispatch
  ),
});

//exporting and connecting component to redux
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamManagementContainer)
);
